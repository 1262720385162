import gql from "graphql-tag";

export const getProjectById = gql`
  query getProjectById($ProjectId: ID!) {
    Project(ProjectId: $ProjectId) {
      isLoading @client
      Id
      Name
      ActivityIds
      DueDateTime
      CompletedDateTime
      AssignedUserId
      Description
      ProjectStatusId
      CustomFields
      Schema {
        FieldType
        Id
        Name
        Order
      }

      Documents {
        Id
        Name
        ContainerId
        ContainerName
        AbsoluteUri
        FileExtension
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
    }
  }
`;
