import * as React from "react";
import { ReactNode, useContext, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { ROUTES } from "../../_constant/screens";

import DeleteButton from "../../components/DeleteButton/DeleteButton";

import Collapse from "@material-ui/core/Collapse";

import { setEditFormIsDirty } from "../../redux/actions/actions";

import Form from "../../components/Form/Form";
import Body from "../../components/Body/Body";
import Modal from "../../containers/Modal/Modal";
import FormHeader from "../../components/FormHeader/FormHeader";
import { RevertDialogComponent } from "../../containers/_common/Dialog";
import EntityTabsProvider from "../../components/EntityTabsContainer/EntityTabsProvider";
import EntityTabsContainer from "../../components/EntityTabsContainer/EntityTabsContainer";

import VendorFormLayout from "./VendorFormFieldsLayout";
import { VendorFormContext } from "./VendorFormContext";
import CustomizeActivityButton from "../CustomizeActivityButton/CustomizeActivityButton";
import RelatedActivities from "../../containers/assets/_components/relaitedActivities/RelatedActivities";
import ActivitiesTabInsideVendor from "./ActivitiesTab/ActivitiesTabInsideVendor";
import VendorDeleteDialog from "./VendorDeleteDialog/VendorDeleteDialog";
import ItemCustomFieldsContainer from "../ItemCustomFields/ItemCustomFieldsContainer";
import CustomizeToggleButton from "../CustomizeToggleButton/CustomizeToggleButton";
import CommonInfoTab from "./CommonInfoTab/CommonInfoTab";

export interface ITabContent {
  name: string;
  disabled: boolean;
  hasCustomizeMode: boolean;
  content: ReactNode;
  tabControl?: ReactNode | null;
}

const VendorFormPageLayout: React.FC = () => {
  const {
    isDeletePopupVisible,
    isWizardOpen,
    isCustomize,
    isRevertPopupVisible,
    handleCloseWizard,
    handleOpenWizard,
    handleCustomize,
    setIsDeletePopupVisible,
    setIsRevertPopupVisible,
    handleSubmit,
    isLoading,
    isFetching,
    isNew,
    data: propsData,
    setHeaderOptions,
    lockSaveButton,
    formApi,
    formData,
    phoneError,
    emailError,
    websiteError,
    handleBlur,
    handleChangeWithValidation,
    dropZone,
  } = useContext(VendorFormContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);

  const [isItemsFetching, setIsItemsFetching] = useState(false);
  const [activityIdsForFilteringSelectOptions, setActivityIdsForFilteringSelectOptions] = useState<
    string[]
  >([]);

  useEffect(() => {
    setActivityIdsForFilteringSelectOptions(formData.AssetActivityIds);
  }, [formData]);

  const handleDeleteDialogOpen = () => {
    setIsDeletePopupVisible(true);
    setIsItemsFetching(true);
  };

  const customerTabs: ITabContent[] = [
    {
      name: "Vendor Info",
      disabled: isNew,
      hasCustomizeMode: true,
      content: (
        <ItemCustomFieldsContainer
          CustomFields={formApi.data.CustomFields}
          Schema={formApi.data.Schema}
          onChange={formApi.handleChange}
          isCustomize={isCustomize}
          entityName="Vendor"
          isLoading={isLoading}
        />
      ),
      tabControl: (
        <CustomizeToggleButton onClick={handleCustomize} checked={isCustomize} disabled={isNew} />
      ),
    },
    {
      name: "Common Info",
      disabled: isNew,
      hasCustomizeMode: false,
      content: <CommonInfoTab handleChange={formApi.handleChange} value={formData.Notes} />,
    },
    {
      name: "Activities",
      disabled: isNew,
      content: propsData ? (
        <RelatedActivities
          hidden={isNew}
          isCustomize={isCustomize}
          withAssetSelect={true}
          vendorId={formData.Id}
          ActivityIds={formData.AssetActivityIds}
          formApi={formApi}
          isWizardOpen={isWizardOpen}
          handleOpenWizard={handleOpenWizard}
          handleCloseWizard={handleCloseWizard}
          activityIdsForFilteringSelectOptions={activityIdsForFilteringSelectOptions}
          setActivityIdsForFilteringSelectOptions={setActivityIdsForFilteringSelectOptions}
        >
          <ActivitiesTabInsideVendor ActivityIds={formData.AssetActivityIds} />
        </RelatedActivities>
      ) : null,
      tabControl: isNew ? null : (
        <CustomizeActivityButton
          disabled={false}
          onClick={handleCustomize}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Activity"}
        </CustomizeActivityButton>
      ),
      hasCustomizeMode: true,
    },
    {
      name: "Manage",
      disabled: isNew,
      content: (
        <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
          <DeleteButton
            onClick={() => setIsDeletePopupVisible(true)}
            disabled={isLoading || isItemsFetching}
            loading={isLoading || isItemsFetching}
          >
            Delete Vendor
          </DeleteButton>
        </div>
      ),
      tabControl: null,
      hasCustomizeMode: false,
    },
  ];

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      pageTitle: isNew ? "Add New Vendor" : "Edit Vendor",
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        delete: !isNew ? () => setIsDeletePopupVisible(true) : null,
        revertFunction: openRevertDialog,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading]);

  return (
    <Body disableDesktopScroll>
      <Modal isShowing={isRevertPopupVisible}>
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      </Modal>
      <Modal isShowing={isDeletePopupVisible}>
        <VendorDeleteDialog id={formData.Id} handleCancel={() => setIsDeletePopupVisible(false)} />
      </Modal>
      <Form>
        <Form header>
          <FormHeader
            isNew={isNew}
            isBusinessAccount={false}
            title={"Vendor"}
            titlePrefix={isNew ? "Add New" : undefined}
            isLoading={isLoading}
            isSaveAvailable={lockSaveButton}
            handleSubmit={handleSubmit}
            cancelRouteForEmptyHistory={ROUTES.VENDORS}
            revertData={openRevertDialog}
            isDataChanged={formApi.isDataChanged}
          />
        </Form>

        <Form body>
          <Form content>
            <Collapse in={!isCustomize} timeout="auto" unmountOnExit>
              <VendorFormLayout
                errorFields={formApi.errorFields}
                data={formApi.data}
                handleChange={formApi.handleChange}
                handleChangeWithValidation={handleChangeWithValidation}
                handleBlur={handleBlur}
                phoneError={phoneError}
                emailError={emailError}
                websiteError={websiteError}
              />
            </Collapse>
            {
              <EntityTabsProvider>
                <EntityTabsContainer
                  content={
                    isCustomize
                      ? customerTabs.filter((el) => el.hasCustomizeMode === isCustomize)
                      : customerTabs
                  }
                  entityName="vendor"
                  isNew={isNew}
                  startFrom="2"
                  isCustomize={isCustomize}
                  changeCustomize={handleCustomize}
                  tabIndexAfterCreate="2"
                  renderSelect={isMobile}
                  isDesktop={false}
                />
              </EntityTabsProvider>
            }
          </Form>
          <Form images>{dropZone}</Form>
        </Form>
      </Form>
    </Body>
  );
};

export default VendorFormPageLayout;
