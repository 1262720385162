import * as types from "../actions/actionTypes";
import { AppInfoModel } from "../models/app/AppInfoModel";
import { ClientInfoModel } from "../models/app/ClientInfoModel";
import { LayoutType } from "../../_constant/constants";

export default function appReducer(state = new AppInfoModel(new ClientInfoModel()), action) {
  switch (action.type) {
    case types.UPDATE_CLIENT_INFO:
      return state.withNewClientInfo(action.props);
    case types.UPDATE_ACTIVITIES_LAYOUT:
      return state.withUpdatedActivitiesLayout(action.props);
    case types.GLOBAL_INFO_UPDATED:
      return state.withUpdatedAvailableStatus(action.props);
    case types.UPDATE_REDIRECTION_PAGE:
      return state.withUpdatedRedirectionPage(action.props);
    case types.SHOW_LEAVING_POPUP:
      return state.withUpdatedRedirectionFlag(action.props);
    case types.UPDATE_IS_DIRTY_PROPERTY:
      return state.withUpdatedDirtyFormFlag(action.props);
    case types.UPDATE_ACTIVITY_FILTER:
      return state.withUpdatedActivityFilter(action.props);
    case types.UPDATE_ACTIVITIES_EMPTY_STATUS:
      return state.withUpdatedActivitiesEmptyStatus(action.props);
    case types.UPDATE_VISITS_EMPTY_STATUS:
      return state.withUpdatedVisitsEmptyStatus(action.props);
    case types.SET_CUSTOMER_ID:
    case types.SET_MEMBER_ID:
      return state.withUpdatedVisitsEmptyStatus({ reset: true });
    case types.UPDATE_CONNECTION_STATE:
      return state.withUpdatedConnectionState(action.props);
    case types.UPDATE_PROJECTS_EMPTY_STATUS:
      return state.withUpdatedProjectsEmptyStatus(action.props);
    default:
      return state;
  }
}

export const getActivitiesLayout = (state): LayoutType => state.appInfo.getActivitiesLayout();

export const isAppAvailable = (state): boolean => state.appInfo.getLoadingFlag();

export const getRedirectionUrl = (state): string => state.appInfo.pageForRedirect;

export const getPopupState = (state): boolean => state.appInfo.getPopupState();

export const getEditFormDirtyFlag = (state): boolean => state.appInfo.getEditFormDirtyFlag();

export const getActivityFilters = (state): Map<string, string> =>
  state.appInfo.getActivityFilters();

export const getClientId = (state): string => state.appInfo.getClientInfo().clientId;

export const getAppUrl = (state): string => state.appInfo.getClientInfo().appUrl;

export const getAuthUrl = (state): string => state.appInfo.getClientInfo().authUrl;

export const getChangePassUrl = (state): string => state.appInfo.getClientInfo().changePasswordUrl;

export const getBlobStorageUrl = (state): string => state.appInfo.getClientInfo().blobStorage;

export const getChangeSubscriptionUrl = (state): string =>
  state.appInfo.getClientInfo().changeSubscriptionUrl;

export const getClientInfo = (state): ClientInfoModel => state.appInfo.getClientInfo();

export const activityEmptyStatusList = (state): Set<string> =>
  state.appInfo.getIsActivitiesListEmpty();

export const visitsEmptyStatusList = (state): Set<string> => state.appInfo.getIsVisitsListEmpty();

export const projectsEmptyStatusList = (state): Set<string> =>
  state.appInfo.getIsProjectListEmpty();

export const isBusinessAccountsEnabled = (state): boolean =>
  state.appInfo.getClientInfo().enableBusinessAccounts;

export const isDeviceRegisterInfoEnabled = (state): boolean =>
  state.appInfo.getClientInfo().enableRegisterDeviceInfo;

export const isDebugInfoEnabled = (state): boolean =>
  state.appInfo.getClientInfo().enableDebugMessages;

export const isConnectionLost = (state): boolean => state.appInfo.getConnectionState();
