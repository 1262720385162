import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const FormTitleStyle = styled((props) => <Typography component="span" {...props} />)`
  font-weight: 500;
`;

export const FormTitlePrefix = styled((props) => <Typography component="span" {...props} />)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 500;
`;
