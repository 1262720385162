import gql from "graphql-tag";

export const getTimezones = gql`
  query getTimezones {
    GetTimezones {
      Id
      DisplayName
    }
  }
`;
