import styled from "styled-components";
import { scrollStyles } from "../_styled/scrollStyles";

export const UploadDropzoneWrapper = styled.section`
  flex-grow: 1;
`;

export const UploadDropzoneContainer = styled.div`
  height: 100%;
`;

export const DropZoneInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px var(--color-brand) dashed;
  border-radius: var(--border-radius-s);

  @media screen and (max-width: 600px) {
    padding-bottom: 0;
    position: relative;

    &::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

export const DropZoneStyled = styled.div`
  height: 100%;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  min-height: 160px;
  padding: 16px 16px 16px 6px;
  ${scrollStyles}

  @media screen and (min-width: 600px) {
    flex-direction: column;
    padding: 16px;
  }
`;

export const DropZoneContainer = styled.div`
  padding-right: 3px;
  max-width: 100%;
  width: 100%;
`;

export const DropZoneEmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const DropZoneEmptyStateIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
`;

export const DropZoneEmptyStateText = styled.div`
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const FormatTitleText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
`;

export const FormatText = styled.span`
  margin-left: 4px;
  font-size: 12px;
`;

export const FormatContainer = styled.span`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FullDropZoneContainer = styled.div`
  position: relative;
  background-image: var(--full-drop-zone-csv);
  background-size: contain;
  background-repeat: no-repeat;
  width: 49px;
  height: 49px;
`;

export const FileNameText = styled.span`
  margin: 8px 0;
  font-size: 12px;
  text-align: center;
`;

export const CancelButton = styled.button`
  position: absolute;
  width: auto;
  right: 0;
  top: 0;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  transform: translate(50%, -50%);
  & svg {
    fill: ${({theme}) => theme.palette.primary.main}
  }
`;

export const ErrorContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary.lighten};
`;

export const ErrorNotification = styled.span`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.error.main};
  svg {
    margin-right: 8px;
    font-size: 14px;
  }
`;
