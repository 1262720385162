import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-apollo";

import useGetAllRelatedActivities from "../../../../customHooks/useGetAllRelatedActivities";
import useSuccessfulRedirect from "../../../../customHooks/useSuccessfulRedirect";

import { AssetModel } from "../../../../redux/models/data/AssetModel";
import { AccountModel } from "../../../../redux/models/data/AccountModel";
import { AssetRefsModel } from "../../../../redux/models/data/AssetRefsModel";

import { ROUTES } from "../../../../_constant/screens";
import { ACCOUNT, ASSET_REFS, ASSET_TYPE_REF, ID } from "../../../../_constant/wordings";
import { NotificationTypes } from "../../../../_constant/NotificationTypes";

import { GET_ACTIVITIES_LIST } from "../../../activities/all/activities-list.query";
import { GET_ASSET_REFS } from "../../all/assets.query";
import { moveItemToAnotherAccountQuery } from "./moveAsset.query";

import { removeItemFromAssetReferencesQuery } from "../deleteAssetPopup/updateCacheFunction";
import {
  convertStatusToFilterVariable,
  displayErrorNotification,
} from "../../../../redux/actions/_utils";
import { getActivityFilters } from "../../../../redux/reducers/appReducer";
import { getSelectedAssetType } from "../../../../redux/reducers/filterReducer";
import { setSelectedAssets } from "../../../../redux/actions/actions";

import ConfirmAssetMove from "../../../../components/AssetConfirmPopup/ConfirmAssetMove";
import AssetConfirmPopup from "../../../../components/AssetConfirmPopup/AssetConfirmPopup";
import { MoveItemDialog } from "../../../_common/Dialog";
import RelatedActivitiesList from "../../../../components/AssetConfirmPopup/RelatedActivitiesList";

interface IMoveAssetPopupWrapper {
  assetData: AssetModel;
  accountMoveTo: AccountModel;
  lockDeleteButton: boolean;
  isCustomerManagement: boolean;
  handlePopupClose: () => void;
  handleFinishPopupLoading: () => void;
}

const MoveAssetPopupWrapper = (props: IMoveAssetPopupWrapper) => {
  const {
    assetData,
    accountMoveTo,
    handleFinishPopupLoading,
    isCustomerManagement,
    handlePopupClose,
    ...rest
  } = props;

  const { successfulRedirect } = useSuccessfulRedirect();

  const {
    isDataAvailable,
    relatedActivities,
    statusNameMap,
    statuses,
    accountId,
  } = useGetAllRelatedActivities({
    assetId: assetData.Id,
  });

  React.useEffect(() => {
    if (isDataAvailable) {
      handleFinishPopupLoading();
    }
  }, [isDataAvailable]);

  const dispatch = useDispatch();

  const filterMap = useSelector(getActivityFilters);
  const selectedAssetType = useSelector(getSelectedAssetType, shallowEqual);

  const [moveItem, { loading, client }] = useMutation(moveItemToAnotherAccountQuery);

  const handleMoveItem = () => {
    if (isCustomerManagement) return;
    let refetchQueries = [];

    if (relatedActivities.length) {
      refetchQueries = statuses.map((element) => ({
        query: GET_ACTIVITIES_LIST,
        variables: { Id: accountId, Filter: convertStatusToFilterVariable(element, filterMap) },
      }));
      try {
        client?.cache.readQuery({
          query: GET_ACTIVITIES_LIST,
          variables: {
            Id: accountId,
            Filter: convertStatusToFilterVariable(statuses[0], filterMap),
          },
        });
      } catch (e) {
        refetchQueries = [];
      }
    }

    const variables = {
      AssetId: assetData.Id,
      DestinationAccountId: accountMoveTo.Id,
      SourceAccountId: accountId,
    };
    moveItem({
      variables,
      refetchQueries,
      update(cache, { data: { AssetMove } }) {
        const assetReferencesQuery: any = cache.readQuery({
          query: GET_ASSET_REFS,
          variables: { Id: accountId },
        });

        removeItemFromAssetReferencesQuery(cache, AssetMove, accountId, assetReferencesQuery);

        const isLastOfType = !assetReferencesQuery?.[ACCOUNT]?.[ASSET_REFS].some(
          (asset: AssetRefsModel) => asset[ASSET_TYPE_REF][ID] === selectedAssetType,
        );

        if (isLastOfType) {
          dispatch(setSelectedAssets());
        }
      },
    })
      .then(({ data }) => {
        if (data) {
          successfulRedirect(ROUTES.ASSETS, NotificationTypes.ASSET_MOVED);
        }
      })
      .catch((e) => {
        console.log(e);
        displayErrorNotification(NotificationTypes.ASSET_MOVE_ERROR);
      });
  };

  const renderDialog = (activities: any[]) => {
    return activities.length > 0 ? (
      <AssetConfirmPopup
        {...rest}
        lockDeleteButton={loading}
        handleSubmit={handleMoveItem}
        confirmContent={
          <ConfirmAssetMove ItemName={assetData.Name} AccountDestination={accountMoveTo.Name} />
        }
        buttonConfirmLabel={"Move"}
        relatedEntityCount={activities.length}
      >
        <RelatedActivitiesList
          activities={activities}
          statusName={statusNameMap}
          disableNavigationByClick
        />
      </AssetConfirmPopup>
    ) : (
      <MoveItemDialog isDataLoading={false} cancel={handlePopupClose} submit={handleMoveItem}>
        Move the <span className="Label">{assetData.Name} </span>
        item to <span className="Label">{accountMoveTo.Name}</span> account?
      </MoveItemDialog>
    );
  };

  return isDataAvailable ? renderDialog(relatedActivities) : null;
};

export default MoveAssetPopupWrapper;
