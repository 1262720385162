import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface CardLabelProps {
  background?: string;
}

export const CardMediaContainer = styled(Box)`
  position: relative;
  overflow: hidden;
`;

export const CardLabelList = styled(Box)`
  position: absolute;
  top: 15px;
  left: 0;
  max-width: 90%;
`;

export const CardLabel = styled(Typography)<CardLabelProps>`
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.8px;
  color: #fff;
  padding: 0 4px;
  background-color: ${({ background }) => (background ? background : "#81CC35")};
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
