import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { VisitModel } from "../../redux/models/data/VisitModel";

import { ACCOUNT, VISITS } from "../../_constant/wordings";
import { getVisitsByStatusQuery } from "../../components/VisitsByStatuses/visitsByStatus.query";
import { customerNameFragment } from "../../graphql/resolvers";

export const updateCacheWithNewVisit = (
  cache: DataProxy,
  visit: VisitModel,
  accountId: string,
  filterString: string,
) => {
  try {
    const visitsListQuery: any = cache.readQuery({
      query: getVisitsByStatusQuery,
      variables: { Id: accountId, Filter: filterString },
    });

    const customerFragment = cache.readFragment({
      fragment: customerNameFragment,
      id: visit.CustomerId!,
    });

    const newVisit: any = { ...visit };
    delete newVisit["Images"];
    newVisit["CustomerName"] = customerFragment?.Name || "";

    if (newVisit["Schedule"]) {
      newVisit["Schedule"] = {
        RecurringType: newVisit.Schedule?.RecurringType,
        __typename: "ScheduleType",
      };
    }

    const newVisitsList = visitsListQuery[ACCOUNT][VISITS].concat([newVisit]);

    cache.writeQuery({
      query: getVisitsByStatusQuery,
      variables: { Id: accountId, Filter: filterString },
      data: { Account: { Visits: newVisitsList, Id: accountId, __typename: ACCOUNT } },
    });
  } catch (e) {}
};
