import * as React from "react";
import { useState } from "react";
import DropTargetRelatedActivities from "../DropTargetRelatedActivities";
import StatusAccordionContent from "./StatusAccordionContent";
import { useLazyQuery } from "react-apollo";
import { GET_RELATED_ACTIVITIES_BY_STATUS } from "../activities-list.query";
import useGetAccountId from "../../../../../customHooks/useGetAccountId";

interface StatusAccordionComponentProps {
  StatusName: string;
  StatusId: string;
  AssetId: string;
  expanded: boolean;
}

const StatusAccordionComponent: React.FC<StatusAccordionComponentProps> = ({
  StatusName,
  StatusId,
  AssetId,
  expanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const { accountId } = useGetAccountId();

  const [getActivities, { data, loading, error }] = useLazyQuery(GET_RELATED_ACTIVITIES_BY_STATUS, {
    variables: {
      AccountId: accountId,
      AssetId: AssetId,
      Filter: `StatusId=="${StatusId}"`,
    },
  });

  const handleOpen = async () => {
    setIsExpanded(true);
    await getActivities();
  };

  const activitiesArrLength =
    data && data.Account?.Assets[0]?.AssetActivities
      ? data.Account?.Assets[0]?.AssetActivities.length
      : 0;
  const labelCounter = isExpanded && activitiesArrLength > 0 && (
    <span className="Label">({activitiesArrLength})</span>
  );

  const activities = data?.Account?.Assets?.length ? data.Account.Assets[0].AssetActivities : [];

  return (
    <DropTargetRelatedActivities
      label={
        <>
          {StatusName.toUpperCase()} {labelCounter}
        </>
      }
      onOpen={handleOpen}
      onClose={() => {
        setIsExpanded(false);
      }}
      isLoading={loading}
      expanded={isExpanded}
      StatusId={StatusId}
    >
      <StatusAccordionContent activities={activities} StatusName={StatusName} />
    </DropTargetRelatedActivities>
  );
};

export default StatusAccordionComponent;
