import * as React from "react";
import FilterSelect from "../../../../components/FilterSelect";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { FILTERING_DETAILS } from "../../../../_constant/ActivityDateConstant";
import { LayoutType } from "../../../../_constant/constants";

const ActivitiesByStatusHeader = ({ status, layout, onClick, isExpanded }) => {
  
  return (
    <div className="ActivitiesList__containerHeader" onClick={onClick}>
      {layout === LayoutType.LIST ? (
        <>
          <h3 className="ActivitiesList__containerHeaderTitle">
            {status.Name}
          </h3>
          <ExpandMoreOutlinedIcon
            className={`ActivitiesList__containerHeaderChevron${
              isExpanded ? " ActivitiesList__containerHeaderChevron--rotated" : ""
            }`}
          />
        </>
      ) : (
        <h3 className="ActivitiesList__containerHeaderTitle">
          {status.Name}
        </h3>
      )}
      {!!FILTERING_DETAILS.get(status.Name) && (
        <FilterSelect
          label={FILTERING_DETAILS.get(status.Name).label}
          statusId={status.Id}
          statusName={status.Name}
          className="ActivitiesList__filter"
        />
      )}
    </div>
  );
};

export default ActivitiesByStatusHeader;
