import * as React from "react";
import { useState } from "react";

import { useDropZoneContext } from "../DropZoneContainer/useDropZoneContext";

import Lightbox from "react-image-lightbox";

import Portal from "@material-ui/core/Portal";

import { prepareImagesForGallery } from "../../_utils/utils";

import "react-image-lightbox/style.css";

interface DropZoneProps {}

const ImageGallery: React.FC<DropZoneProps> = () => {
  const { imagesToDisplay: images, closeGallery, firstImage } = useDropZoneContext();

  const imagesForGallery = prepareImagesForGallery(images);
  const [photoIndex, setPhotoIndex] = useState(firstImage);

  const onMovePrevRequest = () => {
    setPhotoIndex((photoIndex + imagesForGallery.length - 1) % imagesForGallery.length);
  };
  const onMoveNextRequest = () => {
    setPhotoIndex((photoIndex + 1) % imagesForGallery.length);
  };

  return (
    <Portal>
      <Lightbox
        mainSrc={imagesForGallery[photoIndex]}
        onCloseRequest={closeGallery}
        nextSrc={imagesForGallery[(photoIndex + 1) % imagesForGallery.length]}
        prevSrc={imagesForGallery[(photoIndex + images.length - 1) % imagesForGallery.length]}
        onMovePrevRequest={onMovePrevRequest}
        onMoveNextRequest={onMoveNextRequest}
      />
    </Portal>
  );
};

export default ImageGallery;
