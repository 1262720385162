import React from "react";
import styled from "styled-components";
import WizardCard from "../WizardCard/WizardCard";
import { scrollStyles } from "../../_styled/scrollStyles";
import { Button } from "@material-ui/core";

export const WizardItemTypeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 16px 0;
`;

export const WizardItemTypeList = styled.div(
  ({ theme }) => `
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  align-content: flex-start;

  ${theme.breakpoints.up("sm")} {
    flex-direction: row;
    flex-wrap: wrap;
  };

  ${scrollStyles};
`,
);

export const WizardItemTypeHeader = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: space-between;
`;

export const WizardItemTypeHeaderAction = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  font-size: 0.875rem;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

export const WizardItemTypeCard = styled((props) => <WizardCard {...props} />)(
  ({ variant, theme }) => {
    const isCardVariant = variant === "card";

    return `
    width: ${isCardVariant ? "calc(20% - 6px)" : "100%"};
    margin: ${isCardVariant ? "3px" : "3px 0"};
  `;
  },
);
