import * as React from "react";
import { Route, Routes } from "react-router-dom";

import AllActivities from "./all/AllActivities";
import NewActivity from "./new/NewActivity";
import EditActivity from "./existing/EditActivity";
import { CREATE_NEW_PATH, ROUTES } from "../../_constant/screens";

const ActivityPage: React.FC = () => (
  <Routes>
    <Route path=":id" element={<EditActivity />} />
    <Route path={CREATE_NEW_PATH} element={<NewActivity />} />
  </Routes>
);

export default ActivityPage;
