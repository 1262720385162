import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";
import { useStaticDataQuery } from "../../../queries/useStaticDataQuery";

import { Image } from "../../../redux/models/data/ImageModel";
import {
  DocumentFromServer,
  ImageFromServer,
  mapServerDocumentToNormalized,
  mapServerImageToNormalized,
  mapServerVideoToNormalized,
} from "../../../_utils/dropzoneHelpers";

import { ROUTES } from "../../../_constant/screens";
import { LayoutType } from "../../../_constant/constants";

import { addCustomerIdToUrl, isRecurring } from "../../../_utils/utils";
import { formatDateWithoutTZ } from "../../../_utils/date";

import * as statusesConfig from "../../../../configs/statuses-config.json";

import { Box, Grid, Hidden, Typography, useTheme } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import { LoaderCircle } from "../../../components/Loader";
import AssignmentActivityContainer from "../../../components/AssigmentContainer/AssignmentActivityContainer";
import { CardContainer, CardParent } from "../../../components/Cards/styled/card.styled";
import { NormalizedFile } from "../../../components/DropZoneContainer/DropZoneContainer";
import { ActivityItemDate } from "./item/ActivityItemDate";
import MoreAction, { MoreActionOptions } from "../../../components/MoreAction/MoreAction";
import DeleteActivityPopup from "./form/DeleteActivityPopup";

const { EMPTY, IN_PROGRESS, FILL } = statusesConfig["statusNameMap"];

interface ActivityItemProps {
  activity: any; // ActivityModelShort | ActivityModelRelated
  dateType: string;
  layout: LayoutType;
  status?: string;
  disableNavigationByClick?: boolean;
  style?: {};
  isAccountPersonal: boolean;
  isDraggable?: boolean;
  isAssignmentsVisibile: boolean;
  customerId?: string | null;
  finishedView?: boolean;
  withMoreAction?: boolean;
  withDragAndDropIcon?: boolean;
  projectId?: string | null;
  removeActivityFromProjectWithDelete?: (activityId: string) => void;
  isAssignmentWithoutControl?: boolean;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case EMPTY:
      return "var(--color-to-do)";
    case IN_PROGRESS:
      return "var(--color-in-progress)";
    case FILL:
      return "var(--color-done)";
    default:
      return "var(--color-to-do)";
  }
};

const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  const {
    activity,
    dateType,
    layout,
    status = "",
    disableNavigationByClick = false,
    style,
    isAccountPersonal,
    isDraggable = false,
    isAssignmentsVisibile = false,
    customerId = null,
    finishedView = false,
    withMoreAction,
    withDragAndDropIcon,
    projectId = null,
    removeActivityFromProjectWithDelete,
    isAssignmentWithoutControl = false,
  } = props;
  const {
    Name,
    Description = "",
    Images = [],
    Documents = [],
    Videos = [],
    Schedule,
    RelatedDueDateTime,
    isLoading,
    Asset,
    StatusId = "",
  } = activity;
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const theme = useTheme();

  const iconClassName = isRecurring({ schedule: Schedule, relatedDueDate: RelatedDueDateTime })
    ? "ActivityItem_recurring"
    : "";
  const navigate = useNavigate();

  const statuses = useGetAcitvitiesStatuses();

  const staticData = useStaticDataQuery();
  const pdfIcon = staticData?.Icons?.Desktop?.pic_Desktop_PDF ?? "";
  const videoIcon = staticData?.Icons?.Desktop?.pic_Desktop_Video ?? "";

  const currentStatusName = statuses.find((status) => status.Id === StatusId)?.Name ?? "";

  const smUp = useMediaQuery("(min-width: 600px)", { noSsr: true });
  const mdUp = useMediaQuery("(min-width: 960px)", { noSsr: true });

  const openDeleteActivityDialog = useCallback(() => setIsDeletePopupVisible(true), []);
  const closeDeleteActivityDialog = useCallback(() => setIsDeletePopupVisible(false), []);

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: openDeleteActivityDialog,
    },
  ];

  const goToActivity = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let path = `${ROUTES.SINGLE_ACTIVITY}/${activity.Id}`;
    path = customerId ? addCustomerIdToUrl(path, customerId) : path;
    navigate(path);
  };

  const imagesForCard = [] as Image[];
  if (Images && Images.length) {
    Images.forEach((image: Image, index: number) => {
      if (index <= 2) {
        imagesForCard.push(image);
      }
    });
  }

  const renderAssignment = () =>
    !isDraggable && !isAccountPersonal ? (
      <AssignmentActivityContainer
        assignedUserId={activity.AssignedUserId}
        activityId={activity.Id}
        withoutControl={isAssignmentWithoutControl}
      />
    ) : null;

  const renderSomething = () => {
    if (finishedView) {
      return (
        <Box
          display="flex"
          alignItems="center"
          style={{ fontSize: "10px", color: getStatusColor(currentStatusName) }}
        >
          {currentStatusName === FILL && (
            <CheckCircleIcon
              style={{
                fontSize: "1rem",
                color: getStatusColor(currentStatusName),
                marginRight: "3px",
              }}
            />
          )}
          {currentStatusName.toUpperCase()}
        </Box>
      );
    }

    return (
      Asset?.Name && (
        <CardParent alignItems="start">
          <span>FOR </span>
          {Asset.Name}
        </CardParent>
      )
    );
  };

  const renderCardMedia = () => {
    const normalizedImages: NormalizedFile[] = Images
      ? Images.map((file: ImageFromServer, index: number) =>
          mapServerImageToNormalized({ file, index }),
        )
      : [];
    const normalizedDocuments = Documents
      ? Documents.map((file: DocumentFromServer) =>
          mapServerDocumentToNormalized({ file, icon: pdfIcon }),
        )
      : [];
    const normalizedVideos = Videos
      ? Videos.map((file: DocumentFromServer) =>
          mapServerVideoToNormalized({ file, icon: videoIcon }),
        )
      : [];

    const normalizedFiles = [
      ...normalizedImages,
      ...normalizedDocuments,
      ...normalizedVideos,
    ].slice(0, 3);

    if (normalizedFiles.every((file) => file.fileType === "image")) {
      return normalizedFiles.map((image) => {
        const classModifier =
          normalizedFiles.length === 1
            ? ""
            : normalizedFiles.length === 2
            ? "_twoImages"
            : "_threeImages";
        const imageContainer = `ActivityItem__imageContainer ActivityItem__imageContainer${classModifier}`;
        return (
          <div className={imageContainer} key={image.id}>
            <img className="ActivityItem__image" src={image.imgUrl} alt={image.title} />
          </div>
        );
      });
    } else {
      return normalizedFiles.map((file) => {
        switch (file.fileType) {
          case "image":
            return (
              <div
                className="ActivityItem__imageContainer ActivityItem__imageContainer_mediaItem"
                key={file.id}
              >
                <img className="ActivityItem__item" src={file.imgUrl} alt={file.title} />
              </div>
            );
          case "document":
          case "video":
            return (
              <div
                className="ActivityItem__imageContainer ActivityItem__imageContainer_mediaItem"
                key={file.id}
              >
                <img className="ActivityItem__item" src={file.imgUrl} alt={file.title} />
              </div>
            );
          default:
            return null;
        }
      });
    }
  };

  if (layout === LayoutType.LIST) {
    return (
      <>
        <CardContainer
          padding={1}
          style={isDraggable ? style : { borderBottom: "1px solid #eeeeee" }}
          borderRadius={isDraggable ? "8px" : "0"}
          onClick={disableNavigationByClick ? undefined : goToActivity}
          withLabel={status}
        >
          {isLoading && (
            <div className={`ActivityItem_loader`}>
              <LoaderCircle size={"xs"} />
            </div>
          )}

          <Grid spacing={1} container alignItems="center" wrap="nowrap">
            <Grid item container xs zeroMinWidth>
              {withDragAndDropIcon && (
                <Box display="flex" alignItems="center">
                  <DragIndicatorIcon color="action" fontSize="small" />
                </Box>
              )}
              <Box paddingLeft="4px">
                <Typography variant="body2" component="h3" noWrap>
                  {Name}
                </Typography>
                {renderSomething()}
              </Box>
            </Grid>

            <Hidden smDown={isAssignmentWithoutControl ? false : true}>
              <Grid item xs={isAssignmentWithoutControl ? false : 3}>
                {isAssignmentsVisibile && renderAssignment()}
              </Grid>
            </Hidden>

            <Grid item xs={mdUp && !isAssignmentWithoutControl ? 4 : false}>
              <Grid item container alignItems="center" justify="flex-end" spacing={1} wrap="nowrap">
                <Grid item style={{ minWidth: smUp ? 110 : 0 }}>
                  <ActivityItemDate
                    activityDate={formatDateWithoutTZ(activity[dateType])}
                    dateType={dateType}
                    iconOnly={!smUp}
                  />
                </Grid>
                {!finishedView && (
                  <Grid item>
                    <i className={`Icon ${iconClassName}`}>repeat</i>
                  </Grid>
                )}
                {withMoreAction && (
                  <Grid item>
                    <MoreAction options={options} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContainer>
        {isDeletePopupVisible && (
          <DeleteActivityPopup
            activity={activity}
            isDeletePopupVisible={isDeletePopupVisible}
            handleCancel={closeDeleteActivityDialog}
            customerId={customerId}
            projectId={projectId}
            withoutRedirect
            removeActivityFromProjectWithDelete={removeActivityFromProjectWithDelete}
          />
        )}
      </>
    );
  }

  return (
    <>
      <article
        className={`ActivityItem ActivityItem__${layout} ActivityItem--${status}${
          disableNavigationByClick ? " without-navigation" : ""
        }`}
        onClick={disableNavigationByClick ? undefined : goToActivity}
        style={style}
      >
        {isLoading && (
          <div className={`ActivityItem_loader`}>
            <LoaderCircle size={"xs"} />
          </div>
        )}
        <header className={`ActivityItem_header`}>
          <div className="ActivityItem_titleContainer">
            <h3 className="ActivityItem_title" title={Name}>
              {Name && Name.charAt(0).toUpperCase() + Name.slice(1)}
            </h3>
            {withMoreAction && (
              <div className="ActivityItem_moreActions">
                <MoreAction options={options} popupPosition="left-start" />
              </div>
            )}
          </div>
          {Asset && Asset.Name && (
            <div
              className={`ActivityItem_details${
                !isAccountPersonal && layout === LayoutType.LIST ? " ActivityItem_details__cut" : ""
              }`}
            >
              For{" "}
              <span className={`ActivityItem_relatedAsset`} title={Asset.Name}>
                {Asset.Name}
              </span>
            </div>
          )}
        </header>
        {layout === LayoutType.BOARD && (
          <div className={`ActivityItem_body`}>
            {Description && (
              <div className={`ActivityItem_description`}>
                <p className="Label">{Description}</p>
              </div>
            )}
            {(!!Images?.length || !!Documents?.length || !!Videos?.length) &&
              layout === LayoutType.BOARD && (
                <div className="ActivityItem__gallery">{renderCardMedia()}</div>
              )}
            {layout === LayoutType.BOARD && renderAssignment()}
          </div>
        )}
        {layout === LayoutType.LIST && isAssignmentsVisibile && (
          <div className="ActivityItem_assignContainer">
            {renderAssignment()}
            <div className="ActivityItem_assignBuffer" />
          </div>
        )}
        <footer className={`ActivityItem_meta`}>
          <ActivityItemDate
            activityDate={formatDateWithoutTZ(activity[dateType])}
            dateType={dateType}
          />
          <i className={`Icon ${iconClassName}`}>repeat</i>
        </footer>
      </article>
      {isDeletePopupVisible && (
        <DeleteActivityPopup
          activity={activity}
          isDeletePopupVisible={isDeletePopupVisible}
          handleCancel={closeDeleteActivityDialog}
          customerId={customerId}
          projectId={projectId}
          removeActivityFromProjectWithDelete={removeActivityFromProjectWithDelete}
          withoutRedirect
        />
      )}
    </>
  );
};

export default ActivityItem;
