import * as React from "react";
import {LoaderCircle} from "./Loader";

interface ButtonProps {
  label?: string;
  icon?: string;
  className?: string;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: "primary" | "secondary" | "link" | "icon";
  onClick?: (e?: any) => void;
  size?: "s" | undefined;
}

export default class Button extends React.PureComponent<ButtonProps> {
  render() {
    const {label, type, isLoading = false, icon, title, onClick, className: nameOfClass, size} = this.props;
    const buttonType = `Button${type ? `-${type}` : ""}`;
    const className = `${buttonType} ${size ? `${buttonType}_${size}` : ""} ${nameOfClass ? nameOfClass : ""}`;
    const disabled = isLoading || this.props.disabled;
    const btnLabel = <>{icon ? <i className={`Icon Icon-${icon}`}/> : null}<span>{label}</span></>;
    return (
      <button className={className} onClick={onClick} disabled={disabled} title={title}>
        {isLoading ? <LoaderCircle size={"xs"} /> : btnLabel}
      </button>
    );
  }
}
