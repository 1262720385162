import gql from "graphql-tag";

export const createCustomerMutation = gql`
  mutation createNewCustomer($Customer: CustomerInput!, $Files: [Upload]) {
    CustomerPost(Customer: $Customer, Files: $Files) {
      AccountId
      Address
      Email
      Id
      Name
      Phone
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        AbsoluteUri
        FileExtension
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
    }
  }
`;
