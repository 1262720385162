import styled from "styled-components";

import Paper from "@material-ui/core/Paper/Paper";
import { Avatar, Typography } from "@material-ui/core";
import { textClip } from "../../_styled/textClip";

export const CardStyled = styled(Paper)`
  max-width: 780px;
  border-radius: 16px;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};

  :not(:last-child) {
    margin-bottom: 1rem;
  }

  @media print {
    break-inside: avoid;
    break-before: auto;
  }
`;

export const CardContainerStyled = styled.div`
  display: flex;
`;

export const ImgStyled = styled(Avatar)`
  width: 10.75rem;
  height: 10.75rem;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.primary.medium};
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important;

  @media print {
    width: 152px;
    height: 152px;
  }
`;

export const MainContentStyled = styled.section`
  padding: 12px 0 12px 12px;
  display: flex;
`;

export const InfoWrapper = styled.div`
  width: 328px;
  display: flex;
  flex-direction: column;
  margin-left: 1.75rem; // 28px
  margin-top: 1rem;

  @media print {
    width: 298px;
    margin-left: 20px;
    margin-top: 10px;
  }
`;

export const CardTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1.5rem;

  @media print {
    font-size: 18px;
  }
`;

export const SubTitleContaineer = styled.div`
  display: flex;
  align-items: center;
`;

export const TypeIconStyled = styled.i`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SubTitle = styled(Typography)`
  margin-left: 4px;
  /* font-size: 0.625rem; // 10px */
  font-size: 0.75rem; // 10px
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface DescriptionStyledProps {
  $withoutClip: boolean;
}
export const DescriptionStyled = styled(Typography)<DescriptionStyledProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.text.additionalText};
  margin-top: 0.75rem;
  padding-right: 4px;
  ${({ $withoutClip }) => ($withoutClip ? null : textClip)}
`;

export const MoreInfoContainer = styled.div`
  margin: 24px 0 0.5rem;
`;

export const SideContentStyled = styled.section`
  display: flex;
  flex-direction: column;
  padding: 38px 0 0 1.5rem;
  border-left: solid ${({ theme }) => theme.palette.greyDesign.separator} 1px;

  @media print {
    font-size: 16px;
    padding: 28px 0 0 18px;
  }
`;
