import React, { useState, useEffect } from "react";

import { useMediaQuery, useTheme } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import Input from "../../Input/Input";
import { WizardInputInCardContainer } from "./WizardInputInCardStyles";
import FloatingInput from "../../FloatingInput/FloatingInput";

import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";
import { FOR_INPUT_TYPE_TEXT } from "../../../_utils/regex";
import { INPUT_LIMITS } from "../../../_constant/constants";
import { updateItemFunctionType } from "../WizardItemScreen/types";

interface IWizardInputInCard {
  updateItem: updateItemFunctionType;
  newItem: AssetForWizardModel;
  itemsList: AssetForWizardModel[];
}

const WizardInputInCard: React.FC<IWizardInputInCard> = (props) => {
  const { updateItem, newItem, itemsList } = props;
  const [isInputMode, setInputMode] = useState(false);
  const [value, setValue] = useState("");

  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  useEffect(() => {
    if (newItem.Name && newItem._isNameCustom) {
      setValue(newItem.Name);
      setInputMode(true);
    }
  }, []);

  useEffect(() => {
    if (!newItem._isNameCustom) {
      setValue("");
      setInputMode(false);
    }
  }, [newItem]);

  const handleClick = () => {
    if (!isInputMode) {
      setInputMode(true);
    }
    if (!isDesktop && !mobileOpen) {
      setMobileOpen(true);
    }
    updateItem({
      newEntity: { ...newItem, _isNameCustom: true, Name: value, _AssetActivities: [] },
    });
  };

  const handleMobileClose = () => {
    setMobileOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    if (!FOR_INPUT_TYPE_TEXT.test(target.value) && target.value.length !== 0) {
      return;
    }
    setValue(target.value);

    // handle clear input action by button
    if (target.value === "") {
      updateItem({
        newEntity: { ...newItem, Name: target.value, _isNameCustom: true, _AssetActivities: [] },
      });
    }
  };

  const handleChangeMobile = (_?: string, value: string) => {
    const props: any = { target: { value } };
    handleChange(props);
    handleMobileClose();
  };

  const handleBlur = () => {
    let _isNameCustom = true;
    const finalValue = value.trim();

    // turn off input mode if value already exist
    if (itemsList.some((item) => finalValue === item.Name)) {
      setValue("");
      setInputMode(false);
      _isNameCustom = false;
    }
    updateItem({
      newEntity: {
        ...newItem,
        Name: finalValue,
        _isNameCustom,
        _AssetActivities: _isNameCustom ? [] : newItem._AssetActivities,
      },
    });
  };

  const placeholderText = `Add My ${newItem.AssetType.Name}`;

  return (
    <WizardInputInCardContainer
      key={"new_item"}
      isSelected={newItem._isNameCustom}
      onClick={handleClick}
      variant="row"
      isInputMode={isInputMode}
      button={isInputMode ? null : <AddCircleOutlineOutlinedIcon />}
    >
      {isInputMode ? (
        <Input
          placeholder={placeholderText}
          autoFocus
          disableUnderline
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={INPUT_LIMITS.Name.max}
        />
      ) : (
        "Add New"
      )}
      <FloatingInput
        isOpen={isInputMode && mobileOpen && !isDesktop}
        value={value}
        onChange={handleChangeMobile}
        onClose={handleMobileClose}
        placeholder={placeholderText}
        label={"New Item"}
        maxLength={INPUT_LIMITS.Name.max}
      />
    </WizardInputInCardContainer>
  );
};

export default WizardInputInCard;
