import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/react-hooks";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";

import { getActivitiesRelatedToVendorQuery } from "./getActivitiesRelatedToVendor.query";

import { alphabetNameSorting, dateSorting } from "../../../_utils/utils";

import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Collapse from "@material-ui/core/Collapse";

import RelatedActivitiesList from "../../AssetConfirmPopup/RelatedActivitiesList";
import EmptyCustomFieldState from "../../EmptyCustomFieldsState/EmptyCustomFieldState";

import { VendorFormContext } from "../VendorFormContext";
import {
  TabStyleContainer,
  ActivitiesStyledContainer,
  LoadingContainer,
} from "./ActivitiesTabInsideVendor.style";

interface ActivitiesTabProps {
  ActivityIds: string[];
}

const ActivitiesTabInsideVendor: React.FC<ActivitiesTabProps> = ({ ActivityIds }) => {
  const { accountId: AccountId } = useGetAccountId();
  const { tabFetchPolicy, setCachePolicyForTab, formData } = useContext(VendorFormContext);
  const vendorId = formData.Id;

  const [statusNameMap, setStatusName] = useState(new Map());

  const [getActivities, { data, loading, error }] = useLazyQuery(
    getActivitiesRelatedToVendorQuery,
    { fetchPolicy: tabFetchPolicy },
  );

  useEffect(() => {
    if (data && !loading && !error) {
      setCachePolicyForTab("cache-first");
    }
  }, [vendorId, loading, error]);

  useEffect(() => {
    if (vendorId && AccountId) {
      getActivities({
        variables: {
          ActivityIds,
          AccountId,
        },
      });
    }
  }, [tabFetchPolicy, ActivityIds, AccountId, vendorId]);

  const statuses = useGetAcitvitiesStatuses();

  useEffect(() => {
    if (statuses.length > 0) {
      const statusesArr = statuses.map((status) => [status.Id, status.Name]);
      setStatusName(new Map(statusesArr));
    }
  }, [statuses]);

  const activitiesList = data?.AssetActivities ? data?.AssetActivities : [];
  // const isLoading = loading || !statusNameMap.size || isFormLoading;
  const isLoading = loading || !statusNameMap.size || !vendorId;

  const isDataAvailable = !isLoading && !!activitiesList.length && !!statusNameMap.size;
  const emptyCustomFieldsText = `No activities found for this vendor.\nAdd one?`;

  const filterDoneActivities = (vendorActivities: any[] | undefined) => {
    if (!vendorActivities) return [];
    return dateSorting(vendorActivities.sort(alphabetNameSorting), "CompletedDateTime", "asc");
  };

  const filterNotDoneActivities = (vendorActivities: any[] | undefined) => {
    if (!vendorActivities) return [];
    return dateSorting(vendorActivities.sort(alphabetNameSorting), "DueDateTime", "asc");
  };

  const filterVendorActivities = (activitiesList: any[] | undefined) => {
    if (!activitiesList) {
      return [];
    }
    const doneActivities = activitiesList.filter((activity) => !!activity.CompletedDateTime);
    const notDoneActivities = activitiesList.filter((activity) => !activity.CompletedDateTime);
    filterDoneActivities(doneActivities);
    filterNotDoneActivities(notDoneActivities);

    return [...notDoneActivities, ...doneActivities];
  };

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      {!isLoading && !activitiesList.length && data && (
        <EmptyCustomFieldState emptyFieldsMessage={emptyCustomFieldsText} />
      )}
      <Collapse in={isDataAvailable}>
        <TabStyleContainer>
          <ActivitiesStyledContainer>
            <RelatedActivitiesList
              activities={filterVendorActivities(activitiesList)}
              statusName={statusNameMap}
            />
          </ActivitiesStyledContainer>
        </TabStyleContainer>
      </Collapse>
    </>
  );
};

export default React.memo(ActivitiesTabInsideVendor, (prevProps, nextProps) => {
  const activitiesSet = new Set([...prevProps.ActivityIds, ...nextProps.ActivityIds]);
  const activityIdsSame =
    activitiesSet.size === prevProps.ActivityIds.length &&
    activitiesSet.size === nextProps.ActivityIds.length;

  const formLoadingSame = prevProps.isFormLoading === nextProps.isFormLoading;
  if (activityIdsSame && formLoadingSame) {
    return true;
  }

  return false;
});
