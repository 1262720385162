import * as React from "react";
import "./NoResultComponent.scss";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import { ACTIVITIES_EMPTY, ASSETS_EMPTY } from "../../_constant/wordings";

export const NoActivityComponent = () => {
  const staticData = useStaticDataQuery();
  const logoLink = staticData ? staticData.Empty[ACTIVITIES_EMPTY] : "";

  return (
    <div className="NoResults">
      <img className="NoResults_ActivityImg" src={logoLink} alt="Activities are empty" />
      <div className="NoResults_Title">No activities?</div>
      <div className="NoResults_Title NoResultsTitle__subTitle">Try one of these:</div>
      <ul className="NoResults_List">
        <li className="NoResults_Text">Clear the search field</li>
        <li className="NoResults_Text">Clear the filter</li>
        <li className="NoResults_Text">Change the date ranges</li>
      </ul>
    </div>
  );
};

export const NoAssetsComponent = () => {
  const staticData = useStaticDataQuery();
  const logoLink = staticData ? staticData.Empty.items : "";

  return (
    <div className="NoResults NoResults_assets">
      <img className="NoResults_AssetImg" src={logoLink} alt="Items are empty" />
      <div className="NoResults_Title">There is nothing here</div>
      <div className="NoResults_Title__subTitle">So what are you waiting for?</div>
    </div>
  );
};

export const NoVendorsComponent = () => {
  const staticData = useStaticDataQuery();
  const logoLink = staticData ? staticData.Empty.vendors : "";

  return (
    <div className="NoResults NoResults_vendors">
      <img className="NoResults_VendorImg" src={logoLink} alt="Vendors are empty" />
      <div className="NoResults_Title">There is nothing here</div>
      <div className="NoResults_Title__subTitle">Clear the search field and filter or try to add a new Vendor.</div>
    </div>
  );
};

export const NoProjectsComponent = () => {
  const staticData = useStaticDataQuery();
  const logoLink = staticData ? staticData.Empty.projects : "";

  return (
    <div className="NoResults NoResults_projects">
      <img src={logoLink} alt="Projects are empty" />
      <div className="NoResults_Title">There are no Projects</div>
      <div className="NoResults_Title__subTitle">Clear the search field and filter or try to add a new Project.</div>
    </div>
  );
};

export const NoCustomersComponent = () => {
  const staticData = useStaticDataQuery();
  const logoLink = staticData ? staticData.Empty.customers : "";

  return (
    <div className="NoResults NoResults_vendors">
      <img className="NoResults_VendorImg" src={logoLink} alt="Vendors are empty" />
      <div className="NoResults_Title">There are no customers</div>
      <div className="NoResults_Title__subTitle">Try to add one.</div>
    </div>
  );
};
export const NoVisitsComponent = () => {
  const staticData = useStaticDataQuery();
  const logoLink = staticData ? staticData.Empty.visits : "";

  return (
    <div className="NoResults NoResults_vendors">
      <img className="NoResults_VendorImg" src={logoLink} alt="Vendors are empty" />
      <div className="NoResults_Title">There are no visits</div>
      <div className="NoResults_Title__subTitle">Try to add one.</div>
    </div>
  );
};
