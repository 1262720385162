import { ActivityScheduleModel } from "../../../../redux/models/data/ActivityScheduleModel";
import { getDateWithUpdatedTime } from "../../../../_utils/date";

export class ActivityModelRelated {
  Id: string;
  AssetId: string;
  Name: string;
  StatusId: string;
  Schedule: ActivityScheduleModel;
  RelatedDueDateTime: string[];
  CompletedDateTime: string | null;
  DueDateTime: string | null;
  isLoading: boolean;

  constructor({
    Id = "",
    AssetId = "",
    Name = "",
    StatusId = "",
    Schedule = new ActivityScheduleModel(),
    RelatedDueDateTime = [],
    DueDateTime = "",
    CompletedDateTime = "",
    isLoading = false,
  } = {}) {
    this.Id = Id;
    this.AssetId = AssetId;
    this.Name = Name;
    this.StatusId = StatusId;
    this.Schedule = new ActivityScheduleModel(Schedule);
    this.RelatedDueDateTime = RelatedDueDateTime || [];
    this.DueDateTime = DueDateTime ? getDateWithUpdatedTime(DueDateTime) : null;
    this.CompletedDateTime = CompletedDateTime ? getDateWithUpdatedTime(CompletedDateTime) : null;
    this.isLoading = isLoading || false;
  }
}
