import styled from "styled-components";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ListItemIcon, ListItemText, MenuItem } from "@material-ui/core";

export const MoreIconStyled = styled(MoreVertIcon)`
  font-size: 1rem;
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ListItemTextStyled = styled(ListItemText)`
  .MuiTypography-root {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  height: 2.25rem;
`;
