import styled from "styled-components";

export const FloatingInputStyles = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;
