import * as React from "react";
import * as moment from "moment";
import { useState, useEffect } from "react";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { formatDate, setCurrentTime } from "../_utils/date";
import { SCREEN_XS_MAX_SIZE } from "../_constant/constants";
import { useWindowSize } from "../_utils/useWindowSize.hook";

interface CalendarPropsTypes {
  handleChange: (date: string) => void;
  initValue: string;
  blockDatesAfterToday?: boolean;
  numberOfMonths: 1 | 2;
  isDayHighlighted?: any;
  showCurrentMonthRight?: boolean;
  minDate?: string;
}

const DAY_SIZE_DEFAULT = 38;
const DAY_SIZE_SMALL = 34;
const SINGLE_MONTH_VIEW = 1;
const DOUBLE_MONTH_VIEW = 2;

export const Calendar: React.FC<CalendarPropsTypes> = (props) => {
  const { handleChange, initValue, blockDatesAfterToday, numberOfMonths, isDayHighlighted } = props;
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(true);
  const todayDate = moment();
  const [windowSize] = useWindowSize();

  useEffect(() => {
    // component receive new props
    onDateChange(formatDate(initValue));
  }, [initValue]);

  const onDateChange = (value) => {
    const parsedValue = setCurrentTime(value);

    if (parsedValue?.isValid()) {
      setDate(parsedValue);
      handleChange(parsedValue.toISOString(true));
    } else {
      setDate(null);
    }
  };

  const onFocusChange = () => {
    setFocused(true);
  };

  const generateKey = (value) => {
    return value ? `${date.month()}${date.year()}` : `${todayDate.month()}${todayDate.year()}`;
  };

  const correctMonth = (date) => {
    date.set({ month: date.month() - 1 });
    return date;
  };

  const initialMonth = () => {
    // used to display current month in right side in CopletedDate popup
    const dateCopy = moment(date);
    if (props.showCurrentMonthRight && date && date.isValid()) {
      return correctMonth(dateCopy);
    }
    if (props.showCurrentMonthRight) {
      return correctMonth(moment(todayDate));
    }
    return date ? dateCopy : todayDate;
  };

  const setNumberOfMonthByViewWidth = (numberOfMonth) => {
    if (numberOfMonth === SINGLE_MONTH_VIEW) {
      return numberOfMonth;
    }
    return windowSize > SCREEN_XS_MAX_SIZE ? DOUBLE_MONTH_VIEW : SINGLE_MONTH_VIEW;
  };

  const dayBlockedHandle = (day) => {
    if (blockDatesAfterToday) {
      return day.isAfter(moment(), "day");
    }

    if (props.minDate) {
      return day.isBefore(moment(props.minDate), "day");
    }

    return false;
  };

  return (
    <div
      className={`Calendar${
        setNumberOfMonthByViewWidth(numberOfMonths) === 2 ? " Calendar--separated" : ""
      }`}
    >
      <DayPickerSingleDateController
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        focused={focused}
        date={date}
        numberOfMonths={setNumberOfMonthByViewWidth(numberOfMonths)}
        daySize={windowSize > SCREEN_XS_MAX_SIZE ? DAY_SIZE_DEFAULT : DAY_SIZE_SMALL}
        hideKeyboardShortcutsPanel
        isDayBlocked={dayBlockedHandle}
        isDayHighlighted={isDayHighlighted}
        key={generateKey(date)}
        initialVisibleMonth={initialMonth}
      />
    </div>
  );
};
