import React, { useState } from "react";

import { dataApiInterface } from "../../customHooks/useChange";

import { VendorModel } from "../../redux/models/data/VendorModel";
import { Image } from "../../redux/models/data/ImageModel";
import { FetchPolicy } from "apollo-client";

interface VendorFormContextProviderProps {
  handleSubmit: (e?: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
  isLoading: boolean;
  isSaving: boolean;
  isFetching: boolean;
  isNew: boolean;
  data: VendorModel;
  setHeaderOptions: (props: any) => void;
  lockSaveButton: boolean;
  formData: VendorModel;
  formApi: dataApiInterface<VendorModel>;
  handleChangeWithValidation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  phoneError: string;
  emailError: string;
  websiteError: string;
  dropZone: any;
}

interface VendorFormProps extends VendorFormContextProviderProps {
  isCustomize: boolean;
  handleCustomize: () => void;
  handleOpenWizard: () => void;
  handleCloseWizard: () => void;
  setIsRevertPopupVisible: (value: boolean) => void;
  setIsDeletePopupVisible: (value: boolean) => void;
  isRevertPopupVisible: boolean;
  isDeletePopupVisible: boolean;
  isWizardOpen: boolean;
  tabFetchPolicy: FetchPolicy;
  setCachePolicyForTab: (policy: FetchPolicy) => void;
}

export const VendorFormContext = React.createContext<VendorFormProps>({} as VendorFormProps);

export const VendorFormContextProvider: React.FC<VendorFormContextProviderProps> = (props) => {
  const { children, ...rest } = props;
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCustomize, setIsCustomize] = useState(false);
  const [tabFetchPolicy, setTabFetchPolicy] = useState<FetchPolicy>("network-only");
  const handleCustomize = React.useCallback(() => setIsCustomize((prev) => !prev), []);

  const handleOpenWizard = React.useCallback(() => setIsWizardOpen(true), [isWizardOpen]);
  const handleCloseWizard = React.useCallback(() => setIsWizardOpen(false), [isWizardOpen]);
  const setCachePolicyForTab = React.useCallback(
    (policy: FetchPolicy) => {
      setTabFetchPolicy(policy);
    },
    [setTabFetchPolicy],
  );

  return (
    <VendorFormContext.Provider
      value={{
        ...rest,
        isCustomize,
        handleCustomize,
        isRevertPopupVisible,
        isWizardOpen,
        handleOpenWizard,
        handleCloseWizard,
        isDeletePopupVisible,
        setIsRevertPopupVisible,
        setIsDeletePopupVisible,
        tabFetchPolicy,
        setCachePolicyForTab,
      }}
    >
      {children}
    </VendorFormContext.Provider>
  );
};
