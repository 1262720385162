import * as React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {alphabetNameSorting, getAssetImage} from "../../../_utils/utils";
import {useStaticDataQuery} from "../../../queries/useStaticDataQuery";

interface SubmenuProps {
  className: string;
  selectedKey: string;
  selectedAssets: Set<string>;
  items: Map<string, object[]>;
  assetTypes: any[];
  onClick: (type: string, selected: Set<string>) => void;
}

const Submenu: React.FC<SubmenuProps> = (props) => {
  const [selectedItems, setItems] = useState(new Set(props.selectedAssets));

  const staticData = useStaticDataQuery();

  useEffect(() => {
    setItems(props.selectedAssets);
  }, [props.selectedAssets]);

  const handleClick = ({target}) => {
    const itemId = target.getAttribute("data-item");
    const assetTypeId = target.getAttribute("data-item-type");
    const nextItemsSet = new Set(selectedItems);
    
    if (nextItemsSet.has(itemId)) {
      nextItemsSet.delete(itemId);
      
    } else {
      if (props.selectedKey !== assetTypeId) {
        nextItemsSet.clear();
      } 
      if(nextItemsSet.size) {
        nextItemsSet.clear();
        nextItemsSet.add(itemId);
      } else {
        nextItemsSet.add(itemId);
      }
    }
    setItems(nextItemsSet);
    props.onClick(assetTypeId, nextItemsSet);
  };

  const renderSubmenuItems = (assets, assetType, assetName) => {
    return assets.sort(alphabetNameSorting).map(({Id, Name, ImageName, ImageUrl}) => {
      const image = ImageUrl
        ? <img src={ImageUrl} alt={ImageName} className={"Image"}/>
        : <img className="Image-default" src={getAssetImage(staticData, assetName)} alt={assetName}/>;

      const className = `Submenu_item ${selectedItems.has(Id) ? "is-selected" : ""}`;
      return (
        <div className={className} key={Id} data-item-type={assetType} data-item={Id} title={Name} /*onClick={handleClick}*/>
          <div className={"Submenu_item_img"}>{image}</div>
          <div className={"Submenu_item_label"}>{Name}</div>
        </div>
      );
    });
  };

  return (
    <div className={`Submenu ${props.className}`}>
      {
        props.assetTypes
          .filter(assetType => (!!props.items.get(assetType.Id)))
          .map(assetType => (
            <div key={assetType.Id}>
              <div className={"Submenu_header"}>
                <div className={"Submenu_title"}>
                  <span className={"Submenu_titleName"} title={assetType.Name}>{assetType.Name}</span> Items
                </div>
                {/*{!value.IsSystem &&*/}
                {/*<div className={"Submenu_iconChange"} onClick={() => {}} title={`Change ${value.Name} type icon`}>*/}
                {/*<i className={"Icon Icon-edit"}/>*/}
                {/*</div>}*/}
              </div>
              <div className={"Submenu_items"}>
                {renderSubmenuItems(props.items.get(assetType.Id), assetType.Id, assetType.Name)}
              </div>
            </div>
          ))
      }
    </div>
  );
};

export default Submenu;
