import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import {
  ActionButton,
  LoaderStyled,
  BorderLoader,
  IconButtonStyled,
} from "./SmartFieldButton.style";

interface SmartFieldButton {
  loading: boolean;
  handleClick: () => void;
}

const SmartFieldButton: React.FC<SmartFieldButton> = (props) => {
  const { loading, handleClick } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  if (isMobile) {
    return (
      <IconButtonStyled color="primary" onClick={handleClick} disabled={loading}>
        <VisibilityOutlinedIcon />
        {loading && <BorderLoader size={30} />}
      </IconButtonStyled>
    );
  }

  return (
    <ActionButton
      variant="text"
      color="primary"
      onClick={handleClick}
      startIcon={<VisibilityOutlinedIcon />}
      disabled={loading}
    >
      Look up
      {loading && <LoaderStyled size={18} />}
    </ActionButton>
  );
};

export default SmartFieldButton;
