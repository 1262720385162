import React from "react";
import { SelectProps } from "@material-ui/core";

import { SelectContainer, SelectMedia, StyledSelect } from "./SelectStyles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

export type alignmentTypes = "start" | "center" | "end";

interface SelectComponentProps {
  label?: string;
  helperText?: string;
  withoutUnderline?: boolean;
  media?: React.ReactElement;
  alignMedia?: alignmentTypes;
  disableMediaGutters?: boolean;
  withoutControl?: boolean;
}

const Select: React.FC<SelectComponentProps & SelectProps> = ({
  alignMedia = "end",
  disableMediaGutters = false,
  helperText,
  withoutUnderline,
  media,
  label,
  children,
  withoutControl,
  ...restProps
}) => {
  return (
    <SelectContainer>
      {media && (
        <SelectMedia
          alignment={alignMedia}
          disableGutters={disableMediaGutters}
          withoutControl={withoutControl}
        >
          {media}
        </SelectMedia>
      )}
      {!withoutControl && (
        <FormControl fullWidth>
          {label && <InputLabel>{label}</InputLabel>}
          <StyledSelect
            MenuProps={{
              MenuListProps: {
                disablePadding: true,
              },
            }}
            withoutUnderline={withoutUnderline}
            {...restProps}
          >
            {/* Pass <MenuItem> as children (Use <SelectItem> for default styling)  */}
            {children}
          </StyledSelect>
          {helperText && <FormHelperText>Some important helper text</FormHelperText>}
        </FormControl>
      )}
    </SelectContainer>
  );
};

export default Select;
