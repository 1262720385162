import React from "react";
import { AccordionLabel } from "../../pages/Notifications/NotificationsStyle";
import Switch from "@material-ui/core/Switch";
import { PERSONAL } from "../../_constant/wordings";
import NotificationCard, { NotificationModelType } from "../NotificationCard/NotificationCard";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Accordion from "../Accordion";
import { AccountModel } from "../../redux/models/data/AccountModel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutation } from "@apollo/react-hooks";
import { postNotificationSettings } from "../../pages/Notifications/Notifications.query";
import useGetUserId from "../../customHooks/useGetUserId";
import { updateCacheWithNewNotifications } from "../../pages/Notifications/updateCacheWithNewNotification";
import { generateAmPm, getWeekDays } from "../../_utils/date";
import { useQuery } from "react-apollo";
import { getUserInfoQuery } from "../../containers/profile/profile.query";
import { getNotificationLabels } from "../NotificationCard/NotificationLabels.query";
import { AccountNotificationSettingsContainer } from "./AccountNotificationSettings.style";

interface Props {
  account: AccountModel;
  notifications: NotificationModelType[];
  loading: boolean;
}

const convertTitleToValue = (title: string) => {
  return title.toUpperCase().replaceAll(" ", "_");
};

const AccountNotificationSettings: React.FC<Props> = ({ account, notifications, loading }) => {
  const userId = useGetUserId();
  const { data, loading: load, error } = useQuery(getUserInfoQuery, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });
  const { data: labels, loading: labelsLoading, error: labelsError } = useQuery(
    getNotificationLabels,
  );
  const frequency =
    labels &&
    labels.SystemData.NotificationFrequencyDefinitions.map((item: any) => ({
      id: item.Id,
      key: item.Value,
      value: item.Title,
    }));
  const types =
    labels &&
    labels.SystemData.NotificationTypeDefinitions.map((item: any) => ({
      allowedFrequencies: item.AllowedFrequencies.map((el: any) => el.Id),
      key: item.Value,
      value: item.Title,
    }));

  const [addNotification, { data: mutationData, loading: addNotificationInProcess }] = useMutation(
    postNotificationSettings,
    {
      variables: {
        Setting: {
          AccountId: account.Id,
          UserId: userId,
          IsEnabled: true,
          Type: "ACTIVITIES_DUE",
          Frequency: "DAILY",
          Time: "1:00 am",
          NotificationChannel: {
            Source: "EMAIL",
            Destination: data.User.Email,
          },
        },
      },
      update(cache, { data: NotificationSettingsPost }) {
        updateCacheWithNewNotifications(cache, NotificationSettingsPost, userId!);
      },
    },
  );

  const addNotificationHandler = () => {
    if (!userId) return;
    addNotification();
  };
  const contentLoading = () => (
    <div
      style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress color="primary" size={30} />
    </div>
  );

  return (
    <AccountNotificationSettingsContainer>
      <Accordion
        key={account.Id}
        label={
          <>
            {account.Name}{" "}
            <span className="Label">
              {" "}
              {notifications.length > 0 ? `(${notifications.length})` : null}
            </span>
          </>
        }
        withBackground={false}
        withExpandIcon={true}
        expanded={account.Name === PERSONAL}
      >
        {loading || labelsLoading
          ? contentLoading()
          : notifications.map((notification) => {
              return (
                <NotificationCard
                  key={notification.Id}
                  notification={notification}
                  accountId={account.Id}
                  userId={userId || ""}
                  frequencyLabels={frequency}
                  typesLabels={types}
                  isPersonalAccountNotifications={account.Name === PERSONAL}
                />
              );
            })}

        {!loading && userId && (
          <Box display="flex" justifyContent="flex-end" style={{ margin: "12px 0" }}>
            <Button
              color="primary"
              endIcon={<AddCircleOutlineIcon />}
              onClick={addNotificationHandler}
              disabled={addNotificationInProcess || !data}
            >
              Add New
            </Button>
          </Box>
        )}
      </Accordion>
    </AccountNotificationSettingsContainer>
  );
};

export default AccountNotificationSettings;
