import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-apollo";
import { useNavigate } from "react-router-dom";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import useGetAccountId from "../../customHooks/useGetAccountId";

import { Image } from "../../redux/models/data/ImageModel";

import { userRefFragment } from "../AssignUserSelect/getUserRefFragment.query";

import { ROUTES } from "../../_constant/screens";
import { EMPTY_ID } from "../../_constant/constants";

import { addCustomerIdToUrl, composeImageUrl, isRecurring } from "../../_utils/utils";
import { formatDate } from "../../_utils/date";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import Avatar from "@material-ui/core/Avatar";
import { Box, Grid, Typography } from "@material-ui/core";

import { LoaderCircle } from "../Loader";
import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";
import { ActivityItemDate } from "../../containers/activities/_components/item/ActivityItemDate";

import { CardContainer, CardParent } from "../Cards/styled/card.styled";
import MoreAction, { MoreActionOptions } from "../MoreAction/MoreAction";
import DeleteActivityPopup from "../../containers/activities/_components/form/DeleteActivityPopup";

interface ActivityCardMobileProps {
  activity: any; // ActivityModelShort | ActivityModelRelated
  dateType: string;
  status?: string;
  disableNavigationByClick?: boolean;
  style?: {};
  isDraggable?: boolean;
  customerId?: string | null;
  withMoreAction?: boolean;
}

const StyledAssignIcon = styled.div`
  .MuiAvatar-root {
    width: 24px;
    height: 24px;
    font-size: 14px;
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const ActivityCardMobile: React.FC<ActivityCardMobileProps> = (props) => {
  const {
    activity,
    dateType,
    status,
    customerId,
    isDraggable,
    style,
    disableNavigationByClick,
    withMoreAction,
  } = props;
  const { Name, Schedule, RelatedDueDateTime, isLoading, Asset, AssignedUserId } = activity;
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const openDeleteActivityDialog = useCallback(() => setIsDeletePopupVisible(true), []);
  const closeDeleteActivityDialog = useCallback(() => setIsDeletePopupVisible(false), []);

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: openDeleteActivityDialog,
    },
  ];

  const iconClassName = isRecurring({ schedule: Schedule, relatedDueDate: RelatedDueDateTime })
    ? "ActivityItem_recurring"
    : "";

  const navigate = useNavigate();

  const staticData = useStaticDataQuery();

  const defaultUserIcon = staticData ? staticData.User.User_default_icon : "";

  const { accountId, isPersonal } = useGetAccountId();

  const { data: accountUsers, loading: usersLoading, error: usersError, client } = useQuery(
    getAccountUsersQuery,
    {
      variables: { Id: accountId },
      skip: !accountId || !!isPersonal,
      fetchPolicy: "cache-first",
    },
  );
  const createImgUrl = (image: Image) => {
    if (image) {
      return composeImageUrl(image.Id, image.ContainerName, image.ContainerId);
    } else {
      return "";
    }
  };

  const getCurrentAssignedUserAvatar = (id: string | null) => {
    if (id && id !== EMPTY_ID && accountUsers) {
      const user = client.readFragment({ fragment: userRefFragment, id: id });
      return user && user.MainImage ? createImgUrl(user.MainImage) : "";
    } else {
      return defaultUserIcon;
    }
  };

  const getCurrentAssignedUserName = (id: string | null) => {
    if (id && accountUsers) {
      const user = client.readFragment({ fragment: userRefFragment, id: id });
      return user ? `${user.FirstName} ${user.LastName}`.trim() : "";
    } else {
      return "";
    }
  };

  const goToActivity = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let path = `${ROUTES.SINGLE_ACTIVITY}/${activity.Id}`;
    path = customerId ? addCustomerIdToUrl(path, customerId) : path;
    navigate(path);
  };

  return (
    <>
      <CardContainer
        padding={1}
        style={isDraggable ? style : { borderBottom: "1px solid #eeeeee" }}
        borderRadius={isDraggable ? "8px" : "0"}
        onClick={disableNavigationByClick ? undefined : goToActivity}
        withLabel={status}
      >
        {isLoading && (
          <div className={`ActivityItem_loader`}>
            <LoaderCircle size={"xs"} />
          </div>
        )}
        <Grid container spacing={1} alignItems="center">
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body2" component="h3" noWrap>
                {Name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {!isPersonal && (
                  <StyledAssignIcon>
                    <Avatar
                      color="primary"
                      src={getCurrentAssignedUserAvatar(AssignedUserId)}
                      style={{ width: "16px", height: "16px", marginRight: 8 }}
                    >
                      {getCurrentAssignedUserName(AssignedUserId).charAt(0)}
                    </Avatar>
                  </StyledAssignIcon>
                )}
                {withMoreAction && <MoreAction options={options} />}
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={6}>
              {Asset?.Name && (
                <CardParent alignItems="start">
                  <span>FOR </span>
                  {Asset.Name}
                </CardParent>
              )}
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ActivityItemDate
                  activityDate={formatDate(activity[dateType])}
                  dateType={dateType}
                  iconOnly={false}
                />
                <Box paddingLeft="9px">
                  <i className={`Icon ${iconClassName}`}>repeat</i>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContainer>
      {isDeletePopupVisible && (
        <DeleteActivityPopup
          activity={activity}
          isDeletePopupVisible={isDeletePopupVisible}
          handleCancel={closeDeleteActivityDialog}
          customerId={customerId}
          withoutRedirect
        />
      )}
    </>
  );
};

export default ActivityCardMobile;
