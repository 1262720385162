import React from "react";
import styled from "styled-components";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { Box, Button, Typography } from "@material-ui/core";

export const PopoverContainer = styled.section`
  max-width: 340px;
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const PopoverText = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
`;

export const PopoverTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const PopoverButton = styled(Button)`
  &.MuiButton-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: flex;
  }
`;

export const AboutPagePopoverButtonMobile: React.FC = () => {
  return <Button variant="text" startIcon={<InfoOutlinedIcon />} color="inherit" />;
};

export const AboutPagePopoverButton: React.FC = () => {
  return (
    <PopoverButton variant="text" startIcon={<InfoOutlinedIcon />} color="primary">
      What is a Calendar Feed?
    </PopoverButton>
  );
};

interface AboutPagePopoverContentProps {
  handleClose: VoidFunction;
}

export const AboutPagePopoverContent: React.FC<AboutPagePopoverContentProps> = ({
  handleClose,
}) => {
  return (
    <PopoverContainer>
      <PopoverTitle>What is a Calendar Feed?</PopoverTitle>
      <PopoverText>
        A calendar feed is a URL you can add to your calendar app that feeds the list of Upkeepr
        activities right to your calendar. Set the filter to get only the activities you want. Then,
        copy the link and add it to the Internet Calendar settings of your calendar app.
      </PopoverText>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" size="small" color="primary" onClick={handleClose}>
          Got it
        </Button>
      </Box>
    </PopoverContainer>
  );
};
