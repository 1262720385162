import * as React from "react";
import { ItemCustomFieldsTypes } from "./ItemCustomFieldsTypes";
import AddCustomField from "../AddCustomFieldContainer/AddCustomField";
import { Collapse } from "@material-ui/core";
import CustomFieldsList from "../CustomFieldsList/CustomFieldsList";
import CustomFieldsListCustomize from "../CustomFieldsList/CustomFieldsListCustomize";
import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";
import CustomFieldsLoadingState from "../CustomFieldsLoadingState/CustomFieldsLoadingState";

const ItemCustomFieldsContainer: React.FC<ItemCustomFieldsTypes> = ({
  CustomFields,
  Schema,
  onChange,
  isCustomize,
  entityName,
  isLoading,
}) => {
  const emptyCustomFieldsText = `Flip the switch to add\ncustom ${entityName} Info`;

  return (
    <>
      {isCustomize && (
        <AddCustomField Schema={Schema} CustomFields={CustomFields} onChange={onChange} />
      )}

      <Collapse in={Schema.length > 0} timeout="auto" unmountOnExit>
        <section className={"Section"}>
          {isCustomize ? (
            <CustomFieldsListCustomize
              Schema={Schema}
              CustomFields={CustomFields}
              onChange={onChange}
            />
          ) : (
            <CustomFieldsList Schema={Schema} CustomFields={CustomFields} onChange={onChange} />
          )}
        </section>
      </Collapse>
      {isLoading && <CustomFieldsLoadingState />}
      {!isLoading && !Schema.length ? (
        <EmptyCustomFieldState emptyFieldsMessage={emptyCustomFieldsText} />
      ) : null}
    </>
  );
};

export default ItemCustomFieldsContainer;
