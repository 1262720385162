const Icon = new Set([
  "edit",
  "colorize",
  "brush",
  "palette",
  "account_balance_wallet",
  "attach_money",
  "euro_symbol",
  "credit_card",
  "alarm",
  "calendar_today",
  "schedule",
  "book",
  "build",
  "extension",
  "eco",
  "local_florist",
  "spa",
  "wb_sunny",
  "wb_cloudy",
  "nights_stay",
  "flash_on",
  "ac_unit",
  "sports_volleyball",
  "sports_tennis",
  "sports_soccer",
  "sports_rugby",
  "sports_motorsports",
  "sports_mma",
  "fitness_center",
  "sports_basketball",
  "sports_baseball",
  "sports",
  "sports_golf",
  "golf_course",
  "sports_kabaddi",
  "sports_hockey",
  "sports_handball",
  "sports_football",
  "sports_esports",
  "sports_cricket",
  "pool",
  "store",
  "museum",
  "home_work",
  "storefront",
  "emoji_transportation",
  "apartment",
  "local_taxi",
  "call",
  "mail",
  "phone_iphone",
  "phone_android",
  "smartphone",
  "laptop",
  "laptop_windows",
  "laptop_mac",
  "devices",
  "mouse",
  "speaker",
  "headset",
  "scanner",
  "camera_alt",
  "camera",
  "camera_roll",
  "whatshot",
  "vpn_key",
  "save",
  "inbox",
  "waves",
  "local_grocery_store",
  "emoji_objects",
  "school",
  "public",
  "photo",
  "poll",
  "room_service",
  "cake",
  "filter_vintage",
  "deck",
  "format_paint",
  "flag",
  "emoji_events",
  "flight",
  "tram",
  "subway",
  "traffic",
  "local_gas_station",
  "local_cafe",
  "radio",
  "wc",
  "emoji_people",
  "directions_run",
  "face",
  "sentiment_satisfied",
  "sentiment_dissatisfied",
  "mood",
  "child_care",
  "favorite_border",
  "grade",
  "pets",
  "beach_access",
  "bathtub",
  "hotel",
  "healing",
  "outdoor_grill",
  "hot_tub",
  "kitchen",
  "local_car_wash",
  "directions_bus",
  "directions_transit",
  "local_shipping",
  "airport_shuttle",
  "directions_bike",
  "motorcycle",
  "directions_car",
  "directions_boat",
  "watch",
  "videogame_asset",
  "tv",
  "toys",
  "notifications",
  "chat",
  "list_alt",
  "home",
  "exposure",
  "attach_file",
  "all_inclusive",
  "folder",
  "casino",
  "local_laundry_service",
  "child_friendly",
  "local_mall",
  "map",
  "shutter_speed",
  "business_center",
  "business",
  "local_play",
  "menu_book",
  "king_bed",
  "local_bar",
  "fastfood",
  "local_pizza",
  "local_dining",
  "restaurant",
  "local_drink",
  "music_video",
  "movie",
  "local_printshop",
  "meeting_room",
  "explore",
  "local_offer",
]);

export default Icon;
export const DEFAULT_ICON = "mood";
