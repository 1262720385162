import styled from "styled-components";

export const LayoutSwitcherComponent = styled.div`
  .MuiButtonGroup-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .MuiButton-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.secondary};
    border: ${({ theme }) => `1px solid ${theme.palette.grey[200]}`};
    width: 2.5rem;
  }

  .MuiButtonGroup-grouped {
    min-width: 10px;
  }

  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
