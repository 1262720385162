import * as React from "react";
import { CalendarDialog } from "../../../../../_common/Dialog";
import InputFieldWithCalendar from "./recurring/InputFieldWithCalendar";
import Button from "../../../../../../components/Button";
import { useState } from "react";
import { ChangeEvent } from "react";
import { Portal } from "@material-ui/core";

interface NestedCalendarDialogProps {
  value: string;
  onChange: (value: string) => void;
  handleBlur: (e: ChangeEvent) => void;
  hasDueDateError?: boolean;
  label: string;
  name: string;
  className?: string;
  handleClose: () => void;
  dialogTitle: string;
  minDate?: string;
}

const NestedCalendarDialog: React.FC<NestedCalendarDialogProps> = (
  props: NestedCalendarDialogProps,
) => {
  const { hasDueDateError, label, name, className = "", handleClose, dialogTitle, minDate } = props;

  const [innerValue, setInnerValue] = useState(props.value);

  const handleSubmit = () => {
    props.onChange(innerValue);
    handleClose();
  };

  const handleChange = (date: string) => {
    setInnerValue(date);
  };

  const handleBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(target.value);
  };

  return (
    <Portal>
      <div className="CalendarDialog">
        <CalendarDialog cancel={handleClose} submit={handleSubmit}>
          <header className="CalendarDialog_header">
            <Button type={"link"} onClick={handleClose} icon={"arrow_back"} />
            <span>{dialogTitle}</span>
          </header>
          <div className={"CalendarDialog_content"}>
            <InputFieldWithCalendar
              value={innerValue}
              onChange={handleChange}
              handleBlur={handleBlur}
              hasFieldError={!innerValue && !!hasDueDateError}
              label={label}
              name={name}
              className={className}
              numberOfMonth={2}
              minDate={minDate}
            />
          </div>
        </CalendarDialog>
      </div>
    </Portal>
  );
};

export default NestedCalendarDialog;
