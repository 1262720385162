import * as React from "react";

import { useState, useEffect } from "react";
import { useQuery } from "react-apollo";

import { AccountModel } from "../redux/models/data/AccountModel";

import { getAccountRefsList } from "../pages/Accounts/getAccountList.query";

import { ACCOUNT_REFS, PERSONAL, USER } from "../_constant/wordings";

const usePersonalAccount = () => {
  const { data: accountList, loading: accountsLoading, error: accountsError } = useQuery(
    getAccountRefsList,
    {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
  );
  const [personalAccountId, setPersonalAccountId] = useState("");

  useEffect(() => {
    if (!accountsLoading && !accountsError && accountList) {
      const account: AccountModel = accountList[USER][ACCOUNT_REFS].find(
        (element: AccountModel) => element.Name === PERSONAL,
      );
      setPersonalAccountId(account.Id);
    }
  }, [accountList]);

  return {
    accountsLoading,
    personalAccountId,
  };
};

export default usePersonalAccount;
