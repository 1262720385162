import React, { useEffect, useState } from "react";

import { useStaticDataQuery } from "../../../queries/useStaticDataQuery";
import { useGetAssetTypeDetails } from "../../../customHooks/useGetAssetTypesDescriptionPattern";

import { ItemType } from "./types";

import { composeImageUrl, getAssetImage } from "../../../_utils/utils";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";

import { Collapse } from "@material-ui/core";
import {
  CardContainer,
  CardContent,
  StyledAvatar,
  CardHeader,
  CardTitle,
  SubTitleContaineer,
  TypeIconStyled,
  SubTitle,
  DescriptionStyled,
  CardAdditionalInfo,
  DataValues,
  ItemAdditionInfo,
  CardFooter,
  AvatarContainer,
} from "./ItemPrintableCardMobile.style";

import CountInfoRow from "../CountInfoRow";
import TypeFieldRow from "../TypeFieldsRow";
import MoreInfoButton from "../MoreInfoButton";

interface ItemPrintableCardMobileProps {
  item: ItemType;
  isOpenedMoreInfo: boolean;
  toggleExpand: () => void;
}
const ItemPrintableCardMobile: React.FC<ItemPrintableCardMobileProps> = ({
  item,
  isOpenedMoreInfo,
  toggleExpand,
}) => {
  // const [isActive, setActive] = useState(false);

  const staticData = useStaticDataQuery();
  const { getDetailsById } = useGetAssetTypeDetails({ onlyCache: true });

  const MainImage = item?.Images?.length && item.Images[0];
  const description = item?.Description?.length ? item.Description : item?.DefaultDescription;
  const filesCount =
    (item.Documents?.length ?? 0) + (item.Videos?.length ?? 0) + (item.Images?.length ?? 0);
  const activitiesCount = item?.AssetActivities?.length;
  const customFieldsCount = item?.Schema?.length ?? 0;

  const typeFieldValues = item.TypeFieldValues ?? {};
  const { itemTypeField } = getDetailsById(item?.AssetType?.Id);
  const isTypeFieldsExist = !!Object.values(typeFieldValues).filter((value) => !!value).length;

  // useEffect(() => {
  //   setActive(() => isOpenedMoreInfo);
  // }, [isOpenedMoreInfo]);

  return (
    <CardContainer>
      <CardContent>
        <AvatarContainer $withouBackground={!!MainImage}>
          <StyledAvatar
            src={
              MainImage
                ? composeImageUrl(MainImage.Id, MainImage.ContainerName, MainImage.ContainerId)
                : getAssetImage(staticData, item?.AssetType?.Name ?? "")
            }
          />
        </AvatarContainer>

        <CardHeader>
          <CardTitle>{item.Name}</CardTitle>
          <SubTitleContaineer>
            <TypeIconStyled className="Icon">{item?.AssetType?.IconName}</TypeIconStyled>
            <SubTitle>{item?.AssetType?.Name}</SubTitle>
          </SubTitleContaineer>
        </CardHeader>
        {!!description && (
          <DescriptionStyled $withoutClip={isOpenedMoreInfo}>{description}</DescriptionStyled>
        )}
        <Collapse in={isOpenedMoreInfo} timeout="auto" unmountOnExit>
          <CardAdditionalInfo>
            <DataValues>
              <CountInfoRow
                icon={<CheckCircleIcon />}
                value={activitiesCount ?? 0}
                text="Activities"
                outerPadding={16}
              />
              <CountInfoRow
                icon={<DescriptionIcon />}
                value={filesCount}
                text="Files"
                outerPadding={16}
              />
              <CountInfoRow
                icon={<EditIcon />}
                value={customFieldsCount}
                text="Custom Fields"
                outerPadding={16}
              />
            </DataValues>
            {isTypeFieldsExist && (
              <ItemAdditionInfo>
                {Object.keys(typeFieldValues)
                  .filter((key) => key !== "__typename")
                  .map((key) => {
                    const typeField = itemTypeField.find((typeField) => typeField.Name === key);
                    const value =
                      typeFieldValues[key] && typeof typeFieldValues[key] === "object"
                        ? typeFieldValues[key]?.value
                        : typeFieldValues[key];

                    return (
                      value && (
                        <TypeFieldRow
                          fieldType={typeField?.FieldType}
                          title={key}
                          value={value}
                          key={value}
                          outerPadding={16}
                        />
                      )
                    );
                  })}
              </ItemAdditionInfo>
            )}
          </CardAdditionalInfo>
        </Collapse>
      </CardContent>

      <CardFooter>
        <MoreInfoButton isActive={isOpenedMoreInfo} handlerClick={toggleExpand} fullWidth />
      </CardFooter>
    </CardContainer>
  );
};

export default ItemPrintableCardMobile;
