import styled from "styled-components";
import { Box } from "@material-ui/core";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { scrollStyles } from "../../_styled/scrollStyles";

export const ActivityScreenContainer = styled((props) => (
  <Box flexDirection="column" display="flex" {...props} />
))`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TypeAndItemContainer = styled(Breadcrumbs)(
  (props) => `
  margin: 12px 0 24px 16px ;
`,
);

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  ${scrollStyles};
`;
