import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { CustomerModel } from "../../redux/models/data/CustomersModel";

import { ACCOUNT, CUSTOMERS } from "../../_constant/wordings";
import { getCustomersQuery } from "../../components/Customers/customersList.query";

export const updateCacheWithNewCustomer = (
  cache: DataProxy,
  customer: CustomerModel,
  accountId: string,
) => {
  try {
    const customerListQuery: any = cache.readQuery({
      query: getCustomersQuery,
      variables: { Id: accountId },
    });
    const newCustomer: any = { ...customer };
    delete newCustomer["AccountId"];

    const newCusomerList = customerListQuery[ACCOUNT][CUSTOMERS].concat([newCustomer]);

    cache.writeQuery({
      query: getCustomersQuery,
      variables: { Id: accountId },
      data: { Account: { Customers: newCusomerList, Id: accountId, __typename: ACCOUNT } },
    });
  } catch (e) {
    console.error(e);
  }
};
