import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 1rem;
  justify-content: flex-end;

  .MuiButtonGroup-contained {
    box-shadow: none;
  }
`;
