import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { GET_ACTIVITIES_RELATED_TO_ASSET } from "../../containers/projects/_components/relaitedActivities/getActivitiesRelatedToAsset.query";

export const removeActivityFromSelectCache = (
  cache: DataProxy,
  activityId: string | null | undefined,
  accountId: string | null | undefined,
  assetId: string | null | undefined,
  statuses: any[],
) => {
  try {
    const doneStatus = statuses.find((status: { Name: string }) => status.Name === "Done");

    const oldActivities: any = cache.readQuery({
      query: GET_ACTIVITIES_RELATED_TO_ASSET,
      variables: { Id: accountId, AssetId: assetId, Filter: `StatusId!="${doneStatus.Id}"` },
    });
    oldActivities.Account.Assets[0].AssetActivities = oldActivities.Account.Assets[0].AssetActivities.filter(
      (activity: { Id: string }) => activity.Id !== activityId,
    );
    cache.writeQuery({
      query: GET_ACTIVITIES_RELATED_TO_ASSET,
      variables: { Id: accountId, AssetId: assetId, Filter: `StatusId!="${doneStatus.Id}"` },
      data: oldActivities,
    });
  } catch (error) {}
};
