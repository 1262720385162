import React from "react";
import styled from "styled-components";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Button, { ButtonTypeMap } from "@material-ui/core/Button/Button";
import { ExtendButtonBase } from "@material-ui/core";

interface MoreInfoButtonStyledProps {
  $fullWidth?: boolean;
}
const MoreInfoButtonStyled = styled(Button)<MoreInfoButtonStyledProps>`
  margin-left: ${({ $fullWidth }) => ($fullWidth ? "0" : "-8px")};
  margin-top: 2px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "106px")};
  height: ${({ $fullWidth }) => ($fullWidth ? "56px" : "auto")};

  .MuiButton-label {
    justify-content: ${({ $fullWidth }) => ($fullWidth ? "flex-start" : "inherit")};
    padding-left: ${({ $fullWidth }) => ($fullWidth ? "10px" : "0")};
  }
  @media print {
    display: none;
  }
`;
interface ChevronIconStyledProps {
  $isActive: boolean;
}
const ChevronIconStyled = styled(KeyboardArrowDownIcon)<ChevronIconStyledProps>`
  transform: ${({ $isActive }) => ($isActive ? "rotate(180deg)" : "rotate(0deg)")};
  transition: 0.3s transform;
`;

interface MoreInfoButtonProps {
  isActive: boolean;
  handlerClick: () => void;
  fullWidth?: boolean;
}
const MoreInfoButton: React.FC<MoreInfoButtonProps> = ({ isActive, handlerClick, fullWidth }) => (
  <MoreInfoButtonStyled
    variant="text"
    color="primary"
    endIcon={<ChevronIconStyled $isActive={isActive} />}
    onClick={handlerClick}
    size="small"
    $fullWidth={fullWidth}
  >
    {isActive ? "Less" : "More"} Info
  </MoreInfoButtonStyled>
);

export default MoreInfoButton;
