import * as React from "react";
import { connect } from "react-redux";
import { useMutation } from "react-apollo";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import { DeleteDialog } from "../../../_common/Dialog";
import { deleteActivityQuery } from "../../../../_api/mutations";
import { NotificationTypes } from "../../../../_constant/NotificationTypes";
import {
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../../../../redux/actions/_utils";
import { ROUTES } from "../../../../_constant/screens";
import { setSelectedAssets } from "../../../../redux/actions/actions";
import {
  updateActivitiesAfterActivityRemove,
  updateFnAfterActivityRemove,
  updateRelActivitiesAfterActivityRemove,
  updateRelatedItemAfterActivityRemoved,
  updateCustomerActivitiesAfterActivityRemoved,
  updateAssetActivitiesAfterActivityDelete,
} from "./updateFnAfterActivityRemove";
import { ActivityModel } from "../../../../redux/models/data/ActivityModelFull";
import { FILTERING_DETAILS } from "../../../../_constant/ActivityDateConstant";
import { getActivityFilters } from "../../../../redux/reducers/appReducer";
import useGetAcitvitiesStatuses from "../../../../customHooks/api/useGetAcitvitiesStatuses";
import { useNavigate } from "react-router";
import { removeActivityFromProjectTabCache } from "../../../../components/ActivitiesTabProject/removeActivityFromProjectTabCache";
import { removeActivityFromSelectCache } from "../../../../components/ActivitiesTabProject/removeActivityFromSelectCache";

interface DeleteActivityPopupProps {
  activity: ActivityModel;
  statusId?: string;
  isDeletePopupVisible: boolean;
  customerId?: string | null;
  withoutRedirect?: boolean;
  handleCancel: () => void;
  setFilter: (assetType?: string, items?: Set<string>, relatedItems?) => void;
  getFilterString: (status) => string;
  projectId?: string | null;
  removeActivityFromProjectWithDelete?: (activityId: string) => void;
}

const DeleteActivityPopup = (props: DeleteActivityPopupProps) => {
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();
  const navigate = useNavigate();

  const [removeActivity, { loading: isRemoving }] = useMutation(deleteActivityQuery);
  const handleDelete = () => {
    removeActivity({
      variables: { AccountId: accountId, Id: props.activity.Id },
      update(cache, { data: { AssetActivityDelete } }) {
        if (IsCustomerManagement) {
          updateRelatedItemAfterActivityRemoved(cache, AssetActivityDelete);
          if (!props.customerId || !accountId) return;
          updateCustomerActivitiesAfterActivityRemoved({
            cache,
            AssetActivityDelete,
            CustomerId: props.customerId,
            AccountId: accountId,
          });
          return;
        }

        if (props.projectId && props.removeActivityFromProjectWithDelete && props.activity.Id) {
          removeActivityFromProjectTabCache(cache, props.activity.Id, props.projectId);
          removeActivityFromSelectCache(
            cache,
            props.activity.Id,
            accountId,
            props.activity.Asset.Id,
            statuses,
          );
          props.removeActivityFromProjectWithDelete(props.activity.Id);
        }
        const filterString = props.getFilterString(
          statuses.find((element) => element.Id === AssetActivityDelete.StatusId),
        );
        updateActivitiesAfterActivityRemove(cache, AssetActivityDelete, accountId, filterString);
        updateRelActivitiesAfterActivityRemove(cache, AssetActivityDelete, accountId);
        updateAssetActivitiesAfterActivityDelete(cache, AssetActivityDelete, accountId);

        if (AssetActivityDelete.Asset && AssetActivityDelete.Asset.AssetActivities.length === 0) {
          updateFnAfterActivityRemove(cache, AssetActivityDelete, accountId);
        }
      },
    })
      .then(() => {
        props.setFilter();

        displaySuccessNotification(NotificationTypes.ACTIVITY_DELETED);
        if (!props.withoutRedirect) {
          navigate(-1);
        }
        props.handleCancel();
      })
      .catch((e) => {
        console.log(e);
        displayErrorNotification(NotificationTypes.ACTIVITY_DELETE_ERROR);
      });
  };

  if (!props.isDeletePopupVisible) {
    return null;
  }

  const lockDeleteButton = (props.isDeletePopupVisible && !props.activity.Id) || isRemoving;

  return (
    <DeleteDialog
      isDataLoading={lockDeleteButton}
      cancel={props.handleCancel}
      submit={handleDelete}
    >
      {"After deleting this activity, you won't be able to undo this action."}
    </DeleteDialog>
  );
};

const mapStateToProps = (state) => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];

  return {
    getFilterString: (status) => {
      const filterObj = {
        id: status.Id,
        name: status.Name,
        offset: getActivityFilters(state).get(status.Id) || offset(status),
      };
      return getFilterString(filterObj);
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, assets?: Set<string>, relatedItems?) =>
    dispatch(setSelectedAssets(assetType, assets, relatedItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteActivityPopup);
