import * as React from "react";
import { AssetModel } from "../../../redux/models/data/AssetModel";

import AssetTypeIcon from "./form/AssetTypeIcon";
import AssetTypeSelect from "./form/AssetTypeSelect";
import { StyledAssetTypeSelect } from "./form/StylesAssetTypeSelect";

import { ASSET_TYPE, DESCRIPTION, ITEM_NAME, NAME } from "../../../_constant/wordings";
import { Grid } from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { EditOutlined } from "@material-ui/icons";
import useTheme from "@material-ui/core/styles/useTheme";

import Input from "../../../components/Input/Input";
import InputRow from "../../../components/InputRow/InputRow";
import TagsAutocompleteItems from "../../../components/TagsAutocomplete/TagsAutocompleteItems";
import { StyledSection } from "../../../components/_styled/StyledSection";
import InputField from "../../../components/InputField/InputField";

import ParentAssetSelect from "./form/ParentAssetSelect";

interface EssentialAssetProps {
  assetData: AssetModel;
  openDialog: () => void;
  errorFields: Map<string, string>;
  handleFieldChangeWithValidation: (
    e: React.ChangeEvent<HTMLInputElement> | null,
    target?: any,
  ) => void;
  handleFieldChange: (target: any) => void;
  updateManyFields: (newData: Partial<any>) => void;
  isLoading: boolean;
  isNew: boolean;
}

const EssentialAsset: React.FC<EssentialAssetProps> = (props) => {
  const {
    AssetType,
    Name,
    Description,
    DefaultDescription,
    Tags = [],
    ParentId,
    Id,
  } = props.assetData;

  const {
    errorFields,
    handleFieldChangeWithValidation,
    handleFieldChange,
    openDialog,
    updateManyFields,
    isLoading,
    isNew,
  } = props;

  const theme = useTheme();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value ?? "";
    handleFieldChangeWithValidation(null, { value, id: NAME });
  };

  return (
    <StyledSection>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Input
              autoComplete="off"
              name={ITEM_NAME}
              label={ITEM_NAME}
              onChange={handleNameChange}
              value={Name}
              id={ITEM_NAME}
              error={errorFields.has(NAME)}
              errorText={errorFields.get(NAME)}
              required
              icon={<EditOutlined />}
            />
          </Grid>
        </Grid>
      </InputRow>

      <InputRow alignItems="flex-end">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <StyledAssetTypeSelect>
              <AssetTypeIcon
                Id={AssetType.Id}
                iconName={AssetType.IconName}
                openDialog={openDialog}
                direction="column"
                iconStyles={{
                  verticalAlign: "top",
                  fontSize: "16px",
                  color: theme.palette.text.secondary,
                }}
              />
              <AssetTypeSelect
                AssetType={AssetType}
                hasError={errorFields.has(ASSET_TYPE)}
                errorMessage={errorFields.get(ASSET_TYPE)}
                updateManyFields={updateManyFields}
              />
            </StyledAssetTypeSelect>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ParentAssetSelect
              handleFieldChange={handleFieldChange}
              ParentId={ParentId}
              currentAssetId={Id}
              isLoading={isLoading}
              isNew={isNew}
            />
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputField
              handleChange={handleFieldChange}
              value={Description}
              id={DESCRIPTION}
              label={DESCRIPTION}
              fullWidth
              icon={<DescriptionOutlinedIcon />}
              multiline
              maxLength="1000"
              placeholder={DefaultDescription}
            />
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2} alignItems="center">
          <TagsAutocompleteItems handleFieldChange={handleFieldChange} checkedTags={Tags} />
        </Grid>
      </InputRow>
    </StyledSection>
  );
};
export default EssentialAsset;
