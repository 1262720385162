import * as React from "react";
import { useNavigate } from "react-router-dom";

import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SettingsBackupRestoreOutlinedIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { saveButtonWordings } from "./SaveButton.constants";

interface SaveButtonProps {
  isLoading: boolean;
  isSaveAvailable: boolean;
  isNew: boolean;
  isDataChanged: boolean;
  cancelRouteForEmptyHistory: string;
  onCancel?: () => void;
  revertData: () => void;
  saveAndClose: (e: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
}

const SaveButton: React.FC<SaveButtonProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const cancelHandler = () => {
    if (window.history.length < 3) {
      navigate(props.cancelRouteForEmptyHistory);
    } else {
      navigate(-1);
    }
  };

  const options = [
    {
      text: props.isNew ? saveButtonWordings.saveAndClose : saveButtonWordings.save,
      icon: <SaveOutlinedIcon fontSize={"small"} />,
      callback: props.saveAndClose.bind(null, null, !props.isNew),
    },
    {
      text: saveButtonWordings.revert,
      icon: <SettingsBackupRestoreOutlinedIcon fontSize={"small"} />,
      callback: props.revertData,
    },
    {
      text: saveButtonWordings.cancel,
      icon: <CloseOutlinedIcon fontSize={"small"} />,
      callback: cancelHandler,
    },
  ];

  const handleMenuItemClick = (cb: () => void) => {
    cb();
    setOpen(false);
  };

  const handleClickMainBtn = () => {
    props.isSaveAvailable ? props.saveAndClose(null, props.isNew) : cancelHandler();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} className="SaveButton">
        <Button
          color="primary"
          startIcon={
            props.isSaveAvailable ? (
              <i className="Icon Icon-save" />
            ) : (
              <i className="Icon Icon-close" />
            )
          }
          onClick={handleClickMainBtn}
          disabled={props.isLoading}
        >
          {props.isSaveAvailable && props.isNew
            ? "Save"
            : props.isSaveAvailable
            ? "Save & Close"
            : "Close"}
          {props.isLoading && <CircularProgress size={24} className="SaveButton__loader" />}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={props.isLoading}
        >
          <ArrowDropDownIcon
            fontSize={"small"}
            className={`DropDownIcon${open ? " DropDownIcon_rotate" : ""}`}
          />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="SaveButton__dropdown"
        placement={"top"}
        style={{ width: anchorRef.current ? `${anchorRef.current.offsetWidth - 0.5}px` : 0 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option) => {
                    let disabled = props.isLoading || !props.isSaveAvailable;
                    if (option.text === saveButtonWordings.revert) {
                      disabled = props.isLoading || !props.isDataChanged;
                    } else if (option.text === saveButtonWordings.cancel) {
                      disabled = props.isLoading;
                    }

                    return (
                      <MenuItem
                        key={option.text}
                        onClick={() => handleMenuItemClick(option.callback)}
                        disabled={disabled}
                      >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" style={{ fontSize: "12px" }}>
                            {option.text}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SaveButton;
