import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { GET_ASSET_REFS } from "../all/assets.query";
import { GET_DETAILED_ITEM_TYPE_LIST } from "../../_common/filter/asset-type.query";

import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import { AssetModel } from "../../../redux/models/data/AssetModel";
import { Image } from "../../../redux/models/data/ImageModel";

import { ACCOUNT, ASSET_REFS, ID, ASSET_TYPE, ITEM_TYPES, ITEMS, COMPONENT_IDS, PARENT_ID } from "../../../_constant/wordings";
import { getExistAssets } from "../../../components/PartsTabContainer/assets.query";

export function createNewAssetReferenceItem(assetPost: AssetModel): AssetRefsModel {
  const MainImage: any =
    assetPost.Images && assetPost.Images.length !== 0 ? new Image(assetPost.Images[0]) : null;

  const newAssetRef = {
    Id: assetPost[ID],
    AssetTypeRef: assetPost[ASSET_TYPE],
    Name: assetPost.Name,
    Description: assetPost.Description,
    MainImage,
    HasActivities: !!assetPost.hasRelatedActivities,
    DefaultDescription: assetPost.DefaultDescription,
    Tags: assetPost.Tags,
    __typename: "AssetRef",
  };
  if (newAssetRef.MainImage) {
    newAssetRef.MainImage.__typename = "Image";
  }
  return newAssetRef;
}

export function addNewItemInAssetsReferenceQuery(
  cache: DataProxy,
  assetPost: AssetModel,
  accountId: string,
) {
  try {
    const assetReferencesQuery: any = cache.readQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
    });
    const newReferenceItem = createNewAssetReferenceItem(assetPost);
    if (!assetReferencesQuery[ACCOUNT][ASSET_REFS]) {
      assetReferencesQuery[ACCOUNT][ASSET_REFS] = [newReferenceItem];
    } else {
      assetReferencesQuery[ACCOUNT][ASSET_REFS] = [
        ...assetReferencesQuery[ACCOUNT][ASSET_REFS],
        newReferenceItem,
      ];
    }

    cache.writeQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
      data: assetReferencesQuery,
    });
  } catch (e) {
    console.error(e);
  }
}

export function updateAssetTypeListQueryWithNewItem(
  cache: DataProxy,
  assetPost: any,
  accountId: string,
) {
  try {
    const assetTypesQuery: any = cache.readQuery({
      query: GET_DETAILED_ITEM_TYPE_LIST,
      variables: { Id: accountId },
    });

    const assetTypes = assetTypesQuery[ACCOUNT];

    // check have we this assetType before item was create. if not - add it to query
    assetPost[ASSET_TYPE].IsSystem = null;
    if (
      !assetTypes[ITEM_TYPES].some(
        (assetType: AssetRefsModel) => assetType.Id === assetPost[ASSET_TYPE][ID],
      )
    ) {
      assetTypes[ITEM_TYPES] = [...assetTypes[ITEM_TYPES], assetPost[ASSET_TYPE]];
    }

    cache.writeQuery({
      query: GET_DETAILED_ITEM_TYPE_LIST,
      variables: { Id: accountId },
      data: assetTypesQuery,
    });
  } catch (e) {
    console.error(e);
  }
}

export function updateComponentIdsForParentAssetWithNewPart(
  cache: DataProxy,
  assetPost: any,
  accountId: string,
) {
  try {
    const existAssetsQuery: any = cache.readQuery({
      query: getExistAssets,
      variables: {
        Id: accountId,
      },
    });

    const parentAsset = existAssetsQuery[ACCOUNT][ITEMS].find((asset) => asset.Id === assetPost[PARENT_ID]);

    existAssetsQuery[ACCOUNT][ITEMS] = existAssetsQuery[ACCOUNT][ITEMS].map((asset) => {
      if (asset.Id === parentAsset.Id) {
        parentAsset[COMPONENT_IDS].push(assetPost.Id);
        return parentAsset;
      } else {
        return asset;
      }
    });

    cache.writeQuery({
      query: getExistAssets,
      variables: {
        Id: accountId,
      },
      data: existAssetsQuery,
    });
  } catch (e) {}
}
