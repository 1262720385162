import React from "react";

import { useGetAssetTypeDetails } from "../../customHooks/useGetAssetTypesDescriptionPattern";

import { FIELD_TYPES_OBJECT } from "../ItemCustomFields/ItemCustomFieldsTypeOptions";
import { VIN } from "../../_constant/wordings";
import { AssetTypeModel, ItemTypeField } from "../../redux/models/data/AssetTypeModel";

import CustomField from "../CustomField/CustomField";
import VimSmartField from "./SmartField/VimSmartField";

interface CommonInfoTabContainerProps {
  assetType: AssetTypeModel;
  AssetFieldValues: { [key: string]: any };
  onChange: (e: any, target: any) => void;
  multipleUpdate: (newData: Partial<any>) => void;
}

const CommonInfoTabContainer: React.FC<CommonInfoTabContainerProps> = ({
  assetType,
  AssetFieldValues,
  multipleUpdate,
}) => {
  const { getDetailsById } = useGetAssetTypeDetails();
  const { pattern, itemTypeField: ItemTypeFields } = getDetailsById(assetType?.Id);

  const handleCustomFieldValueChange = ({ target }) => {
    const name = target.id;
    let value = target.value;

    const currentFieldset = ItemTypeFields.find((element) => element.Name === name);
    if (!currentFieldset) return;
    if (currentFieldset.FieldType === FIELD_TYPES_OBJECT.NUMBER) {
      value = value ? Number(value) : null;
    }
    if (currentFieldset.FieldType === FIELD_TYPES_OBJECT.DATE) {
      value = value ? value : null;
    }
    if (currentFieldset.FieldType === FIELD_TYPES_OBJECT.SELECT) {
      value = value.hasOwnProperty("value") ? value.value : value;
    }

    const newDefaultDescription: string[] = pattern.reduce((acc: string[], el: string) => {
      if (el === currentFieldset.Name) {
        acc.push(value);
      } else {
        acc.push(AssetFieldValues[el] ?? "");
      }
      return acc;
    }, []);

    multipleUpdate({
      TypeFieldValues: { ...AssetFieldValues, [currentFieldset.Name]: value },
      DefaultDescription: newDefaultDescription.join(" ").trim(),
    });
  };

  return (
    <section className="Section">
      {ItemTypeFields.sort((prev, next) => prev.Order - next.Order).map(
        ({ Id, Name, FieldType, AllowedValues, IsCustomValueAllowed }) => {
          const value = AssetFieldValues.hasOwnProperty(Name) ? AssetFieldValues[Name] : null;
          const options = AllowedValues?.map((item) => ({ value: item, label: item }));

          if (Name === VIN) {
            return (
              <VimSmartField
                key={Id}
                type={FieldType}
                name={Name}
                value={value}
                options={options}
                isSelectWritable={!!IsCustomValueAllowed}
                multipleUpdate={multipleUpdate}
                AssetFieldValues={AssetFieldValues}
                onChange={handleCustomFieldValueChange}
                defaultDescriptionPatern={pattern}
              />
            );
          }
          return (
            <CustomField
              key={Id}
              type={FieldType}
              name={Name}
              value={value}
              onChange={handleCustomFieldValueChange}
              options={options}
              isSelectWritable={!!IsCustomValueAllowed}
            />
          );
        },
      )}
    </section>
  );
};

export default CommonInfoTabContainer;
