import { GET_ACCOUNT_ID } from "./useGetAccountId";
import useInitialPath from "./useInitialPath";

import { useNavigate, createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setActivitiesFilterValue,
  setSearchString,
  setSelectedAssets,
} from "../redux/actions/actions";

import { getUserInfoQuery } from "../containers/profile/profile.query";
import { getAccountRefsList } from "../pages/Accounts/getAccountList.query";

import { ROUTES } from "../_constant/screens";
import { CURRENT_ACCOUNT } from "../_constant/wordings";
import { ALL_ACTIVITIES } from "../_constant/constants";

import gql from "graphql-tag";
import { GET_STATIC_DATA } from "../queries/useStaticDataQuery";
import { getWizardDataQuery } from "../components/Wizard/WizardContainer/getWizardData.query";

import useGetApolloClient from "./useGetApolloClient";
import { ApolloClient } from "apollo-client";
import { DocumentNode } from "graphql";

interface saveAccToLocaleStorage {
  Id: string;
  IsCustomerManagement?: boolean;
}

const accountFragment = gql`
  fragment Account on AccountRefs {
    Id
    Name
    IsCustomerManagement
  }
`;

const getQueryFromCache = (client: ApolloClient<object>, query: DocumentNode, variables?: any) => {
  let result = null;

  try {
    result = client.readQuery({ query: query, variables });
  } catch (error) {}
  return result;
};

const useAccountSwitch = () => {
  const { optionalRedirect } = useInitialPath();
  const client = useGetApolloClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCachedAccount = (Id: string) => {
    if (!client) {
      return null;
    }

    let account = null;
    try {
      account = client.readFragment({ fragment: accountFragment, id: Id });
    } catch {}
    return account;
  };

  const persistCache = async (accountId: string) => {
    if (!client) {
      return;
    }

    const accountListCache = getQueryFromCache(client, getAccountRefsList);
    const userInfoCache = getQueryFromCache(client, getUserInfoQuery);
    const staticDataCache = getQueryFromCache(client, GET_STATIC_DATA);
    const wizardDataCache = getQueryFromCache(client, getWizardDataQuery);
    const account = getCachedAccount(accountId);

    await client.clearStore();

    client.writeQuery({
      query: GET_ACCOUNT_ID,
      data: {
        Account: {
          accountId,
          Name: account && account.Name,
          IsCustomerManagement: account ? account.IsCustomerManagement : false,
          __typename: "Account",
        },
      },
    });
    if (accountListCache) client.writeQuery({ query: getAccountRefsList, data: accountListCache });
    if (userInfoCache) client.writeQuery({ query: getUserInfoQuery, data: userInfoCache });
    if (staticDataCache) client.writeQuery({ query: GET_STATIC_DATA, data: staticDataCache });
    if (wizardDataCache) client.writeQuery({ query: getWizardDataQuery, data: wizardDataCache });
  };

  const saveAccToLocaleStorage = ({ Id, IsCustomerManagement }: saveAccToLocaleStorage) => {
    const currentAccountObject = { Id, IsCustomerManagement: IsCustomerManagement ?? false };
    localStorage.setItem(CURRENT_ACCOUNT, JSON.stringify(currentAccountObject));
  };

  const switchAccountById = async (Id: string) => {
    navigate(
      { pathname: ROUTES.CHANGE_ACCOUNT, search: `?${createSearchParams({ id: Id })}` },
      { replace: true },
    );

    if (!client) {
      return;
    }
    const account = getCachedAccount(Id);

    await persistCache(Id);

    const IsCustomerManagement = account ? account.IsCustomerManagement : false;
    saveAccToLocaleStorage({ Id, IsCustomerManagement });

    // reset filter and search
    dispatch(setSelectedAssets());
    dispatch(setSearchString(""));
    dispatch(setActivitiesFilterValue(ALL_ACTIVITIES));

    return await optionalRedirect({ accountId: Id, IsCustomerManagement }, false);
  };

  return { switchAccountById, changeAccountIdWithoutCache: saveAccToLocaleStorage };
};

export default useAccountSwitch;
