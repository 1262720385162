import React from "react";

import Button from "@material-ui/core/Button/Button";
import StopIcon from "@material-ui/icons/Stop";
import ReplayIcon from "@material-ui/icons/Replay";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ReactComponent as PlayIcon } from "../../../images/ic_not_started.svg";

const ButtonStyled = styled(Button)`
  width: 6rem;
  height: 2rem;
`;

interface StartVisitButtonProps {
  isStarted: boolean;
  isFinished: boolean;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}
const StartVisitButton: React.FC<StartVisitButtonProps> = (props) => {
  const { isStarted, isLoading = false, onClick, disabled = false, isFinished } = props;

  const getIcon = (loading: boolean, play: boolean) => {
    if (loading) {
      return <CircularProgress size={20} color={isStarted ? "primary" : "inherit"} />;
    }
    if (isFinished) return <ReplayIcon />;
    return play ? <StopIcon /> : <PlayIcon style={{ width: "1.1rem", height: "1.1rem" }} />;
  };

  return (
    <ButtonStyled
      variant={isStarted || isFinished ? "outlined" : "contained"}
      color="primary"
      startIcon={getIcon(isLoading, isStarted)}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {!isStarted && !isFinished && "Start"}
      {isStarted && "Finish"}
      {isFinished && "Restart"}
      {/* {<CircularProgress size={24} color={started ? "primary" : "inherit"} />} */}
    </ButtonStyled>
  );
};

export default StartVisitButton;
