import React, { useState, useEffect } from "react";
import { Box, CircularProgress, useTheme } from "@material-ui/core";
import styled from "styled-components";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { AssetModel } from "../../redux/models/data/AssetModel";
import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import AssetCardRow from "../Cards/AssetCardRow";
import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";
import AssetCardLink from "../AssetCardAdditionalInfo/AssetCardLink";
import useRemoveRelatedPartMutation from "../../customHooks/useRemoveRelatedPartMutation";
import useGetAllRelatedParts from "../../customHooks/useGetAllRelatedParts";

const StyledListLabel = styled.p`
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;

  .amount {
    color: ${({ theme }) => theme.palette.greyDesign.surface2};
  }
`;

interface PartsTabContainerProps {
  isLoading: boolean;
  currentAssetId: string | "";
}

const PartsTabContainer: React.FC<PartsTabContainerProps> = ({ isLoading, currentAssetId }) => {
  const theme = useTheme();

  const { accountId } = useGetAccountId();

  const { relatedParts, loading } = useGetAllRelatedParts({ assetId: currentAssetId, accountId });

  const { handleRemove, isUpdating } = useRemoveRelatedPartMutation({
    partsList: relatedParts,
    accountId,
  });

  if (loading || isLoading) {
    return (
      <div style={{ height: "100%", flexGrow: 1, padding: "1rem", textAlign: "center" }}>
        <CircularProgress size={22} />
      </div>
    );
  }

  return (
    <Box padding="1rem" paddingTop="">
      {relatedParts.length ? (
        <>
          <StyledListLabel>
            Parts <span className="amount">{`(${relatedParts.length})`}</span>
          </StyledListLabel>

          <Box
            overflow="hidden"
            borderRadius="8px"
            border={`1px solid ${theme.palette.greyDesign.onSurface3}`}
          >
            {relatedParts.map((item: AssetModel) => {
              if (!item) {
                return null;
              }
              const convertedAsset: AssetRefsModel = new AssetRefsModel({
                ...item,
                AssetTypeRef: item?.AssetType,
                MainImage: item?.Images && item.Images.length ? item.Images[0] : null,
                HasActivities: item?.AssetActivities?.length > 0,
              });

              return (
                <AssetCardRow
                  item={convertedAsset}
                  key={item.Id}
                  additionalInfo={<AssetCardLink item={convertedAsset} />}
                  withoutRedirect
                  isCardInPartsTab
                  handleRemove={handleRemove}
                />
              );
            })}
          </Box>
        </>
      ) : (
        <Box padding="1rem" paddingTop="">
          <EmptyCustomFieldState emptyFieldsMessage="There are no parts" />
        </Box>
      )}
    </Box>
  );
};

export default PartsTabContainer;
