import imagesConfig from "../../configs/images-config.json";
const IMAGE_MAX_COUNT = imagesConfig.imagesUploadLimit;

export enum NotificationTypes {
  PROFILE_UPDATED = 200,
  PROFILE_UPDATED_ERROR = 210,
  PROFILE_ACCESS_ERROR = 211,
  ASSET_CREATED = 300,
  ASSETS_CREATED = 301,
  ASSET_UPDATED = 302,
  ASSET_DELETED = 306,
  ASSET_MOVED = 307,
  ASSET_CREATE_ERROR = 350,
  ASSETS_CREATE_ERROR = 351,
  ASSET_UPDATE_ERROR = 352,
  ASSET_DELETE_ERROR = 356,
  ASSET_GET_ERROR = 358,
  ASSET_MOVE_ERROR = 359,
  ASSET_TYPE_ICON_UPDATED_ERROR = 358,
  ASSET_ACCESS_ERROR = 353,
  IMAGE_RESTRICTION = 400,
  IMAGE_RESTRICTION_FOR_ONE = 401,
  IMAGE_LIMIT = 402,
  ACTIVITY_CREATED = 500,
  ACTIVITY_UPDATED = 502,
  ACTIVITY_STATUS_UPDATED = 504,
  ACTIVITY_DELETED = 506,
  ACTIVITY_CREATE_ERROR = 550,
  ACTIVITY_UPDATE_ERROR = 552,
  ACTIVITY_STATUS_UPDATED_ERROR = 554,
  ACTIVITY_DELETE_ERROR = 556,
  ACTIVITY_ASSIGN_UPDATED = 557,
  ACTIVITY_ASSIGN_UPDATED_ERROR = 558,
  ACTIVITY_ACCESS_ERROR = 559,
  COMMON_ERROR = 600,
  LOST_CONNECTION = 602,
  UNEXPECTED_ERROR = 604,
  CUSTOM_FIELD_ALREADY_EXIST = 702,
  ACCOUNT_CREATED = 800,
  ACCOUNT_CREATE_ERROR = 801,
  ACCOUNT_UPDATED = 802,
  ACCOUNT_UPDATED_ERROR = 803,
  ACCOUNT_DELETED = 804,
  ACCOUNT_DELETE_ERROR = 805,
  ACCOUNT_LEAVED = 806,
  ACCOUNT_LEAVED_ERROR = 807,
  ACCOUNT_ACCESS_ERROR = 808,
  INVITATION_ACCEPTED = 900,
  INVITATION_ACCEPT_ERROR = 901,
  INVITATION_DECLINED = 910,
  INVITATION_DECLINE_ERROR = 911,
  INVITATION_REACTIVATED = 912,
  INVITATION_REACTIVATE_ERROR = 913,
  USER_REMOVED = 950,
  USER_REMOVE_ERROR = 951,
  BUSINESS_ACCOUNT_CREATED = 1000,
  BUSINESS_ACCOUNT_CREATE_ERROR = 1001,
  BUSINESS_ACCOUNT_UPDATED = 1002,
  BUSINESS_ACCOUNT_UPDATED_ERROR = 1003,
  BUSINESS_ACCOUNT_DELETED = 1004,
  BUSINESS_ACCOUNT_DELETE_ERROR = 1005,
  CUSTOMER_CREATED = 1100,
  CUSTOMER_CREATE_ERROR = 1101,
  CUSTOMER_UPDATED = 1102,
  CUSTOMER_UPDATED_ERROR = 1103,
  CUSTOMER_ACCESS_ERROR = 1104,
  CUSTOMER_DELETED = 1104,
  CUSTOMER_DELETE_ERROR = 1105,
  VISIT_CREATED = 1200,
  VISIT_CREATE_ERROR = 1201,
  VISIT_UPDATED = 1202,
  VISIT_UPDATED_ERROR = 1203,
  VISIT_ACCESSS_ERROR = 1204,
  VISIT_DELETED = 1204,
  VISIT_DELETE_ERROR = 1205,
  VISIT_ASSIGN_UPDATED = 1206,
  VISIT_ASSIGN_UPDATED_ERROR = 1207,
  VISIT_REPORT_SUCCESS = 1210,
  VISIT_REPORT_FAILURE = 1211,
  VENDOR_CREATED = 1300,
  VENDOR_CREATE_ERROR = 1301,
  VENDOR_UPDATED = 1302,
  VENDOR_UPDATED_ERROR = 1303,
  VENDOR_ACCESS_ERROR = 1304,
  VENDOR_DELETED = 1304,
  VENDOR_DELETE_ERROR = 1305,
  SEND_VERIFICATION_EMAIL_SUCCESS = 1400,
  SEND_VERIFICATION_EMAIL_FAILURE = 1401,
  FILE_RESTRICTION = 1500,
  FILE_COUNT_RESTRICTION = 1501,
  FEED_CONFIG_CREATED = 1600,
  FEED_CONFIG_CREATED_ERROR = 1601,
  FEED_CONFIG_UPDATED = 1602,
  FEED_CONFIG_UPDATED_ERROR = 1603,
  TEMPLATE_DOWNLOADED = 1700,
  DOWNLOAD_TEMPLATE_ERROR = 1701,
  MORE_THEN_ONE_FILE = 1800,
  UPLOAD_FILE_ERROR = 1801,
  PROJECT_CREATED = 1900,
  PROJECT_CREATE_ERROR = 1901,
  PROJECT_UPDATED = 1902,
  PROJECT_UPDATED_ERROR = 1903,
  PROJECT_ACCESSS_ERROR = 1904,
  PROJECT_DELETED = 1904,
  PROJECT_DELETE_ERROR = 1905,
  PROJECT_ASSIGN_UPDATED = 1906,
  PROJECT_ASSIGN_UPDATED_ERROR = 1907,
  PROJECT_STARTED = 1908,
  PROJECT_STARTED_ERROR = 1909,
  PROJECT_FINISHED = 1910,
  PROJECT_FINISHED_ERROR = 1911,
  ACTIVITIES_CREATE_ERROR,
}

export const ERROR_NOTIFICATIONS = new Map([
  [NotificationTypes.COMMON_ERROR, "An error occurred"],
  [
    NotificationTypes.ASSET_TYPE_ICON_UPDATED_ERROR,
    "An error occurred during updating asset type icon",
  ],
  [NotificationTypes.ASSET_GET_ERROR, "An error occurred during uploading items"],
  [NotificationTypes.PROFILE_UPDATED_ERROR, "Could not update profile information"],
  [NotificationTypes.PROFILE_ACCESS_ERROR, "Could not access profile information"],
  [NotificationTypes.ASSET_DELETE_ERROR, "Could not delete item"],
  [NotificationTypes.ASSET_CREATE_ERROR, "Could not create new item"],
  [NotificationTypes.ASSETS_CREATE_ERROR, "Could not create new items"],
  [NotificationTypes.ASSET_UPDATE_ERROR, "Could not update item information"],
  [NotificationTypes.ASSET_MOVE_ERROR, "Could not move item to another account"],
  [NotificationTypes.ASSET_ACCESS_ERROR, "Could not access missing item"],
  [NotificationTypes.ACTIVITY_STATUS_UPDATED_ERROR, "Could not update activity status"],
  [NotificationTypes.ACTIVITY_DELETE_ERROR, "Could not delete activity"],
  [NotificationTypes.ACTIVITY_CREATE_ERROR, "Could not create activity"],
  [NotificationTypes.ACTIVITY_UPDATE_ERROR, "Could not update activity information"],
  [NotificationTypes.ACTIVITY_ASSIGN_UPDATED_ERROR, "Could not assign activity"],
  [NotificationTypes.ACTIVITY_ACCESS_ERROR, "Could not access missing activity"],
  [NotificationTypes.ACTIVITIES_CREATE_ERROR, "Could not create new activities"],
  [NotificationTypes.CUSTOM_FIELD_ALREADY_EXIST, "Custom field already exist"],
  [NotificationTypes.ACCOUNT_CREATE_ERROR, "Could not create new account"],
  [NotificationTypes.ACCOUNT_UPDATED_ERROR, "Could not update account information"],
  [NotificationTypes.ACCOUNT_DELETE_ERROR, "Could not delete account"],
  [NotificationTypes.ACCOUNT_LEAVED_ERROR, "Could not leave account"],
  [NotificationTypes.ACCOUNT_ACCESS_ERROR, "Could not access missing account"],
  [NotificationTypes.INVITATION_ACCEPT_ERROR, "Could not accept invitation"],
  [NotificationTypes.INVITATION_DECLINE_ERROR, "Could not decline invitation"],
  [NotificationTypes.INVITATION_REACTIVATE_ERROR, "Could not reactivate invitation"],
  [NotificationTypes.USER_REMOVE_ERROR, "Could not remove user"],
  [NotificationTypes.BUSINESS_ACCOUNT_CREATE_ERROR, "Could not create new business account"],
  [
    NotificationTypes.BUSINESS_ACCOUNT_UPDATED_ERROR,
    "Could not update business account information",
  ],
  [NotificationTypes.BUSINESS_ACCOUNT_DELETE_ERROR, "Could not delete business account"],
  [NotificationTypes.CUSTOMER_CREATE_ERROR, "Could not create new customer"],
  [NotificationTypes.CUSTOMER_UPDATED_ERROR, "Could not update customer information"],
  [NotificationTypes.CUSTOMER_ACCESS_ERROR, "Could not access customer information"],
  [NotificationTypes.CUSTOMER_DELETE_ERROR, "Could not delete customer"],
  [NotificationTypes.VISIT_CREATE_ERROR, "Could not create new visit"],
  [NotificationTypes.VISIT_UPDATED_ERROR, "Could not update visit information"],
  [NotificationTypes.VISIT_ACCESSS_ERROR, "Could not access visit information"],
  [NotificationTypes.VISIT_DELETE_ERROR, "Could not delete visit"],
  [NotificationTypes.VISIT_ASSIGN_UPDATED_ERROR, "Could not assign visit"],
  [NotificationTypes.VENDOR_CREATE_ERROR, "Could not create new vendor"],
  [NotificationTypes.VENDOR_UPDATED_ERROR, "Could not update vendor information"],
  [NotificationTypes.VENDOR_ACCESS_ERROR, "Could not access vendor information"],
  [NotificationTypes.VENDOR_DELETE_ERROR, "Could not delete vendor"],
  [NotificationTypes.SEND_VERIFICATION_EMAIL_FAILURE, "Could not send verification email"],
  [NotificationTypes.VISIT_REPORT_FAILURE, "Could not send report"],
  [NotificationTypes.FEED_CONFIG_CREATED_ERROR, "Could not create feed settings"],
  [NotificationTypes.FEED_CONFIG_UPDATED_ERROR, "Could not update feed settings"],
  [NotificationTypes.UNEXPECTED_ERROR, "Unexpected error happened"],
  [NotificationTypes.UPLOAD_FILE_ERROR, "Could not upload file"],
  [NotificationTypes.DOWNLOAD_TEMPLATE_ERROR, "Could not download template"],
  [NotificationTypes.PROJECT_CREATE_ERROR, "Could not create new project"],
  [NotificationTypes.PROJECT_UPDATED_ERROR, "Could not update project information"],
  [NotificationTypes.PROJECT_ACCESSS_ERROR, "Could not access project information"],
  [NotificationTypes.PROJECT_DELETE_ERROR, "Could not delete project"],
  [NotificationTypes.PROJECT_ASSIGN_UPDATED_ERROR, "Could not assign project"],
  [NotificationTypes.PROJECT_STARTED_ERROR, "Could not start project"],
  [NotificationTypes.PROJECT_FINISHED_ERROR, "Could not finish project"],
]);

export const WARNING_NOTIFICATIONS = new Map([
  [NotificationTypes.IMAGE_RESTRICTION, "Some images were rejected"],
  [NotificationTypes.IMAGE_RESTRICTION_FOR_ONE, "Image were rejected"],
  [NotificationTypes.IMAGE_LIMIT, `You can upload maximum ${IMAGE_MAX_COUNT} images`],
  [NotificationTypes.LOST_CONNECTION, "Internet connection lost"],
  [NotificationTypes.FILE_RESTRICTION, "Some files were rejected"],
  [
    NotificationTypes.FILE_COUNT_RESTRICTION,
    `The file limit has been exceeded, you have ${imagesConfig.imagesUploadLimit} files available for uploading`,
  ],
  [
    NotificationTypes.MORE_THEN_ONE_FILE,
    "You could not load more than one file. The first file will be used",
  ],
]);

export const SUCCESS_NOTIFICATIONS = new Map([
  [NotificationTypes.PROFILE_UPDATED, "Personal info updated"],
  [NotificationTypes.ASSET_CREATED, "Item created"],
  [NotificationTypes.ASSETS_CREATED, "Items created"],
  [NotificationTypes.ASSET_UPDATED, "Item updated"],
  [NotificationTypes.ASSET_DELETED, "Item deleted"],
  [NotificationTypes.ASSET_MOVED, "Item moved to another account"],
  [NotificationTypes.ACTIVITY_CREATED, "Activity created"],
  [NotificationTypes.ACTIVITY_STATUS_UPDATED, "Activity status updated"],
  [NotificationTypes.ACTIVITY_UPDATED, "Activity updated"],
  [NotificationTypes.ACTIVITY_DELETED, "Activity deleted"],
  [NotificationTypes.ACTIVITY_ASSIGN_UPDATED, "Activity assigned"],
  [NotificationTypes.ACCOUNT_CREATED, "Account created"],
  [NotificationTypes.ACCOUNT_UPDATED, "Account updated"],
  [NotificationTypes.ACCOUNT_DELETED, "Account deleted"],
  [NotificationTypes.ACCOUNT_LEAVED, "You have left the account"],
  [NotificationTypes.INVITATION_ACCEPTED, "Invitation accepted"],
  [NotificationTypes.INVITATION_DECLINED, "Invitation declined"],
  [NotificationTypes.INVITATION_REACTIVATED, "Invitation reactivated"],
  [NotificationTypes.USER_REMOVED, "User removed"],
  [NotificationTypes.BUSINESS_ACCOUNT_CREATED, "Business account created"],
  [NotificationTypes.BUSINESS_ACCOUNT_UPDATED, "Business account updated"],
  [NotificationTypes.BUSINESS_ACCOUNT_DELETED, "Business account deleted"],
  [NotificationTypes.CUSTOMER_CREATED, "Customer created"],
  [NotificationTypes.CUSTOMER_UPDATED, "Customer updated"],
  [NotificationTypes.CUSTOMER_DELETED, "Customer deleted"],
  [NotificationTypes.VISIT_CREATED, "Visit created"],
  [NotificationTypes.VISIT_UPDATED, "Visit updated"],
  [NotificationTypes.VISIT_DELETED, "Visit deleted"],
  [NotificationTypes.VISIT_ASSIGN_UPDATED, "Visit assigned"],
  [NotificationTypes.VENDOR_CREATED, "Vendor created"],
  [NotificationTypes.VENDOR_UPDATED, "Vendor updated"],
  [NotificationTypes.VENDOR_DELETED, "Vendor deleted"],
  [NotificationTypes.SEND_VERIFICATION_EMAIL_SUCCESS, "Verification email successfully sent"],
  [NotificationTypes.VISIT_REPORT_SUCCESS, "Report successfully sent"],
  [NotificationTypes.FEED_CONFIG_CREATED, "Feed settings created"],
  [NotificationTypes.FEED_CONFIG_UPDATED, "Feed settings updated"],
  [NotificationTypes.TEMPLATE_DOWNLOADED, "Template downloaded"],
  [NotificationTypes.PROJECT_CREATED, "Project created"],
  [NotificationTypes.PROJECT_UPDATED, "Project updated"],
  [NotificationTypes.PROJECT_DELETED, "Project deleted"],
  [NotificationTypes.PROJECT_ASSIGN_UPDATED, "Project assigned"],
  [NotificationTypes.PROJECT_STARTED, "Project started"],
  [NotificationTypes.PROJECT_FINISHED, "Project finished"],
]);
