import React from "react";
import styled from "styled-components";
import { FakeItemCardContainer } from "../../FakeItemCard/FakeItemCardStyles";
import ErrorIcon from "@material-ui/icons/Error";

export const WizardLoadingContainer = styled.div`
  width: 100%;
  padding: 25px;
`;

export const WizardLoadingFakeCardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  background: var(--wizard-success-loading) no-repeat center;
  background-size: contain;

  ${FakeItemCardContainer} {
    width: 200px;
  }
`;

export const WizardLoadingBody = styled.div`
  text-align: center;
`;

export const WizardErrorIcon = styled(ErrorIcon)`
  color: ${({ theme }) => `${theme.palette.error.main}`};
  margin-right: 10px;
  vertical-align: bottom;
`;
