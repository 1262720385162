import * as React from "react";
import SelectComponent, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import commonConfig from "../../configs/common-config.json";

export interface TypeSelectValue {
  label: string;
  value: string;
}

interface TypeCustomSelectProps {
  isCreatable?: boolean;
  label?: string;
  name?: string;
  value: TypeSelectValue;
  options: TypeSelectValue[];
  onChange: (newValue, actionMeta) => void;
  isClearable?: boolean;
  isSearchable?: boolean;
  defaultValue?: TypeSelectValue;
  maxLength?: number;
  isRequired?: boolean;
  hasError?: boolean;
  errorMessage?: string;
}

const Control = (props) => (
  <>
    <label
      className={`${props.selectProps.className}__label ${
        props.isFocused || props.hasValue ? "has-value" : ""
      }`}
    >
      {props.selectProps.label}
    </label>
    <components.Control {...props} />
    {props.selectProps.hasError && (
      <div className={`${props.selectProps.className}_errorLabel`}>
        {props.selectProps.errorMessage ? props.selectProps.errorMessage : "Required field"}
      </div>
    )}
    {props.selectProps.isRequired &&
      !props.selectProps.value &&
      !props.selectProps.hasError && (
        <div className={`${props.selectProps.className}_requiredLabel`}>
          * Required field
        </div>
      )}
  </>
);

const SelectContainer = ({ children, ...props }) => {
  return (
    <components.SelectContainer {...props}>
      {children}
      <span className={props.isFocused ? "Bar Bar--active" : "Bar"} />
    </components.SelectContainer>
  );
};

const DropdownIndicator = (props) => {
  return !props.selectProps.isClearable || !props.hasValue ? (
    <components.DropdownIndicator {...props}>
      <i
        className={`Icon Icon-expand_${
          props.selectProps.menuIsOpen ? "less" : "more"
        }`}
      />
    </components.DropdownIndicator>
  ) : null;
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <i className="Icon Icon-clear" />
    </components.ClearIndicator>
  );
};

const IndicatorSeparator = () => {
  return null;
};

const Select: React.FC<TypeCustomSelectProps> = (props) => {
  const {
    isCreatable = false,
    isSearchable = false,
    isClearable = false,
    label,
    name,
    value,
    options,
    onChange,
    maxLength = commonConfig.maxInputLength,
    isRequired = false,
    hasError,
    errorMessage,
    defaultValue,
  } = props;

  const selectProps = {
    blurInputOnSelect: true,
    className: "Select",
    classNamePrefix: "Select",
    components: {
      ClearIndicator,
      Control,
      DropdownIndicator,
      IndicatorSeparator,
      SelectContainer,
    },
    hasError,
    errorMessage,
    isClearable,
    isRequired,
    label: label || "",
    name,
    onChange: (newValue, actionMeta) => onChange(newValue, actionMeta),
    onInputChange: (inputValue) =>
      inputValue.length <= maxLength
        ? inputValue
        : inputValue.substr(0, maxLength),
    openMenuOnFocus: true,
    options,
    placeholder: "",
    value: value && value.label ? value : null,
  };

  if (isCreatable) {
    return <CreatableSelect {...selectProps} createOptionPosition="first" />;
  }

  return (
    <SelectComponent
      {...selectProps}
      isSearchable={isSearchable}
      defaultValue={defaultValue}
    />
  );
};

export default Select;
