import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import { scrollStyles } from "../../_styled/scrollStyles";

export const WizardWelcomeContainer = styled.div`
  overflow-y: scroll;

  ${scrollStyles};
`;

export const WizardWelcomeScreenImageBg = styled.div`
  position: relative;
  padding: 220px 16px 16px 16px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 260px 60px 36px 60px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    max-height: 180px;
    top: 24px;
    left: 0;
    background-image: var(--wizard-welcome-img);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      max-height: 220px;
    }
  }
`;

export const WizardWelcomeScreenFooterText = styled((props) => (
  <Typography component="p" {...props} />
))(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)}px;
  color: ${theme.palette.text.secondary};
  font-size: 0.75rem;
`,
);
