import React from "react";

import { Image } from "../../redux/models/data/ImageModel";

import { composeImageUrl } from "../../_utils/utils";

import { CardAvatarStyled, PersonIconStyled } from "./Table.styles";

interface TableCardAvatarProps {
  image: Image | null;
  icon: string | null;
}

const TableCardAvatar: React.FC<TableCardAvatarProps> = ({ image, icon }) => {
  if (image || icon) {
    const srcIcon = icon ?? "";
    const finalSrc = image
      ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
      : srcIcon;
    return <CardAvatarStyled variant="rounded" src={finalSrc} />;
  }

  return (
    <CardAvatarStyled variant="rounded">
      <PersonIconStyled />
    </CardAvatarStyled>
  );
};

export default TableCardAvatar;
