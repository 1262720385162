import * as React from "react";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { useNavigate, useMatch } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";

import { AccountModel } from "../../redux/models/data/AccountModel";
import { Image } from "../../redux/models/data/ImageModel";

import { ACCOUNT_PUT, ID } from "../../_constant/wordings";

import AccountForm from "../../components/AccountForm/AccountForm";

import { updateAccountQuery } from "./updateAccount.query";
import { getAccountById } from "./getAccount.query";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { updateAccountsCacheAfterEditAccount } from "./updateAccountsCacheAfterEditAccount";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";
import { ROUTES } from "../../_constant/screens";
import { setEditFormIsDirty } from "../../redux/actions/actions";

interface IMatchParams {
  id: string;
}

const EditAccount = () => {
  const [accountData, setAccountData] = useState(new AccountModel());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successfulRedirect } = useSuccessfulRedirect();

  const match = useMatch("/settings/account/:id");

  const { data, loading: isFetching, error } = useQuery(getAccountById, {
    variables: { Id: match?.params?.id },
    fetchPolicy: "network-only",
    skip: !match,
    onError: () => {
      navigate(ROUTES.SETTINGS);
      displayErrorNotification(NotificationTypes.ACCOUNT_ACCESS_ERROR);
    },
  });

  const [
    updateAccount,
    { data: updatedData, loading: isUpdating, error: updateError },
  ] = useMutation(updateAccountQuery);

  useEffect(() => {
    if (!loading && !error && data) {
      setAccountData(new AccountModel(data["Account"]));
    }
  }, [data]);

  const { setHeaderOptions } = useHeaderOptions();

  const handleSubmit = (data: AccountModel, saveType: SaveType) => {
    let Image: Image;
    let ImagesList: Image[] = [];
    if (data.Images.length) {
      Image = { ...data.Images[0] };
      delete Image.__typename;
      ImagesList = [Image];
    }
    updateAccount({
      variables: {
        Id: data.Id,
        Name: data.Name,
        Description: data.Description,
        Files: data.Files,
        Images: ImagesList,
      },
      update(cache, { data: { AccountPut } }) {
        updateAccountsCacheAfterEditAccount(cache, AccountPut);
      },
    })
      .then(({ data }: any) => {
        if (data[ACCOUNT_PUT][ID] && saveType.type === SAVE) {
          saveType.callback(data[ACCOUNT_PUT]);
          displaySuccessNotification(NotificationTypes.ACCOUNT_UPDATED);
        } else if (data[ACCOUNT_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(data[ACCOUNT_PUT]);
          successfulRedirect(ROUTES.ACCOUNTS, NotificationTypes.ACCOUNT_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.ACCOUNT_UPDATED_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error(message, "");
        } else {
          displayErrorNotification(NotificationTypes.ACCOUNT_UPDATED_ERROR);
        }
      });
  };
  const loading = isFetching || isUpdating;
  return (
    <AccountForm
      handleSubmit={handleSubmit}
      isLoading={loading}
      isNew={false}
      data={accountData}
      setHeaderOptions={setHeaderOptions}
      isBusinessAccount={accountData.IsCustomerManagement}
    />
  );
};

export default EditAccount;
