import React from "react";

import Container from "@material-ui/core/Container";

import { VendorModel } from "../../redux/models/data/VendorModel";
import { LayoutType } from "../../_constant/constants";

import VendorBoardCard from "../VendorBoardCard/VendorBoardCard";

import { LoaderCircle } from "../Loader";
import { AssetsListContainer, AssetsListStyled } from "../AssetsList/AssetsListStyles";

import { NoVendorsComponent } from "../NoResultComponent/NoResultComponent";

interface AllVendorsBoardViewProps {
  vendors: VendorModel[];
  loading: boolean;
}

const AllVendorsBoardView: React.FC<AllVendorsBoardViewProps> = ({ vendors, loading }) => {
  if (!loading && !vendors.length) {
    return <NoVendorsComponent />;
  }

  return (
    <AssetsListStyled>
      {loading && (
        <div className={"LoaderWrapper"}>
          <LoaderCircle size={"xl"} />
        </div>
      )}
      {!loading && (
        <Container maxWidth="xl">
          <AssetsListContainer $layout={LayoutType.BOARD}>
            {!!vendors.length &&
              vendors.map((vendor: VendorModel) => {
                return <VendorBoardCard data={vendor} key={vendor.Id} />;
              })}
          </AssetsListContainer>
        </Container>
      )}
    </AssetsListStyled>
  );
};

export default AllVendorsBoardView;
