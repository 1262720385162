export class InviteFormModel {
  MembersName: string;
  MembersEmail: string;
  InviteMessage: string;

  constructor({ MembersName = "", MembersEmail = "", InviteMessage = "" } = {}) {
    this.MembersName = MembersName || "";
    this.MembersEmail = MembersEmail || "";
    this.InviteMessage = InviteMessage || "";
  }
}
