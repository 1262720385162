import gql from "graphql-tag";

export const getCalendarConfigsQuery = gql`
  query getCalendarConfigs($userId: ID) {
    CalendarConfigurations(UserId: $userId) {
      Id
      UserId
      AccountId
      AssignType
      AbsoluteUri
    }
  }
`;
