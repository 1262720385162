import styled from "styled-components";

import {
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Dialog,
  DialogContent,
} from "@material-ui/core";

export const StyledDialog = styled(Dialog)`
  margin: 0;
  .MuiDialog-paper {
    width: 80%;
    margin: 0;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 0;
  font-weight: 400;
`;

export const ButtonContainer = styled.div`
  height: 48px;
  width: 50%;
  display: flex;
  justify-content: center;
  :not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
`;

export const StyledDialogContentText = styled(DialogContentText)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 8px 16px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-root {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    fill: ${({ theme }) => theme.palette.warning.main};
  }
  &.MuiDialogTitle-root {
    padding: 16px;
  }
`;
