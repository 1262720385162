import React, { SyntheticEvent } from "react";

import useGetProjectsStatuses from "../../customHooks/api/useGetProjectsStatuses";

import StartVisitButton from "../StartVisitButton/StartVisitButton";

import { ProjectModel } from "../../redux/models/data/ProjectModel";

interface StartProjectButtonProps {
  isLoading: boolean;
  projectData: ProjectModel;
  isProjectStarted: boolean;
  isProjectFinished: boolean;
  handleProjectStatus: () => void;
  isUpdating: boolean;
  isNew: boolean;
}

const StartProjectButton: React.FC<StartProjectButtonProps> = ({
  isLoading,
  projectData,
  isProjectStarted,
  isProjectFinished,
  handleProjectStatus,
  isUpdating,
  isNew,
}) => {
  const statuses = useGetProjectsStatuses();

  const handleClick = () => {
    if (isLoading || !statuses || !projectData.Id) return;

    handleProjectStatus();
  };

  if (isNew) return null;

  return (
    <>
      <StartVisitButton
        isStarted={isProjectStarted}
        isFinished={isProjectFinished}
        onClick={handleClick}
        isLoading={isLoading || isUpdating}
      />
    </>
  );
};

export default StartProjectButton;
