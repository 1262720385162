import React from "react";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Tab } from "@material-ui/core";

interface FormControlLabelStyleProps {
  $hideLabelOnMobile?: boolean;
}
export const FormControlLabelStyle = styled(FormControlLabel)<FormControlLabelStyleProps>`
  &.MuiFormControlLabel-root {
    margin-left: 0;
    padding-left: 12px;
  }

  .MuiFormControlLabel-label {
    color: ${({ checked, theme }) =>
      checked ? theme.palette.primary.main : theme.palette.text.secondary};
  }

  ${({ $hideLabelOnMobile }) => {
    return (
      $hideLabelOnMobile &&
      `@media screen and (max-width: 600px) {
    .MuiFormControlLabel-label {
      display: none;
    }
  }`
    );
  }}
`;

export const ControlContainer = styled.div`
  padding-left: 4px;
  display: flex;
`;

export const SeparatorLine = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.greyDesign.onSurface3};
  align-self: center;
`;

interface TabStyleProps {
  $fillAvailableWidth?: boolean;
}

export const TabStyle = styled(Tab)<TabStyleProps>`
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  min-width: ${({ $fillAvailableWidth }) => ($fillAvailableWidth ? "auto" : "120px")};
  ${({ $fillAvailableWidth }) => $fillAvailableWidth && "flex-grow: 1"};
`;
