import { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { alphabetNameSorting } from "../_utils/utils";
import { ACCOUNT, ITEMS, PARENT_ID } from "../_constant/wordings";
import { getExistAssets } from "../components/PartsTabContainer/assets.query";

interface IUseGetAllRelatedParts {
  assetId: string;
  accountId: string | null;
}

const useGetAllRelatedParts = ({ assetId, accountId }: IUseGetAllRelatedParts) => {
  const [relatedParts, setRelatedParts] = useState([]);
  const [isPartsDataAvailable, setIsPartsDataAvailable] = useState(false);

  const { data, loading, error } = useQuery(getExistAssets, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data && !loading && !error) {
      const partsList = data[ACCOUNT][ITEMS].filter((asset) => asset[PARENT_ID] === assetId);
      setRelatedParts(partsList.sort(alphabetNameSorting));
      setIsPartsDataAvailable(true);
    }
  }, [data, loading, error, assetId]);

  return { isPartsDataAvailable, relatedParts, loading };
};

export default useGetAllRelatedParts;
