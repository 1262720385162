import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useRemoveItem from "./deleteAssetPopup/useRemoveItem";
import { useStaticDataQuery } from "../../../queries/useStaticDataQuery";

import { ROUTES } from "../../../_constant/screens";

import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import { RemoveDialogState } from "../../../components/AssetsList/AssetsList";

import { setSelectedAssets } from "../../../redux/actions/actions";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import BoardCardLayout from "./BoardCardLayout";
import DeleteAssetPopupWrapper from "./deleteAssetPopup/DeleteAssetPopupWrapper";

interface AssetItemProps {
  data: AssetRefsModel;
  additionalClassName?: any;
  customerId?: string | null;
  setFilter: (assetType?: string, items?: Set<string>, relatedItems?: any) => void;
}

const AssetItem = (props: AssetItemProps) => {
  const [deleteState, setDeleteState] = useState({
    isOpen: false,
    isLoading: false,
  });

  const handlePopupClose = useCallback(() => {
    setDeleteState((prev) => ({ ...prev, isOpen: false, isLoading: false }));
  }, []);

  const handleRemovePopupOpen = useCallback((props) => {
    setDeleteState((prev) => ({ ...prev, ...props }));
  }, []);

  const handleRemoveIsOver = useCallback(
    () => setDeleteState((prev) => ({ ...prev, isLoading: false })),
    [],
  );

  const staticData = useStaticDataQuery();
  const navigate = useNavigate();

  const handleSelectAsset = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate(`${ROUTES.SINGLE_ASSET}/${props.data.Id}`);
  };

  const handleSelectNewActivity = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`${ROUTES.CREATE_ACTIVITY}?item=${props.data.Id}`);
  };

  const handleSelectActivitiesList = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    const { AssetTypeRef, Id } = props.data;
    const itemsFilter: Set<string> = new Set();
    itemsFilter.add(Id);

    props.setFilter(AssetTypeRef.Id, itemsFilter);
    navigate(ROUTES.ACTIVITIES);
  };

  const {
    Name,
    AssetTypeRef,
    Description,
    MainImage,
    HasActivities,
    DefaultDescription,
    Id,
  } = props.data;
  const description = Description || DefaultDescription || "";

  const footerIcon = HasActivities ? "Icon-menu" : "Icon-add";
  const footerText = HasActivities ? "Activities List" : "Create activity for this item";
  const handleFooterClick = HasActivities ? handleSelectActivitiesList : handleSelectNewActivity;

  const handleRemove = () => {
    handleRemovePopupOpen({ isOpen: true, isLoading: true });
  };

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: handleRemove,
    },
  ];

  return (
    <>
      <BoardCardLayout
        name={Name}
        image={MainImage}
        alt={AssetTypeRef.Name}
        staticData={staticData}
        footerIcon={footerIcon}
        footerText={footerText}
        handleFooterClick={handleFooterClick}
        handleSelectCard={handleSelectAsset}
        moreActionOptions={options}
        isLoading={deleteState.isLoading}
      >
        <div className="Asset_subtitle">
          <i className="Icon">{AssetTypeRef.IconName}</i>
          <span className="Asset_type" title={AssetTypeRef.Name}>
            {AssetTypeRef.Name}
          </span>
        </div>
        <div className="Asset_description">{description}</div>
      </BoardCardLayout>
      {deleteState.isOpen && (
        <DeleteAssetPopupWrapper
          handlePopupClose={handlePopupClose}
          assetId={Id}
          assetName={Name}
          handleFinishPopupLoading={handleRemoveIsOver}
          customerId={props.customerId}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setFilter: (assetType?: string, assets?: Set<string>, relatedItems?: any) =>
    dispatch(setSelectedAssets(assetType, assets, relatedItems)),
});

export default connect(null, mapDispatchToProps)(AssetItem);
