import * as React from "react";
import { useState } from "react";
import AddNewActivitySection from "./AddNewActivitySection";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import { ShortActivityModel } from "../../../assets/_components/relaitedActivities/new/ShortActivityModel";
import { useEffect } from "react";
import { ActivitiesFieldName } from "../../../../_constant/constants";
import useGetAcitvitiesStatuses from "../../../../customHooks/api/useGetAcitvitiesStatuses";
import { VendorRefInputType } from "../../../../redux/models/data/VendorModel";

interface RelatedActivitiesProps {
  assetId?: string;
  isCustomize: boolean;
  hidden?: boolean;
  customerId?: string;
  visitId?: string;
  vendorId?: string | null;
  ActivityIds?: string[];
  projectId?: string | null;
  setActivityToProject?: (activityId: string) => void;
}

const RelatedActivities: React.FC<RelatedActivitiesProps> = ({
  assetId = "",
  hidden,
  isCustomize,
  customerId,
  visitId,
  children,
  vendorId,
  ActivityIds,
  projectId,
  setActivityToProject,
}) => {
  const { accountId } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();

  const [newActivity, setNewActivity] = useState(
    new ShortActivityModel({
      AssetId: assetId,
      AccountId: accountId,
      VendorRefs: vendorId ? [{ Id: vendorId }] : ([] as VendorRefInputType[]),
    }),
  );

  const [error, setError] = useState(new Map<string, string>());

  useEffect(() => {
    //component receive new props
    if (statuses.length > 0) {
      setNewActivity((prevState: any) => ({
        ...prevState,
        AssetId: assetId,
        VendorRefs: vendorId ? [{ Id: vendorId }] : ([] as VendorRefInputType[]),
        [ActivitiesFieldName.STATUS_ID]: statuses[0].Id,
        AccountId: accountId,
      }));
    }
  }, [statuses, accountId]);

  const updateNewActivity = (updateFields, target?) => {
    const nextActivity = Object.assign({}, newActivity, updateFields);

    if (updateFields === null && !!target) {
      const { id, value } = target;
      setNewActivity((prevValue) => ({ ...prevValue, [id]: value }));
      error.delete(target.id);
    } else {
      setError(new Map() as Map<string, string>);
      setNewActivity(nextActivity);
    }
  };

  const createNewActivity = () => {
    setNewActivity((previous) => ({
      ...new ShortActivityModel({
        AssetId: assetId,
        AccountId: accountId,
        [ActivitiesFieldName.STATUS_ID]: statuses[0].Id,
        VendorRefs: vendorId ? [{ Id: vendorId }] : ([] as VendorRefInputType[]),
      }),
      AssetId: previous.AssetId,
    }));
  };

  if (hidden) {
    return null;
  }

  return (
    <>
      {isCustomize && (
        <AddNewActivitySection
          newRelatedActivity={newActivity}
          updateRelatedActivity={updateNewActivity}
          handleActivityCreate={createNewActivity}
          errorInField={error}
          setError={setError}
          customerId={customerId}
          visitId={visitId}
          ActivityIds={ActivityIds}
          projectId={projectId}
          setActivityToProject={setActivityToProject}
        />
      )}
      {children}
    </>
  );
};

export default RelatedActivities;
