import React from "react";
import { useLayoutSwitcherContext } from "./useLayoutSwitcherContext";
import LayoutSwitcher from "./LayoutSwitcher";

interface LayoutSwitcherContainerProps {}

const LayoutSwitcherVendorContainer: React.FC<LayoutSwitcherContainerProps> = (props) => {
  const {
    state: { vendors },
    switchLayout,
  } = useLayoutSwitcherContext();

  return <LayoutSwitcher layout={vendors} switchLayout={switchLayout.vendors} />;
};

export default LayoutSwitcherVendorContainer;
