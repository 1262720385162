import React, { useState, useEffect } from "react";
import EditCustomField from "../AddCustomFieldContainer/EditCustomField";
import { displayErrorNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";

export interface ChangedFields {
  id: string | null;
  oldName: string;
  name: string;
}

export interface applyTypeChangeInterface {
  id: string | null;
  name: string;
  type: string;
}

interface CustomFieldsListCustomize {
  Schema: any[];
  CustomFields: any;
  onChange: (e, target: any) => void;
}

const initChangedFields = {
  id: null,
  oldName: "",
  name: "",
};

const CustomFieldsListCustomize: React.FC<CustomFieldsListCustomize> = (props) => {
  const { Schema, CustomFields, onChange } = props;

  const [changedFields, setChangedFields] = useState<ChangedFields>(initChangedFields);

  const handleChangeNameCustomField = ({ id, oldName, name }: any) => {
    setChangedFields((prev) => ({ ...prev, id, oldName, name }));
  };

  useEffect(() => {
    return setChangedFields(initChangedFields);
  }, []);

  const handleRemove = (fieldName: string) => {
    const newSchema = Schema.filter((element) => element.Name !== fieldName)
      .sort((a, b) => a.Order - b.Order)
      .map((element, index) => ({ ...element, Order: index + 1 }));

    const newCustomFields = { ...CustomFields };
    delete newCustomFields[fieldName];
    onChange(null, { id: "Schema", value: newSchema });
    onChange(null, { id: "CustomFields", value: newCustomFields });
  };

  const handelChangeFieldName = () => {
    let newSchema = [...Schema];
    const newCustomFields: any = { ...CustomFields };
    const { id, oldName, name } = changedFields;

    const currentField = newSchema.find((schema) => schema.Id === id || schema.Name === oldName);
    newSchema = newSchema.filter((schema) => schema.Id !== id || schema.Name !== oldName);

    if (!name) {
      return;
    }

    if (newSchema.some((schemaItem) => schemaItem.Name.toLowerCase() === name.toLowerCase())) {
      displayErrorNotification(NotificationTypes.CUSTOM_FIELD_ALREADY_EXIST);
      return;
    }

    newSchema.push({
      ...currentField,
      Name: name,
    });

    onChange(null, { id: "Schema", value: newSchema });
    const valueOfField = newCustomFields[oldName];
    delete newCustomFields[oldName];
    onChange(null, {
      id: "CustomFields",
      value: { ...newCustomFields, [name]: valueOfField },
    });

    setChangedFields(initChangedFields);
  };

  const handleChangeTypeCustomField = ({ name, type }: applyTypeChangeInterface) => {
    const newCustomFields: any = { ...CustomFields };

    const newSchema = Schema.map((schema) => {
      if (schema.Name === name) {
        return { ...schema, FieldType: type };
      }
      return schema;
    });

    onChange(null, { id: "Schema", value: newSchema });
    onChange(null, {
      id: "CustomFields",
      value: { ...newCustomFields, [name]: null },
    });
  };

  return (
    <>
      {Schema.sort((a, b) => a.Order - b.Order).map(({ Id, Name, FieldType }) => (
        <EditCustomField
          Name={changedFields.oldName === Name ? changedFields.name : Name}
          oldName={Name}
          FieldType={FieldType}
          setField={handleChangeNameCustomField}
          setTypeField={handleChangeTypeCustomField}
          apllyChange={handelChangeFieldName}
          key={Id || Name}
          Id={Id}
          handleRemove={() => handleRemove(Name)}
        />
      ))}
    </>
  );
};

export default CustomFieldsListCustomize;
