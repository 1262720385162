import React from "react";
import styled from "styled-components";

import WizardCard from "../WizardCard/WizardCard";

export const WizardItemScreenContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up("sm")} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`,
);

export const WizardItemCard = styled((props) => <WizardCard {...props} />)(
  ({ theme }) => `
    margin: 3px 0;

    ${theme.breakpoints.up("sm")} {
      margin: 3px;
      width: calc(50% - 6px);
    }
  `,
);
