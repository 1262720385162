import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import { useNavigate, useLocation } from "react-router-dom";

import useGetBusinessStatuses from "../../customHooks/useGetBusinessStatuses";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useCreateVisitMutation from "./useCreateVisitMutation";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";

import { VisitModel } from "../../redux/models/data/VisitModel";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { FILE_UPLOAD_RESULTS, ID, VISIT_POST } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";
import config from "../../../configs/statuses-config.json";

import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../redux/actions/_utils";
import { setEditFormIsDirty, updateVisitEmptyStatus } from "../../redux/actions/actions";

import VisitFormContainer from "../../components/VisitFormContainer/VisitFormContainer";
import { formatDateForSubmit } from "../../_utils/date";

const { EMPTY_BUSINESS } = config["statusNameMap"];

interface LocationState {
  customerId: string;
}

const NewVisit: React.FC = () => {
  const [data, setData] = useState(new VisitModel());
  const { accountId } = useGetAccountId();
  const navigate = useNavigate();
  const statuses = useGetBusinessStatuses();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const { successfulRedirect } = useSuccessfulRedirect();

  useEffect(() => {
    if (statuses.length > 0) {
      const url = new URL(window.location.href);
      const initialStatus = statuses.find((status: any) => status.Name === EMPTY_BUSINESS);
      // if we have parameter in URL
      setData(new VisitModel({ ...data, VisitStatusId: initialStatus.Id }));
    }
  }, [statuses]);

  useEffect(() => {
    if (location.state && location.state.customerId) {
      setData((prevState: VisitModel) => {
        return new VisitModel({
          ...prevState,
          CustomerId: location.state.customerId,
        });
      });
    }
  }, [location.state]);

  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
    pageTitle: "Create New Visit",
    headerType: "BUSINESS",
  });

  const [createVisit, { loading: isCreating }] = useCreateVisitMutation();

  const handleCreate = (data: VisitModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }

    const formatDueDateTime = formatDateForSubmit(data?.DueDateTime as string);
    const dataForPost = { ...data, DueDateTime: formatDueDateTime };

    createVisit(dataForPost)
      .then(({ data }: any) => {
        const uploadResult = data[VISIT_POST]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);
        dispatch(updateVisitEmptyStatus({ status: data.VisitPost.VisitStatusName, add: false }));

        if (data[VISIT_POST][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VisitModel(data[VISIT_POST]));
          displaySuccessNotification(NotificationTypes.VISIT_CREATED);
          const currentVisitPath = `${ROUTES.SINGLE_VISIT}/${data[VISIT_POST][ID]}`;
          navigate(currentVisitPath, {
            replace: true,
            state: { justCreated: true },
          });
        } else if (data[VISIT_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VisitModel(data[VISIT_POST]));
          successfulRedirect(ROUTES.VISITS, NotificationTypes.VISIT_CREATED);
        } else {
          displayErrorNotification(NotificationTypes.VISIT_CREATE_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.VISIT_CREATE_ERROR);
      });
  };

  return (
    <VisitFormContainer
      data={data}
      handleSubmit={handleCreate}
      isLoading={isCreating}
      isNew
      setHeaderOptions={setHeaderOptions}
    />
  );
};

export default NewVisit;
