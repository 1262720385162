import React, { useCallback, useState } from "react";
import styled from "styled-components";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, CircularProgress, DialogContent, Typography } from "@material-ui/core";

import MobileDialogSuccessLink from "../MobileDialogSuccessLink/MobileDialogSuccessLink";

import UploadTemplateZone from "./UploadTemplateZone";

import UploadDropzone from "./UploadDropzone";
import { AssetTypeModel } from "../../redux/models/data/AssetTypeModel";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { useGetItemsFromCsvFile } from "./uploadCsvDialogHooks";
import useDialogSuccessLinkState from "../MobileDialogSuccessLink/useDialogSuccessLinkState";

export const UploadDialog = styled((props) => (
  <Dialog disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="sm" {...props} />
))`
  overflow-y: scroll;

  .MuiDialog-paperFullWidth {
    width: calc(100% - 32px);
  }

  .MuiDialog-paper {
    margin: 16px;
    height: 100%;
    min-height: 300px;
    max-height: 500px;
  }

  .MuiDialog-scrollPaper {
    align-items: center;

    @media screen and (max-height: 400px) {
      align-items: flex-start;
    }
  }
`;

export const ButtonWithLoader = styled(Button)`
  position: relative;
  @media screen and (max-width: 400px) {
    height: 60px;
    margin: 0;
    width: 50%;
    display: flex;
    justify-content: center;
    border-radius: 0;
    &.MuiDialogActions-spacing {
      margin: 0;
    }
  }
`;

export const CancelButton = styled(Button)`
  margin-right: 1rem;
  @media screen and (max-width: 400px) {
    margin: 0;
    height: 60px;
    max-width: auto;
    width: 50%;
    border-right: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
    border-radius: 0;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
`;

export const UploadTitleBar = styled((props) => <DialogTitle disableTypography {...props} />)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

export const UploadDialogTitle = styled(Typography)`
  font-size: 1.5rem;
`;
export const UploadDialogFooter = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(1)}px;
  @media screen and (max-width: 400px) {
    padding: 0;
    justify-content: space-between;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 16px 24px 30px;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export type FileType = File | null;
export type UploadErrorType = Text | null;
export interface UploadCsvDialogProps {
  isOpen: Boolean;
  setIsOpen: (isOpen: Boolean) => void;
}

const UploadCsvDialog: React.FC<UploadCsvDialogProps> = ({ isOpen, setIsOpen }) => {
  const [itemType, setItemType] = useState(new AssetTypeModel({ Name: "", IconName: "" }));
  const [file, setFile] = useState<FileType>(null);
  const [uploadError, setUploadError] = useState<UploadErrorType>(null);
  const { isOpen: isOpenTemplateLinkDialog, onClose, url, onOpen } = useDialogSuccessLinkState();

  const { accountId } = useGetAccountId();

  const changeType = useCallback((itemType: AssetTypeModel) => {
    setItemType(
      () =>
        new AssetTypeModel({ Id: itemType.Id, IconName: itemType.IconName, Name: itemType.Name }),
    );
  }, []);

  const selectFileHanlder = useCallback((newFile: File | null) => {
    setFile(() => newFile);
  }, []);

  const handleCloseDialog = () => {
    setIsOpen(false);
    setFile(null);
  };

  const [uploadCSVFile, { uploadLoading }] = useGetItemsFromCsvFile();

  const uploadFile = () => {
    if (!file || !accountId) {
      return;
    }
    uploadCSVFile(file, setFile, setIsOpen, setUploadError);
  };

  return (
    <>
      <UploadDialog open={isOpen} onClose={handleCloseDialog}>
        <UploadTitleBar>
          <UploadDialogTitle>Upload CSV File</UploadDialogTitle>
        </UploadTitleBar>
        <DialogContentStyled dividers>
          <ContentWrapper>
            <UploadTemplateZone changeType={changeType} itemType={itemType} onOpen={onOpen} />
            <UploadDropzone
              selectFile={selectFileHanlder}
              file={file}
              uploadError={uploadError}
              setUploadError={setUploadError}
            />
          </ContentWrapper>
        </DialogContentStyled>
        <UploadDialogFooter disableSpacing>
          <CancelButton variant="text" onClick={handleCloseDialog}>
            Cancel
          </CancelButton>
          <ButtonWithLoader
            variant="text"
            color="primary"
            disabled={!file || !!uploadError || !!uploadLoading}
            onClick={uploadFile}
          >
            {uploadLoading && <StyledCircularProgress size={24} />}
            Upload
          </ButtonWithLoader>
        </UploadDialogFooter>
        <MobileDialogSuccessLink
          isOpen={isOpenTemplateLinkDialog}
          onClose={onClose}
          url={url}
          title="Download Template"
          contentText="Download CSV file template."
        />
      </UploadDialog>
    </>
  );
};

export default UploadCsvDialog;
