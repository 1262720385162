import React from "react";
import { useLayoutSwitcherContext } from "./useLayoutSwitcherContext";
import LayoutSwitcher from "./LayoutSwitcher";

interface LayoutSwitcherContainerProps {}

const LayoutSwitcherItemContainer: React.FC<LayoutSwitcherContainerProps> = (props) => {
  const {
    state: { items },
    switchLayout,
  } = useLayoutSwitcherContext();

  return <LayoutSwitcher layout={items} switchLayout={switchLayout.items} withPrintable />;
};

export default LayoutSwitcherItemContainer;
