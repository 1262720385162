import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { getCustomersQuery } from "../Customers/customersList.query";
import { ROUTES } from "../../_constant/screens";

import { composeImageUrl } from "../../_utils/utils";
import { setCustomerId } from "../../redux/actions/actions";
import { getSelectedCustomerId } from "../../redux/reducers/filterReducer";

import List from "@material-ui/core/List/List";
import Box from "@material-ui/core/Box/Box";

import FilterListItem from "./FilterListItem";

import { SectionHeaderText, StyledButton } from "./FilterVisitDesktop.style";

const CustomersFilterList: React.FC = () => {
  const { accountId } = useGetAccountId();

  const selectedCustomerId = useSelector(getSelectedCustomerId, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: customersData } = useQuery(getCustomersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });
  const customersList = customersData?.Account?.Customers ?? [];

  const handlerListItemClick = useCallback((payload) => dispatch(setCustomerId(payload)), []);

  const handleAddCustomerClick = () => {
    navigate(ROUTES.CREATE_CUSTOMER);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem"
        paddingBottom="0"
      >
        <SectionHeaderText>Customers: {customersList.length}</SectionHeaderText>
        <StyledButton variant="text" color="primary" onClick={handleAddCustomerClick}>
          Add new
        </StyledButton>
      </Box>
      <List>
        {customersList.map((customer: any) => {
          const customerImage = customer.Images?.length
            ? composeImageUrl(
                customer.Images[0].Id,
                customer.Images[0].ContainerName,
                customer.Images[0].ContainerId,
              )
            : "";
          const isCustomerSelected = selectedCustomerId === customer.Id;
          const customerName = `${customer?.Name ?? ""}`;
          return (
            <FilterListItem
              key={customer.Id}
              id={customer.Id}
              text={customerName}
              isActive={isCustomerSelected}
              imgUrl={customerImage}
              handleClick={handlerListItemClick}
            />
          );
        })}
      </List>
    </>
  );
};

export default CustomersFilterList;
