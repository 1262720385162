import React, { useState } from "react";
import { useMutation } from "react-apollo";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import usePersonalAccount from "../../../customHooks/usePersonalAccount";
import useAccountSwitch from "../../../customHooks/useAccountSwitch";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";

import deleteAccountQuery from "./deleteAccount.query";
import { deleteAccountCacheFn } from "./accountDeleteUpdateFn";

import { displayErrorNotification } from "../../../redux/actions/_utils";

import { ROUTES } from "../../../_constant/screens";
import { NotificationTypes } from "../../../_constant/NotificationTypes";

import InputField from "../../../components/InputField/InputField";

import "./DeleteAccountDialog.scss";
import { Box, CircularProgress } from "@material-ui/core";
import useSuccessfulRedirect from "../../../customHooks/useSuccessfulRedirect";

interface Props {
  isOpen: boolean;
  accountId: string;
  accountName: string;
  handleClose: () => void;
}

export const DeleteAccountDialog: React.FC<Props> = ({
  handleClose,
  isOpen,
  accountName,
  accountId,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isMatch, setIsMatch] = useState(false);

  const [handleDeleteAccount, { loading }] = useMutation(deleteAccountQuery);

  const { accountId: currentAccountId } = useGetAccountId();
  const { personalAccountId, accountsLoading } = usePersonalAccount();
  const { switchAccountById } = useAccountSwitch();
  const { successfulRedirect } = useSuccessfulRedirect();

  const handlerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setInputValue(value);
    if (value === accountName) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  };

  const handleDelete = () => {
    handleDeleteAccount({
      variables: { Id: accountId },
      update(cache, { data: { AccountDelete } }) {
        deleteAccountCacheFn(cache, AccountDelete.Id);
      },
    })
      .then(() => {
        if (currentAccountId && currentAccountId === accountId && !accountsLoading) {
          switchAccountById(personalAccountId);
        } else {
          successfulRedirect(ROUTES.ACCOUNTS, NotificationTypes.ACCOUNT_DELETED);
        }
      })
      .catch((err) => {
        displayErrorNotification(NotificationTypes.ACCOUNT_DELETE_ERROR);
        console.log(err);
      });
  };

  return (
    <Dialog
      className="dialog"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle disableTypography className="dialog__title">
        <ErrorOutlineIcon />
        <Typography variant="h6">Are you sure?</Typography>
      </DialogTitle>

      <DialogContent className="dialog__content" dividers>
        <Typography className="dialog__text">
          Deleting the <span style={{ color: "var(--color-brand)" }}>{accountName}</span> account is
          irreversible and will remove access to all its information.
        </Typography>
        <Typography className="dialog__subtext">
          To proceed, please type the account name below.
        </Typography>
        <Typography gutterBottom>
          <Box display={{ xs: "none", sm: "flex" }}>
            <InputField
              type="text"
              handleChange={handlerInput}
              value={inputValue}
              id="AccountName"
              label="Account name"
              fullWidth
              icon={<VerifiedUserOutlinedIcon />}
            />
          </Box>

          <Box display={{ xs: "flex", sm: "none" }}>
            <InputField
              type="text"
              handleChange={handlerInput}
              value={inputValue}
              id="AccountName"
              label="Account name"
              fullWidth
            />
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions className="dialog__actions">
        <Button
          className="dialog__action-button dialog__action-button--default"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="dialog__action-button dialog__action-button--primary"
          onClick={handleDelete}
          color="primary"
          disabled={!isMatch || loading}
        >
          {loading ? <CircularProgress size={22} /> : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
