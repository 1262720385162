import gql from "graphql-tag";

export const GET_USER_SUBSCRIPTION_SETTINGS = gql`
  query getUserSubscriptionSettings {
    User {
      Id
      SubscriptionSettings {
        LimitsToRender
      }
    }
  }
`;
