import * as React from "react";
import { useEffect, useState } from "react";
import { DateDialog } from "../../../../_common/Dialog";
import Button from "../../../../../components/Button";
import DateValue from "../../../../../components/DateValue/DateValue";
import DueDateTabs from "./DueDateTabs";
import { ActivityScheduleModel } from "../../../../../redux/models/data/ActivityScheduleModel";
import { FullActivityDueDate } from "../../../../../redux/models/data/ActivityModelFull";
import {
  checkIfDueDateValid,
  checkIfRecurringCountValid,
  checkIfRecurringValid,
  getDueDateFieldsWithError,
  getValidDateValue,
  validateDueDate,
} from "./forms/recurring/validation";
import { formatField, getEndAfterValue, isRecurring } from "../../../../../_utils/utils";
import { formatDateWithoutTZ } from "../../../../../_utils/date";
import {
  ActivitiesFieldName,
  DATE_DIALOG_SAVE_DUE_DATE,
  EndAfter,
} from "../../../../../_constant/constants";

import MaterialButton from "@material-ui/core/Button/Button";
import EventIcon from "@material-ui/icons/Event";
import { DueDateButton } from "./DueDateComponent.style";
import RepeatIcon from "@material-ui/icons/Repeat";

interface DueDateComponentProps {
  value: string;
  additionalProps: ActivityScheduleModel;
  relatedDueDateTime: string[];
  onChange: any;
}

const dateValue = {
  dueDate: "",
  relatedDueDate: [],
  schedule: new ActivityScheduleModel(),
  endRecurring: EndAfter.NO_LIMIT,
};

export const DateContext = React.createContext(dateValue);

export default function DueDateComponent(props: DueDateComponentProps) {
  // Date values
  const [dueDate, setValue] = useState(props.value);
  const [schedule, setSchedule] = useState(props.additionalProps);
  const [relatedDueDate, setRelatedDates] = useState(props.relatedDueDateTime);
  const [endRecurring, setEndRecurring] = useState(getEndAfterValue(props.additionalProps));
  // validation
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [errorFields, setFieldWithError] = useState(new Set<string>());
  // dialog
  const [dialogWindowState, setDialogWindowState] = useState(false);
  const [dialogLabel, setDialogLabel] = useState(DATE_DIALOG_SAVE_DUE_DATE);

  useEffect(() => {
    //component receive new props
    setValue(props.value);
    setSchedule(props.additionalProps);
    setRelatedDates(props.relatedDueDateTime);
    setEndRecurring(getEndAfterValue(props.additionalProps));
  }, [props.value, props.additionalProps, props.relatedDueDateTime]);

  const handleSubmit = () => {
    const sortedDatesArr = [dueDate, ...relatedDueDate]
      .sort((a, b) => formatField(a) - formatField(b))
      .filter((date) => date !== null);

    const errorFields = getDueDateFieldsWithError({
      dueDate,
      relatedDueDate,
      schedule,
      endRecurring,
    });
    if (errorFields.size > 0) {
      // if there are any errors -> highlight them with red
      setFieldWithError(errorFields);
      setIsSubmitBtnDisabled(true);
    } else {
      props.onChange(null, {
        id: ActivitiesFieldName.DUE_DATE_TIME,
        value: sortedDatesArr[0] ? sortedDatesArr[0] : null,
      });
      props.onChange(null, { id: "Schedule", value: schedule });
      props.onChange(null, { id: "RelatedDueDateTime", value: sortedDatesArr.slice(1) });
      setDialogWindowState(false);
    }
  };

  const handleValueChanged = (value: FullActivityDueDate) => {
    let nextErrFields = new Set(errorFields);
    if (value.hasOwnProperty("dueDate")) {
      value.dueDate = getValidDateValue(value.dueDate);
      setValue(value.dueDate);
      nextErrFields = value.hasOwnProperty("schedule")
        ? validateDueDate(value, nextErrFields)
        : new Set();
    }
    if (value.hasOwnProperty("relatedDueDate")) {
      setRelatedDates(value.relatedDueDate);
    }
    if (value.hasOwnProperty("schedule")) {
      setSchedule(value.schedule);
      nextErrFields = checkIfDueDateValid(value, nextErrFields);
      nextErrFields = checkIfRecurringCountValid(value, nextErrFields);
    }
    if (value.hasOwnProperty("endRecurring")) {
      setEndRecurring(value.endRecurring);
      nextErrFields = checkIfRecurringValid(value, nextErrFields);
    }
    setFieldWithError(nextErrFields);
    if (nextErrFields.size === 0) {
      setIsSubmitBtnDisabled(false);
    }
  };

  const handleInitValueChanged = (value: FullActivityDueDate) => {
    setSchedule(value.schedule);
    setEndRecurring(value.endRecurring);
    setRelatedDates(value.relatedDueDate);
  };

  const handleClose = () => {
    setValue(props.value);
    setSchedule(props.additionalProps);
    setRelatedDates(props.relatedDueDateTime);
    setFieldWithError(new Set<string>());
    setDialogWindowState(false);
  };

  const openDialog = () => {
    setDialogWindowState(true);
  };

  const dateValue = { dueDate, relatedDueDate, schedule, endRecurring };
  return (
    <>
      {props.value ? (
        <DateValue
          label="Due Date"
          className="notRequired"
          value={formatDateWithoutTZ(props.value)}
          onClick={openDialog}
        />
      ) : (
        <DueDateButton
          startIcon={<EventIcon fontSize="small" />}
          onClick={openDialog}
          variant="text"
          color="primary"
        >
          Due Date
        </DueDateButton>
      )}
      <DueDateButton
        startIcon={<RepeatIcon fontSize="small" />}
        onClick={openDialog}
        variant="text"
        color={isRecurring({ schedule, relatedDueDate }) ? "primary" : "default"}
      >
        Recurring
      </DueDateButton>
      {dialogWindowState && (
        <DateDialog
          cancel={handleClose}
          submit={handleSubmit}
          submitDisabled={isSubmitBtnDisabled}
          okLabel={dialogLabel}
        >
          <DateContext.Provider value={dateValue}>
            <DueDateTabs
              onChange={handleValueChanged}
              setProps={handleInitValueChanged}
              errorFields={errorFields}
              setDialogLabel={setDialogLabel}
              clearErrorFields={() => {
                setFieldWithError(new Set<string>());
                setIsSubmitBtnDisabled(false);
              }}
            />
          </DateContext.Provider>
        </DateDialog>
      )}
    </>
  );
}
