import gql from "graphql-tag";

// Related to Activities
export const deleteActivityQuery = gql`
  mutation deleteActivity($AccountId: ID, $Id: ID) {
    AssetActivityDelete(AccountId: $AccountId, Id: $Id) {
      Id
      Asset {
        Id
        AssetActivities(Take: 1) {
          Id
        }
      }
      StatusId
    }
  }
`;
