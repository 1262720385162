import React, { useEffect, useState } from "react";
import InputRow from "../../../components/InputRow/InputRow";
import { CircularProgress, IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Input from "../../../components/Input/Input";
import Grid from "@material-ui/core/Grid/Grid";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { VisitModel } from "../../../redux/models/data/VisitModel";
import useCreateVisitMutation from "../../../pages/NewVisit/useCreateVisitMutation";
import useGetBusinessStatuses from "../../../customHooks/useGetBusinessStatuses";
import config from "../../../../configs/statuses-config.json";
import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import AssignUserSelect from "../../../components/AssignUserSelect/AssignUserSelect";

const { EMPTY_BUSINESS } = config["statusNameMap"];

interface AddNewVisitSectionProps {
  customerId: string;
}

const AddNewVisitSection: React.FC<AddNewVisitSectionProps> = ({ customerId }) => {
  const statuses = useGetBusinessStatuses();
  const [data, setData] = useState(new VisitModel());
  const [error, setError] = useState(new Map<string, string>());
  const [createVisit, { loading: isCreating }] = useCreateVisitMutation();

  useEffect(() => {
    if (statuses.length > 0) {
      const initialStatus = statuses.find((status: any) => status.Name === EMPTY_BUSINESS);
      setData((prevData: VisitModel) => {
        return new VisitModel({
          ...prevData,
          VisitStatusId: initialStatus.Id,
          CustomerId: customerId,
        });
      });
    }
  }, [statuses, customerId]);

  const onSubmitHandler = () => {
    const newError = new Map(error);
    if (data.Purpose) {
      newError.delete("Purpose");
    } else {
      newError.set("Purpose", "Required field");
    }

    setError(newError);

    if (newError.size > 0) return;

    createVisit(data)
      .then(() => {
        setData((prevData: VisitModel) => {
          return new VisitModel({
            ...prevData,
            Purpose: "",
            AssignedUserId: null,
          });
        });
        displaySuccessNotification(NotificationTypes.VISIT_CREATED);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.VISIT_CREATE_ERROR);
      });
  };

  const renderAddButton = () => (
    <IconButton
      style={{ marginBottom: "-8px", marginRight: "-8px", padding: "8px" }}
      onClick={onSubmitHandler}
      color="primary"
      disabled={isCreating}
    >
      {isCreating ? <CircularProgress size={22} /> : <AddCircleOutlineIcon />}
    </IconButton>
  );

  const onChangeHandler = (e, target) => {
    if (e) {
      const { id, value } = e.target;
      setData((prevData: VisitModel) => {
        return new VisitModel({
          ...prevData,
          [id]: value,
        });
      });
    } else {
      setData((prevData: VisitModel) => {
        return new VisitModel({
          ...prevData,
          [target.id]: target.value,
        });
      });
    }
  };
  return (
    <section className="Section Section_withBorder Section_defaultBackground">
      <InputRow alignItems="flex-end" actionZoneComponent={renderAddButton}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              autoComplete="off"
              label="Purpose*"
              id="Purpose"
              value={data.Purpose}
              icon={<CreateOutlinedIcon />}
              isDisabled={false}
              error={error.has("Purpose")}
              errorText={error.get("Purpose")}
              onChange={onChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AssignUserSelect
              assignedUserId={data.AssignedUserId}
              handleFieldChange={onChangeHandler}
            />
          </Grid>
        </Grid>
      </InputRow>
    </section>
  );
};

export default AddNewVisitSection;
