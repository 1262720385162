import styled from "styled-components";

import { ReactComponent as NoInternetImage } from "../../../images/pic_no_internet.svg";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const Image = styled(NoInternetImage)`
  width: 220px;
  height: 220px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const MessageTitle = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.main};
`;

export const MessageText = styled.p`
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
