import { getAccountRefsList } from "../../../pages/Accounts/getAccountList.query";
import { ACCOUNT_REFS, USER } from "../../../_constant/wordings";
import { AccountModel } from "../../../redux/models/data/AccountModel";

export const deleteAccountCacheFn = (cache: any, accountId: string): void => {
  const accountsQuery = cache.readQuery({ query: getAccountRefsList });
  accountsQuery[USER][ACCOUNT_REFS] = accountsQuery[USER][ACCOUNT_REFS].filter(
    (account: AccountModel) => account.Id !== accountId,
  );

  cache.writeQuery({
    query: getAccountRefsList,
    data: { User: { ...accountsQuery[USER], AccountRefs: accountsQuery[USER][ACCOUNT_REFS] } },
  });
};
