import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";

export const FakeItemCardContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 6px;
  background-color: ${theme.palette.background.paper};
  min-width: 210px;
  border-radius: ${theme.shape.borderRadius}px;
  box-shadow: ${theme.shadows[4]};
`,
);

export const FakeItemCardImagePlaceholder = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: ${theme.palette.primary.light};
  border-radius: ${theme.shape.borderRadius}px;
`,
);

export const FakeItemCardAvatar = styled(Avatar)(
  ({ theme }) => `
width: 50px;
height: 50px;
background-color: ${theme.palette.primary.medium};
`,
);

export const FakeItemCardBody = styled.div`
  padding: 8px 12px;
`;

export const FakeItemCardTitle = styled((props) => <Typography component="h3" noWrap {...props} />)(
  ({ theme }) => `
    margin: 0;
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 4px;
`,
);

export const FakeItemCardType = styled((props) => (
  <Typography component="p" noWrap {...props} />
))(
  ({ theme }) => `
    margin: 0;
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${theme.palette.primary.main};
    vertical-align: middle;

    .Icon {
      position: relative;
      top: -1px;
      font-size: 0.85rem;
    }
`,
);
