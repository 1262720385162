import styled from "styled-components";
import React from "react";

import Select from "@material-ui/core/Select";
import { alignmentTypes } from "./Select";

type StyledSelectProps = {
  withoutUnderline?: boolean;
};

export const SelectContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

type SelectMediaProps = {
  alignment?: alignmentTypes;
  disableGutters: boolean;
  withoutControl?: boolean;
};

export const SelectMedia = styled.div<SelectMediaProps>`
  max-width: 24px;
  display: flex;
  align-items: ${({ alignment }) => {
    switch (alignment) {
      case "start":
        return "flex-start";
      case "center":
        return "center";
      case "end":
        return "flex-end";
    }
  }};
  margin-right: ${({ withoutControl }) => (!withoutControl ? "16px" : "0")};
  padding: ${({ disableGutters }) => (disableGutters ? "0" : "6px 0")};

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }

  .MuiAvatar-root {
    width: 24px;
    height: 24px;
    font-size: 14px;
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export const StyledSelect = styled(({ withoutUnderline, ...rest }) => (
  <Select {...rest} />
))<StyledSelectProps>`
  &::before {
    display: ${({ withoutUnderline }) => (withoutUnderline ? "none" : "block")};
  }

  .MuiSelect-icon {
    width: 0.75rem;
    height: 0.75rem;
    top: calc(50% - 0.373rem);
  }
  .MuiListItem-root {
    padding: 0;
  }

  .MuiListItemAvatar-root {
    display: none;
  }

  .MuiListItemText-primary {
    font-size: 0.875rem;
    line-height: 0.7;
  }

  .MuiSelect-selectMenu {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
