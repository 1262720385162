import * as React from "react";
import { toastr } from "react-redux-toastr";

import { useState } from "react";
import { useMutation } from "react-apollo";

import { InvitationModel } from "../../redux/models/data/InvitationModel";

import { acceptInvitationQuery } from "../../pages/Accounts/acceptInvitation.query";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { updateCacheWithNewAccount } from "../../pages/NewAccount/updateCacheWithNewAccount";
import {
  addInvitationToHistoryList,
  removeInvitationFromInvitationList,
} from "../../pages/Accounts/updateCacheInvitation";

import { declineInvitationMutation } from "../../pages/Accounts/declineInvitation.query";

import { ACCEPT_INVITE, DECLINE_INVITE, ID } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import InvitationItemLayout from "./InvitationItemLayout";

import "./InvitationItem.scss";

interface IInvitationItem {
  invitation: InvitationModel;
  declineCallback: () => void;
}

const InvitationItem: React.FC<IInvitationItem> = (props) => {
  const {
    invitation: { Id: InvitationId },
    declineCallback,
  } = props;
  const [isOpen, setOpen] = useState(false);

  const [acceptMutation, { loading: acceptLoading }] = useMutation(acceptInvitationQuery);
  const [declineMutation, { loading: declineLoading }] = useMutation(declineInvitationMutation);

  const handleDetailClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(!isOpen);
  };

  const handleAccept = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    acceptMutation({
      variables: { InvitationId },
      update(cache, { data: { AcceptInvite } }) {
        updateCacheWithNewAccount(cache, AcceptInvite);
        // update invitation list
        removeInvitationFromInvitationList(cache, InvitationId);
      },
    })
      .then(({ data }: any) => {
        if (data[ACCEPT_INVITE][ID]) {
          displaySuccessNotification(NotificationTypes.INVITATION_ACCEPTED);
        } else {
          displayErrorNotification(NotificationTypes.INVITATION_ACCEPT_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error(message);
        } else {
          displayErrorNotification(NotificationTypes.INVITATION_ACCEPT_ERROR);
        }
      });
  };

  const handleDecline = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    declineMutation({
      variables: { InvitationId },
      update(cache, { data: { DeclineInvite } }) {
        removeInvitationFromInvitationList(cache, DeclineInvite[ID]);
        addInvitationToHistoryList(cache, DeclineInvite);
      },
    })
      .then(({ data }: any) => {
        if (data[DECLINE_INVITE][ID]) {
          displaySuccessNotification(NotificationTypes.INVITATION_DECLINED);
          declineCallback();
        } else {
          displayErrorNotification(NotificationTypes.INVITATION_DECLINE_ERROR);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.INVITATION_DECLINE_ERROR);
      });
  };

  return (
    <InvitationItemLayout
      invitation={props.invitation}
      isOpen={isOpen}
      acceptLoading={acceptLoading}
      declineLoading={declineLoading}
      handleDetailClick={handleDetailClick}
      handleAccept={handleAccept}
      handleDecline={handleDecline}
      isHistory={false}
    />
  );
};

export default InvitationItem;
