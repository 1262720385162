import styled from "styled-components";

import { Button, FormControlLabel, Typography } from "@material-ui/core";

export const PrintableViewContainer = styled.div`
  display: flex;

  max-width: 780px;
  width: 100%;
  height: 32px;
  margin: 0 auto 1.5rem;
  padding-left: 8px;

  .MuiSvgIcon-root {
    font-size: 1rem;
  }

  .MuiBox-root {
    margin-left: auto;
  }

  @media screen and (max-width: 700px) {
    justify-content: space-between;
    padding: 0 10px;
  }

  @media print {
    display: none;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    padding: 0 8px;
    margin: 0;
  }

  .MuiTypography-root {
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.primary.main};
    user-select: none;
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-right: 10px;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const InfoButton = styled(Button)`
  margin-left: auto;
`;

export const PrintButton = styled(Button)`
  /* padding-right: 0; */
`;

export const SeparatorLine = styled.div`
  width: 1px;
  height: 1rem;
  margin: 0 42px;
  background: ${({theme}) => theme.palette.primary.main};
  align-self: center;
`;

export const PopoverContainer = styled.section`
  width: 300px;
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const PopoverTitle = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 500;
`;

export const PopoverText = styled(Typography)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.greyDesign.onSurface2};
`;

export const StyledList = styled.ol`
  margin: 0.5rem 0 0 0;
  padding: 0 0 0 1rem;
`;

export const StyledListItem = styled.li`
  ::marker {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.greyDesign.onSurface2};
  }
`;
