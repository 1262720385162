import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  subscriptionValues,
  useGetUserSubscriptionTier,
} from "../customHooks/useGetUserSubscriptionTier";

import { CREATE_NEW_PATH, ROUTES } from "../_constant/screens";

import Business from "../pages/Business/Business";
import NewBusinessAccount from "../pages/Business/NewBusinessAccount";
import EditBusinessAccount from "../pages/Business/EditBusinessAccount";

const BusinessRoutes: React.FC = () => {
  const userSubscriptionTier = useGetUserSubscriptionTier();
  const enableBusinessAccounts = userSubscriptionTier.value === subscriptionValues.professional;

  if (!enableBusinessAccounts && !userSubscriptionTier.loading) {
    return <Navigate to={ROUTES.SETTINGS} />;
  }

  return (
    <Routes>
      <Route path="/" element={<Business />} />
      <Route path={CREATE_NEW_PATH} element={<NewBusinessAccount />} />
      <Route path="edit" element={<EditBusinessAccount />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default BusinessRoutes;
