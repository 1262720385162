import * as React from "react";
import * as moment from "moment";
import { useState } from "react";
import { isSameDay } from "react-dates";
import Button from "../../../../../../../components/Button";
import { Calendar } from "../../../../../../../components/Calendar";
import ScheduledDateInput from "./ScheduledDateInput";
import { FullActivityDueDate } from "../../../../../../../redux/models/data/ActivityModelFull";
import {DATE_FORMAT, getDateWithUpdatedTime, incrementDateDays, setCurrentTime} from "../../../../../../../_utils/date";
import {formatField} from "../../../../../../../_utils/utils";
import {useContext} from "react";
import {DateContext} from "../../DueDateComponent";
import NestedCalendarDialog from "../NestedCalenderDialog";

interface ScheduledFormProps {
  onChange: (value: FullActivityDueDate) => void;
}

export default function ScheduledForm(props: ScheduledFormProps) {
  const {dueDate, relatedDueDate} = useContext(DateContext);
  const [activeInput, setActiveInput] = useState(0);
  const datesArr = relatedDueDate ? [dueDate, ...relatedDueDate] : [dueDate];
  const [scheduledCalendarVisible, setScheduledCalendarVisible] = useState(false);

  const handleAddNew = () => {
    const datesArrLength = datesArr.length;

    //if last date value is empty
    if (!datesArr[datesArrLength - 1]) {
      // check if the value before exists and calculate last date value
      datesArr[datesArrLength - 1] = datesArr[datesArrLength - 2] ?
        incrementDateDays(datesArr[datesArrLength - 2], 1).toISOString()
        :
        moment().toISOString();
    }

    datesArr.sort((a, b) => formatField(a) - formatField(b));
    datesArr.push(null);
    props.onChange({ dueDate: datesArr[0], relatedDueDate: datesArr.slice(1) });
    setActiveInput(datesArr.length - 1);
  };

  const handleDatePick = (date) => {
    datesArr[activeInput] = setCurrentTime(date).toISOString();
    props.onChange({ dueDate: datesArr[0], relatedDueDate: datesArr.slice(1) });
  };

  const handleClearDate = (index) => {
    if (relatedDueDate && relatedDueDate.length === 0) {
      props.onChange({ dueDate: null, relatedDueDate: null });
      setActiveInput(0);
    } else {
      datesArr.splice(index, 1);
      const nextDueDate = datesArr[0];
      datesArr.splice(0, 1);
      const nextRelatedDueDate = datesArr.length === 0 ? null : datesArr;
      props.onChange({ dueDate: nextDueDate, relatedDueDate: nextRelatedDueDate });
    }
  };

  const handleChange = ({target}) => {
    const relatedDueDate = [...datesArr];
    relatedDueDate[+target.name] = getDateWithUpdatedTime(target.value);
    // split Due Date and rest dates
    const dueDate = relatedDueDate[0];
    relatedDueDate.splice(0, 1);
    // update in parent
    props.onChange({dueDate, relatedDueDate});
  };

  return (
    <div className="ScheduledForm TwoColumnContent">
      <div className="TwoColumnContent_formColumn TwoColumnContent_formColumn__withScroll">
        <ScheduledDateInput
          datesArr={datesArr}
          onChange={props.onChange}
          onClear={handleClearDate}
          setActive={setActiveInput}
          active={activeInput}
          showCalendar={() => setScheduledCalendarVisible(true)}
        />
        <Button type={"link"} icon={"add"} label={"Add Occurrence"} onClick={handleAddNew} />
        {scheduledCalendarVisible
        && <NestedCalendarDialog
            value={datesArr[activeInput]}
            onChange={(date) => handleDatePick(date)}
            handleBlur={handleChange}
            label="Due Date"
            name={activeInput.toString()}
            handleClose={() => setScheduledCalendarVisible(false)}
            dialogTitle={"Schedule date pick"}
          />
        }
      </div>
      <div className="TwoColumnContent_calendarColumn">
        <div className="ScheduledForm__calendar">
          <Calendar
            handleChange={(date) => handleDatePick(date)}
            initValue={`${setCurrentTime(datesArr[activeInput])?.toISOString()}`}
            numberOfMonths={1}
            isDayHighlighted={(day1) =>
              datesArr.some((day2) =>
                isSameDay(
                  moment(day1, [DATE_FORMAT, moment.ISO_8601], true),
                  moment(day2, [DATE_FORMAT, moment.ISO_8601], true),
                ),
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
