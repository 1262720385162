import * as React from "react";

import { GlobalLoader } from "../../components/Loader";

import useGetAccountId from "../../customHooks/useGetAccountId";
import useInitialPath from "../../customHooks/useInitialPath";

const AppRedirect = () => {
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const { optionalRedirect } = useInitialPath();

  optionalRedirect({ accountId, IsCustomerManagement }, true);

  return <GlobalLoader />;
};

export default AppRedirect;
