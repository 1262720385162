import { ROUTES } from "../../_constant/screens";

const EDIT_PAGE_ROUTES = [
  ROUTES.SINGLE_ASSET,
  ROUTES.SINGLE_ACTIVITY,
  ROUTES.EDIT_PROFILE,
  ROUTES.NEW_ACCOUNT,
  ROUTES.SINGLE_ACCOUNT,
  ROUTES.SINGLE_CUSTOMER,
  ROUTES.CREATE_CUSTOMER,
  ROUTES.SINGLE_VISIT,
  ROUTES.CREATE_VISIT,
  ROUTES.SINGLE_VENDOR,
  ROUTES.CREATE_VENDOR,
  ROUTES.BUSINESS,
  ROUTES.SINGLE_PROJECT,
  ROUTES.CREATE_PROJECT,
];

const ITEM_FILTERING_PAGES_ROUTES = [ROUTES.ASSETS, ROUTES.ACTIVITIES];
const ITEM_SEARCH_PAGES_ROUTES = [
  ROUTES.ASSETS,
  ROUTES.ACTIVITIES,
  ROUTES.ACCOUNTS,
  ROUTES.VISITS,
  ROUTES.CUSTOMERS,
];

const PAGE_WITHOUT_SIDE_MENU = [ROUTES.VENDORS, ROUTES.PROJECTS];

export function isEditPage(path: string): boolean {
  return isPartOfTheRoutes(EDIT_PAGE_ROUTES, path);
}

export function isPageWithoutSideMenu(path: string): boolean {
  return isPartOfTheRoutes(PAGE_WITHOUT_SIDE_MENU, path);
}

export function isPageWithItemFiltering(path: string): boolean {
  return isPartOfTheRoutes(ITEM_FILTERING_PAGES_ROUTES, path);
}

export function isPageWithItemSearchByTags(path: string): boolean {
  return isPartOfTheRoutes([ROUTES.ASSETS], path);
}

export function isPageWithSearch(path: string): boolean {
  return isPartOfTheRoutes(ITEM_SEARCH_PAGES_ROUTES, path);
}

function isPartOfTheRoutes(arr: string[], path: string): boolean {
  return arr.filter((url) => path.includes(`${url}/`) || path === url).length !== 0;
}

export function isAuthenticated() {
  return !!localStorage.getItem("access_token");
}
