import styled from "styled-components";
import Typography from "@material-ui/core/Typography/Typography";

export const TabForNewEntityContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0 84px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: 32px 0;
  }
`;

interface TabForNewEntityImageProps {
  url: string;
}

export const TabForNewEntityImage = styled.div<TabForNewEntityImageProps>`
  width: 250px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ url }) => `url(${url})`};

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 182px;
    height: 126px;
  }
`;

export const TabForNewEntityMessage = styled(Typography)`
  margin: 24px 0;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => `${theme.palette.text.secondary}`};

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin: 20px 0 0;
    font-size: 0.875rem;
  }
`;
