import React from "react";

// Styles
import { StyledContainer, StyledList } from "./VendorsList.style";

// Custom Hooks
import { useGetActivityVendors } from "../../customHooks/useGetActivityVendors";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

// Components
import { LoaderCircle } from "../Loader";
import { VendorControlsLayout } from "./VendorControlsLayout/VendorControlsLayout";
import { ListItem } from "./VendorListItem/VendorListItem";
import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";

// Types
import { VendorsListProps } from "./VendorList.types";

// Utils
import { composeImageUrl } from "../../_utils/utils";

export const VendorsList: React.FC<VendorsListProps> = ({
  vendorRefs,
  isCustomize,
  setActivity,
}) => {
  const { vendorsList, allVendorsOptions, loading } = useGetActivityVendors(vendorRefs);
  const staticData = useStaticDataQuery();
  const vendorImage = staticData ? staticData?.Icons?.Desktop?.pic_vendor : "";

  const emptyCustomFieldsText = `There are no \n vendors`;

  if (isCustomize) {
    return (
      <>
        <VendorControlsLayout
          allVendorsOptions={allVendorsOptions}
          setActivity={setActivity}
          vendorRefs={vendorRefs}
          inputType="combobox"
          buttonType="add"
        />
        {!!vendorsList?.length && (
          <StyledList divided>
            {vendorsList.map(({ Id, Name, Images, Email, Phone, Address, Website }) => (
              <VendorControlsLayout
                transparentBg
                vendorRefs={vendorRefs}
                setActivity={setActivity}
                buttonType="remove"
                vendorId={Id}
                key={Id}
              >
                <ListItem
                  vendorId={Id}
                  title={Name}
                  img={
                    Images && Images.length
                      ? composeImageUrl(
                          Images[0].Id,
                          Images[0].ContainerName,
                          Images[0].ContainerId,
                        )
                      : vendorImage
                  }
                  email={Email}
                  phone={Phone}
                  address={Address}
                  website={Website}
                />
              </VendorControlsLayout>
            ))}
          </StyledList>
        )}

        {!loading && !vendorsList.length ? (
          <EmptyCustomFieldState emptyFieldsMessage={emptyCustomFieldsText} />
        ) : null}
      </>
    );
  }

  if (!loading && !vendorsList.length) {
    return <EmptyCustomFieldState emptyFieldsMessage={emptyCustomFieldsText} />;
  }

  return (
    <>
      <StyledContainer
        display={loading ? "flex" : "block"}
        alignItems="center"
        justifyContent="center"
      >
        {loading ? (
          <LoaderCircle size="l" />
        ) : (
          <StyledList rounded>
            {vendorsList.map(({ Id, Name, Images, Email, Phone, Address, Website }) => (
              <ListItem
                vendorId={Id}
                key={Id}
                title={Name}
                img={
                  Images && Images.length
                    ? composeImageUrl(Images[0].Id, Images[0].ContainerName, Images[0].ContainerId)
                    : vendorImage
                }
                email={Email}
                phone={Phone}
                address={Address}
                website={Website}
              />
            ))}
          </StyledList>
        )}
      </StyledContainer>
    </>
  );
};
