import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import { RemoveDialogState } from "../AssetsList/AssetsList";

import { ROUTES } from "../../_constant/screens";

import { composeImageUrl, getAssetImage } from "../../_utils/utils";
import { setSelectedAssets } from "../../redux/actions/actions";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { RemoveCircleOutlineOutlined } from "@material-ui/icons";

import { Box, Hidden, Typography } from "@material-ui/core";

import MoreAction from "../MoreAction/MoreAction";
import CardBackdrop from "../CardBackdrop/CardBackdrop";

import { CardContainer, CardParent, CardRowMedia, MoreButtonContainer } from "./styled/card.styled";
import DeleteAssetPopupWrapper from "../../containers/assets/_components/deleteAssetPopup/DeleteAssetPopupWrapper";

interface AssetCardRowProps {
  item: AssetRefsModel;
  additionalInfo?: any;
  customerId?: string | null;
  onClick?: (() => void) | null;
  hideMoreAction?: boolean;
  withoutRedirect?: boolean;
  isCardInPartsTab?: boolean;
  disableNavigationByClick?: boolean;
  handleRemove?: ({
    removedPartId,
    setLoadingStatus,
  }: {
    removedPartId: string;
    setLoadingStatus: (loadingStatus: boolean) => void;
  }) => void | undefined;
  hideRemoveOption?: boolean;
}

const AssetCardRow: React.FC<AssetCardRowProps> = ({
  item,
  additionalInfo,
  onClick: onClickFromProps,
  customerId,
  hideMoreAction,
  withoutRedirect,
  isCardInPartsTab,
  disableNavigationByClick,
  handleRemove,
  hideRemoveOption,
}) => {
  const { Id, Name, AssetTypeRef, MainImage, HasActivities } = item;

  const [deleteState, setDeleteState] = useState({
    isOpen: false,
    isLoading: false,
  });

  const handlePopupClose = useCallback(() => {
    setDeleteState({ isOpen: false, isLoading: false });
  }, []);

  const handleRemovePopupOpen = useCallback((props: RemoveDialogState) => {
    setDeleteState((prev) => ({ ...prev, ...props }));
  }, []);

  const handleFinishPopupLoading = useCallback(
    () => setDeleteState((prev) => ({ ...prev, isLoading: false })),
    [],
  );

  const navigate = useNavigate();
  const staticData = useStaticDataQuery();

  const onClick = () => {
    if (disableNavigationByClick) return;
    navigate(`${ROUTES.SINGLE_ASSET}/${Id}`, { state: { goToEditPrevAsset: isCardInPartsTab } });
  };

  const handleDelete = () => {
    handleRemovePopupOpen({ assetId: Id, assetName: Name, isOpen: true, isLoading: true });
  };

  const setLoadingStatus = (loadingStatus: boolean) => {
    setDeleteState({ isOpen: false, isLoading: loadingStatus });
  };

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: handleDelete,
    },
    {
      text: "Remove Part",
      icon: <RemoveCircleOutlineOutlined fontSize="small" />,
      callback: () => handleRemove && handleRemove({ removedPartId: Id, setLoadingStatus }),
    },
  ];

  return (
    <>
      <CardContainer
        className="related-asset-card"
        padding={1}
        onClick={onClickFromProps || onClick}
        $isLoading={deleteState.isLoading}
      >
        <Box mr={1}>
          <CardRowMedia
            variant="rounded"
            src={
              MainImage
                ? composeImageUrl(MainImage.Id, MainImage.ContainerName, MainImage.ContainerId)
                : getAssetImage(staticData, AssetTypeRef.Name)
            }
          />
        </Box>
        <Box>
          <Typography variant="body2" component="h3" noWrap>
            {Name}
          </Typography>
          <CardParent>
            <i className="Icon">{AssetTypeRef.IconName}</i>
            {AssetTypeRef.Name}
          </CardParent>
        </Box>
        <Hidden xsDown>{additionalInfo}</Hidden>
        <MoreButtonContainer>
          {!hideMoreAction && (
            <MoreAction
              options={customerId || hideRemoveOption ? options.slice(0, 1) : options}
              isLoading={deleteState.isLoading}
            />
          )}
        </MoreButtonContainer>
        <CardBackdrop open={deleteState.isLoading} />
      </CardContainer>
      {deleteState.isOpen && (
        <DeleteAssetPopupWrapper
          handlePopupClose={handlePopupClose}
          assetId={Id}
          assetName={Name}
          handleFinishPopupLoading={handleFinishPopupLoading}
          customerId={customerId}
          withoutRedirect={withoutRedirect}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, assets?: Set<string>, relatedItems?) =>
    dispatch(setSelectedAssets(assetType, assets, relatedItems)),
});

export default connect(null, mapDispatchToProps)(AssetCardRow);
