import React from "react";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import {
  TabForNewEntityContainer,
  TabForNewEntityImage,
  TabForNewEntityMessage,
} from "./TabForNewEntityStyle";

interface TabForNewEntityProps {
  entityName: string;
}

const TabForNewEntity: React.FC<TabForNewEntityProps> = ({ entityName }) => {
  const staticData = useStaticDataQuery();
  const newPageImage = staticData ? staticData.Empty.pic_confirmation_for_tabs : "";

  return (
    <TabForNewEntityContainer>
      <TabForNewEntityImage url={newPageImage} />
      <TabForNewEntityMessage>
        Please save your {entityName}
        <br />
        to get additional functionality
      </TabForNewEntityMessage>
    </TabForNewEntityContainer>
  );
};

export default TabForNewEntity;
