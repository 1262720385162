import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  WizardItemTypeCard,
  WizardItemTypeContainer,
  WizardItemTypeHeader,
  WizardItemTypeHeaderAction,
  WizardItemTypeList,
} from "./WizardItemTypeStyles";

import { AssetTypeModel } from "../../../redux/models/data/AssetTypeModel";

import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";
import SearchIcon from "@material-ui/icons/Search";

interface WizardItemTypeScreenProps {
  newItem: AssetForWizardModel;
  handleChangeView: () => void;
  itemTypes: AssetTypeModel[];
  handleClick: (id: string) => void;
}

const WizardItemTypeScreen: React.FC<WizardItemTypeScreenProps> = ({
  newItem,
  itemTypes,
  handleChangeView,
  handleClick,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const loadingList = Array.from(Array(isDesktop ? 10 : 4).keys()).map((item) => (
    <WizardItemTypeCard
      key={item}
      variant={isDesktop ? "card" : "row"}
      isLoading
      iconName={isDesktop ? "icon" : undefined}
    />
  ));

  return (
    <WizardItemTypeContainer>
      <WizardItemTypeHeader>
        <div>Select the category that best fits the item you want to maintain:</div>
        <WizardItemTypeHeaderAction onClick={handleChangeView} startIcon={<SearchIcon />}>
          Search
        </WizardItemTypeHeaderAction>
      </WizardItemTypeHeader>
      <WizardItemTypeList>
        {itemTypes.length
          ? itemTypes.map((itemType) => (
              <WizardItemTypeCard
                key={itemType.Id}
                isSelected={newItem.AssetType?.Id === itemType.Id}
                onClick={() => handleClick(itemType.Id)}
                variant={isDesktop ? "card" : "row"}
                iconName={itemType.IconName}
              >
                {itemType.Name}
              </WizardItemTypeCard>
            ))
          : loadingList}
      </WizardItemTypeList>
    </WizardItemTypeContainer>
  );
};

export default WizardItemTypeScreen;
