import gql from "graphql-tag";

export const getEssentialActivityFieldsQuery = gql`
  query getActivityById($Id: ID, $ActivityId: ID) {
    Account(Id: $Id) {
      Id
      AssetActivities(Id: $ActivityId) {
        AssetId
        Id
        Name
        Description
        DueDateTime
        StatusId
        CompletedDateTime
        Schedule {
          RecurringType
          AppearanceCount
          DayOfWeek
          EndDate
          RecurrenceCount
          RecurringRepeatType
          RelatedDayOfWeeks
          StartDate
          WeekInMonth
        }
        RelatedDueDateTime
        Images {
          Id
          Name
          ContainerId
          ContainerName
          FileExtension
          AbsoluteUri
        }
        Documents {
          Id
          Name
          ContainerId
          ContainerName
          FileExtension
          AbsoluteUri
        }
        Videos {
          Id
          Name
          ContainerId
          ContainerName
          FileExtension
          AbsoluteUri
        }
        Affiliates {
          ProductUrl
          ServiceUrl
          Type
        }
        Schema {
          Id
          Name
          Order
          FieldType
        }
        VendorRefs {
          Id
        }
        CustomFields
        AssignedUserId
      }
    }
  }
`;

export const updateActivityQuery = gql`
  mutation updateExistingActivity(
    $AccountId: ID
    $AssetId: ID
    $Id: ID
    $Name: String!
    $Description: String!
    $StatusId: ID
    $DueDateTime: DateTimeOffset
    $CompletedDateTime: DateTimeOffset
    $Schedule: ScheduleInputType
    $RelatedDueDateTime: [DateTimeOffset!]
    $Images: [ImageInput]
    $Documents: [FileInput]
    $Videos: [FileInput]
    $Files: [Upload]
    $Schema: [SchemaElementInput]
    $CustomFields: Json
    $AssignedUserId: ID
    $VendorRefs: [VendorRefInputType]
  ) {
    AssetActivityPut(
      AssetActivity: {
        AccountId: $AccountId
        Id: $Id
        Name: $Name
        AssetId: $AssetId
        Description: $Description
        StatusId: $StatusId
        DueDateTime: $DueDateTime
        CompletedDateTime: $CompletedDateTime
        Schedule: $Schedule
        RelatedDueDateTime: $RelatedDueDateTime
        Images: $Images
        Documents: $Documents
        Videos: $Videos
        Schema: $Schema
        CustomFields: $CustomFields
        AssignedUserId: $AssignedUserId
        VendorRefs: $VendorRefs
      }
      Files: $Files
    ) {
      AccountId
      Id
      Name
      AssetId
      Description
      StatusId
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Schema {
        Id
        Name
        Order
        FieldType
      }
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      VendorRefs {
        Id
      }
      CustomFields
      AssignedUserId
    }
  }
`;

export const createNewActivityQuery = gql`
  mutation createActivity(
    $AccountId: ID
    $AssetId: ID
    $Name: String!
    $Description: String!
    $StatusId: ID
    $DueDateTime: DateTimeOffset
    $CompletedDateTime: DateTimeOffset
    $Schedule: ScheduleInputType
    $RelatedDueDateTime: [DateTimeOffset!]
    $Images: [ImageInput]
    $Files: [Upload]
    $AssignedUserId: ID
    $VendorRefs: [VendorRefInputType]
  ) {
    AssetActivityPost(
      AssetActivity: {
        AccountId: $AccountId
        AssetId: $AssetId
        Name: $Name
        Description: $Description
        StatusId: $StatusId
        DueDateTime: $DueDateTime
        CompletedDateTime: $CompletedDateTime
        Schedule: $Schedule
        RelatedDueDateTime: $RelatedDueDateTime
        Images: $Images
        AssignedUserId: $AssignedUserId
        VendorRefs: $VendorRefs
      }
      Files: $Files
    ) {
      AccountId
      Id
      Name
      AssetId
      Description
      StatusId
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      VendorRefs {
        Id
      }
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      AssignedUserId
    }
  }
`;

export const createActivitiesQuery = gql`
  mutation ActivitiesPost($activities: [AssetActivityInput]) {
    ActivitiesPost(Activities: $activities) {
      isLoading @client
      AccountId
      Id
      Name
      Asset {
        Id
        Name
      }
      AssetId
      Description
      StatusId
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      VendorRefs {
        Id
      }
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      AssignedUserId
    }
  }
`;
