import gql from "graphql-tag";

export const getExistAssets = gql`
  query getExistAssets($Id: ID) {
    Account(Id: $Id) {
      Id
      Assets {
        Id
        Name
        ParentId
        ComponentIds
        AssetType {
          Id
          Name
          IconName
        }
        Images {
          Id
          Name
          ContainerId
          ContainerName
          AbsoluteUri
          FileExtension
        }
        AssetActivities {
          Id
        }
      }
    }
  }
`;