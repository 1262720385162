import { useContext } from "react";
import { EntityTabsContext } from "./EntityTabsProvider";

export const useEntityTabsContext = () => {
  const context = useContext(EntityTabsContext);

  if (context === undefined) {
    throw new Error("useEntityTabsContext must be used within a EntityTabsProvider");
  }

  const { state, setState } = context;

  const changeTab = (tabIndex: string) => {
    setState({ ...state, currentTab: tabIndex });
  };

  return { ...state, changeTab };
};
