import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import useRemoveItem from "./useRemoveItem";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import useGetAllRelatedActivities from "../../../../customHooks/useGetAllRelatedActivities";
import useGetAllRelatedParts from "../../../../customHooks/useGetAllRelatedParts";

import AssetConfirmPopup from "../../../../components/AssetConfirmPopup/AssetConfirmPopup";
import { DeleteDialog } from "../../../_common/Dialog";
import ConfirmAssetDelete from "../../../../components/AssetConfirmPopup/ConfirmAssetDelete";

import { ROUTES } from "../../../../_constant/screens";
import { NotificationTypes } from "../../../../_constant/NotificationTypes";

import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../../redux/actions/_utils";

import RelatedActivitiesList from "../../../../components/AssetConfirmPopup/RelatedActivitiesList";

interface DeleteAssetPopupWrapperProps {
  assetId: string;
  assetName: string;
  customerId?: string | null;
  handlePopupClose: () => void;
  handleFinishPopupLoading: () => void;
  withoutRedirect?: boolean;
  backToParentAsset?: boolean;
}

const DeleteAssetPopupWrapper = (props: DeleteAssetPopupWrapperProps) => {
  const { assetName, assetId, customerId } = props;
  const { handlePopupClose, handleFinishPopupLoading, withoutRedirect, backToParentAsset } = props;

  const [isDeleteAllParts, setIsDeleteAllParts] = React.useState(false);
  const handleChange = () => {
    setIsDeleteAllParts((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const locattion = useLocation();
  const isAssetEditPage = locattion.pathname.includes(ROUTES.SINGLE_ASSET);

  const { isDataAvailable, relatedActivities, statusNameMap } = useGetAllRelatedActivities({
    assetId,
  });

  const { accountId, IsCustomerManagement } = useGetAccountId();
  const { isPartsDataAvailable, relatedParts } = useGetAllRelatedParts({
    accountId,
    assetId,
  });
  const { removeItem, isRemoving } = useRemoveItem(relatedParts);

  React.useEffect(() => {
    if (isDataAvailable && isPartsDataAvailable) {
      handleFinishPopupLoading();
    }
  }, [isDataAvailable, isPartsDataAvailable]);

  const handleDelete = () => {
    const res = removeItem({
      relatedActivities: !!relatedActivities.length,
      assetId,
      customerId,
      isDeleteAllParts,
    });
    res
      ?.then(() => {
        if ((IsCustomerManagement && isAssetEditPage) || backToParentAsset) {
          navigate(-1);
        }
        if (!IsCustomerManagement && !withoutRedirect && !backToParentAsset) {
          navigate(ROUTES.ASSETS);
        }
        displaySuccessNotification(NotificationTypes.ASSET_DELETED);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.ASSET_DELETE_ERROR);
      })
      .finally(() => {
        handlePopupClose();
      });
  };

  const renderDialog = (activities, parts) => {
    const relatedPartsCount = parts.length;
    const relatedActivitiesCount = activities.length;
    const getRelatedEntityName =
      relatedPartsCount && relatedActivitiesCount
        ? "parts and activities"
        : relatedPartsCount
        ? "parts"
        : "activities";

    return relatedActivitiesCount > 0 || relatedPartsCount > 0 ? (
      <AssetConfirmPopup
        lockDeleteButton={isRemoving}
        handlePopupClose={handlePopupClose}
        handleSubmit={handleDelete}
        confirmContent={
          <ConfirmAssetDelete itemName={assetName} relatedEntityName={getRelatedEntityName} />
        }
        buttonConfirmLabel={"Delete"}
        relatedEntityCount={relatedActivitiesCount}
        relatedPartsLabel="Parts"
        relatedParts={parts}
        relatedPartsCount={relatedPartsCount}
        checked={isDeleteAllParts}
        handleSwitch={handleChange}
      >
        <RelatedActivitiesList
          activities={activities}
          statusName={statusNameMap}
          disableNavigationByClick
        />
      </AssetConfirmPopup>
    ) : (
      <DeleteDialog isDataLoading={isRemoving} cancel={handlePopupClose} submit={handleDelete}>
        Delete the <span className="Label">{assetName}</span> item?
      </DeleteDialog>
    );
  };

  return isDataAvailable && isPartsDataAvailable
    ? renderDialog(relatedActivities, relatedParts)
    : null;
};

export default DeleteAssetPopupWrapper;
