import React, { createContext, useState } from "react";
import { Provider } from "react-redux";

type ContextTypes = {
  state: EntityTabsState;
  setState: (newState: EntityTabsState) => void;
};

type EntityTabsState = {
  currentTab: string;
};

const EntityTabsContextDefaults = {
  currentTab: "1",
};

export const EntityTabsContext = createContext<ContextTypes | undefined>(undefined);

const EntityTabsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(EntityTabsContextDefaults);

  return (
    <EntityTabsContext.Provider value={{ state, setState }}>{children}</EntityTabsContext.Provider>
  );
};

export default EntityTabsProvider;
