import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";

import useCreateAssetMutation from "../../../customHooks/useCreateAssetMutation";
import useCreateAssetActivities from "../../../customHooks/useCreateAssetActivities";

import { AssetModel } from "../../../redux/models/data/AssetModel";
import { ActivitiesCustomMap } from "../../../redux/models/data/ActivityForWizardModel";
import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";

import { ROUTES } from "../../../_constant/screens";
import { ID, ITEM_POST } from "../../../_constant/wordings";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { isWizardScreeForSelect, WIZARD_SCREENS_NAME, wizardScreens } from "./WizardConstants";

import { displayErrorNotification } from "../../../redux/actions/_utils";
import { addCustomerIdToUrl } from "../../../_utils/utils";

import Hidden from "@material-ui/core/Hidden";

import StepperLine from "../../StepperLine/StepperLine";

import {
  WizardDialog,
  WizardContent,
  WizardTitleBar,
  WizardStepTitle,
  WizardSkipButton,
  WizardCloseIcon,
  WizardFooter,
  WizardFooterStepper,
  WizardFooterActions,
  WizardStepper,
  WizardStep,
  WizardStepLabel,
} from "./WizardContainerStyles";
import { ShortActivityModel } from "../../../containers/assets/_components/relaitedActivities/new/ShortActivityModel";
import { useGetAssetTypeDetails } from "../../../customHooks/useGetAssetTypesDescriptionPattern";

interface WizardContainerProps {
  isOpen: boolean;
  startStep: number;
  handleClose: () => void;
  customerId?: string;
  isInsideAddActivitySection?: boolean;
  updateRelatedActivity?: any;
  updateCacheInSelectItem?: boolean;
  parentId?: string;
}

export type ItemTypeScreen = "search" | "select";

const WizardContainer: React.FC<WizardContainerProps> = ({
  isOpen,
  handleClose,
  startStep,
  customerId,
  isInsideAddActivitySection,
  updateRelatedActivity,
  parentId,
}) => {
  const [step, setStep] = useState(startStep);
  const [newItem, setNewItem] = useState(new AssetForWizardModel());
  const [newActivities, setNewActivities] = useState<ActivitiesCustomMap>(new Map());

  const [itemTypeScreen, setItemTypeScreen] = useState<ItemTypeScreen>("search");

  const [typesMap, setTypesMap] = useState<Map<string, AssetModel[]>>(new Map());

  const [nextDisabled, setNextDisabled] = useState(true);

  const { getDetailsById } = useGetAssetTypeDetails();
  const { pattern, itemTypeField: ItemTypeFields } = getDetailsById(newItem.AssetType.Id);

  const switchDisable = (value: boolean) => {
    if (nextDisabled !== value) {
      setNextDisabled(value);
    }
  };

  const updateTypesMap = (items: Map<string, AssetModel[]>) => {
    setTypesMap(new Map(items));
  };

  const updateActivities = (newActivitiesMap: ActivitiesCustomMap) => {
    setNewActivities(newActivitiesMap);
  };

  const updateItem = (item: AssetForWizardModel) => {
    setNewItem((previous) => {
      return new AssetForWizardModel({ ...previous, ...item });
    });
  };

  useEffect(() => {
    setStep((previous) => (previous !== startStep ? startStep : previous));
  }, [startStep]);

  const resetData = () => {
    setNewItem(new AssetForWizardModel());
    setNewActivities(new Map());
  };

  useEffect(() => {
    if (isOpen) {
      setStep((previous) => {
        if (previous === WIZARD_SCREENS_NAME.welcome) {
          return previous;
        } else if (previous === WIZARD_SCREENS_NAME.type_select) {
          return previous;
        } else {
          return WIZARD_SCREENS_NAME.type_select;
        }
      });
      resetData();
    }
  }, [isOpen]);

  const [createActivities, { isActivityCreating }] = useCreateAssetActivities();

  const [createAssetMutation, { isCreating: isItemCreating }] = useCreateAssetMutation(
    isInsideAddActivitySection,
  );

  const createAsset = () => {
    const isAnyActivitySelected = Object.values(newActivities).some(
      (activity) => activity._checked,
    );
    newItem.CustomerId = customerId || null;
    const postData = { ...newItem, ParentId: parentId ?? null };

    return createAssetMutation(postData, isAnyActivitySelected)
      .then(({ data }) => {
        if (data[ITEM_POST][ID]) {
          return data[ITEM_POST][ID];
        } else {
          setStep(WIZARD_SCREENS_NAME.error);
          displayErrorNotification(NotificationTypes.ASSET_CREATE_ERROR);
        }
      })
      .catch((e) => {
        const message = e.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? e.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : e.networkError?.result?.errors[0]?.message;
        setStep(WIZARD_SCREENS_NAME.error);

        if (message && message.length < 100) {
          toastr.error(message);
        } else {
          displayErrorNotification(NotificationTypes.ASSET_CREATE_ERROR);
        }
      });
  };

  const nextStep = () => {
    setStep((currentStep) => {
      if (currentStep >= wizardScreens.size) {
        return WIZARD_SCREENS_NAME.welcome;
      }

      if (currentStep === WIZARD_SCREENS_NAME.item_select && !newItem.Name.length) {
        const newName = `My ${newItem.AssetType.Name} Item`;
        updateItem({ ...newItem, Name: newName, _isNameCustom: true });
      }

      if (currentStep === WIZARD_SCREENS_NAME.activities_select) {
        createAsset().then((assetId) => {
          const addedActivities = Array.from(newActivities.values()).filter(
            (activity: any) => activity._checked,
          );

          if (assetId) {
            isInsideAddActivitySection &&
              updateRelatedActivity(
                new ShortActivityModel({
                  AssetId: assetId,
                  Asset: { Name: newItem.Name, Id: assetId },
                }),
              );

            if (!addedActivities.length) {
              setStep(WIZARD_SCREENS_NAME.success);
              return;
            }

            createActivities(newActivities, assetId, customerId)
              ?.then((props) => {
                setStep(WIZARD_SCREENS_NAME.success);
              })
              .catch((e) => {
                console.error(e);
                const message = e.networkError?.result?.errors[0]?.message.includes("GraphQL")
                  ? e.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
                  : e.networkError?.result?.errors[0]?.message;
                setStep(WIZARD_SCREENS_NAME.error);

                if (message && message.length < 100) {
                  toastr.error(message);
                } else {
                  displayErrorNotification(NotificationTypes.ACTIVITIES_CREATE_ERROR);
                }
              });
          } else {
            assetId && setStep(WIZARD_SCREENS_NAME.success);
          }
        });
      }

      return ++currentStep;
    });
  };

  const prevStep = () => {
    setStep((currentStep) => {
      if (currentStep <= WIZARD_SCREENS_NAME.welcome) {
        return WIZARD_SCREENS_NAME.welcome;
      }

      if (currentStep === WIZARD_SCREENS_NAME.item_select && newItem.Name.length) {
        updateItem({ ...newItem, Name: "", _isNameCustom: false });
      }

      return --currentStep;
    });
  };

  const toFirstScreen = () => {
    setStep(WIZARD_SCREENS_NAME.type_select);
    resetData();
  };

  const navigate = (backward?: boolean) => {
    if (backward) {
      prevStep();
    } else {
      nextStep();
    }
  };

  const generateStepperContent = (arr: any[]): any[] => {
    return arr.filter((screen) => screen.stepperTitle !== null);
  };

  // TODO handle esc button

  const isLoading = isActivityCreating || isItemCreating;
  const skipPath = customerId
    ? addCustomerIdToUrl(ROUTES.CREATE_ASSET, customerId)
    : ROUTES.CREATE_ASSET;

  const getCurrentStepTitle = () => {
    let currentStepTitle = "";

    if (WIZARD_SCREENS_NAME.type_select === step) {
      currentStepTitle =
        itemTypeScreen === "search"
          ? "Find Item Type or Item"
          : wizardScreens.get(step)?.title ?? "";
    } else {
      currentStepTitle = wizardScreens.get(step)?.title ?? "";
    }

    return currentStepTitle;
  };

  return (
    <WizardDialog open={isOpen} onClose={handleClose}>
      {/* <WizardDialog open={true} onClose={handleClose}> */}
      <WizardTitleBar>
        <WizardStepTitle>{getCurrentStepTitle()}</WizardStepTitle>

        {wizardScreens.get(step)?.headerAction === "skip" ? (
          <WizardSkipButton component={Link} to={skipPath}>
            Skip
          </WizardSkipButton>
        ) : (
          <WizardCloseIcon onClick={handleClose} disabled={isLoading} />
        )}
      </WizardTitleBar>
      <Hidden smUp>
        <StepperLine currentStep={step} />
      </Hidden>
      <WizardContent>
        {wizardScreens.get(step)?.renderContent(
          step !== WIZARD_SCREENS_NAME.activities_select
            ? {
                newItem,
                updateItem,
                typesMap,
                updateTypesMap,
                toFirstScreen,
                isLoading,
                switchDisable,
                itemTypeScreen,
                setItemTypeScreen,
                setStep,
                ItemTypeFields,
                pattern,
              }
            : { newItem, newActivities, updateActivities },
        )}
      </WizardContent>
      <WizardFooter>
        {isWizardScreeForSelect(step) && (
          <Hidden xsDown>
            <WizardFooterStepper>
              <WizardStepper activeStep={step - 2}>
                {generateStepperContent(Array.from(wizardScreens.values())).map(
                  ({ stepperTitle }) => {
                    return (
                      <WizardStep key={stepperTitle}>
                        <WizardStepLabel />
                      </WizardStep>
                    );
                  },
                )}
              </WizardStepper>
            </WizardFooterStepper>
          </Hidden>
        )}
        <WizardFooterActions>
          {wizardScreens.get(step)?.renderFooter({
            navigate,
            handleClose,
            nextDisabled,
            customerId,
            isInsideAddActivitySection,
            parentId,
          })}
        </WizardFooterActions>
      </WizardFooter>
    </WizardDialog>
  );
};

export default WizardContainer;
