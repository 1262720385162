import * as React from "react";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { dataApiInterface } from "../../customHooks/useChange";

import { RevertDialogComponent } from "../../containers/_common/Dialog";
import EntityTabsProvider from "../EntityTabsContainer/EntityTabsProvider";
import Modal from "../../containers/Modal/Modal";
import Form from "../Form/Form";
import Body from "../Body/Body";
import FinishVisitDialog from "../FinishVisitDialog/FinishVisitDialog";
import StartVisitMobile from "../StartVisitMobile/StartVisitMobile";

import { VisitModel } from "../../redux/models/data/VisitModel";
import { setEditFormIsDirty } from "../../redux/actions/actions";
import { getVisitBooleanStatus } from "../../_utils/utils";

import DeleteVisitDialog from "../DeleteVisitDialog/DeleteVisitDialog";
import ActivitiesTab from "../ActivitiesTab/ActivitiesTab";
import RelatedActivities from "../../containers/assets/_components/relaitedActivities/RelatedActivities";
import { ITabContent } from "../CustomerForm/CustomerFormDesktop";
import ItemsTab from "../ItemsTab/ItemsTab";

import CustomerTab from "../CustomerTab/CustomerTab";
import StartVisitButtonContainer from "../../containers/visits/_components/StartVisitButtonContainer";

import VisitFormLayout from "./VisitFormLayout";
import { VisitFormContext } from "./VisitFormContext";
import EntityTabsBusinessContainer from "../EntityTabsContainer/EntityTabsBusinessMobile";
import { FormFilesContainer, InfoTabContainer } from "../FormMobile/BusinessInfoTabWrapper.style";

interface IVisitFormProps {
  handleSubmit: (e?: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
  isLoading: boolean;
  isNew: boolean;
  data: VisitModel;
  setHeaderOptions: (props: any) => void;
  formData: VisitModel;
  formApi: dataApiInterface;
  dropZone: any;
  lockSaveButton: boolean;
}

const VisitFormMobile = (props: IVisitFormProps) => {
  const {
    handleSubmit,
    isLoading,
    isNew,
    data: propsData,
    setHeaderOptions,
    formData,
    formApi,
    dropZone,
    lockSaveButton,
  } = props;

  const { isVisitFinished } = getVisitBooleanStatus(formData.VisitStatusName);
  const dispatch = useDispatch();

  const {
    isWizardOpen,
    isCustomize,
    isDeletePopupVisible,
    isRevertPopupVisible,
    setIsRevertPopupVisible,
    setIsDeletePopupVisible,
    handleCancel,
    handleCloseWizard,
    handleCustomize,
    handleOpenWizard,
    handleRevert,
    isConfirmationDialogVisible,
    setIsConfirmationDialogVisible,
    finishedActivities,
    handleFinishVisit,
  } = useContext(VisitFormContext);

  const visitTabs: ITabContent[] = [
    {
      name: "Info",
      hasCustomizeMode: false,
      content: (
        <InfoTabContainer>
          <VisitFormLayout
            data={formApi.data}
            handleChange={formApi.handleChange}
            errorFields={formApi.errorFields}
            isVisitFinished={isVisitFinished}
          />
          <FormFilesContainer>{dropZone}</FormFilesContainer>
        </InfoTabContainer>
      ),
      disabled: false,
    },
    {
      name: "Activities",
      disabled: isNew,
      hasCustomizeMode: false,
      content: (
        <RelatedActivities
          assetId={""}
          isCustomize={isCustomize}
          customerId={formData.CustomerId ?? ""}
          withAssetSelect
        >
          {formData.CustomerId ? (
            <ActivitiesTab visit={formData} isVisitLoading={isLoading} />
          ) : null}
        </RelatedActivities>
      ),
    },
    {
      name: "Items",
      disabled: isNew,
      content: (
        <ItemsTab
          isWizardOpen={isWizardOpen}
          handleCloseDialog={handleCloseWizard}
          customerId={formData.CustomerId ?? ""}
          isCustomerLoading={isLoading}
          withCreateButton
          handleOpenWizard={handleOpenWizard}
          visitId={formData.Id ?? ""}
        />
      ),
      hasCustomizeMode: false,
    },
    {
      name: "Customer",
      hasCustomizeMode: false,
      disabled: isNew,
      content: <CustomerTab customerId={formData.CustomerId ?? null} />,
      tabControl: null,
    },
  ];

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: handleSubmit,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading]);

  return (
    <Body disableDesktopScroll withBottomPadding>
      <EntityTabsProvider>
        <Modal isShowing={isRevertPopupVisible}>
          <RevertDialogComponent
            submit={revertData}
            cancel={() => setIsRevertPopupVisible(false)}
          />
        </Modal>
        <Modal isShowing={isDeletePopupVisible}>
          <DeleteVisitDialog
            visitId={formData.Id}
            handleCancel={() => setIsDeletePopupVisible(false)}
            VisitStatusId={formData.VisitStatusId}
          />
        </Modal>
        <Modal isShowing={!!formData.Id && !isNew && !isVisitFinished}>
          <StartVisitMobile visitStatusId={formApi.data.VisitStatusId}>
            <StartVisitButtonContainer
              customerId={formApi.data.CustomerId ?? ""}
              visitStatusId={formApi.data.VisitStatusId ?? ""}
              loading={props.isLoading}
              visit={propsData}
              showButton={!isNew}
              handleFinishVisit={handleFinishVisit}
            />
          </StartVisitMobile>
        </Modal>
        <FinishVisitDialog
          visit={formData}
          visible={isConfirmationDialogVisible}
          onCancel={() => setIsConfirmationDialogVisible(false)}
          finishedActivities={finishedActivities}
        />
        <Form>
          <Form body>
            <Form content>
              {
                <EntityTabsBusinessContainer
                  content={visitTabs}
                  entityName="visit"
                  isNew={isNew}
                  startFrom="0"
                  tabIndexAfterCreate="1"
                />
              }
            </Form>
          </Form>
        </Form>
      </EntityTabsProvider>
    </Body>
  );
};

export default VisitFormMobile;
