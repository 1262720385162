import gql from "graphql-tag";

export const GET_RELATED_ACTIVITIES_BY_STATUS = gql`
  query getRelActivitiesByStatus($AccountId: ID, $AssetId: ID, $Filter: String) {
    Account(Id: $AccountId) {
      Id
      Assets(Id: $AssetId) {
        Id
        AssetActivities(Filter: $Filter) {
          Id
          AssetId
          Name
          StatusId
          DueDateTime
          CompletedDateTime
          RelatedDueDateTime
          Schedule {
            EndDate
            RecurringRepeatType
            RecurringType
          }
          isLoading @client
        }
      }
    }
  }
`
