import React, { ChangeEvent } from "react";
import { useQuery } from "react-apollo";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import useGetAccountId from "../../customHooks/useGetAccountId";

import { Image } from "../../redux/models/data/ImageModel";

import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";

import { composeImageUrl } from "../../_utils/utils";
import { EMPTY_ID, UNASSIGNED } from "../../_constant/constants";

import { userRefFragment } from "./getUserRefFragment.query";

import { Avatar } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import Select from "../Select/Select";
import SelectItem from "../SelectItem/SelectItem";

interface IAssignUserSelect {
  assignedUserId: string | null;
  isLoading?: boolean;
  withEmptyId?: boolean;
  handleFieldChange: (e: ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  withoutControl?: boolean;
}

const AssignUserSelect: React.FC<IAssignUserSelect> = (props) => {
  const { assignedUserId, handleFieldChange, isLoading, withEmptyId, withoutControl } = props;

  const { accountId, isPersonal } = useGetAccountId();
  const staticData = useStaticDataQuery();

  const defaultUserIcon = staticData ? staticData.User.User_default_icon : "";

  const { data: accountUsers, loading: usersLoading, error: usersError, client } = useQuery(
    getAccountUsersQuery,
    {
      variables: { Id: accountId },
      skip: !accountId || !!isPersonal,
      fetchPolicy: "cache-first",
    },
  );

  const createImgUrl = (image: Image) => {
    if (image) {
      return composeImageUrl(image.Id, image.ContainerName, image.ContainerId);
    } else {
      return "";
    }
  };

  const getCurrentAssignedUserAvatar = (id: string | null) => {
    if (id && id !== EMPTY_ID && accountUsers) {
      const user = client.readFragment({ fragment: userRefFragment, id: id });
      return user && user.MainImage ? createImgUrl(user.MainImage) : "";
    } else {
      return defaultUserIcon;
    }
  };

  const getCurrentAssignedUserName = (id: string | null) => {
    if (id && accountUsers) {
      const user = client.readFragment({ fragment: userRefFragment, id: id });
      return user ? `${user.FirstName} ${user.LastName}`.trim() : "";
    } else {
      return "";
    }
  };

  let selectOptions =
    accountUsers &&
    accountUsers.Account.UserRefs.map(({ Id, FirstName, LastName, MainImage }: any) => {
      const fullName = `${FirstName} ${LastName ? LastName : ""}`.trim();
      return (
        <MenuItem value={Id} key={Id}>
          <SelectItem
            media={
              <Avatar alt={fullName} src={createImgUrl(MainImage)}>
                {fullName.charAt(0)}
              </Avatar>
            }
          >
            {fullName}
          </SelectItem>
        </MenuItem>
      );
    });

  const unassignedOption = (
    <MenuItem value={UNASSIGNED} key={UNASSIGNED}>
      <SelectItem>{UNASSIGNED}</SelectItem>
    </MenuItem>
  );

  selectOptions = selectOptions ? [unassignedOption, ...selectOptions] : [unassignedOption];

  const getValue = (value: string | null) => {
    if (!accountUsers) return "";
    return value === EMPTY_ID || value === null ? UNASSIGNED : value;
  };

  const handleChange = ({ target: { value } }: any) => {
    if (usersLoading || !accountUsers) return;
    return value !== UNASSIGNED
      ? handleFieldChange(null, { id: "AssignedUserId", value })
      : withEmptyId
      ? handleFieldChange(null, { id: "AssignedUserId", value: EMPTY_ID })
      : handleFieldChange(null, { id: "AssignedUserId", value: null });
  };

  return (
    // <AssignedContainer>
    <Select
      alignMedia="end"
      media={
        <Avatar
          style={{ width: "16px", height: "16px" }}
          src={getCurrentAssignedUserAvatar(assignedUserId)}
        >
          {getCurrentAssignedUserName(assignedUserId).charAt(0)}
        </Avatar>
      }
      label="Assign"
      value={getValue(assignedUserId)}
      onChange={handleChange}
      onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
      disabled={isLoading}
      withoutControl={withoutControl}
    >
      {accountUsers ? (
        selectOptions
      ) : (
        <MenuItem>
          <CircularProgress size={24} style={{ margin: "0 auto" }} />
        </MenuItem>
      )}
      {/*{selectOptions}*/}
    </Select>
    // </AssignedContainer>
  );
};

export default AssignUserSelect;
