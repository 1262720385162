import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface FoundItemProps {
  assetName: string;
  assetTypeName: string;
  iconName: string;
  handleClick: () => void;
}

const StyledAssetContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 12px;
  border: ${({theme}) => `1px solid ${theme.palette.primary.light}`};
  border-radius: 8px;
  cursor: pointer;

  & + & {
    margin-top: 8px;
  }
`;

const StyledAssetContent = styled.div`
  flex: 1;
`;

const StyledAssetName = styled.div`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${({theme}) => theme.palette.text.primary};
  margin-bottom: 4px;
`;

const StyledAssetTypeDetails = styled.div`
  display: flex;
  align-items: center;

  .Icon {
    color: ${({ theme }) => theme.palette.primary.main};
    margin-right: 8px;
  }
`;

const StyledAssetTypeName = styled.div`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledAssetIcon = styled.div`
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    vertical-align: middle;
  }
`;

const FoundItem: React.FC<FoundItemProps> = ({
  assetName,
  assetTypeName,
  iconName,
  handleClick,
}) => {
  return (
    <StyledAssetContainer onClick={handleClick}>
      <StyledAssetContent>
        <StyledAssetName>{assetName}</StyledAssetName>
        <StyledAssetTypeDetails>
          <i className="Icon">{iconName}</i>
          <StyledAssetTypeName>{assetTypeName}</StyledAssetTypeName>
        </StyledAssetTypeDetails>
      </StyledAssetContent>
      <StyledAssetIcon>
        <ChevronRightIcon />
      </StyledAssetIcon>
    </StyledAssetContainer>
  );
};

export default FoundItem;
