import React, { createContext, SyntheticEvent, useState } from "react";
import { toastr } from "react-redux-toastr";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import {
  AVAILABLE_DOCUMENT_TYPES,
  AVAILABLE_IMAGE_TYPES,
  AVAILABLE_VIDEO_TYPES,
} from "../../_constant/constants";

import { convertUploadedFilesToMap, validateUploadedFile } from "../../_utils/utils";
import {
  DocumentFromServer,
  FileType,
  ImageFromServer,
  mapLocalDocumentsToNormalized,
  mapLocalImageToNormalized,
  mapLocalVideoToNormalized,
  mapServerDocumentToNormalized,
  mapServerImageToNormalized,
  mapServerVideoToNormalized,
} from "../../_utils/dropzoneHelpers";

import ImagesGallery from "../ImagesGallery/ImagesGallery";
import DropZone from "../DropZone/DropZone";

interface DropZoneContainerProps {
  data: any;
  amountOfMainFiles?: number;
  isSingleFile?: boolean;
  imagesOnly: boolean;
  isMobile: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  updateManyFields: any;
  entityName: string;
  limitImages?: number;
  limitDocuments?: number;
  limitVideos?: number;
}

export interface NormalizedFile {
  id: string;
  imgUrl: string;
  url: string | null;
  isLocal: boolean;
  fileType: string;
  title?: string;
  imgOrder?: number;
}

export interface DropZoneContextTypes {
  imagesOnly: boolean;
  isMobile: boolean;
  isSingleFile: boolean;
  entityName: string;
  reducedImages: any;
  reducedDocuments: any;
  reducedVideos: any;
  localFiles: File[];
  mainFiles: NormalizedFile[];
  imagesToDisplay: any[];
  firstImage: number;
  showImageGallery: boolean;
  amountOfMainFiles: number;
  totalFilesAmount: number;
  emptyStateIcon: string;
  handleLocalFilesDelete: (e: SyntheticEvent<HTMLDivElement>, filePath: string) => void;
  handleServerFileDelete: (e: SyntheticEvent<HTMLDivElement>, id: string, path: FileType) => void;
  handleImageClick: (e: SyntheticEvent<HTMLElement>) => void;
  handleFileClick: (e: any, file: NormalizedFile) => void;
  handleFileFromServerClick: (e: any, doc: NormalizedFile) => void;
  closeGallery: () => void;
  addFilesToDropZone: (files: File[]) => void;
}

interface mapFiles {
  images: File[];
  documents: File[];
  videos: File[];
}

const initialStateOfMap = {
  images: [] as File[],
  documents: [] as File[],
  videos: [] as File[],
};

export const DropZoneContext = createContext<DropZoneContextTypes | undefined>(undefined);

const DropZoneContainer: React.FC<DropZoneContainerProps> = (props) => {
  const {
    handleChange,
    updateManyFields,
    amountOfMainFiles = 1,
    limitVideos = 2,
    limitDocuments = 2,
    limitImages = 2,
    imagesOnly = true,
    isMobile = false,
    entityName = "",
    isSingleFile = false,
    data: {
      Files: localFiles = [] as File[],
      Images: imagesFromServer = [],
      Documents: documentsFromServer = [],
      Videos: videosFromServer = [],
    },
  } = props;

  const [firstImage, setFirstImage] = useState(0);
  const [showImageGallery, setShowImageGallery] = useState(false);

  const imagesFromLocalFiles = localFiles.filter((file: File) =>
    AVAILABLE_IMAGE_TYPES.includes(file.type),
  );

  const imagesToDisplay = [...imagesFromServer, ...imagesFromLocalFiles];

  const staticData = useStaticDataQuery();
  const emptyMobileState = staticData?.Icons?.Mobile?.pic_Mobile_Empty_State ?? "";
  const pdfIcon = staticData?.Icons?.Desktop?.pic_Desktop_PDF ?? "";
  const videoIcon = staticData?.Icons?.Desktop?.pic_Desktop_Video ?? "";

  const normalizedImages: NormalizedFile[] = imagesFromServer.map(
    (file: ImageFromServer, index: number) => mapServerImageToNormalized({ file, index }),
  );

  const normalizedLocalImages: NormalizedFile[] = localFiles
    .filter((file: File) => AVAILABLE_IMAGE_TYPES.includes(file.type))
    .map((file: File, index: number) =>
      mapLocalImageToNormalized({ file, index: normalizedImages.length + index }),
    );

  let normalizedLocalFiles: NormalizedFile[] = localFiles
    .filter((file: File) => AVAILABLE_DOCUMENT_TYPES.includes(file.type))
    .map((file: File) => mapLocalDocumentsToNormalized({ file, icon: pdfIcon }));

  const normalizedLocalVideos: NormalizedFile[] = localFiles
    .filter((file: File) => AVAILABLE_VIDEO_TYPES.includes(file.type))
    .map((file: File) => mapLocalVideoToNormalized({ file, icon: videoIcon }));

  normalizedLocalFiles = [
    ...normalizedLocalImages,
    ...normalizedLocalFiles,
    ...normalizedLocalVideos,
  ];

  const normalizedDocuments = documentsFromServer.map((file: DocumentFromServer) =>
    mapServerDocumentToNormalized({ file, icon: pdfIcon }),
  );

  const normalizedVideos = videosFromServer.map((file: DocumentFromServer) =>
    mapServerVideoToNormalized({ file, icon: videoIcon }),
  );

  let mainFiles = [...normalizedImages];
  if (amountOfMainFiles > 1) {
    mainFiles = mainFiles.concat([
      ...normalizedDocuments,
      ...normalizedVideos,
      ...normalizedLocalFiles,
    ]);
  } else {
    mainFiles = mainFiles.concat([...normalizedLocalImages]);
  }

  const isMainFilesContainsImage = mainFiles.findIndex((file) => file.fileType === "image") >= 0;

  if (isMainFilesContainsImage) {
    mainFiles.length = mainFiles.length > amountOfMainFiles ? amountOfMainFiles : mainFiles.length;
  } else {
    mainFiles.splice(amountOfMainFiles - 1);
  }

  const mainFilesId = mainFiles.map(({ id }) => id).join(" ");

  const reducedImages = normalizedImages.filter(({ id }) => !mainFilesId.includes(id));
  const reducedDocuments = normalizedDocuments.filter(({ id }) => !mainFilesId.includes(id));
  const reducedVideos = normalizedVideos.filter(({ id }) => !mainFilesId.includes(id));
  const reducedLocalFiles = normalizedLocalFiles.filter(({ id }) => !mainFilesId.includes(id));

  const addFilesToDropZone = (files: File[]) => {
    const validationPromises = files.map((file) => validateUploadedFile(file));
    Promise.allSettled(validationPromises)
      .then((values) => {
        const validatedFiles = values
          .filter(({ status }) => status === "fulfilled")
          .map((obj) => obj.status === "fulfilled" && obj.value);

        values
          .filter(({ status }) => status === "rejected")
          .map((obj) => obj.status === "rejected" && obj.reason)
          .forEach((error) => toastr.warning(error));

        // Check limit of each type of file
        const uploadedFilesMap = validatedFiles.reduce(convertUploadedFilesToMap, {
          images: [] as File[],
          documents: [] as File[],
          videos: [] as File[],
        });
        const localFilesMap: mapFiles = localFiles.reduce(convertUploadedFilesToMap, {
          images: [] as File[],
          documents: [] as File[],
          videos: [] as File[],
        });

        const totalImagesLength =
          uploadedFilesMap.images.length + imagesFromServer.length + localFilesMap.images.length;
        const totalDocumentsLength =
          uploadedFilesMap.documents.length +
          documentsFromServer.length +
          localFilesMap.documents.length;
        const totalVideoLength =
          uploadedFilesMap.videos.length + videosFromServer.length + localFilesMap.videos.length;

        if (totalImagesLength > limitImages) {
          const filesCapacityLeft = limitImages - totalImagesLength;
          uploadedFilesMap.images.splice(filesCapacityLeft);
          toastr.warning(
            "",
            `The image limit has been exceeded, you have ${limitImages} image${
              limitImages > 1 ? "s" : ""
            } available for uploading`,
          );
        }

        if (totalDocumentsLength > limitDocuments) {
          const filesCapacityLeft = limitDocuments - totalDocumentsLength;
          uploadedFilesMap.documents.splice(filesCapacityLeft);
          toastr.warning(
            "",
            `The document limit has been exceeded, you have ${limitDocuments} document${
              limitDocuments > 1 ? "s" : ""
            } available for uploading`,
          );
        }

        if (totalVideoLength > limitVideos) {
          const filesCapacityLeft = limitVideos - totalVideoLength;
          uploadedFilesMap.videos.splice(filesCapacityLeft);
          toastr.warning(
            "",
            `The video limit has been exceeded, you have ${limitVideos} video${
              limitVideos > 1 ? "s" : ""
            } available for uploading`,
          );
        }

        const uploadedResult = [
          ...uploadedFilesMap.images,
          ...uploadedFilesMap.documents,
          ...uploadedFilesMap.videos,
        ];

        if (isSingleFile) {
          if (imagesOnly) {
            validatedFiles.splice(1);
            updateManyFields({
              Images: [],
              Files: uploadedResult,
            });
          } else {
            updateManyFields({
              Images: [],
              Documents: [],
              Files: uploadedResult,
            });
          }
        } else {
          handleChange(null, { id: "Files", value: [...localFiles, ...uploadedResult] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLocalFilesDelete = (e: SyntheticEvent<HTMLDivElement>, filePath: string) => {
    e.stopPropagation();
    e.preventDefault();

    const filteredFiles = localFiles.filter((file) => file.path !== filePath);

    handleChange(null, { id: "Files", value: filteredFiles });
  };

  const handleServerFileDelete = (
    e: SyntheticEvent<HTMLDivElement>,
    id: string,
    path: FileType,
  ) => {
    e.stopPropagation();
    e.preventDefault();

    switch (path) {
      case "image":
        const filteredImages = imagesFromServer.filter((file) => file.Id !== id);
        handleChange(null, { id: "Images", value: filteredImages });
        break;
      case "document":
        const filteredDocuments = documentsFromServer.filter((file) => file.Id !== id);
        handleChange(null, { id: "Documents", value: filteredDocuments });
        break;
      case "video":
        const filteredVideos = videosFromServer.filter((file) => file.Id !== id);
        handleChange(null, { id: "Videos", value: filteredVideos });
        break;
      default:
        return;
    }
  };

  const handleImageClick = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const indexOfImage = e?.currentTarget?.dataset?.order;
    if (!indexOfImage || isNaN(+indexOfImage)) {
      return;
    }
    setFirstImage(+indexOfImage);
    setShowImageGallery(true);
  };

  const closeGallery = () => {
    setShowImageGallery(false);
  };

  const handleFileClick = (e: React.SyntheticEvent, file: NormalizedFile) => {
    e.stopPropagation();
    // if (!file.url) return;
    // window.open(file.url);
  };

  const handleFileFromServerClick = (e: any, doc: NormalizedFile) => {
    e.stopPropagation();
    // const url = doc?.url;
    // if (url) {
    //   window.open(url);
    // }
  };

  const value: DropZoneContextTypes = {
    imagesOnly,
    isMobile,
    isSingleFile,
    entityName,
    reducedImages,
    reducedDocuments,
    reducedVideos,
    localFiles: reducedLocalFiles,
    imagesToDisplay,
    firstImage,
    showImageGallery,
    mainFiles,
    amountOfMainFiles,
    totalFilesAmount: limitDocuments + limitImages + limitVideos,
    emptyStateIcon: emptyMobileState,
    handleLocalFilesDelete,
    handleServerFileDelete,
    handleFileClick,
    handleImageClick,
    handleFileFromServerClick,
    closeGallery,
    addFilesToDropZone,
  };

  return (
    <DropZoneContext.Provider value={value}>
      <>
        <DropZone />
        {showImageGallery && <ImagesGallery />}
      </>
    </DropZoneContext.Provider>
  );
};

export default DropZoneContainer;
