const initial = {
  isAvailable: true,
  redirectPage: "",
  showOnLeavingPopup: false,
  isEditFormDirty: false,
  isConnectionLost: false,
  activitiesEmptyStatus: new Set<string>(),
  visitsEmptyStatus: new Set<string>(),
  projectsEmptyStatus: new Set<string>(),
};

export class ViewModel {
  isAvailable: boolean; // check if global info is loading from server
  redirectPage: string; // page with redirection url for "dirty" form
  showOnLeavingPopup: boolean; // show popup on leaving page
  isEditFormDirty: boolean;
  activitiesEmptyStatus: Set<string>;
  visitsEmptyStatus: Set<string>;
  projectsEmptyStatus: Set<string>;
  isConnectionLost: boolean;

  constructor(prevParams = initial) {
    this.isAvailable = prevParams.isAvailable;
    this.redirectPage = prevParams.redirectPage;
    this.showOnLeavingPopup = prevParams.showOnLeavingPopup;
    this.isEditFormDirty = prevParams.isEditFormDirty;
    this.activitiesEmptyStatus = prevParams.activitiesEmptyStatus;
    this.visitsEmptyStatus = prevParams.visitsEmptyStatus;
    this.projectsEmptyStatus = prevParams.projectsEmptyStatus;
    this.isConnectionLost = prevParams.isConnectionLost;
  }

  // Setters
  withUpdatedAvailableStatus(isAvailable: boolean): ViewModel {
    const nextData = Object.assign({}, this, { isAvailable });
    return new ViewModel(nextData);
  }

  withUpdatedRedirectionPage(redirectPage: string): ViewModel {
    return new ViewModel(Object.assign({}, this, { redirectPage }));
  }

  withUpdatedRedirectionFlag(showOnLeavingPopup: boolean): ViewModel {
    return new ViewModel(Object.assign({}, this, { showOnLeavingPopup }));
  }

  withUpdatedDirtyFormFlag(isEditFormDirty: boolean): ViewModel {
    return new ViewModel(Object.assign({}, this, { isEditFormDirty }));
  }

  withUpdatedActivitiesEmptyState(status: string, add: boolean): ViewModel {
    const activitiesEmptyStatus = new Set(this.activitiesEmptyStatus);

    add ? activitiesEmptyStatus.add(status) : activitiesEmptyStatus.delete(status);
    return new ViewModel(Object.assign({}, this, { activitiesEmptyStatus }));
  }

  withUpdatedVisitsEmptyState({
    status,
    add,
    reset,
  }: {
    status?: string;
    add?: boolean;
    reset?: boolean;
  }): ViewModel {
    if (reset) {
      return new ViewModel(Object.assign({}, this, { visitsEmptyStatus: new Set() }));
    }
    if (!status) {
      return new ViewModel(Object.assign({}, this, { visitsEmptyStatus: this.visitsEmptyStatus }));
    }
    const visitsEmptyStatus = new Set(this.visitsEmptyStatus);

    add ? visitsEmptyStatus.add(status) : visitsEmptyStatus.delete(status);
    return new ViewModel(Object.assign({}, this, { visitsEmptyStatus }));
  }

  withUpdatedProjectsEmptyState({
    status,
    add,
    reset,
  }: {
    status?: string;
    add?: boolean;
    reset?: boolean;
  }): ViewModel {
    if (reset) {
      return new ViewModel(Object.assign({}, this, { projectsEmptyStatus: new Set() }));
    }
    if (!status) {
      return new ViewModel(
        Object.assign({}, this, { projectsEmptyStatus: this.projectsEmptyStatus }),
      );
    }
    const projectsEmptyStatus = new Set(this.projectsEmptyStatus);

    add ? projectsEmptyStatus.add(status) : projectsEmptyStatus.delete(status);
    return new ViewModel(Object.assign({}, this, { projectsEmptyStatus }));
  }

  withUpdatedConnectionState(isConnectionLost: boolean): ViewModel {
    return new ViewModel(Object.assign({}, this, { isConnectionLost }));
  }

  // Getters
  get loadingStateFlag(): boolean {
    return this.isAvailable;
  }

  get pageForRedirect(): string {
    return this.redirectPage;
  }

  get popupStateFlag(): boolean {
    return this.showOnLeavingPopup;
  }

  get connectionState(): boolean {
    return this.isConnectionLost;
  }
}
