import React from "react";
import { CreateOutlined } from "@material-ui/icons";

import { VendorModel } from "../../redux/models/data/VendorModel";

import Combobox from "../Combobox/Combobox";

interface VendorToAttachType {
  label: string;
  value: string;
}
interface VendorSelectContainerProps {
  allVendorsOptions: VendorModel[];
  vendorToAttach: VendorToAttachType;
  setVendorToAttach: any;
}

export const VendorSelectContainer: React.FC<VendorSelectContainerProps> = ({
  allVendorsOptions,
  vendorToAttach,
  setVendorToAttach,
}) => {
  // @ts-ignore
  const handleItemSelect = (e, newValue: { id: string; value: any }) => {
    const { id, value } = newValue;
    if (value && value.value) {
      setVendorToAttach(value);
    }
  };

  return (
    <Combobox
      style={{ marginBottom: "22px", flexGrow: 1 }}
      id="VendorId"
      label="Name"
      value={vendorToAttach}
      options={allVendorsOptions}
      handleChange={handleItemSelect}
      creatable={false}
      required={false}
      hasError={false}
      icon={<CreateOutlined />}
    />
  );
};
