import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { ACCOUNT, CUSTOMERS, VISITS } from "../../_constant/wordings";
import { getCustomersQuery } from "../Customers/customersList.query";

export const updateCacheAfterDeleteCustomer = (
  cache: DataProxy,
  customerDelete: any,
  accountId: string,
) => {
  try {
    const customerListQuery: any = cache.readQuery({
      query: getCustomersQuery,
      variables: { Id: accountId },
    });

    const newCustomerList = customerListQuery[ACCOUNT][CUSTOMERS].filter(
      (customerItem) => customerItem.Id !== customerDelete.Id,
    );

    cache.writeQuery({
      query: getCustomersQuery,
      variables: { Id: accountId },
      data: { Account: { Customers: newCustomerList, Id: accountId, __typename: ACCOUNT } },
    });
  } catch (error) {}
};
