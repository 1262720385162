import React from "react";
import { useQuery } from "react-apollo";

import useGetAccountId from "./useGetAccountId";

import { ACCOUNT, ASSET_REFS } from "../_constant/wordings";

import { GET_ASSET_REFS } from "../containers/assets/all/assets.query";

export const useIsItemsExistsForList = () => {
  const { accountId } = useGetAccountId();

  const { data } = useQuery(GET_ASSET_REFS, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-only",
  });

  let exists = false;

  if (data?.[ACCOUNT]?.[ASSET_REFS]?.length) {
    exists = true;
  }

  return {
    exists,
    isDataAvailable: !!data,
  };
};
