import React from "react";
import { useQuery } from "react-apollo";

import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetBusinessStatuses from "../../customHooks/useGetBusinessStatuses";

import { getCustomersQuery } from "../Customers/customersList.query";

import ActivitiesList from "../ActivitiesList/ActivitiesList";
import { LoaderCircle } from "../Loader";
import VisitsByStatuses from "../VisitsByStatuses/VisitsByStatuses";
import { useSelector } from "react-redux";
import { visitsEmptyStatusList } from "../../redux/reducers/appReducer";
import { NoVisitsComponent } from "../NoResultComponent/NoResultComponent";
import { Fade } from "@material-ui/core";

interface AllVisitsWrapperProps {}

const AllVisitsWrapper: React.FC<AllVisitsWrapperProps> = () => {
  const { accountId } = useGetAccountId();
  const statuses = useGetBusinessStatuses();
  const { data: customersData } = useQuery(getCustomersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });
  const emptyStatuses = useSelector(visitsEmptyStatusList);

  if (statuses && statuses.length) {
    return (
      <>
        <Fade in={emptyStatuses.size === statuses.length} exit enter unmountOnExit>
          <NoVisitsComponent />
        </Fade>

        {emptyStatuses.size !== statuses.length && (
          <ActivitiesList listView>
            {statuses.map((status) => {
              return <VisitsByStatuses layout="List" key={status.Id} status={status} />;
            })}
          </ActivitiesList>
        )}
      </>
    );
  }

  // if (statuses.length === 0) {
  return (
    <div className={"LoaderWrapper"}>
      <LoaderCircle size={"xl"} />
    </div>
  );
};

export default AllVisitsWrapper;
