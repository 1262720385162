import styled from "styled-components";
import { COLOR_CODE_STATE } from "../../_constant/constants";

interface DateValueContainerProps {
  $dateState:
    | typeof COLOR_CODE_STATE.completed
    | typeof COLOR_CODE_STATE.overdue
    | typeof COLOR_CODE_STATE.close
    | typeof COLOR_CODE_STATE.far;
}
export const DateValueContainer = styled.div<DateValueContainerProps>`
  width: 120px;

  .MuiSvgIcon-root,
  .MuiInputBase-root {
    color: ${({ theme, $dateState }) => {
      switch ($dateState) {
        case COLOR_CODE_STATE.completed:
          return theme.palette.success.main;
        case COLOR_CODE_STATE.overdue:
        case COLOR_CODE_STATE.cancelled:
          return theme.palette.error.main;
        case COLOR_CODE_STATE.close:
          return theme.palette.warning.main;
        case COLOR_CODE_STATE.far:
          return theme.palette.primary.main;
      }
    }};
  }

  .MuiInputBase-root {
    text-decoration: ${({ dateState }) =>
      dateState === COLOR_CODE_STATE.completed ? "line-through" : "none"};
    ${({ dateState, theme }) => {
      if (dateState === COLOR_CODE_STATE.far) {
        return `color: ${theme.palette.text.primary}`;
      }
    }}
  }

  .MuiFormLabel-root {
    white-space: nowrap;
  }
`;
