import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { displayErrorNotification, displaySuccessNotification } from "../redux/actions/_utils";
import { NotificationTypes } from "../_constant/NotificationTypes";
import { AssetModel } from "../redux/models/data/AssetModel";
import { updatePartOfSelectCacheAfterRemovingPart } from "../containers/assets/new/updateItemSelectCache";
import { removeIdFromComponentIdsInParentAsset } from "../containers/assets/_components/deleteAssetPopup/updateCacheFunction";

const removeRelatedPartQuery = gql`
  mutation removeRelatedPart(
    $AccountId: ID
    $AssetType: AssetTypeInput
    $Name: String!
    $Id: ID
    $ParentId: ID
  ) {
    AssetPatch(
      Asset: {
        AccountId: $AccountId
        AssetType: $AssetType
        Name: $Name
        Id: $Id
        ParentId: $ParentId
      }
    ) {
      Id
    }
  }
`;

interface IUseRemoveRelatedPartMutation {
  partsList: [] | AssetModel[];
  accountId: string | null;
}

const useRemoveRelatedPartMutation = ({ partsList, accountId }: IUseRemoveRelatedPartMutation) => {
  const [removeRelatedPart, { loading: isUpdating }] = useMutation(removeRelatedPartQuery);

  const handleRemove = ({
    removedPartId,
    setLoadingStatus,
  }: {
    removedPartId: string;
    setLoadingStatus: (status: boolean) => void;
  }) => {
    setLoadingStatus(true);

    const removedPart = partsList.find((part) => part.Id === removedPartId);
    delete removedPart?.Images;
    delete removedPart?.ComponentIds;
    delete removedPart?.__typename;

    const variables = {
      ...removedPart,
      AssetType: {
        Id: removedPart?.AssetType.Id,
        Name: removedPart?.AssetType.Name,
        IconName: removedPart?.AssetType.IconName,
      },
      AccountId: accountId,
      ParentId: null,
    };
    removeRelatedPart({
      variables,
      update(cache, { data }) {
        updatePartOfSelectCacheAfterRemovingPart(cache, removedPart, accountId);
        removeIdFromComponentIdsInParentAsset(cache, removedPart, accountId);
      },
    })
      .then(({ data }) => {
        setLoadingStatus(false);
        displaySuccessNotification(NotificationTypes.ASSET_UPDATED);
      })
      .catch((error) => {
        setLoadingStatus(false);
        displayErrorNotification(NotificationTypes.ASSET_UPDATE_ERROR);
      });
  };

  return { isUpdating, handleRemove };
};

export default useRemoveRelatedPartMutation;
