import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import Combobox from "../Combobox/Combobox";

interface AddressAutocompleteProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  address: string | null;
  icon: JSX.Element;
  id?: string;
  label?: string;
}
const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  handleChange,
  address = "",
  icon,
  id = "Address",
  label = "Address",
}) => {
  const [addressOptions, setAddressOptions] = useState<
    { label: string; value: string; payload?: string }[]
  >([]);
  const [isDetailsLoading, setDetailsLoading] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
    options: {
      types: ["address"],
    },
    language: "en",
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    const addressValue = { value: address ?? "", label: address ?? "" };
    let addressOptions = [addressValue];

    if (placePredictions?.length === 0) {
      return;
    }

    const optionsFromRequest =
      placePredictions?.map((obj) => {
        return { label: obj.description, value: obj.description, payload: obj?.place_id };
      }) ?? [];

    addressOptions = address ? [addressValue, ...optionsFromRequest] : [...optionsFromRequest];

    if (placePredictions.length) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails: any) => {
          if (!placeDetails?.formatted_address) {
            return;
          }
          setAddressOptions((prev) => {
            const detailedValue = {
              label: placeDetails.formatted_address as string,
              value: placeDetails.formatted_address as string,
            };
            const restValues = prev.slice(2);
            return [prev[0], detailedValue, ...restValues];
          });
        },
      );
    }

    setAddressOptions(addressOptions);
  }, [placePredictions]);

  const getNewValueBasedOnAction = (e, newValue: { id?: string; value: any }) => {
    const { id, value } = newValue;

    if (typeof value === "string") {
      handleChange(null, {
        id,
        value,
      });
    } else if (newValue && value) {
      let resValue = value?.value;

      if (value?.payload) {
        setDetailsLoading(true);
        const promise = new Promise((resolve, reject) => {
          placesService?.getDetails(
            {
              placeId: value.payload,
            },
            (placeDetails: any, status: any) => {
              if (
                status === google?.maps?.places?.PlacesServiceStatus?.OK &&
                !placeDetails?.formatted_address
              ) {
                reject();
              }
              resolve(placeDetails?.formatted_address);
            },
          );
        });
        promise
          .then((res) => {
            resValue = res ?? resValue;
            setDetailsLoading(false);

            handleChange(null, {
              id,
              value: resValue,
            });
          })
          .catch((err) => console.error(err));
      }
    } else if (!value || !newValue) {
      handleChange(null, {
        id,
        value: "",
      });
    }
  };

  const handleAddressInputChange = (e: any, text: string) => {
    getPlacePredictions({
      input: text,
    });

    if (!text?.length) {
      setAddressOptions([]);
    }

    handleChange(null, {
      id,
      value: text,
    });
  };

  const addressValue = { value: address, label: address };

  return (
    <>
      <Combobox
        value={addressValue}
        handleChange={getNewValueBasedOnAction}
        onInputChange={handleAddressInputChange}
        required={false}
        id={id}
        label={label}
        options={addressOptions}
        creatable
        loading={isPlacePredictionsLoading || isDetailsLoading}
        icon={icon}
      />
    </>
  );
};

export default AddressAutocomplete;
