import React from "react";

import { DeleteDialog } from "../../containers/_common/Dialog";
import useGetRelatedCustomerItems from "../../customHooks/useGetRelatedCustomerItems";
import useDeleteCustomer from "../../customHooks/useDeleteCustomer";
import AssetConfirmPopup from "../AssetConfirmPopup/AssetConfirmPopup";
import RelatedItemsList from "../AssetConfirmPopup/RelatedItemsList";
import ConfirmCustomerDelete from "../AssetConfirmPopup/ConfirmCustomerDelete";

interface DeleteCustomerDialogProps {
  customerId: string | null;
  customerName: string | null;
  handleCancel: () => void;
  handleFinishPopupLoading: () => void;
}

const DeleteCustomerDialog: React.FC<DeleteCustomerDialogProps> = (props) => {
  const { customerId, handleCancel, customerName, handleFinishPopupLoading } = props;

  const { relatedItems, isDataAvailable } = useGetRelatedCustomerItems(
    customerId,
    handleFinishPopupLoading,
  );

  const { removeCustomer, isRemoving } = useDeleteCustomer(customerId);

  const renderDialog = () => {
    if (relatedItems.length) {
      return (
        <AssetConfirmPopup
          lockDeleteButton={isRemoving}
          handlePopupClose={handleCancel}
          handleSubmit={removeCustomer}
          confirmContent={<ConfirmCustomerDelete customerName={customerName || ""} />}
          buttonConfirmLabel={"Delete"}
          relatedEntityCount={relatedItems.length}
          relatedEntityName="Items"
        >
          {<RelatedItemsList relatedItems={relatedItems} />}
        </AssetConfirmPopup>
      );
    }

    return (
      <DeleteDialog isDataLoading={isRemoving} cancel={handleCancel} submit={removeCustomer}>
        Delete the <span className="Label">{customerName}</span> customer?
      </DeleteDialog>
    );
  };

  return isDataAvailable ? renderDialog() : null;
};

export default DeleteCustomerDialog;
