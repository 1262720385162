import * as React from "react";
import { useEffect, useState } from "react";
import { notEmpty, updateChangedFields, validateNameFields } from "../_utils/utils";
import { ASSET_TYPE_ICON } from "../_constant/wordings";
import { ChangeEvent } from "react";

interface useChangeProps<T> {
  initData: T;
  classModel: any; // class that create new instance of data
  requiredFields: string[];
  fieldsForValidation: Map<string, null | Function>; // first parameter - field name, second is a validate function
} // or null for default function

export interface dataApiInterface<T> {
  data: T;
  initFormData: T;
  changedFields: Set<string>;
  isDataChanged: boolean;
  isDataValid: boolean;
  errorFields: Map<string, string>;
  handleChange: (e: ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  handleChangeWithoutDirtyForm: (id: string, value: any[], initValue: any[]) => void;
  updateInitFormData: (data: any) => void;
  handleChangeIconName: (value: string) => void;
  revertData: () => void;
  validateFields: () => void;
  updateManyFields: (newData: Partial<any>) => void;
}

export function useChange<T>(props: useChangeProps<T>): dataApiInterface<T> {
  const { initData, classModel, requiredFields } = props;

  const fieldsForValidation = props.fieldsForValidation;

  if (!(initData instanceof classModel)) {
    throw new Error("Initial data does not match to received class");
  }

  const [initFormData, setInitFormData] = useState(new classModel(initData));
  const [formData, setFormData] = useState(new classModel(initData));
  const [changedFields, setChangedFields] = useState(new Set<string>());
  const [isDataChanged, setDataChanged] = useState(false);
  const [isDataValid, setIsDataValid] = useState(false);
  const [errorFields, setErrorFields] = useState(new Map() as Map<string, string>);

  const updateErrorField = (field: string, value: string) => {
    if (fieldsForValidation.has(field)) {
      const validateFunc = fieldsForValidation.get(field) || validateNameFields;
      const newErrorFields = validateFunc(field, value, errorFields)[0];
      setErrorFields(() => newErrorFields);
    }
  };

  const handleChangeWithoutDirtyForm = (id: string, value: any[], initValue: any[]) => {
    setFormData((previous: typeof classModel) => new classModel({ ...previous, [id]: value }));
    setInitFormData(
      (previous: typeof classModel) => new classModel({ ...previous, [id]: initValue }),
    );
  };

  const memoizedHandleChangeWithoutDirtyForm = React.useCallback(handleChangeWithoutDirtyForm, [
    initFormData,
    formData,
    changedFields,
  ]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | null,
    target: { id: string; value: any },
  ) => {
    let id: string, value: any;
    if (e === null) {
      id = target.id;
      value = target.value;
    } else {
      id = e.target.id;
      value = e.target.value;
    }

    const initialValue = initFormData[id];
    const prevValue = formData[id];
    const newChangedFields = updateChangedFields(id, value, initialValue, prevValue, changedFields);

    updateErrorField(id, value);

    setFormData((previous: typeof classModel) => new classModel({ ...previous, [id]: value }));
    setChangedFields(newChangedFields);
    if (newChangedFields.size > 0) {
      setDataChanged(() => true);
    } else {
      setDataChanged(() => false);
    }
  };

  const memoizedHandleChange = React.useCallback(handleChange, [
    initFormData,
    formData,
    changedFields,
  ]);

  const handleChangeIconName = (iconName: string) => {
    const newFormData = new classModel(formData);
    newFormData.AssetType.IconName = iconName;
    const newChangedFields = new Set(changedFields);

    if (initFormData.AssetType.IconName !== iconName) {
      newChangedFields.add(ASSET_TYPE_ICON);
      setDataChanged(() => true);
    } else {
      newChangedFields.delete(ASSET_TYPE_ICON);
      setDataChanged(() => false);
    }

    setFormData((previous: typeof classModel) => ({ ...previous, ...newFormData }));
    setChangedFields(() => newChangedFields);
  };

  useEffect(() => {
    const result = requiredFields.every((field) => {
      const value = formData[field].hasOwnProperty("Name") ? formData[field].Name : formData[field];
      return notEmpty(value);
    });

    const fieldsValid = errorFields.size === 0;
    setIsDataValid(result && fieldsValid);
  }, [formData, errorFields]);

  const updateInitFormData = (newData: typeof classModel) => {
    setInitFormData(() => newData);
    setFormData(() => newData);
    setChangedFields(new Set());
    setDataChanged(() => false);
  };

  const validateFields = (data?: typeof classModel) => {
    const dataSource = data ? data : formData;
    Object.keys(dataSource).forEach((field) => {
      updateErrorField(field, dataSource[field]);
    });
  };

  const revertData = () => {
    setFormData(() => initFormData);
    setChangedFields(new Set());
    setDataChanged(false);
    validateFields(initFormData);
  };

  const updateManyFields = (newData: Partial<typeof classModel>) => {
    const updatedFields = Object.keys(newData);
    let newChangedFields = new Set<string>(changedFields);
    let newErrorFields = new Map(errorFields);
    updatedFields.forEach((key: string) => {
      const initialValue = initFormData[key];
      const prevValue = formData[key];
      updateChangedFields(key, newData[key], initialValue, prevValue, newChangedFields).forEach(
        (el: string) => {
          newChangedFields.add(el);
        },
      );
      if (fieldsForValidation.has(key)) {
        const validateFunc = fieldsForValidation.get(key) || validateNameFields;
        newErrorFields = new Map(validateFunc(key, newData[key], newErrorFields)[0]);
      }
    });

    setChangedFields(newChangedFields);
    setErrorFields(() => newErrorFields);
    setFormData((previous: typeof classModel) => new classModel({ ...previous, ...newData }));

    if (newChangedFields.size > 0) {
      setDataChanged(() => true);
    } else {
      setDataChanged(() => false);
    }
  };

  return {
    data: formData,
    initFormData,
    changedFields,
    isDataChanged,
    isDataValid,
    errorFields,
    // handleChange,
    handleChange: memoizedHandleChange,
    handleChangeWithoutDirtyForm: memoizedHandleChangeWithoutDirtyForm,
    updateInitFormData,
    handleChangeIconName,
    revertData,
    validateFields,
    updateManyFields,
  };
}
