import React from "react";
import styled, { css } from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import { textClip } from "../_styled/textClip";
import { scrollStyles } from "../_styled/scrollStyles";
interface AccountAlertContainerProps {
  isMobile: boolean;
}
export const AccountAlertContainer = styled(({ isMobile, ...rest }) => <Dialog {...rest} />)<
  AccountAlertContainerProps
>`
  &.MuiDialog-root {
    inset: 2.75rem 0.5rem auto auto !important;
    top: 2.75rem !important; /* inset property doesn't work in safari */
    right: ${({ isMobile }) => (isMobile ? "5% !important" : "0.5rem !important")};
    bottom: auto !important;
    left: auto !important;

    width: ${({ isMobile }) => (isMobile ? "90%" : "336px")};
  }

  & .MuiDialog-paper {
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;
    margin: 0;
  }

  .MuiDialog-container {
    display: block;
  }
`;

export const AccountAlertHeader = styled.header`
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  font-weight: bold;
  color: ${({ theme }) => `${theme.palette.primary.light}`};
  display: flex;
  padding: 0 0.75rem;
`;

export const AccountAlertContent = styled.section`
  padding: 0 0.75rem 1.5rem;
  /* overflow: auto; */
  display: flex;
  height: 100%;
`;

const photoSize = "2.875rem";

const AccountAlertPhotoStyle = css`
  width: ${photoSize};
  height: ${photoSize};
`;

export const AccountAlertPhotoPlaceholder = styled(Skeleton)`
  &.MuiSkeleton-root {
    ${AccountAlertPhotoStyle}
  }
`;

export const AccountAlertPhoto = styled(Avatar)`
  &.MuiAvatar-root {
    ${AccountAlertPhotoStyle}
  }
`;

export const AccountAlertNamePlaceholder = styled(Skeleton)`
  min-width: 150px;
`;

export const AccountAlertMailPlaceholder = styled(Skeleton)`
  min-width: 220px;
`;

export const AccountAlertUserInfo = styled.article`
  overflow: hidden;
  margin-left: 0.5rem;
  height: min-content;
`;

export const AccountAlertUserName = styled(Typography)`
  padding-top: 4px;
  font-size: 0.875rem;
  ${textClip}
`;

export const AccountAlertUserMail = styled(Typography)`
  padding-top: 4px;
  color: ${({ theme }) => `${theme.palette.text.secondary}`};
  font-size: 0.75rem;
  ${textClip}
`;

export const AccountAlertAccountList = styled.section`
  max-height: 266px;
  overflow-y: auto;
  ${scrollStyles}
`;

interface AccountPopupFooterProps {
  isMobile?: boolean;
}

export const AccountPopupFooter = styled.footer<AccountPopupFooterProps>`
  height: ${({ isMobile }) => (isMobile ? "48px" : "36px")};
  padding: 0 0.75rem;
  background-color: ${({ theme }) => `${theme.palette.primary.light}`};
  /* border-top: solid #e4e1e1 1px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
