import React from "react";
import { IconButton, List } from "@material-ui/core";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { ListItemIconStyled, ListItemTextStyled, ListItemStyled } from "./FormMenu.style";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import SettingsBackupRestoreOutlinedIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";
import { saveOptionsInterface } from "../../../components/HeaderOptionsProvider/HeaderOptionsModel";
import { Popover } from "../../../components/Popover/Popover";
import { ReactComponent as DownloadIcon } from "../../../../images/ic_download.svg";

interface FormMenuProps {
  saveOptions: saveOptionsInterface;
  isFormDirty: boolean;
}

interface ListInterface {
  text: string;
  icon: any;
  disabled: boolean;
  callback?: () => void;
}

const FormMenu: React.FC<FormMenuProps> = (props) => {
  const { saveOptions, isFormDirty } = props;

  const menuItems: ListInterface[] = [
    {
      text: "Save and close",
      icon: <SaveOutlinedIcon />,
      disabled: !isFormDirty || saveOptions.isLoading || saveOptions.disabled,
      callback: saveOptions.saveAndClose,
    },
    {
      text: "Revert",
      icon: <SettingsBackupRestoreOutlinedIcon />,
      disabled: !isFormDirty || saveOptions.isLoading || saveOptions.disabled,
      callback: saveOptions.revertFunction,
    },
  ];

  if (saveOptions?.delete) {
    menuItems.push({
      text: "Delete",
      icon: <DeleteOutlineOutlinedIcon />,
      disabled: saveOptions.isLoading,
      callback: saveOptions.delete,
    });
  }
  if (saveOptions?.getDate) {
    menuItems.push({
      text: "Add to Calendar",
      icon: <DownloadIcon style={{ width: 16, height: 16 }} />,
      disabled: saveOptions.isLoading,
      callback: saveOptions.getDate,
    });
  }

  return (
    <>
      <Popover
        target={
          <IconButton>
            <MoreVertOutlinedIcon />
          </IconButton>
        }
        content={(handleClose) => (
          <List component="nav" disablePadding>
            {menuItems.map(({ text, icon, disabled, callback }) => {
              return (
                <ListItemStyled
                  key={text}
                  button
                  onClick={() => {
                    !!callback && callback();
                    handleClose();
                  }}
                  disableGutters
                  disabled={disabled}
                >
                  <ListItemIconStyled>{icon}</ListItemIconStyled>
                  <ListItemTextStyled primary={text} />
                </ListItemStyled>
              );
            })}
          </List>
        )}
      />
    </>
  );
};

export default FormMenu;
