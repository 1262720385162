import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { GET_CUSTOMER_ASSETS } from "./getCustomerAssets.query";
import { CUSTOMER, ITEMS } from "../../_constant/wordings";

export const updateCustomerItemCache = (
  cache: DataProxy,
  assetPost: any,
  accountId: string,
  customerId: string,
) => {
  try {
    const customerAssets: any = cache.readQuery({
      query: GET_CUSTOMER_ASSETS,
      variables: {
        AccountId: accountId,
        CustomerId: customerId,
      },
    });

    if (!customerAssets?.[CUSTOMER]?.[ITEMS]) {
      return;
    }
    customerAssets[CUSTOMER][ITEMS] = customerAssets[CUSTOMER][ITEMS].concat([
      { ...assetPost, __typename: "AssetRef" },
    ]);
    cache.writeQuery({
      query: GET_CUSTOMER_ASSETS,
      variables: {
        AccountId: accountId,
        CustomerId: customerId,
      },
      data: { ...customerAssets },
    });
  } catch (e) {
    console.error(e);
  }
};
