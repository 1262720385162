import * as React from "react";
import { render } from "react-dom";
import App from "./js/App";
import * as serviceWorker from "./serviceWorker";
import swConfig from "./swConfig";
const rootEl = document.getElementById("root");

render(<App />, rootEl);

serviceWorker.register(swConfig);
