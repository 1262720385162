import { GET_ACTIVITIES_LIST } from "./activities-list.query";
import { ACCOUNT, ASSET_REFS } from "../../../_constant/wordings";
import { assetNameFragment } from "../../../graphql/resolvers";

export const AllActivitiesCacheUpdate = (
  cache,
  activity,
  activityResponse,
  statusObjectFrom,
  statusObjectTo,
  accountId,
  withLoader = true,
) => {
  try {
    const activitiesFromStatus = cache.readQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: statusObjectFrom,
      },
    });

    const activitiesToStatus = cache.readQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: statusObjectTo,
      },
    });
    let activityWithOldStatus = {};
    activitiesFromStatus[ACCOUNT]["AssetActivities"] = activitiesFromStatus[ACCOUNT][
      "AssetActivities"
    ].filter((activityFromArray) => {
      if (activityFromArray.Id === activity.Id) {
        activityWithOldStatus = activityFromArray;
      } else {
        return activityFromArray.Id !== activity.Id;
      }
    });

    const newActivity = {
      ...activityWithOldStatus,
      ...activity,
      ...activityResponse,
      isLoading: withLoader,
    };
    if (newActivity.hasOwnProperty("AccountId")) {
      delete newActivity["AccountId"];
    }
    const assetId = activity["AssetId"];

    // Change the asset field inside the activity,
    // because activity could have been assigned to another item (asset)
    newActivity.Asset = {
      Name: cache.readFragment({ fragment: assetNameFragment, id: assetId }).Name,
      Id: assetId,
      __typename: "Asset",
    };

    activitiesToStatus[ACCOUNT]["AssetActivities"] = activitiesToStatus[ACCOUNT][
      "AssetActivities"
    ].concat([newActivity]);
    cache.writeQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: statusObjectFrom,
      },
      data: activitiesFromStatus,
    });

    cache.writeQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: statusObjectTo,
      },
      data: activitiesToStatus,
    });
  } catch (e) {}
};

export const turnOffLoadingInActivityCache = (client, activity, statusObjectTo, accountId) => {
  try {
    const activitiesWithToStatus = client.readQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: statusObjectTo,
        isLoading: activity.isLoading,
      },
    });

    activitiesWithToStatus[ACCOUNT]["AssetActivities"] = activitiesWithToStatus[ACCOUNT][
      "AssetActivities"
    ].map((element) => {
      if (element.Id === activity.Id) {
        element.isLoading = false;
      }
      return element;
    });

    client.writeQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: statusObjectTo,
      },
      data: activitiesWithToStatus,
    });
  } catch (error) {}
};
