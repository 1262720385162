import * as React from "react";

interface ActivityCapProps {
  listView?: boolean;
}

const ActivityCap: React.FC<ActivityCapProps> = ({ listView }) => {
  return (
    <>
      <div className={`activity-cap ${listView ? "activity-cap--list-view" : ""}`}>
        <div className="activity-cap__text-cap-container">
          <div className="activity-cap__text-cap activity-cap__text-cap--title" />
          <div className="activity-cap__text-cap activity-cap__text-cap--info" />
          <div className="activity-cap__text-cap activity-cap__text-cap--other" />
        </div>
        <div className="activity-cap__img-cap activity-cap__gradient" />
      </div>
      <div className={`activity-cap ${listView ? "activity-cap--list-view" : ""}`}>
        <div className="activity-cap__text-cap-container">
          <div className="activity-cap__text-cap activity-cap__text-cap--title" />
          <div className="activity-cap__text-cap activity-cap__text-cap--info" />
          <div className="activity-cap__text-cap activity-cap__text-cap--other" />
        </div>
        <div className="activity-cap__img-cap activity-cap__gradient" />
      </div>
    </>
  );
};

export default ActivityCap;
