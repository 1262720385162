import * as React from "react";

interface LogoProps {
  className?: string;
}
const Logo: React.FC<LogoProps> = ({ className = "" }) => {
  return (
    <div className={`Logo ${className}`} />
  );
};

export default Logo;
