import styled from "styled-components";

export const AccountNotificationSettingsContainer = styled.section`
  .Accordion_header {
    display: flex;
  }
  .MuiSvgIcon-root {
    margin-left: auto;
  }
`;
