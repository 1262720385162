import React, { useContext, useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { ROUTES } from "../../_constant/screens";

import { setEditFormIsDirty } from "../../redux/actions/actions";

import Button from "@material-ui/core/Button/Button";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import Form from "../Form/Form";
import Body from "../Body/Body";
import ItemsTab from "../ItemsTab/ItemsTab";
import VisitTab from "../VisitTab/VisitTab";
import Modal from "../../containers/Modal/Modal";
import FormHeader from "../FormHeader/FormHeader";
import { ITabContent } from "./CustomerFormDesktop";
import CustomerFormLayout from "./CustomerFormLayout";
import VisitTabContent from "../VisitTab/VisitTabContent";
import { CustomerFormContext } from "./CustomerFormContext";
import VisitStatusContent from "../VisitTab/VisitStatusContent";
import { RevertDialogComponent } from "../../containers/_common/Dialog";
import EntityTabsProvider from "../EntityTabsContainer/EntityTabsProvider";
import DeleteCustomerDialog from "../DeleteCustomerDialog/DeleteCustomerDialog";
import CustomizeActivityButton from "../CustomizeActivityButton/CustomizeActivityButton";
import EntityTabsBusinessContainer from "../EntityTabsContainer/EntityTabsBusinessMobile";
import RelatedActivities from "../../containers/assets/_components/relaitedActivities/RelatedActivities";
import StatusAccordionCustomer from "../../containers/assets/_components/relaitedActivities/byStatuses/StatusAccordionCustomer";
import StatusesAccordionBuilder from "../../containers/assets/_components/relaitedActivities/byStatuses/StatusesAccordionBuilder";

import { FormFilesContainer, InfoTabContainer } from "../FormMobile/BusinessInfoTabWrapper.style";

const CustomerFormMobile: React.FC = () => {
  const {
    isDeletePopupVisible,
    isWizardOpen,
    isCustomize,
    isRevertPopupVisible,
    handleCloseWizard,
    handleOpenWizard,
    handleCustomize,
    setIsDeletePopupVisible,
    setIsRevertPopupVisible,
    handleSubmit,
    isLoading,
    isNew,
    data: propsData,
    setHeaderOptions,
    lockSaveButton,
    formApi,
    formData,
    phoneError,
    emailError,
    handleBlur,
    handleChangeWithValidation,
    dropZone,
  } = useContext(CustomerFormContext);

  const dispatch = useDispatch();

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);
  const [isItemsFetching, setIsItemsFetching] = useState(false);

  const handleDeleteDialogOpen = () => {
    setIsDeletePopupVisible(true);
    setIsItemsFetching(true);
  };

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  const customerTabs: ITabContent[] = [
    {
      name: "Info",
      disabled: false,
      content: (
        <InfoTabContainer>
          <CustomerFormLayout
            errorFields={formApi.errorFields}
            data={formApi.data}
            handleChange={formApi.handleChange}
            handleChangeWithValidation={handleChangeWithValidation}
            handleBlur={handleBlur}
            phoneError={phoneError}
            emailError={emailError}
          />
          <FormFilesContainer>{dropZone}</FormFilesContainer>
        </InfoTabContainer>
      ),
      hasCustomizeMode: false,
    },
    {
      name: "Visit",
      disabled: isNew,
      content: (
        <VisitTab customerId={formData.Id ?? ""} isCustomize={isCustomize}>
          {formData.Id && (
            <VisitTabContent
              customerId={formData.Id ?? ""}
              renderComponent={(props) => {
                return <VisitStatusContent customerId={formData?.Id || ""} {...props} />;
              }}
            />
          )}
        </VisitTab>
      ),
      tabControl: isNew ? null : (
        <CustomizeActivityButton
          disabled={false}
          onClick={handleCustomize}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Visit"}
        </CustomizeActivityButton>
      ),
      hasCustomizeMode: true,
    },
    {
      name: "Activities",
      disabled: isNew,
      content: (
        <RelatedActivities
          assetId={""}
          isCustomize={isCustomize}
          customerId={formData.Id ?? ""}
          withAssetSelect
        >
          {formData.Id ? (
            <StatusesAccordionBuilder
              withCreateButton
              customerId={formData.Id ?? ""}
              renderComponent={(props) => (
                <StatusAccordionCustomer customerId={formData?.Id || ""} {...props} />
              )}
            />
          ) : null}
        </RelatedActivities>
      ),
      tabControl: isNew ? null : (
        <CustomizeActivityButton
          disabled={false}
          onClick={handleCustomize}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Activity"}
        </CustomizeActivityButton>
      ),
      hasCustomizeMode: true,
    },
    {
      name: "Items",
      disabled: isNew,
      content: (
        <ItemsTab
          isWizardOpen={isWizardOpen}
          handleCloseDialog={handleCloseWizard}
          customerId={formData.Id ?? ""}
          isCustomerLoading={isLoading}
          withCreateButton
          handleOpenWizard={handleOpenWizard}
        />
      ),
      tabControl: isNew ? null : (
        <Button
          onClick={handleOpenWizard}
          color="primary"
          startIcon={<AddOutlinedIcon color="primary" fontSize="small" />}
        >
          Item
        </Button>
      ),
      hasCustomizeMode: false,
    },
  ];

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      pageTitle: isNew ? "Add Customer" : "Edit Customer",
      saveOptions: {
        isLoading: isLoading || isItemsFetching,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        delete: handleDeleteDialogOpen,
        revertFunction: openRevertDialog,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading, isItemsFetching]);

  return (
    <Body disableDesktopScroll>
      <Modal isShowing={isRevertPopupVisible}>
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      </Modal>
      <Modal isShowing={isDeletePopupVisible}>
        <DeleteCustomerDialog
          customerId={formData.Id}
          customerName={formData.Name}
          handleCancel={() => setIsDeletePopupVisible(false)}
          handleFinishPopupLoading={() => setIsItemsFetching(false)}
        />
      </Modal>
      <Form>
        <Form header>
          <FormHeader
            isNew={isNew}
            isBusinessAccount={false}
            title={"Customer"}
            titlePrefix={isNew ? "Add New" : undefined}
            isLoading={isLoading}
            isSaveAvailable={lockSaveButton}
            handleSubmit={handleSubmit}
            cancelRouteForEmptyHistory={ROUTES.CUSTOMERS}
            revertData={openRevertDialog}
            isDataChanged={formApi.isDataChanged}
          />
        </Form>

        <Form body>
          <Form content>
            {
              <EntityTabsProvider>
                <EntityTabsBusinessContainer
                  content={customerTabs}
                  entityName="Customer"
                  isNew={isNew}
                  startFrom="0"
                  tabIndexAfterCreate="3"
                />
              </EntityTabsProvider>
            }
          </Form>
        </Form>
      </Form>
    </Body>
  );
};

export default CustomerFormMobile;
