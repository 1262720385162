import React from "react";

import "./LayoutSwitcher.scss";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import ViewDayOutlinedIcon from "@material-ui/icons/ViewDayOutlined";
import ViewDayIcon from "@material-ui/icons/ViewDay";

import { LayoutType } from "../../_constant/constants";
import { pageSwitcher } from "../LayoutSwitcher/useLayoutSwitcherContext";

interface ActivitiesLayoutSwitcherProps {
  layout: LayoutType;
  switchLayout: pageSwitcher;
}

const ActivitiesLayoutSwitcher: React.FC<ActivitiesLayoutSwitcherProps> = ({
  layout,
  switchLayout,
}) => {
  return (
    <ButtonGroup className="LayoutSwitcher">
      <Button
        className={`LayoutSwitcher__button ${
          layout === LayoutType.BOARD ? "LayoutSwitcher__button--active" : ""
        }`}
        onClick={switchLayout.toBoardView}
      >
        {layout === LayoutType.BOARD ? (
          <ViewWeekIcon className="LayoutSwitcher__icon" />
        ) : (
          <ViewWeekOutlinedIcon className="LayoutSwitcher__icon" />
        )}
      </Button>
      <Button
        className={`LayoutSwitcher__button ${
          layout === LayoutType.LIST ? "LayoutSwitcher__button--active" : ""
        }`}
        onClick={switchLayout.toListView}
      >
        {layout === LayoutType.BOARD ? (
          <ViewDayOutlinedIcon className="LayoutSwitcher__icon" />
        ) : (
          <ViewDayIcon className="LayoutSwitcher__icon" />
        )}
      </Button>
    </ButtonGroup>
  );
};

export default ActivitiesLayoutSwitcher;
