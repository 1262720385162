import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const ControlVendorButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  margin-left: 20px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;
