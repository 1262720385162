import React from "react";
import { ReactComponent as UpkeeprQRcode } from "../../images/brands/default/QR_code.svg";
import { ReactComponent as AutixQRcode } from "../../images/brands/autix/QR_code.svg";

import { BrandType } from "../../types";

interface AboutPageConfig {
  copyright: string;
  sharingLink: string;
  sharingLinkLabel: string;
  website: string;
  QRcode: React.FC;
  acknowledgementsText: string;
  acknowledgementsLink: string;
  acknowledgementsLinkLabel: string;
}

export const generateContentInfo = (): AboutPageConfig => {
  const brand = process.env.REACT_APP_BRAND as BrandType;
  const currentYear = new Date().getFullYear();

  switch (brand) {
    case "autix":
      return {
        copyright: currentYear > 2022 ? `Copyright 2022-${currentYear} Autix Automotive Inc.` : `Copyright ${currentYear} Autix Automotive Inc.`,
        sharingLink: "https://www.autix.co",
        sharingLinkLabel: "autix.co",
        website: "autix.co",
        QRcode: AutixQRcode,
        acknowledgementsText: "Powered by Upkeepr:",
        acknowledgementsLink: "https://upkeepr.com",
        acknowledgementsLinkLabel: "upkeepr.com",
      };
    default:
      return {
        copyright: `Copyright 2020-${currentYear} Upkeepr Corp.`,
        sharingLink: "http://www.upkeepr.com/getit",
        sharingLinkLabel: "upkeepr.com/getit",
        website: "upkeepr.com",
        QRcode: UpkeeprQRcode,
        acknowledgementsText: "Author of some embedded images:",
        acknowledgementsLink: "http://www.freepik.com",
        acknowledgementsLinkLabel: "freepik.com",
      };
  }
};
