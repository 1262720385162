import { NormalizedFile } from "../components/DropZoneContainer/DropZoneContainer";
import { composeImageUrl } from "./utils";
import { NormalizedFileModel } from "../redux/models/data/NormalizedFileModel";

export type FileType = "image" | "document" | "video";

export interface ImageFromServer {
  AbsoluteUri: string;
  ContainerId: string;
  ContainerName: string;
  Description: string;
  FileExtension: string;
  Id: string;
  IsPrimary: boolean;
  Name: string;
  SortOrder: number;
}

export interface DocumentFromServer {
  AbsoluteUri: string;
  ContainerId: string;
  ContainerName: string;
  CreateDateTime: string;
  CreateUser: any;
  CreateUserId: string;
  Description: string;
  FileExtension: string;
  Id: string;
  IsDeleted: boolean;
  MimeType: string;
  Name: string;
  TotalBytes: number;
  UpdateDateTime: string;
  UpdateUser: any;
  UpdateUserId: string;
  Uri: string;
}

interface ImagesMapperOptions<T> {
  file: T;
  index: number;
}

interface FilesMapperOptions<T> {
  file: T;
  icon: string;
}

export const mapServerImageToNormalized = ({
  file,
  index,
}: ImagesMapperOptions<ImageFromServer>): NormalizedFile =>
  new NormalizedFileModel({
    id: file.Id,
    imgUrl: file.AbsoluteUri ?? composeImageUrl(file.Id, file.ContainerName, file.ContainerId),
    url: null,
    isLocal: false,
    fileType: "image" as FileType,
    title: file.Name,
    imgOrder: index,
  });

export const mapLocalImageToNormalized = ({ file, index }: ImagesMapperOptions<File>) =>
  new NormalizedFileModel({
    id: file.path,
    imgUrl: URL.createObjectURL(file),
    url: null,
    isLocal: true,
    fileType: "image" as FileType,
    title: file.name,
    imgOrder: index,
  });

export const mapServerDocumentToNormalized = ({
  file,
  icon,
}: FilesMapperOptions<DocumentFromServer>): NormalizedFile =>
  new NormalizedFileModel({
    id: file.Id,
    imgUrl: icon,
    url: file.AbsoluteUri ?? composeImageUrl(file.Id, file.ContainerName, file.ContainerId),
    isLocal: false,
    fileType: "document" as FileType,
    title: file.Name,
  });

export const mapLocalDocumentsToNormalized = ({ file, icon }: FilesMapperOptions<File>) =>
  new NormalizedFileModel({
    id: file.path,
    imgUrl: icon,
    url: URL.createObjectURL(file),
    isLocal: true,
    fileType: "document" as FileType,
    title: file.name,
  });

export const mapServerVideoToNormalized = ({
  file,
  icon,
}: FilesMapperOptions<DocumentFromServer>) =>
  new NormalizedFileModel({
    id: file.Id,
    imgUrl: icon,
    url: file.AbsoluteUri ?? composeImageUrl(file.Id, file.ContainerName, file.ContainerId),
    isLocal: false,
    fileType: "video" as FileType,
    title: file.Name,
  });

export const mapLocalVideoToNormalized = ({ file, icon }: FilesMapperOptions<File>) =>
  new NormalizedFileModel({
    id: file.path,
    imgUrl: icon,
    url: URL.createObjectURL(file),
    isLocal: true,
    fileType: "video" as FileType,
    title: file.name,
  });
