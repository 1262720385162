import React from "react";
import "./DeleteButton.scss";
import { Button, CircularProgress } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

interface DeleteButtonProps {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  icon?: React.ReactElement;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick,
  disabled,
  loading,
  children,
  icon = <DeleteOutlineOutlinedIcon />,
  ...otherProps
}) => {
  return (
    <Button
      className="DeleteButton"
      startIcon={icon}
      onClick={onClick}
      disabled={disabled || loading}
      {...otherProps}
    >
      {children}
      {loading && <CircularProgress size={24} />}
    </Button>
  );
};

export default DeleteButton;
