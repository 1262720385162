export const getIdentityConfig = (appAuthUrl, appUrl, appId) => ({
  authority: appAuthUrl,
  client_id: appId,
  redirect_uri: appUrl,
  scope: "openid profile email UpkeeprApi offline_access",
  response_type: "code",
  login: appAuthUrl,
  automaticSilentRenew: true,
  loadUserInfo: false, // Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: appUrl + "/silentrenew", //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: appUrl,
  responseType: "id_token token refresh_token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  grantType: "password",
  revokeAccessTokenOnSignout: true,
  // webAuthResponseType: "id_token token refresh_token"
});

export const getMetadataOidc = (REACT_APP_AUTH_URL) => ({
  issuer: REACT_APP_AUTH_URL,
  jwks_uri: REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: REACT_APP_AUTH_URL + "/connect/authorize",
  token_endpoint: REACT_APP_AUTH_URL + "/connect/token",
  userinfo_endpoint: REACT_APP_AUTH_URL + "/connect/userinfo",
  end_session_endpoint: REACT_APP_AUTH_URL + "/connect/endsession",
  check_session_iframe: REACT_APP_AUTH_URL + "/connect/checksession",
  revocation_endpoint: REACT_APP_AUTH_URL + "/connect/revocation",
  introspection_endpoint: REACT_APP_AUTH_URL + "/connect/introspect",
  device_authorization_endpoint: REACT_APP_AUTH_URL + "/connect/deviceauthorization"
});