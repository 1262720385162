import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useEntityTabsContext } from "../../../components/EntityTabsContainer/useEntityTabsContext";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import useGetBusinessStatuses from "../../../customHooks/useGetBusinessStatuses";
import useGetUserId from "../../../customHooks/useGetUserId";

import { VisitModel } from "../../../redux/models/data/VisitModel";

import {
  finishVisitMutation,
  startVisitMutation,
} from "../../../components/VisitForm/updateVisitStatus.query";

import * as statusesConfig from "../../../../configs/statuses-config.json";
import { convertStatusToFilterVariable } from "../../../redux/actions/_utils";
import updateCacheAfterVisitUpdate from "../../../pages/EditVisit/updateCacheAfterVisitUpdate";
import { getActivityFilters } from "../../../redux/reducers/appReducer";

import StartVisitButton from "../../../components/StartVisitButton/StartVisitButton";
import { getVisitsByStatusQuery } from "../../../components/VisitsByStatuses/visitsByStatus.query";
import { isRecurring } from "../../../_utils/utils";

const { EMPTY_BUSINESS, FINISH } = statusesConfig["statusNameMap"];

interface StartVisitButtonContainerProps {
  customerId: string;
  visitStatusId: string;
  loading: boolean;
  visit: VisitModel;
  showButton: boolean;
  formApiVisit: VisitModel;
  handleFinishVisit: (data: any[]) => void;
}

const StartVisitButtonContainer: React.FC<StartVisitButtonContainerProps> = ({
  customerId,
  visitStatusId,
  loading,
  visit,
  showButton,
  formApiVisit,
  handleFinishVisit,
}) => {
  const [isVisitStarted, setIsVisitStarted] = useState<boolean>(false);
  const [isVisitFinished, setIsVisitFinished] = useState<boolean>(false);
  const [requestFilterString, setRequestFilterString] = useState("");

  const { accountId } = useGetAccountId();
  const userId = useGetUserId();
  const businessStatuses = useGetBusinessStatuses();
  const { changeTab } = useEntityTabsContext();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const filterMap = useSelector(
    (state) => getActivityFilters(state),
    (map1, map2) => {
      if (!map1.has(visitStatusId)) {
        return true;
      }
      if (map1.get(visitStatusId) === map2.get(visitStatusId)) {
        return true;
      }
      return false;
    },
  );

  const [startVisit, { loading: startVisitLoading, data: startVisitData }] = useMutation(
    startVisitMutation,
  );
  const [finishVisit, { loading: finishVisitLoading, data: finishVisitData }] = useMutation(
    finishVisitMutation,
    {
      onCompleted: ({ VisitFinish }) => {
        handleFinishVisit(VisitFinish?.FinishedVisitActivities || []);
      },
    },
  );

  useEffect(() => {
    if (businessStatuses) {
      const foundStatus = businessStatuses.find((status) => status.Id === visitStatusId);
      if (foundStatus) {
        setRequestFilterString(
          convertStatusToFilterVariable(foundStatus, filterMap, "VisitStatusId"),
        );
        const isFinished = foundStatus.Name === FINISH;
        const inProgress = foundStatus.Name !== EMPTY_BUSINESS && foundStatus.Name !== FINISH;
        setIsVisitFinished(isFinished);
        setIsVisitStarted(inProgress);
      }
    }
  }, [businessStatuses, visitStatusId, filterMap]);

  const onClickHandler = () => {
    if (loading || !businessStatuses || !accountId || isVisitFinished) return;

    const imagesWithAppropriateTypename =
      visit.Images?.map((image) => {
        delete image.__typename;
        return image;
      }) ?? [];
    const documentsWithAppropriateTypename =
      visit.Documents?.map((doc) => {
        delete doc.__typename;
        return doc;
      }) ?? [];
    const videosWithAppropriateTypename =
      visit.Videos?.map((video) => {
        delete video.__typename;
        return video;
      }) ?? [];
    const shallowVisit: Partial<VisitModel> & { AccountId: string } = {
      ...visit,
      Images: imagesWithAppropriateTypename,
      Documents: documentsWithAppropriateTypename,
      Videos: videosWithAppropriateTypename,
      AccountId: accountId,
    };
    delete shallowVisit.VisitStatusId;
    delete shallowVisit.Files;
    delete shallowVisit.VisitStatusName;
    delete shallowVisit.CustomerName;

    const tabToSwitch = isDesktop ? "0" : "1";

    if (isVisitStarted) {
      if (!userId) return;

      const recurring = isRecurring({
        schedule: visit.Schedule,
        relatedDueDate: visit.RelatedDueDateTime,
      });

      const refetchQueries: any = recurring
        ? [
            {
              query: getVisitsByStatusQuery,
              variables: {
                Id: accountId,
                Filter: convertStatusToFilterVariable(
                  businessStatuses[0],
                  filterMap,
                  "VisitStatusId",
                ),
              },
            },
          ]
        : [];

      finishVisit({
        variables: {
          Visit: shallowVisit,
          Files: visit.Files,
        },
        update: (cache, { data: { VisitFinish } }) => {
          const newStatus = businessStatuses.find(
            (status) => status.Id === VisitFinish.VisitStatusId,
          );
          const newFilterString = convertStatusToFilterVariable(
            newStatus,
            filterMap,
            "VisitStatusId",
          );
          updateCacheAfterVisitUpdate(
            cache,
            visit,
            VisitFinish,
            accountId,
            requestFilterString,
            newFilterString,
          );
        },
        refetchQueries,
      })
        .then(() => changeTab(tabToSwitch))
        .catch((error) => console.log(error));
    } else {
      startVisit({
        variables: {
          Visit: shallowVisit,
          Files: visit.Files,
        },
        update: (cache, { data: { VisitStart } }) => {
          const newStatus = businessStatuses.find(
            (status) => status.Id === VisitStart.VisitStatusId,
          );
          const newFilterString = convertStatusToFilterVariable(
            newStatus,
            filterMap,
            "VisitStatusId",
          );
          updateCacheAfterVisitUpdate(
            cache,
            visit,
            VisitStart,
            accountId,
            requestFilterString,
            newFilterString,
          );
        },
      })
        .then(() => changeTab(tabToSwitch))
        .catch((error) => console.log(error));
    }
  };

  if (!showButton) return null;

  if (isVisitFinished) return null;

  return (
    <StartVisitButton
      isStarted={isVisitStarted}
      onClick={onClickHandler}
      isLoading={loading || startVisitLoading || finishVisitLoading}
      disabled={!customerId}
    />
  );
};

export default StartVisitButtonContainer;
