import * as React from "react";
import styled from "styled-components";

import { ActivityAffiliateModel } from "../redux/models/data/ActivityAffiliateModel";

import Link from "@material-ui/core/Link";

import { useStaticDataQuery } from "../queries/useStaticDataQuery";

import youtubeSample from "../../images/youtube.svg";

export const StyledLink = styled(Link)`
  height: 78px;
  width: calc(33.33% - var(--space-size--s));
  margin-left: var(--space-size--s);
  margin-top: var(--space-size--s);
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: var(--shadow-suggestion-card);

  &:hover {
    box-shadow: var(--shadow-card-active);
    transform: translateY(-3px);
    cursor: pointer;
    transition: all 0.2s ease;
  }

  @media (max-width: 768px) {
    width: calc(50% - var(--space-size--s));
  }

  @media (max-width: 464px) {
    max-width: 100%;
    margin: 0 auto 12px;
    width: 100%;
    max-height: 100px;
  }
`;

export const CardType = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: start;
  color: var(--color-brand);
  padding: 12px;
  white-space: nowrap;
  span {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 0;
  position: relative;

  &:last-child {
    padding-bottom: 12px;
  }
`;

export const Logo = styled.img`
  width: 55px;
  height: 25px;
  object-fit: cover;
`;

interface AffiliateCardProps {
  affiliate: ActivityAffiliateModel;
}

const AffiliateCard: React.FC<AffiliateCardProps> = ({ affiliate }) => {
  const { Url, Type, Text, Icon } = affiliate;
  const staticData = useStaticDataQuery();
  const logo = Type ? staticData?.Affiliates[Type.toLowerCase()] : null;

  return (
    <StyledLink href={Url ?? ""} target="_blank" rel="noreferrer">
      <LogoContainer>
        <Logo src={logo ? logo : youtubeSample} alt={Text ? Text : ""} />
      </LogoContainer>
      <CardType>
        <span className="Icon">{Icon}</span>
        {Text}
      </CardType>
    </StyledLink>
  );
};

export default AffiliateCard;
