import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { useDispatch } from "react-redux";

import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { AccountModel } from "../../redux/models/data/AccountModel";

import { createNewAccountQuery } from "./NewAccount.query";
import { getAccountRefsList } from "../Accounts/getAccountList.query";

import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";
import { ACCOUNT_POST, ACCOUNT_REFS, ID, USER } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { updateCacheWithNewAccount } from "./updateCacheWithNewAccount";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import AccountForm from "../../components/AccountForm/AccountForm";
import { ExceededLimitDialog } from "../../containers/_common/Dialog";

const NewAccount = () => {
  const [createAccount, { loading: isCreating }] = useMutation(createNewAccountQuery);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data] = useState(new AccountModel());
  const [isLimitExceeded, setLimitExceeded] = useState(false);

  const { successfulRedirect } = useSuccessfulRedirect();
  const limits = useGetLimits();
  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
  });

  const { data: accountsData, loading: isAccountsLoading } = useQuery(getAccountRefsList, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (isAccountsLoading || !accountsData || !limits) return;

    const accountLimit =
      limits?.account?.maxPersonalAccounts?.Value + limits?.account?.maxGroupAccounts?.Value;
    if (accountsData?.[USER]?.[ACCOUNT_REFS]?.length >= accountLimit) {
      setLimitExceeded(true);
    } else {
      setLimitExceeded(false);
    }
  }, [limits, accountsData, isAccountsLoading]);

  const handleCreate = (data: AccountModel, saveType: SaveType) => {
    const variables = { ...data };
    delete variables.MaxMembers;

    createAccount({
      variables: variables,
      update(cache, { data: { AccountPost } }) {
        updateCacheWithNewAccount(cache, AccountPost);
      },
    })
      .then(({ data }: any) => {
        if (data[ACCOUNT_POST][ID] && saveType.type === SAVE) {
          saveType.callback(data[ACCOUNT_POST]);
          dispatch(setEditFormIsDirty(false));
          displaySuccessNotification(NotificationTypes.ACCOUNT_CREATED);
          const currentAccountPath = `${ROUTES.SINGLE_ACCOUNT}/${data[ACCOUNT_POST][ID]}`;
          navigate(currentAccountPath, { replace: true, state: { justCreated: true } });
        } else if (data[ACCOUNT_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(data[ACCOUNT_POST]);
          successfulRedirect(ROUTES.ACCOUNTS, NotificationTypes.ACCOUNT_CREATED);
        } else {
          displayErrorNotification(NotificationTypes.ACCOUNT_CREATE_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error(message);
        } else {
          displayErrorNotification(NotificationTypes.ACCOUNT_CREATE_ERROR);
        }
      });
  };

  return (
    <>
      <AccountForm
        handleSubmit={handleCreate}
        isLoading={isCreating || isAccountsLoading || !accountsData}
        isNew
        data={data}
        setHeaderOptions={setHeaderOptions}
      />
      {isLimitExceeded && (
        <ExceededLimitDialog
          message="Creating a new Account would exceed your limit."
          submit={() => navigate(-1)}
          cancel={() => navigate(ROUTES.EDIT_PROFILE)}
        />
      )}
    </>
  );
};

export default NewAccount;
