import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import withScrolling from "react-dnd-scrolling";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import { useChange } from "../../../customHooks/useChange";

import { ROUTES } from "../../../_constant/screens";
import { ASSET_TYPE, NAME, PARENT_ID } from "../../../_constant/wordings";
import { NotificationTypes } from "../../../_constant/NotificationTypes";

import { SAVE, SAVE_AND_CLOSE, SaveType } from "../existing/EditAsset";
import { ITabContent } from "../../../components/CustomerForm/CustomerFormDesktop";

import { WIZARD_SCREENS_NAME } from "../../../components/Wizard/WizardContainer/WizardConstants";

import { AssetModel } from "../../../redux/models/data/AssetModel";
import { AccountModel } from "../../../redux/models/data/AccountModel";

import { isDebugInfoEnabled } from "../../../redux/reducers/appReducer";
import { setEditFormIsDirty } from "../../../redux/actions/actions";
import { displayWarningNotification } from "../../../redux/actions/_utils";

import Collapse from "@material-ui/core/Collapse";

import Body from "../../../components/Body/Body";
import Form from "../../../components/Form/Form";
import EssentialAsset from "./EssentialAsset";
import RelatedActivities from "./relaitedActivities/RelatedActivities";
import DeleteItemPopup from "./deleteAssetPopup/DeleteAssetPopupWrapper";
import AssetManageTab from "../../../components/AssetManageTab/AssetManageTab";
import ItemCustomFieldsContainer from "../../../components/ItemCustomFields/ItemCustomFieldsContainer";
import SaveButton from "../../../components/SaveButton/SaveButton";
import EntityTabsContainer from "../../../components/EntityTabsContainer/EntityTabsContainer";
import ActionsBar from "../../../components/ActionsBar/ActionsBar";
import MoveAssetPopupWrapper from "./MoveAssetPopup/MoveAssetPopupWrapper";
import EntityTabsProvider from "../../../components/EntityTabsContainer/EntityTabsProvider";
import FormPageTitle from "../../../components/FormPageTitle/FormPageTitle";
import StatusesAccordionBuilder from "./relaitedActivities/byStatuses/StatusesAccordionBuilder";
import StatusAccordionComponent from "./relaitedActivities/byStatuses/StatusAccordionComponent";
import CustomerInfo from "../../../components/CustomerInfo/CustomerInfo";
import CustomizeActivityButton from "../../../components/CustomizeActivityButton/CustomizeActivityButton";
import CustomizeToggleButton from "../../../components/CustomizeToggleButton/CustomizeToggleButton";
import StatusAccordionCustomer from "./relaitedActivities/byStatuses/StatusAccordionCustomer";
import DropZoneContainer from "../../../components/DropZoneContainer/DropZoneContainer";
import CommonInfoTabContainer from "../../../components/CommonInfoTabContainer/CommonInfoTabContainer";
import { AssetIconsDialog, RevertDialogComponent } from "../../_common/Dialog";
import { useGetLimits } from "../../../customHooks/useGetLimits";
import PartsTabContainer from "../../../components/PartsTabContainer/PartsTabContainer";
import WizardContainer from "../../../components/Wizard/WizardContainer/WizardContainer";

interface EditAssetPageProps {
  assetId?: string;
  statusFrom?: string;
  assetData?: AssetModel;
  handleSubmit: (data: AssetModel, saveType: SaveType) => void;
  isLoading: boolean;
  setHeaderOptions: (props: any) => void;
}

const ScrollingComponent = withScrolling("div");

const AssetForm: React.FC<EditAssetPageProps> = (props) => {
  const formInitObject = {
    initData: new AssetModel(),
    classModel: AssetModel,
    requiredFields: [ASSET_TYPE, NAME],
    fieldsForValidation: new Map([
      [ASSET_TYPE, null],
      [NAME, null],
    ]),
  }; // if null received instead of function - useChange used default one "validateNameField"
  const formApi = useChange<AssetModel>(formInitObject);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const limits = useGetLimits();

  const [isIconsPopupVisible, setIsIconsPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isActivitiesFetching, setIsActivitiesFetching] = useState(false);
  const [isItemMovePopupVisible, setIsItemMovePopupVisible] = useState(false);
  const [accountMoveTo, setAccountMoveTo] = useState<AccountModel>(new AccountModel());
  const [isCustomize, setIsCustomize] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const { IsCustomerManagement } = useGetAccountId();

  const isDev = useSelector(isDebugInfoEnabled);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const [assetTypeIcon, setAssetTypeIcon] = useState(formApi.data.AssetType.IconName);

  const { assetId, assetData, setHeaderOptions } = props;

  const lockSaveButton = formApi.isDataChanged && formApi.isDataValid;
  const isNew = !assetId;

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);

  useEffect(() => {
    if (assetData && !props.isLoading) {
      formApi.updateInitFormData(new AssetModel(assetData));
      setAssetTypeIcon(assetData.AssetType.IconName);
    }
  }, [assetData]);

  const handlePopupClose = () => {
    setIsDeletePopupVisible(false);
    setIsIconsPopupVisible(false);
  };

  const handleIconSubmit = (icon) => {
    formApi.handleChangeIconName(icon);
    handlePopupClose();
  };

  const handleDeletePopupOpen = () => {
    setIsActivitiesFetching(true);
    setIsDeletePopupVisible(true);
  };

  const handleSubmit = (e?, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: SaveType = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: formApi.updateInitFormData,
    };

    props.handleSubmit(formApi.data, saveType);
  };

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      saveOptions: {
        isLoading: props.isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        revertFunction: openRevertDialog,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, props.isLoading]);

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  const handleConfirmOpen = (accountMoveItemTo: AccountModel) => {
    setIsItemMovePopupVisible(true);
    setIsActivitiesFetching(true);
    setAccountMoveTo(accountMoveItemTo);
  };

  const handleCustomize = () => {
    setIsCustomize((prev) => !prev);
  };

  const formTabs: ITabContent[] = [
    {
      name: "Activities",
      disabled: isNew,
      hasCustomizeMode: true,
      content: assetData ? (
        <RelatedActivities
          assetId={assetId || ""}
          hidden={isNew}
          isCustomize={isCustomize}
          withAssetSelect={false}
          customerId={assetData?.CustomerId || ""}
          visitId={(location?.state?.visitId as string) ?? ""}
        >
          <StatusesAccordionBuilder
            withCreateButton={false}
            customerId={assetData?.CustomerId || ""}
            renderComponent={(props) =>
              IsCustomerManagement ? (
                <StatusAccordionCustomer
                  customerId={assetData?.CustomerId || ""}
                  AssetId={assetId || ""}
                  {...props}
                />
              ) : (
                <StatusAccordionComponent AssetId={assetId || ""} {...props} />
              )
            }
          />
        </RelatedActivities>
      ) : null,
      tabControl: (
        <CustomizeActivityButton
          onClick={handleCustomize}
          disabled={isNew}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Activity"}
        </CustomizeActivityButton>
      ),
    },
    {
      name: "Parts",
      disabled: isNew,
      hasCustomizeMode: false,
      content: <PartsTabContainer currentAssetId={assetId || ""} isLoading={props.isLoading} />,
      tabControl: (
        <CustomizeActivityButton
          onClick={() => setIsWizardOpen(true)}
          disabled={isNew}
          color="primary"
          withStartIcon={true}
        >
          Part
        </CustomizeActivityButton>
      ),
    },
    {
      name: "Common Info",
      disabled: isNew,
      hasCustomizeMode: false,
      content: (
        <CommonInfoTabContainer
          onChange={formApi.handleChange}
          assetType={formApi.data?.AssetType}
          AssetFieldValues={formApi.data?.TypeFieldValues ?? {}}
          multipleUpdate={formApi.updateManyFields}
        />
      ),
      tabControl: null,
    },
    {
      name: "Item Info",
      disabled: isNew,
      hasCustomizeMode: true,
      content: (
        <ItemCustomFieldsContainer
          CustomFields={formApi.data.CustomFields}
          Schema={formApi.data.Schema}
          onChange={formApi.handleChange}
          isCustomize={isCustomize}
          entityName="Item"
          isLoading={props.isLoading}
        />
      ),
      tabControl: (
        <CustomizeToggleButton onClick={handleCustomize} checked={isCustomize} disabled={isNew} />
      ),
    },
    {
      name: "Manage",
      disabled: isNew,
      hasCustomizeMode: false,
      content: (
        <AssetManageTab
          isRemoveLoading={isActivitiesFetching && isDeletePopupVisible}
          isMoveLoading={isActivitiesFetching && isItemMovePopupVisible}
          disabled={!assetId || formApi.isDataChanged}
          onDelete={handleDeletePopupOpen}
          openConfirm={handleConfirmOpen}
          isCustomerManagement={IsCustomerManagement}
        />
      ),
      tabControl: null,
    },
  ];

  const [testInputValue, setTestInputValue] = React.useState("");
  const handleInputValueChange = (e) => {
    const { target } = e;

    setTestInputValue(target.value);
  };

  return (
    <>
      {isDeletePopupVisible && (
        <DeleteItemPopup
          handlePopupClose={handlePopupClose}
          assetId={assetId}
          assetName={assetData ? assetData.Name : ""}
          handleFinishPopupLoading={() => setIsActivitiesFetching(false)}
          isCustomerManagement={IsCustomerManagement}
          customerId={assetData?.CustomerId}
          backToParentAsset={!!assetData?.[PARENT_ID]}
        />
      )}
      {isItemMovePopupVisible && (
        <MoveAssetPopupWrapper
          lockDeleteButton={false}
          handlePopupClose={() => setIsItemMovePopupVisible(false)}
          assetData={formApi.data}
          accountMoveTo={accountMoveTo}
          handleFinishPopupLoading={() => setIsActivitiesFetching(false)}
          isCustomerManagement={IsCustomerManagement}
        />
      )}
      {isIconsPopupVisible && (
        <AssetIconsDialog
          cancel={handlePopupClose}
          submit={() => handleIconSubmit(assetTypeIcon)}
          selectedIcon={assetTypeIcon}
          onIconUpdate={setAssetTypeIcon}
        />
      )}
      {isRevertPopupVisible && (
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      )}
      <WizardContainer
        isOpen={isWizardOpen}
        handleClose={() => setIsWizardOpen(false)}
        startStep={WIZARD_SCREENS_NAME.type_select}
        parentId={assetId}
        customerId={assetData?.CustomerId || ""}
      />
      <Body disableDesktopScroll disableFilterPadding>
        <Form>
          <Form header>
            <ActionsBar
              title={
                <FormPageTitle
                  isNew={isNew}
                  isCustomize={isCustomize}
                  entity="Item"
                  name={formApi.data.Name}
                />
              }
              additionalInfo={<CustomerInfo customerId={assetData?.CustomerId || null} />}
            >
              <SaveButton
                saveAndClose={handleSubmit}
                isLoading={props.isLoading}
                revertData={openRevertDialog}
                isSaveAvailable={lockSaveButton}
                isNew={isNew}
                cancelRouteForEmptyHistory={ROUTES.ASSETS}
                isDataChanged={formApi.isDataChanged}
              />
            </ActionsBar>
          </Form>
          <Form body>
            <Form content>
              <Collapse in={!isCustomize} timeout="auto" unmountOnExit>
                <EssentialAsset
                  openDialog={() => setIsIconsPopupVisible(!isIconsPopupVisible)}
                  assetData={formApi.data}
                  handleFieldChange={formApi.handleChange}
                  handleFieldChangeWithValidation={formApi.handleChange}
                  errorFields={formApi.errorFields}
                  updateManyFields={formApi.updateManyFields}
                  isLoading={props.isLoading}
                  isNew={isNew}
                />
              </Collapse>
              <EntityTabsProvider>
                <EntityTabsContainer
                  content={
                    isCustomize
                      ? formTabs.filter((el) => el.hasCustomizeMode === isCustomize)
                      : formTabs
                  }
                  startFrom={isNew ? "1" : "0"}
                  isCustomize={isCustomize}
                  changeCustomize={handleCustomize}
                  isNew={isNew}
                  entityName={"item"}
                  tabIndexAfterCreate="0"
                  renderSelect={isMobile}
                />
              </EntityTabsProvider>
            </Form>
            <Form images>
              <DropZoneContainer
                data={formApi.data}
                handleChange={formApi.handleChange}
                updateManyFields={formApi.updateManyFields}
                entityName="item"
                isMobile={isMobile}
                imagesOnly={false}
                limitImages={limits?.asset?.images?.Value}
                limitDocuments={limits?.asset?.documents?.Value}
                limitVideos={limits?.asset?.videos?.Value}
              />
            </Form>
          </Form>
        </Form>
      </Body>
    </>
  );
};

export default React.memo(AssetForm, (prevProps, nextProps) => {
  if (
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.assetData?.Id === nextProps.assetData?.Id
  ) {
    return true;
  }
  return false;
});
