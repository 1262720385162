import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";
import { useLeaveDialogContext } from "../../containers/_common/useLeaveDialogContext";

import { AppBar, Badge, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { getSelectedAssetType } from "../../redux/reducers/filterReducer";
import { isEditPage, isPageWithItemFiltering } from "../../redux/actions/updateData";
import { ROUTES } from "../../_constant/screens";

import SettingsNav from "../SettingsNav/SettingsNav";
import HeaderNavMobile from "../HeaderNavMobile/HeaderNavMobile";
import FormMenu from "../../containers/_common/header/FormMenu";
import Search from "../Search";
import HeaderAccount from "../../containers/_common/header/HeaderAccount";
import ElevationScroll from "../ElevationScroll/ElevationScroll";
import {
  AboutPagePopoverButtonMobile,
  AboutPagePopoverContent,
} from "../../pages/CalendarFeeds/InfoPopoverAboutPage";
import { Popover } from "../Popover/Popover";

interface LocationState {
  firstLoad?: boolean;
}

const DefaultHeader = () => {
  const navigation = useNavigate();
  const location = useLocation<LocationState>();
  const { IsCustomerManagement } = useGetAccountId();
  const filterValue = useSelector((state) => getSelectedAssetType(state));
  const { headerOptions, setHeaderOptions } = useHeaderOptions();
  const {
    filterOpen,
    withBackButton,
    withSettingsNav,
    pageTitle,
    saveOptions,
    isFormDirty,
  } = headerOptions;

  const { showDialogBeforeChangeUrl } = useLeaveDialogContext();

  const handleGoBack = () => {
    if (location.state?.firstLoad) {
      IsCustomerManagement ? navigation(ROUTES.VISITS) : navigation(ROUTES.ACTIVITIES);
    } else {
      isFormDirty ? showDialogBeforeChangeUrl(() => navigation(-1)) : navigation(-1);
    }
  };

  const defaultMobileIcon = filterOpen ? <MenuOpenIcon /> : <FilterListIcon />;

  return (
    <ElevationScroll>
      <AppBar className="MobileHeader__appBar">
        <div className="MobileHeader">
          <div className="MobileHeader__button">
            <IconButton
              onClick={
                withBackButton ? handleGoBack : () => setHeaderOptions({ filterOpen: !filterOpen })
              }
            >
              <Badge
                variant="dot"
                invisible={!filterValue || !isPageWithItemFiltering(location.pathname)}
              >
                {!withBackButton ? (
                  defaultMobileIcon
                ) : isFormDirty ? (
                  <CloseOutlinedIcon />
                ) : (
                  <ArrowBackIcon />
                )}
              </Badge>
            </IconButton>
          </div>
          <div className="MobileHeader__content">
            {withSettingsNav ? <SettingsNav /> : <HeaderNavMobile pageTitle={pageTitle} />}
          </div>
          <div className="MobileHeader__actions">
            {isEditPage(location.pathname) ? (
              <>
                <IconButton
                  disabled={saveOptions.disabled || saveOptions.isLoading}
                  onClick={saveOptions.saveFunction}
                >
                  {saveOptions.isLoading ? (
                    <CircularProgress size={22} color="inherit" />
                  ) : (
                    <SaveOutlinedIcon color={saveOptions.disabled ? "disabled" : "inherit"} />
                  )}
                </IconButton>
                <FormMenu saveOptions={saveOptions} isFormDirty={isFormDirty} />
              </>
            ) : (
              <>
                {location.pathname === ROUTES.CALENDAR_FEEDS ? (
                  <Popover
                    target={<AboutPagePopoverButtonMobile />}
                    content={(handler) => <AboutPagePopoverContent handleClose={handler} />}
                  />
                ) : (
                  <>
                    <Search placeholder={"Search"} mobile />
                    <HeaderAccount isMobileVersion />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </AppBar>
    </ElevationScroll>
  );
};

export default DefaultHeader;
