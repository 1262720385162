import * as React from "react";

import { useMutation } from "react-apollo";

import useGetUserId from "../../../customHooks/useGetUserId";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import useAccountSwitch from "../../../customHooks/useAccountSwitch";
import usePersonalAccount from "../../../customHooks/usePersonalAccount";
import useSuccessfulRedirect from "../../../customHooks/useSuccessfulRedirect";

import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { ROUTES } from "../../../_constant/screens";
import { ID, REMOVE_USER } from "../../../_constant/wordings";

import { removeUserFromAccountQuery } from "../../../components/AccountUsersItem/removeUserFromAccount.query";
import { deleteAccountCacheFn } from "../delete/accountDeleteUpdateFn";
import { displayErrorNotification } from "../../../redux/actions/_utils";

import { LeaveDialogComponent } from "../../_common/Dialog";

interface ILeaveAccountContainer {
  accountId: string;
  handleLeavePopupClose: () => void;
}

const LeaveAccountContainer: React.FC<ILeaveAccountContainer> = (props) => {
  const { handleLeavePopupClose, accountId } = props;

  const userId = useGetUserId();
  const { accountId: currentAccountId } = useGetAccountId();
  const { switchAccountById } = useAccountSwitch();
  const { personalAccountId, accountsLoading } = usePersonalAccount();
  const { successfulRedirect } = useSuccessfulRedirect();

  const [removeAccountMutation, { loading }] = useMutation(removeUserFromAccountQuery);

  const leaveAccountHandler = () => {
    const variables = { UserId: userId, AccountId: accountId };

    removeAccountMutation({
      variables,
      update(cache) {
        deleteAccountCacheFn(cache, accountId);
      },
    })
      .then(({ data }) => {
        if (data[REMOVE_USER][ID]) {
          if (currentAccountId && currentAccountId === accountId && !accountsLoading) {
            switchAccountById(personalAccountId);
          } else {
            successfulRedirect(ROUTES.ACCOUNTS, NotificationTypes.ACCOUNT_DELETED);
          }
        } else {
          displayErrorNotification(NotificationTypes.ACCOUNT_LEAVED_ERROR);
        }
      })
      .catch((error) => {
        console.log(error);
        displayErrorNotification(NotificationTypes.ACCOUNT_LEAVED_ERROR);
      });
  };

  return (
    <LeaveDialogComponent
      onCancel={handleLeavePopupClose}
      onSubmit={leaveAccountHandler}
      content="You will be removed from account. Access to all items and activities will be denied."
      buttonConfirmLabel="Leave Account"
      isLoading={loading}
    />
  );
};

export default LeaveAccountContainer;
