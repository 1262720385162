import * as React from "react";
import moment from "moment";

import { DatePicker } from "@material-ui/pickers";

import EventIcon from "@material-ui/icons/Event";

import { LabelButton, WizardDatePickerContainer } from "./WizardDatePickerStyles";

interface DatePickerInputProps {
  value: string;
  disabled: boolean;
  format?: string;
  handleChange: (value: any) => void;
}

const WizardDatePicker: React.FC<DatePickerInputProps> = (props) => {
  const { value, handleChange, format = "YYYY/MM/DD", disabled } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <WizardDatePickerContainer>
      <DatePicker
        value={value || null}
        onChange={(inputValue) => handleChange(inputValue)}
        format={format}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disabled={disabled}
      />
      <LabelButton disabled={disabled} onClick={() => setOpen(!open)} endIcon={<EventIcon />}>
        {moment(value).format(format)}
      </LabelButton>
    </WizardDatePickerContainer>
  );
};

export default WizardDatePicker;
