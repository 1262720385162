import * as React from "react";
import Select from "./Select/Select";
import SelectItem from "./SelectItem/SelectItem";
import { connect } from "react-redux";
import { DATE_OFFSET_OPTIONS, FILTERING_DETAILS } from "../_constant/ActivityDateConstant";
import { updateActivityFilter } from "../redux/actions/actions";
import { getActivityFilters } from "../redux/reducers/appReducer";
import { MenuItem } from "@material-ui/core";

interface FilterSelectProps {
  label: string;
  statusId: string;
  statusName: string;
  selectedStatusFilter: (statusId: string, statusName: string) => string;
  setNewDateFilter: (e) => void;
  className?: string;
}

class FilterSelect extends React.PureComponent<FilterSelectProps> {
  renderOptions = () => {
    return DATE_OFFSET_OPTIONS.get(this.props.statusName).map((option) => (
      <MenuItem
        className={"Filter__option"}
        key={option.value}
        value={option.value}
        id={this.props.statusId}
      >
        <SelectItem>{option.label}</SelectItem>
      </MenuItem>
    ));
  };

  render() {
    const { statusId, statusName, label } = this.props;

    return (
      <div
        className={`Filter ${this.props.className ? this.props.className : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <label className={"Filter__label"} htmlFor="">
          {label}
        </label>
        <Select
          value={this.props.selectedStatusFilter(statusId, statusName)}
          onChange={this.props.setNewDateFilter}
          withoutUnderline
          style={{ color: "var(--color-brand)" }}
        >
          {this.renderOptions()}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedStatusFilter: (statusId: string, statusName: string) =>
    getActivityFilters(state).get(statusId) ||
    (FILTERING_DETAILS.get(statusName) && FILTERING_DETAILS.get(statusName)["selected"]),
});

const mapDispatchToProps = (dispatch) => ({
  setNewDateFilter: (e, child) => {
    dispatch(updateActivityFilter({ id: child.props.id, offset: e.target.value }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelect);
