import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";

import { ROUTES } from "../../_constant/screens";

import { getSearchStringValue } from "../../redux/reducers/filterReducer";
import { getTitlePrefix } from "../../_utils/utils";

import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";

import AddIcon from "@material-ui/icons/Add";

import Body from "../../components/Body/Body";
import ActionsBar from "../../components/ActionsBar/ActionsBar";
import AllVendorsWrapper from "../../components/AllVendorsWrapper/AllVendorsWrapper";
import { StyledAddButtonMobile } from "../../containers/activities/all/AllActivities";
import LayoutSwitcherVendorContainer from "../../components/LayoutSwitcher/LayoutSwitcherVendorContainer";

const Vendors = () => {
  const navigate = useNavigate();
  const searchString: string = useSelector(getSearchStringValue, shallowEqual);

  const onAddButtonHandler = () => {
    navigate(ROUTES.CREATE_VENDOR);
  };

  useHeaderOptions({
    pageTitle: "Vendors",
    withAddButton: true,
    addButtonCallback: onAddButtonHandler,
    headerType: "DEFAULT",
  });

  const titlePrefix = getTitlePrefix(!!searchString);

  return (
    <Body disableDesktopScroll disableFilterPadding>
      <Container maxWidth="md">
        <ActionsBar title={`${titlePrefix} Vendors`} withSearch>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <LayoutSwitcherVendorContainer />
            </Grid>
            <Grid item>
              <Hidden smDown>
                <Button
                  component={Link}
                  to={ROUTES.CREATE_VENDOR}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Vendor
                </Button>
              </Hidden>
              <Hidden mdUp>
                <StyledAddButtonMobile onClick={onAddButtonHandler}>
                  <AddIcon />
                </StyledAddButtonMobile>
              </Hidden>
            </Grid>
          </Grid>
        </ActionsBar>
      </Container>
      <AllVendorsWrapper />
    </Body>
  );
};

export default Vendors;
