import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { RemoveScroll } from "react-remove-scroll";
import Input from "../Input/Input";

import { FloatingInputStyles } from "./FloatingInputStyles";

import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

interface FloatingInputProps {
  isOpen: boolean;
  value: string;
  name?: string;
  label: string;
  placeholder: string;
  onChange: (inputName: string, value: string) => void;
  onClose: () => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  maxLength?: number;
}

const FloatingInput: React.FC<FloatingInputProps> = ({
  value,
  onChange,
  isOpen = false,
  onClose,
  name,
  label,
  placeholder,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = ({ target }: { target: { value: any } }) => {
    const { value } = target;

    setInputValue(value);
  };

  const handleButtonClick = () => {
    onChange(name, inputValue);
  };

  return (
    <RemoveScroll enabled={isOpen}>
      <Modal open={isOpen} onClose={() => onClose()}>
        <FloatingInputStyles>
          <Grid container>
            <Grid item xs>
              <Input
                label={label}
                value={inputValue}
                onChange={({ target }) => setInputValue(target.value)}
                placeholder={placeholder}
                fullWidth
                disableUnderline
                disableClearButton
                autoFocus
                {...rest}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={handleButtonClick} color="primary" edge="end">
                <KeyboardReturnIcon />
              </IconButton>
            </Grid>
          </Grid>
        </FloatingInputStyles>
      </Modal>
    </RemoveScroll>
  );
};

export default FloatingInput;
