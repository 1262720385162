import React from "react";
import styled from "styled-components";
import { AVAILABLE_IMAGE_TYPES } from "../../../_constant/constants";
import { mapLocalImageToNormalized } from "../../../_utils/dropzoneHelpers";
import DropZoneSvgCard from "../../DropZoneSvgCard/DropZoneSvgCard";

interface NormalizedFile {
  id: string;
  imgUrl: string;
  url: string | null;
  isLocal: boolean;
  fileType: string;
  title?: string;
  imgOrder?: number;
}

const StyledMainSection = styled.div`
  // min-width: 116px;
  position: relative;
  display: flex;
  padding-right: 12px;

  justify-content: center;
  width: 100%;
  padding-right: 0;

  @media screen and (min-width: 600px) {
    padding: 16px 0;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
  }
`;

const WizardMainFilesSection: React.FC = ({ newItem, removeFile }) => {
  const normalizedImage: NormalizedFile[] = newItem?.Files.filter((file: File) =>
    AVAILABLE_IMAGE_TYPES.includes(file.type),
  ).map((file: File, index: number) => mapLocalImageToNormalized({ file, index }));

  const imagePreview = normalizedImage[0];

  return (
    <StyledMainSection>
      {normalizedImage.length && (
        <DropZoneSvgCard
          large
          bigTitle
          title={imagePreview.title}
          imageUrl={imagePreview.imgUrl}
          id={imagePreview.id}
          remove={removeFile}
          dataOrder={imagePreview.imgOrder}
        />
      )}
    </StyledMainSection>
  );
};

export default WizardMainFilesSection;
