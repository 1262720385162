import React from "react";

import { ROUTES } from "./screens";

import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";

const NAV_LIST_DEFAULT = [
  {
    label: "Items",
    path: ROUTES.ASSETS,
  },
  {
    label: "Activities",
    path: ROUTES.ACTIVITIES,
  },
  {
    label: "Projects",
    path: ROUTES.PROJECTS,
  },
  {
    label: "Vendors",
    path: ROUTES.VENDORS,
  },
];

const NAV_LIST_BUSINESS = [
  {
    label: "Visits",
    path: ROUTES.VISITS,
    icon: <FlagOutlinedIcon />,
  },
  {
    label: "Customers",
    path: ROUTES.CUSTOMERS,
    icon: <PeopleAltOutlinedIcon />,
  },
];

export const NAV_LIST = {
  default: NAV_LIST_DEFAULT,
  business: NAV_LIST_BUSINESS,
};
