import * as React from "react";

import { Box, Collapse, Paper, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar/Avatar";

import { changeDateFormat, composeImageUrl } from "../../_utils/utils";

import ShowDetailsButton from "./DetailsButton";
import ActionButtons from "./ActionButtons";
import { InvitationModel } from "../../redux/models/data/InvitationModel";

interface IInvitationItemLayout {
  invitation: InvitationModel;
  isOpen: boolean;
  isHistory: boolean;
  acceptLoading: boolean;
  declineLoading?: boolean;
  handleDetailClick: (e: React.SyntheticEvent) => void;
  handleAccept: (e: React.SyntheticEvent) => void;
  handleDecline?: (e: React.SyntheticEvent) => void;
}

const InvitationItemLayout: React.FC<IInvitationItemLayout> = (props) => {
  const {
    invitation: { Account, CreateDateTime, Message, Sender, Id },
    handleDetailClick,
    isHistory,
    isOpen,
    handleAccept,
    handleDecline,
    acceptLoading,
    declineLoading,
  } = props;

  const senderPicture =
    Sender &&
    Sender.Images &&
    Sender.Images.length &&
    composeImageUrl(
      Sender.Images[0].Id,
      Sender.Images[0].ContainerName,
      Sender.Images[0].ContainerId,
    );

  return (
    <Paper className="invitation-item" elevation={2} component="article">
      <Box
        className="invitation-item__wrapper"
        component="section"
        display="flex"
        alignItems="center"
        onClick={handleDetailClick}
      >
        <Avatar
          className="AccountsList__photo"
          variant="circle"
          alt="Avatar"
          src={senderPicture || ""}
        />
        <Typography className="invitation-item__name" component="span">
          {`${Sender.FirstName} ${Sender.LastName ? Sender.LastName : ""}`.trim()}
        </Typography>
        <Box
          display={{ xs: "none", md: "flex" }}
          alignItems="center"
          style={{ marginLeft: "1rem" }}
        >
          <Typography className="invitation-item__account-to" component="span">
            To
          </Typography>
          <Typography className="invitation-item__account-name" component="span">
            {" "}
            {Account && Account.Name}
          </Typography>
        </Box>
        <Box display={{ xs: "flex", md: "none" }} style={{ marginLeft: "auto" }}>
          <ShowDetailsButton
            isOpen={isOpen}
            handleDetailClick={handleDetailClick}
            isHistory={isHistory}
          />
        </Box>
        <Box display={{ xs: "none", md: "flex" }} style={{ marginLeft: "auto" }}>
          <ShowDetailsButton
            isOpen={isOpen}
            handleDetailClick={handleDetailClick}
            extraText
            isHistory={isHistory}
          />
          <ActionButtons
            handleAccept={handleAccept}
            handleDecline={handleDecline}
            acceptLoading={acceptLoading}
            declineLoading={declineLoading}
            isHistory={isHistory}
          />
        </Box>
      </Box>
      <Collapse in={isOpen} timeout="auto" unmountOnExit component="section">
        <Box
          className="invitation-item__details"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box
            display={{ xs: "flex", md: "none" }}
            alignItems="center"
            style={{ marginBottom: "9px" }}
          >
            <Typography
              className="invitation-item__account-to invitation-item__account-to--large"
              component="span"
            >
              Account:
            </Typography>
            <Typography
              className="invitation-item__account-name invitation-item__account-name--large"
              component="span"
            >
              {Account && Account.Name}
            </Typography>
          </Box>
          <Typography className="invitation-item__description">{Message}</Typography>
          <Typography className="invitation-item__time" component="time">
            {changeDateFormat(CreateDateTime)}
          </Typography>
          <Box
            className="invitation-item__footer"
            component="footer"
            display={{ xs: "flex", md: "none" }}
            alignSelf="flex-end"
          >
            <ActionButtons
              handleAccept={handleAccept}
              handleDecline={handleDecline}
              acceptLoading={acceptLoading}
              declineLoading={declineLoading}
              isHistory={isHistory}
            />
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default InvitationItemLayout;
