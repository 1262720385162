import React from "react";
import { Route, useNavigate, useLocation } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { ROUTES } from "../_constant/screens";

import SaveConnection from "../containers/SaveConnection";
import MainWrapper from "../components/MainWrapper/MainWrapper";

const ProtectedRoute = ({ component: Component, withoutWrapper = false, ...args }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, user } = useAuth0();

  const { search } = location;

  if (isAuthenticated && user?.email_verified === false) {
    navigate(ROUTES.VERIFY_EMAIL);
  }

  if (search.includes("?error=unauthorized")) {
    let msg = search.split("error_description=")[1]?.split("&")[0];
    while (typeof msg === "string" && msg.length > 0 && !msg.includes(" ")) {
      msg = decodeURI(msg);
    }
    navigate(ROUTES.ERROR, { state: { error: msg } });
  }

  const ComponentWithWrapper: React.FC = withoutWrapper
    ? Component
    : () => (
        <MainWrapper>
          <Component />
        </MainWrapper>
      );
  const Child = withAuthenticationRequired(ComponentWithWrapper, {
    onRedirecting: () => <SaveConnection />,
  });
  return <Route element={<Child />} {...args} />;
};

export default ProtectedRoute;
