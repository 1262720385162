import React from "react";

import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import { AssetModel } from "../../../redux/models/data/AssetModel";
import { LayoutType } from "../../../_constant/constants";

import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import AssetsList from "../../../components/AssetsList/AssetsList";
import ActionsBar from "../../../components/ActionsBar/ActionsBar";
import Body from "../../../components/Body/Body";
import { LoaderCircle } from "../../../components/Loader";
import LayoutSwitcherItemContainer from "../../../components/LayoutSwitcher/LayoutSwitcherItemContainer";
import AddItemContextMenu from "../../../components/AddItemContextMenu/AddItemContextMenu";
import { useLayoutSwitcherContext } from "../../../components/LayoutSwitcher/useLayoutSwitcherContext";
import AssetListPrintableView from "../../../components/AssetsList/AssetListPrintableVIew";

interface AssetsPageProps {
  assetsToShow: AssetRefsModel[];
  detailedAssets: AssetModel[];
  detailedLoading: boolean;
  loading: boolean;
  titlePrefix: string;
  isDataAvailable: boolean;
  isDetailedDataAvailable: boolean;
  handleOpenWizard: () => void;
}

const AllAssets: React.FC<AssetsPageProps> = (props) => {
  const {
    assetsToShow,
    loading,
    isDataAvailable,
    handleOpenWizard,
    titlePrefix,
    detailedAssets,
    detailedLoading,
    isDetailedDataAvailable,
  } = props;

  const {
    state: { items: layout },
  } = useLayoutSwitcherContext();

  const detailedDataExist = !detailedLoading && isDetailedDataAvailable;
  const defaultDataExist = !loading && isDataAvailable;
  const dataExist = layout === LayoutType.PRINTABLE ? detailedDataExist : defaultDataExist;

  const renderList = () => {
    if (!dataExist) return null;

    return layout === LayoutType.PRINTABLE ? (
      <AssetListPrintableView assets={detailedAssets} />
    ) : (
      <AssetsList assets={assetsToShow} />
    );
  };

  return (
    <>
      <Body disableDesktopScroll>
        <Container maxWidth="md">
          <ActionsBar title={`${titlePrefix} Items`} withSearch>
            <Grid item container spacing={2} alignItems="center" justify="space-between">
              <Grid item>
                <LayoutSwitcherItemContainer />
              </Grid>
              <Grid item>
                <AddItemContextMenu handleOpenWizard={handleOpenWizard} />
              </Grid>
            </Grid>
          </ActionsBar>
        </Container>
        {!dataExist && (
          <div className={"LoaderWrapper"}>
            <LoaderCircle size={"xl"} />
          </div>
        )}
        {renderList()}
      </Body>
    </>
  );
};

export default AllAssets;
