import React from "react";

import { Button, useTheme } from "@material-ui/core";

interface WizardActivityFooterProps {}

const WizardActivityFooter: React.FC<WizardActivityFooterProps> = (props) => {
  const { navigate } = props;
  const theme = useTheme();

  return (
    <div>
      <Button onClick={() => navigate("backward")} style={{ color: theme.palette.text.secondary }}>
        Previous
      </Button>
      <Button onClick={() => navigate()} color="primary" variant="contained">
        Create
      </Button>
    </div>
  );
};

export default WizardActivityFooter;
