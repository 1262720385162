import React from "react";
import styled from "styled-components";

const DateRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    align-content: space-between;
  }
`;

interface DateRowLayoutProps {}

const DateRowLayout: React.FC<DateRowLayoutProps> = ({ children }) => {
  return <DateRowContainer>{children}</DateRowContainer>;
};

export default DateRowLayout;
