import * as React from "react";

interface ConfirmAssetDeleteI {
  itemName: string;
  relatedEntityName: string;
}

const ConfirmAssetDelete = (props: ConfirmAssetDeleteI) => {
  const { itemName, relatedEntityName } = props;

  return (
    <>
      Deleting the <span className="item-name">{itemName}</span> item will also permanently delete
      these {relatedEntityName}.
      <div className="DeleteAsset_note">
        After deleting this item, you won't be able to undo this action.
      </div>
    </>
  );
};

export default ConfirmAssetDelete;
