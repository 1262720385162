import styled from "styled-components";
import Typography from "@material-ui/core/Typography/Typography";

interface EmptyCustomFieldsProps {
  url: string;
}

export const EmptyCustomFields = styled.div<EmptyCustomFieldsProps>`
  margin-bottom: 24px;
  width: 185px;
  height: 125px;
  background-image: ${({ url }) => `url(${url})`};
  background-repeat: no-repeat;
  background-position: center;
`;

export const EmptyCusomFieldsContainer = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyCusomFieldsText = styled(Typography)`
  font-size: 0.875rem;
  white-space: pre;
  text-align: center;
  color: ${({ theme }) => `${theme.palette.text.secondary}`};
`;
