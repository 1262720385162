import React from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button/Button";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { Box } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

export const CreateNewEntityContainer: React.FC = ({ children }) => {
  return (
    <Hidden smUp>
      <Box padding="1rem">{children}</Box>
    </Hidden>
  );
};

const CreateNewButton = styled((props) => (
  <Button fullWidth startIcon={<AddOutlinedIcon color="primary" />} {...props} />
))`
  &.MuiButton-root {
    background-color: ${({ theme }) => theme.palette.primary.light};
    height: 2.75rem;
  }

  .MuiButton-label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface CreateNewButtonMobileProps {
  handleCreate: () => void;
  disabled: boolean;
}

const CreateNewButtonMobile: React.FC<CreateNewButtonMobileProps> = ({
  handleCreate,
  disabled,
  children = "Create",
}) => {
  return (
    <CreateNewButton onClick={handleCreate} disabled={disabled}>
      {children}
    </CreateNewButton>
  );
};

export default CreateNewButtonMobile;
