import styled, { css } from "styled-components";
import { AppBar } from "@material-ui/core";
import { StyledHeaderMobileTitle } from "../../containers/_common/header/Header.style";

const commonAppBarStyles = css`
  display: flex;
  height: 56px;
`;

const defaultAppBarStyles = css`
  color: var(--color-white);
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const businessAppBarStyles = css`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

interface WithBusinessFlag {
  $isBusinessStyles: boolean;
}

interface StyledMobileAppBarProps extends WithBusinessFlag {
  $isEditPage?: boolean;
}

export const StyledMobileAppBar = styled(AppBar)<StyledMobileAppBarProps>`
  ${commonAppBarStyles}
  ${({ $isBusinessStyles }) => ($isBusinessStyles ? businessAppBarStyles : defaultAppBarStyles)}
  border-bottom: ${({ $isEditPage }) => ($isEditPage ? "none" : "var(--border-style)")};
  color: ${({ theme, $isEditPage }) => ($isEditPage ? theme.palette.text.primary : theme.palette.primary.main)};
`;

const commonHeaderStyles = css`
  display: flex;
  align-items: center;
  height: 100%;

  .MuiAvatar-root {
    width: 28px;
    height: 28px;
    font-size: 1rem;
  }

  .MuiBadge-dot {
    background-color: var(--color-warning);
  }

  ${StyledHeaderMobileTitle} {
    font-size: 1rem;
  }
`;

const defaultHeaderStyles = css`
  ${commonHeaderStyles}

  .MuiIconButton-root {
    color: var(--color-white);
  }

  .MuiBadge-dot {
    background-color: var(--color-warning);
  }
`;

const businessHeaderStyles = css`
  ${commonHeaderStyles}

  .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .Search {
    background-color: var(--color-white);
  }
`;

export const StyledMobileHeader = styled.div<WithBusinessFlag>`
  ${({ $isBusinessStyles }) => ($isBusinessStyles ? businessHeaderStyles : defaultHeaderStyles)}
`;

export const StyledMobileHeaderButton = styled.div``;

export const StyledMobileHeaderContent = styled.div``;

export const StyledMobileHeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
