import React from "react";
import { useNavigate } from "react-router-dom";
import useGetBusinessStatuses from "../../customHooks/useGetBusinessStatuses";
import { EXPANDED_STATUSES } from "../../_constant/constants";
import CreateNewButtonMobile, {
  CreateNewEntityContainer,
} from "../CreateNewButtonMobile/CreateNewButtonMobile";
import { Box } from "@material-ui/core";
import { ROUTES } from "../../_constant/screens";

interface childrenComponentProps {
  expanded: boolean;
  status: any;
  key: string;
}

interface VisitTabContentProps {
  renderComponent: (props: childrenComponentProps) => any;
  customerId: string;
}

const VisitTabContent: React.FC<VisitTabContentProps> = ({ renderComponent, ...restProps }) => {
  const statuses = useGetBusinessStatuses();

  const navigate = useNavigate();

  const redirectToVisits = () => {
    navigate(ROUTES.CREATE_VISIT, {
      state: { customerId: restProps.customerId },
    });
  };

  return (
    <Box>
      <div className={"RelatedActivities"}>
        <CreateNewEntityContainer>
          <CreateNewButtonMobile handleCreate={redirectToVisits} disabled={!restProps.customerId}>
            Visit
          </CreateNewButtonMobile>
        </CreateNewEntityContainer>
        {statuses.map((status) => {
          const expanded = EXPANDED_STATUSES.includes(status.Name.trim().toLowerCase());

          return renderComponent({ key: status.Id, status, expanded, ...restProps });
        })}
      </div>
    </Box>
  );
};

export default VisitTabContent;
