import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";
import useGetUserId from "../../customHooks/useGetUserId";

import { AccountModel } from "../../redux/models/data/AccountModel";

import { ACCOUNT_POST, ACCOUNT_REFS, ID, USER } from "../../_constant/wordings";
import { ROUTES } from "../../_constant/screens";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";

import { updateCacheWithNewAccount } from "../NewAccount/updateCacheWithNewAccount";
import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import { createNewBusinessAccountQuery } from "./createBusinessAccount.query";
import { getAccountRefsList } from "../Accounts/getAccountList.query";

import { setEditFormIsDirty } from "../../redux/actions/actions";

import AccountForm from "../../components/AccountForm/AccountForm";

const NewBusinessAccount: React.FC = () => {
  const navigate = useNavigate();
  const [accountModel] = useState(new AccountModel());
  const { setHeaderOptions } = useHeaderOptions();

  const currentUserId = useGetUserId();
  const { successfulRedirect } = useSuccessfulRedirect();

  const dispatch = useDispatch();

  const { data: accountRefsData, loading: accountRefsLoading } = useQuery(getAccountRefsList, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (currentUserId && accountRefsData) {
      const foundBusinessAccount: AccountModel = accountRefsData[USER][ACCOUNT_REFS].find(
        (account: AccountModel) => {
          return account.IsCustomerManagement && account.OwnerId === currentUserId;
        },
      );
      if (foundBusinessAccount) {
        navigate(ROUTES.EDIT_BUSINESS);
      }
    }
  }, [accountRefsData, currentUserId]);

  const [createAccount, { data: createData, loading: isCreating }] = useMutation(
    createNewBusinessAccountQuery,
  );

  const onSubmitHandler = (data: AccountModel, saveType: SaveType) => {
    createAccount({
      variables: { ...data, IsCustomerManagement: true },
      update(cache, { data: { AccountPost } }) {
        updateCacheWithNewAccount(cache, AccountPost);
      },
    })
      .then(({ data }: any) => {
        if (data[ACCOUNT_POST][ID] && saveType.type === SAVE) {
          saveType.callback(data[ACCOUNT_POST]);
          dispatch(setEditFormIsDirty(false));
          displaySuccessNotification(NotificationTypes.BUSINESS_ACCOUNT_CREATED);
          const currentAccountPath = `${ROUTES.SINGLE_ACCOUNT}/${data[ACCOUNT_POST][ID]}`;
          setHeaderOptions({ isPromptAvailable: false });
          navigate(currentAccountPath, { replace: true });
          setHeaderOptions({ isPromptAvailable: true });
        } else if (data[ACCOUNT_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(data[ACCOUNT_POST]);
          setHeaderOptions({ isPromptAvailable: false });
          successfulRedirect(ROUTES.ACCOUNTS, NotificationTypes.BUSINESS_ACCOUNT_CREATED);
          setHeaderOptions({ isPromptAvailable: true });
        } else {
          displayErrorNotification(NotificationTypes.BUSINESS_ACCOUNT_CREATE_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.BUSINESS_ACCOUNT_CREATE_ERROR);
      });
  };

  return (
    <AccountForm
      handleSubmit={onSubmitHandler}
      isLoading={isCreating}
      isNew={true}
      data={accountModel}
      setHeaderOptions={setHeaderOptions}
      isBusinessAccount={true}
    />
  );
};

export default NewBusinessAccount;
