import { ViewModel } from "./ViewModel";
import { ClientInfoModel } from "./ClientInfoModel";
import { LayoutType } from "../../../_constant/constants";

export interface ActivityFilterType {
  id: string;
  offset: string;
}

export class AppInfoModel {
  clientInfo: ClientInfoModel;
  activitiesLayout: LayoutType;
  viewProps: ViewModel;
  activityFilters: Map<string, string>;

  constructor(clientInfo, activitiesLayout?, viewProps?, activityFilters?) {
    this.clientInfo = clientInfo;
    this.activitiesLayout = activitiesLayout || LayoutType.BOARD;
    this.viewProps = viewProps || new ViewModel();
    this.activityFilters = activityFilters || new Map();
  }

  // Setters
  withNewClientInfo(clientInfo) {
    return new AppInfoModel(
      this.clientInfo.withUpdatedValues(clientInfo),
      this.activitiesLayout,
      this.viewProps,
      this.activityFilters,
    );
  }

  withUpdatedActivitiesLayout(activitiesLayout: LayoutType) {
    return new AppInfoModel(
      this.clientInfo,
      activitiesLayout,
      this.viewProps,
      this.activityFilters,
    );
  }

  withUpdatedAvailableStatus(isAvailable: boolean): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedAvailableStatus(isAvailable),
      this.activityFilters,
    );
  }

  withUpdatedRedirectionPage(pageForRedirect: string): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedRedirectionPage(pageForRedirect),
      this.activityFilters,
    );
  }

  withUpdatedRedirectionFlag(showOnLeavePopup: boolean): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedRedirectionFlag(showOnLeavePopup),
      this.activityFilters,
    );
  }

  withUpdatedActivityFilter(activityFilter: ActivityFilterType) {
    const activityFilters = new Map(this.activityFilters);
    activityFilters.set(activityFilter.id, activityFilter.offset);

    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps,
      activityFilters,
    );
  }

  withUpdatedDirtyFormFlag(isEditFormDirty: boolean): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedDirtyFormFlag(isEditFormDirty),
      this.activityFilters,
    );
  }

  withUpdatedActivitiesEmptyStatus({ status, add }): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedActivitiesEmptyState(status, add),
      this.activityFilters,
    );
  }

  withUpdatedVisitsEmptyStatus({
    status,
    add,
    reset,
  }: {
    status?: string;
    add?: boolean;
    reset?: boolean;
  }): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedVisitsEmptyState({ status, add, reset }),
      this.activityFilters,
    );
  }

  withUpdatedProjectsEmptyStatus({
    status,
    add,
    reset,
  }: {
    status?: string;
    add?: boolean;
    reset?: boolean;
  }): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedProjectsEmptyState({ status, add, reset }),
      this.activityFilters,
    );
  }

  withUpdatedConnectionState(isConnectionLost: boolean): AppInfoModel {
    return new AppInfoModel(
      this.clientInfo,
      this.activitiesLayout,
      this.viewProps.withUpdatedConnectionState(isConnectionLost),
      this.activityFilters,
    );
  }

  // Getters
  getActivitiesLayout(): LayoutType {
    return this.activitiesLayout;
  }

  getLoadingFlag(): boolean {
    return this.viewProps.loadingStateFlag;
  }

  get pageForRedirect(): string {
    return this.viewProps.pageForRedirect;
  }

  getPopupState(): boolean {
    return this.viewProps.popupStateFlag;
  }

  getEditFormDirtyFlag(): boolean {
    return this.viewProps.isEditFormDirty;
  }

  getClientInfo() {
    return this.clientInfo;
  }

  getActivityFilters(): Map<string, string> {
    return this.activityFilters;
  }

  getIsActivitiesListEmpty(): Set<string> {
    return this.viewProps.activitiesEmptyStatus;
  }

  getIsVisitsListEmpty(): Set<string> {
    return this.viewProps.visitsEmptyStatus;
  }

  getIsProjectListEmpty(): Set<string> {
    return this.viewProps.projectsEmptyStatus;
  }

  getConnectionState(): boolean {
    return this.viewProps.connectionState;
  }
}
