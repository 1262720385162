import gql from "graphql-tag";

export const GET_ACTIVITIES_IDS = gql`
  query checkForActivities($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetActivities(Take: 1) {
        Id
      }
    }
  }
`;
