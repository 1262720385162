import * as React from "react";
import { Button, useTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IActionButtons {
  handleAccept: (e: React.SyntheticEvent) => void;
  handleDecline?: (e: React.SyntheticEvent) => void;
  acceptLoading: boolean;
  declineLoading?: boolean;
  isHistory: boolean;
}

const ActionButtons = (props: IActionButtons) => {
  const { handleAccept, handleDecline, acceptLoading, declineLoading, isHistory } = props;
  const loading = acceptLoading || declineLoading;
  const theme = useTheme();

  if (isHistory) {
    return (
      <Button variant="text" color="primary" onClick={handleAccept} disabled={loading}>
        {acceptLoading ? <CircularProgress size={24} /> : "Undo"}
      </Button>
    );
  }

  return (
    <>
      <Button variant="text" style={{ color: theme.palette.error.main }} onClick={handleDecline} disabled={loading}>
        {declineLoading ? <CircularProgress size={24} /> : "Decline"}
      </Button>
      <Button
        style={{ marginLeft: "20px" }}
        variant="text"
        color="primary"
        onClick={handleAccept}
        disabled={loading}
      >
        {acceptLoading ? <CircularProgress size={24} /> : "Accept"}
      </Button>
    </>
  );
};

export default ActionButtons;
