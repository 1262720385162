import React, { ChangeEvent } from "react";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { useMutation } from "react-apollo";
import { assignUserMutation } from "./assignUser.query";
import { VISIT_PATCH } from "../../_constant/wordings";
import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { AssignUserInsideCard } from "../AssigmentContainer/AssignUserInsideCard";
import AssignUserSelect from "../AssignUserSelect/AssignUserSelect";

interface IAssigmentContainer {
  visitId: string;
  assignedUserId: string;
  purpose: string;
}

const AssignmentVisitContainer: React.FC<IAssigmentContainer> = ({
  visitId,
  assignedUserId,
  purpose,
}) => {
  const { accountId } = useGetAccountId();
  const [assignVisitMutation, { data, loading, error }] = useMutation(assignUserMutation);

  const handleAssignVisit = (e: ChangeEvent<HTMLInputElement> | null, target?: any) => {
    assignVisitMutation({
      variables: {
        AccountId: accountId,
        Id: visitId,
        AssignedUserId: target.value,
        Purpose: purpose,
      },
    })
      .then(({ data }: any) => {
        if (data[VISIT_PATCH] && data[VISIT_PATCH].AssignedUserId === target.value) {
          displaySuccessNotification(NotificationTypes.VISIT_ASSIGN_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.VISIT_ASSIGN_UPDATED_ERROR);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.VISIT_ASSIGN_UPDATED_ERROR);
      });
  };

  return (
    <AssignUserInsideCard>
      <AssignUserSelect
        assignedUserId={assignedUserId}
        handleFieldChange={handleAssignVisit}
        isLoading={loading}
        withEmptyId
      />
    </AssignUserInsideCard>
  );
};

export default AssignmentVisitContainer;
