import React from "react";
import {
  WizardActivityRowActionZone,
  WizardActivityRowContent,
  WizardActivityRowStyled,
} from "./WizardActivityRowStyles";

interface Props {
  actionZoneComponent?: React.ReactElement;
  noBorder?: boolean;
}

const WizardActivityRow: React.FC<Props> = ({ children, actionZoneComponent, noBorder, }) => {
  return (
    <WizardActivityRowStyled noBorder={noBorder}>
      <WizardActivityRowContent>{children}</WizardActivityRowContent>
      <WizardActivityRowActionZone>{actionZoneComponent}</WizardActivityRowActionZone>
    </WizardActivityRowStyled>
  );
};

export default WizardActivityRow;
