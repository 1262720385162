import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { PROJECTS } from "../../../_constant/wordings";
import { GET_PROJECTS_LIST } from "../../../containers/projects/all/projects-list.query";
import { ProjectModel } from "../../../redux/models/data/ProjectModel";

export const updateCacheAfterDeleteProject = (
  cache: DataProxy,
  projectDelete: Pick<ProjectModel, "Id">,
  accountId: string,
  filterString: string,
) => {
  try {
    const projectList: any = cache.readQuery({
      query: GET_PROJECTS_LIST,
      variables: { AccountId: accountId, Filter: filterString },
    });

    const newProjectList = projectList[PROJECTS].filter(
      (project: ProjectModel) => project.Id !== projectDelete.Id,
    );

    cache.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: { AccountId: accountId, Filter: filterString },
      data: { Projects: newProjectList },
    });
  } catch (error) {
    console.error(error);
  }
};
