import React from "react";
import Switch from "@material-ui/core/Switch";
import { FormControlLabelStyle } from "../EntityTabsContainer/EntityTabsContainer.style";

interface CustomizeToggleButtonProps {
  onClick: () => void;
  checked: boolean;
  disabled: boolean;
  label?: string;
}

const CustomizeToggleButton: React.FC<CustomizeToggleButtonProps> = ({
  onClick,
  disabled,
  checked,
  label,
}) => {
  return (
    <FormControlLabelStyle
      control={
        <Switch
          checked={checked}
          onChange={onClick}
          name="customize-toggle"
          color="primary"
          size="small"
          disabled={disabled}
        />
      }
      checked={checked}
      label={label ? label : "Customize"}
    />
  );
};

export default CustomizeToggleButton;
