import useGetBusinessStatuses from "./useGetBusinessStatuses";
import * as statusesConfig from "../../configs/statuses-config.json";

const { IN_PROGRESS } = statusesConfig["statusNameMap"];

const useGetVisitStatus = () => {
  const businessStatuses = useGetBusinessStatuses();

  const checkVisitStarted = (visitStatusId: string) => {
    if (businessStatuses) {
      const foundStatus = businessStatuses.find((status) => status.Id === visitStatusId);

      if (!foundStatus) return false;

      return foundStatus.Name === IN_PROGRESS;
    }
  };

  return { checkVisitStarted };
};

export default useGetVisitStatus;
