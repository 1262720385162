import gql from "graphql-tag";

export const cancelInvitationQuery = gql`
  mutation cancelInvitation($InvitationId: Guid!) {
    CancelInvite(InvitationId: $InvitationId) {
      Id
      CreateDateTime
      Message
      Receiver {
        Id
        Name
        Email
      }
      State
    }
  }
`;
