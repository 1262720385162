import React from "react";
import { useMutation } from "react-apollo";
import { useSelector } from "react-redux";

import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetBusinessStatuses from "../../customHooks/useGetBusinessStatuses";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";

import { deleteVisitMutation } from "./deleteVisitMutation";
import { getActivityFilters } from "../../redux/reducers/appReducer";
import { displayErrorNotification, getFilterString } from "../../redux/actions/_utils";
import { updateCacheAfterDeleteVisit } from "./updateCacheAfterDeleteVisit";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";
import { FILTERING_DETAILS } from "../../_constant/ActivityDateConstant";

import { DeleteDialog } from "../../containers/_common/Dialog";

interface DeleteVisitDialogProps {
  visitId: string | null;
  handleCancel: () => void;
  VisitStatusId: string;
}

const offset = (status) =>
  FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)?.selected;

const getFilterStringForQuery = (status, filterMap, fieldName: undefined | string = undefined) => {
  const filterObj = {
    id: status.Id,
    name: status.Name,
    offset: filterMap.get(status.Id) || offset(status),
  };
  return getFilterString(filterObj, fieldName);
};

const DeleteVisitDialog: React.FC<DeleteVisitDialogProps> = (props) => {
  const { visitId, handleCancel, VisitStatusId } = props;
  const { accountId } = useGetAccountId();
  const { successfulRedirect } = useSuccessfulRedirect();
  const statuses = useGetBusinessStatuses();

  const foundStatus = statuses.find((status) => {
    return status.Id === VisitStatusId;
  });

  const [deleteVisit, { loading: isRemoving }] = useMutation(deleteVisitMutation);

  const filterMap = useSelector(
    (state) => getActivityFilters(state),
    (map1, map2) => {
      if (!map1.has(foundStatus?.Id)) {
        return true;
      }
      if (map1.get(foundStatus?.Id) === map2.get(foundStatus?.Id)) {
        return true;
      }
      return false;
    },
  );
  const filterString =
    foundStatus && getFilterStringForQuery(foundStatus, filterMap, "VisitStatusId");

  const handleDelete = () => {
    if (!accountId) {
      return;
    }
    deleteVisit({
      variables: { AccountId: accountId, Id: visitId },
      update(cache, { data: { VisitDelete } }) {
        updateCacheAfterDeleteVisit(cache, VisitDelete, accountId as string, filterString);
      },
    })
      .then(() => {
        successfulRedirect(ROUTES.VISITS, NotificationTypes.VISIT_DELETED);
      })
      .catch(() => displayErrorNotification(NotificationTypes.VISIT_DELETE_ERROR));
  };

  return (
    <DeleteDialog isDataLoading={isRemoving} cancel={handleCancel} submit={handleDelete}>
      After deleting this visit, you won't be able to undo this action.
    </DeleteDialog>
  );
};

export default DeleteVisitDialog;
