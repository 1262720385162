import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { useGetLimits } from "../../../customHooks/useGetLimits";
import useGetAccountId from "../../../customHooks/useGetAccountId";

import { AssetModel } from "../../../redux/models/data/AssetModel";
import { ACCOUNT, ASSET_REFS } from "../../../_constant/wordings";

import { GET_ASSET_REFS } from "../../../containers/assets/all/assets.query";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import FakeItemCard from "../../FakeItemCard/FakeItemCard";

import {
  WizardErrorIcon,
  WizardLoadingBody,
  WizardLoadingContainer,
  WizardLoadingFakeCardContainer,
} from "../WizardLoadingScreen/WizardLoadingStyles";

interface WizardFinalScreenProps {
  newItem: AssetModel;
  toFirstScreen: () => void;
  withError?: boolean;
  navigate?: (backward?: boolean, step?: number) => void;
}

const WizardFinalScreen: React.FC<WizardFinalScreenProps> = ({
  newItem: { AssetType, Name },
  toFirstScreen,
  withError,
  navigate,
}) => {
  const limits = useGetLimits();
  const { accountId } = useGetAccountId();

  const { data: itemsData, loading: isItemsLoading, error } = useQuery(GET_ASSET_REFS, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
  });

  const itemsLength = itemsData?.[ACCOUNT]?.[ASSET_REFS]?.length ?? 0;
  const itemsLimitExceeded = limits
    ? itemsLength >= limits.account.maxItemsPerAccount.Value
    : false;

  return (
    <WizardLoadingContainer>
      <WizardLoadingFakeCardContainer>
        {withError ? (
          <FakeItemCard
            itemName="Something went wrong"
            typeName="Please try again"
            typeIcon="build"
          />
        ) : (
          <FakeItemCard itemName={Name} typeName={AssetType.Name} typeIcon={AssetType.IconName} />
        )}
      </WizardLoadingFakeCardContainer>
      <WizardLoadingBody>
        <>
          <Box mb={2}>
            <Typography variant="subtitle2">
              {withError && <WizardErrorIcon />}
              {withError
                ? "Some error happened while creating item or activities"
                : "Your item has been created with related activities."}
            </Typography>
            {withError && (
              <Typography variant="body1" color="textSecondary" gutterBottom>
                "Please try again"
              </Typography>
            )}
            {!withError && !itemsLimitExceeded && (
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {"Let's keep going!"}
              </Typography>
            )}
          </Box>
          {!itemsLimitExceeded && (
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="contained"
              color="primary"
              onClick={toFirstScreen}
              disabled={!limits || isItemsLoading}
            >
              Item
            </Button>
          )}
        </>
      </WizardLoadingBody>
    </WizardLoadingContainer>
  );
};

export default WizardFinalScreen;
