import gql from "graphql-tag";

export const GET_CUSTOMER_INFO = gql`
  query getCustomerInfo($AccountId: ID, $CustomerId: ID) {
    Customer(Id: $CustomerId, AccountId: $AccountId) {
      Id
      Name
      Images {
        Id
        IsPrimary
        ContainerId
        ContainerName
      }
    }
  }
`;
