import styled from "styled-components";

import { Paper, Typography } from "@material-ui/core";

import { textClip } from "../../_styled/textClip";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export const CardContainer = styled(Paper)`
  &.MuiPaper-root {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    :not(:last-child) {
      margin-bottom: 1rem;
    }

    @media print {
      break-inside: avoid;
      break-before: auto;
    }
  }
`;

export const StyledAvatar = styled.img`
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
`;

interface AvatarContainerProp {
  $withouBackground?: boolean;
}
export const AvatarContainer = styled.div<AvatarContainerProp>`
  /* width: 200px; */
  height: 172px;
  overflow: hidden;
  background-color: ${({ theme, $withouBackground }) =>
    $withouBackground ? "none" : theme.palette.primary.medium};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContent = styled.div`
  padding: 12px 12px 0 12px;
`;

export const CardHeader = styled.header`
  margin-top: 0.625rem; // 10px
`;

export const CardTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
`;

export const SubTitleContaineer = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
`;

export const TypeIconStyled = styled.i`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SubTitle = styled(Typography)`
  margin-left: 4px;
  /* font-size: 0.625rem; // 10px */
  font-size: 0.625rem; // 10px
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface DescriptionStyledProps {
  $withoutClip: boolean;
}
export const DescriptionStyled = styled(Typography)<DescriptionStyledProps>`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.additionalText};
  margin-top: 0.75rem;
  padding-right: 4px;
  ${({ $withoutClip }) => ($withoutClip ? null : textClip)}
`;

export const CardFooter = styled.footer`
  margin-top: 0.875rem;
  min-height: 56px;
  /* padding: 12px 12px 12px 10px; */
  background-color: ${({ theme }) => theme.palette.primary.light};
`;

export const CardAdditionalInfo = styled.section`
  margin-top: 1rem;
`;

export const DataValues = styled.section`
  border-top: 1px solid ${({ theme }) => theme.palette.greyDesign.separator};
  padding: 0.75rem 0;
`;

export const ItemAdditionInfo = styled.section`
  border-top: 1px solid ${({ theme }) => theme.palette.greyDesign.separator};
  padding-top: 0.75rem;
`;

interface ChevronIconStyledProps {
  $isActive: boolean;
}
export const ChevronIconStyled = styled(KeyboardArrowDownIcon)<ChevronIconStyledProps>`
  transform: ${({ $isActive }) => ($isActive ? "rotate(180deg)" : "rotate(0deg)")};
  transition: 0.3s transform;
`;
