import * as React from "react";
import moment from "moment";
import Select from "../../../../../../../components/Select/Select";
import SelectItem from "../../../../../../../components/SelectItem/SelectItem";
import {getOptionWithValue} from "../../../../../../../_utils/utils";
import {ActivityScheduleModel} from "../../../../../../../redux/models/data/ActivityScheduleModel";
import {FullActivityDueDate} from "../../../../../../../redux/models/data/ActivityModelFull";
import {RepeatAfter, optionsFrequencyUnit, RepeatEvery, RepeatType} from "../../../../../../../_constant/constants";
import {useContext} from "react";
import {DateContext} from "../../DueDateComponent";
import { MenuItem } from "@material-ui/core";

interface RecurringTypeSelectProps {
  onChange: (value: FullActivityDueDate) => void;
  onTypeChange: (value) => void;
}

const RecurringTypeSelect: React.FC<RecurringTypeSelectProps> = (props: RecurringTypeSelectProps) => {
  const {dueDate, schedule} = useContext(DateContext);

  const handleRecurringTypeChange = (value) => {
    const nextSchedule = new ActivityScheduleModel(schedule);
    nextSchedule.RecurringType = value;
    if (schedule.RecurringRepeatType !== RepeatAfter.COMPLETED_DATE) {
      if (value === RepeatType.MONTH) {
        const nextDueDate = dueDate || moment().toISOString();
        props.onTypeChange(RepeatEvery.DAY_IN_MONTH);
        props.onChange({dueDate: nextDueDate, schedule: nextSchedule});
        return;
      }
      props.onTypeChange(RepeatAfter.DUE_DATE);
      // clear nested to "MONTH" value params
      nextSchedule.WeekInMonth = null;
      nextSchedule.DayOfWeek = null;
    }
    props.onChange({schedule: nextSchedule});
  };

  const selectOptions = optionsFrequencyUnit.map((option) => (
    <MenuItem value={option.value} key={option.value}>
      <SelectItem>{option.label}</SelectItem>
    </MenuItem>
  ));

  return (
    <Select
      style={{marginLeft: "16px", width: "100px"}}
      name="RecurringType"
      id="RecurringType"
      value={getOptionWithValue(schedule.RecurringType, "s").value}
      onChange={({ target: {value} }) => handleRecurringTypeChange(value)}
    >
      {selectOptions}
    </Select>
  );
};

export default RecurringTypeSelect;
