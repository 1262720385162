import * as React from "react";
import "./SettingsButton.scss";
import { IconButton } from "@material-ui/core";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";

interface SettingsButtonProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const SettingsButton: React.FC<SettingsButtonProps> = ({ expanded, setExpanded }) => {
  return (
    <div className="SettingsButton">
      <IconButton onClick={() => setExpanded(!expanded)}>
        <MenuOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default SettingsButton;
