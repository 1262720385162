import * as React from "react";

import Button from "../../components/Button";
import { AssetIcons } from "../assets/_components/form/AssetIcons";
import { useCustomRedirectPrompt } from "../../customHooks/useCustomRedirectPrompt";
import { Portal } from "@material-ui/core";
import { DateDialogContainer } from "./Dialog.style";
import { RemoveScroll } from "react-remove-scroll";

interface DialogProps {
  size: "xl" | "l" | "m" | "s";
  header: any;
  content: any;
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
  noGutters?: boolean;
  hasCalendar?: boolean;
  isSubmitDisabled?: boolean;
  hasCancel?: boolean;
  buttonConfirmLabel?: string;
  noShadow?: boolean;
  noBlackout?: boolean;
  buttonCancelLabel?: string;
}

class Dialog extends React.PureComponent<DialogProps> {
  renderHeader = () => {
    const { size, header } = this.props;
    if (size !== "s") {
      return header;
    }
    return (
      <>
        <i className="Icon">error_outline</i>
        {header}
      </>
    );
  };

  render() {
    const { onCancel, onSubmit, buttonConfirmLabel, size, buttonCancelLabel } = this.props;
    const {
      isLoading = false,
      isSubmitDisabled = false,
      noGutters = false,
      hasCalendar = false,
    } = this.props;
    const { noShadow = false, noBlackout = false } = this.props;
    let finalClassName = `Dialog_wrapper ${noGutters ? "Dialog_noGutters" : ""} ${
      hasCalendar ? "hasCalendar" : ""
    }`;
    const turnOffBackground = `${noBlackout ? " Dialog_wrapper__noBlackout" : ""}`;
    finalClassName = finalClassName.concat(turnOffBackground);
    return (
      <RemoveScroll enabled>
        <div className={finalClassName}>
          <div className={`Dialog_container-${size}`}>
            <div className={`Dialog${noShadow ? " Dialog__noShadow" : ""}`}>
              <div className={"Dialog_header"}>{this.renderHeader()}</div>
              <div className={"Dialog_content"}>{this.props.content}</div>
              <div className={"Dialog_actions"}>
                {onCancel && (
                  <Button
                    label={buttonCancelLabel || "Cancel"}
                    onClick={onCancel}
                    disabled={isLoading}
                  />
                )}
                <Button
                  label={buttonConfirmLabel || "Ok"}
                  onClick={onSubmit}
                  isLoading={isLoading}
                  disabled={isSubmitDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </RemoveScroll>
    );
  }
}

export const DeleteDialog = (props) => {
  return (
    <Dialog
      size={"s"}
      header={"Are you sure?"}
      content={props.children}
      onCancel={props.cancel}
      onSubmit={props.submit}
      isLoading={props.isDataLoading}
      buttonConfirmLabel={"Delete"}
    />
  );
};
export const MoveItemDialog = (props) => {
  return (
    <Dialog
      size={"s"}
      header={"Are you sure?"}
      content={props.children}
      onCancel={props.cancel}
      onSubmit={props.submit}
      isLoading={props.isDataLoading}
      buttonConfirmLabel={"Move"}
    />
  );
};
export const AssetConfirmDialog = (props) => {
  return (
    <Dialog
      size={"xl"}
      header={props.header}
      content={props.children}
      onCancel={props.cancel}
      onSubmit={props.submit}
      isLoading={props.isDataLoading}
      buttonConfirmLabel={props.buttonConfirmLabel}
    />
  );
};

export const DateDialog = (props) => {
  return (
    <Portal>
      <DateDialogContainer>
        <Dialog
          size={"xl"}
          header={props.header}
          content={props.children}
          onCancel={props.cancel}
          onSubmit={props.submit}
          noGutters={!props.header}
          isSubmitDisabled={props.submitDisabled}
          hasCalendar
          buttonConfirmLabel={props.okLabel}
        />
      </DateDialogContainer>
    </Portal>
  );
};

export const CalendarDialog = (props) => {
  return (
    <Dialog
      size={"xl"}
      header={props.header}
      content={props.children}
      onCancel={props.cancel}
      onSubmit={props.submit}
      noGutters={!props.header}
      isSubmitDisabled={props.submitDisabled}
      buttonConfirmLabel={"Select"}
      buttonCancelLabel={"Back"}
      hasCalendar
      noBlackout
      noShadow
    />
  );
};

export const AssetIconsDialog = (props) => {
  return (
    <Dialog
      size={"l"}
      header={"Choose Icon"}
      content={
        <AssetIcons
          handleIconPick={(id) => props.onIconUpdate(id)}
          selectedIcon={props.selectedIcon}
        />
      }
      onCancel={props.cancel}
      onSubmit={props.submit}
    />
  );
};

export const FirstVisitDialog = (props) => {
  return (
    <Dialog
      size={"l"}
      header={props.header}
      content={props.children}
      onSubmit={props.submit}
      noGutters={!props.header}
      buttonConfirmLabel={props.buttonLabel}
      isLoading={props.isLoading}
    />
  );
};

export const LeaveDialogComponent: React.FC<{
  onCancel: () => void;
  onSubmit: () => void;
  content?: string;
  buttonConfirmLabel?: string;
  isLoading?: boolean;
}> = ({
  onCancel,
  onSubmit,
  content = "Your data changes won’t be saved",
  buttonConfirmLabel = "Discard",
  isLoading = false,
}) => (
  <Dialog
    size={"s"}
    header={"Are you sure?"}
    content={content}
    onCancel={onCancel}
    onSubmit={onSubmit}
    buttonConfirmLabel={buttonConfirmLabel}
    isLoading={isLoading}
  />
);

interface LeaveDialogComponentProps {
  // isVisible: boolean;
}

export const LeaveDialogViaRedirect: React.FC<LeaveDialogComponentProps> = () => {
  const { showPrompt, confirmNavigation, cancelNavigation } = useCustomRedirectPrompt();

  return showPrompt ? (
    <LeaveDialogComponent onCancel={cancelNavigation} onSubmit={confirmNavigation} />
  ) : null;
};

export const RevertDialogComponent = (props) => {
  return (
    <Dialog
      size={"s"}
      header={"Are you sure?"}
      content={"Your data changes will be lost"}
      onCancel={props.cancel}
      onSubmit={props.submit}
      buttonConfirmLabel={"Revert"}
    />
  );
};

interface ExceededLimitDialogProps {
  message: string;
  submit: () => void;
  cancel: () => void;
}
export const ExceededLimitDialog: React.FC<ExceededLimitDialogProps> = (props) => {
  return (
    <Dialog
      size={"s"}
      header={"Warning"}
      content={props.message}
      onCancel={props.cancel}
      onSubmit={props.submit}
      buttonConfirmLabel="Got it"
      buttonCancelLabel="View limits"
    />
  );
};
