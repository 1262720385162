import { useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { getUserInfoQuery } from "../containers/profile/profile.query";
import { ID, USER } from "../_constant/wordings";

const useGetUserId = () => {
  const [userId, setUserId] = useState<null | string>(null);

  const { data, loading, error } = useQuery(getUserInfoQuery, { fetchPolicy: "cache-first" });

  useEffect(() => {
    if (!loading && !error && data) {
      if (data[USER] && data[USER][ID]) {
        setUserId(data[USER][ID]);
      }
    }
  }, [data]);

  if (data && data[USER] && data[USER][ID]) return data[USER][ID];
  return userId;
};

export default useGetUserId;
