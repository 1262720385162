import React from "react";
import {
  AccountListItemNamePlaceholder,
  AccountListItemTextStyle,
  AccountListItemNameContainer,
  AccountListItemLabel,
} from "./AccountListItemStyle";

interface AccountListItemNameProps {
  accountName: string;
  isSelected: boolean;
  isLoading: boolean | undefined;
  isMobile: boolean;
  isBusiness: boolean;
}

const AccountListItemName: React.FC<AccountListItemNameProps> = (props) => {
  const { accountName, isLoading, isSelected, isMobile, isBusiness } = props;

  if (isLoading) {
    return (
      <AccountListItemNameContainer isSelected={isSelected}>
        <AccountListItemNamePlaceholder animation="wave" />
      </AccountListItemNameContainer>
    );
  }

  const businessLabel = (
    <AccountListItemLabel variant="caption">Customer Management</AccountListItemLabel>
  );

  const renderBusinessLabel = () => {
    if (isMobile) {
      return isBusiness ? businessLabel : null;
    } else {
      return isBusiness && !isSelected && businessLabel;
    }
  };

  return (
    <AccountListItemNameContainer $isSelected={isSelected} $isMobile={isMobile}>
      {!isMobile && isSelected && (
        <AccountListItemLabel variant="caption">Active</AccountListItemLabel>
      )}
      {renderBusinessLabel()}
      <AccountListItemTextStyle title={accountName}>{accountName}</AccountListItemTextStyle>
    </AccountListItemNameContainer>
  );
};

export default AccountListItemName;
