import { GET_ASSET_REFS } from "../../assets/all/assets.query";
import { ACCOUNT, ASSET_REFS, ITEMS, ITEM_ACTIVITIES, ITEM_ID } from "../../../_constant/wordings";
import { ActivityModel } from "../../../redux/models/data/ActivityModelFull";
import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import { GET_ACTIVITIES_LIST } from "../all/activities-list.query";
import { assetNameFragment, assetTypeRefFragmentType } from "../../../graphql/resolvers";
import {
  createQueryOptions,
  createRelatedActivitiesQueryObject,
  getActivitiesFromRelatedActivities,
} from "../../assets/_components/relaitedActivities/RelatedActivitiesCacheUpdate";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { getExistAssets } from "../../../components/PartsTabContainer/assets.query";

export function updateAssetRefQuery(cache: DataProxy, newActivity: ActivityModel, Id: string) {
  try {
    const assetRefs: any = cache.readQuery({ query: GET_ASSET_REFS, variables: { Id } });
    assetRefs[ACCOUNT][ASSET_REFS] = assetRefs[ACCOUNT][ASSET_REFS].map(
      (assetRef: AssetRefsModel) => {
        if (assetRef.Id === newActivity.AssetId) {
          assetRef.HasActivities = true;
        }
        return assetRef;
      },
    );

    cache.writeQuery({
      query: GET_ASSET_REFS,
      variables: { Id },
      data: assetRefs,
    });

    const optionsActivitiesToStatus = createQueryOptions(
      Id,
      newActivity.AssetId,
      newActivity.StatusId,
    );
    const activitiesToStatusQuery: any = cache.readQuery(optionsActivitiesToStatus);
    let activitiesToStatus = getActivitiesFromRelatedActivities(activitiesToStatusQuery);

    const updatedActivitiesToStatus = {
      ...newActivity,
      StatusId: newActivity.StatusId,
    };

    activitiesToStatus = activitiesToStatus.concat([updatedActivitiesToStatus]);

    cache.writeQuery({
      ...optionsActivitiesToStatus,
      data: createRelatedActivitiesQueryObject(activitiesToStatusQuery, activitiesToStatus),
    });
  } catch (e) {}
}
// assetRefFragment

export function updateAllActivitiesCache(
  cache: DataProxy,
  AssetActivityPost: any,
  filterString: string,
) {
  try {
    const activitiesList: any = cache.readQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: AssetActivityPost.AccountId,
        Filter: filterString,
      },
    });

    const assetRef: assetTypeRefFragmentType = cache.readFragment({
      fragment: assetNameFragment,
      id: AssetActivityPost.AssetId,
    });
    AssetActivityPost.Asset = {
      Name: assetRef?.Name,
      Id: AssetActivityPost.AssetId,
      __typename: "Asset",
    };

    AssetActivityPost["isLoading"] = false;
    delete AssetActivityPost["AccountId"];

    activitiesList[ACCOUNT]["AssetActivities"] = activitiesList[ACCOUNT]["AssetActivities"].concat([
      AssetActivityPost,
    ]);

    cache.writeQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: AssetActivityPost.AccountId,
        Filter: filterString,
      },
      data: activitiesList,
    });
  } catch (e) {
    // console.error(e);
  }
}

export const updateAssetActivitiesAfterAddNewActivity = (
  cache: DataProxy,
  AssetActivityPost: any,
  accountId: string,
) => {
  try {
    const cacheData: any = cache.readQuery({
      query: getExistAssets,
      variables: {
        Id: accountId,
      },
    });

    const updatedAssets = cacheData[ACCOUNT][ITEMS].map((asset) => {
      if (asset.Id === AssetActivityPost[ITEM_ID]) {
        return {
          ...asset,
          [ITEM_ACTIVITIES]: [
            ...asset[ITEM_ACTIVITIES],
            { Id: AssetActivityPost.Id, __typename: "AssetActivity" },
          ],
        };
      } else return asset;
    });

    cacheData[ACCOUNT][ITEMS] = updatedAssets;

    cache.writeQuery({
      query: getExistAssets,
      variables: { Id: accountId },
      data: cacheData,
    });
  } catch (error) {}
};
