export const GLOBAL_INFO_UPDATED = "GLOBAL_INFO_UPDATED";

export const UPDATE_CLIENT_INFO = "UPDATE_CLIENT_INFO";

export const UPDATE_ACTIVITIES_LAYOUT = "UPDATE_ACTIVITIES_LAYOUT";

export const UPDATE_REDIRECTION_PAGE = "UPDATE_REDIRECTION_PAGE";
export const SHOW_LEAVING_POPUP = "SHOW_LEAVING_POPUP";
export const UPDATE_IS_DIRTY_PROPERTY = "UPDATE_IS_DIRTY_PROPERTY";

export const UPDATE_ACTIVITY_FILTER = "UPDATE_ACTIVITY_FILTER";

export const SELECT_ASSETS_TO_DISPLAY = "SELECT_ASSETS_TO_DISPLAY";
export const UPDATE_SEARCH_ASSET_QUERY = "UPDATE_SEARCH_ASSET_QUERY";

export const UPDATE_SEARCH_ACTIVITIES_QUERY = "UPDATE_SEARCH_ACTIVITIES_QUERY";

export const UPDATE_SEARCH_ASSET_TAGS_QUERY = "UPDATE_SEARCH_ASSET_TAGS_QUERY";

export const UPDATE_ACTIVITIES_EMPTY_STATUS = "UPDATE_ACTIVITIES_EMPTY_STATUS";

export const UPDATE_VISITS_EMPTY_STATUS = "UPDATE_VISITS_EMPTY_STATUS";

export const UPDATE_PROJECTS_EMPTY_STATUS = "UPDATE_PROJECTS_EMPTY_STATUS";

export const UPDATE_CONNECTION_STATE = "UPDATE_CONNECTION_STATE";

export const SET_ACTIVITIES_FILTER_VALUE = "SET_ACTIVITIES_FILTER_VALUE";

export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_MEMBER_ID = "SET_MEMBER_ID";
