import gql from "graphql-tag";

export const InvitationMutation = gql`
  mutation InviteUser(
    $AccountId: Guid!
    $InvitedUserEmail: String!
    $InvitedUserName: String!
    $Message: String!
  ) {
    InviteUser(
      AccountId: $AccountId
      InvitedUserEmail: $InvitedUserEmail
      InvitedUserName: $InvitedUserName
      Message: $Message
    ) {
      Id
      Account {
        Id
        Name
        Description
      }
      CreateDateTime
      Id
      Message
      Receiver {
        Id
        Email
        FirstName
        LastName
      }
      State
      UpdateDateTime
    }
  }
`;
