import * as React from "react";
import { useDrop } from "react-dnd";

import { ProjectModel } from "../../redux/models/data/ProjectModel";

interface ProjectsDropWrapperProps {
  status: any;
  expandStatus: (flag: boolean) => void;
  loading: boolean;
  updateProjectStatus: (project: any, nextStatusId: string) => void;
}

const ProjectsDropWrapper: React.FC<ProjectsDropWrapperProps> = ({
  status,
  expandStatus,
  loading,
  children,
  updateProjectStatus,
}) => {
  const [{ canDrop, isOver, item }, drop] = useDrop({
    accept: "project",
    drop: (item: { type: string; project: ProjectModel }) => {
      expandStatus(true);
      updateProjectStatus(item.project, status.Id);
    },
    canDrop: (item) => {
      return item.project.ProjectStatusId !== status.Id && !loading;
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      };
    },
  });

  return (
    <div
      ref={drop}
      className={`ActivitiesList__container${
        canDrop && isOver ? " ActivitiesList__container--highlighted" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ProjectsDropWrapper;
