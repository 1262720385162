import gql from "graphql-tag";

export const GET_ACTIVITIES_RELATED_TO_ASSET = gql`
  query getRelatedActivitiesToAsset($Id: ID, $AssetId: ID, $Filter: String) {
    Account(Id: $Id) {
      Id
      Assets(Id: $AssetId) {
        Id
        AssetActivities(Filter: $Filter) {
          Id
          Name
          StatusId
          DueDateTime
          CompletedDateTime
          RelatedDueDateTime
          Schedule {
            RecurringType
          }
          Asset {
            Id
            Name
          }
          VendorRefs {
            Id
          }
        }
      }
    }
  }
`;
