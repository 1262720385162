import styled from "styled-components";

export const DateDialogContainer = styled.div`
  .Dialog {
    min-height: 470px;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      min-height: 485px;
    }
  }
`;
