import * as React from "react";
import { ReactNode, useContext, useEffect } from "react";

import { useDispatch } from "react-redux";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import { ROUTES } from "../../_constant/screens";

import Collapse from "@material-ui/core/Collapse";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";

import Form from "../../components/Form/Form";
import Body from "../../components/Body/Body";

import { ProjectFormContext } from "./ProjectFormContext";
import ProjectFormFieldsLayout from "./ProjectFormFieldsLayout";
import DeleteProjectDialog from "./ProjectDeleteDialog/DeleteProjectDialog";

import EntityTabsContainer from "../EntityTabsContainer/EntityTabsContainer";
import ActivitiesTabProject from "../ActivitiesTabProject/ActivitiesTabProject";
import DeleteButton from "../DeleteButton/DeleteButton";
import CustomizeToggleButton from "../CustomizeToggleButton/CustomizeToggleButton";
import ItemCustomFieldsContainer from "../ItemCustomFields/ItemCustomFieldsContainer";

import Modal from "../../containers/Modal/Modal";
import { RevertDialogComponent } from "../../containers/_common/Dialog";
import RelatedActivities from "../../containers/projects/_components/relaitedActivities/RelatedActivities";

import MobileScrollingContainer from "../MobileScrollingContainer/MobileScrollingContainer";
import StartProjectButton from "../../components/StartProjectButton/StartProjectButton";
import ActionsBar from "../ActionsBar/ActionsBar";
import FormPageTitle from "../FormPageTitle/FormPageTitle";
import SaveButton from "../SaveButton/SaveButton";
import { ButtonsContainer } from "../VisitForm/VisitForm.style";
import FinishProjectDialog from "../FinishProjectDialog/FinishProjectDialog";
import { useHandleQueryProjectStatus } from "./useHandleQueryProjectStatus";
import StartProjectMobile from "../StartProjectMobile/StartProjectMobile";
import useGetProjectsStatuses from "../../customHooks/api/useGetProjectsStatuses";
import * as statusesConfig from "../../../configs/statuses-config.json";

import ItemsTabProject from "../ItemsTabProject/ItemsTabProject";
import { VendorsListForProject } from "../VendorsList/VendorsListForProject";
import styled from "styled-components";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import IconButton from "@material-ui/core/IconButton";

import CircularProgress from "@material-ui/core/CircularProgress";

const WarningCardStyled = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 1rem;
  border-radius: 8px;
  & .Icon {
    color: ${({ theme }) => theme.palette.warning.main};
    font-size: 1.5rem;
    padding-right: 1rem;
  }
  & .MuiButtonBase-root {
    padding: 10px;
    margin-left: auto;
    & .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export interface ITabContent {
  name: string;
  disabled: boolean;
  hasCustomizeMode: boolean;
  content: ReactNode;
  tabControl?: ReactNode | null;
}

const ProjectFormPageLayout: React.FC = () => {
  const {
    isDeletePopupVisible,
    isCustomize,
    handleCustomize,
    isNew,
    formApi,
    dropZone,
    handleSubmit,
    lockSaveButton,
    isLoading,
    setIsDeletePopupVisible,
    setHeaderOptions,
    setIsRevertPopupVisible,
    isRevertPopupVisible,
    setCachePolicyForActivityTab,
    activitiesTabFetchPolicy,
    isProjectStarted,
    isProjectFinished,
    handleStartProject,
    handleProjectFinished,
    isFinishProjectDialogVisible,
    handleFinishProjectDialog,
    isSaving,
  } = useContext(ProjectFormContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const dispatch = useDispatch();
  const handleOpenFinishProjectDialog = () => {
    handleFinishProjectDialog(true);
  };
  const handleCloseFinishProjectDialog = () => {
    handleFinishProjectDialog(false);
  };

  const { changeStatus, finishProject, isUpdating, isFinishing } = useHandleQueryProjectStatus({
    project: formApi.data,
    isFinishProjectDialogVisible,
    handleOpenFinishProjectDialog,
    isProjectStarted,
    updateInitFormData: formApi.updateInitFormData,
    handleCustomize,
    initFormData: formApi.initFormData,
    handleCloseFinishProjectDialog,
    isFormDirty: formApi.isDataChanged,
  });

  const setActivityToProject = (activityId: string): void => {
    formApi.handleChange(null, {
      id: "ActivityIds",
      value: [...formApi.data.ActivityIds, activityId],
    });
  };

  const removeActivityFromProject = (activityId: string): void => {
    let newActivities = formApi.data.ActivityIds.filter((activity) => {
      return activityId !== activity;
    });

    formApi.handleChange(null, {
      id: "ActivityIds",
      value: newActivities,
    });
  };

  const removeActivityFromProjectWithDelete = (activityId: string): void => {
    const checkActivitiesChanged = () => {
      return formApi.initFormData.ActivityIds.includes(activityId);
    };

    let newActivities = formApi.data.ActivityIds.filter((activity) => {
      return activityId !== activity;
    });

    let newInitActivities = formApi.initFormData.ActivityIds.filter((activity) => {
      return activityId !== activity;
    });

    if (checkActivitiesChanged()) {
      formApi.handleChangeWithoutDirtyForm("ActivityIds", newActivities, newInitActivities);
    } else {
      formApi.handleChange(null, {
        id: "ActivityIds",
        value: newActivities,
      });
    }
  };

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
    setCachePolicyForActivityTab("network-only");
    formApi.changedFields.clear();
  };

  const { EMPTY_BUSINESS, FINISH, CANCELLED, IN_PROGRESS } = statusesConfig["statusNameMap"];

  const statuses = useGetProjectsStatuses();

  const projectStatus = statuses?.find(
    (status: { Id: string }) => status.Id === formApi.data.ProjectStatusId,
  );

  const [showWarningMsg, setShowWarningMsg] = React.useState(false);

  useEffect(() => {
    isSaving && formApi.changedFields.clear();
  }, [isSaving]);

  useEffect(() => {
    if (formApi.changedFields.has("ActivityIds")) {
      setShowWarningMsg(true);
    } else {
      setShowWarningMsg(false);
    }
  }, [formApi.changedFields, formApi.data.ActivityIds]);

  const renderWarningCard = () => {
    return (
      <WarningCardStyled>
        <i className="Icon">error_outline</i>
        Activities have been added or removed. Please save the project to refresh this list.
        <IconButton onClick={(event) => handleSubmit(event, true)} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : <SaveOutlinedIcon />}
        </IconButton>
      </WarningCardStyled>
    );
  };

  const projectTabs: ITabContent[] = [
    {
      name: "Activities",
      disabled: isNew,
      content: (
        <RelatedActivities
          hidden={isNew}
          isCustomize={isCustomize}
          projectId={formApi?.data?.Id}
          setActivityToProject={setActivityToProject}
          ActivityIds={formApi?.data?.ActivityIds}
        >
          <ActivitiesTabProject
            projectId={formApi?.data?.Id}
            isLoadingForm={isLoading}
            activitiesTabFetchPolicy={activitiesTabFetchPolicy}
            setCachePolicyForActivityTab={setCachePolicyForActivityTab}
            isCustomize={isCustomize}
            removeActivityFromProject={removeActivityFromProject}
            removeActivityFromProjectWithDelete={removeActivityFromProjectWithDelete}
            isProjectStarted={isProjectStarted}
            isProjectFinished={isProjectFinished}
          />
        </RelatedActivities>
      ),
      hasCustomizeMode: !isProjectFinished,
      tabControl: !isProjectFinished && (
        <CustomizeToggleButton
          onClick={handleCustomize}
          checked={isCustomize}
          disabled={isNew}
          label="Planning"
        />
      ),
    },
    {
      name: "Items",
      disabled: isNew,
      content: (
        <ItemsTabProject
          projectId={formApi.data.Id ?? ""}
          showWarningMsg={showWarningMsg}
          renderWarningCard={renderWarningCard}
        />
      ),
      hasCustomizeMode: false,
    },
    {
      name: "Vendors",
      disabled: isNew,
      content: (
        <VendorsListForProject
          projectId={formApi.data.Id ?? ""}
          showWarningMsg={showWarningMsg}
          renderWarningCard={renderWarningCard}
        />
      ),
      hasCustomizeMode: false,
    },
    {
      name: "Project Info",
      disabled: isNew,
      hasCustomizeMode: true,
      content: (
        <ItemCustomFieldsContainer
          CustomFields={formApi.data.CustomFields}
          Schema={formApi.data.Schema}
          onChange={formApi.handleChange}
          isCustomize={isCustomize}
          entityName="Project"
          isLoading={isLoading}
        />
      ),
      tabControl: !isProjectFinished && (
        <CustomizeToggleButton onClick={handleCustomize} checked={isCustomize} disabled={isNew} />
      ),
    },
    {
      name: "Manage",
      disabled: isNew,
      content: (
        <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
          <DeleteButton
            onClick={() => setIsDeletePopupVisible(true)}
            disabled={isLoading}
            loading={isLoading}
          >
            Delete Project
          </DeleteButton>
        </div>
      ),
      tabControl: null,
      hasCustomizeMode: false,
    },
  ];

  useEffect(() => {
    if (projectStatus) {
      const isFinished = projectStatus.Name === FINISH || projectStatus.Name === CANCELLED;
      const inProgress =
        projectStatus.Name !== EMPTY_BUSINESS &&
        projectStatus.Name !== FINISH &&
        projectStatus.Name !== CANCELLED;

      handleStartProject(inProgress);
      handleProjectFinished(isFinished);
    }
  }, [projectStatus]);

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        revertFunction: handleRevert,
        delete: !isNew ? () => setIsDeletePopupVisible(true) : null,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, isLoading, lockSaveButton]);

  const handleRevert = React.useCallback(() => {
    setIsRevertPopupVisible(true);
  }, []);

  return (
    <Body disableDesktopScroll disableMobileScroll>
      <MobileScrollingContainer isMobile={isMobile}>
        <Modal isShowing={isDeletePopupVisible}>
          <DeleteProjectDialog
            id={formApi.data.Id ?? null}
            handleCancel={() => setIsDeletePopupVisible(false)}
            statusId={formApi.data.ProjectStatusId ?? null}
          />
        </Modal>

        <FinishProjectDialog
          visible={isFinishProjectDialogVisible}
          handleClose={handleCloseFinishProjectDialog}
          projectId={formApi?.data?.Id}
          handleFinish={finishProject}
          isUpdating={isFinishing}
        />

        {isRevertPopupVisible && (
          <RevertDialogComponent
            submit={revertData}
            cancel={() => setIsRevertPopupVisible(false)}
          />
        )}
        <Form>
          <Form header>
            <ActionsBar
              title={
                <FormPageTitle
                  isNew={isNew}
                  isCustomize={false}
                  entity="Project"
                  name={formApi.data.Name ?? ""}
                />
              }
              buttonContainer={ButtonsContainer}
            >
              <Grid item container spacing={2} alignItems="center">
                <Grid item>
                  <StartProjectButton
                    isLoading={isLoading}
                    projectData={formApi.data}
                    isProjectStarted={isProjectStarted}
                    isProjectFinished={isProjectFinished}
                    handleProjectStatus={changeStatus}
                    isUpdating={isUpdating}
                    isNew={isNew}
                  />
                </Grid>
                <Grid item>
                  <SaveButton
                    saveAndClose={handleSubmit}
                    isLoading={isLoading}
                    revertData={() => setIsRevertPopupVisible(true)}
                    isSaveAvailable={lockSaveButton}
                    isNew={isNew}
                    cancelRouteForEmptyHistory={ROUTES.VISITS}
                    isDataChanged={formApi.isDataChanged}
                  />
                </Grid>
              </Grid>
            </ActionsBar>
          </Form>

          <Form body addBottomSpace={isMobile}>
            <Form content>
              <Collapse in={!isCustomize} timeout="auto" unmountOnExit>
                <ProjectFormFieldsLayout
                  errorFields={formApi.errorFields}
                  data={formApi.data}
                  handleChange={formApi.handleChange}
                />
              </Collapse>

              <EntityTabsContainer
                content={
                  isCustomize
                    ? projectTabs.filter((el) => el.hasCustomizeMode === isCustomize)
                    : projectTabs
                }
                entityName="project"
                isNew={isNew}
                startFrom="0"
                isCustomize={isCustomize}
                tabIndexAfterCreate="0"
                renderSelect={isMobile}
                isDesktop={false}
              />
            </Form>

            <Form images>{dropZone}</Form>

            {isMobile && !isNew && (
              <StartProjectMobile
                isProjectStarted={isProjectStarted}
                isProjectFinished={isProjectFinished}
              >
                <StartProjectButton
                  isLoading={isLoading}
                  projectData={formApi.data}
                  isProjectStarted={isProjectStarted}
                  isProjectFinished={isProjectFinished}
                  handleProjectStatus={changeStatus}
                  isUpdating={isUpdating}
                  isNew={isNew}
                />
              </StartProjectMobile>
            )}
          </Form>
        </Form>
      </MobileScrollingContainer>
    </Body>
  );
};

export default ProjectFormPageLayout;
