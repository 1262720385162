import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ROUTES } from "../../_constant/screens";
import { LayoutType } from "../../_constant/constants";

import { addCustomerIdToUrl, isRecurring } from "../../_utils/utils";
import { formatDateWithoutTZ } from "../../_utils/date";

import * as statusesConfig from "../../../configs/statuses-config.json";

import { Box, Grid, Typography } from "@material-ui/core";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { RemoveCircleOutlineOutlined } from "@material-ui/icons";

import { LoaderCircle } from "../../components/Loader";
import AssignmentActivityContainer from "../../components/AssigmentContainer/AssignmentActivityContainer";
import { CardContainer, CardParent } from "../../components/Cards/styled/card.styled";
import MoreAction from "../../components/MoreAction/MoreAction";
import { ActivityItemDate } from "../../containers/activities/_components/item/ActivityItemDate";
import DeleteActivityPopup from "../../containers/activities/_components/form/DeleteActivityPopup";
import { removeActivityFromProjectTabCache } from "../ActivitiesTabProject/removeActivityFromProjectTabCache";
import { useApolloClient } from "react-apollo";

import {
  ControlActivityButton,
  StyledGrid,
  RemoveLabel,
  RepeatItemContainer,
} from "./ProjectTabActivityItem.style";

const { EMPTY, IN_PROGRESS, FILL } = statusesConfig["statusNameMap"];

interface ProjectTabActivityItemProps {
  activity: any; // ActivityModelShort | ActivityModelRelated
  dateType: string;
  layout: LayoutType;
  status?: string;
  disableNavigationByClick?: boolean;
  style?: {};
  isAccountPersonal: boolean;
  isDraggable?: boolean;
  isAssignmentsVisibile: boolean;
  customerId?: string | null;
  finishedView?: boolean;
  withMoreAction?: boolean;
  withDragAndDropIcon?: boolean;
  projectId?: string | null;
  removeActivity: (activityId: string) => void;
  isCustomize: boolean;
  removeActivityFromProjectWithDelete?: (activityId: string) => void;
  isHover?: boolean;
}

const ProjectTabActivityItem: React.FC<ProjectTabActivityItemProps> = (props) => {
  const {
    activity,
    dateType,
    status = "",
    disableNavigationByClick = false,
    style,
    isAccountPersonal,
    isDraggable = false,
    customerId = null,
    finishedView = false,
    withMoreAction,
    withDragAndDropIcon,
    projectId = null,
    removeActivity,
    isCustomize = false,
    removeActivityFromProjectWithDelete,
    isHover = false,
  } = props;
  const { Id, Name, Schedule, RelatedDueDateTime, isLoading, Asset, AssignedUserId } = activity;
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const client = useApolloClient();

  const iconClassName = isRecurring({ schedule: Schedule, relatedDueDate: RelatedDueDateTime })
    ? "ActivityItem_recurring"
    : "";
  const navigate = useNavigate();

  const smUp = useMediaQuery("(min-width: 600px)", { noSsr: true });
  const mdUp = useMediaQuery("(min-width: 960px)", { noSsr: true });

  const openDeleteActivityDialog = useCallback(() => setIsDeletePopupVisible(true), []);
  const closeDeleteActivityDialog = useCallback(() => setIsDeletePopupVisible(false), []);

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: openDeleteActivityDialog,
    },
  ];

  const goToActivity = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let path = `${ROUTES.SINGLE_ACTIVITY}/${activity.Id}`;
    path = customerId ? addCustomerIdToUrl(path, customerId) : path;
    navigate(path);
  };

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    removeActivity(Id);
    removeActivityFromProjectTabCache(client, Id, projectId);
  };

  const renderAssignment = () => {
    return !isDraggable && !isAccountPersonal ? (
      <AssignmentActivityContainer
        assignedUserId={AssignedUserId}
        activityId={activity.Id}
        withoutControl
      />
    ) : null;
  };

  return (
    <>
      <CardContainer
        padding={1}
        style={isDraggable ? style : { borderBottom: "1px solid #eeeeee" }}
        borderRadius={isDraggable ? "8px" : "0"}
        onClick={disableNavigationByClick ? undefined : goToActivity}
        withLabel={status}
        isHover={isHover}
      >
        {isLoading && (
          <div className={`ActivityItem_loader`}>
            <LoaderCircle size={"xs"} />
          </div>
        )}

        <Grid spacing={1} container alignItems="flex-start" wrap="nowrap">
          {withDragAndDropIcon && (
            <Box display="flex" alignItems="center" padding="8px 0">
              <DragIndicatorIcon color="action" fontSize="small" />
            </Box>
          )}
          <StyledGrid item container zeroMinWidth>
            <Grid item container direction="column" justify="space-between">
              <Box padding="4px 0">
                <Typography variant="body2" component="h3">
                  {Name}
                </Typography>
              </Box>
              {Asset?.Name && (
                <Box margin="8px 0">
                  <CardParent alignItems="start">
                    <span>FOR </span>
                    {Asset.Name}
                  </CardParent>
                </Box>
              )}
            </Grid>
          </StyledGrid>
          <StyledGrid
            item
            xs={mdUp ? 4 : false}
            container
            direction="column"
            alignItems="flex-end"
            justify="space-between"
          >
            <Grid item container alignItems="center" justify="flex-end" spacing={1} wrap="nowrap">
              <Grid item>{renderAssignment()}</Grid>
              <Grid item style={{ minWidth: smUp ? 110 : 0 }}>
                <ActivityItemDate
                  activityDate={formatDateWithoutTZ(activity[dateType])}
                  dateType={dateType}
                  iconOnly={!smUp}
                />
              </Grid>
              {!finishedView && (
                <RepeatItemContainer item container>
                  <i className={`Icon ${iconClassName}`}>repeat</i>
                </RepeatItemContainer>
              )}
              {withMoreAction && (
                <Grid item>
                  <MoreAction options={options} />
                </Grid>
              )}
            </Grid>
            {isCustomize && (
              <Grid item>
                <ControlActivityButton color="primary" onClick={handleClick}>
                  <RemoveLabel>Remove</RemoveLabel>
                  <RemoveCircleOutlineOutlined />
                </ControlActivityButton>
              </Grid>
            )}
          </StyledGrid>
        </Grid>
      </CardContainer>
      {isDeletePopupVisible && (
        <DeleteActivityPopup
          activity={activity}
          isDeletePopupVisible={isDeletePopupVisible}
          handleCancel={closeDeleteActivityDialog}
          customerId={customerId}
          projectId={projectId}
          withoutRedirect
          removeActivityFromProjectWithDelete={removeActivityFromProjectWithDelete}
        />
      )}
    </>
  );
};

export default ProjectTabActivityItem;
