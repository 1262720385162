import { Image } from "./ImageModel";
import { ActivityScheduleModel } from "./ActivityScheduleModel";
import { formatDateWithoutTZ, getDateWithUpdatedTime } from "../../../_utils/date";

export class VisitModel {
  Id: string | null;
  CustomerId: string | null;
  Purpose: string;
  Notes: string;
  AssignedUserId: string | null;
  VisitStatusId: string;
  DueDateTime: string | null;
  CompletedDateTime: string | null;
  Schedule: ActivityScheduleModel;
  RelatedDueDateTime: string[];
  Images: Image[];
  Documents: any[];
  Videos: any[];
  Files: any;
  VisitStatusName: string;
  CustomerName: string;

  constructor(
    {
      Id = null,
      CustomerId = "",
      Purpose = "",
      Notes = "",
      AssignedUserId = null as string | null,
      VisitStatusId = "",
      DueDateTime = "" as string | null,
      CompletedDateTime = "" as string | null,
      Schedule = new ActivityScheduleModel(),
      RelatedDueDateTime = [] as string[],
      Images = [] as Image[],
      Documents = [] as any[],
      Videos = [] as any[],
      Files = [],
      VisitStatusName = "",
      CustomerName = "",
    } = {} as VisitModel,
  ) {
    this.Id = Id;
    this.CustomerId = CustomerId ?? "";
    this.Purpose = Purpose ?? "";
    this.Notes = Notes ?? "";
    this.AssignedUserId = AssignedUserId ?? null;
    this.VisitStatusId = VisitStatusId ?? null;
    this.DueDateTime = DueDateTime ? formatDateWithoutTZ(DueDateTime) : null;
    this.CompletedDateTime = CompletedDateTime ? getDateWithUpdatedTime(CompletedDateTime) : null;
    this.Schedule = Schedule ? new ActivityScheduleModel(Schedule) : new ActivityScheduleModel();
    this.RelatedDueDateTime = RelatedDueDateTime ?? ([] as string[]);
    this.Images = Images ?? [];
    this.Documents = Documents ?? [];
    this.Videos = Videos ?? [];
    this.Files = Files ?? [];
    this.VisitStatusName = VisitStatusName ?? "";
    this.CustomerName = CustomerName || "";
  }
}
