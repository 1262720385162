import * as React from "react";

import { useState } from "react";
import { useMutation } from "react-apollo";

import { InvitationModel } from "../../redux/models/data/InvitationModel";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import {
  addInvitationToInvitationList,
  removeInvitationFromHistoryList,
} from "../../pages/Accounts/updateCacheInvitation";

import { reactivateInvitationMutation } from "./reactivateMutation.query";

import { ACTIVATE_INVITE, ID } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import InvitationItemLayout from "./InvitationItemLayout";

import "./InvitationItem.scss";

interface IInvitationItem {
  invitation: InvitationModel;
}

const InvitationHistoryItem: React.FC<IInvitationItem> = (props) => {
  const {
    invitation: { Id: InvitationId },
  } = props;
  const [isOpen, setOpen] = useState(false);

  const [reactivateMutation, { loading: reactivateLoading }] = useMutation(
    reactivateInvitationMutation,
  );

  const handleDetailClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(!isOpen);
  };

  const handleReactiveInvitation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    reactivateMutation({
      variables: { InvitationId },
      update(cache, { data: { ActivateInvite } }) {
        // update history list, remove reactive invite
        removeInvitationFromHistoryList(cache, InvitationId);
        // update invitation list, add reactive invite
        addInvitationToInvitationList(cache, ActivateInvite);
      },
    })
      .then(({ data }: any) => {
        if (data[ACTIVATE_INVITE][ID]) {
          displaySuccessNotification(NotificationTypes.INVITATION_REACTIVATED);
        } else {
          displayErrorNotification(NotificationTypes.INVITATION_REACTIVATE_ERROR);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.INVITATION_REACTIVATE_ERROR);
      });
  };

  return (
    <InvitationItemLayout
      invitation={props.invitation}
      isOpen={isOpen}
      acceptLoading={reactivateLoading}
      handleDetailClick={handleDetailClick}
      handleAccept={handleReactiveInvitation}
      isHistory
    />
  );
};

export default InvitationHistoryItem;
