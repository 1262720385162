import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { ProjectModel } from "../../redux/models/data/ProjectModel";
import { getActivityFilters } from "../../redux/reducers/appReducer";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetProjectsStatuses from "../../customHooks/api/useGetProjectsStatuses";

import ProjectFormContainer from "../../components/ProjectForm/ProjectFormContainer";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";

import {
  checkUploadResult,
  convertStatusToFilterVariable,
  displayErrorNotification,
  displaySuccessNotification,
  successfulRedirect,
} from "../../redux/actions/_utils";

import { updateProjectMutation } from "./updateProject.query";
import { getProjectById } from "./getProjectById";
import { updateCacheWithEditProject } from "./updateCacheWithEditProject";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { ROUTES } from "../../_constant/screens";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { PROJECT_PUT, ID, FILE_UPLOAD_RESULTS, PROJECT } from "../../_constant/wordings";
import { useGetUserSubscriptionTier } from "../../customHooks/useGetUserSubscriptionTier";
import { formatDateForSubmit, formatDateInCustomFieldsForSubmit } from "../../_utils/date";

const EditProject: React.FC = () => {
  const [project, setProject] = useState(new ProjectModel());

  const { accountId } = useGetAccountId();

  const match = useMatch(`${ROUTES.SINGLE_PROJECT}/:id`);
  const userSubscriptionTier = useGetUserSubscriptionTier();

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statuses = useGetProjectsStatuses();
  const projectFiltersMap = useSelector((state) => getActivityFilters(state));

  const { setHeaderOptions } = useHeaderOptions({
    pageTitle: "Edit Project",
    withBackButton: true,
    headerType: "DEFAULT",
  });

  useEffect(() => {
    if (
      !userSubscriptionTier.isProfessional &&
      !userSubscriptionTier.isPremium &&
      !userSubscriptionTier.loading
    ) {
      navigate(ROUTES.ACTIVITIES);
    }
  }, [userSubscriptionTier]);

  const { data, loading: isFetching, error } = useQuery(getProjectById, {
    variables: { ProjectId: params?.id },
    skip: !match,
    fetchPolicy: "network-only",
    onError: () => {
      navigate(ROUTES.PROJECTS);
      displayErrorNotification(NotificationTypes.PROJECT_ACCESSS_ERROR);
    },
  });

  useEffect(() => {
    if (!isFetching && data && !error) {
      setProject(new ProjectModel(data[PROJECT]));
    }
  }, [data, isFetching, error]);

  const [updateProject, { loading: isUpdating }] = useMutation(updateProjectMutation);

  const updateHandler = (data: ProjectModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }
    const { Files, Activities } = data;

    const imagesWithAppropriateTypename = data.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = data.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = data.Videos.map((video) => {
      delete video.__typename;
      return video;
    });

    const schemaWithAppropriateTypename = data.Schema.map((schema) => {
      delete schema.__typename;
      return schema;
    });

    const formatDueDateTime = formatDateForSubmit(data?.DueDateTime as string);
    const dataForPost: any = { ...data, AccountId: accountId, DueDateTime: formatDueDateTime };
    formatDateInCustomFieldsForSubmit(data?.CustomFields);

    delete dataForPost.Files;

    const variables = {
      Project: {
        ...dataForPost,
        Images: imagesWithAppropriateTypename,
        Documents: documentsWithAppropriateTypename,
        Videos: videosWithAppropriateTypename,
        Schema: schemaWithAppropriateTypename,
      },
      Files,
    };

    const currentStatus = statuses.find(
      (element: { Id: any }) => element.Id === variables.Project.ProjectStatusId,
    );

    const oldStatus = statuses.find(
      (element: { Id: string | null | undefined }) => element.Id === project.ProjectStatusId,
    );

    const filterStringForNewProject = convertStatusToFilterVariable(
      currentStatus,
      projectFiltersMap,
      "ProjectStatusId",
    );

    const filterStringForOldProject = convertStatusToFilterVariable(
      oldStatus,
      projectFiltersMap,
      "ProjectStatusId",
    );

    updateProject({
      variables: variables,
      update(cache, { data: { ProjectPut } }) {
        updateCacheWithEditProject(
          cache,
          ProjectPut,
          filterStringForNewProject,
          filterStringForOldProject,
        );
      },
    })
      .then(({ data }: any) => {
        const uploadResult = data[PROJECT_PUT]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[PROJECT_PUT][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new ProjectModel(data[PROJECT_PUT]));
          displaySuccessNotification(NotificationTypes.PROJECT_UPDATED);
        } else if (data[PROJECT_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new ProjectModel(data[PROJECT_PUT]));
          successfulRedirect(ROUTES.PROJECTS, NotificationTypes.PROJECT_UPDATED);
          navigate(ROUTES.PROJECTS);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_UPDATED_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error("", message);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_UPDATED_ERROR);
        }
      });
  };

  return (
    <ProjectFormContainer
      handleSubmit={updateHandler}
      isSaving={isUpdating}
      isFetching={!data || isFetching}
      isNew={false}
      data={project}
      setHeaderOptions={setHeaderOptions}
    />
  );
};

export default EditProject;
