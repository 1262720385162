import React from "react";

import { LayoutType } from "../../_constant/constants";

import {
  NoActivitiesHeader,
  NoActivitiesImageContainer,
  NoActivitiesContainer,
  NoActivitiesPaperStyle,
  NoActivitiesTitle,
  NoActivitiesDescription,
} from "./NoActivities.style";

import { NoProjectsImage } from "./NoProjects.style";

interface NoProjectsProps {
  layout: LayoutType;
  description: string;
  url: string;
}

const NoProjects: React.FC<NoProjectsProps> = (props) => {
  const { layout, description, url } = props;

  return (
    <NoActivitiesPaperStyle $layout={layout}>
      <NoActivitiesContainer $layout={layout}>
        <NoActivitiesHeader>
          <NoActivitiesTitle>No projects?</NoActivitiesTitle>
          <NoActivitiesDescription>{description}</NoActivitiesDescription>
        </NoActivitiesHeader>
        <NoActivitiesImageContainer $layout={layout}>
          <NoProjectsImage $url={url} />
        </NoActivitiesImageContainer>
      </NoActivitiesContainer>
    </NoActivitiesPaperStyle>
  );
};

export default NoProjects;
