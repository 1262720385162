import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import {
  ButtonContainer,
  StyledDialogActions,
  StyledDialogContentText,
  StyledDialogTitle,
  StyledButton,
  StyledDialog,
  StyledDialogContent,
} from "./MobileDialogSuccessLink.style";

export interface CalendarLinkDialogProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  contentText: string;
}

const MobileDialogSuccessLink: React.FC<CalendarLinkDialogProps> = ({
  isOpen,
  url,
  onClose,
  title,
  contentText,
}) => {
  return (
    <StyledDialog onClose={onClose} open={isOpen}>
      <StyledDialogTitle>
        <InfoOutlinedIcon color="action" fontSize="small" /> {title}
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>{contentText}</StyledDialogContentText>
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <ButtonContainer>
          <StyledButton onClick={onClose} color="default">
            Cancel
          </StyledButton>
        </ButtonContainer>
        <ButtonContainer>
          <StyledButton onClick={onClose} href={url} download color="primary" autoFocus>
            Download
          </StyledButton>
        </ButtonContainer>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default MobileDialogSuccessLink;
