import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import React from "react";
import Select from "../Select/Select";

export const NotificationWrapper = styled(({ ...props }) => <Paper {...props} />)`
  width: 100%;
  padding: 10px 19px;
  margin-bottom: 8px;
`;

export const NotificationContainer = styled(({ ...props }) => <Box {...props} />)`
  display: flex;
  align-items: center;
`;

export const NotificationAction = styled(({ ...props }) => <Box {...props} />)`
  flex: 0 0 30px;
  margin-left: 5px;
  align-self: flex-start;

  @media screen and (min-width: 768px) {
    margin-left: 20px;
    align-self: center;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

export const NotificationItem = styled.div`
  width: calc(50% - 10px);
  max-width: 50%;

  &:nth-child(odd) {
    margin-right: 10px;
  }

  @media screen and (min-width: 768px) {
    // width: auto;
    width: calc(100% / 4 - 35px);
    max-width: 25%;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }
`;
export const StyledSelect = styled(({ ...props }) => <Select {...props} />)`
  &.MuiPaper-root {
    max-height: 300px;
  }
  
  .MuiTypography-root {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
      font-size: 12px;
    }
  }
`;
