import React, { useState } from "react";

import { useDropZoneContext } from "../DropZoneContainer/useDropZoneContext";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  StyledHintButton,
  StyledHintSectionContainer,
  StyledHintTitle,
  StyledTooltip,
  StyledTooltipButton,
  StyledTooltipDescription,
  StyledTooltipTitle,
} from "./DropZone.style";

interface DropZoneHintSectionProps {
  getRootProps: any;
}

const DropZoneHintSection: React.FC<DropZoneHintSectionProps> = ({ getRootProps }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const { amountOfMainFiles } = useDropZoneContext();

  const { entityName, isMobile } = useDropZoneContext();

  const closeTooltip = () => {
    setIsTooltipVisible(false);
  };

  const toggleTooltip = () => {
    setIsTooltipVisible((prevState) => !prevState);
  };

  const getHintMessage = () => {
    switch (amountOfMainFiles) {
      case 1:
        return `The first image uploaded will be shown on the ${entityName} card.`;
      case 3:
        return `The first 3 files uploaded will be shown on the ${entityName} card.`;
      default:
        return `The first image uploaded will be shown on the ${entityName} card.`;
    }
  };

  return (
    <StyledHintSectionContainer>
      <StyledHintTitle {...getRootProps()}>Upload Files</StyledHintTitle>
      {amountOfMainFiles > 0 && (
        <ClickAwayListener onClickAway={closeTooltip}>
          <div>
            <StyledTooltip
              open={isTooltipVisible}
              title={
                <>
                  <StyledTooltipTitle>
                    Click {isMobile ? "" : "or drag and drop"} to upload
                  </StyledTooltipTitle>
                  <StyledTooltipDescription>{getHintMessage()}</StyledTooltipDescription>
                  <StyledTooltipButton onClick={closeTooltip} underline="none">
                    Got it
                  </StyledTooltipButton>
                </>
              }
              interactive
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <StyledHintButton onClick={toggleTooltip}>
                <InfoOutlinedIcon />
              </StyledHintButton>
            </StyledTooltip>
          </div>
        </ClickAwayListener>
      )}
    </StyledHintSectionContainer>
  );
};

export default DropZoneHintSection;
