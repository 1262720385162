import styled from "styled-components";
import { Autocomplete } from "@material-ui/lab";
import { Button, Chip, Grid, Paper } from "@material-ui/core";

import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";

import { scrollStyles } from "../_styled/scrollStyles";

export const StyledAutocompleteContainer = styled(Grid)`
  margin-top: 4px;
  display: flex;
  align-items: end;
  .MuiInput-formControl {
    margin-top: 20px;
  }
`;

export const StyledLabelOutlinedIcon = styled(LabelOutlinedIcon)`
  margin: 0 12px 8px 0;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  .MuiButtonBase-root {
    width: 12px;
    height: 12px;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`;

export const StyledChip = styled(Chip)`
  height: 26px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  .MuiChip-deleteIcon {
    margin-right: 12px;
    width: 8px;
    height: 8px;
    fill: #253858;
  }
`;

export const StyledPaper = styled(Paper)`
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  max-height: 136px;
  overflow-y: auto;
  ${scrollStyles}

  .MuiAutocomplete-listbox {
    padding: 8px 8px 4px 4px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .MuiAutocomplete-option {
    min-height: auto;
    padding: 0;
    margin: 0 0 6px 4px;
    border-radius: 16px;
  }
  .MuiChip-root {
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 0;
  display: flex;
  justify-content: start;
  font-weight: 400;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const PaperContainer = styled.div`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const NoOptionsHint = styled.div`
  padding: 6px 8px;
  font-size: 14px;
`;
