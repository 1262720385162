import React from "react";
import InputRow from "../InputRow/InputRow";
import { Grid } from "@material-ui/core";
import Input from "../Input/Input";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { PHONE_INPUT_MASK } from "../../_utils/regex";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import { CustomerModel } from "../../redux/models/data/CustomersModel";
import MaskedInput from "react-text-mask";
import { dataApiInterface } from "../../customHooks/useChange";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";

interface CustomerFormLayoutProps {
  data: CustomerModel;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  handleChangeWithValidation: any;
  handleBlur: any;
  phoneError: any;
  emailError: any;
  errorFields: Map<string, string>;
}

const CustomerFormLayout: React.FC<CustomerFormLayoutProps> = ({
  data,
  handleChange,
  emailError,
  phoneError,
  handleBlur,
  handleChangeWithValidation,
  errorFields,
}) => {
  const nameInput = React.useMemo(
    () => (
      <Input
        type="text"
        onChange={handleChange}
        value={data.Name}
        label={"Name"}
        id={"Name"}
        fullWidth
        icon={<FaceOutlinedIcon />}
        required
        helperText="* Required field"
        errorText={errorFields.get("Name")}
        error={!!errorFields.get("Name")}
      />
    ),
    [data.Name],
  );

  const addressAutocomplete = React.useMemo(
    () => (
      <AddressAutocomplete
        address={data.Address}
        handleChange={handleChange}
        icon={<LocationOnOutlinedIcon />}
      />
    ),
    [data.Address],
  );

  const phoneWithMask = React.useMemo(
    () => (
      <MaskedInput
        keepCharPositions
        mask={PHONE_INPUT_MASK}
        id={"Phone"}
        defaultValue={data.Phone || ""}
        onChange={handleChangeWithValidation}
        label={"Phone"}
        onBlur={handleBlur}
        autoComplete={"off"}
        error={!!phoneError}
        helperText={phoneError}
        fullWidth
        icon={<PhoneOutlinedIcon />}
        type="tel"
        render={(ref, { defaultValue, ...renderProps }) => {
          return <Input value={defaultValue} {...renderProps} inputRef={ref} />;
        }}
      />
    ),
    [data.Phone, phoneError],
  );

  const emailInput = React.useMemo(
    () => (
      <Input
        type="email"
        onChange={handleChangeWithValidation}
        onBlur={handleBlur}
        value={data.Email}
        error={!!emailError}
        helperText={emailError}
        label={"Email"}
        id={"Email"}
        fullWidth
        icon={<MailOutlineOutlinedIcon />}
      />
    ),
    [data.Email, emailError],
  );
  return (
    <div className="Section">
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {nameInput}
          </Grid>
          <Grid item xs={12} sm={6}>
            {addressAutocomplete}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {phoneWithMask}
          </Grid>
          <Grid item xs={12} sm={6}>
            {emailInput}
          </Grid>
        </Grid>
      </InputRow>
    </div>
  );
};

export default CustomerFormLayout;
