import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import { WeekButtonStyle } from "./DayOfWeek.style";

interface DayOfWeekProps {
  text: string;
  isSelected: boolean;
  handleDateChange: () => void;
}

const DayOfWeek: React.FC<DayOfWeekProps> = (props) => {
  const { text, isSelected, handleDateChange } = props;

  return (
    // <div>
    <WeekButtonStyle isSelected={isSelected} variant="text" onClick={handleDateChange}>
      {text}
    </WeekButtonStyle>
    // </div>
  );
};

export default DayOfWeek;
