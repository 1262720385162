import * as React from "react";
import { Box } from "@material-ui/core";
import { useEntityTabsContext } from "./useEntityTabsContext";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useEffect, useRef } from "react";
import { TabStyle } from "./EntityTabsContainer.style";
import TabForNewEntity from "./TabForNewEntity";
import { EntityTabsContainerStyle } from "./EntityTabsBusinessContainer.style";
import { useNavigate, useLocation } from "react-router-dom";
import { LocationState } from "./EntityTabsContainer";

interface ITabContent {
  name: string;
  disabled: boolean;
  content: React.ReactNode;
}

interface EntityTabsBusinessContainerProps {
  content: ITabContent[];
  startFrom?: "0" | "1" | "2" | "3";
  isNew?: boolean;
  entityName: string;
  tabIndexAfterCreate: "0" | "1" | "2" | "3";
}

const NEW_ENTITY_PAGE = "NEW_ENTITY_PAGE";

const EntityTabsBusinessContainer: React.FC<EntityTabsBusinessContainerProps> = ({
  content,
  startFrom,
  isNew,
  entityName,
  tabIndexAfterCreate = "0",
}) => {
  const { currentTab, changeTab } = useEntityTabsContext();

  const location = useLocation<LocationState>();

  const prevTabNameRef = useRef(content[currentTab] ? content[currentTab].name : content[0].name);

  useEffect(() => {
    if (startFrom && content[startFrom]) {
      changeTab(startFrom);
    }
  }, [startFrom]);

  useEffect(() => {
    prevTabNameRef.current = content[currentTab]?.name;
  }, [currentTab]);

  useEffect(() => {
    if (location.state && location.state.justCreated) {
      changeTab(tabIndexAfterCreate);
    }
  }, [location.state]);

  const handleChange = (event: any, newValue: any) => {
    changeTab(newValue);
  };
  const handleChangeMemoized = React.useCallback(handleChange, []);

  return (
    <EntityTabsContainerStyle>
      <TabContext value={isNew && currentTab !== "0" ? NEW_ENTITY_PAGE : currentTab}>
        {/* <Box display="flex" flexWrap="nowrap" justifyContent="space-between" alignItems="center"> */}
        <TabList
          onChange={handleChangeMemoized}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          {content.map((tab, i) => {
            return (
              <TabStyle
                key={tab.name}
                label={tab.name}
                value={tab.name === NEW_ENTITY_PAGE ? NEW_ENTITY_PAGE : i.toString()}
                disabled={tab.disabled}
                hidden={tab.name === NEW_ENTITY_PAGE}
              />
            );
          })}
        </TabList>
        {/* </Box> */}
        {content.map((tab, i) => (
          <TabPanel key={tab.name} value={i.toString()}>
            {tab.content}
          </TabPanel>
        ))}
        {isNew && (
          <TabPanel key={NEW_ENTITY_PAGE} value={NEW_ENTITY_PAGE}>
            <TabForNewEntity entityName={entityName} />
          </TabPanel>
        )}
      </TabContext>
    </EntityTabsContainerStyle>
  );
};

export default EntityTabsBusinessContainer;
