import gql from "graphql-tag";

export const getAccountById = gql`
  query getAccountQuery($Id: ID) {
    Account(Id: $Id) {
      Id
      Name
      Description
      OwnerId
      IsCustomerManagement
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
      }
      MaxMembers(Id: $Id) {
        Value
        IsLimited
      }
    }
  }
`;
