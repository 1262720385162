import React from "react";
import styled, { css } from "styled-components";
import { DATE_STATUS } from "./ActivityItemDate";
import { COLOR_CODE_STATE } from "../../../../_constant/constants";
import { Theme } from "@material-ui/core";

const getDateStyles = (theme: Theme, status: DATE_STATUS) => {
  switch (status) {
    case COLOR_CODE_STATE.far:
      return css`
        color: ${theme.palette.primary.main};
      `;
    case COLOR_CODE_STATE.overdue:
      return css`
        color: ${theme.palette.error.main};
      `;
    case COLOR_CODE_STATE.close:
      return css`
        color: ${theme.palette.warning.main};
      `;
    case COLOR_CODE_STATE.completed:
      return css`
        color: ${theme.palette.success.main};
        text-decoration: line-through;
      `;
    case COLOR_CODE_STATE.cancelled:
      return css`
        color: ${theme.palette.error.main};
        text-decoration: line-through;
      `;
    default:
      return css`
        color: var(--color-dark);
      `;
  }
};

interface StyledActivityItemDateProps {
  theme: Theme;
  status: DATE_STATUS;
  iconOnly: boolean;
}
export const StyledActivityItemDate = styled.div<StyledActivityItemDateProps>`
  ${({ theme, status }) => getDateStyles(theme, status)};
  display: flex;
  align-items: center;

  & .MuiSvgIcon-root {
    margin-right: ${({ iconOnly }) => (iconOnly ? "0" : "5px")};
    font-size: 16px;
  }
`;
