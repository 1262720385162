import { useMutation } from "react-apollo";
import { createNewActivityQuery } from "../containers/activities/existing/activity.query";
import {
  updateAllActivitiesCache,
  updateAssetActivitiesAfterAddNewActivity,
  updateAssetRefQuery,
} from "../containers/activities/new/updateCacheWithNewActivity";
import useGetAccountId from "./useGetAccountId";
import { ActivityModel } from "../redux/models/data/ActivityModelFull";
import { ExecutionResult } from "graphql";
import useGetAcitvitiesStatuses from "./api/useGetAcitvitiesStatuses";
import { getActivityFilters } from "../redux/reducers/appReducer";
import { convertStatusToFilterVariable } from "../redux/actions/_utils";
import { useSelector } from "react-redux";
import { updateActivitiesAmountCache } from "../components/ItemsTab/updateActivitiesCache";
import { updateCustomerActivitiesCacheWithNewActivity } from "../containers/assets/_components/relaitedActivities/new/updateActivitiesCacheWithNewActivity";

const useCreateActivityMutation = (): [
  (data: ActivityModel, customerId?: string | null) => Promise<ExecutionResult<any>> | undefined,
  { isActivityCreating: boolean },
] => {
  const [createActivityMutation, { loading: isActivityCreating }] = useMutation(
    createNewActivityQuery,
  );
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();

  const activityFiltersMap = useSelector((state) => getActivityFilters(state));

  const createActivity = (dataForCreate: ActivityModel, customerId: string | null = null) => {
    if (!statuses) {
      return;
    }
    const variables = {
      ...dataForCreate,
      AccountId: accountId,
      AssetId: dataForCreate.AssetId,
      StatusId: dataForCreate.StatusId,
    };
    const currentStatus = statuses.find((element) => element.Id === dataForCreate.StatusId);

    const filterString = convertStatusToFilterVariable(currentStatus, activityFiltersMap);

    return createActivityMutation({
      variables,
      update(cache, { data: { AssetActivityPost } }) {
        if (IsCustomerManagement && customerId) {
          updateActivitiesAmountCache(cache, AssetActivityPost);
          updateCustomerActivitiesCacheWithNewActivity(cache, AssetActivityPost, customerId);
          return;
        }
        updateAllActivitiesCache(cache, AssetActivityPost, filterString);
        updateAssetRefQuery(cache, AssetActivityPost, accountId);
        updateAssetActivitiesAfterAddNewActivity(cache, AssetActivityPost, accountId);
      },
    });
  };

  return [createActivity, { isActivityCreating }];
};

export default useCreateActivityMutation;
