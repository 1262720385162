import { GET_PROJECTS_LIST } from "./projects-list.query";
import { PROJECTS } from "../../../_constant/wordings";

export const AllProjectsCacheUpdate = (
  cache,
  project,
  projectResponse,
  statusObjectFrom,
  statusObjectTo,
  accountId,
  withLoader = true,
) => {
  try {
    const projectsFromStatus = cache.readQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: accountId,
        Filter: statusObjectFrom,
      },
    });

    const projectsToStatus = cache.readQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: accountId,
        Filter: statusObjectTo,
      },
    });

    let projectWithOldStatus = {};

    projectsFromStatus[PROJECTS] = projectsFromStatus[PROJECTS].filter((projectFromArray) => {
      if (projectFromArray.Id === project.Id) {
        projectWithOldStatus = projectFromArray;
      } else {
        return projectFromArray.Id !== project.Id;
      }
    });

    const newProject = {
      ...projectWithOldStatus,
      ...project,
      ...projectResponse,
      isLoading: withLoader,
    };

    projectsToStatus[PROJECTS] = projectsToStatus[PROJECTS].concat([newProject]);

    cache.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: accountId,
        Filter: statusObjectFrom,
      },
      data: projectsFromStatus,
    });

    cache.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: accountId,
        Filter: statusObjectTo,
      },
      data: projectsToStatus,
    });
  } catch (e) {}
};

export const turnOffLoadingInProjectCache = (client, project, statusObjectTo, accountId) => {
  try {
    const projectsWithToStatus = client.readQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: accountId,
        Filter: statusObjectTo,
        isLoading: project.isLoading,
      },
    });

    projectsWithToStatus[PROJECTS] = projectsWithToStatus[PROJECTS].map((element) => {
      if (element.Id === project.Id) {
        element.isLoading = false;
      }
      return element;
    });

    client.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: accountId,
        Filter: statusObjectTo,
      },
      data: projectsWithToStatus,
    });
  } catch (error) {}
};
