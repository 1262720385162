import * as React from "react";
import moment from "moment";
import config from "../../../configs/statuses-config.json";
import { DATE_FORMAT } from "../../_utils/date";
import Input from "../Input/Input";

import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import TimerOffOutlinedIcon from "@material-ui/icons/TimerOffOutlined";
import EventIcon from "@material-ui/icons/Event";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";
import { DateValueContainer } from "./DateValue.style";
import { COLOR_CODE_STATE } from "../../_constant/constants";

interface DateValueProps {
  value: string;
  label: string;
  className?: string;
  onClick: () => void;
  isCompleted?: boolean;
  isCancelled?: boolean;
  disabled?: boolean;
}

const generateColorCodeClass = (value: string | null, isCompleted = false, isCancelled = false) => {
  if (isCancelled) {
    return COLOR_CODE_STATE.cancelled;
  }

  if (isCompleted) {
    return COLOR_CODE_STATE.completed;
  }

  const DAYS_TO_MARK_AS_COMING_SOON = config.daysToMarkAsComingSoon;
  const today = moment();
  const dateFromValue = moment(value, [DATE_FORMAT, moment.ISO_8601], true);
  if (dateFromValue.isBefore(today, "day")) {
    return COLOR_CODE_STATE.overdue;
  }

  if (
    dateFromValue.isBetween(
      today,
      moment(today).add(DAYS_TO_MARK_AS_COMING_SOON, "days"),
      "day",
      "[)",
    )
  ) {
    return COLOR_CODE_STATE.close;
  }

  return COLOR_CODE_STATE.far;
};

const iconsCodeMap = {
  [COLOR_CODE_STATE.completed]: EventAvailableOutlinedIcon,
  [COLOR_CODE_STATE.overdue]: TimerOffOutlinedIcon,
  [COLOR_CODE_STATE.close]: TimerOutlinedIcon,
  [COLOR_CODE_STATE.far]: EventIcon,
  [COLOR_CODE_STATE.cancelled]: EventBusyOutlinedIcon,
};

const getIcon = (value: string | null, isCompleted = false, isCancelled = false) => {
  const Icon = iconsCodeMap[generateColorCodeClass(value, isCompleted, isCancelled)];

  return <Icon />;
};

export default function DateValue(props: DateValueProps) {
  const ref = React.useRef<HTMLInputElement>();
  const disabled = !!props.disabled;

  const handleClick = (e: React.SyntheticEvent) => {
    if (disabled) {
      return;
    }
    ref?.current?.blur();

    props.onClick();
  };

  return (
    <DateValueContainer
      $dateState={generateColorCodeClass(props.value, props.isCompleted, props.isCancelled)}
    >
      <Input
        value={props.value}
        label={props.label}
        onClick={handleClick}
        icon={getIcon(props.value, props.isCompleted, props.isCancelled)}
        disableClearButton
        inputRef={ref}
        disabled={disabled}
      />
    </DateValueContainer>
  );
}
