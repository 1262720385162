import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { setSearchString, setSearchTagsArray } from "../../redux/actions/actions";
import { getSelectedAssetsTags } from "../../redux/reducers/filterReducer";

import SearchTagsAutocomplete from "./SearchTagsAutocomplete";

import { useQuery } from "react-apollo";
import { getAssetsTags } from "../TagsAutocomplete/getAssetsTagsQuery";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { SearchForm } from "./SearchTagAutocomplete.style";

interface SearchTagsAutocompleteItemsProps {
  placeholder: string;
  searchValue: string;
  limitTags?: number;
  expanded?: boolean;
}

const SearchTagsAutocompleteItems: React.FC<SearchTagsAutocompleteItemsProps> = ({
  placeholder,
  searchValue,
  limitTags,
  expanded = true,
}) => {
  const dispatch = useDispatch();
  const checkedTags = useSelector((state) => getSelectedAssetsTags(state));

  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(getAssetsTags, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "network-only",
  });

  const allTags = data?.Account?.AssetsTags ?? [];

  const clearInput = () => {
    dispatch(setSearchString(""));
    dispatch(setSearchTagsArray([]));
  };

  const setTags = (newValue: string[]) => {
    dispatch(setSearchTagsArray(newValue));
  };

  return (
    <SearchForm $expanded={expanded}>
      <SearchTagsAutocomplete
        clearInput={clearInput}
        allTags={allTags}
        allTagsLoaded={loading}
        searchValue={searchValue}
        placeholder={placeholder}
        checkedTags={checkedTags}
        setTags={setTags}
        onInputChange={(e, value) => {
          dispatch(setSearchString(value));
        }}
        limitTags={limitTags}
      />
    </SearchForm>
  );
};

export default SearchTagsAutocompleteItems;
