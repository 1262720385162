import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Collapse } from "@material-ui/core";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import { useWindowSize } from "../../_utils/useWindowSize.hook";
import useGetAccountId from "../../customHooks/useGetAccountId";

import { getActivityFilters } from "../../redux/reducers/appReducer";
import {
  getSearchStringValue,
  getSelectedCustomerId,
  getSelectedMemberId,
} from "../../redux/reducers/filterReducer";

import { VisitModel } from "../../redux/models/data/VisitModel";
import { SORTING_DETAILS } from "../../_constant/ActivityDateConstant";
import { ACCOUNT, VISITS } from "../../_constant/wordings";
import { LayoutType } from "../../_constant/constants";
import { sortActivities, transformName } from "../../_utils/utils";
import { filterVisits } from "../../_utils/filtering";
import { convertStatusToFilterVariable } from "../../redux/actions/_utils";

import { getVisitsByStatusQuery } from "./visitsByStatus.query";

import ActivitiesByStatusHeader from "../../containers/activities/all/content/ActivitiesByStatusHeader";
import ActivityCap from "../activity-cap";
import VisitCard from "../VisitCard/VisitCard";
import { updateVisitEmptyStatus } from "../../redux/actions/actions";

interface VisitsByStatusesProps {
  layout: LayoutType;
  status: any;
  searchString: string;
}

const VisitsByStatuses: React.FC<VisitsByStatusesProps> = (props) => {
  const { status, layout } = props;
  const [isExpanded, setExpanded] = useState(true);
  const [filteredVisits, setFilteredVisits] = useState<VisitModel[]>([] as VisitModel[]);
  const [fetchPolicy, setFetchPolicy] = useState<"cache-first" | "network-only">("cache-first");

  const searchString = useSelector(getSearchStringValue, shallowEqual);
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const selectedMemberId = useSelector(getSelectedMemberId);

  const filterMap = useSelector(
    (state) => getActivityFilters(state),
    (map1, map2) => {
      if (!map1.has(status?.Id)) {
        return true;
      }
      if (map1.get(status?.Id) === map2.get(status?.Id)) {
        return true;
      }
      return false;
    },
  );
  const requestFilterString = convertStatusToFilterVariable(status, filterMap, "VisitStatusId");

  const staticData = useStaticDataQuery();
  const emptyImgUrl = staticData ? staticData.Empty[transformName(props.status?.Name || "")] : "";
  const { accountId, isPersonal } = useGetAccountId();
  const [browserWindowWidth] = useWindowSize();
  const dispatch = useDispatch();

  const [getActivitiesList, activitiesData] = useLazyQuery(getVisitsByStatusQuery, {
    fetchPolicy,
    errorPolicy: "all",
  });

  useEffect(() => {
    getActivitiesList({
      variables: {
        Id: accountId,
        Filter: requestFilterString,
      },
    });
  }, []);

  useEffect(() => {
    if (activitiesData.data && activitiesData.data[ACCOUNT][VISITS]) {
      setFetchPolicy("network-only");
    }
  }, [requestFilterString]);

  useEffect(() => {
    getActivitiesList({
      variables: {
        Id: accountId,
        Filter: requestFilterString,
      },
    });
  }, [fetchPolicy]);

  useEffect(() => {
    if (activitiesData.data && !activitiesData.loading && !activitiesData.error) {
      const visitData = activitiesData.data?.Account?.Visits || [];
      let filteredVisitsArr: VisitModel[] = filterVisits(visitData, searchString);

      if (selectedCustomerId) {
        filteredVisitsArr = filteredVisitsArr.filter((visit) => {
          return visit.CustomerId === selectedCustomerId;
        });
      }
      if (selectedMemberId) {
        filteredVisitsArr = filteredVisitsArr.filter((visit) => {
          return visit.AssignedUserId === selectedMemberId;
        });
      }
      const actionObject = { status: status.Name, add: !filteredVisitsArr.length };
      dispatch(updateVisitEmptyStatus(actionObject));

      setFilteredVisits(sortActivities(status?.Name, filteredVisitsArr));
      setFetchPolicy("cache-first");
    }
  }, [activitiesData.data, searchString, selectedCustomerId, selectedMemberId]);

  const statusStyleName = status.Name.toLowerCase().split(" ").join("-");

  return (
    <>
      <ActivitiesByStatusHeader
        status={status}
        layout={layout}
        onClick={() => setExpanded(!isExpanded)}
        isExpanded={isExpanded}
      />
      <div
        className={`ActivitiesList__statusColumnContent ${
          isExpanded ? "ActivitiesList__statusColumnContent--withPadding" : ""
        }`}
      >
        <Collapse in={isExpanded || layout === LayoutType.BOARD} timeout="auto" unmountOnExit>
          <div
            className={`ActivitiesList__wrapper${
              layout === LayoutType.LIST ? " ActivitiesList__wrapper--listView" : ""
            }`}
          >
            {activitiesData.loading && (
              <>
                <ActivityCap listView={layout === LayoutType.LIST} />
              </>
            )}
            {!activitiesData.loading &&
              filteredVisits.length > 0 &&
              filteredVisits.map((visit: VisitModel) => {
                return (
                  <VisitCard
                    key={visit.Id}
                    visit={visit}
                    dateType={SORTING_DETAILS.get(status.Name)["field"]}
                    isAccountPersonal={false}
                    isAssignmentsVisible={true}
                    status={status.Name}
                  />
                );
              })}
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default VisitsByStatuses;
