import React, { ReactNode } from "react";
import {
  EmptyCusomFieldsContainer,
  EmptyCustomFields,
  EmptyCusomFieldsText,
} from "./EmptyCustomFieldStyle";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

interface EmptyCustomFieldStateProps {
  emptyFieldsMessage: string;
  emptyFieldsImageName?: string;
}

const EmptyCustomFieldState: React.FC<EmptyCustomFieldStateProps> = ({
  emptyFieldsMessage,
  emptyFieldsImageName = "custom_fields",
}) => {
  const staticData = useStaticDataQuery();
  const emptyImgUrl: string = staticData ? staticData.Empty[emptyFieldsImageName] : "";

  return (
    <EmptyCusomFieldsContainer>
      <EmptyCustomFields url={emptyImgUrl} />
      <EmptyCusomFieldsText>{emptyFieldsMessage}</EmptyCusomFieldsText>
    </EmptyCusomFieldsContainer>
  );
};

export default EmptyCustomFieldState;
