import React from "react";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { NotificationTypes, SUCCESS_NOTIFICATIONS } from "../_constant/NotificationTypes";

const useSuccessfulRedirect = () => {
  const navigate = useNavigate();

  const successfulRedirect = (path: string, notification: NotificationTypes) => {
    navigate(path);
    toastr.success(SUCCESS_NOTIFICATIONS.get(notification));
  };

  return { successfulRedirect };
};

export default useSuccessfulRedirect;
