import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { GET_PROJECTS_LIST } from "../../containers/projects/all/projects-list.query";

import { ProjectModel } from "../../redux/models/data/ProjectModel";

export const updateCacheWithNewProject = (
  cache: DataProxy,
  ProjectPost: ProjectModel,
  filterString: string,
) => {
  try {
    const projectsList: any = cache.readQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: ProjectPost.AccountId,
        Filter: filterString,
      },
    });

    const { Activities, Videos, Documents, FileUploadResults, ...project } = { ...ProjectPost };

    const newProjectList = { Projects: [...projectsList.Projects, project] };

    cache.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: ProjectPost.AccountId,
        Filter: filterString,
      },
      data: newProjectList,
    });
  } catch (e) {
    // console.error(e);
  }
};
