import { useMutation } from "@apollo/react-hooks";
import { ExecutionResult } from "graphql";
import { toastr } from "react-redux-toastr";

import useGetUserId from "../../customHooks/useGetUserId";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import { mapAssignToServer } from "../../_utils/utils";

import { createCalendarFeedConfig, updateCalendarFeedConfig } from "./calendarFeedMutations.query";
import { getCalendarConfigsQuery } from "../../pages/CalendarFeeds/getCalendarConfigs.query";

interface createConfigParameters {
  accountId: string;
  assignValue: string;
}

export const useCreateCalendarFeedMutation = (): [
  (params: createConfigParameters) => void,
  { createLoading: boolean },
] => {
  const userId = useGetUserId();

  const [createConfigMutation, { loading: createLoading }] = useMutation(createCalendarFeedConfig);

  const createConfig = ({ accountId, assignValue }: createConfigParameters) => {
    return createConfigMutation({
      variables: {
        AccountId: accountId,
        UserId: userId,
        AssignType: mapAssignToServer(assignValue, userId),
      },
      update(cache, { data: { CalendarConfigurationPost } }: any) {
        try {
          const configLists: any = cache.readQuery({
            query: getCalendarConfigsQuery,
            variables: { userId },
          });

          let list = configLists?.CalendarConfigurations ?? [];
          list = list.concat(CalendarConfigurationPost);

          cache.writeQuery({
            query: getCalendarConfigsQuery,
            variables: { userId },
            data: { CalendarConfigurations: list },
          });
        } catch (error) {}
      },
    })
      .then(({ data }) => {
        if (data?.CalendarConfigurationPost?.Id) {
          displaySuccessNotification(NotificationTypes.FEED_CONFIG_CREATED);
          window.navigator.clipboard.writeText(data.CalendarConfigurationPost.AbsoluteUri);
          toastr.success("URL copied", "");
        }
      })
      .catch((err) => {
        displayErrorNotification(NotificationTypes.FEED_CONFIG_CREATED_ERROR);
      });
  };
  return [createConfig, { createLoading }];
};

interface updateConfigParameters {
  accountId: string;
  assignValue: string;
  configId: string;
}
export const useUpdateCalendarFeedMutation = (): [
  (params: updateConfigParameters) => void,
  { updateLoading: boolean },
] => {
  const userId = useGetUserId();

  const [updateConfigMutation, { loading: updateLoading }] = useMutation(updateCalendarFeedConfig);

  const updateConfig = ({ accountId, assignValue, configId }: updateConfigParameters) => {
    return updateConfigMutation({
      variables: {
        AccountId: accountId,
        UserId: userId,
        AssignType: mapAssignToServer(assignValue, userId),
        Id: configId,
      },
    })
      .then(({ data }) => {
        if (data?.CalendarConfigurationPut?.Id) {
          displaySuccessNotification(NotificationTypes.FEED_CONFIG_UPDATED);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.FEED_CONFIG_UPDATED_ERROR);
      });
  };
  return [updateConfig, { updateLoading }];
};
