import React from "react";
import { useNavigate } from "react-router-dom";
import { conformToMask } from "react-text-mask";
import { useMediaQuery } from "@material-ui/core";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import { CustomerModel } from "../../redux/models/data/CustomersModel";
import { VendorModel } from "../../redux/models/data/VendorModel";

import { PHONE_CARD_MASK } from "../../_utils/regex";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

import TableCardAvatar from "./TableCardAvatar";
import CardBackdrop from "../CardBackdrop/CardBackdrop";
import MoreAction, { MoreActionOptions } from "../MoreAction/MoreAction";

import { TableCardStyled, StyledCardText } from "./Table.styles";

interface TableCardProps {
  data: Partial<CustomerModel> | Partial<VendorModel>;
  navigatePath: string;
  moreActionOptions?: MoreActionOptions[];
  isLoading?: boolean;
}

const TableCard: React.FC<TableCardProps> = (props) => {
  const { data, navigatePath, moreActionOptions, isLoading } = props;
  const xsUp = useMediaQuery("(min-width:600px)", { noSsr: true });
  const navigate = useNavigate();
  const staticData = useStaticDataQuery();

  const vendorData = data instanceof VendorModel ? (data as VendorModel) : null;

  const handleOnClick = () => {
    navigate(`${navigatePath}/${data.Id}`);
  };

  const image = data?.Images?.length ? data.Images[0] : null;
  const vendorDefaultIcon = vendorData ? staticData?.Icons?.Desktop?.pic_vendor : null;

  return (
    <TableCardStyled item xs={12} onClick={handleOnClick}>
      <Grid container spacing={2} alignItems="center" style={{ margin: 0, width: "100%" }}>
        <Grid item xs={xsUp ? 4 : 11}>
          <Box display="flex" alignItems="center">
            <TableCardAvatar icon={vendorDefaultIcon} image={image} />
            <StyledCardText component="span" field="name">
              {data.Name}
            </StyledCardText>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid container item xs={4} direction="column">
            <StyledCardText slice="true" component="span" field="address">
              {data.Address}
            </StyledCardText>
            {vendorData?.Website && (
              <StyledCardText slice="true" component="span" field="address">
                {vendorData.Website}
              </StyledCardText>
            )}
          </Grid>
          <Grid container item xs={3} direction="column">
            <StyledCardText component="span" field="phone">
              {data.Phone && conformToMask(data.Phone, PHONE_CARD_MASK).conformedValue}
            </StyledCardText>
            <StyledCardText slice="true" component="span" field="email">
              {data.Email}
            </StyledCardText>
          </Grid>
        </Hidden>
        {moreActionOptions && (
          <Grid container item xs={1} justify="flex-end">
            <MoreAction options={moreActionOptions} />
          </Grid>
        )}
      </Grid>
      {isLoading && <CardBackdrop open={isLoading} borderRadius={6} />}
    </TableCardStyled>
  );
};

export default TableCard;
