import React from "react";
import styled from "styled-components";

import { FieldTypes, FIELD_TYPES_OBJECT } from "../ItemCustomFields/ItemCustomFieldsTypeOptions";

import { getCustomFieldIcon } from "../ItemCustomFields/CustomFIeldIconMapByType";
import { formatDate } from "../../_utils/date";

import { Typography } from "@material-ui/core";

import IconWrapper from "./IconWrapper";

const TypeFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 6px;
`;

const TypeFieldValue = styled.span`
  font-size: 14px;
`;

const TypeFieldName = styled(Typography)`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.text.additionalText};
`;

interface TypeFieldRowProps {
  fieldType?: FieldTypes;
  value: string;
  title: string;

  innerPadding?: number;
  outerPadding?: number;
}

const TypeFieldRow: React.FC<TypeFieldRowProps> = ({
  fieldType = FIELD_TYPES_OBJECT.STRING,
  value,
  title,
  innerPadding,
  outerPadding,
}) => {
  return (
    <IconWrapper
      icon={getCustomFieldIcon(fieldType)}
      innerPadding={innerPadding}
      outerPadding={outerPadding}
    >
      <TypeFieldContainer>
        <TypeFieldName>{title}</TypeFieldName>
        <TypeFieldValue>
          {fieldType === FIELD_TYPES_OBJECT.DATE ? formatDate(value) : value}
        </TypeFieldValue>
      </TypeFieldContainer>
    </IconWrapper>
  );
};

export default TypeFieldRow;
