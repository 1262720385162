import gql from "graphql-tag";

export const getVim = gql`
  query getVim($VIN: String) {
    GetVehicleInfoByVIN(VIN: $VIN) {
      Model
      Make
      Trim
      Year
    }
  }
`;
