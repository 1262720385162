import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";

import { CustomerModel } from "../../redux/models/data/CustomersModel";

import { createCustomerMutation } from "./createCustomer.query";
import { updateCacheWithNewCustomer } from "./updateCacheWithNewCustomer";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { CUSTOMER_POST, ID } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import CustomerFormContainer from "../../components/CustomerFormContainer/CustomerFormContainer";

const NewCustomer: React.FC = () => {
  const [data] = useState(new CustomerModel());
  const { accountId } = useGetAccountId();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successfulRedirect } = useSuccessfulRedirect();

  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
    headerType: "BUSINESS",
  });

  const [createCustomer, { loading: isCreating }] = useMutation(createCustomerMutation);

  const handleCreate = (data: CustomerModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }
    const Phone = data.Phone.length ? Number(data.Phone.replace(/\D+/g, "")).toString() : "";
    const Files = data.Files;

    const dataForPost: any = { ...data, Phone, AccountId: accountId };
    delete dataForPost.Files;

    const variables = { Customer: dataForPost, Files };
    createCustomer({
      variables: variables,
      update(cache, { data: { CustomerPost } }) {
        updateCacheWithNewCustomer(cache, CustomerPost, accountId);
      },
    })
      .then(({ data }: any) => {
        if (data[CUSTOMER_POST][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new CustomerModel(data[CUSTOMER_POST]));
          displaySuccessNotification(NotificationTypes.CUSTOMER_CREATED);
          const currentCustomerPath = `${ROUTES.SINGLE_CUSTOMER}/${data[CUSTOMER_POST][ID]}`;
          navigate(currentCustomerPath, {
            replace: true,
            state: { justCreated: true },
          });
        } else if (data[CUSTOMER_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new CustomerModel(data[CUSTOMER_POST]));
          successfulRedirect(ROUTES.CUSTOMERS, NotificationTypes.CUSTOMER_CREATED);
        } else {
          displayErrorNotification(NotificationTypes.CUSTOMER_CREATE_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.CUSTOMER_CREATE_ERROR);
      });
  };

  return (
    <CustomerFormContainer
      data={data}
      handleSubmit={handleCreate}
      isLoading={isCreating}
      isNew
      setHeaderOptions={setHeaderOptions}
    />
  );
};

export default NewCustomer;
