import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import RecurringForm from "./forms/RecurringForm";
import DueDateForm from "./forms/DueDateForm";
import ScheduledForm from "./forms/scheduled/ScheduledForm";
import { ActivityScheduleModel } from "../../../../../redux/models/data/ActivityScheduleModel";
import { FullActivityDueDate } from "../../../../../redux/models/data/ActivityModelFull";
import {
  DATE_DIALOG_SAVE_DUE_DATE,
  DATE_DIALOG_SAVE_RECURRENCE,
  DATE_DIALOG_SAVE_SCHEDULE,
  EndAfter,
  RepeatAfter,
  RepeatType,
} from "../../../../../_constant/constants";
import { DateContext } from "./DueDateComponent";

interface DueDateTabsProps {
  errorFields: Set<string>;
  onChange: (value: FullActivityDueDate) => void;
  setProps: (value: FullActivityDueDate) => void;
  clearErrorFields: () => void;
  setDialogLabel: (text: string) => void;
}

const TabPanel = ({ children, value, index }) => (
  <div
    id={`full-width-tabpanel-${index}`}
    className={`Tabs_panel${value === index ? " active" : ""}`}
  >
    {value === index && children}
  </div>
);

const TabPanelProps = (index: number) => ({
  "aria-controls": `full-width-tabpanel-${index}`,
  id: `full-width-tab-${index}`,
});

export default function DueDateTabs(props: DueDateTabsProps) {
  const { relatedDueDate, schedule, endRecurring } = useContext(DateContext);
  const { errorFields } = props;
  // active tab
  const initIndex =
    schedule.RecurringType !== RepeatType.NONE
      ? 1
      : relatedDueDate && relatedDueDate.length > 0
      ? 2
      : 0;
  const [index, setIndex] = useState(initIndex);

  useEffect(() => {
    if (index === 1) {
      props.setDialogLabel(DATE_DIALOG_SAVE_RECURRENCE);
    } else if (index === 2) {
      props.setDialogLabel(DATE_DIALOG_SAVE_SCHEDULE);
    } else {
      props.setDialogLabel(DATE_DIALOG_SAVE_DUE_DATE);
    }
  }, [index]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const initialAdditionalParams = {
      schedule: new ActivityScheduleModel(),
      endRecurring: EndAfter.NO_LIMIT,
      relatedDueDate: [],
    };
    switch (newValue) {
      case 1:
        const nextSchedule = new ActivityScheduleModel(schedule);
        nextSchedule.RecurringType = RepeatType.DAY;
        nextSchedule.RecurrenceCount = 1;
        nextSchedule.RecurringRepeatType = RepeatAfter.COMPLETED_DATE;
        props.setProps({
          schedule: nextSchedule,
          endRecurring: EndAfter.NO_LIMIT,
          relatedDueDate: [],
        });
        break;
      case 2:
        props.setProps(initialAdditionalParams);
        break;
      default:
        props.setProps(initialAdditionalParams);
    }
    props.clearErrorFields();
    setIndex(newValue);
  };

  const handleDatePick = (nextDueDate) => {
    props.onChange({ dueDate: nextDueDate, schedule, endRecurring });
  };

  return (
    <div className="Tabs">
      <Tabs
        value={index}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Once" icon={<i className="Icon Icon-event" />} {...TabPanelProps(1)} />
        <Tab label="Recurring" icon={<i className="Icon Icon-repeat" />} {...TabPanelProps(2)} />
        <Tab label="Scheduled" icon={<i className="Icon Icon-timer" />} {...TabPanelProps(3)} />
      </Tabs>
      <TabPanel value={index} index={0}>
        <DueDateForm onChange={props.onChange} handleDatePick={handleDatePick} />
      </TabPanel>
      <TabPanel value={index} index={1}>
        <RecurringForm
          handleBlur={({ target }) => handleDatePick(target.value)}
          onChange={props.onChange}
          errorFields={errorFields}
        />
      </TabPanel>
      <TabPanel value={index} index={2}>
        <ScheduledForm onChange={props.onChange} />
      </TabPanel>
    </div>
  );
}
