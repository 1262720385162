import React from "react";

import { FIELD_TYPES_OBJECT, FieldTypes } from "./ItemCustomFieldsTypeOptions";

import EventIcon from "@material-ui/icons/Event";
import ListAltOutlined from "@material-ui/icons/ListAltOutlined";
import ExposureOutlinedIcon from "@material-ui/icons/ExposureOutlined";
import ToggleOffOutlinedIcon from "@material-ui/icons/ToggleOffOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const CUSTOM_FIELD_ICONS: { [prop: string]: React.ReactElement } = {
  [FIELD_TYPES_OBJECT.STRING]: <DescriptionOutlinedIcon />,
  [FIELD_TYPES_OBJECT.DATE]: <EventIcon />,
  [FIELD_TYPES_OBJECT.BOOLEAN]: <ToggleOffOutlinedIcon />,
  [FIELD_TYPES_OBJECT.NUMBER]: <ExposureOutlinedIcon />,
  [FIELD_TYPES_OBJECT.SELECT]: <ListAltOutlined />,
  [FIELD_TYPES_OBJECT.ADDRESS]: <LocationOnOutlinedIcon />,
};

export const getCustomFieldIcon = (type: FieldTypes | string) => {
  if (CUSTOM_FIELD_ICONS[type]) {
    return CUSTOM_FIELD_ICONS[type];
  } else {
    console.error("Icon for this type does not exist!");
    return <DescriptionOutlinedIcon />;
  }
};
