import styled from "styled-components";

import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import IconButton from "@material-ui/core/IconButton/IconButton";

export const ActionButton = styled(Button)`
  height: fit-content;
  width: 96px;

  && {
    margin-right: 0;
  }
`;

export const LoaderStyled = styled(CircularProgress)`
  position: absolute;
`;

export const BorderLoader = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
`;

export const IconButtonStyled = styled(IconButton)`
  margin-right: -12px;
`;
