import styled from "styled-components";
import { WizardItemCard } from "../WizardItemScreen/WizardItemStyles";

export const WizardInputInCardContainer = styled(WizardItemCard)(
  ({ theme, isInputMode }) => `
  height: 46px;
  background-color: ${isInputMode ? "unset" : theme.palette.primary.light};
  
  & .MuiTypography-root {
    flex-grow: 1;
    margin-right: 8px;
    text-align: left;
  }
`,
);
