import { FieldsSchemaTypes } from "./AssetModel";
import { Image } from "./ImageModel";

export interface VendorRefInputType {
  Id: string;
}

export class VendorModel {
  Id: string | null;
  Name: string;
  Address: string;
  Email: string;
  Phone: string;
  Website: string;
  Notes: string;
  AssetActivityIds: string[];
  CustomFields: any;
  Schema: FieldsSchemaTypes[];
  Images: Image[];
  Documents: any[];
  Videos: any[];
  Files: any;

  constructor(props = {} as VendorModel) {
    let data = props;
    if (!props) {
      data = {
        Id: null as null | string,
        Name: "",
        Address: "",
        Email: "",
        Phone: "",
        Website: "",
        Notes: "",
        AssetActivityIds: [] as string[],
        CustomFields: {} as any,
        Schema: [] as FieldsSchemaTypes[],
        Images: [] as Image[],
        Documents: [] as any[],
        Videos: [] as any[],
        Files: [] as any,
      };
    }

    const {
      Id = null as null | string,
      Name = "",
      Address = "",
      Email = "",
      Phone = "",
      Website = "",
      Notes = "",
      AssetActivityIds = [] as string[],
      CustomFields = {} as any,
      Schema = [] as FieldsSchemaTypes[],
      Images = [] as Image[],
      Documents = [] as any[],
      Videos = [] as any[],
      Files = [] as any,
    } = data;

    this.Id = Id ?? null;
    this.Name = Name;
    this.Address = Address ?? "";
    this.Email = Email ?? "";
    this.Phone = Phone ?? "";
    this.Website = Website ?? "";
    this.Notes = Notes ?? "";
    this.AssetActivityIds = AssetActivityIds ?? ([] as string[]);
    this.CustomFields = CustomFields ?? ({} as any);
    this.Schema = Schema ?? ([] as FieldsSchemaTypes[]);
    this.Images = Images ?? [];
    this.Documents = Documents ?? [];
    this.Videos = Videos ?? [];
    this.Files = Files ?? [];
  }
}
