import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useLayoutSwitcherContext } from "../../../../components/LayoutSwitcher/useLayoutSwitcherContext";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import { useWindowSize } from "../../../../_utils/useWindowSize.hook";
import { useStaticDataQuery } from "../../../../queries/useStaticDataQuery";

import { ActivityModel } from "../../../../redux/models/data/ActivityModelFull";

import { GET_ACTIVITIES_LIST } from "../activities-list.query";

import {
  LayoutType,
  ME_PREFIX,
  UNASSIGNED,
  EMPTY_ID,
  ALL_ACTIVITIES,
} from "../../../../_constant/constants";
import { ROUTES } from "../../../../_constant/screens";
import { ACCOUNT, ITEM_ACTIVITIES } from "../../../../_constant/wordings";
import { EMPTY_STATUS_STATE, SORTING_DETAILS } from "../../../../_constant/ActivityDateConstant";

import {
  getFilterValue,
  getSearchStringValue,
  getSelectedAssetIds,
  getSelectedAssetType,
} from "../../../../redux/reducers/filterReducer";
import { sortActivities, transformName } from "../../../../_utils/utils";
import { filterActivities } from "../../../../_utils/filtering";
import { updateActivityEmptyStatus } from "../../../../redux/actions/actions";

import { Collapse, useTheme } from "@material-ui/core";

import ActivityItemDragWrapper from "../wrappers/ActivityItemDragWrapper";
import ActivityItem from "../../_components/ActivityItem";
import ActivitiesByStatusHeader from "./ActivitiesByStatusHeader";
import ActivityCap from "../../../../components/activity-cap";
import ActivitiesDropWrapper from "../../../../components/ActivitiesDropWrapper/ActivitiesDropWrapper";
import NoActivities from "../../../../components/NoResultComponent/NoActivities";
import ActivityCardMobile from "../../../../components/ActivityCardMobile/ActivityCardMobile";

interface ActivitiesByStatusProps {
  status: any;
  statuses: any;
  isAccountPersonal: boolean;
  showEmptyMessage: boolean;
  searchString: string;
  selectedAssetType: string[];
  selectedAssetIds: string[];
  filterValue: string;
  filterString: () => void;
  setActivityStatusEmpty: (status: string) => void;
  removeActivityStatusFromEmptyList: (status: string) => void;
  updateActivityStatus: (activity: any, nextStatusId: string) => void;
  filterActivitiesArr: (statusName: string, activities: ActivityModel[]) => ActivityModel[];
}

const ActivitiesByStatus: React.FC<ActivitiesByStatusProps> = (props) => {
  const { accountId } = useGetAccountId();
  const staticData = useStaticDataQuery();
  const {
    state: { activities: layout },
  } = useLayoutSwitcherContext();

  const emptyImgUrl = staticData ? staticData.Empty[transformName(props.status?.Name || "")] : "";
  const emptyImgUrlRetina = staticData
    ? staticData.Empty[transformName(props.status ? `${props.status.Name}_2x` : "")]
    : "";

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const navigate = useNavigate();

  const filterActivities = (activities: ActivityModel[], filterValue: string) => {
    if (filterValue === ALL_ACTIVITIES) {
      return activities;
    } else if (filterValue === UNASSIGNED) {
      return activities.filter((activity) => {
        return activity.AssignedUserId === EMPTY_ID || !activity.AssignedUserId;
      });
    } else if (filterValue.includes(ME_PREFIX)) {
      const userId = filterValue.replace(ME_PREFIX, "");
      return activities.filter((activity) => {
        return (
          activity.AssignedUserId === userId ||
          activity.AssignedUserId === EMPTY_ID ||
          !activity.AssignedUserId
        );
      });
    } else {
      return activities.filter((activity) => activity.AssignedUserId === filterValue);
    }
  };

  const { status, filterString, updateActivityStatus, isAccountPersonal } = props;

  const [isExpanded, setExpanded] = useState(true);
  const [filteredData, setFilteredData] = useState<ActivityModel[]>([] as ActivityModel[]);
  const [browserWindowWidth] = useWindowSize();
  const [fetchPolicy, setFetchPolicy] = useState<"cache-first" | "network-only">("cache-first");

  const [getActivitiesList, activitiesData] = useLazyQuery(GET_ACTIVITIES_LIST, {
    fetchPolicy,
    errorPolicy: "all",
  });

  if (activitiesData && activitiesData.error) {
    navigate(ROUTES.ERROR);
  }

  useEffect(() => {
    if (!accountId) return;
    if (activitiesData.data && activitiesData.data?.[ACCOUNT]?.[ITEM_ACTIVITIES]) return;

    getActivitiesList({
      variables: {
        Id: accountId,
        Filter: filterString,
      },
    });
  }, [accountId]);

  useEffect(() => {
    if (activitiesData.data && activitiesData.data?.[ACCOUNT]?.[ITEM_ACTIVITIES]) {
      setFetchPolicy("network-only");
    }
  }, [filterString]);

  useEffect(() => {
    if (!accountId) return;
    if (fetchPolicy !== "network-only") return;
    getActivitiesList({
      variables: {
        Id: accountId,
        Filter: filterString,
      },
    });
  }, [fetchPolicy, accountId]);

  useEffect(() => {
    if (!activitiesData.loading && !activitiesData.error && activitiesData.data) {
      const activities = activitiesData.data[ACCOUNT][ITEM_ACTIVITIES];
      const { removeActivityStatusFromEmptyList, setActivityStatusEmpty } = props;

      let filtered = props.filterActivitiesArr(status.Name, activities);
      filtered = filterActivities(filtered, props.filterValue);

      filtered.length
        ? removeActivityStatusFromEmptyList(status.Name)
        : setActivityStatusEmpty(status.Name);
      setFilteredData(filtered);
      setFetchPolicy("cache-first");
    }
  }, [
    props.searchString,
    props.selectedAssetType,
    props.selectedAssetIds,
    activitiesData.data,
    props.filterValue,
  ]);

  const handleExpandStatus = useCallback((flag: boolean) => setExpanded(flag), []);
  const toggleExpandStatus = useCallback(() => setExpanded((prevValue) => !prevValue), []);

  const statusStyleName = status.Name.toLowerCase().split(" ").join("-");

  return (
    <ActivitiesDropWrapper
      loading={activitiesData.loading}
      layout={layout}
      status={status}
      updateActivityStatus={updateActivityStatus}
      expandStatus={handleExpandStatus}
    >
      <ActivitiesByStatusHeader
        status={status}
        layout={layout}
        onClick={toggleExpandStatus}
        isExpanded={isExpanded}
      />
      <div
        className={`ActivitiesList__statusColumnContent ${
          isExpanded ? "ActivitiesList__statusColumnContent--withPadding" : ""
        }`}
      >
        <Collapse in={isExpanded || layout === LayoutType.BOARD} timeout="auto" unmountOnExit>
          <div
            className={`ActivitiesList__wrapper${
              layout === LayoutType.LIST ? " ActivitiesList__wrapper--listView" : ""
            }`}
          >
            {activitiesData.loading && (
              <>
                <ActivityCap listView={layout === LayoutType.LIST} />
              </>
            )}
            {props.showEmptyMessage && (
              <NoActivities
                layout={layout}
                description={EMPTY_STATUS_STATE.get(status.Name)?.description || ""}
                url={emptyImgUrl}
                retinaUrl={emptyImgUrlRetina}
              />
            )}
            {!activitiesData.loading &&
              filteredData.length > 0 &&
              filteredData.map((activity) => (
                <ActivityItemDragWrapper
                  key={activity.Id}
                  activity={activity}
                  layout={layout}
                  statusName={status.Name}
                  browserWindowWidth={browserWindowWidth}
                  isAccountPersonal={isAccountPersonal}
                >
                  {!isDesktop && layout === LayoutType.LIST ? (
                    <ActivityCardMobile
                      key={activity.Id}
                      activity={activity}
                      dateType={SORTING_DETAILS.get(status.Name)["field"]}
                      status={statusStyleName}
                      withMoreAction
                    />
                  ) : (
                    <ActivityItem
                      key={activity.Id}
                      activity={activity}
                      dateType={SORTING_DETAILS.get(status.Name)["field"]}
                      layout={layout}
                      status={statusStyleName}
                      isAccountPersonal={isAccountPersonal}
                      isAssignmentsVisibile
                      withMoreAction
                    />
                  )}
                </ActivityItemDragWrapper>
              ))}
          </div>
        </Collapse>
      </div>
    </ActivitiesDropWrapper>
  );
};

const mapStateToProps = (state) => ({
  searchString: getSearchStringValue(state),
  selectedAssetType: getSelectedAssetType(state),
  selectedAssetIds: getSelectedAssetIds(state),
  filterValue: getFilterValue(state),
  filterActivitiesArr: (statusName, activities) =>
    sortActivities(statusName, filterActivities(state, activities || [])),
});

const mapDispatchToProps = (dispatch) => ({
  setActivityStatusEmpty: (status) => dispatch(updateActivityEmptyStatus(status, true)),
  removeActivityStatusFromEmptyList: (status) => dispatch(updateActivityEmptyStatus(status, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesByStatus);
