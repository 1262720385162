import gql from "graphql-tag";

export const GET_PROJECT_ASSETS = gql`
  query getProjectAssets($ProjectId: ID) {
    Project(ProjectId: $ProjectId) {
      Id

      Assets {
        Id
        Name
        AssetType {
          IconName
          Name
        }

        AssetActivities {
          Id
        }
      }
    }
  }
`;
