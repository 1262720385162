import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";
import {notEmpty} from "../../../../../../../_utils/utils";
import {DATE_FORMAT} from "../../../../../../../_utils/date";
import {ActivityScheduleModel} from "../../../../../../../redux/models/data/ActivityScheduleModel";
import {FullActivityDueDate} from "../../../../../../../redux/models/data/ActivityModelFull";
import {validateDay} from "./validation";
import {TWO_DIGITS_ONLY} from "../../../../../../../_utils/regex";
import Input from "../../../../../../../components/Input";
import {ChangeEvent} from "react";

const DECIMAL = 10;

const replaceDay = (date: string|moment.Moment, newDay: number) => {
  return moment(date, [DATE_FORMAT, moment.ISO_8601], true).set("date", newDay).toISOString();
};

interface DayInMonthInputProps {
  additionalProps: ActivityScheduleModel;
  value: string;
  onChange: (value: FullActivityDueDate) => void;
}

const DayInMonthInput: React.FC<DayInMonthInputProps> = (props: DayInMonthInputProps) => {
  const {additionalProps, value, onChange: onDueDateChange} = props;

  const initialInputValue = (notEmpty(value)
    ? moment(value, [DATE_FORMAT, moment.ISO_8601], true).date().toString(DECIMAL)
    : moment().date().toString(DECIMAL));
  const [inputValue, setInputValue] = useState(parseInt(initialInputValue, DECIMAL));

  useEffect(() => {
    if (notEmpty(value)) {
      const day = moment(value, [DATE_FORMAT, moment.ISO_8601], true).date();
      setInputValue(day);
    }
  }, [value]);

  const handleBlur = () => {
    const validInputDay = validateDay(inputValue, value);
    setInputValue(validInputDay);
    const dueDate = notEmpty(value) ? replaceDay(value, validInputDay) : replaceDay(moment(), validInputDay);
    onDueDateChange({dueDate, schedule: additionalProps});
  };

  const onChange = ({target} : ChangeEvent<HTMLInputElement>) => {
    const dayValueFromInput = target.value;
    const result: number = TWO_DIGITS_ONLY.test(dayValueFromInput) ? parseInt(dayValueFromInput) : inputValue;
    setInputValue(result);
  };

  return (
    <div >
        Day
      <Input
        value={inputValue}
        name={"DayInMonth"}
        placeholder={initialInputValue}
        onChange={onChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default DayInMonthInput;
