import React from "react";
import styled from "styled-components";

import useGetCalendarDate from "../../../../customHooks/useGetCalendarDate";

import { ReactComponent as DownloadIcon } from "../../../../../images/ic_download.svg";
import { CheckOutlined } from "@material-ui/icons";

import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";

const ButtonStyled = styled(Button)`
  position: relative;

  .MuiButton-endIcon {
    margin-top: 2px;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-root {
    position: absolute;
    top: 3px;
    left: 7px;
    z-index: 1;
  }
`;

interface DonwloadIconStyledProps {
  $loading: boolean;
}
const DonwloadIconStyled = styled(DownloadIcon)<DonwloadIconStyledProps>`
  width: 16px;
  height: 16px;
  color: ${({ $loading, theme }) => ($loading ? theme.palette.primary.main : "inherit")};
`;

interface ButtonWithLoadingProps {
  loading: boolean;
  uploaded: boolean;
}
const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({ loading, uploaded }) => {
  return (
    <LoadingWrapper>
      {uploaded ? <CheckOutlined color="secondary" /> : <DonwloadIconStyled $loading={loading} />}
      {loading && <CircularProgress size={24} />}
    </LoadingWrapper>
  );
};

interface GetCalendarDateButtonProps {
  activityId?: string;
  assetId?: string;
  className?: string;
  disabled?: boolean;
}

const GetCalendarDateButton: React.FC<GetCalendarDateButtonProps> = ({
  activityId,
  assetId,
  className,
  disabled,
}) => {
  const {
    state: { loading, uploaded },
    getDateFile,
  } = useGetCalendarDate();

  const handleButtonClick = () => {
    if (!activityId || !assetId) {
      return;
    }
    getDateFile({ activityId, assetId });
  };

  const isDisabled = loading || !activityId || !assetId || disabled;

  return (
    <ButtonStyled
      variant="outlined"
      color={uploaded ? "secondary" : "primary"}
      onClick={handleButtonClick}
      startIcon={<ButtonWithLoading loading={loading} uploaded={uploaded} />}
      className={className}
      disabled={isDisabled}
    >
      Add to Calendar
    </ButtonStyled>
  );
};

export default GetCalendarDateButton;
