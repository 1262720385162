import * as React from "react";
import {ChangeEvent, useContext, useState} from "react";
import {
  ActivityScheduleModel,
  ActivityScheduleModelFieldsName
} from "../../../../../../redux/models/data/ActivityScheduleModel";
import RecurringTypeSelect from "./recurring/RecurringTypeSelect";
import RecurringTypeForm from "./recurring/RecurringTypeForm";
import RecurringEndForm from "./recurring/RecurringEndForm";
import {FullActivityDueDate} from "../../../../../../redux/models/data/ActivityModelFull";
import RecurrenceCountInput from "./recurring/RecurrenceCount";
import {
  optionsDaysOfWeek,
  optionsNumberOfWeekInMonth,
  RepeatAfter,
  RepeatEvery,
  RepeatType,
} from "../../../../../../_constant/constants";
import {DateInput} from "../../../../../../components/DateInput";
import {DateContext} from "../DueDateComponent";
import NestedCalendarDialog from "./NestedCalenderDialog";
import InputFieldWithCalendar from "./recurring/InputFieldWithCalendar";
import {getJsDate, getWeekOfMonth} from "../../../../../../_utils/date";

type PossibleNameOfFields = ActivityScheduleModelFieldsName | "StartDate-mobileView";

interface RecurringFormProps {
  errorFields: Set<string>;
  onChange: (value: FullActivityDueDate) => void;
  handleBlur: (value: ChangeEvent<Element>) => void;
}

function getRadioValue(additionalProps: ActivityScheduleModel) {
  if (additionalProps.RecurringType === RepeatType.MONTH && additionalProps.RecurringRepeatType === RepeatAfter.DUE_DATE) {
    return !!additionalProps.WeekInMonth ? RepeatEvery.DAY_OF_WEEK_IN_MONTH : RepeatEvery.DAY_IN_MONTH;
  }
  return `${additionalProps.RecurringRepeatType}`;
}

export default function RecurringForm(props: RecurringFormProps) {
  const {dueDate, schedule, endRecurring} = useContext(DateContext);
  const [radioValue, setRadioValue] = useState(getRadioValue(schedule));
  const [dueDateCalenderVisible, setDueDateCalenderVisible] = useState(false);

  const handleChange = (name: PossibleNameOfFields, value: any) => {
    const fieldName: ActivityScheduleModelFieldsName = name === "StartDate-mobileView" ? "StartDate" : name;
    const nextSchedule: any = new ActivityScheduleModel(schedule);
    nextSchedule[fieldName] = value;
    props.onChange({schedule: nextSchedule});
  };

  const handleChangeDueDate = (nextDueDate: string) => {
    const nextSchedule = new ActivityScheduleModel(schedule);
    nextSchedule.StartDate = nextDueDate;

    if (schedule.RecurringType === RepeatType.WEEK) {
      nextSchedule.WeekInMonth = null;
      nextSchedule.DayOfWeek = optionsDaysOfWeek[getJsDate(nextDueDate).getDay()].value;
    }
    if (schedule.RecurringType === RepeatType.MONTH && schedule.RecurringRepeatType === RepeatAfter.DUE_DATE) {
      nextSchedule.DayOfWeek = optionsDaysOfWeek[getJsDate(nextDueDate).getDay()].value;
      nextSchedule.WeekInMonth = optionsNumberOfWeekInMonth[getWeekOfMonth(nextDueDate)].value;
    }
    props.onChange({dueDate: nextDueDate, schedule: nextSchedule, endRecurring});
  };

  return (
    <>
      <div className="RecurringForm TwoColumnContent">
        <div className="TwoColumnContent_calendarColumn">
          <InputFieldWithCalendar
            value={dueDate}
            name={"StartDate"}
            onChange={handleChangeDueDate}
            handleBlur={props.handleBlur}
            hasFieldError={props.errorFields.has("DueDate")}
            label={"Due Date"}
            className={"is-cropped"}
            numberOfMonth={1}
          />
        </div>
        <div className="TwoColumnContent_formColumn">
          <div className="Form_item Form_item__mobileView Form_item__noLabel">
            <i className="Icon Icon-event"/>
            <DateInput
              label="Due Date"
              name="StartDate-mobileView"
              value={dueDate}
              onClear={() => props.onChange({dueDate: null, schedule, endRecurring})}
              errorMessage={"Required field"}
              hasError={props.errorFields.has("DueDate")}
              showCalendar={() => setDueDateCalenderVisible(true)}
            />
          </div>
          <div className={"Form_item Form_item__noLabel"}>
            <RecurrenceCountInput
              value={schedule.RecurrenceCount || 0}
              onChange={handleChange}
              hasError={props.errorFields.has("RecurrenceCount")}
            />
            <RecurringTypeSelect onChange={props.onChange} onTypeChange={setRadioValue} />
          </div>
          <RecurringTypeForm onChange={props.onChange} radioValue={radioValue} setRadioValue={setRadioValue}/>
          <RecurringEndForm errorFields={props.errorFields} onChange={props.onChange}/>
        </div>
      </div>
      {dueDateCalenderVisible
        && <NestedCalendarDialog
            value={dueDate}
            onChange={(date) => props.onChange({dueDate: date, schedule, endRecurring})}
            handleBlur={props.handleBlur}
            hasDueDateError={props.errorFields.has("DueDate")}
            label="Due Date"
            name="StartDate"
            handleClose={() => setDueDateCalenderVisible(false)}
            dialogTitle={"Recurring Due Date"}
          />
      }
    </>
  );
}
