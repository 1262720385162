import * as React from "react";
import { usePreview } from "react-dnd-multi-backend";
import ActivityItem from "../containers/activities/_components/ActivityItem";
import ProjectItem from "../containers/projects/_components/ProjectItem";
import { SORTING_DETAILS } from "../_constant/ActivityDateConstant";
import { LayoutType } from "../_constant/constants";
import ProjectTabActivityItem from "./ProjectTabActivityItem/ProjectTabActivityItem";

// const
const generatePreview = (item, style) => {
  // decrease height by height of assign select + margin
  const height =
    LayoutType.LIST === item.layout || item.isAccountPersonal ? item : item.height - 36;

  return (
    <div
      className={"CustomDragLayer"}
      style={{
        ...style,
      }}
    >
      {item.type === "activity" && (
        <ActivityItem
          style={{
            width: item.width,
            height: height,
            boxShadow:
              "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)",
          }}
          key={item.activity.Id}
          activity={item.activity}
          dateType={SORTING_DETAILS.get(item.statusName)["field"]}
          layout={item.layout}
          isAccountPersonal={item.isAccountPersonal}
          isDraggable
          isAssignmentsVisibile={false}
        />
      )}
      {item.type === "project-activity" && (
        <ProjectTabActivityItem
          style={{
            maxWidth: item.width,
            height: item.height,
            boxShadow:
              "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)",
          }}
          key={item?.activity.Id}
          activity={item.activity}
          dateType={item?.activity.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
          layout={LayoutType.LIST}
          isAccountPersonal={false}
          isAssignmentsVisibile={false}
          customerId={null}
          withMoreAction
          withDragAndDropIcon
          projectId={null}
          removeActivity={() => {}}
          isCustomize={false}
          removeActivityFromProjectWithDelete={() => {}}
          isDraggable
        />
      )}

      {item.type === "project" && (
        <ProjectItem
          style={{
            width: item.width,
            height: height,
            boxShadow:
              "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)",
          }}
          key={item.project.Id}
          project={item.project}
          statusName=""
          dateType={SORTING_DETAILS.get(item.statusName)["field"]}
          isAccountPersonal={item.isPersonal}
          isAssignmentsVisibile={false}
          isDraggable
        />
      )}
    </div>
  );
};

const CustomDragLayer = () => {
  const { display, style, item } = usePreview();

  if (!display) {
    return null;
  }
  return generatePreview(item, style);
};

export default CustomDragLayer;
