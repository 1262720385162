import { useContext } from "react";
import { LayoutType } from "../../_constant/constants";
import {
  LayoutSwitcherDispatchContext,
  LayoutSwitcherStateContext,
  LayoutSwitcherStateTypes,
  LayoutSwitcherStateInterface,
} from "./LayoutSwitcherProvider";

export interface pageSwitcher {
  toListView: () => void;
  toBoardView: () => void;
  toPrintableView: () => void;
}

export const useLayoutSwitcherContext = () => {
  const state = useContext(LayoutSwitcherStateContext);
  const dispatch = useContext(LayoutSwitcherDispatchContext);

  if (state === undefined) {
    throw new Error("useLayoutSwitcherContext must be used within a LayoutSwitcherProvider");
  }

  function toListView(fieldName: keyof LayoutSwitcherStateInterface) {
    return () => {
      if (!dispatch) {
        return;
      }
      if (Object.values(LayoutSwitcherStateTypes).includes(fieldName)) {
        dispatch({ type: fieldName, payload: LayoutType.LIST });
      }
    };
  }
  function toBoardView(fieldName: keyof LayoutSwitcherStateInterface) {
    return () => {
      if (!dispatch) {
        return;
      }
      if (Object.values(LayoutSwitcherStateTypes).includes(fieldName)) {
        dispatch({ type: fieldName, payload: LayoutType.BOARD });
      }
    };
  }

  function toPrintableView(fieldName: keyof LayoutSwitcherStateInterface) {
    return () => {
      if (!dispatch) {
        return;
      }
      if (Object.values(LayoutSwitcherStateTypes).includes(fieldName)) {
        dispatch({ type: fieldName, payload: LayoutType.PRINTABLE });
      }
    };
  }

  const switchLayout = {
    items: {
      toListView: toListView(LayoutSwitcherStateTypes.items),
      toBoardView: toBoardView(LayoutSwitcherStateTypes.items),
      toPrintableView: toPrintableView(LayoutSwitcherStateTypes.items),
    },
    activities: {
      toListView: toListView(LayoutSwitcherStateTypes.activities),
      toBoardView: toBoardView(LayoutSwitcherStateTypes.activities),
    },
    vendors: {
      toListView: toListView(LayoutSwitcherStateTypes.vendors),
      toBoardView: toBoardView(LayoutSwitcherStateTypes.vendors),
    },
  };

  return { state, switchLayout };
};
