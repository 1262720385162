import React from "react";
import { useQuery } from "react-apollo";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { composeImageUrl } from "../../_utils/utils";
import { Avatar } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import { GET_CUSTOMER_INFO } from "./getCustomerInfo.query";

interface CustomerInfoProps {
  customerId: string | null;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ customerId }) => {
  const { accountId } = useGetAccountId();

  const { data: customer, loading, error } = useQuery(GET_CUSTOMER_INFO, {
    variables: {
      CustomerId: customerId,
      AccountId: accountId,
    },
    skip: !accountId || !customerId,
  });

  const renderAvatar = () => {
    if (customer && customer.Customer.Images && customer.Customer.Images.length > 0) {
      return (
        <Avatar
          style={{ height: "var(--icon-size)", width: "var(--icon-size)", marginRight: "10px" }}
          src={composeImageUrl(
            customer.Customer.Images[0].Id,
            customer.Customer.Images[0].ContainerName,
            customer.Customer.Images[0].ContainerId,
          )}
        />
      );
    }

    return (
      <Avatar
        style={{ height: "var(--icon-size)", width: "var(--icon-size)", marginRight: "10px" }}
      >
        <AccountCircleIcon />
      </Avatar>
    );
  };

  if (!customerId) {
    return null;
  }

  if (loading || !accountId) {
    return (
      <Box display="flex" alignItems="center" marginRight="15px">
        <Skeleton variant="circle" width={26} height={26} style={{ marginRight: "10px" }} />
        <Skeleton variant="text" width={100} height={16} />
      </Box>
    );
  }

  if (customer) {
    return (
      <Box display="flex" alignItems="center" marginRight="15px">
        {renderAvatar()}
        <Typography noWrap>{customer.Customer.Name}</Typography>
      </Box>
    );
  }

  return null;
};

export default CustomerInfo;
