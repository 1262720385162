import React from "react";
import styled from "styled-components";

import { Avatar } from "@material-ui/core";

interface IconWrapperStyledProps {
  $outerPadding?: number;
}
const IconWrapperStyled = styled.div<IconWrapperStyledProps>`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ $outerPadding }) => ($outerPadding ? `${$outerPadding}px` : "24px")};
  }
  @media print {
    padding-right: 10px;

    :not(last-child) {
      margin-bottom: 18px;
    }
  }
`;

interface IconBackgroundProps {
  $innerPadding?: number;
}
const IconBackground = styled(Avatar)<IconBackgroundProps>`
  height: 28px;
  width: 28px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  margin-right: ${({ $innerPadding }) => ($innerPadding ? `${$innerPadding}px` : "14px")};

  @media print {
    margin-right: 10px;
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface IconWrapperProps {
  icon: JSX.Element;
  innerPadding?: number;
  outerPadding?: number;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  icon,
  children,
  innerPadding,
  outerPadding,
}) => {
  return (
    <IconWrapperStyled $outerPadding={outerPadding}>
      <IconBackground $innerPadding={innerPadding} variant="circular">
        {icon ? icon : null}
      </IconBackground>
      {children}
    </IconWrapperStyled>
  );
};

export default IconWrapper;
