import React from "react";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import { Grid } from "@material-ui/core";

import InputRow from "../../components/InputRow/InputRow";
import Input from "../Input/Input";

import { VisitModel } from "../../redux/models/data/VisitModel";

import AssignUserSelect from "../AssignUserSelect/AssignUserSelect";
import DueDateComponent from "../../containers/activities/_components/date/due_date/DueDateComponent";
import CompletedDateComponent from "../../containers/activities/_components/date/completed/CompletedDateComponent";
import DateRowLayout from "../DateRowLayout/DateRowLayout";
import CustomerSelect from "../CustomerSelect/CustomerSelect";
import InputField from "../InputField/InputField";

interface VisitFormLayoutProps {
  data: VisitModel;
  errorFields: Map<string, string>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  isVisitFinished: boolean;
}

const VisitFormLayout: React.FC<VisitFormLayoutProps> = (props) => {
  const { data, handleChange, errorFields, isVisitFinished } = props;

  const purposeInput = React.useMemo(
    () => (
      <Input
        type="text"
        onChange={handleChange}
        value={data.Purpose}
        label={"Purpose"}
        id={"Purpose"}
        fullWidth
        icon={<EditOutlinedIcon />}
        required
        error={errorFields.has("Purpose")}
        errorText={errorFields.get("Purpose")}
      />
    ),
    [data.Purpose],
  );

  const notesInput = React.useMemo(
    () => (
      <InputField
        handleChange={handleChange}
        value={data.Notes}
        id="Notes"
        label="Notes"
        fullWidth
        icon={<DescriptionOutlinedIcon />}
        multiline
        maxLength="1000"
      />
    ),
    [data.Notes],
  );

  const dueDateInput = React.useMemo(
    () => (
      <DueDateComponent
        onChange={handleChange}
        value={data.DueDateTime ?? ""}
        additionalProps={data.Schedule}
        relatedDueDateTime={data.RelatedDueDateTime || []}
      />
    ),
    [data.DueDateTime, data.Schedule, data.RelatedDueDateTime],
  );

  const completedDate = React.useMemo(
    () => (
      <CompletedDateComponent
        value={data.CompletedDateTime ?? ""}
        onChange={handleChange}
        statusFieldName="VisitStatusId"
        disabled={!isVisitFinished}
        disabledInfoText="You must finish visit first"
      />
    ),
    [data.CompletedDateTime],
  );

  const assignUserSelect = React.useMemo(
    () => (
      <AssignUserSelect assignedUserId={data.AssignedUserId} handleFieldChange={handleChange} />
    ),
    [data.AssignedUserId],
  );

  return (
    <div className="Section">
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomerSelect customerId={data.CustomerId} handleChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {purposeInput}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {notesInput}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {assignUserSelect}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <DateRowLayout>
          {dueDateInput}
          {completedDate}
        </DateRowLayout>
      </InputRow>
    </div>
  );
};

export default VisitFormLayout;
