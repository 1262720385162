import * as React from "react";

interface RadioProps {
  className?: string;
  label?: string;
  name?: string;
  value?: boolean;
  onChange?: (e) => void;
  isStatusIndicator?: boolean;
  children?: any;
}

const Radio: React.FC<RadioProps> = (props) => {
  const {label = "", className, value, onChange, isStatusIndicator = false, children, name} = props;
  const statusClassName = isStatusIndicator ? " status-indicator" : "";
  const labelClassName = `Radio ${className}${!label ? " no-label" : ""}${value ? " checked" : ""}`;
  return (
    <>
      <label htmlFor={name}
             className={labelClassName}
             onClick={() => onChange({value: name})} >
        <input
          id={name}
          type={"radio"}
          className={`Radio_input${statusClassName}`}
          checked={value}
          onChange={() => onChange({value: name})}
        />
        <span className={`Radio_label${statusClassName}`}>
          {label && <span className={"Label"}>{label}</span>}
        </span>
      </label>
      <>{children}</>
    </>
  );
};

export default Radio;