import React from "react";
import { Box, useTheme } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import styled from "styled-components";

const StyledStatusText = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: ${({ $status, theme }) => {
    switch ($status) {
      case "To Do":
        return theme.palette.status.toDo;
      case "In Progress":
        return theme.palette.status.inProgress;
      case "Done":
        return theme.palette.status.done;
      default:
        return theme.palette.status.toDo;
    }
  }};
`;

const handleStatusChange = (e: React.SyntheticEvent) => {
  e.stopPropagation();
};

const StatusRadio = ({ status, onClick }) => {
  const theme = useTheme();

  const renderRadio = () => {
    switch (status) {
      case "To Do":
        return (
          <Radio
            checked={false}
            onClick={onClick}
            value="a"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
            size="small"
            style={{ color: `${theme.palette.status.toDo}` }}
          />
        );
      case "In Progress":
        return (
          <Radio
            checked={true}
            onClick={onClick}
            value="a"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
            size="small"
            style={{ color: `${theme.palette.status.inProgress}` }}
          />
        );
      case "Done":
        return (
          <Radio
            checked={true}
            onClick={onClick}
            value="a"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
            size="small"
            style={{ color: `${theme.palette.status.done}` }}
          />
        );
      default:
        return (
          <Radio
            checked={false}
            onClick={onClick}
            value="a"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
            size="small"
            style={{ color: `${theme.palette.status.toDo}` }}
          />
        );
    }
  };
  return (
    <Box display="flex" alignItems="center" onClick={handleStatusChange}>
      <StyledStatusText $status={status}>{status}</StyledStatusText>
      {renderRadio()}
    </Box>
  );
};

export default StatusRadio;
