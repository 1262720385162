import React from "react";
import styled from "styled-components";

import { Typography } from "@material-ui/core";

import { textClip } from "../_styled/textClip";

import DropZoneSvgCardBase, { DropZoneSvgCardBaseProps } from "./DropZoneSvgCardBase";
import DropZoneCardContainer from "./DropZoneCardContainer";

interface CardTitleProps {
  $large?: boolean;
  $bigTitle?: boolean;
}
const CardTitle = styled(Typography)<CardTitleProps>`
  font-size: 0.75rem;
  padding-top: 6px;
  max-width: ${({ $large }) => ($large ? "116px" : "66px")};
  min-height: 24px;

  @media screen and (max-width: 600px) {
    font-size: ${({ $bigTitle }) => ($bigTitle ? "1.125rem" : "0.75rem")};
    padding-top: ${({ $bigTitle }) => ($bigTitle ? "10px" : "6px")};
  }

  ${textClip}
`;

interface DropZoneSvgCardProps extends DropZoneSvgCardBaseProps {
  title?: string;
  bigTitle?: boolean;
  open: () => void;
  remove: () => void;
  dataOrder?: number;
  url?: string | null;
}

const DropZoneSvgCard: React.FC<DropZoneSvgCardProps> = (props) => {
  const { title, large, bigTitle, open, remove, dataOrder, url, ...rest } = props;

  return (
    <DropZoneCardContainer data-order={dataOrder} onClick={open} url={url}>
      <DropZoneSvgCardBase remove={remove} large={large} {...rest} />
      <CardTitle $bigTitle={bigTitle} $large={large}>
        {title}
      </CardTitle>
    </DropZoneCardContainer>
  );
};

export default DropZoneSvgCard;
