import { GET_DETAILED_ITEM_TYPE_LIST } from "../../_common/filter/asset-type.query";
import { ACCOUNT, ASSET_REFS, ID, ASSET_TYPE, ITEM_TYPES } from "../../../_constant/wordings";
import { AssetTypeModel } from "../../../redux/models/data/AssetTypeModel";
import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import { GET_ASSET_REFS } from "../all/assets.query";
import { AssetModel } from "../../../redux/models/data/AssetModel";
import { Image } from "../../../redux/models/data/ImageModel";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";

export function updateAssetTypeListQuery(
  cache: DataProxy,
  changedItem: AssetModel,
  accountId: string,
  initialAssetTypeId: string,
) {
  try {
    const assetTypesQuery: any = cache.readQuery({
      query: GET_DETAILED_ITEM_TYPE_LIST,
      variables: { Id: accountId },
    });

    const assetsRef: any = cache.readQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
    });

    const assetTypes = assetTypesQuery[ACCOUNT];

    // check if the previous assetType has items. if not - remove it
    const hasAssetTypeAnyItems = assetsRef[ACCOUNT][ASSET_REFS].some(
      (asset: AssetRefsModel) => asset.AssetTypeRef.Id === initialAssetTypeId,
    );

    const typeThatWasChanged = assetTypes[ITEM_TYPES].find(
      (assetType: AssetTypeModel) => assetType[ID] === initialAssetTypeId,
    );
    const isAssetTypeSystem = !!typeThatWasChanged?.assetType?.IsSystem;

    if (!hasAssetTypeAnyItems && !isAssetTypeSystem) {
      assetTypes[ITEM_TYPES] = assetTypes[ITEM_TYPES].filter(
        (assetType) => assetType.Id !== initialAssetTypeId,
      );
    }

    // check have we this assetType before item was change. if not - add it to query
    changedItem[ASSET_TYPE].IsSystem = null;
    if (
      !assetTypes[ITEM_TYPES].some((assetType) => assetType[ID] === changedItem[ASSET_TYPE][ID])
    ) {
      assetTypes[ITEM_TYPES] = assetTypes[ITEM_TYPES].concat([changedItem[ASSET_TYPE]]);
    }

    cache.writeQuery({
      query: GET_DETAILED_ITEM_TYPE_LIST,
      variables: { Id: accountId },
      data: assetTypesQuery,
    });
  } catch (e) {}
}

export function updateAssetRefsQuery(cache: DataProxy, changedItem: AssetModel, accountId: string) {
  try {
    const assetRefs: any = cache.readQuery({ query: GET_ASSET_REFS, variables: { Id: accountId } });

    const assets = assetRefs[ACCOUNT];

    // change assetType object in changed asset from GET_ASSET_TYPE_LIST query
    assets[ASSET_REFS] = assets[ASSET_REFS].map((asset: AssetRefsModel) => {
      if (asset[ID] === changedItem[ID]) {
        asset.Name = changedItem.Name;
        asset.Description = changedItem.Description;
        asset.AssetTypeRef = changedItem[ASSET_TYPE];
        asset.MainImage =
          changedItem.Images.length !== 0
            ? { ...new Image(changedItem["Images"][0]), __typename: "Image" }
            : null;
      }
      return asset;
    });

    cache.writeQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
      data: assetRefs,
    });
  } catch (e) {}
}
