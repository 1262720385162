import React from "react";
import { StyledTableHeader } from "./Table.styles";
import Grid from "@material-ui/core/Grid/Grid";

interface TableHeaderProps {
  entityName: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ entityName }) => {
  return (
    <StyledTableHeader container item spacing={2}>
      <Grid item xs={4}>
        <div>{entityName} Names</div>
      </Grid>
      <Grid item xs={4}>
        <div>Address</div>
      </Grid>
      <Grid item xs={3}>
        <div>Contacts</div>
      </Grid>
      <Grid item xs={1} />
    </StyledTableHeader>
  );
};

export default TableHeader;
