import {
  getSearchStringValue,
  getSelectedAssetIds,
  getSelectedAssetsTags,
  getSelectedAssetType,
  getSelectedAssetTypeItemsIds,
} from "../redux/reducers/filterReducer";
import { AssetRefsModel } from "../redux/models/data/AssetRefsModel";
import { ASSET_TYPE_REF, DESCRIPTION, NAME } from "../_constant/wordings";
import { ActivityModel } from "../redux/models/data/ActivityModelFull";
import { ALL_ACTIVITIES, ME_PREFIX, UNASSIGNED } from "../_constant/constants";
import { AccountModel } from "../redux/models/data/AccountModel";
import { VisitModel } from "../redux/models/data/VisitModel";
import { AssetModel } from "../redux/models/data/AssetModel";
import { ProjectModel } from "../redux/models/data/ProjectModel";

const searchEntry = (
  object: { [key: string]: any },
  field: string,
  searchStringInLowerCase: string,
): boolean => {
  if (!object[field]) {
    return false;
  }
  if (typeof object[field] === "object" && object[field].hasOwnProperty(NAME)) {
    const entity = object[field];
    return entity[NAME].toLowerCase().includes(searchStringInLowerCase);
  }

  return object[field].toLowerCase().includes(searchStringInLowerCase);
};

function searchByNameAndDescription(array: any[], searchString: string): any[] {
  const searchStringInLowerCase: string = searchString.toLowerCase();

  if (array?.[0]?.[ASSET_TYPE_REF]) {
    return array.filter(
      (item: AssetRefsModel) =>
        (!!item && searchEntry(item, NAME, searchStringInLowerCase)) ||
        searchEntry(item, DESCRIPTION, searchStringInLowerCase) ||
        searchEntry(item, "DefaultDescription", searchStringInLowerCase) ||
        searchEntry(item[ASSET_TYPE_REF], NAME, searchStringInLowerCase),
    );
  }

  if (array?.[0]?.AssetType) {
    return array.filter(
      (item: AssetModel) =>
        (!!item && searchEntry(item, NAME, searchStringInLowerCase)) ||
        searchEntry(item, DESCRIPTION, searchStringInLowerCase) ||
        searchEntry(item, "DefaultDescription", searchStringInLowerCase) ||
        searchEntry(item.AssetType, NAME, searchStringInLowerCase),
    );
  }
  return [];
}

function searchByTags(items: AssetModel[], tagsArray: string[]): any[] {
  const filteredItems = items.filter((item) => {
    const isTagIncludesInItem = (tag: string) => {
      return item.Tags.includes(tag);
    };
    return tagsArray.every(isTagIncludesInItem);
  });
  return filteredItems;
}

function searchActivitiesByNameAndDescription(array: any[], searchString: string) {
  const searchStringInLowerCase: string = searchString.toLowerCase();

  return array.filter(
    (item) =>
      (!!item && searchEntry(item, NAME, searchStringInLowerCase)) ||
      searchEntry(item, DESCRIPTION, searchStringInLowerCase) ||
      searchEntry(item, "Asset", searchStringInLowerCase),
  );
}

export function filterAssets(state: any, assets: any) {
  let filteredAssets = assets;
  const search = getSearchStringValue(state);
  const tags = getSelectedAssetsTags(state);
  const selectedAssetIds = getSelectedAssetIds(state);
  const selectedAssetType = getSelectedAssetType(state);

  filteredAssets = tags?.length > 0 ? searchByTags(assets, tags) : filteredAssets;

  if (selectedAssetIds.size > 0) {
    const selectedAssets = filteredAssets.filter((item: AssetRefsModel) =>
      selectedAssetIds.has(item.Id),
    );
    return searchByNameAndDescription(selectedAssets, search);
  }
  if (selectedAssetType) {
    const selectedAssets = filteredAssets.filter((item: AssetRefsModel) => {
      if (selectedAssetType === item?.AssetTypeRef?.Id) return true;
      if (selectedAssetType === item?.AssetType?.Id) return true;
      return false;
    });
    return searchByNameAndDescription(selectedAssets, search);
  }

  return searchByNameAndDescription(filteredAssets, search);
}

export function filterActivities(state: any, activities: ActivityModel[]) {
  const selectedAssetType = getSelectedAssetType(state);
  const selectedAssetIds = getSelectedAssetIds(state);
  const search = getSearchStringValue(state);

  let sortedActivities = activities;
  if (selectedAssetIds.size > 0) {
    sortedActivities = activities.filter((item) => selectedAssetIds.has(item.AssetId));
  } else if (selectedAssetType) {
    const relatedItemIds = getSelectedAssetTypeItemsIds(state);
    sortedActivities = activities.filter((item) => relatedItemIds.includes(item.AssetId));
  }
  return searchActivitiesByNameAndDescription(sortedActivities, search);
}

export function isDefaultSelectOption(filterValue: string) {
  return (
    filterValue === ALL_ACTIVITIES || filterValue === UNASSIGNED || filterValue.includes(ME_PREFIX)
  );
}

export function filterAccounts(accounts: AccountModel[], filterValue: string) {
  return accounts.filter(
    (account) =>
      account.Name.toLowerCase().includes(filterValue.toLowerCase()) ||
      (account.Description &&
        account.Description.toLowerCase().includes(filterValue.toLowerCase())),
  );
}
export function filterVisits(visits: VisitModel[], searchString: string) {
  return visits.filter((visit: VisitModel) => {
    return (
      visit.Purpose.toLowerCase().includes(searchString.toLowerCase()) ||
      visit.CustomerName.toLowerCase().includes(searchString.toLowerCase())
    );
  });
}

export function filterProjects(projects: ProjectModel[], searchString: string) {
  return projects.filter((project: ProjectModel) => {
    return project?.Name?.toLowerCase().includes(searchString.toLowerCase());
  });
}
