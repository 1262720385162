import React from "react";
import { DayOfWeekPickerContainer } from "./DayOfWeekPicker.style";
import { DAYS_OF_WEEK } from "../../../_utils/date";
import DayOfWeek from "../DayOfWeek/DayOfWeek";
import moment from "moment";

interface DayOfWeekPickerProps {
  date: moment.Moment;
  changeDayHandler: (value: string) => void;
}

const DayOfWeekPicker: React.FC<DayOfWeekPickerProps> = ({ date, changeDayHandler }) => {
  return (
    <DayOfWeekPickerContainer>
      {Object.values(DAYS_OF_WEEK).map((value) => (
        <DayOfWeek
          handleDateChange={() => changeDayHandler(value)}
          text={value}
          isSelected={date.format("dddd") === value}
          key={value}
        />
      ))}
    </DayOfWeekPickerContainer>
  );
};

export default DayOfWeekPicker;
