import gql from "graphql-tag";

export const acceptInvitationQuery = gql`
  mutation acceptInvite($InvitationId: Guid!) {
    AcceptInvite(InvitationId: $InvitationId) {
      Id
      Name
      Description
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      IsCustomerManagement
      OwnerId
    }
  }
`;
