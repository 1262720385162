import gql from "graphql-tag";

export const vendorDetailedFragment = `
    Id
    Name
    Address
    Email
    Phone
    Website
    CustomFields
    AssetActivityIds
    Notes
    Schema {
      FieldType
      Id
      Name
      Order
    }
    Images {
      Id
      ContainerId
      ContainerName
      Description
      FileExtension
      Name
      IsPrimary
    }
    Documents {
      Id
      Name
      ContainerId
      ContainerName
      AbsoluteUri
      FileExtension
    }
    Videos {
      Id
      Name
      ContainerId
      ContainerName
      FileExtension
      AbsoluteUri
    }  
`;

export const FileUploadResult = `
  FileUploadResults {
    ErrorMessages
    FileName
    Status
  }
`;

export const createVendorMutation = gql`
  mutation createVendor($Vendor: VendorInput!, $Files: [Upload]) {
    VendorPost(Vendor: $Vendor, Files: $Files) {
      ${vendorDetailedFragment}
      ${FileUploadResult}
    }
  }
`;
