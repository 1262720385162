import React from "react";

import { Container, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import styled from "styled-components";

import Body from "../../components/Body/Body";

import { ReactComponent as Logo } from "logoBrand/logo.svg";
import { FileCopyOutlined } from "@material-ui/icons";
import { generateContentInfo } from "../../_utils/aboutPageConfig";

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const StyledLogo = styled(Logo)`
  max-width: 100%;
  width: 100%;
  max-height: 100px;
  height: 100%;
`;

const StyledIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
  }
`;
const QRcodeWrapper = styled(LogoWrapper)`
  margin-bottom: 0;
  padding: 48px 0 32px;
`;

const About: React.FC = () => {
  const {
    copyright,
    sharingLink,
    sharingLinkLabel,
    website,
    QRcode,
    acknowledgementsText,
    acknowledgementsLink,
    acknowledgementsLinkLabel,
  } = generateContentInfo();

  return (
    <Body disableDesktopScroll>
      <Container maxWidth="sm">
        <Box marginTop="30px" marginBottom="30px">
          <Typography component="h1" variant="body1" style={{ fontWeight: 500 }}>
            About
          </Typography>
        </Box>
        <Card style={{ padding: "16px" }}>
          <LogoWrapper>
            <StyledLogo />
          </LogoWrapper>
          <Grid container spacing={2}>
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="flex-end"
              wrap="nowrap"
            >
              <Grid item>
                <Box marginRight="10px">
                  <Typography variant="caption">{copyright}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Link href={`http://${website}`} target="_blank" rel="noreferrer" underline="none">
                  <Typography variant="caption">{website}</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="flex-end"
              wrap="nowrap"
            >
              <Grid item>
                <Box marginRight="10px">
                  <Typography variant="caption" style={{ fontWeight: 600 }}>
                    Acknowledgements:
                  </Typography>
                  <br />
                  &nbsp;
                  <Typography variant="caption">{acknowledgementsText}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Link
                  href={acknowledgementsLink}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  >
                  <Typography variant="caption">{acknowledgementsLinkLabel}</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="flex-end"
              wrap="nowrap"
            >
              <Grid item style={{ paddingBottom: "10px" }}>
                <Box marginRight="10px">
                  <Typography variant="caption" style={{ fontWeight: 600 }}>
                    Sharing:
                  </Typography>
                  <br />
                  &nbsp;
                  <Typography variant="caption">Copy this URL and send to others:</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <StyledIconButton
                    color="primary"
                    onClick={() => window.navigator.clipboard.writeText(sharingLink)}
                  >
                    <FileCopyOutlined />
                  </StyledIconButton>

                  <Link href={sharingLink} target="_blank" rel="noreferrer" underline="none">
                    <Typography variant="caption">{sharingLinkLabel}</Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <QRcodeWrapper>
            <QRcode />
          </QRcodeWrapper>
        </Card>
      </Container>
    </Body>
  );
};

export default About;
