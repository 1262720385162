import * as React from "react";
import { useDrop } from "react-dnd";
import Accordion from "../../../../components/Accordion";
import { ActivityModelRelated } from "./ActivityModelRelated";
import { useMutation } from "react-apollo";
import { CHANGE_STATUS, GET_ACTIVITIES_LIST } from "../../../activities/all/activities-list.query";
import { isCompletedStatus, isRecurring } from "../../../../_utils/utils";
import {
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../../../../redux/actions/_utils";
import { NotificationTypes } from "../../../../_constant/NotificationTypes";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import moment from "moment";
import useGetAcitvitiesStatuses from "../../../../customHooks/api/useGetAcitvitiesStatuses";
import { AllActivitiesCacheUpdate } from "../../../activities/all/AllActivitiesCacheUpdate";
import { FILTERING_DETAILS } from "../../../../_constant/ActivityDateConstant";
import { getActivityFilters } from "../../../../redux/reducers/appReducer";
import { connect } from "react-redux";
import {
  createQueryOptions,
  RelatedActivitiesCacheUpdate,
  turnOffLoadingInRelativeActivityCache,
} from "./RelatedActivitiesCacheUpdate";

interface DropTargetRelatedActivitiesProps {
  label: any;
  onOpen: () => void;
  onClose: () => void;
  expanded: boolean;
  isLoading: boolean;
  children: any;
  StatusId: string;
  getFilterString: (status) => string;
}

const DropTargetRelatedActivities = (props: DropTargetRelatedActivitiesProps) => {
  const { onOpen, isLoading, expanded, label, onClose } = props;

  const { accountId } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();

  const [updateStatus, { client }] = useMutation(CHANGE_STATUS);

  const updateActivityStatus = async (activity, nextStatus) => {
    const CompletedDateTime = isCompletedStatus(statuses, nextStatus)
      ? moment().toISOString()
      : null;
    const recurring = isRecurring({
      schedule: activity.Schedule,
      relatedDueDate: activity.RelatedDueDateTime,
    });
    const allActivitiesRefetchOptions = {
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: props.getFilterString(statuses[0]),
      },
    };

    const refetchQueries: any =
      nextStatus === statuses[statuses.length - 1].Id && recurring
        ? [createQueryOptions(accountId, activity.AssetId, statuses[0].Id)]
        : [];

    try {
      if (client?.cache.readQuery(allActivitiesRefetchOptions)) {
        refetchQueries.push(allActivitiesRefetchOptions);
      }
    } catch (e) {}

    await onOpen();

    updateStatus({
      variables: {
        AccountId: accountId,
        AssetId: activity.AssetId,
        Id: activity.Id,
        StatusId: nextStatus,
        CompletedDateTime,
      },
      optimisticResponse: {
        AssetActivityPatch: {
          Id: activity.Id,
          StatusId: nextStatus,
          CompletedDateTime,
          __typename: "AssetActivity",
        },
      },
      update: (cache, { data: { AssetActivityPatch } }) => {
        // Related activities inside item page cache update
        RelatedActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPatch,
          accountId,
          nextStatus,
          CompletedDateTime,
        );
        // All activities page cache update
        const statusObjectFrom = props.getFilterString(
          statuses.filter((element) => element.Id === activity.StatusId)[0],
        );
        const statusObjectTo = props.getFilterString(
          statuses.filter((element) => element.Id === nextStatus)[0],
        );
        AllActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPatch,
          statusObjectFrom,
          statusObjectTo,
          accountId,
        );
      },
      refetchQueries,
      awaitRefetchQueries: true,
    })
      .then(({ data: { AssetActivityPatch } }) => {
        turnOffLoadingInRelativeActivityCache(client, accountId, activity, AssetActivityPatch);
        displaySuccessNotification(NotificationTypes.ACTIVITY_STATUS_UPDATED);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.ACTIVITY_STATUS_UPDATED_ERROR);
      });
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "activity",
    drop: (item: { type: string; activity: ActivityModelRelated }) => {
      updateActivityStatus(item.activity, props.StatusId);
    },
    canDrop: (item) => item.activity.StatusId !== props.StatusId && !isLoading,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Accordion
      className={`RelatedActivities__Accordion ${canDrop && isOver ? "is-active" : ""}`}
      label={label}
      onOpen={onOpen}
      onClose={onClose}
      isLoading={isLoading}
      dndRef={drop}
      expanded={expanded}
    >
      {props.children}
    </Accordion>
  );
};

const mapStateToProps = (state) => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];
  return {
    getFilterString: (status) => {
      const filterObj = {
        id: status.Id,
        name: status.Name,
        offset: getActivityFilters(state).get(status.Id) || offset(status),
      };
      return getFilterString(filterObj);
    },
  };
};

export default connect(mapStateToProps)(DropTargetRelatedActivities);
