import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {findDOMNode} from "react-dom";
import {useDrag} from "react-dnd";
import {ActivityModelRelated} from "../../assets/_components/relaitedActivities/ActivityModelRelated";
import {getEmptyImage} from "react-dnd-html5-backend";

interface RelatedActivityDragWrapperProps {
  activity: ActivityModelRelated;
  layout: string;
  statusName: string;
  browserWindowWidth: number;
}

const RelatedActivityDragWrapper: React.FC<RelatedActivityDragWrapperProps> = (props) => {
  const {activity, children, layout, statusName, browserWindowWidth} = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [{isDragging}, drag, preview] = useDrag({
    canDrag: !activity.isLoading,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    // TODO activity passed for custom dnd layer display,
    //  refactor passed values after refactor queries and mutations
    item: {type: "activity", activity, layout, statusName, width, height},
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  const attach = useCallback((domElement) => {
    drag(domElement);

    const node: any = findDOMNode(domElement);
    node ? setWidth(node.clientWidth) : setWidth(0);
    node ? setHeight(node.clientHeight) : setHeight(0);
  }, [layout, browserWindowWidth]);

  const className = `ActivityItem_wrapper ${isDragging ? "ActivityItem__whileDragPlaceholder" : ""}`;
  return (
    <div key={activity.Id} ref={attach} className={className}>
      {children}
    </div>
  );
};

export default RelatedActivityDragWrapper;
