import gql from "graphql-tag";

export const GET_ACTIVITIES_FOR_ITEM_REMOVE = gql`
  query getActivitiesList($Id: ID, $Filter: String!, $OrderBy: OrderByExpressionType, $Take: Int) {
    Account(Id: $Id) {
      Id
      AssetActivities(Filter: $Filter, OrderBy: $OrderBy, Take: $Take) {
        Name
        Id
        DueDateTime
        CompletedDateTime
        AssetId
        StatusId
        Schedule {
          RecurringType
        }
      }
    }
  }
`;

export const deleteAssetQuery = gql`
    mutation deleteAsset($AccountId: ID, $AssetId: ID) {
        AssetDelete(
            AccountId: $AccountId
            Id: $AssetId
        ) {
            Id
            AssetType {
                Id
                IsSystem
            }
        }
    }
`;
