import styled from "styled-components";
import GetCalendarDateButton from "./GetCalendarDateButton";

export const ActionBarButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GetCalendarButtonStyled = styled(GetCalendarDateButton)`
  &.MuiButtonBase-root {
    height: 32px;
    margin-right: 1rem;
    margin-top: 2px;
  }
`;
