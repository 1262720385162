import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { toastr } from "react-redux-toastr";

import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetUserId from "../../customHooks/useGetUserId";
import { useCreateCalendarFeedMutation, useUpdateCalendarFeedMutation } from "./calendarFeedHooks";

import { ALL_ACTIVITIES } from "../../_constant/constants";
import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";
import { CalendarConfig } from "../../pages/CalendarFeeds/CalendarConfig.types";

import { useMediaQuery, useTheme } from "@material-ui/core";
import CalendarFeedCardMobile from "./CalendarFeedCardMobile/CalendarFeedCardMobile";
import CalendarFeedCard from "./CalendarFeedCard/CalendarFeedCard";

export interface CalendarFeedContainerProps {
  imageUrl: string | null;
  name: string;
  isBusiness: boolean;
  id: string;
  isPersonal: boolean;
  personalImg: string;
  config: CalendarConfig | null | undefined;
}

export interface CalendarFeedCardProps extends CalendarFeedContainerProps {
  isImgExist: boolean;
  userId: string;
  usersLoading: boolean;
  accountUsers: any;
  selectHandler: (text: string) => void;
  createConfigHandler: () => void;
  assignValue: string;
  createLoading: boolean;
  updateLoading: boolean;
}

const CalendarFeedContainer: React.FC<CalendarFeedContainerProps> = ({
  isPersonal,
  config = null,
  id,
  imageUrl,
  ...props
}) => {
  const [assignValue, setAssignValue] = useState(ALL_ACTIVITIES);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const { accountId } = useGetAccountId();
  const userId = useGetUserId();

  const { data: accountUsers, loading: usersLoading } = useQuery(getAccountUsersQuery, {
    variables: { Id: accountId },
    skip: !accountId || !!isPersonal,
    fetchPolicy: "cache-first",
  });

  const [createConfig, { createLoading }] = useCreateCalendarFeedMutation();
  const [updateConfig, { updateLoading }] = useUpdateCalendarFeedMutation();

  const selectHandler = (text: string) => {
    if (config) {
      updateConfig({
        accountId: id,
        assignValue: text,
        configId: config.Id,
      });
    }
    setAssignValue(text);
  };

  const createConfigHandler = () => {
    if (config) {
      toastr.success("URL copied", "");
      window.navigator.clipboard.writeText(config.AbsoluteUri);
      return;
    }
    createConfig({
      accountId: id,
      assignValue: assignValue,
    });
  };

  const isImgExist = isPersonal || !!imageUrl;

  return isMobile ? (
    <CalendarFeedCardMobile
      isImgExist={isImgExist}
      selectHandler={selectHandler}
      usersLoading={usersLoading}
      accountUsers={accountUsers}
      createConfigHandler={createConfigHandler}
      createLoading={createLoading}
      updateLoading={updateLoading}
      isPersonal={isPersonal}
      config={config}
      id={id}
      imageUrl={imageUrl}
      assignValue={assignValue}
      userId={userId}
      {...props}
    />
  ) : (
    <CalendarFeedCard
      isImgExist={isImgExist}
      selectHandler={selectHandler}
      usersLoading={usersLoading}
      accountUsers={accountUsers}
      createConfigHandler={createConfigHandler}
      createLoading={createLoading}
      updateLoading={updateLoading}
      isPersonal={isPersonal}
      config={config}
      id={id}
      imageUrl={imageUrl}
      assignValue={assignValue}
      userId={userId}
      {...props}
    />
  );
};

export default CalendarFeedContainer;
