import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { useMutation, useQuery } from "react-apollo";
import EditProfileForm from "./components/EditProfileForm";
import { getUserInfoQuery, updateUserInfoQuery } from "./profile.query";
import { UserInfoModel } from "../../redux/models/data/UserInfoModel";
import { ID, USER, USER_PUT } from "../../_constant/wordings";
import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { useChange } from "../../customHooks/useChange";
import { validateLastName } from "../../_utils/utils";
import { ROUTES } from "../../_constant/screens";
import SaveButton from "../../components/SaveButton/SaveButton";
import { RevertDialogComponent } from "../_common/Dialog";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../assets/existing/EditAsset";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import { useNavigate } from "react-router-dom";
import Body from "../../components/Body/Body";
import Form from "../../components/Form/Form";
import ActionsBar from "../../components/ActionsBar/ActionsBar";
import { setEditFormIsDirty } from "../../redux/actions/actions";

interface EditProfilePageProps {}

const EditProfilePage: React.FC<EditProfilePageProps> = (props) => {
  const formInitObject = {
    initData: new UserInfoModel(),
    classModel: UserInfoModel,
    requiredFields: ["FirstName"],
    fieldsForValidation: new Map([
      ["FirstName", validateLastName],
      ["LastName", validateLastName],
    ]),
  }; // if null received instead of function - useChange used default one "validateNameField"
  const formApi = useChange<UserInfoModel>(formInitObject);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isAvailableForSaving, setAvailableForSaving] = useState(false);

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);

  useEffect(() => {
    const isChangedAndValid = formApi.isDataValid && formApi.isDataChanged;
    setAvailableForSaving(isChangedAndValid);
  }, [formApi.isDataValid, formApi.isDataChanged]);

  const { data, loading, error } = useQuery(getUserInfoQuery, {
    fetchPolicy: "network-only",
  });

  const [updateProfile, { loading: isSaving }] = useMutation(updateUserInfoQuery);

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    if (!loading && !error && data[USER]) {
      formApi.updateInitFormData(new UserInfoModel(data[USER]));
    }
  }, [data]);

  const { setHeaderOptions } = useHeaderOptions();

  useEffect(() => {
    setHeaderOptions({
      withBackButton: true,
      withSettingsNav: true,
      saveOptions: {
        isLoading: loading || isSaving,
        disabled: !isAvailableForSaving,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        revertFunction: openRevertDialog,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, isAvailableForSaving, loading, isSaving]);

  const handleSubmit = (e, fastSave = false) => {
    const saveType: SaveType = {
      type: fastSave ? SAVE : SAVE_AND_CLOSE,
      callback: () => formApi.updateInitFormData(formApi.data),
    };

    const profileData = { ...formApi.data };
    if (profileData.Images.length) {
      delete profileData.Images[0].__typename;
    }
    updateProfile({ variables: profileData })
      .then(({ data }) => {
        if (data[USER_PUT][ID] && saveType.type === SAVE) {
          saveType.callback(data[USER_PUT]);
          displaySuccessNotification(NotificationTypes.PROFILE_UPDATED);
        } else if (data[USER_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          displaySuccessNotification(NotificationTypes.PROFILE_UPDATED);
          navigate(-1);
        } else {
          displayErrorNotification(NotificationTypes.PROFILE_UPDATED_ERROR);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.PROFILE_UPDATED_ERROR);
      });
  };

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  return (
    <>
      {isRevertPopupVisible && (
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      )}
      <Body disableDesktopScroll>
        <Form>
          <Form header>
            <ActionsBar title="Edit Profile">
              <SaveButton
                saveAndClose={handleSubmit}
                isLoading={isSaving}
                revertData={openRevertDialog}
                isSaveAvailable={isAvailableForSaving || !!error}
                isNew={false}
                cancelRouteForEmptyHistory={ROUTES.ASSETS}
                isDataChanged={formApi.isDataChanged}
              />
            </ActionsBar>
          </Form>
          <Form body>
            <EditProfileForm
              profileData={formApi.data}
              handleFieldChange={formApi.handleChange}
              errorFields={formApi.errorFields}
              updateManyFields={formApi.updateManyFields}
            />
          </Form>
        </Form>
      </Body>
    </>
  );
};

export default EditProfilePage;
