import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    // runs on location, i.e. route, change
    window.scrollTo(0, 0);

    // console.log("handle route change here", location);
  }, [location]);

  return null;
}

export default ScrollToTop;
