import React from "react";

import { LayoutType } from "../../_constant/constants";

import AppsIcon from "@material-ui/icons/Apps";
import ViewDayOutlinedIcon from "@material-ui/icons/ViewDayOutlined";
import ViewDayIcon from "@material-ui/icons/ViewDay";
import PrintIcon from "@material-ui/icons/Print";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { LayoutSwitcherComponent } from "./LayoutSwitcher.style";
import { pageSwitcher } from "./useLayoutSwitcherContext";
interface LayoutSwitcherProps {
  layout: LayoutType;
  switchLayout: pageSwitcher;
  withPrintable?: boolean;
}

const LayoutSwitcher: React.FC<LayoutSwitcherProps> = ({
  layout,
  switchLayout,
  withPrintable = false,
}) => {
  return (
    <LayoutSwitcherComponent>
      <ButtonGroup variant="outlined">
        <Button onClick={switchLayout.toBoardView}>
          {layout === LayoutType.BOARD ? <AppsIcon color="primary" /> : <AppsIcon />}
        </Button>
        <Button onClick={switchLayout.toListView}>
          {layout === LayoutType.LIST ? <ViewDayIcon color="primary" /> : <ViewDayOutlinedIcon />}
        </Button>
        {withPrintable && (
          <Button onClick={switchLayout.toPrintableView}>
            {layout === LayoutType.PRINTABLE ? <PrintIcon color="primary" /> : <PrintIcon />}
          </Button>
        )}
      </ButtonGroup>
    </LayoutSwitcherComponent>
  );
};

export default LayoutSwitcher;
