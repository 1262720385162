import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import { textClip } from "../_styled/textClip";
import { scrollStyles } from "../_styled/scrollStyles";

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 13.25rem;
    height: calc(100% - 44px);
    margin-top: 44px;

    ${scrollStyles}
  }
`;

export const PaddingWrapper = styled.section`
  padding: 1rem;
`;

export const SectionHeaderText = styled(Typography)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  margin-right: -8px;
`;

interface ActiveListItemProps {
  isActive?: boolean;
}
export const AvatarStyled = styled(Avatar)<ActiveListItemProps>`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`;

export const ListItemTextStyled = styled(ListItemText)<ActiveListItemProps>`
  .MuiTypography-root {
    font-size: 0.875rem;
    font-weight: 400;

    ${textClip}
  }
`;

export const StyledListItem = styled(ListItem)<ActiveListItemProps>`
  .MuiTypography-root {
    color: ${({ isActive, theme }) => (isActive ? `${theme.palette.primary.main}` : null)};
  }

  .MuiAvatar-root {
    border: ${({ isActive, theme }) =>
      isActive ? `2px solid ${theme.palette.primary.main}` : null};
  }
`;
