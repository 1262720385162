import React from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Avatar from "@material-ui/core/Avatar/Avatar";
import PersonIcon from "@material-ui/icons/Person";

export const StyledTableHeader = styled((props) => <Grid {...props} />)`
  margin: 0;
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  width: 100%;
`;

export const TableCardStyled = styled((props) => <Grid {...props} />)`
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.greyDesign.onSurface3}`};
  &:first-child:not(:last-child) {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-bottom: none;
    &:not(:first-child) {
      border-radius: 0 0 8px 8px;
    }
  }
`;
type CardFields = "name" | "email" | "address" | "phone";

interface StyledTextProps {
  field: CardFields;
}

const getTextStyle = (field: CardFields) => {
  switch (field) {
    case "name":
      return `
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;`;

    case "email":
      return `
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: var(--color-gray);
      `;

    case "address":
    case "phone":
      return `
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
      `;
  }
};

const sliceStyle = css`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

export const StyledCardText = styled((props) => <Typography {...props} />)<StyledTextProps>`
  ${(props) => getTextStyle(props.field)};
  ${(props) => props.slice && sliceStyle}
`;

export const TableContentStyled = styled((props) => <Grid {...props} />)`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  &.MuiList-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

export const StyledSkeletonList = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export const CardAvatarStyled = styled(Avatar)`
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
  background-color: var(--color-bg-image);
`;

export const PersonIconStyled = styled(PersonIcon)`
  color: #6e94b5;
`;
