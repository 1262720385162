import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button/Button";

interface WeekButtonStyleProps {
  isSelected: boolean;
}
export const WeekButtonStyle = styled(({ isSelected, ...props }) => (
  <Button color={isSelected ? "primary" : "inherit"} {...props} />
))<WeekButtonStyleProps>`
  height: 40px;
  margin: ${({ isSelected }) => (isSelected ? "10px 0" : "0")};

  .MuiButton-label {
    font-size: ${({ isSelected }) => (isSelected ? "1.5rem" : "1rem")};
    font-weight: ${({ isSelected }) => (isSelected ? "500" : "400")};
  }
`;
