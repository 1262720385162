import gql from "graphql-tag";

export const GET_CUSTOMER_ASSETS = gql`
  query getCustomerAssets($AccountId: ID, $CustomerId: ID) {
    Customer(Id: $CustomerId, AccountId: $AccountId) {
      Id
      AssetActivities {
        Id
      }
      Assets {
        Id
        Name
        Description
        Images {
          Id
          Name
          FileExtension
          Description
          ContainerId
          ContainerName
          TotalBytes
        }
        AssetType {
          Id
          Name
          IconName
        }
        AssetActivities {
          Id
        }
        Schema {
          Id
          Name
          Order
          FieldType
        }
        CustomFields
      }
    }
  }
`;
