import * as React from "react";
import { Box } from "@material-ui/core";
import { useEntityTabsContext } from "./useEntityTabsContext";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { ReactNode, useEffect, useRef } from "react";
import { ControlContainer, SeparatorLine, TabStyle } from "./EntityTabsContainer.style";
import TabForNewEntity from "./TabForNewEntity";
import { ITabContent } from "../CustomerForm/CustomerFormDesktop";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { StyledSelect } from "./MobileFormContentSelectStyle";
import MenuItem from "@material-ui/core/MenuItem";

interface EntityTabsContainer {
  content: ITabContent[];
  startFrom?: "0" | "1" | "2" | "3";
  isCustomize?: boolean;
  isNew?: boolean;
  entityName: string;
  changeCustomize?: () => void;
  isDesktop: boolean;
  tabIndexAfterCreate: "0" | "1" | "2" | "3";
  renderSelect?: boolean;
  removeScrollButtons?: boolean;
  fillAvailableWidth?: boolean;
}

const NEW_ENTITY_PAGE = "NEW_ENTITY_PAGE";

export interface LocationState {
  justCreated?: boolean;
}

const EntityTabsContainer: React.FC<EntityTabsContainer> = ({
  content,
  startFrom,
  isCustomize,
  changeCustomize,
  isNew,
  entityName,
  isDesktop,
  tabIndexAfterCreate,
  renderSelect = false,
  removeScrollButtons = false,
  fillAvailableWidth = false,
}) => {
  const { currentTab, changeTab } = useEntityTabsContext();
  const indexCurrentTab = +currentTab;

  const location = useLocation();
  const locationState = location.state as LocationState;

  const prevTabNameRef = useRef(
    content[indexCurrentTab] ? content[indexCurrentTab]?.name : content[0]?.name,
  );
  const isCustomizeRef = useRef(isCustomize);

  useEffect(() => {
    if (startFrom && content[startFrom]) {
      changeTab(startFrom);
    }
  }, [startFrom]);

  useEffect(() => {
    if (locationState && locationState?.justCreated) {
      changeTab(tabIndexAfterCreate);
    }
  }, [locationState]);

  useEffect(() => {
    if (isCustomize !== isCustomizeRef.current) {
      const isPrevTabInNewContent = content.findIndex((tab) => tab.name === prevTabNameRef.current);
      if (isPrevTabInNewContent >= 0) {
        changeTab(isPrevTabInNewContent.toString());
      }
      isCustomizeRef.current = isCustomize;
    }
  }, [isCustomize, content]);

  useEffect(() => {
    prevTabNameRef.current = content[indexCurrentTab]?.name;
  }, [currentTab]);

  const handleChange = (event: any, newValue: any) => {
    changeTab(newValue);
  };
  const handleChangeMemoized = React.useCallback(handleChange, []);

  const renderTabsController = () => {
    if (content[indexCurrentTab] && content[indexCurrentTab].tabControl) {
      return content[indexCurrentTab].tabControl;
    }
    return null;
  };

  if (renderSelect) {
    return (
      <Box display="flex" flexDirection="column">
        <Box borderBottom="var(--border-style)">
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <StyledSelect
                value={currentTab}
                onChange={(e) => handleChangeMemoized(e, e.target.value)}
                disabled={isNew}
              >
                {content.map((tab, index) => {
                  return <MenuItem value={index}>{tab.name}</MenuItem>;
                })}
              </StyledSelect>
            </Grid>
            <Grid item>
              {content[indexCurrentTab]?.tabControl && content[indexCurrentTab]?.tabControl}
            </Grid>
          </Grid>
        </Box>
        <Box style={{ backgroundColor: "var(--color-bg-secondary)" }}>
          {content[indexCurrentTab]?.content}
        </Box>

        <div className="EntityTabsContainer">
          <TabContext value={isNew ? NEW_ENTITY_PAGE : currentTab}>
            {isNew && (
              <TabPanel key={NEW_ENTITY_PAGE} value={NEW_ENTITY_PAGE}>
                <TabForNewEntity entityName={entityName} />
              </TabPanel>
            )}
          </TabContext>
        </div>
      </Box>
    );
  }

  return (
    <div className="EntityTabsContainer">
      <TabContext value={isNew ? NEW_ENTITY_PAGE : currentTab}>
        <Box
          className="EntityTabsContainer__Box"
          display="flex"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <TabList
            onChange={handleChangeMemoized}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={removeScrollButtons ? "off" : "on"}
            style={{ minWidth: fillAvailableWidth ? "100%" : "auto" }}
          >
            {content.map((tab, i) => {
              return (
                <TabStyle
                  key={tab.name}
                  label={tab.name}
                  value={tab.name === NEW_ENTITY_PAGE ? NEW_ENTITY_PAGE : i.toString()}
                  disabled={tab.disabled}
                  hidden={tab.name === NEW_ENTITY_PAGE}
                  $fillAvailableWidth={fillAvailableWidth}
                />
              );
            })}
          </TabList>

          <ControlContainer>
            {content[indexCurrentTab] && content[indexCurrentTab].tabControl && <SeparatorLine />}
            {renderTabsController()}
          </ControlContainer>
        </Box>
        {content.map((tab, i) => (
          <TabPanel key={tab.name} value={i.toString()}>
            {tab.content}
          </TabPanel>
        ))}
        {isNew && (
          <TabPanel key={NEW_ENTITY_PAGE} value={NEW_ENTITY_PAGE}>
            <TabForNewEntity entityName={entityName} />
          </TabPanel>
        )}
      </TabContext>
    </div>
  );
};

export default EntityTabsContainer;
