import React from "react";
import MUISkeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

interface SkeletonProps {
  count?: number;
}

const StyledSkeleton = styled(MUISkeleton)`
  width: 100%;
  height: 56px;
  border-radius: 8px;

  & + & {
    margin-top: 8px;
  }
`;

const Skeleton: React.FC<SkeletonProps> = ({ count }) => {
  return (
    <div>
      {Array(count || 3)
        .fill(null)
        .map((_, index) => {
          return <StyledSkeleton key={index} variant="rect" />;
        })}
    </div>
  );
};

export default Skeleton;
