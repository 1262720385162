import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";
import { ACCOUNT, USER_REFS } from "../../_constant/wordings";
import { Users } from "../../redux/models/data/UsersModel";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";

export const updateMembersCache = (
  cache: DataProxy,
  accountIdRemoveFrom: string,
  IdForRemove: string,
) => {
  const accountUsers: any = cache.readQuery({
    query: getAccountUsersQuery,
    variables: { Id: accountIdRemoveFrom },
  });
  const newUserRefs = accountUsers[ACCOUNT][USER_REFS].filter(
    (user: Users) => user.Id !== IdForRemove,
  );

  cache.writeQuery({
    query: getAccountUsersQuery,
    variables: { Id: accountIdRemoveFrom },
    data: {
      Account: { Id: accountIdRemoveFrom, __typename: "Account", UserRefs: newUserRefs },
    },
  });
};
