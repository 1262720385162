import React from "react";
import MUIPopover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";

interface PopoverProps {
  target?: React.ReactNode;
  content: (handler: () => void) => React.ReactNode;
}

export const Popover: React.FC<PopoverProps> = ({ target, content }: PopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box aria-describedby={id} display="flex" onClick={handleClick}>
        {target}
      </Box>

      <MUIPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box aria-label="main mailbox folders">{content(handleClose)}</Box>
      </MUIPopover>
    </>
  );
};
