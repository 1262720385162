import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ROUTES } from "../../../_constant/screens";

import { Button } from "@material-ui/core";
import useGetAccountId from "../../../customHooks/useGetAccountId";

interface WizardFinalScreenFooterProps {
  navigate: (backward: boolean) => void;
  handleClose: () => void;
  customerId: string;
  isInsideAddActivitySection: boolean;
  parentId: string;
}

const WizardFinalScreenFooter: React.FC<WizardFinalScreenFooterProps> = ({
  // navigate,
  handleClose,
  customerId,
  isInsideAddActivitySection,
  parentId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { IsCustomerManagement } = useGetAccountId();

  const isVisitPage = location.pathname.includes(ROUTES.SINGLE_VISIT);

  const redirectToActivities = () => {
    navigate(ROUTES.ACTIVITIES);
    handleClose();
  };

  const redirectToVisits = () => {
    navigate(ROUTES.CREATE_VISIT, {
      state: { customerId },
    });
    handleClose();
  };

  const layoutForBusiness = () => (
    <div>
      {!isVisitPage && IsCustomerManagement && (
        <Button onClick={redirectToVisits} color="primary">
          Plan Visit
        </Button>
      )}
      <Button onClick={handleClose} color="primary">
        Done
      </Button>
    </div>
  );

  const defaultLayout = () => (
    <div>
      <Button onClick={redirectToActivities}>Activities List</Button>
      <Button onClick={handleClose}>Item List</Button>
    </div>
  );

  return IsCustomerManagement || isInsideAddActivitySection || parentId
    ? layoutForBusiness()
    : defaultLayout();
};

export default WizardFinalScreenFooter;
