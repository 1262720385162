import * as React from "react";
import { ChangeEvent } from "react";
import { useState, useEffect } from "react";

import moment from "moment";

import { statusClassName } from "../../_utils/utils";

import Radio from "../Radio";
import Select from "../Select/Select";
import SelectItem from "../SelectItem/SelectItem";

import { SORTING_DETAILS } from "../../_constant/ActivityDateConstant";
import { ActivitiesFieldName } from "../../_constant/constants";

import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import useGetUserId from "../../customHooks/useGetUserId";

import * as statusesConfig from "../../../configs/statuses-config.json";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetProjectsStatuses from "../../customHooks/api/useGetProjectsStatuses";
const { EMPTY } = statusesConfig["statusNameMap"];

interface StatusSelectProps {
  statusId: string;
  fieldName?: string;
  onChange: (e: ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  assignedUserId: string | null;
}

const StatusSelect: React.FC<StatusSelectProps> = (props) => {
  const [statusOptions, setStatuses] = useState<Map<string, any>>(new Map());

  const userId = useGetUserId();
  const { isPersonal } = useGetAccountId();

  const fieldName = props.fieldName ?? "ProjectStatusId";

  const statuses = useGetProjectsStatuses();
  useEffect(() => {
    if (statuses.length > 0) {
      const newProjectsMap = statuses.map((item: { Id: string }) => [item.Id, item]);
      setStatuses(new Map(newProjectsMap));
    }
  }, [statuses]);

  const handleStatusSelect = ({ target }: any) => {
    if (!statuses.length) return;
    // Set Completed Date when Done status selected
    const nameOfStatus = statusOptions.get(target.value)
      ? statusOptions.get(target.value).Name
      : "";

    const date =
      SORTING_DETAILS?.get(nameOfStatus)?.["field"] === ActivitiesFieldName.COMPLETED_DATE_TIME
        ? moment().toISOString()
        : null;
    props.onChange(null, { id: fieldName, value: target.value });
    props.onChange(null, { id: ActivitiesFieldName.COMPLETED_DATE_TIME, value: date });

    const selectedStatusName = statusOptions.get(target.value)?.Name || "";

    if (
      !isPersonal &&
      !props.assignedUserId &&
      selectedStatusName &&
      selectedStatusName !== EMPTY
    ) {
      props.onChange(null, { id: ActivitiesFieldName.ASSIGNED_USER_ID, value: userId });
    }
  };

  const currentStatus = statusOptions.get(props.statusId) || {
    Id: "",
    Name: "",
  };

  return (
    <>
      <Select
        type="text"
        value={currentStatus.Id}
        onChange={handleStatusSelect}
        label="Status"
        fullWidth
        media={
          <Radio
            value={true}
            className={statusClassName(currentStatus.Name)}
            onChange={() => {}}
            isStatusIndicator
          />
        }
        disableMediaGutters
      >
        {statuses.length ? (
          statuses.map(({ Id, Name }: any) => (
            <MenuItem value={Id} key={Id}>
              <SelectItem>{Name}</SelectItem>
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <CircularProgress size={24} style={{ margin: "0 auto" }} />
          </MenuItem>
        )}
      </Select>
    </>
  );
};

export default StatusSelect;
