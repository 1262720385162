import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { useNavigate } from "react-router-dom";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { AssetModel } from "../../redux/models/data/AssetModel";
import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";

import { WIZARD_SCREENS_NAME } from "../Wizard/WizardContainer/WizardConstants";
import { CUSTOMER, ITEMS } from "../../_constant/wordings";
import { addCustomerIdToUrl } from "../../_utils/utils";
import { ROUTES } from "../../_constant/screens";
import { GET_CUSTOMER_ASSETS } from "./getCustomerAssets.query";

import { CircularProgress, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import AssetCardRow from "../Cards/AssetCardRow";
import AssetCardInfo from "../AssetCardAdditionalInfo/AssetCardInfo";
import WizardContainer from "../Wizard/WizardContainer/WizardContainer";
import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";
import CreateNewButtonMobile, {
  CreateNewEntityContainer,
} from "../CreateNewButtonMobile/CreateNewButtonMobile";
import Hidden from "@material-ui/core/Hidden";

interface ItemsTabProps {
  customerId: string;
  isWizardOpen: boolean;
  handleCloseDialog: () => void;
  isCustomerLoading: boolean;
  visitId?: string;
  withCreateButton?: boolean;
  handleOpenWizard?: () => void;
}

const ItemsTab: React.FC<ItemsTabProps> = (props) => {
  const {
    customerId,
    isWizardOpen,
    handleCloseDialog,
    isCustomerLoading,
    visitId = "",
    withCreateButton = false,
    handleOpenWizard,
  } = props;

  const { accountId } = useGetAccountId();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const theme = useTheme();

  const onAssetCardClick = (assetId: string) => {
    navigate(`${ROUTES.SINGLE_ASSET}/${assetId}`, {
      state: { visitId: visitId },
    });
  };

  const { data: assetData, loading: assetLoading, error: assetError } = useQuery(
    GET_CUSTOMER_ASSETS,
    {
      variables: {
        AccountId: accountId,
        CustomerId: customerId,
      },
      skip: !customerId || !accountId,
      fetchPolicy: "cache-first",
    },
  );

  useEffect(() => {
    if (!isCustomerLoading && !assetLoading && assetData) {
      setLoading(false);
    }
  }, [isCustomerLoading, assetLoading, assetData]);

  if (loading) {
    return (
      <div style={{ height: "100%", flexGrow: 1, padding: "1rem", textAlign: "center" }}>
        <CircularProgress size={22} />
      </div>
    );
  }

  const handleCreate = () => {
    if (handleOpenWizard) {
      handleOpenWizard();
    }
  };

  const assetList = assetData && assetData?.[CUSTOMER]?.[ITEMS] ? assetData[CUSTOMER][ITEMS] : [];

  return (
    <>
      <WizardContainer
        isOpen={isWizardOpen}
        handleClose={handleCloseDialog}
        startStep={WIZARD_SCREENS_NAME.type_select}
        customerId={customerId}
      />
      <Box>
        {withCreateButton && !!assetList.length && (
          <CreateNewEntityContainer>
            <CreateNewButtonMobile handleCreate={handleCreate} disabled={!customerId}>
              Item
            </CreateNewButtonMobile>
          </CreateNewEntityContainer>
        )}
        {assetList.length ? (
          <Box padding="1rem" paddingTop="">
            <Box overflow="hidden" borderRadius="8px" border={`1px solid ${theme.palette.greyDesign.onSurface3}`}>
              {assetList.map((item: AssetModel) => {
                const convertedAsset: AssetRefsModel = new AssetRefsModel({
                  ...item,
                  AssetTypeRef: item.AssetType,
                  MainImage: item.Images && item.Images.length ? item.Images[0] : null,
                  HasActivities: item.AssetActivities.length > 0,
                });
                return (
                  <AssetCardRow
                    item={convertedAsset}
                    key={item.Id}
                    additionalInfo={
                      <AssetCardInfo item={convertedAsset} assetActivities={item.AssetActivities} />
                    }
                    onClick={visitId ? () => onAssetCardClick(item.Id) : null}
                    customerId={customerId}
                  />
                );
              })}
            </Box>
          </Box>
        ) : (
          <>
            <Box padding="1rem" paddingTop="">
              <EmptyCustomFieldState emptyFieldsMessage="There are no items" />
            </Box>
            {withCreateButton && (
              <Hidden smUp>
                <CreateNewButtonMobile handleCreate={handleCreate} disabled={!customerId} />
              </Hidden>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ItemsTab;
