import React from "react";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router-dom";

import useGetAccountId from "../../../customHooks/useGetAccountId";

import { deleteVendorQuery } from "./deleteVendor.query";
import { updateCacheAfterDeleteVendor } from "./updateCacheAfterDeleteVendor";

import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";
import { NotificationTypes } from "../../../_constant/NotificationTypes";

import { DeleteDialog } from "../../../containers/_common/Dialog";

interface VendorDeleteDialogProps {
  id: string | null;
  handleCancel: () => void;
  withoutRedirect?: boolean;
}

const VendorDeleteDialog: React.FC<VendorDeleteDialogProps> = (props) => {
  const { id, handleCancel, withoutRedirect } = props;

  const { accountId } = useGetAccountId();
  const navigate = useNavigate();

  const [removeVendor, { loading: isRemoving }] = useMutation(deleteVendorQuery);

  const handleDelete = () => {
    if (!id || !accountId) {
      return;
    }
    removeVendor({
      variables: { Id: id },
      update: (cache, { data: { VendorDelete } }) => {
        updateCacheAfterDeleteVendor(cache, VendorDelete, accountId);
      },
    })
      .then(() => {
        if (!withoutRedirect) {
          navigate(-1);
        }
        displaySuccessNotification(NotificationTypes.VENDOR_DELETED);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.VENDOR_DELETE_ERROR);
        handleCancel();
      });
  };

  return (
    <DeleteDialog isDataLoading={isRemoving} cancel={handleCancel} submit={handleDelete}>
      {"After deleting this vendor, you won't be able to undo this action."}
    </DeleteDialog>
  );
};

export default VendorDeleteDialog;
