import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { useChange } from "../../customHooks/useChange";

import { InviteFormModel } from "./inviteFormModel";

import SendIcon from "@material-ui/icons/Send";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LibraryAddOutlinedIcon from "@material-ui/icons/LibraryAddOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InvitationMutation } from "./invitationMutation.query";
import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";

import { validateEmailField } from "../../_utils/utils";
import { updateCacheWithNewInvitation } from "./updateCacheWithNewInvitation";

import Section from "../Section/Section";
import InputRow from "../InputRow/InputRow";
import InputField from "../InputField/InputField";

import "./InviteForm.scss";

interface InviteFormProps {
  accountId: string;
  maxMembers?: number;
}

const InviteForm: React.FC<InviteFormProps> = ({ accountId, maxMembers }) => {
  const [errorFields, setErrorFields] = useState(new Map());
  const [disableSubmit, setDisableSubmit] = useState(false);

  const formInitObject = {
    initData: new InviteFormModel(),
    classModel: InviteFormModel,
    requiredFields: ["MembersName", "MembersEmail"],
    fieldsForValidation: new Map([
      ["MembersName", null],
      ["MembersEmail", validateEmailField],
    ]),
  }; // if null received instead of function - useChange used default one "validateNameField"
  const formApi = useChange<InviteFormModel>(formInitObject);

  const handleMutationComplete = (data: any) => {
    toastr.success(
      "",
      `${formApi.data.MembersName} (${formApi.data.MembersEmail}) was invited to account`,
    );
    formApi.updateInitFormData(formInitObject.initData);
  };

  const handleMutationError = (error: any) => {
    // error message contains 'GraphQL.ExecutionError' substring on dev
    const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
      ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
      : error.networkError?.result?.errors[0]?.message;
    toastr.error("", message);
  };

  const [sendInvite, { loading }] = useMutation(InvitationMutation, {
    variables: {
      AccountId: accountId,
      InvitedUserName: formApi.data.MembersName,
      InvitedUserEmail: formApi.data.MembersEmail,
      Message: formApi.data.InviteMessage,
    },
    update(cache, { data: { InviteUser } }) {
      updateCacheWithNewInvitation(cache, InviteUser, accountId);
    },
    onError: handleMutationError,
    onCompleted: handleMutationComplete,
  });

  const { data: membersData, loading: isMembersLoading, error: membersError } = useQuery(
    getAccountUsersQuery,
    {
      variables: { Id: accountId },
      skip: !accountId,
      fetchPolicy: "network-only",
    },
  );

  const handleSubmit = () => {
    formApi.validateFields();
    setErrorFields(new Map(formApi.errorFields));

    if (isMembersLoading || membersError || !membersData || !maxMembers) return;

    if (formApi.errorFields.size || errorFields.size) return;

    if (membersData?.Account?.UserRefs?.length > maxMembers) {
      setDisableSubmit(true);
      toastr.warning("", `You can invite ${maxMembers} person`);
      return;
    }
    sendInvite();
  };

  // trigger formApi errorHandling, when no changes was done
  useEffect(() => {
    formApi.handleChange(null, { id: "MembersEmail", value: "" });
    formApi.handleChange(null, { id: "MembersName", value: "" });
  }, []);

  useEffect(() => {
    setDisableSubmit(loading || !accountId || isMembersLoading);
  }, [loading, accountId, isMembersLoading]);

  const handleChange = (e: React.SyntheticEvent<Element, Event>) => {
    setErrorFields(new Map());
    formApi.handleChange(e);
  };

  return (
    <Section background="default" withBorder>
      <InputRow
        alignItems="flex-end"
        actionZoneComponent={() => (
          <IconButton
            style={{ marginBottom: "-8px", marginRight: "-8px", padding: "8px" }} // align to right side
            onClick={handleSubmit}
            color="primary"
            disabled={disableSubmit}
          >
            {loading || isMembersLoading ? <CircularProgress size={22} /> : <SendIcon />}
          </IconButton>
        )}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputField
              type="text"
              handleChange={handleChange}
              value={formApi.data.MembersName}
              id="MembersName"
              label="Member's Name"
              fullWidth
              icon={<LibraryAddOutlinedIcon />}
              errorMessage={errorFields.get("MembersName")}
              error={!!errorFields.get("MembersName")}
              autoComplete="name? username? nickname?"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              type="email"
              handleChange={handleChange}
              value={formApi.data.MembersEmail}
              id="MembersEmail"
              label="Member's Email"
              fullWidth
              icon={<EmailOutlinedIcon />}
              errorMessage={errorFields.get("MembersEmail")}
              error={!!errorFields.get("MembersEmail")}
              maxLength="320"
              autoComplete="email"
            />
          </Grid>
        </Grid>
      </InputRow>
      <InputField
        type="text"
        handleChange={formApi.handleChange}
        value={formApi.data.InviteMessage}
        id="InviteMessage"
        label="Invitation Message"
        fullWidth
        icon={<DescriptionOutlinedIcon />}
        maxLength={1000}
        multiline
      />
    </Section>
  );
};

export default InviteForm;
