import * as React from "react";
import { AssetConfirmDialog } from "../../containers/_common/Dialog";
import { ActivityModelRelated } from "../../containers/assets/_components/relaitedActivities/ActivityModelRelated";
import RelatedItemsList from "./RelatedItemsList";
import { AssetModel } from "../../redux/models/data/AssetModel";
import Switch from "@material-ui/core/Switch";

interface DeleteAssetPopupProps {
  lockDeleteButton: boolean;
  handleSubmit: () => void;
  handlePopupClose: () => void;
  confirmContent: React.ReactNode;
  buttonConfirmLabel: string;
  relatedEntityName?: string;
  relatedEntityCount: number | string;
  relatedPartsLabel?: string;
  relatedPartsCount?: number | string;
  relatedParts?: AssetModel[];
  checked?: boolean;
  handleSwitch?: () => void;
}

const AssetConfirmPopup: React.FC<DeleteAssetPopupProps> = (props) => {
  const {
    confirmContent,
    relatedEntityName = "Activities",
    children,
    relatedEntityCount,
    relatedPartsLabel,
    relatedPartsCount,
    relatedParts,
    checked,
    handleSwitch,
  } = props;
  return (
    <AssetConfirmDialog
      header={
        <>
          {" "}
          <i className="Icon">error_outline</i> Are You Sure?{" "}
        </>
      }
      isDataLoading={props.lockDeleteButton}
      cancel={props.handlePopupClose}
      submit={props.handleSubmit}
      buttonConfirmLabel={props.buttonConfirmLabel}
    >
      <div className="DeleteAsset">
        <section className="DeleteAsset_content">
          {confirmContent}
          <div
            className={`DeleteAssetActivities ${
              relatedPartsCount && relatedEntityCount ? "DeleteAssetActivities_double-entity" : ""
            }`}
          >
            {relatedPartsCount > 0 && (
              <>
                <div className="DeleteAssetActivities_header DeleteAssetActivities_parts-header">
                  <div>
                    Related {relatedPartsLabel} <span className="Label">({relatedPartsCount})</span>
                  </div>
                  <div className="switch-container">
                    <span>Orphan all parts</span>
                    <Switch
                      checked={checked}
                      onChange={handleSwitch}
                      name="del-all-parts-toggle"
                      color="primary"
                      size="small"
                    />
                    <span>Delete all parts</span>
                  </div>
                </div>
                <div className="DeleteAssetActivities_content">
                  <div className="RelatedActivities_list">
                    <RelatedItemsList
                      relatedItems={relatedParts}
                      disableNavigationByClick
                      hideContextMenu
                    />
                  </div>
                </div>
              </>
            )}

            {relatedEntityCount > 0 && (
              <>
                <div className="DeleteAssetActivities_header">
                  Related {relatedEntityName} <span className="Label">({relatedEntityCount})</span>
                </div>
                <div className="DeleteAssetActivities_content">
                  <div className="RelatedActivities_list">{children}</div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </AssetConfirmDialog>
  );
};

export default AssetConfirmPopup;
