import React from "react";

import { StyledListItem, StyledListItemAvatar, StyledListItemText } from "./SelectItemStyles";

interface SelectItemProps {
  media?: React.ReactElement;
}

const SelectItem: React.FC<SelectItemProps> = ({ media, children }) => {
  return (
    <StyledListItem>
      {media && <StyledListItemAvatar>{media}</StyledListItemAvatar>}
      <StyledListItemText primary={children} />
    </StyledListItem>
  );
};

export default SelectItem;
