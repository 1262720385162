import { useEffect, useState } from "react";
import { useQuery } from "react-apollo";

import { getVendorsQuery } from "../components/AllVendorsWrapper/getVendors.query";
import { VendorModel } from "../redux/models/data/VendorModel";
import { alphabetNameSorting } from "../_utils/utils";

import useGetAccountId from "./useGetAccountId";

export const useGetActivityVendors = (vendorRefs: VendorModel[]) => {
  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(getVendorsQuery, {
    variables: { AccountId: accountId },
    skip: !accountId,
  });
  const [vendorsList, setVendorsList] = useState<VendorModel[]>([]);
  const [allVendorsOptions, setAllVendorsOptions] = useState([]);

  useEffect(() => {
    if (data) {
      const allVendors: VendorModel[] = data.Vendors;
      const activityVendorRefs = vendorRefs.map(({ Id }) => Id);

      const toRender: VendorModel[] = allVendors
        .filter(({ Id }) => activityVendorRefs?.includes(Id))
        .sort(alphabetNameSorting);

      setAllVendorsOptions(
        // @ts-ignore
        data?.Vendors.filter(({ Id }) => !activityVendorRefs.includes(Id))
          .sort(alphabetNameSorting)
          .map(({ Id, Name }) => ({
            value: Id,
            label: Name,
          })),
      );
      setVendorsList(toRender);
    }
  }, [loading, vendorRefs?.length]);

  return { vendorsList, allVendorsOptions, loading };
};
