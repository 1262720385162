import * as React from "react";
import moment from "moment";
import {ActivityScheduleModel} from "../../../../../../../redux/models/data/ActivityScheduleModel";
import DayOfWeekSelect from "./DayOfWeekSelect";
import DayInMonthInput from "./DayInMonthInput";
import {FullActivityDueDate} from "../../../../../../../redux/models/data/ActivityModelFull";
import {
  RepeatAfter,
  optionsNumberOfWeekInMonth,
  RepeatEvery,
  RepeatType,
  optionsDaysOfWeek
} from "../../../../../../../_constant/constants";
import {getJsDate, getWeekOfMonth} from "../../../../../../../_utils/date";
import {ChangeEvent, useContext} from "react";
import {DateContext} from "../../DueDateComponent";
import {FormControl, FormControlLabel, RadioGroup} from "@material-ui/core";
import RadioButton from "../../../../../../../components/RadioButton";
import DayOfWeekButtonGroup from "./DayOfWeekButtonGroup";

interface RecurringTypeFormProps {
  onChange: (value: FullActivityDueDate) => void;
  radioValue: string;
  setRadioValue: (value: string) => void;
}

export default function RecurringTypeForm(props: RecurringTypeFormProps) {
  const {dueDate, schedule} = useContext(DateContext);
  // initial values for selects
  const initialWeekInMonth = optionsNumberOfWeekInMonth[getWeekOfMonth(dueDate)];
  const initialDayOfWeek = optionsDaysOfWeek[getJsDate(dueDate).getDay()];

  const handleRadioSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const nextSchedule = new ActivityScheduleModel(schedule);
    nextSchedule.RecurringRepeatType = value === RepeatAfter.COMPLETED_DATE ? value : RepeatAfter.DUE_DATE;
    props.onChange({schedule: nextSchedule});
    props.setRadioValue(value);
  };

  const handleRadioSwitchWithDate = ({value}: { value: string }) => {
    const nextSchedule = new ActivityScheduleModel(schedule);
    if (!!schedule.WeekInMonth) {
      nextSchedule.WeekInMonth = null;
      nextSchedule.DayOfWeek = null;
    }
    if (schedule.RecurringType === RepeatType.WEEK) {
      nextSchedule.WeekInMonth = null;
      nextSchedule.DayOfWeek = initialDayOfWeek.value;
    }
    if (value === RepeatEvery.DAY_OF_WEEK_IN_MONTH) {
      nextSchedule.DayOfWeek = initialDayOfWeek.value;
      nextSchedule.WeekInMonth = optionsNumberOfWeekInMonth[getWeekOfMonth(dueDate)].value;
    }
    nextSchedule.RecurringRepeatType = value === RepeatAfter.COMPLETED_DATE ? value : RepeatAfter.DUE_DATE;
    props.onChange({dueDate: dueDate || moment().toISOString(), schedule: nextSchedule});
    props.setRadioValue(value);
  };

  const monthRadioGroup = () => (
    <RadioGroup
      aria-label="Repeat after"
      name="repeatAfter"
      value={props.radioValue}
    >
      <FormControlLabel
        value={RepeatAfter.COMPLETED_DATE}
        control={
          <RadioButton onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioSwitch(e)}
          />
        }
        label="After Completion Date"
      />
      <FormControlLabel
        value={RepeatEvery.DAY_IN_MONTH}
        control={
          <RadioButton
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioSwitchWithDate({value: e.target.value})}
          />
        }
        label={
          <DayInMonthInput
            value={dueDate}
            onChange={({dueDate, schedule}) => {
              props.onChange({dueDate, schedule});
              props.setRadioValue(RepeatEvery.DAY_IN_MONTH);
            }}
            additionalProps={schedule}
          />
        }
      />
      <div style={{display: "flex"}}>
        <FormControlLabel
          value={RepeatEvery.DAY_OF_WEEK_IN_MONTH}
          control={
            <RadioButton
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioSwitchWithDate({value: e.target.value})}
            />}
          label=""
          className="RadioButton__label"
        />
        <DayOfWeekSelect
          dueDate={dueDate}
          additionalProps={schedule}
          initialWeekInMonth={initialWeekInMonth}
          initialDayOfWeek={initialDayOfWeek}
          onChange={({dueDate, schedule}) => {
            props.onChange({dueDate, schedule});
            props.setRadioValue(RepeatEvery.DAY_OF_WEEK_IN_MONTH);
          }}
        />
      </div>
    </RadioGroup>
  );

  const weekRadioGroup = () => (
    <RadioGroup
      aria-label="Repeat after"
      name="repeatAfter"
      value={props.radioValue}
    >
      <FormControlLabel
        value={RepeatAfter.COMPLETED_DATE}
        control={<RadioButton onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioSwitch(e)}/>}
        label="After Completion Date"
      />
      <FormControlLabel
        value={RepeatAfter.DUE_DATE}
        control={
          <RadioButton
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioSwitchWithDate({value: e.target.value})}
          />
        }
        style={{position: "relative", paddingBottom: "30px"}}
        label={
          <DayOfWeekButtonGroup
            dueDate={dueDate}
            additionalProps={schedule}
            initialWeekInMonth={initialWeekInMonth}
            initialDayOfWeek={initialDayOfWeek}
            onChange={({dueDate, schedule}) => {
              props.onChange({dueDate, schedule});
              props.setRadioValue(RepeatAfter.DUE_DATE);
            }}
          />}
      />
    </RadioGroup>
  );

  const renderByRecurringType = () => {
    if (schedule.RecurringType === RepeatType.MONTH) {
      return monthRadioGroup();
    }
    if (schedule.RecurringType === RepeatType.WEEK) {
      return weekRadioGroup();
    } else {
      return (
        <RadioGroup
          aria-label="Repeat after"
          name="repeatAfter"
          value={props.radioValue}
          onChange={(e) => handleRadioSwitch(e)}>
          <FormControlLabel value={RepeatAfter.COMPLETED_DATE} control={<RadioButton/>} label="After Completion Date"/>
          <FormControlLabel value={RepeatAfter.DUE_DATE} control={<RadioButton/>} label="After Due Date"/>
        </RadioGroup>
      )
    }
  };

  return (
    <FormControl component="fieldset" className={"RadioGroup"}>
      {renderByRecurringType()}
    </FormControl>
  );
}
