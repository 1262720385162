import gql from "graphql-tag";

export const getNotificationLabels = gql(`
query getNotificationLabels {
  SystemData {
    NotificationFrequencyDefinitions {
      Id
      Title
      Value
    }
    NotificationTypeDefinitions {
      AllowedFrequencies {
        Id
      }
      Id
      SortOrder
      Title
      Value
    }
  }
}
`);
