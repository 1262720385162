import React from "react";
import "./Body.scss";
import MobileNavigation from "../MobileNavigation/MobileNavigation";

interface BodyProps {
  disableDesktopScroll?: boolean;
  disableMobileScroll?: boolean;
  disableFilterPadding?: boolean;
  withBottomPadding?: boolean;
  withMobileNavigation?: boolean;
}

const Body: React.FC<BodyProps> = ({
  children,
  disableDesktopScroll = false,
  disableMobileScroll = false,
  disableFilterPadding = false,
  withBottomPadding = false,
  withMobileNavigation = false,
}) => {
  return (
    <>
      <div
        className={`Body${disableDesktopScroll ? " Body--disableDesktopScroll" : ""}${
          disableMobileScroll ? " Body--disableMobileScroll" : ""
        }${disableFilterPadding ? " Body--disableFilterPadding" : ""}${
          withBottomPadding ? " Body--bottomPadding" : ""
        }${withMobileNavigation ? " Body--withMobileNavigation" : ""}`}
      >
        {children}
      </div>
      <MobileNavigation />
    </>
  );
};

export default Body;
