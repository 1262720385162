import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";

import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";

import Button from "@material-ui/core/Button/Button";
import AddIcon from "@material-ui/icons/Add";

import { ROUTES } from "../../_constant/screens";

import AllCustomersWrapper from "../../components/Customers/AllCustomersWrapper";
import ActionsBar from "../../components/ActionsBar/ActionsBar";
import Body from "../../components/Body/Body";

const Customers = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const { setHeaderOptions } = useHeaderOptions();

  useEffect(() => {
    if (isDesktop) {
      setHeaderOptions({ withoutMenuButton: true });
    }
  }, [isDesktop]);

  const onAddButtonHandler = () => {
    navigate(ROUTES.CREATE_CUSTOMER);
  };

  useHeaderOptions({
    pageTitle: "Customers",
    withAddButton: true,
    addButtonCallback: onAddButtonHandler,
    headerType: "BUSINESS",
  });

  const addButton = (
    <Button
      component={Link}
      to={ROUTES.CREATE_CUSTOMER}
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
    >
      Customer
    </Button>
  );

  return (
    <Body withMobileNavigation>
      <Container maxWidth="md" className="Accounts">
        <Hidden only="xs">
          <ActionsBar title="All Customers" withSearch>
            {addButton}
          </ActionsBar>
        </Hidden>
        <AllCustomersWrapper />
      </Container>
    </Body>
  );
};

export default Customers;
