import { getActivitiesRelatedToProjectQuery } from "../ActivitiesTabProject/getActivitiesRelatedToProject.query";

import { DataProxy } from "apollo-cache/lib/types/DataProxy";

export const updateActivitiesCache = (
  cache: DataProxy,
  newActivities: any[],
  projectId: string | null | undefined,
) => {
  try {
    cache.writeQuery({
      query: getActivitiesRelatedToProjectQuery,
      variables: { ProjectId: projectId },
      data: {
        Project: {
          Id: projectId,
          AssetActivities: newActivities,
          __typename: "ProjectType",
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};
