import React, { useState } from "react";
import InputField from "../InputField/InputField";
import DayAndTimePicker from "../DayAndTimePicker/DayAndTimePicker";
import moment from "moment";
import { TimePicker } from "@material-ui/pickers";
import { DateType } from "@date-io/type";

interface DayAndTimeInputBtnProps {
  date: moment.Moment;
  withDayOfWeek: boolean;
  disabled: boolean;
  changeTimeValue: (date: moment.Moment | null) => void;
}

const DayAndTimeInputBtn: React.FC<DayAndTimeInputBtnProps> = ({
  date,
  withDayOfWeek,
  changeTimeValue,
  disabled,
}) => {
  const [open, setOpen] = useState(false);

  const format = withDayOfWeek ? "ddd, hh:mm a" : "hh:mm a";

  const handleDialog = () => {
    if (!disabled) {
      setOpen((prev) => !prev);
    }
  };
  return (
    <>
      {withDayOfWeek ? (
        <>
          <InputField
            value={date.format(format)}
            onClick={handleDialog}
            label={"Timing"}
            withoutClearBtn
            disabled={disabled}
          />
          <DayAndTimePicker
            open={open}
            selectedDate={date}
            handleDateChange={changeTimeValue}
            handleDialog={handleDialog}
          />
        </>
      ) : (
        <TimePicker
          value={date}
          minutesStep={15}
          onChange={changeTimeValue}
          TextFieldComponent={InputField}
          label={"Timing"}
          format={format}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default DayAndTimeInputBtn;
