import gql from "graphql-tag";

export const createNewAccountQuery = gql`
  mutation AccountPost(
    $Name: String
    $Description: String
    $Files: [Upload]
  ) {
    AccountPost(
      Account: { Name: $Name, Description: $Description }
      Files: $Files
    ) {
        Id
        Name
        Description
        Images {
          Id
          ContainerId
          ContainerName
          Description
          FileExtension
          Name
          IsPrimary
        }
      }
  }
`;
