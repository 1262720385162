import * as React from "react";

interface ConfirmAssetMoveI {
  ItemName: string;
  AccountDestination: string;
}

const ConfirmAssetMove = (props: ConfirmAssetMoveI) => {
  const {ItemName, AccountDestination} = props;

  return (
    <>
      <span className="item-name">{ItemName} </span>
       item will move to
      <span className="item-name"> {AccountDestination} </span>
       account with all related activities.
      <div className="DeleteAsset_note">
        After moving, you can access this item with all related activities in your other account.
      </div>
    </>
  )
};

export default ConfirmAssetMove;
