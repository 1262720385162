import styled from "styled-components";
import { Box } from "@material-ui/core";

export const StyledContainer = styled(Box)`
  padding: 1.5rem 0;

  @media print {
    display: none;
  }
`;

export const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;
export const StyledSearch = styled.div`
  display: none;
  max-width: 342px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 600px) {
    display: block;
  }

  .MuiInputBase-input {
    height: auto;
    padding: 8px 0;
  }
`;
export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  // flex: 0 1 190px;
  min-width: 0;
  justify-content: flex-end;

  .MuiButtonGroup-contained {
    box-shadow: none;
  }
  @media screen and (min-width: 960px) {
    // flex: 0 0 220px;
  }
`;
