import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import {
  subscriptionValues,
  useGetUserSubscriptionTier,
} from "../../customHooks/useGetUserSubscriptionTier";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { ROUTES } from "../../_constant/screens";

import FaceIcon from "@material-ui/icons/Face";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import MenuOpenOutlinedIcon from "@material-ui/icons/MenuOpenOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import EventIcon from "@material-ui/icons/Event";

import {
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider/Divider";

import "./SettingsDrawer.scss";

export interface PopoverLinkItem {
  name: string;
  url: string;
  icon?: any;
  disabled?: boolean;
}
interface getSettingsLinksParams {
  profile?: boolean;
  accounts?: boolean;
  myBusiness?: boolean;
  calendarFeeds?: boolean;
  notifications?: boolean;
  about?: boolean;
}

export const getSettingsLinks = ({
  profile = false,
  accounts = false,
  myBusiness = true,
  calendarFeeds = true,
  notifications = false,
  about = false,
}: getSettingsLinksParams): PopoverLinkItem[] => {
  return [
    {
      name: "Profile",
      url: ROUTES.EDIT_PROFILE,
      icon: <FaceIcon />,
      disabled: profile,
    },
    {
      name: "Accounts",
      url: ROUTES.ACCOUNTS,
      icon: <PeopleAltOutlinedIcon />,
      disabled: accounts,
    },
    {
      name: "My Business",
      url: ROUTES.BUSINESS,
      icon: <BusinessCenterOutlinedIcon />,
      disabled: myBusiness,
    },
    {
      name: "Calendar Feeds",
      url: ROUTES.CALENDAR_FEEDS,
      icon: <EventIcon />,
      disabled: calendarFeeds,
    },
    {
      name: "Notifications",
      url: ROUTES.NOTIFICATIONS,
      icon: <NotificationsNoneIcon />,
      disabled: notifications,
    },
    {
      name: "About",
      url: ROUTES.ABOUT,
      icon: <BookOutlinedIcon />,
      disabled: about,
    },
  ];
};

const SettingsDrawer: React.FC = (props: any) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    root: {
      "&.Mui-selected": {
        "& > *": {
          color: theme.palette.primary.main,
        },
      },
    },
  });

  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const limits = useGetLimits();
  const enableCalendarFeed = limits?.user.calendarIntegration.Value;

  const userSubscriptionTier = useGetUserSubscriptionTier();
  const enableBusinessAccounts = userSubscriptionTier.value === subscriptionValues.professional;

  const links: PopoverLinkItem[] = getSettingsLinks({
    myBusiness: !enableBusinessAccounts,
    calendarFeeds: !enableCalendarFeed,
  });

  const handleLogout = () => {
    navigate(ROUTES.LOGOUT);
  };

  const drawer = (
    <List className="SettingsDrawer__list" disablePadding>
      {links
        .filter((el) => !el.disabled)
        .map(({ name, url, icon }) => (
          <ListItem
            button
            disableGutters
            component={NavLink}
            to={url}
            key={name}
            activeClassName="Mui-selected"
            classes={{
              root: classes.root,
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      <Divider />
      <ListItem button disableGutters onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );

  return (
    <Hidden xsDown implementation="css">
      <Drawer
        variant="temporary"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={mobileOpen}
        onClose={() => setMobileOpen(!mobileOpen)}
      >
        <Toolbar className="SettingsDrawer__toolBar" disableGutters>
          <IconButton onClick={() => setMobileOpen(!mobileOpen)}>
            <MenuOpenOutlinedIcon />
          </IconButton>
        </Toolbar>
        {drawer}
      </Drawer>
      <Drawer variant="permanent" open className="SettingsDrawer">
        <Toolbar className="SettingsDrawer__toolBar" disableGutters>
          <IconButton onClick={() => setMobileOpen(!mobileOpen)}>
            <MenuOutlinedIcon />
          </IconButton>
        </Toolbar>
        {drawer}
      </Drawer>
    </Hidden>
  );
};

export default SettingsDrawer;
