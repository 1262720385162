import React from 'react';
import Portal from '@material-ui/core/Portal';

interface IModalProps {
  isShowing: boolean;
  children: React.ReactNode
}

const Modal = ({ isShowing, children }: IModalProps) => isShowing
  ? (
    <Portal>
      {children}
    </Portal>
  ) : null;

export default Modal;