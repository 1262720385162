import React from "react";

import { useLocation } from "react-router-dom";

const useGetCustomerIdFromUrl = (): string | null => {
  const location = useLocation();
  const customerSplitted = location.search ? location.search.split("customerId=") : null;

  return customerSplitted && customerSplitted.length > 1 ? customerSplitted[1] : null;
};

export default useGetCustomerIdFromUrl;
