import {
  ACCOUNT,
  ASSET_REFS,
  CUSTOMER,
  ITEM,
  ITEMS,
  ITEM_ACTIVITIES,
} from "../../../../_constant/wordings";
import { GET_ASSET_REFS } from "../../../assets/all/assets.query";
import { GET_ACTIVITIES_LIST } from "../../all/activities-list.query";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import gql from "graphql-tag";
import { GET_CUSTOMER_ACTIVITIES } from "../../../../components/ActivitiesTab/getCustomerActivities.query";
import {
  createQueryOptions,
  createRelatedActivitiesQueryObject,
  getActivitiesFromRelatedActivities,
} from "../../../assets/_components/relaitedActivities/RelatedActivitiesCacheUpdate";
import { getExistAssets } from "../../../../components/PartsTabContainer/assets.query";

export function updateFnAfterActivityRemove(cache, activity, accountId) {
  try {
    const assetsRef = cache.readQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
    });
    assetsRef[ACCOUNT][ASSET_REFS] = assetsRef[ACCOUNT][ASSET_REFS].map((asset) => {
      if (activity.Asset.Id === asset.Id) {
        asset.HasActivities = false;
      }
      return asset;
    });

    cache.writeQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
      data: assetsRef,
    });
  } catch (e) {}
}

export const updateActivitiesAfterActivityRemove = (cache, activity, accountId, filterString) => {
  try {
    const activities = cache.readQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: filterString,
      },
    });

    activities[ACCOUNT]["AssetActivities"] = activities[ACCOUNT]["AssetActivities"].filter(
      (el) => el.Id !== activity.Id,
    );

    cache.writeQuery({
      query: GET_ACTIVITIES_LIST,
      variables: {
        Id: accountId,
        Filter: filterString,
      },
      data: activities,
    });
  } catch (e) {}
};

export const updateRelActivitiesAfterActivityRemove = (cache, activity, accountId) => {
  const options = createQueryOptions(accountId, activity.Asset.Id, activity.StatusId);
  try {
    const activitiesFromStatus = cache.readQuery(options);

    let activitiesFromStatusList = getActivitiesFromRelatedActivities(activitiesFromStatus);
    activitiesFromStatusList = activitiesFromStatusList.filter(
      (element) => element.Id !== activity.Id,
    );

    cache.writeQuery({
      ...options,
      data: createRelatedActivitiesQueryObject(activitiesFromStatus, activitiesFromStatusList),
    });
  } catch (e) {}
};

export const relatedItemForActivity = gql`
  fragment relatedAsset on Asset {
    Id
    AssetActivities {
      Id
    }
  }
`;

export const updateRelatedItemAfterActivityRemoved = (
  cache: DataProxy,
  AssetActivityDelete: any,
) => {
  try {
    const options = {
      fragment: relatedItemForActivity,
      id: AssetActivityDelete?.Asset?.Id,
    };
    const relatedAsset: any = cache.readFragment(options);
    let activities = relatedAsset?.AssetActivities ?? [];
    activities = activities.filter((activity) => activity.Id !== AssetActivityDelete.Id);
    cache.writeFragment({
      ...options,
      data: { ...relatedAsset, AssetActivities: activities },
    });
  } catch (error) {
    console.error(error);
  }
};

interface customerActivitiesProps {
  cache: DataProxy;
  AssetActivityDelete: any;
  CustomerId: string;
  AccountId: string;
}
export const updateCustomerActivitiesAfterActivityRemoved = ({
  cache,
  AssetActivityDelete,
  CustomerId,
  AccountId,
}: customerActivitiesProps) => {
  try {
    const options = {
      query: GET_CUSTOMER_ACTIVITIES,
      variables: { CustomerId, AccountId },
    };

    const customerAtivitiesQuery: any = cache.readQuery(options);

    customerAtivitiesQuery[CUSTOMER][ITEM_ACTIVITIES] = customerAtivitiesQuery[CUSTOMER][
      ITEM_ACTIVITIES
    ]
      ? customerAtivitiesQuery[CUSTOMER][ITEM_ACTIVITIES].filter(
          (activity) => activity.Id !== AssetActivityDelete.Id,
        )
      : [];

    cache.writeQuery({ ...options, data: { ...customerAtivitiesQuery } });
  } catch (error) {
    console.error(error);
  }
};

export const updateAssetActivitiesAfterActivityDelete = (
  cache: DataProxy,
  AssetActivityDelete: any,
  accountId: string,
) => {
  try {
    const cacheData: any = cache.readQuery({
      query: getExistAssets,
      variables: {
        Id: accountId,
      },
    });

    const updatedAssets = cacheData.Account.Assets.map((asset) => {
      if (asset.Id === AssetActivityDelete[ITEM].Id) {
        const filteredAssetActivities = asset[ITEM_ACTIVITIES].filter(
          (activity) => activity.Id !== AssetActivityDelete.Id,
        );
        return {
          ...asset,
          [ITEM_ACTIVITIES]: [...filteredAssetActivities],
        };
      } else return asset;
    });

    cacheData[ACCOUNT][ITEMS] = updatedAssets;

    cache.writeQuery({
      query: getExistAssets,
      variables: { Id: accountId },
      data: cacheData,
    });
  } catch (error) {}
};
