import gql from "graphql-tag";

export const assignUserQuery = gql`
  mutation assignUserQuery($AccountId: ID, $Id: ID, $AssignedUserId: ID) {
    AssetActivityPatch(
      AssetActivity: { AccountId: $AccountId, Id: $Id, AssignedUserId: $AssignedUserId }
    ) {
      Id
      AssignedUserId
    }
  }
`;
