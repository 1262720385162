import gql from "graphql-tag";

export const updateProjectMutation = gql`
  mutation updateProject($Project: ProjectInputType!, $Files: [Upload]) {
    ProjectPut(Project: $Project, Files: $Files) {
      AccountId
      ActivityIds
      AssignedUserId
      Description
      Name
      ProjectStatusId
      DueDateTime
      CompletedDateTime
      Id
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        AbsoluteUri
        FileExtension
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      CustomFields
      Schema {
        FieldType
        Id
        Name
        Order
      }
    }
  }
`;
