import * as React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { ROUTES } from "../_constant/screens";

import Visits from "../pages/Visits/Visits";
import Customers from "../pages/Customers/Customers";
import NewCustomer from "../pages/NewCustomer/NewCustomer";
import EditCustomer from "../pages/EditCustomer/EditCustomer";
import NewVisit from "../pages/NewVisit/NewVisit";
import EditVisit from "../pages/EditVisit/EditVisit";
import AllAssetsWrapper from "../containers/assets/all/AllAssetsWrapper";
import AllActivities from "../containers/activities/all/AllActivities";
import ChangePasswordPage from "../containers/ChangePassword";
import ActivityPage from "../containers/activities/ActivityPage";
import ErrorPage from "../containers/ErrorPage";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";
import AssetPage from "../containers/assets/AssetPage";
import SwitchAccountPage from "../pages/SwitchAccountPage";
import AllProjects from "../containers/projects/all/AllProjects";
import NewProject from "../pages/NewProject/NewProject";
import EditProject from "../pages/EditProject/EditProject";

import SwitchAccountByUrl from "./SwitchAccountByUrl";
import AppRedirect from "./redirection/AppRedirect";
import ProtectedRoute from "./ProtectedRoute";
import ErrorBoundary from "../containers/ErrorBoundary/ErrorBoundary";
import CustomDragLayer from "../components/CustomDragLayer";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { SilentRenew } from "./SilentRenew";
import { LogoutRoute } from "./LogoutRoute";
import Vendors from "../pages/Vendors/Vendors";
import NewVendor from "../pages/NewVendor/NewVendor";
import EditVendor from "../pages/EditVendor/EditVendor";
import MockRoute from "./MockRoute";
import Settings from "../pages/Settings/Settings";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <CustomDragLayer />
      <ErrorBoundary>
        <SwitchAccountByUrl />
        <ScrollToTop />
        <Routes>
          <ProtectedRoute path="/" component={AppRedirect} withoutWrapper />

          <Route path={`${ROUTES.SINGLE_ACTIVITY}`} element={<Navigate to={ROUTES.ACTIVITIES} />} />
          <ProtectedRoute path={ROUTES.ACTIVITIES} component={AllActivities} />
          <ProtectedRoute path={`${ROUTES.SINGLE_ACTIVITY}/*`} component={ActivityPage} />

          <Route path={`${ROUTES.SINGLE_ASSET}`} element={<Navigate to={ROUTES.ASSETS} />} />
          <ProtectedRoute path={`${ROUTES.ASSETS}`} component={AllAssetsWrapper} />
          <ProtectedRoute path={`${ROUTES.SINGLE_ASSET}/*`} component={AssetPage} />

          <ProtectedRoute path={`${ROUTES.SETTINGS}/*`} component={Settings} />

          <Route path={`${ROUTES.SINGLE_VISIT}`} element={<Navigate to={ROUTES.VISITS} />} />
          <ProtectedRoute path={ROUTES.VISITS} component={Visits} />
          <ProtectedRoute path={ROUTES.CREATE_VISIT} component={NewVisit} />
          <ProtectedRoute path={`${ROUTES.SINGLE_VISIT}/:id`} component={EditVisit} />

          <Route path={`${ROUTES.SINGLE_CUSTOMER}`} element={<Navigate to={ROUTES.CUSTOMERS} />} />
          <ProtectedRoute path={ROUTES.CUSTOMERS} component={Customers} />
          <ProtectedRoute path={ROUTES.CREATE_CUSTOMER} component={NewCustomer} />
          <ProtectedRoute path={`${ROUTES.SINGLE_CUSTOMER}/:id`} component={EditCustomer} />

          <Route path={`${ROUTES.SINGLE_VENDOR}`} element={<Navigate to={ROUTES.VENDORS} />} />
          <ProtectedRoute path={ROUTES.VENDORS} component={Vendors} />
          <ProtectedRoute path={ROUTES.CREATE_VENDOR} component={NewVendor} />
          <ProtectedRoute path={`${ROUTES.SINGLE_VENDOR}/:id`} component={EditVendor} />

          <Route path={`${ROUTES.SINGLE_PROJECT}`} element={<Navigate to={ROUTES.PROJECTS} />} />
          <ProtectedRoute path={ROUTES.PROJECTS} component={AllProjects} />
          <ProtectedRoute path={ROUTES.CREATE_PROJECT} component={NewProject} />
          <ProtectedRoute path={`${ROUTES.SINGLE_PROJECT}/:id`} component={EditProject} />

          <Route path={`${ROUTES.CHANGE_ACCOUNT}`} element={<SwitchAccountPage />} />

          <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
          <Route path={ROUTES.SILENT_RENEW} element={<SilentRenew />} />
          <Route path={ROUTES.LOGOUT} element={<LogoutRoute />} />
          {/* <Route path={`/mock`} element={<MockRoute />} /> */}

          <Route
            path={ROUTES.ERROR}
            element={
              <ErrorPage
                bgImage={"wrong"}
                title={"Something went wrong"}
                message={"We're working on it and we'll get it fixed\nas soon as we can."}
                buttonText={"Reload app"}
              />
            }
          />

          <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />

          <Route
            path="/404"
            element={
              <ErrorPage
                bgImage={"error"}
                title={"The page you were looking for doesn’t exist"}
                message={"You may have typed the address\nincorrectly or the page has moved."}
                buttonText={"Reload app"}
              />
            }
          />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
