export class ActivityAffiliateModel {
  [key: string]: string | null;

  constructor(
    { Url = null, Type = null, Text = null, Icon = null } = {} as ActivityAffiliateModel,
  ) {
    this.Url = Url;
    this.Type = Type;
    this.Text = Text;
    this.Icon = Icon;
  }
}
