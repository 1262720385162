import gql from "graphql-tag";
import { useQuery } from "react-apollo";

export const GET_STATIC_DATA = gql`
  query getStaticData($Brand: ID) {
    StaticData(Brand: $Brand)
  }
`;

export const useStaticDataQuery = () => {
  const brand = process.env.REACT_APP_BRAND;
  const brandVar = brand && brand.charAt(0).toUpperCase() + brand.slice(1);

  const { data, loading, error } = useQuery(GET_STATIC_DATA,{ variables: {Brand: brandVar}, fetchPolicy: "cache-first" });

  if (loading || error) {
    return null;
  }

  if (data) {
    return JSON.parse(data.StaticData);
  }

  return null;
};
