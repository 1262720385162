import * as React from "react";

interface LoaderInterface {
  size: string;
}

export const LoaderCircle = (props: LoaderInterface) => {
  return (
    <div className={`Loader Loader-${props.size}`}>
      <svg className="Loader_circle" viewBox="0 0 72 72">
        <circle className="Loader_path" cx="36" cy="36" r="30"></circle>
      </svg>
    </div>
  );
};

export const GlobalLoader = () => {
  return (
    <div className={"Overlay is-active"}>
      <LoaderCircle size={"xl"} />
    </div>
  );
};
