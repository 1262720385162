// temp file
import * as config from "../../configs/statuses-config.json";
import { PossibleStates } from "../redux/models/data/InvitationModel";
import { UserRoleType } from "../redux/models/data/UserRefModel";
import { FIRST_LETTER } from "../_utils/regex";

function getOptionWithValue(value: string, str?: string) {
  const label = `${value.toLowerCase().replace(FIRST_LETTER, (c) => c.toUpperCase())}${str || ""}`;
  return { label, value };
}

export const INVALID_DATE = "Invalid date";

export const DATE_DIALOG_SAVE_DUE_DATE = "Set One Occurrence";
export const DATE_DIALOG_SAVE_RECURRENCE = "Set Recurring";
export const DATE_DIALOG_SAVE_SCHEDULE = "Set Schedule";

export const EXPANDED_STATUSES = config["expandedStatuses"].map((status) =>
  status.trim().toLowerCase(),
);
export const statusesInDeletePopupArr = config["showInDeletePopup"].map((status) =>
  status.trim().toLowerCase(),
);
//
export enum WeekInMonth {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
  FOURTH = "FOURTH",
  LAST = "LAST",
}

const weekInMonthArr = Object.values(WeekInMonth);
export const optionsNumberOfWeekInMonth = weekInMonthArr.map((value) => getOptionWithValue(value));
//
export enum RepeatAfter {
  DUE_DATE = "DUE_DATE",
  COMPLETED_DATE = "COMPLETED_DATE",
}
//
export enum RepeatEvery {
  DAY_OF_WEEK_IN_MONTH = "DUE_DATE_DAY_OF_WEEK_IN_MONTH",
  DAY_IN_MONTH = "DUE_DATE_MONTH",
}
//
export enum EndAfter {
  NO_LIMIT = "NO_LIMIT",
  END_DATE = "END_DATE",
  REPEAT_COUNT = "REPEAT_COUNT",
}
//
export enum DaysOfWeek {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

const daysOfWeekArr = Object.values(DaysOfWeek);
export const optionsDaysOfWeek = daysOfWeekArr.map((value) => getOptionWithValue(value));
//
export enum RepeatType {
  NONE = "NONE",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

const frequencyValuesArr = [RepeatType.DAY, RepeatType.WEEK, RepeatType.MONTH, RepeatType.YEAR];
export const optionsFrequencyUnit = frequencyValuesArr.map((value) =>
  getOptionWithValue(value, "s"),
);
//
export enum LayoutType {
  BOARD = "Board",
  LIST = "List",
  PRINTABLE = "Printable",
}

export enum ActivitiesFieldName {
  ACTIVITIES = "AssetActivities",
  DUE_DATE_TIME = "DueDateTime",
  COMPLETED_DATE_TIME = "CompletedDateTime",
  START_DATE = "StartDate",
  STATUS_ID = "StatusId",
  ASSIGNED_USER_ID = "AssignedUserId",
}

export const SCREEN_XS_MAX_SIZE = 767;

export enum AffiliatesType {
  AMAZON = "AMAZON",
  EBAY = "EBAY",
  YOU_TUBE = "YOU_TUBE",
}

export enum AffiliatesUrls {
  PRODUCT_URL = "ProductUrl",
  SERVICE_URL = "ServiceUrl",
}

export enum AffiliatesUrlsType {
  PRODUCT_URL = "ProductUrl",
  SERVICE_URL = "ServiceUrl",
}

export const InvitationStateOptions = {
  active: "ACTIVE" as PossibleStates,
  accepted: "ACCEPTED" as PossibleStates,
  declined: "DECLINED" as PossibleStates,
  cancelled: "CANCELLED" as PossibleStates,
  ignored: "IGNORED" as PossibleStates,
};

export const UserRole = {
  admin: "ADMIN" as UserRoleType,
  owner: "OWNER" as UserRoleType,
  member: "MEMBER" as UserRoleType,
};

export const UNASSIGNED = "Unassigned";
export const ALL_ACTIVITIES = "AllActivities";
export const ME_PREFIX = "@me_";
export const EMPTY_ID = "00000000-0000-0000-0000-000000000000";
export type unassignedType = typeof UNASSIGNED | typeof EMPTY_ID;

export type dayOfWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY"
  | "EACH";

export const EACH_DAY = "EACH";

export const COLOR_CODE_STATE = {
  completed: "completed",
  overdue: "overdue",
  close: "close",
  far: "far",
  cancelled: "cancelled",
};

export const INPUT_LIMITS = {
  Name: { min: 1, max: 30 },
  NameActivity: { min: 1, max: 50 },
};

export const AVAILABLE_IMAGE_TYPES: string[] = [
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/gif",
];
export const AVAILABLE_DOCUMENT_TYPES: string[] = ["application/pdf"];
export const AVAILABLE_VIDEO_TYPES: string[] = [
  "video/mp4",
  "video/3gp",
  "video/m1v",
  "video/ogg",
  "video/mov",
  "video/quicktime",
  "video/qt",
  "video/webm",
  "video/avi",
];

export const SW_UPDATE_NOTIFICATION =
  "New app updates available. App will be automatically restarted";

export const HEADER_TYPES = {
  DEFAULT: "DEFAULT",
  BUSINESS: "BUSINESS",
};

export const BRAND = {
  autix: "Autix",
  default: "Upkeepr",
};
