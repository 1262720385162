import React from "react";
import InviteForm from "../InviteForm/InviteForm";
import { InvitationList } from "../InvitationList/InvitationList";

interface Props {
  accountId: string;
  maxMembers?: number;
}

export const InvitationTabContent = ({ accountId, maxMembers }: Props) => {
  return (
    <>
      <InviteForm accountId={accountId} maxMembers={maxMembers} />
      <InvitationList accountId={accountId} />
    </>
  );
};
