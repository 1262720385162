import styled from "styled-components";

export const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`;

export const ProgressTitle = styled.span`
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
`;

export const LegendList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const LegendItem = styled.li`
  &:not(:last-child) {
    margin-right: 16px;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 2px;
    margin-right: 4px;
  }

  &:nth-child(1) {
    &::before {
      background-color: ${({ theme }) => theme.palette.status.toDo};
    }
  }

  &:nth-child(2) {
    &::before {
      background-color: ${({ theme }) => theme.palette.status.inProgress};
    }
  }

  &:nth-child(3) {
    &::before {
      background-color: ${({ theme }) => theme.palette.status.done};
    }
  }
`;

export const ProgressLineWrap = styled.div`
  margin-top: 8px;
  display: flex;
  border: 0.1px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  height: 8px;

  & .progress-bar-line {
    width: 100%;
  }
`;
