import * as React from "react";
import RelatedActivityDragWrapper from "../../../../activities/related/RelatedActivityDragWrapper";
import ActivityItem from "../../../../activities/_components/ActivityItem";
import { sortActivities, statusClassName } from "../../../../../_utils/utils";
import { LayoutType } from "../../../../../_constant/constants";
import { SORTING_DETAILS } from "../../../../../_constant/ActivityDateConstant";
import { useWindowSize } from "../../../../../_utils/useWindowSize.hook";
import { ActivityModelRelated } from "../ActivityModelRelated";

interface StatusAccordionContentProps {
  activities: ActivityModelRelated[];
  StatusName: string;
  customerId?: string;
}

const StatusAccordionContent: React.FC<StatusAccordionContentProps> = ({
  activities,
  StatusName,
  customerId,
}) => {
  const [browserWindowWidth] = useWindowSize();

  const sortedActivities = activities ? sortActivities(StatusName, activities) : [];

  const statusStyleName = StatusName.toLowerCase().split(" ").join("-");

  return (
    <div className={`RelatedActivities_list ${statusClassName(StatusName)}`}>
      {sortedActivities &&
        sortedActivities.map((activity) => (
          <RelatedActivityDragWrapper
            key={activity.Id}
            activity={activity}
            statusName={StatusName}
            layout={LayoutType.LIST}
            browserWindowWidth={browserWindowWidth}
          >
            <ActivityItem
              activity={activity}
              layout={LayoutType.LIST}
              dateType={SORTING_DETAILS.get(StatusName)["field"]}
              status={statusStyleName}
              customerId={customerId}
            />
          </RelatedActivityDragWrapper>
        ))}
    </div>
  );
};

export default StatusAccordionContent;
