import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import useGetVisitStatus from "../../customHooks/useGetVisitStatus";

const StartContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
  display: flex;
  background-color: var(--color-white);
  padding: 12px 8px 12px 16px;
  gap: 10px;
  z-index: 999;
`;

const TextContainer = styled.div`
  flex: 3 0 180px;
`;

const ActionContainer = styled.div`
  flex: 2;
`;

const TextStyled = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1rem;
  white-space: pre-wrap;
`;

const wordings = {
  not_started: `When you press Start, the visit \ngets "In Progress" status.`,
  started: `When you press Finish, the visit \ngets "Done" status.`,
};

interface StartVisitMobileProps {
  visitStatusId: string;
}

const StartVisitMobile: React.FC<StartVisitMobileProps> = ({ children, visitStatusId }) => {
  const { checkVisitStarted } = useGetVisitStatus();
  const visitStarted = checkVisitStarted(visitStatusId);

  return (
    <StartContainer>
      <TextContainer>
        <TextStyled>{visitStarted ? wordings.started : wordings.not_started}</TextStyled>
      </TextContainer>
      <ActionContainer>{children}</ActionContainer>
    </StartContainer>
  );
};

export default StartVisitMobile;
