import gql from "graphql-tag";

export const getActivitiesRelatedToVendorQuery = gql`
  query getActivitiesRelatedToVendor($ActivityIds: [ID], $AccountId: ID) {
    AssetActivities(ActivityIds: $ActivityIds, AccountId: $AccountId) {
      Id
      Name
      StatusId
      DueDateTime
      CompletedDateTime
      AssignedUserId
      Schedule {
        RecurringType
      }
      RelatedDueDateTime
      Asset {
        Id
        Name
      }
      VendorRefs {
        Id
      }
    }
  }
`;
