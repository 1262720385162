import React from "react";
import AddNewVisitSection from "../../containers/visits/_components/AddNewVisitSection";

interface VisitTabProps {
  customerId: string;
  isCustomize: boolean;
}

const VisitTab: React.FC<VisitTabProps> = ({ customerId, isCustomize, children }) => {
  return (
    <>
      {isCustomize && <AddNewVisitSection customerId={customerId} />}
      {children}
    </>
  );
};

export default VisitTab;
