import React from "react";
import styled, { css } from "styled-components";
import { ListItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography/Typography";
import { textClip } from "../_styled/textClip";
import IconButton from "@material-ui/core/IconButton/IconButton";

const accountPhotoSizeMobile = "1.5rem";
const accountPhotoSizeDesktop = "2rem";
interface ListItemStyledProps {
  selected: boolean;
  isMobile: boolean;
}
export const AccountListItemStyled = styled(({ selected, isMobile, ...rest }) => (
  <ListItem {...rest} />
))<ListItemStyledProps>`
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    min-height: ${({ isMobile }) => (isMobile ? "48px" : "62px")};

    padding: 5px 5px 5px var(--space-size--m);
    border-top: solid var(--color-light) 1px;
    border-radius: 0;
  }

  ${({ isMobile }) =>
    isMobile
      ? `& .MuiAvatar-root, & .MuiSkeleton-circle, & .MuiSkeleton-rect {
    width: ${accountPhotoSizeMobile};
    height: ${accountPhotoSizeMobile};
  }`
      : ""}

  & .MuiAvatar-circle {
    border: ${({ theme, selected }) =>
      selected ? `2px solid ${theme.palette.primary.main}` : "none"};
  }
`;

const avatarStyle = css`
  width: ${accountPhotoSizeDesktop};
  height: ${accountPhotoSizeDesktop};
`;

export const AccountListItemAvatar = styled(Avatar)`
  ${avatarStyle}
  background-color: ${({ theme }) => `${theme.palette.primary.medium}`};
  color: ${({ theme }) => `${theme.palette.primary.main}`};
`;
export const AccountListLetterInAvatar = styled(Typography)`
  padding-top: 2px;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
`;

interface ItemAvatarPlaceholderProps {
  rounded?: number;
}
export const AccountListItemAvatarPlaceholder = styled(Skeleton)<ItemAvatarPlaceholderProps>`
  ${avatarStyle}
  background-color: rgba(0, 0, 0, 0.11);
  ${({ rounded }) => (rounded ? `border-radius: ${rounded}px;` : "")}
`;

export const AccountListItemTextStyle = styled(Typography)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 14px;
  color: inherit;
  max-width: 179px;
  ${textClip}
`;

export const AccountListItemNamePlaceholder = styled(Skeleton)`
  max-width: 220px;
`;

interface AccountListItemNameContainerProps {
  $isSelected: boolean;
  $isMobile: boolean;
}
export const AccountListItemNameContainer = styled.div<AccountListItemNameContainerProps>`
  width: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0.5rem;
  color: ${({ theme, $isSelected, $isMobile }) =>
    $isSelected && !$isMobile ? theme.palette.primary.main : theme.palette.text.primary};
`;

export const AccountListItemLabel = styled(Typography)`
  font-size: 0.625rem; // 10px;
  line-height: 16px;
`;

export const RadioButtonStyle = styled(IconButton)`
  margin: 0 10px;
  padding: 0;
`;
