import * as React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import useGetAccountId from "./useGetAccountId";
import { ACCOUNT, ASSET_REFS } from "../_constant/wordings";
import { alphabetNameSorting } from "../_utils/utils";

export const GET_ITEMS_LIST = gql`
  query getItemsList($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetRefs {
        Id
        Name
      }
    }
  }
`;

const useGetItemsNames = (skipVariable: string | null | undefined = null) => {
  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(GET_ITEMS_LIST, {
    variables: { Id: accountId },
    skip: !accountId || !!skipVariable,
  });

  const assetsData =
    data && data[ACCOUNT] && data[ACCOUNT]?.[ASSET_REFS]?.sort(alphabetNameSorting);
  return { assetsData: assetsData || [], loading };
};

export default useGetItemsNames;
