import * as React from "react";
import { Route, Routes } from "react-router-dom";

import EditAsset from "./existing/EditAsset";
import NewAssetPage from "./new/NewAsset";
import { CREATE_NEW_PATH } from "../../_constant/screens";
import { useParams } from "react-router";

const AssetPage: React.FC = () => {
  const params = useParams();

  return (
    <Routes>
      <Route path=":id" element={<EditAsset />} />
      <Route path={`${CREATE_NEW_PATH}`} element={<NewAssetPage />} />
    </Routes>
  );
};

export default AssetPage;
