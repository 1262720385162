import * as React from "react";
import { useEffect, useState } from "react";
import { DateDialog } from "../../../../_common/Dialog";
import Button from "../../../../../components/Button";
import DateValue from "../../../../../components/DateValue/DateValue";
import CompletedDateForm from "./CompletedDateForm";
import {
  ActivitiesFieldName,
  INVALID_DATE,
  SCREEN_XS_MAX_SIZE,
} from "../../../../../_constant/constants";
import { isDateValid } from "../due_date/forms/recurring/validation";
import config from "../../../../../../configs/statuses-config.json";
import { formatDateWithoutTZ } from "../../../../../_utils/date";
import useGetAcitvitiesStatuses from "../../../../../customHooks/api/useGetAcitvitiesStatuses";
import { DueDateButton } from "../due_date/DueDateComponent.style";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import useGetBusinessStatuses from "../../../../../customHooks/useGetBusinessStatuses";
import DueDateForm from "./forms/DueDateForm";
import { DateInput } from "../../../../../components/DateInput";
import { useWindowSize } from "../../../../../_utils/useWindowSize.hook";
import { Calendar } from "../../../../../components/Calendar";

interface CompletedDateComponentProps {
  value: string;
  onChange: (event, customEvent) => void;
  statusFieldName?: string;
  disabled?: boolean;
  disabledInfoText?: string;
}

const { FILL, FINISH } = config["statusNameMap"];

const DueDateWithoutReccuring = (props: CompletedDateComponentProps) => {
  const [isOpened, setOpen] = useState(false);
  const [value, setValue] = useState(props.value);
  const [isSubmitDisabled, setSubmitDisabled] = useState(!isDateValid(value));
  const [statusIdCompleted, setStatusId] = useState("");
  const [windowSize] = useWindowSize();

  const statusFieldName = props.statusFieldName ?? "StatusId";
  const disabled = !!props.disabled;
  const statuses = useGetAcitvitiesStatuses();
  const statusesBusiness = useGetBusinessStatuses();

  useEffect(() => {
    if (!props.statusFieldName && statuses.length > 0) {
      const statusId = statuses.find((status) => status.Name === FILL).Id;
      setStatusId(statusId);
    }
    if (props.statusFieldName && statusesBusiness.length > 0) {
      const statusId = statusesBusiness.find((status) => status.Name === FINISH).Id;
      setStatusId(statusId);
    }
  }, [statuses, statusesBusiness]);

  useEffect(() => {
    // component receive new props
    setValue(props.value);
    setSubmitDisabled(!isDateValid(props.value));
  }, [props.value]);

  const handleSubmit = () => {
    if (value !== INVALID_DATE) {
      props.onChange(null, { id: ActivitiesFieldName.DUE_DATE_TIME, value: value });
    }

    setOpen(false);
  };

  const handleChange = (value) => {
    setValue(value);
    setSubmitDisabled(!isDateValid(value));
  };

  const handleClose = () => {
    setOpen(false);
    setValue(null);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };

  return (
    <>
      {props.value ? (
        <DateValue
          label="Due Date"
          value={formatDateWithoutTZ(props.value)}
          className="notRequired"
          onClick={() => setOpen(true)}
        />
      ) : (
        <div title={disabled && props.disabledInfoText ? props.disabledInfoText : ""}>
          <DueDateButton
            startIcon={<EventOutlinedIcon fontSize="small" />}
            onClick={handleOpen}
            variant="text"
            color="primary"
            disabled={disabled}
          >
            Due Date
          </DueDateButton>
        </div>
      )}
      {isOpened && (
        <DateDialog
          header={"Due Date"}
          cancel={handleClose}
          submit={handleSubmit}
          submitDisabled={isSubmitDisabled}
        >
          <div className="DueDateForm">
            <div className={"Form_item"}>
              <i className="Icon Icon-event" />
              <DateInput
                label="Due Date"
                name={ActivitiesFieldName.DUE_DATE_TIME}
                value={value}
                onBlur={(e) =>
                  props.onChange(e, {
                    id: ActivitiesFieldName.DUE_DATE_TIME,
                    value: e.target.value,
                  })
                }
                className={windowSize > SCREEN_XS_MAX_SIZE ? "is-cropped" : null}
                onClear={() =>
                  props.onChange({ id: ActivitiesFieldName.DUE_DATE_TIME, value: null })
                }
              />
            </div>
            <Calendar handleChange={handleChange} initValue={value} numberOfMonths={2} />
          </div>
        </DateDialog>
      )}
    </>
  );
};

export default DueDateWithoutReccuring;
