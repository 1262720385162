import * as React from "react";
import {DateInput} from "../../../../../../../components/DateInput";
import {FullActivityDueDate} from "../../../../../../../redux/models/data/ActivityModelFull";
import {getDateWithUpdatedTime} from "../../../../../../../_utils/date";
import {useWindowSize} from "../../../../../../../_utils/useWindowSize.hook";
import {SCREEN_XS_MAX_SIZE} from "../../../../../../../_constant/constants";

interface ScheduledDateInputProps {
  datesArr: string[];
  onChange: (value: FullActivityDueDate) => void;
  onClear: (index: number) => void;
  setActive: (index: number) => void;
  active: number;
  showCalendar: () => void;
}

const ScheduledDateInput: React.FC<ScheduledDateInputProps> = (props: ScheduledDateInputProps) => {
  const {datesArr, onChange, setActive, active} = props;
  const [windowSize] = useWindowSize();

  const handleChange = ({target}) => {
    const relatedDueDate = [...datesArr];
    relatedDueDate[+target.name] = getDateWithUpdatedTime(target.value);
    // split Due Date and rest dates
    const dueDate = relatedDueDate[0];
    relatedDueDate.splice(0, 1);
    // update in parent
    onChange({dueDate, relatedDueDate});
  };

  const showCalendar = (index) => {
    setActive(index);
    props.showCalendar();
  }

  return (
    <>
    {datesArr.map((item, index) => {
      const isFirstInput = index === 0;
      const dateClassName = `${active === index ? "activeInput" : ""}${isFirstInput ? " withoutGap" : ""}`

      return (
        <div
          className={"Form_item Form_item__scheduled"}
          onClick={(windowSize > SCREEN_XS_MAX_SIZE) ? () => setActive(index) : null}
          key={index}
        >
          {isFirstInput
            && <span className="ScheduledForm_text">Next</span>
          }
          <DateInput
            className={dateClassName}
            label="Due Date"
            name={index.toString()}
            value={item}
            onBlur={handleChange}
            onClear={() => props.onClear(index)}
            showCalendar={(windowSize < SCREEN_XS_MAX_SIZE) ? () => showCalendar(index) : null}
          />
        </div>
      );
    })}
    </>
  );
};

export default ScheduledDateInput;
