import * as React from "react";
import { EXPANDED_STATUSES } from "../../../../../_constant/constants";
import StatusAccordionComponent from "./StatusAccordionComponent";
import useGetAcitvitiesStatuses from "../../../../../customHooks/api/useGetAcitvitiesStatuses";
import { addCustomerIdToUrl } from "../../../../../_utils/utils";
import { ROUTES } from "../../../../../_constant/screens";
import { useNavigate } from "react-router-dom";
import CreateNewButtonMobile, {
  CreateNewEntityContainer,
} from "../../../../../components/CreateNewButtonMobile/CreateNewButtonMobile";
import { Box } from "@material-ui/core";

interface chilrenComponentProps {
  StatusName: string;
  StatusId: string;
  expanded: boolean;
  key: string;
}

interface StatusesAccordionBuilderProps {
  renderComponent: (props: chilrenComponentProps) => any;
  customerId: string;
  withCreateButton: boolean;
}

const StatusesAccordionBuilder: React.FC<StatusesAccordionBuilderProps> = ({
  renderComponent,
  withCreateButton = false,
  ...restProps
}) => {
  const navigate = useNavigate();
  const statuses: { Id: string; Name: "string" }[] = useGetAcitvitiesStatuses();

  const handleCreate = () => {
    navigate(addCustomerIdToUrl(ROUTES.CREATE_ACTIVITY, restProps.customerId || ""));
  };

  return (
    <Box>
      <div className={"RelatedActivities"}>
        {withCreateButton && (
          <CreateNewEntityContainer>
            <CreateNewButtonMobile handleCreate={handleCreate} disabled={!restProps.customerId}>
              Activity
            </CreateNewButtonMobile>
          </CreateNewEntityContainer>
        )}
        {statuses.map(({ Id, Name }) => {
          const expanded = EXPANDED_STATUSES.includes(Name.trim().toLowerCase());

          return renderComponent({
            key: Id,
            StatusName: Name,
            StatusId: Id,
            expanded,
            ...restProps,
          });
        })}
      </div>
    </Box>
  );
};

export default StatusesAccordionBuilder;
