import { DaysOfWeek, RepeatAfter, RepeatType, WeekInMonth } from "../../../_constant/constants";
import { isNumberCheck } from "../../../containers/activities/_components/date/due_date/forms/recurring/validation";

export type ActivityScheduleModelFieldsName =
  | "AppearanceCount"
  | "DayOfWeek"
  | "EndDate"
  | "RecurrenceCount"
  | "RecurringRepeatType"
  | "RecurringType"
  | "StartDate"
  | "WeekInMonth";

export class ActivityScheduleModel {
  AppearanceCount?: number | null;
  DayOfWeek?: DaysOfWeek | null;
  EndDate?: string | null;
  RecurrenceCount?: number | null;
  RecurringRepeatType?: RepeatAfter | null;
  RecurringType?: RepeatType;
  StartDate?: string | null;
  WeekInMonth?: WeekInMonth | null;

  constructor(
    {
      AppearanceCount = null,
      DayOfWeek = null,
      EndDate = null,
      RecurrenceCount = null,
      RecurringRepeatType = null,
      RecurringType = RepeatType.NONE,
      StartDate = null,
      WeekInMonth = null,
    } = {} as ActivityScheduleModel,
  ) {
    this.AppearanceCount = isNumberCheck(AppearanceCount) ? AppearanceCount : null;
    this.DayOfWeek = DayOfWeek || null;
    this.EndDate = EndDate || null;
    this.RecurrenceCount = RecurrenceCount || null;
    this.RecurringRepeatType = RecurringRepeatType || null;
    this.RecurringType = RecurringType || RepeatType.NONE;
    this.StartDate = StartDate || null;
    this.WeekInMonth = WeekInMonth || null;
  }
}
