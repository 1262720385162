import React from "react";
import { useQuery } from "react-apollo";
import { useSelector, shallowEqual } from "react-redux";

import { useGetUserSubscriptionTier } from "../../customHooks/useGetUserSubscriptionTier";
import useGetIsPreview from "../../customHooks/useGetIsPreview";

import { GET_USER_SUBSCRIPTION_SETTINGS } from "./getUserSubscriptionInfo.query";
import { getChangeSubscriptionUrl } from "../../redux/reducers/appReducer";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AllInclusive, Clear, Done } from "@material-ui/icons";

import { LoaderCircle } from "../Loader";
import { Label } from "../Label/Label";

import {
  CardWrapper,
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
  StyledList,
  StyledListItem,
  StyledCardFooter,
  FeatureImg,
  FeatureImgWrapper,
} from "./SubscriptionCardStyle";

interface IFeature {
  IsLimited: boolean;
  Value: number;
  Label: string;
  IconUrl: string;
}

export const SubscriptionCard: React.FC = () => {
  const { isPreview } = useGetIsPreview();

  const { data, loading, error } = useQuery(GET_USER_SUBSCRIPTION_SETTINGS);

  const userSubscriptionTier = useGetUserSubscriptionTier();

  const changeSubscriptionUrl = useSelector(
    (state) => getChangeSubscriptionUrl(state),
    shallowEqual,
  );

  if (loading) {
    return (
      <CardWrapper display="flex" alignItems="center" justifyContent="center">
        <LoaderCircle size="l" />
      </CardWrapper>
    );
  }

  const {
    User: { SubscriptionSettings },
  } = data;

  const handleChangeSubscription = () => {
    window.open(changeSubscriptionUrl);
  };

  const getFeatureValue = (isLimited: boolean, value: boolean | number) => {
    if (!isLimited) {
      return <AllInclusive />;
    } else if (isLimited && typeof value !== "number") {
      return isLimited && !value ? <Clear /> : <Done />;
    } else if (isLimited && typeof value === "number") {
      return value;
    }
  };

  const getFeatureValueColor = (isLimited: boolean, value: boolean | number) => {
    if (!isLimited) {
      return "primary";
    } else if (isLimited && typeof value !== "number") {
      return isLimited && !value ? "error" : "secondary";
    } else if (isLimited && typeof value === "number") {
      return "initial";
    }
  };

  return (
    <CardWrapper>
      <StyledCard>
        <StyledCardHeader
          title={
            <Typography variant="subtitle1" component="h3">
              Subscription Level
            </Typography>
          }
          subheader={userSubscriptionTier.value ?? ""}
        />
        <StyledCardContent>
          <StyledList>
            <StyledListItem>
              <Typography variant="subtitle1" color="primary">
                Feature
              </Typography>
              <Typography variant="subtitle1" color="primary">
                Maximum
              </Typography>
            </StyledListItem>
            {SubscriptionSettings?.LimitsToRender?.map(
              ({ Label, IsLimited, IconUrl, Value }: IFeature) => (
                <StyledListItem key={Label}>
                  <Box display="flex" alignItems="center">
                    <FeatureImgWrapper>
                      <FeatureImg src={IconUrl} alt={Label} />
                    </FeatureImgWrapper>
                    <Typography variant="subtitle2" component="span">
                      {Label}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color={getFeatureValueColor(IsLimited, Value)}
                  >
                    {getFeatureValue(IsLimited, Value)}
                  </Typography>
                </StyledListItem>
              ),
            )}
          </StyledList>
        </StyledCardContent>
        <StyledCardFooter>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p" color="textSecondary">
                To change your subscription level, visit our webpage here:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button color="primary" variant="contained" onClick={handleChangeSubscription}>
                Change Subscription
              </Button>
            </Grid>
          </Grid>
        </StyledCardFooter>
        {isPreview && <Label text={"PREVIEW"} />}
      </StyledCard>
    </CardWrapper>
  );
};
