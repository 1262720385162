import styled from "styled-components";

export const TabStyleContainer = styled.section`
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.primary.lighten};
`;

export const ActivitiesStyledContainer = styled.div`
  border: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  border-radius: 6px;
  overflow: hidden;
`;

export const LoadingContainer = styled.div`
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
