import React from "react";

import { Image } from "../../../redux/models/data/ImageModel";

import { composeImageUrl, getAssetImage } from "../../../_utils/utils";

import MoreAction, { MoreActionOptions } from "../../../components/MoreAction/MoreAction";
import CardBackdrop from "../../../components/CardBackdrop/CardBackdrop";

interface BoardCardLayoutProps {
  staticData: any;
  // restContent: React.ReactElement;
  image: Image | null;
  imageIcon?: string;
  name: string;
  alt?: string;
  footerText: string;
  footerIcon: string;
  moreActionOptions: MoreActionOptions[];
  isLoading?: boolean;
  handleSelectCard: (e: React.SyntheticEvent) => void;
  handleFooterClick: (e: React.SyntheticEvent) => void;
}

const BoardCardLayout: React.FC<BoardCardLayoutProps> = (props) => {
  const {
    handleSelectCard,
    staticData,
    image,
    imageIcon,
    alt,
    name,
    children,
    handleFooterClick,
    footerText,
    footerIcon,
    moreActionOptions,
    isLoading,
  } = props;

  const renderImage = (image, type) => {
    return image ? (
      <img
        src={composeImageUrl(image.Id, image.ContainerName, image.ContainerId)}
        alt=""
        className="Image"
      />
    ) : (
      <div className="Image-default__container">
        <img
          className="Image-default"
          src={imageIcon ?? getAssetImage(staticData, type)}
          alt={type}
        />
      </div>
    );
  };

  return (
    <div className={`Asset_wrapper AssetsList__item`}>
      <div className="Asset">
        <div onClick={handleSelectCard}>
          <div className="Asset_image">{renderImage(image, alt)}</div>
          <div className="Asset_content">
            <div className="Asset_title" title={name}>
              {name}
              {moreActionOptions && (
                <div style={{ marginLeft: "auto" }}>
                  <MoreAction options={moreActionOptions} />
                </div>
              )}
            </div>
            {children}
          </div>
          <div className="Asset_action" onClick={handleFooterClick}>
            <div className="Asset_action-create">
              <i className={`Icon ${footerIcon}`} />
              <span>{footerText}</span>
            </div>
          </div>
        </div>
        {isLoading && <CardBackdrop open={isLoading} borderRadius={6} />}
      </div>
    </div>
  );
};

export default BoardCardLayout;
