import config from "../../configs/statuses-config.json";

export const SORTING_DETAILS = new Map(config["sorting"].map((item) => [item.column, item]));

export const FILTERING_DETAILS = new Map(config["filtering"].map((item) => [item.column, item]));

export const EMPTY_STATUS_STATE = new Map(config["emptyState"].map((item) => [item.column, item]));

const buildDateOffsetOption = (key: string) => {
  if (key === "all") return { label: "all", value: "all" };
  if (isNaN(+key)) {
    return { label: `${parseInt(key)} year`, value: key };
  }
  return { label: `${key} days`, value: key };
};

export const DATE_OFFSET_OPTIONS = new Map(
  config["filtering"].map((item) => [
    item.column,
    item.options.map((props) => buildDateOffsetOption(props)),
  ]),
);
