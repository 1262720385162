import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useAccountSwitch from "../../customHooks/useAccountSwitch";
import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import Button from "@material-ui/core/Button/Button";
import IconButton from "@material-ui/core/IconButton/IconButton";

import SettingsIcon from "@material-ui/icons/Settings";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { composeImageUrl } from "../../_utils/utils";

import { AccountModel } from "../../redux/models/data/AccountModel";
import { UserInfoModel } from "../../redux/models/data/UserInfoModel";

import { PERSONAL } from "../../_constant/wordings";
import { ROUTES } from "../../_constant/screens";

import AccountListItem from "../AccountListItem/AccountListItem";
import Modal from "../../containers/Modal/Modal";
import { LeaveDialogComponent } from "../../containers/_common/Dialog";
import {
  AccountAlertContainer,
  AccountPopupFooter,
  AccountAlertHeader,
  AccountAlertContent,
  AccountAlertPhotoPlaceholder,
  AccountAlertPhoto,
  AccountAlertUserInfo,
  AccountAlertUserName,
  AccountAlertUserMail,
  AccountAlertAccountList,
  AccountAlertNamePlaceholder,
  AccountAlertMailPlaceholder,
} from "./AccountPopupStyle";
import PopupNavigation from "../PopupNavigation/PopupNavigation";

interface IAccountPopup {
  isExpanded: boolean;
  isMobileVersion?: boolean;
  userLoading: boolean;
  accountsLoading: boolean;
  User: UserInfoModel;
  accountList: AccountModel[];
  currentAccountId: string;
  handleClick: () => void;
  closeAlert: () => void;
}

const AccountPopup = (props: IAccountPopup) => {
  const {
    isExpanded,
    isMobileVersion,
    currentAccountId,
    userLoading,
    accountList,
    accountsLoading,
    User,
  } = props;
  const { handleClick, closeAlert } = props;
  const { FirstName, LastName, Email, Images } = User;
  const image = Images && Images[0];

  const userName =
    !FirstName && !LastName ? Email.substring(0, Email.indexOf("@")) : `${FirstName} ${LastName}`;

  const [accountIdForSwitch, setAccountIdForSwitch] = useState("");
  const [isLeaveDialogShowing, setLeaveDialogShowing] = useState(false);

  const navigate = useNavigate();
  const { setHeaderOptions, headerOptions } = useHeaderOptions();
  const { switchAccountById } = useAccountSwitch();
  const staticData = useStaticDataQuery();
  const defaultUserIcon = staticData ? staticData.User.User_default_icon : "";

  const handleSettingsClick = () => {
    navigate(ROUTES.EDIT_PROFILE);
    closeAlert();
  };

  const handleAccountSwitch = (id: string) => {
    if (currentAccountId === id) {
      return;
    }
    if (!headerOptions.isFormDirty) {
      closeAlert();
      switchAccountById(id);
    } else {
      closeAlert();
      setAccountIdForSwitch(id);
      setLeaveDialogShowing(true);
    }
  };

  const submitLeaveDialog = () => {
    setLeaveDialogShowing(false);
    setHeaderOptions({ isPromptAvailable: false });
    setTimeout(() => {
      switchAccountById(accountIdForSwitch).then(() =>
        setHeaderOptions({ isPromptAvailable: false }),
      );
    }, 0);
  };

  const accountsPlaceholder = () => (
    <>
      {[true, false, false].map((item, index) => (
        <AccountListItem
          isLoading
          isPersonal={item}
          account={new AccountModel()}
          closeAlert={closeAlert}
          currentAccountId={""}
          key={index}
          isMobile={!!isMobileVersion}
          isBusiness={false}
        />
      ))}
    </>
  );

  const handleLogoutClick = () => {
    navigate(ROUTES.LOGOUT);
  };

  const onAddHandle = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(ROUTES.NEW_ACCOUNT);
    closeAlert();
  };

  return (
    <>
      <AccountAlertContainer isMobile={!!isMobileVersion} open={isExpanded} onClose={closeAlert}>
        <AccountAlertHeader>
          {isMobileVersion ? (
            <PopupNavigation closeAlert={closeAlert} />
          ) : (
            <Button
              className="Link-edit"
              color="primary"
              startIcon={<SettingsIcon />}
              onClick={handleSettingsClick}
            >
              Settings
            </Button>
          )}
          <IconButton edge="end" onClick={handleClick} color="primary">
            <ClearIcon />
          </IconButton>
        </AccountAlertHeader>
        <AccountAlertContent>
          {userLoading ? (
            <AccountAlertPhotoPlaceholder animation="wave" variant="circle" />
          ) : (
            <AccountAlertPhoto
              alt="avatar"
              src={
                image && image.Id
                  ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
                  : defaultUserIcon
              }
            />
          )}
          <AccountAlertUserInfo>
            <AccountAlertUserName title={userName}>
              {userLoading || !userName ? (
                <AccountAlertNamePlaceholder animation="wave" />
              ) : (
                userName
              )}
            </AccountAlertUserName>
            <AccountAlertUserMail title={Email}>
              {userLoading || !Email ? <AccountAlertMailPlaceholder animation="wave" /> : Email}
            </AccountAlertUserMail>
          </AccountAlertUserInfo>
        </AccountAlertContent>
        <AccountAlertAccountList>
          {accountsLoading || !accountList
            ? accountsPlaceholder()
            : accountList.map((account: AccountModel) => {
                const isPersonal = account.Name === PERSONAL;
                const isBusiness = account.IsCustomerManagement;
                return (
                  <AccountListItem
                    key={account.Id}
                    account={account}
                    isMobile={!!isMobileVersion}
                    isPersonal={isPersonal}
                    isBusiness={isBusiness}
                    currentAccountId={currentAccountId}
                    closeAlert={closeAlert}
                    handleAccountSwitch={handleAccountSwitch}
                  />
                );
              })}
        </AccountAlertAccountList>
        <AccountPopupFooter isMobile={isMobileVersion}>
          {isMobileVersion ? (
            <Button color="primary" startIcon={<AddIcon />} onClick={onAddHandle}>
              Account
            </Button>
          ) : (
            <Button
              className="Link-edit"
              color="primary"
              startIcon={<ExitToAppIcon />}
              onClick={handleLogoutClick}
            >
              Log out
            </Button>
          )}
        </AccountPopupFooter>
      </AccountAlertContainer>
      <Modal isShowing={isLeaveDialogShowing}>
        <LeaveDialogComponent
          onCancel={() => setLeaveDialogShowing(false)}
          onSubmit={submitLeaveDialog}
        />
      </Modal>
    </>
  );
};

export default AccountPopup;
