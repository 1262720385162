import React, { useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { LoaderCircle } from "../Loader";
import { StyledContainer, StyledList } from "./VendorsList.style";
import { ListItem } from "./VendorListItem/VendorListItem";
import { GET_PROJECT_VENDORS } from "./getProjectVendors.query";
import { composeImageUrl } from "../../_utils/utils";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";

import Box from "@material-ui/core/Box";

interface VendorsListForProjectProps {
  projectId: string;
  showWarningMsg: boolean;
  renderWarningCard: (forEmptyState?: boolean) => void;
}

export const VendorsListForProject: React.FC<VendorsListForProjectProps> = ({
  projectId,
  showWarningMsg,
  renderWarningCard,
}) => {
  const [
    getVendors,
    { data: vendorData, loading: vendorLoading, error: vendorError },
  ] = useLazyQuery(GET_PROJECT_VENDORS, {
    variables: {
      ProjectId: projectId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    getVendors();
  }, [showWarningMsg]);

  const vendorsList = vendorData?.Project?.Vendors;
  const staticData = useStaticDataQuery();
  const vendorImage = staticData ? staticData?.Icons?.Desktop?.pic_vendor : "";
  const emptyCustomFieldsText = `There are no \n vendors`;

  if (!vendorLoading && !vendorsList?.length) {
    return (
      <Box padding="1rem" paddingTop="">
        {showWarningMsg && renderWarningCard()}
        <EmptyCustomFieldState emptyFieldsMessage={emptyCustomFieldsText} />
      </Box>
    );
  }

  return (
    <>
      <StyledContainer
        display={vendorLoading ? "flex" : "block"}
        alignItems="center"
        justifyContent="center"
      >
        {vendorLoading ? (
          <LoaderCircle size="l" />
        ) : (
          <>
            {showWarningMsg && renderWarningCard()}
            <StyledList rounded>
              {vendorsList.map(({ Id, Name, Images, Email, Phone, Address, Website }) => (
                <ListItem
                  vendorId={Id}
                  key={Id}
                  title={Name}
                  img={
                    Images && Images.length
                      ? composeImageUrl(
                          Images[0].Id,
                          Images[0].ContainerName,
                          Images[0].ContainerId,
                        )
                      : vendorImage
                  }
                  email={Email}
                  phone={Phone}
                  address={Address}
                  website={Website}
                />
              ))}
            </StyledList>
          </>
        )}
      </StyledContainer>
    </>
  );
};
