import React from "react";

import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";

interface WizardItemFooterProps {}

const WizardItemFooter = (props) => {
  const { navigate } = props;

  const theme = useTheme();

  return (
    <div>
      <Button onClick={() => navigate("backward")} style={{ color: theme.palette.text.secondary }}>
        Previous
      </Button>
      <Button onClick={() => navigate()} color="primary">
        Next
      </Button>
    </div>
  );
};

export default WizardItemFooter;
