import React from "react";
import styled from "styled-components";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

export const WizardDialog = styled((props) => (
  <Dialog disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="sm" {...props} />
))(({ theme }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  return `
  overflow-y: scroll;

  .MuiDialog-paperFullWidth {
    width: calc(100% - 32px);
  }

  .MuiDialog-paper {
    margin: 16px;
    height: 100%;
    min-height: 300px;
    max-height: 500px;
  }

  .MuiDialog-scrollPaper {
    align-items: center;

    @media screen and ( max-height: 400px ) {
      align-items: flex-start;
    }
  }

  ${WizardStepTitle} {
    font-size: ${isDesktop ? "1.5rem" : "1.25rem"};
  }
`;
});

export const WizardTitleBar = styled((props) => <DialogTitle disableTypography {...props} />)(
  ({}) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`,
);

export const WizardStepTitle = styled((props) => <Typography component="h2" {...props} />)``;

export const WizardActivitiesHint = styled((props) => <Typography {...props} />)`
  padding: 0 16px 10px 16px;
`;

export const WizardSkipButton = styled((props) => <Button {...props} />)(
  ({ theme }) => `
  margin-right: -${theme.spacing(1)}px;
  color: ${theme.palette.primary.main};
  letter-spacing: 1px;
  font-weight: normal;
`,
);

export const WizardCloseIcon = styled((props) => (
  <IconButton edge="end" {...props}>
    <CloseOutlinedIcon />
  </IconButton>
))(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  margin-top: -${theme.spacing(1)}px;
  margin-bottom: -${theme.spacing(1)}px;
`,
);

export const WizardContent = styled((props) => <DialogContent dividers {...props} />)`
  padding: 0;
  overflow: hidden;
  display: flex;
`;

export const WizardFooter = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const WizardFooterStepper = styled.div`
  min-width: 350px;
`;

export const WizardFooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;

  .MuiButton-root {
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const WizardStepper = styled(Stepper)`
  padding: 0;
`;

export const WizardStep = styled(Step)``;

export const WizardStepLabel = styled(StepLabel)(
  ({ theme }) => `
  .MuiStepLabel-label {
    color: ${theme.palette.text.secondary};
  }
  .MuiStepLabel-active {
    color: ${theme.palette.text.primary};
  }
`,
);
