import React from "react";
import DropZoneSvgCard from "../DropZoneSvgCard/DropZoneSvgCard";

interface DropZonePlaceholderProps {
  id: string;
}

const DropZonePlaceholder: React.FC<DropZonePlaceholderProps> = ({ id }) => {
  const clickHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // e.stopPropagation();
  };
  return <DropZoneSvgCard title="" imageUrl="" id={id} open={clickHandler} />;
};

export default DropZonePlaceholder;
