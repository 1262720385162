import gql from "graphql-tag";

export const getUserInfoQuery = gql`
  query getFullUserInfo {
    User {
      Id
      Email
      FirstName
      LastName
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
      }
      TimezoneId
      IsPreview
    }
  }
`;
export const updateUserInfoQuery = gql`
  mutation updateUserProfile(
    $Id: ID
    $FirstName: String!
    $LastName: String!
    $Email: String!
    $Images: [ImageInput]
    $Files: [Upload]
    $TimezoneId: String!
  ) {
    UserPut(
      User: {
        Id: $Id
        FirstName: $FirstName
        LastName: $LastName
        Email: $Email
        Images: $Images
        TimezoneId: $TimezoneId
      }
      Files: $Files
    ) {
      Id
      LastName
      FirstName
      Email
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
      }
      TimezoneId
    }
  }
`;
