import styled from "styled-components";

import { CardHeader } from "@material-ui/core";

export const StyledTabdHeader = styled(CardHeader)`
  /* background-color: #f5fbff; */
  &.MuiCardHeader-root {
    padding-left: 0;
  }

  .MuiCardHeader-content {
    display: flex;
    align-items: center;
    .MuiCardHeader-subheader {
      padding: 4px 10px;
      background-color: ${({theme}) => theme.palette.primary.medium};
      color: ${({theme}) => theme.palette.primary.main};
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 4px;
      margin-left: 16px;
    }
  }
  h3 {
    font-weight: 500;
  }

  .MuiSvgIcon-root {
    transform: translate(0px, 3px);
  }
`;
