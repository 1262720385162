import React from "react";
import styled, { css } from "styled-components";

import { Avatar } from "@material-ui/core";

interface CardContainerProps {
  padding?: number;
  borderRadius?: string;
  withLabel?: string;
  $isLoading?: boolean;
  isHover?: boolean;
}

export const CardContainer = styled.article<CardContainerProps>`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  padding: ${({ theme, padding }) => (padding ? theme.spacing(padding) : 0)}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ borderRadius }) => borderRadius || "0px"};
  cursor: ${({ $isLoading }) => ($isLoading ? "default" : "pointer")};
  ${({ $isLoading }) => ($isLoading ? "pointer-events: none" : "")};
  background-color: ${({ theme, isHover }) =>
    isHover ? theme.palette.primary.lighten : theme.palette.background.paper};

  * {
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.lighten};
  }

  &:last-child {
    border-bottom: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ withLabel, theme }) => {
      switch (withLabel) {
        case "to-do":
        case "not-started":
          return theme.palette.status.toDo;
        case "in-progress":
          return theme.palette.status.inProgress;
        case "done":
        case "finished":
          return theme.palette.status.done;
        case "cancelled":
          return theme.palette.status.cancelled;
        default:
          return "none";
      }
    }};
  }
`;

export const CardRowMedia = styled((props) => <Avatar {...props} />)`
  width: 32px;
  height: 32px;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.primary.medium};
`;

interface CardParentProps {
  alignItems?: "start" | "end";
}
export const CardParent = styled.div<CardParentProps>`
  display: flex;
  align-items: ${({ alignItems }) => {
    switch (alignItems) {
      case "start":
        return "flex-start";
      case "end":
        return "flex-end";
      default:
        return "flex-end";
    }
  }};
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.primary.main};

  i {
    font-size: 0.875rem;
    margin-right: 4px;
  }

  span {
    padding-right: ${({ theme }) => theme.spacing(1) / 2}px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

interface CardAdditionalInfoProps {
  $contentType: "info" | "link";
}

export const CardAdditionalInfo = styled.div<CardAdditionalInfoProps>`
  min-width: ${({ $contentType }) => ($contentType === "info" ? "85px" : "105px")};
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  color: ${({ theme, $contentType }) =>
    $contentType === "link" ? theme.palette.primary.main : theme.palette.greyDesign.surface2};

  &:hover {
    text-decoration: ${({ $contentType }) => ($contentType === "link" ? "underline" : "none")};
  }

  .MuiSvgIcon-root {
    margin-right: 4px;
    font-size: 0.875rem;
  }
`;

export const MoreButtonContainer = styled.div`
  display: flex;
  align-self: center;

  @media screen and (max-width: 600px) {
    margin-left: auto;
  }
`;
