import React from "react";

import { MenuItem, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { ActivityForWizardModel } from "../../../redux/models/data/ActivityForWizardModel";
import { ActivityScheduleModel } from "../../../redux/models/data/ActivityScheduleModel";

import WizardDatePicker from "../WizardDatePicker/WizardDatePicker";

import { optionsFrequencyUnit, RepeatType } from "../../../_constant/constants";

import { isNumberCheck } from "../../../containers/activities/_components/date/due_date/forms/recurring/validation";
import { formatDate, formatDateWithoutTZ } from "../../../_utils/date";

import {
  WizardRecurringSelectorText,
  WizardRecurringCount,
  WizardActivityDropdown,
} from "./WizardActivityRecurringSelectorStyles";
import Grid from "@material-ui/core/Grid/Grid";

const INCREASE = "INCREASE";
const DECREASE = "DECREASE";
type increaseType = typeof INCREASE | typeof DECREASE;

interface IWizardActivityRecurringSelector {
  activity: ActivityForWizardModel;
  handleChange: (id: string, property: string, value: any) => void;
}

const WizardActivityRecurringSelector: React.FC<IWizardActivityRecurringSelector> = ({
  activity: { Name, Schedule, _checked, DueDateTime },
  handleChange,
}) => {
  let dateForRecurringSelector = DueDateTime;

  if (!DueDateTime && Name && Schedule?.RecurringType === "NONE") {
    const todayDate = new Date().toISOString();
    const formattedTodayDate = formatDateWithoutTZ(todayDate);

    handleChange(Name, "DueDateTime", formattedTodayDate);
    dateForRecurringSelector = formattedTodayDate;
  }

  const handleCountChange = (increase: increaseType) => {
    let RecurrenceCount;
    if (increase === INCREASE) {
      RecurrenceCount = Schedule.RecurrenceCount ? Schedule.RecurrenceCount + 1 : 1;
    }
    if (increase === DECREASE) {
      RecurrenceCount = Schedule.RecurrenceCount ? Schedule.RecurrenceCount - 1 : 0;
    }
    handleChange(
      Name,
      "Schedule",
      new ActivityScheduleModel({
        ...Schedule,
        RecurrenceCount,
      }),
    );
  };

  const handleSelect = ({ target }: any) => {
    const value: RepeatType = target.value;
    handleChange(
      Name,
      "Schedule",
      new ActivityScheduleModel({
        ...Schedule,
        RecurringType: value,
      }),
    );
  };

  const handleDateChange = (value: any) => {
    handleChange(Name, "DueDateTime", formatDate(value));
  };

  const disabled = !_checked;
  const recurrenceCount = isNumberCheck(Schedule.RecurrenceCount) ? Schedule.RecurrenceCount : 0;

  const renderRecurring = () => (
    <>
      <Grid item style={{ margin: "0 4px 0 8px" }}>
        <IconButton
          onClick={() => handleCountChange(DECREASE)}
          size="small"
          color="primary"
          disabled={disabled}
        >
          <RemoveCircleOutlineIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item style={{ width: "1.5rem", textAlign: "center" }}>
        <WizardRecurringCount disabled={disabled}>{recurrenceCount}</WizardRecurringCount>
      </Grid>
      <Grid item style={{ margin: "0 8px 0 4px" }}>
        <IconButton
          onClick={() => handleCountChange(INCREASE)}
          size="small"
          color="primary"
          disabled={disabled}
        >
          <AddCircleOutlineIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item style={{ width: "5rem" }}>
        <WizardActivityDropdown
          type="text"
          handleChange={handleSelect}
          value={Schedule.RecurringType}
          id={"field-type"}
          select
          disabled={disabled}
          fullWidth
        >
          {optionsFrequencyUnit.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </WizardActivityDropdown>
      </Grid>
    </>
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <WizardRecurringSelectorText disabled={disabled}>
          {dateForRecurringSelector ? (recurrenceCount ? "Every year on" : "Once on") : "Every"}
        </WizardRecurringSelectorText>
      </Grid>

      {dateForRecurringSelector ? (
        <WizardDatePicker
          value={dateForRecurringSelector}
          handleChange={handleDateChange}
          format="MMMM D"
          disabled={disabled}
        />
      ) : (
        renderRecurring()
      )}
    </Grid>
  );
};

export default WizardActivityRecurringSelector;
