import styled from "styled-components";
import Button from "@material-ui/core/Button/Button";

export const WizardDatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    display: none;
  }
`;

export const LabelButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.main};

  &.MuiButtonBase-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.disabled};
  }

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;
