import styled from "styled-components";
import { scrollStyles } from "../_styled/scrollStyles";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import React from "react";

export const DropZoneFile = styled.div`
  position: relative;
  padding: var(--space-size--s);
`;

export const DropZoneImgRemove = styled.div`
  position: absolute;
  right: 4px;
  top: 5px;
  border-radius: 50%;
  padding: 0 3px;
  background-color: var(--color-brand);
  color: var(--color-white);
  border: 3px solid var(--color-white);
  cursor: pointer;
`;

export const DropZoneWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
`;

export const DropZoneInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px var(--color-brand) dashed;
  border-radius: var(--border-radius-s);

  @media screen and (max-width: 600px) {
    padding-bottom: 0;
    position: relative;

    &::-webkit-scrollbar {
      height: 5px;
    }
  }
`;
interface DropZoneStyledProps {
  $withoutOffset: boolean;
}
export const DropZoneStyled = styled.div<DropZoneStyledProps>`
  height: 100%;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  min-height: 160px;
  padding: ${({ $withoutOffset }) => ($withoutOffset ? "16px 0" : "16px 16px 16px 6px")};
  ${scrollStyles}

  @media screen and (min-width: 600px) {
    flex-direction: column;
    padding: 16px;
  }
`;

export const DropZoneContainer = styled.div`
  padding-right: 3px;
  max-width: 100%;
  width: 100%;
`;

export const DropZoneEmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const DropZoneEmptyStateIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
`;

export const DropZoneEmptyStateText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface DropZoneGridContainerProps {
  $withPaddingRight?: boolean;
}

export const DropZoneGridContainer = styled.div<DropZoneGridContainerProps>`
  width: 100%;
  display: grid;
  // justify-items: center;
  grid-auto-columns: 66px;
  grid-template-rows: 50% 50%;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding-left: 0.75rem;

  ${({ $withPaddingRight }) =>
    $withPaddingRight
      ? `&:after {
    content: "";
    width: 1px;
    margin-right: -1px;
  }`
      : ""}

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content;
    grid-auto-flow: unset;
    padding: 16px 0;
    height: auto;

    &:after {
      display: none;
    }
  }
`;

export const StyledHintTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
  cursor: pointer;
`;

export const StyledHintButton = styled(IconButton)`
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};

    @media screen and (min-width: 600px) {
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 600px) {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const StyledHintSectionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 600px) {
    padding-top: 25px;
    justify-content: center;
  }
`;

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.palette.background.paper};
    padding: 16px;
    color: ${({theme}) => theme.palette.greyDesign.surface2};
    border-radius: 8px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 5px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledTooltipTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({theme}) => theme.palette.text.primary};
  letter-spacing: 0.4px;
  margin-bottom: 5px;
`;

export const StyledTooltipDescription = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({theme}) => theme.palette.greyDesign.surface2};
  letter-spacing: 0.25px;
  margin-bottom: 8px;
`;

export const StyledTooltipButton = styled(Link)`
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: auto;
  cursor: pointer;
`;
