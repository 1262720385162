import gql from "graphql-tag";

export const GET_INVITATION_IDS = gql`
  query checkForInvitations {
    User {
      Id
      MyInvitations(Filter: "State=='ACTIVE'" Take: 1) {
          Id
      }
    }
  }
`;
