import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-apollo";
import { ExecutionResult } from "graphql";

import useGetAccountId from "./useGetAccountId";

import { getSelectedAssetType } from "../redux/reducers/filterReducer";
import { setSelectedAssets } from "../redux/actions/actions";

import { AssetModel } from "../redux/models/data/AssetModel";

import { createNewAssetQuery } from "../containers/assets/existing/asset.query";

import {
  addNewItemInAssetsReferenceQuery,
  updateAssetTypeListQueryWithNewItem,
  updateComponentIdsForParentAssetWithNewPart,
} from "../containers/assets/new/updateCacheWithNewItem";
import { getDetailedAssetInfo } from "../containers/assets/all/assets.query";

import { ASSET_TYPE, ID, PARENT_ID } from "../_constant/wordings";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { updateCustomerItemCache } from "../components/ItemsTab/updateCustomerItemCache";
import {
  updateCustomerItemSelectCache,
  updateItemSelectListCache,
  updateItemPartOfSelectCache,
} from "../containers/assets/new/updateItemSelectCache";

type updateFnDateType = { data: { AssetPost: any } };
type updateFnType = (cache: DataProxy, data: updateFnDateType | any) => void;

const useCreateAssetMutation = (
  updateCacheInSelectItem?: boolean,
): [
  (data: AssetModel, hasRelatedActivities?: boolean) => Promise<ExecutionResult<any>>,
  { isCreating: boolean },
] => {
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const [createAsset, { loading: isCreating, client }] = useMutation(createNewAssetQuery);

  const selectedAssetType = useSelector((state) => getSelectedAssetType(state));
  const dispatch = useDispatch();

  const handleSave = (data: AssetModel, hasRelatedActivities = false) => {
    if (!accountId) {
      console.error("Trying to do request without account id");
      return;
    }
    const schemaWithAppropriateTypename = data.Schema.map((element) => {
      delete element.__typename;
      return element;
    });

    delete data.AssetType.ItemTypeFields;
    delete data.AssetType.DescriptionPattern;
    delete data.DefaultDescription;
    delete data._activitiesLength;

    const variables = { ...data, AccountId: accountId, Schema: schemaWithAppropriateTypename };

    const refetchQueries = [];
    const refetchPrintableViewOption = {
      query: getDetailedAssetInfo,
      variables: { Id: accountId },
    };

    try {
      if (client?.readQuery(refetchPrintableViewOption)) {
        refetchQueries.push(refetchPrintableViewOption);
      }
    } catch {}

    return createAsset({
      variables,
      refetchQueries,
      update(cache: DataProxy, { data: { AssetPost } }: any) {
        if (IsCustomerManagement) {
          updateItemPartOfSelectCache(cache, AssetPost, accountId);
          AssetPost[PARENT_ID] &&
            updateComponentIdsForParentAssetWithNewPart(cache, AssetPost, accountId);
          updateCacheInSelectItem
            ? updateCustomerItemSelectCache(cache, AssetPost, accountId, data.CustomerId)
            : updateCustomerItemCache(cache, AssetPost, accountId, data.CustomerId);
          return;
        }
        if (hasRelatedActivities) {
          AssetPost.hasRelatedActivities = hasRelatedActivities; // this is mean that current item has activities. used for wizard
        }
        // addNewItemInAssetsReferenceQuery(cache, AssetPost, accountId);
        updateItemSelectListCache(cache, AssetPost, accountId);
        updateAssetTypeListQueryWithNewItem(cache, AssetPost, accountId);
        updateItemPartOfSelectCache(cache, AssetPost, accountId);
        AssetPost[PARENT_ID] &&
          updateComponentIdsForParentAssetWithNewPart(cache, AssetPost, accountId);

        if (selectedAssetType === AssetPost[ASSET_TYPE][ID]) {
          dispatch(setSelectedAssets(selectedAssetType));
        } else {
          dispatch(setSelectedAssets());
        }
      },
    });
  };

  return [handleSave, { isCreating }];
};

export default useCreateAssetMutation;
