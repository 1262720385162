import { useBlocker, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useRouterPrompt } from "./useRedirectPrompt";
import { useLeaveDialogContext } from "../containers/_common/useLeaveDialogContext";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getEditFormDirtyFlag } from "../redux/reducers/appReducer";
import { setEditFormIsDirty } from "../redux/actions/actions";

export const useCustomRedirectPrompt = () => {
  const navigate = useNavigate();

  const {
    showPrompt,
    togglePrompt,
    deferredCallback,
    confirmedNavigation,
    lastLocation,
    toggleConfirmedNavigation,
    changeLastLocation,
    showDialogBeforeChangeUrl,
  } = useLeaveDialogContext();

  const isFormDirty = useSelector(getEditFormDirtyFlag);
  const dispatch = useDispatch();

  const hideDialogBeforeChangeUrl = () => {
    togglePrompt(false);
  };

  const cancelNavigation = useCallback(() => {
    if (deferredCallback.current) deferredCallback.current = null;
    hideDialogBeforeChangeUrl();
    changeLastLocation(null);
  }, []);

  const handleBlockedNavigation = (history) => {
    const nextLocation = history?.location;
    if (nextLocation?.pathname === lastLocation?.pathname) return true;

    if (!confirmedNavigation && isFormDirty) {
      showDialogBeforeChangeUrl();
      changeLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const confirmNavigation = () => {
    if (deferredCallback.current) deferredCallback.current();
    dispatch(setEditFormIsDirty(false));
    hideDialogBeforeChangeUrl();
    toggleConfirmedNavigation(true);
    deferredCallback.current = null;
  };

  useEffect(() => {
    if (confirmedNavigation) {
      if (lastLocation) navigate(lastLocation.pathname);
      changeLastLocation(null);
      toggleConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, isFormDirty);

  return { showPrompt, confirmNavigation, cancelNavigation };
};
