import React, { useEffect, useState } from "react";

import { WizardWelcomeContainer, WizardWelcomeScreenImageBg } from "./WizardWelcomeScreenStyles";

import WizardCard from "../WizardCard/WizardCard";

import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import FloatingInput from "../FloatingInput/FloatingInput";
import Input from "../Input/Input";

interface WizardWelcomeScreenProps {}

const WizardWelcomeScreen: React.FC<WizardWelcomeScreenProps> = ({ hello }) => {
  const [open, setOpen] = useState(true);

  return (
    <WizardWelcomeContainer>
      <WizardWelcomeScreenImageBg>
        <Typography variant="body1" gutterBottom>
          Let's get started by creating an item that you want to maintain.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          We recommend starting with something that needs maintenance now or an item that's
          important to you and you want to keep well.
        </Typography>
      </WizardWelcomeScreenImageBg>
    </WizardWelcomeContainer>
  );
};

export default WizardWelcomeScreen;
