import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import { PROJECTS_STATUSES, SYSTEM_DATA } from "../../_constant/wordings";

import { ROUTES } from "../../_constant/screens";
import { useNavigate } from "react-router";

const GET_STATUSES_LIST = gql`
  query getProjectStatuses {
    SystemData {
      ProjectStatuses {
        Description
        Id
        IsPrimary
        Label
        Name
        SortOrder
      }
    }
  }
`;

const useGetProjectsStatuses = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_STATUSES_LIST, {
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return [];
  }

  if (error) {
    navigate(ROUTES.ERROR);
    return [];
  }

  if (data && data[SYSTEM_DATA]) {
    return data[SYSTEM_DATA][PROJECTS_STATUSES].sort(
      (current, next) => current.SortOrder - next.SortOrder,
    );
  }
  return [];
};

export default useGetProjectsStatuses;
