import styled from "styled-components";
import Button from "@material-ui/core/Button/Button";

export const DueDateButton = styled(Button)`
  &.MuiButtonBase-root {
    margin-left: -4px;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }

  .MuiButton-startIcon {
    margin-right: 1rem;
  }
`;
