import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import { useMatch, useNavigate } from "react-router-dom";

import useGetBusinessStatuses from "../../customHooks/useGetBusinessStatuses";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";
import useGetAccountId from "../../customHooks/useGetAccountId";

import { VisitModel } from "../../redux/models/data/VisitModel";

import { getVisitByIdQuery } from "./getVisitById.query";
import { updateVisitMutation } from "./updateVisit.query";
import updateCacheAfterVisitUpdate from "./updateCacheAfterVisitUpdate";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { FILE_UPLOAD_RESULTS, ID, VISIT_PATCH } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";

import {
  checkUploadResult,
  convertStatusToFilterVariable,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../redux/actions/actions";
import { getActivityFilters } from "../../redux/reducers/appReducer";

import VisitFormContainer from "../../components/VisitFormContainer/VisitFormContainer";
import { formatDateForSubmit } from "../../_utils/date";

interface IMatchParams {
  id: string;
}
interface EditVisitProps {}

const EditVisit: React.FC<EditVisitProps> = () => {
  const [visitData, setVisitData] = useState(new VisitModel());

  const { accountId } = useGetAccountId();
  const statuses = useGetBusinessStatuses();
  const filterMap = useSelector((state) => getActivityFilters(state));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { successfulRedirect } = useSuccessfulRedirect();

  const match = useMatch(`${ROUTES.SINGLE_VISIT}/:id`);

  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
    pageTitle: "Edit Visit",
    headerType: "BUSINESS",
  });

  const { data, loading, error } = useQuery(getVisitByIdQuery, {
    variables: {
      AccountId: accountId,
      VisitId: match?.params?.id,
    },
    fetchPolicy: "network-only",
    skip: !accountId || !match,
    onError: () => {
      navigate(ROUTES.VISITS);
      displayErrorNotification(NotificationTypes.VISIT_ACCESSS_ERROR);
    },
  });

  useEffect(() => {
    if (!loading && !error && data && data["Visit"] !== null) {
      setVisitData(new VisitModel(data["Visit"]));
    }
  }, [data, loading, error]);

  const [updateVisit, { loading: isSaving }] = useMutation(updateVisitMutation);

  const handleCreate = (data: VisitModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }

    const imagesWithAppropriateTypename = data.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = data.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = data.Videos.map((video) => {
      delete video.__typename;
      return video;
    });

    const Files = data.Files;
    const formatDueDateTime = formatDateForSubmit(data?.DueDateTime as string);

    const dataForRequest: any = {
      ...data,
      Images: imagesWithAppropriateTypename,
      Documents: documentsWithAppropriateTypename,
      Videos: videosWithAppropriateTypename,
      AccountId: accountId,
      DueDateTime: formatDueDateTime,
    };

    delete dataForRequest.Files;
    delete dataForRequest.VisitStatusId;
    delete dataForRequest.VisitStatusName;
    delete dataForRequest.CustomerName;

    const variables = { Visit: dataForRequest, Files };
    const oldStatus = statuses.find((status) => status.Id === visitData.VisitStatusId);
    const oldFilterString = convertStatusToFilterVariable(oldStatus, filterMap, "VisitStatusId");

    updateVisit({
      variables: variables,
      update(cache, { data: { VisitPut } }) {
        const newStatus = statuses.find((status) => status.Id === VisitPut.VisitStatusId);
        const newFilterString = convertStatusToFilterVariable(
          newStatus,
          filterMap,
          "VisitStatusId",
        );
        updateCacheAfterVisitUpdate(
          cache,
          visitData,
          VisitPut,
          accountId,
          oldFilterString,
          newFilterString,
        );
      },
    })
      .then(({ data }: any) => {
        const uploadResult = data[VISIT_PATCH]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[VISIT_PATCH][ID] && saveType.type === SAVE) {
          saveType.callback(new VisitModel(data[VISIT_PATCH]));
          displaySuccessNotification(NotificationTypes.VISIT_UPDATED);
        } else if (data[VISIT_PATCH][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VisitModel(data[VISIT_PATCH]));
          successfulRedirect(ROUTES.VISITS, NotificationTypes.VISIT_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.VISIT_UPDATED_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.VISIT_UPDATED_ERROR);
      });
  };

  const isLoading = loading || isSaving;

  return (
    <VisitFormContainer
      data={visitData}
      handleSubmit={handleCreate}
      isLoading={isLoading}
      isNew={false}
      setHeaderOptions={setHeaderOptions}
    />
  );
};

export default EditVisit;
