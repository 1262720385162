import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMatch, useNavigate, useParams } from "react-router-dom";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";

import { VendorModel } from "../../redux/models/data/VendorModel";

import { updateVendorMutation } from "./updateVendor.query";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { ID, VENDOR_PUT, VENDOR, FILE_UPLOAD_RESULTS } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";

import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import VendorFormContainer from "../../components/VendorForm/VendorFormContainer";
import { getVendorById } from "./getVendorById";
import { formatDateInCustomFieldsForSubmit } from "../../_utils/date";

const EditVendor: React.FC = () => {
  const [vendorData, setVendorData] = useState(new VendorModel());
  const { accountId } = useGetAccountId();
  const match = useMatch(`${ROUTES.SINGLE_VENDOR}/:id`);
  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successfulRedirect } = useSuccessfulRedirect();

  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
    headerType: "DEFAULT",
  });

  const { data, loading, error } = useQuery(getVendorById, {
    variables: { AccountId: accountId, VendorId: params?.id },
    skip: !accountId || !match,
    fetchPolicy: "network-only",
    onError: () => {
      navigate(ROUTES.VENDORS);
      displayErrorNotification(NotificationTypes.VENDOR_ACCESS_ERROR);
    },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setVendorData(new VendorModel(data[VENDOR]));
    }
  }, [data, loading, error]);

  const [updateVendor, { loading: isUpdating }] = useMutation(updateVendorMutation);

  const handleUpdate = (data: VendorModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }
    const Phone = data.Phone.length ? Number(data.Phone.replace(/\D+/g, "")).toString() : "";
    const { Files } = data;

    const imagesWithAppropriateTypename = data.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = data.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = data.Videos.map((video) => {
      delete video.__typename;
      return video;
    });
    const schemaWithAppropriateTypename = data.Schema.map((element) => {
      delete element.__typename;
      return element;
    });

    const dataForPost: any = { ...data, Phone, AccountId: accountId };
    delete dataForPost.Files;
    formatDateInCustomFieldsForSubmit(data?.CustomFields);

    const variables = {
      Vendor: {
        ...dataForPost,
        Images: imagesWithAppropriateTypename,
        Documents: documentsWithAppropriateTypename,
        Videos: videosWithAppropriateTypename,
        Schema: schemaWithAppropriateTypename,
      },
      Files,
    };

    updateVendor({
      variables: variables,
      update(cache, { data: { VendorPost } }) {
        // TODO: handle cache
        // updateCacheWithNewCustomer(cache, CustomerPost, accountId);
      },
    })
      .then(({ data }: any) => {
        const uploadResult = data[VENDOR_PUT]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[VENDOR_PUT][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VendorModel(data[VENDOR_PUT]));
          displaySuccessNotification(NotificationTypes.VENDOR_UPDATED);
        } else if (data[VENDOR_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VendorModel(data[VENDOR_PUT]));
          successfulRedirect(ROUTES.VENDORS, NotificationTypes.VENDOR_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.VENDOR_UPDATED_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.VENDOR_UPDATED_ERROR);
      });
  };

  return (
    <VendorFormContainer
      data={vendorData}
      setHeaderOptions={setHeaderOptions}
      handleSubmit={handleUpdate}
      isNew={false}
      isFetching={loading}
      isSaving={isUpdating}
    />
  );
};

export default EditVendor;
