import React from "react";

interface SectionProps {
  withBorder?: boolean;
  background?: "none" | "default";
}

const Section: React.FC<SectionProps> = ({ children, withBorder, background }) => {
  return (
    <section
      className={`Section${withBorder ? " Section_withBorder" : ""} ${
        background === "default" ? " Section_defaultBackground" : ""
      }`}
    >
      {children}
    </section>
  );
};

export default Section;
