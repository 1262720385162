import React, { useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { toastr } from "react-redux-toastr";

import { FieldTypes } from "../../ItemCustomFields/ItemCustomFieldsTypeOptions";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { getVim } from "./getVim.query";

import { displayErrorNotification } from "../../../redux/actions/_utils";

import CustomField from "../../CustomField/CustomField";
import SmartFieldButton from "./SmartFieldButton";

import { CustomFieldStyled, SmartFieldContainer } from "./VimSmartField.style";

interface VimSmartField {
  type: FieldTypes;
  name: string;
  value: string;
  defaultDescriptionPatern: string[];
  isSelectWritable?: boolean;
  options?: { value: string; label: string }[];
  AssetFieldValues: { [key: string]: any };
  onChange: (e: any) => void;
  multipleUpdate: (newData: Partial<any>) => void;
}

const VimSmartField: React.FC<VimSmartField> = (props) => {
  const {
    name,
    type,
    options,
    isSelectWritable,
    onChange,
    value,
    multipleUpdate,
    AssetFieldValues,
    defaultDescriptionPatern,
  } = props;

  const handleError = (error: any) => {
    // error message contains 'GraphQL.ExecutionError' substring on dev
    const message = error?.message?.includes("GraphQL")
      ? error?.message.split("GraphQL.ExecutionError: ").slice(1).join(" ")
      : error?.message;
    message ? toastr.error(message) : displayErrorNotification(NotificationTypes.UNEXPECTED_ERROR);
  };

  const [getVimData, { data, loading, error }] = useLazyQuery(getVim, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    onError: handleError,
  });

  useEffect(() => {
    if (data && !loading && !error) {
      const dataFromVin = data?.GetVehicleInfoByVIN ?? {};
      const finalData = { ...AssetFieldValues, ...dataFromVin };

      const newDefaultDescription = defaultDescriptionPatern.reduce((acc, el) => {
        if (finalData[el]) {
          acc.push(finalData[el]);
        }
        return acc;
      }, [] as string[]);

      multipleUpdate({
        TypeFieldValues: finalData,
        DefaultDescription: newDefaultDescription.join(" ").trim(),
      });
    }
  }, [data, loading, error]);

  const handleClick = () => {
    getVimData({ variables: { VIN: value } });
  };

  return (
    <SmartFieldContainer>
      <CustomFieldStyled>
        <CustomField
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          isSelectWritable={isSelectWritable}
        />
      </CustomFieldStyled>
      <SmartFieldButton handleClick={handleClick} loading={loading} />
    </SmartFieldContainer>
  );
};

export default VimSmartField;
