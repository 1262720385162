import * as React from "react";
import Icon from "../../../../_constant/Icons";

interface AssetIconsProps {
  selectedIcon: string;
  handleIconPick: (key: string) => void;
}

export const AssetIcons: React.FC<AssetIconsProps> = ({selectedIcon, handleIconPick}) => (
  <ul className="AssetIcons">
    {Array.from(Icon.values()).map((icon) => (
      <li key={icon}
          className={`AssetIcons__item${selectedIcon === icon ? " is-active" : ""}`}
          onClick={() => handleIconPick(icon)}
      >
        <i className="Icon">{icon}</i>
      </li>
    ))}
  </ul>
);
