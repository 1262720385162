import ApolloClient, { FetchPolicy } from "apollo-client";
import React, { SyntheticEvent, useState } from "react";

import { dataApiInterface } from "../../customHooks/useChange";
import { ProjectModel } from "../../redux/models/data/ProjectModel";

export interface ProjectFormPageLayoutProps {
  client: ApolloClient<any>;
}

interface ProjectFormContextProviderProps {
  isNew: boolean;
  formApi: dataApiInterface<ProjectModel>;
  dropZone: any;
  lockSaveButton: boolean;
  handleSubmit: (e?: SyntheticEvent<Element, Event> | null | undefined, justSave?: boolean) => void;
  isLoading: boolean;
  isSaving: boolean;
  isFetching: boolean;
  setHeaderOptions: (props: any) => void;
}

interface ProjectFormProps extends ProjectFormContextProviderProps {
  isFetching: boolean;
  isCustomize: boolean;
  isWizardOpen: boolean;
  isRevertPopupVisible: boolean;
  isDeletePopupVisible: boolean;
  formApi: dataApiInterface<ProjectModel>;
  handleCustomize: (val?: boolean) => void;
  handleOpenWizard: () => void;
  handleCloseWizard: () => void;
  setIsRevertPopupVisible: (value: boolean) => void;
  setIsDeletePopupVisible: (value: boolean) => void;
  setCachePolicyForActivityTab: (policy: FetchPolicy) => void;
  activitiesTabFetchPolicy: FetchPolicy;
  isProjectStarted: boolean;
  isProjectFinished: boolean;
  handleStartProject: (isStarted: boolean) => void;
  handleProjectFinished: (isFinished: boolean) => void;
  isFinishProjectDialogVisible: boolean;
  handleFinishProjectDialog: (isOpen: boolean) => void;
}

export const ProjectFormContext = React.createContext<ProjectFormProps>({} as ProjectFormProps);

export const ProjectFormContextProvider: React.FC<ProjectFormContextProviderProps> = (props) => {
  const { children, formApi, ...rest } = props;
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isFinishProjectDialogVisible, setIsFinishProjectDialogVisible] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCustomize, setIsCustomize] = useState(false);
  const [activitiesTabFetchPolicy, setActivitiesTabFetchPolicy] = useState<FetchPolicy>(
    "network-only",
  );
  const [isProjectStarted, setIsProjectStarted] = useState(false);
  const [isProjectFinished, setIsProjectFinished] = useState(false);

  const setCachePolicyForActivityTab = (policy: FetchPolicy) => {
    setActivitiesTabFetchPolicy(policy);
  };
  const handleCustomize = React.useCallback(
    (val) => (typeof val === "boolean" ? setIsCustomize(val) : setIsCustomize((prev) => !prev)),
    [],
  );
  const handleOpenWizard = React.useCallback(() => setIsWizardOpen(true), [isWizardOpen]);
  const handleCloseWizard = React.useCallback(() => setIsWizardOpen(false), [isWizardOpen]);
  const handleStartProject = React.useCallback((isStarted) => setIsProjectStarted(isStarted), [
    isProjectStarted,
  ]);
  const handleProjectFinished = React.useCallback(
    (isFinished) => setIsProjectFinished(isFinished),
    [isProjectFinished],
  );
  const handleFinishProjectDialog = (isOpen: boolean) => setIsFinishProjectDialogVisible(isOpen);

  return (
    <ProjectFormContext.Provider
      value={{
        ...rest,
        isCustomize,
        handleCustomize,
        isRevertPopupVisible,
        isWizardOpen,
        handleOpenWizard,
        handleCloseWizard,
        isDeletePopupVisible,
        setIsRevertPopupVisible,
        setIsDeletePopupVisible,
        formApi,
        setCachePolicyForActivityTab,
        activitiesTabFetchPolicy,
        isProjectStarted,
        isProjectFinished,
        handleStartProject,
        handleProjectFinished,
        isFinishProjectDialogVisible,
        handleFinishProjectDialog,
      }}
    >
      {children}
    </ProjectFormContext.Provider>
  );
};
