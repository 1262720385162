import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";

import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetAcitvitiesStatuses from "../../customHooks/api/useGetAcitvitiesStatuses";

import ActivityItem from "../../containers/activities/_components/ActivityItem";

import { LayoutType } from "../../_constant/constants";
import { GET_CUSTOMER_ACTIVITIES } from "./getCustomerActivities.query";
import { getStartedVisitActivities } from "../../pages/EditVisit/getStartedVisitActivities.query";
import {
  CUSTOMER,
  FINISHED_VISIT_ACTIVITIES,
  ITEM_ACTIVITIES,
  STARTED_VISIT_ACTIVITIES,
  VISIT,
} from "../../_constant/wordings";
import * as statusesConfig from "../../../configs/statuses-config.json";
import {
  addCustomerIdToUrl,
  alphabetNameSorting,
  dateSorting,
  getVisitBooleanStatus,
} from "../../_utils/utils";

import { CircularProgress, Box, useTheme } from "@material-ui/core";

import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";
import ActiveActivityCard from "../ActiveActivityCard/ActiveActivityCard";
import CreateNewButtonMobile, {
  CreateNewEntityContainer,
} from "../CreateNewButtonMobile/CreateNewButtonMobile";
import { ROUTES } from "../../_constant/screens";

import { getFinishedVisitActivities } from "../../pages/EditVisit/getFinishedVisitActivities";
import { VisitModel } from "../../redux/models/data/VisitModel";
import Hidden from "@material-ui/core/Hidden";
const { FILL, FINISH, IN_PROGRESS } = statusesConfig["statusNameMap"];

interface ActivitiesTabProps {
  visit: VisitModel;
  isVisitLoading: boolean;
}

const ActivitiesTab: React.FC<ActivitiesTabProps> = ({ visit, isVisitLoading }) => {
  const { CustomerId = "", Id: visitId, VisitStatusName } = visit;
  const { isPersonal, accountId } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();

  const navigate = useNavigate();

  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(true);
  const [doneStatusId, setDoneStatusId] = useState("");

  const { isVisitFinished, isVisitStarted } = getVisitBooleanStatus(VisitStatusName);

  const [
    getCustomerActivities,
    { data: customerActivitiesData, loading: customerActivitiesLoading },
  ] = useLazyQuery(GET_CUSTOMER_ACTIVITIES);

  const [
    getStartedActivities,
    { data: startedActivitiesData, loading: startedActivitiesLoading },
  ] = useLazyQuery(getStartedVisitActivities, { fetchPolicy: "network-only" });

  const [
    getFinishedActivities,
    { data: finishedActivitiesData, loading: finishedActivitiesLoading },
  ] = useLazyQuery(getFinishedVisitActivities, { fetchPolicy: "cache-first" });

  const getActivities = () => {
    if (!accountId || !CustomerId || !VisitStatusName) return;

    if (isVisitStarted) {
      return getStartedActivities({
        variables: {
          AccountId: accountId,
          VisitId: visitId,
        },
      });
    }

    if (isVisitFinished) {
      return getFinishedActivities({
        variables: {
          AccountId: accountId,
          VisitId: visitId,
        },
      });
    }

    getCustomerActivities({
      variables: {
        AccountId: accountId,
        CustomerId: CustomerId,
      },
    });
  };

  useEffect(() => {
    getActivities();
  }, [accountId, CustomerId, VisitStatusName]);

  useEffect(() => {
    if (statuses) {
      const foundStatus = statuses.find((status) => status.Name === FILL);

      if (foundStatus) {
        setDoneStatusId(foundStatus.Id);
      }
    }
  }, [statuses]);

  useEffect(() => {
    if (
      !isVisitLoading &&
      !customerActivitiesLoading &&
      !startedActivitiesLoading &&
      !finishedActivitiesLoading &&
      (startedActivitiesData || customerActivitiesData || finishedActivitiesData)
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [
    isVisitLoading,
    customerActivitiesLoading,
    customerActivitiesData,
    startedActivitiesLoading,
    startedActivitiesData,
    finishedActivitiesLoading,
    finishedActivitiesData,
  ]);

  const handleCreate = () => {
    navigate(addCustomerIdToUrl(ROUTES.CREATE_ACTIVITY, CustomerId || ""));
  };

  if (loading) {
    return (
      <div style={{ height: "100%", flexGrow: 1, padding: "1rem", textAlign: "center" }}>
        <CircularProgress size={22} />
      </div>
    );
  }

  const sortActivities = (visitActivities: any[] | undefined) => {
    if (!visitActivities) return [];
    return dateSorting(visitActivities.sort(alphabetNameSorting), "DueDateTime", "asc");
  };

  const filterActivities = (visitActivities: any[] | undefined) => {
    if (!visitActivities || !doneStatusId) return [];
    return dateSorting(
      visitActivities
        .filter((activity: any) => activity.StatusId !== doneStatusId)
        .sort(alphabetNameSorting),
      "DueDateTime",
      "asc",
    );
  };

  const activities = isVisitStarted
    ? sortActivities(startedActivitiesData?.[VISIT]?.[STARTED_VISIT_ACTIVITIES])
    : isVisitFinished
    ? sortActivities(finishedActivitiesData?.[VISIT]?.[FINISHED_VISIT_ACTIVITIES])
    : filterActivities(customerActivitiesData?.[CUSTOMER]?.[ITEM_ACTIVITIES]);

  const renderActivities = () => {
    return isVisitStarted
      ? activities.map((activity: any) => {
          return (
            <ActiveActivityCard
              key={activity.Id}
              activity={activity}
              dateType={activity.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
              layout={LayoutType.LIST}
              isAccountPersonal={isPersonal || false}
              isAssignmentsVisible={false}
            />
          );
        })
      : activities.map((activity: any) => {
          return (
            <ActivityItem
              key={activity.Id}
              activity={activity}
              dateType={activity.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
              layout={LayoutType.LIST}
              isAccountPersonal={isPersonal || false}
              isAssignmentsVisibile={false}
              customerId={CustomerId}
            />
          );
        });
  };

  return (
    <Box>
      {!!activities.length && (
        <CreateNewEntityContainer>
          <CreateNewButtonMobile handleCreate={handleCreate} disabled={!CustomerId}>
            Activity
          </CreateNewButtonMobile>
        </CreateNewEntityContainer>
      )}
      {activities.length ? (
        <Box padding="1rem" paddingTop="">
          <Box overflow="hidden" borderRadius="8px" border={`1px solid ${theme.palette.greyDesign.onSurface3}`}>
            {renderActivities()}
          </Box>
        </Box>
      ) : (
        <>
          <Box padding="1rem" paddingTop="">
            <EmptyCustomFieldState emptyFieldsMessage="There are no activities" />
          </Box>
          <Hidden smUp>
            <CreateNewButtonMobile handleCreate={handleCreate} disabled={!CustomerId}>
              Activity
            </CreateNewButtonMobile>
          </Hidden>
        </>
      )}
    </Box>
  );
};

export default ActivitiesTab;
