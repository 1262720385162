import React from "react";

import Button from "@material-ui/core/Button";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { WizardWelcomeScreenFooterText } from "./WizardWelcomeScreenStyles";

interface WizardWelcomeScreenFooterProps {}

const WizardWelcomeScreenFooter: React.FC<WizardWelcomeScreenFooterProps> = ({ navigate }) => {
  return (
    <>
      <WizardWelcomeScreenFooterText>
        Create a new item by clicking here:
      </WizardWelcomeScreenFooterText>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={() => navigate()}
      >
        Item
      </Button>
    </>
  );
};

export default WizardWelcomeScreenFooter;
