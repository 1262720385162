import gql from "graphql-tag";

export const reactivateInvitationMutation = gql`
  mutation reactivateInvite($InvitationId: Guid!) {
    ActivateInvite(InvitationId: $InvitationId) {
      Account {
        Id
        Name
        Description
      }
      CreateDateTime
      Id
      Message
      Sender {
        Id
        FirstName
        LastName
        Images {
          Id
          ContainerId
          ContainerName
          Description
          FileExtension
          Name
        }
      }
      State
      UpdateDateTime
    }
  }
`;
