import { getIdentityConfig, getMetadataOidc } from "./AuthConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { ROUTES } from "../_constant/screens";
import { clearLocalStorageExceptCurrentAccount } from "../_utils/utils";

export default class AuthService {
  UserManager;
  accessToken;
  _expired;
  isExpired;

  constructor({ authUrl, appUrl, clientId }) {
    this.UserManager = new UserManager({
      ...getIdentityConfig(authUrl, appUrl, clientId),
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...getMetadataOidc(authUrl),
      },
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    const token = localStorage.getItem("access_token");
    if (token) {
      this._expired = this.parseJwt(token).exp;
    }

    this.isExpired = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      return currentTime >= this._expired;
    };

    this.UserManager.events.addUserLoaded((user) => {
      console.log("addUserLoaded start");
      this.accessToken = user.access_token;
      localStorage.setItem("access_token", user.access_token);
      localStorage.setItem("id_token", user.id_token);
      localStorage.setItem("refresh_token", user.refresh_token);
      this.setUserInfo({
        accessToken: user.access_token,
        idToken: user.id_token,
        refreshToken: user.refresh_token,
      });

      const redirectUri = !!localStorage.getItem("startPage")
        ? localStorage.getItem("startPage")
        : ROUTES.ASSETS;

      console.log("addUserLoaded end");
      if (window.location.search && window.location.search.length > 0) {
        window.location.replace(redirectUri);
      }
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    console.log("signinRedirectCallback start");
    this.UserManager.signinRedirectCallback()
      .then((info) => {
        // don't know
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin + window.location.pathname,
        );
        // window.location = "index.html";
        console.log("signinRedirectCallback body", info);
      })
      .catch((e: Error) => console.error(e));
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    if (user.expired) {
      return await this.UserManager.signinSilent();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  setUserInfo = (authResult) => {
    const data = this.parseJwt(authResult.accessToken);
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("refresh_token", authResult.refreshToken);
    localStorage.setItem("userId", data.sub);
    this._expired = data.exp;
  };

  signinRedirect = () => {
    console.log("signinRedirect start");
    clearLocalStorageExceptCurrentAccount();
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  signinSilentCallback = () => {
    console.log("signinSilentCallback start");
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  signinSilent = () => {
    console.log("signinSilent start");
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        const homeUrl = window.localStorage.appUrl;
        console.log(err);
        console.log("signinSilent failed");
        console.log("error, redirecting to: ", homeUrl);
        clearLocalStorageExceptCurrentAccount();
        window.location.href = homeUrl;
      });
  };

  logout = async () => {
    console.log("logout start");
    await this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });

    localStorage.clear();
    await this.UserManager.clearStaleState();
  };
}
