import { toastr } from "react-redux-toastr";
import moment from "moment";
import {
  NotificationTypes,
  ERROR_NOTIFICATIONS,
  SUCCESS_NOTIFICATIONS,
  WARNING_NOTIFICATIONS,
} from "../../_constant/NotificationTypes";
import { FILTERING_DETAILS, SORTING_DETAILS } from "../../_constant/ActivityDateConstant";
import { formatDate } from "../../_utils/date";
import { useNavigate } from "react-router-dom";

type FileUploadStatus = "REJECTED" | "ACCEPTED";

interface FileUploadResult {
  ErrorMessages: string[] | null;
  FileName: string;
  Status: FileUploadStatus;
}

export const checkUploadResult = (uploadResult: FileUploadResult[]) => {
  const rejectedFiles = uploadResult.filter((resultItem: any) => resultItem.Status === "REJECTED");

  if (rejectedFiles.length) {
    displayFailedUploadResult(rejectedFiles);
  }
};

export const displayFailedUploadResult = (rejectedFiles: FileUploadResult[]) => {
  rejectedFiles.forEach((error: FileUploadResult) => {
    if (error.ErrorMessages?.length) {
      error.ErrorMessages.forEach((message) => {
        toastr.error(message);
      });
    } else {
      toastr.error(`${error.FileName} was not uploaded`);
    }
  });
};

export function successfulRedirect(path: string, notification: NotificationTypes) {
  // navigate(path);
  toastr.success(SUCCESS_NOTIFICATIONS.get(notification));
}

export function displayErrorNotification(error: NotificationTypes) {
  toastr.error(ERROR_NOTIFICATIONS.get(error));
}

export function displayWarningNotification(warningMsg: NotificationTypes) {
  toastr.warning(WARNING_NOTIFICATIONS.get(warningMsg));
}

export function displaySuccessNotification(notification: NotificationTypes) {
  toastr.success(SUCCESS_NOTIFICATIONS.get(notification));
}

export function getFilterString(props, fieldName = "StatusId"): string {
  const { id, name, offset } = props;
  if (!offset) {
    // no date offset - request all
    return `${fieldName}=="${id}"`;
  }
  const { field } = SORTING_DETAILS.get(name); // get field name for apply filtering
  const { order } = FILTERING_DETAILS.get(name);
  if (offset === "all") {
    return `${fieldName}=="${id}"`;
  }
  const dateOffset = isNaN(+offset) ? [1, "years"] : [+offset === 1 && order === "after" ? +offset+1 : +offset, "days"];
  const sign = order === "after" ? "<" : ">";
  const deadline =
    order === "after"
      ? formatDate(moment().add(...dateOffset))
      : formatDate(moment().subtract(...dateOffset));

  return `${fieldName}=="${id}" AND (${field}${sign}'${deadline}' OR ${field}==null)`;
}

const offset = (status: any) =>
  FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)?.selected;

export function convertStatusToFilterVariable(
  status: any,
  filterMap: Map<string, string>,
  fieldName: undefined | string = undefined,
) {
  const filterObj = {
    id: status.Id,
    name: status.Name,
    offset: filterMap.get(status.Id) || offset(status),
  };
  return getFilterString(filterObj, fieldName);
}
