import gql from "graphql-tag";

export const assignUserToProject = gql`
  mutation assignUserToProject($AccountId: ID, $Id: ID, $AssignedUserId: ID, $Name: String) {
    ProjectPatch(
      Project: { AccountId: $AccountId, Id: $Id, AssignedUserId: $AssignedUserId, Name: $Name }
    ) {
      Id
      AssignedUserId
      AccountId
      Name
    }
  }
`;
