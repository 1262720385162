import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";

import { ACCOUNT, ITEM_ACTIVITIES, USER, VISITS } from "../_constant/wordings";
import { ROUTES } from "../_constant/screens";

import { GET_INVITATION_IDS } from "../routing/redirection/invitations-ids.query";
import { GET_ACTIVITIES_IDS } from "../routing/redirection/activities-ids.query";
import { CHECK_VISITS_EXIST } from "../routing/redirection/visits-ids.query";

import ApolloClient from "apollo-client";

interface RedirectOption {
  accountId: string | null;
  IsCustomerManagement: boolean;
}

const checkInvitationExist = async (client: ApolloClient<object>) => {
  try {
    const { data } = await client.query({
      query: GET_INVITATION_IDS,
      fetchPolicy: "network-only",
    });

    return data && data[USER] && data[USER]["MyInvitations"].length > 0;
  } catch (error) {
    return false;
  }
};

const checkVisitExist = async (client: ApolloClient<object>, accountId: string) => {
  try {
    const { data }: any = await client.query({
      query: CHECK_VISITS_EXIST,
      variables: { Id: accountId },
    });

    return data && data[ACCOUNT] && data[ACCOUNT][VISITS].length > 0;
  } catch (error) {
    return false;
  }
};

const checkActivitiesExist = async (client: ApolloClient<object>, accountId: string) => {
  try {
    const { data }: any = await client.query({
      query: GET_ACTIVITIES_IDS,
      variables: { Id: accountId },
    });

    return data && data[ACCOUNT] && data[ACCOUNT][ITEM_ACTIVITIES].length > 0;
  } catch (error) {
    return false;
  }
};

const useInitialPath = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const optionalRedirect = async (
    { accountId, IsCustomerManagement }: RedirectOption,
    invitationCheck = false,
  ) => {
    if (!accountId || !client) {
      return;
    }

    if (invitationCheck) {
      const res = await checkInvitationExist(client);

      if (res) {
        navigate(ROUTES.ACCOUNTS, { replace: true, state: { firstLoad: true } });
        return;
      }
    }

    if (IsCustomerManagement) {
      const res = await checkVisitExist(client, accountId);
      res
        ? navigate(ROUTES.VISITS, { replace: true })
        : navigate(ROUTES.CUSTOMERS, { replace: true });
      return;
    }

    const res = await checkActivitiesExist(client, accountId);
    res
      ? navigate(ROUTES.ACTIVITIES, { replace: true })
      : navigate(ROUTES.ASSETS, { replace: true });
  };
  return {
    optionalRedirect,
  };
};

export default useInitialPath;
