import styled from "styled-components";

export const InfoTabContainer = styled.div`
  height: 100%;
  flex-grow: 1;
`;

export const FormFilesContainer = styled.section`
  width: 100%;
  padding: 1rem;
`;
