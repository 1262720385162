import * as types from "../actions/actionTypes";
import { FilterModel } from "../models/data/FilterModel";

export default function dataInfo(state = new FilterModel(), action) {
  switch (action.type) {
    case types.SELECT_ASSETS_TO_DISPLAY:
      return state.withSelectedAssets(action.props);
    case types.UPDATE_SEARCH_ASSET_QUERY:
    case types.UPDATE_SEARCH_ACTIVITIES_QUERY:
      return state.withUpdatedSearchQuery(action.props);
    case types.UPDATE_SEARCH_ASSET_TAGS_QUERY:
      return state.withUpdateFilterTagsValue(action.props);
    case types.SET_ACTIVITIES_FILTER_VALUE:
      return state.withUpdateFilterValue(action.props);
    case types.SET_CUSTOMER_ID:
      return state.withSelectedCustomer(action.props);
    case types.SET_MEMBER_ID:
      return state.withSelectedMember(action.props);
    default:
      return state;
  }
}

export function getSelectedAssetType(state): string {
  return state.dataInfo.getSelectedAssetType();
}

export function getSelectedAssetTypeItemsIds(state): string {
  return state.dataInfo.getSelectedAssetTypeItemsIds();
}

export function getSearchStringValue(state): string {
  return state.dataInfo.searchStringValue;
}

export function getSelectedAssetIds(state): Set<string> {
  return state.dataInfo.getSelectedAssetIds();
}

export function getFilterValue(state): string {
  return state.dataInfo.getFilterValue();
}

export function getSelectedCustomerId(state): string {
  return state.dataInfo.getCustomerId();
}

export function getSelectedMemberId(state): string {
  return state.dataInfo.getMemberId();
}

export function getSelectedAssetsTags(state): string[] {
  return state.dataInfo.getAssetsTags();
}
