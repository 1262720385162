import * as React from "react";
import {ActivityScheduleModel} from "../../../../../../../redux/models/data/ActivityScheduleModel";
import {incrementDateDays} from "../../../../../../../_utils/date";
import {FullActivityDueDate} from "../../../../../../../redux/models/data/ActivityModelFull";
import {optionsDaysOfWeek, RepeatAfter} from "../../../../../../../_constant/constants";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {ToggleButton} from "@material-ui/lab";
import moment from "moment";
import {useEffect, useState} from "react";

interface DayOfWeekButtonGroupProps {
  dueDate: string;
  initialDayOfWeek: {label: string, value: string};
  initialWeekInMonth: {label: string, value: string};
  additionalProps: ActivityScheduleModel;
  onChange: (value: FullActivityDueDate) => void;
}

const DayOfWeekButtonGroup: React.FC<DayOfWeekButtonGroupProps> = (props: DayOfWeekButtonGroupProps) => {
  const {additionalProps, dueDate, initialDayOfWeek} = props;
  const daysOfWeekArray = optionsDaysOfWeek.map((item) => item.value);
  const isDisable = additionalProps.RecurringRepeatType !== RepeatAfter.DUE_DATE;
  const [day, setDay] = useState(additionalProps.DayOfWeek || initialDayOfWeek.value);

  useEffect(() => {
    const newDate = moment(dueDate);
    if (newDate.isValid()) {
      setDay(daysOfWeekArray[newDate.days()])
    }
  }, [dueDate]);

  const handleChange = (nextValue) => {
    if (nextValue === null) {
      return;
    }
    const daysDiff = daysOfWeekArray.indexOf(nextValue) - daysOfWeekArray.indexOf(initialDayOfWeek.value);
    const newDate = moment(dueDate).isValid() ? dueDate : moment();
    const date = incrementDateDays(newDate, daysDiff);

    const schedule = new ActivityScheduleModel(additionalProps);
    schedule.DayOfWeek = nextValue;
    schedule.RecurringRepeatType = RepeatAfter.DUE_DATE;
    props.onChange({dueDate: date.toISOString(), schedule});
  };

  return (
    <div className="DayOfWeek">
      <span className="DayOfWeek__label">Repeat on</span>
        <ToggleButtonGroup
          value={day}
          exclusive
          onChange={(e, newValue) => {

            handleChange(newValue)
          }}
          aria-label="day of week"
          className="DayOfWeek__buttons"
        >
        {optionsDaysOfWeek.map((item) => (
          <ToggleButton
            value={item.value}
            className={`DayOfWeekBtn${isDisable ? " DayOfWeekBtn_disabled" : ""}`}
            key={item.label}
          >
            <span>{item.label.charAt(0)}</span>
          </ToggleButton>
        ))}
        </ToggleButtonGroup>
      </div>
  );
};

export default DayOfWeekButtonGroup;
