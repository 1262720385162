import * as React from "react";
import { connect } from "react-redux";
import { updateClientDetails } from "../redux/actions/actions";
import { getClientId } from "../redux/reducers/appReducer";

interface ConfigWrapperProps {
  configUploaded: boolean;
  requestConfig: () => void;
}

class ConfigWrapper extends React.Component<any> {
  componentDidMount() {
    this.props.requestConfig();
  }

  render() {
    if (!this.props.configUploaded) {
      return null;
    }

    return <>{this.props.children}</>;
  }
}

const mapStateToProps = (state: any) => ({
  configUploaded: !!getClientId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  requestConfig: () =>
    dispatch((dispatch: any) => {
      const headers = { "Content-Type": `application/json`, Accept: "*/*" };
      const brand = process.env.REACT_APP_BRAND ?? "default";

      return fetch(`/brands/${brand}/web-config.json`, { method: "GET", headers })
        .then((response) => response.json())
        .then((data) => {
          // this is used for apollo client static creation
          window.localStorage.setItem("serverUrl", data["UpkeeprApi.Uri"]);
          // set all to store
          data.clientId = process.env.REACT_APP_CLIENT_ID;
          dispatch(updateClientDetails(data));
        });
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigWrapper);
