import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { getInvitedByMe } from "../InvitationList/InvitationList.query";

import { InvitationStateOptions } from "../../_constant/constants";

import { MY_INVITES, USER } from "../../_constant/wordings";

export const updateCacheWithNewInvitation = (
  cache: DataProxy,
  newInvitation: any,
  accountId: string,
) => {
  try {
    const invitationListQuery: any = cache.readQuery({
      query: getInvitedByMe(accountId, [
        InvitationStateOptions.active,
        InvitationStateOptions.declined,
        InvitationStateOptions.ignored,
      ]),
    });
    cache.writeQuery({
      query: getInvitedByMe(accountId, [
        InvitationStateOptions.active,
        InvitationStateOptions.declined,
        InvitationStateOptions.ignored,
      ]),
      data: {
        User: {
          ...invitationListQuery[USER],
          InvitedByMe: [...invitationListQuery[USER][MY_INVITES], newInvitation],
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
};
