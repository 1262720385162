import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import { Badge, IconButton, InputBase } from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

import { getSearchStringValue, getSelectedAssetsTags } from "../redux/reducers/filterReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchString,
  updateProjectsEmptyStatus,
  updateVisitEmptyStatus,
} from "../redux/actions/actions";
import { isPageWithItemSearchByTags, isPageWithSearch } from "../redux/actions/updateData";
import SearchTagsAutocompleteItems from "./SearchTagAutocomplete/SearchTagsAutocompleteItems";

interface SearchProps {
  placeholder?: string;
  mobile?: boolean;
}

const Search: React.FC<SearchProps> = ({ placeholder = "What are you looking for?", mobile }) => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => getSearchStringValue(state));
  const location = useLocation();
  const tags = useSelector((state) => getSelectedAssetsTags(state));
  const [expanded, setExpanded] = useState(false);

  const inputElement = useRef(null);
  const desktopInputElement = useRef(null);

  const focusInput = () => {
    inputElement.current.focus();
    inputElement.current.select();
  };

  useEffect(() => {
    if (inputElement.current && expanded) {
      focusInput();
    }
  }, [expanded]);

  useEffect(() => {
    return () => {
      dispatch(setSearchString(""));
    };
  }, []);

  const renderSearchIcon = (expanded = false, formDirty = false): React.ReactNode => {
    if (expanded) {
      return <ArrowBackIcon />;
    }

    if (formDirty) {
      return <ClearOutlinedIcon />;
    }

    return <SearchOutlinedIcon />;
  };

  const handleChange = ({ target }: { target: HTMLInputElement }) => {
    const value = target.value;

    dispatch(updateProjectsEmptyStatus({ reset: true }));
    dispatch(updateVisitEmptyStatus({ reset: true }));

    dispatch(setSearchString(value));
  };

  if (mobile) {
    return (
      <>
        <IconButton onClick={() => setExpanded(true)}>
          <Badge variant="dot" invisible={!searchValue || !isPageWithSearch(location.pathname)}>
            <SearchOutlinedIcon />
          </Badge>
        </IconButton>

        <div className={`Search ${expanded ? "Search--expanded" : ""}`}>
          <div className="Search__button">
            <IconButton onClick={() => setExpanded(!expanded)} edge="start">
              {renderSearchIcon(expanded)}
            </IconButton>
          </div>
          <div className="Search__form">
            {isPageWithItemSearchByTags(location.pathname) ? (
              <SearchTagsAutocompleteItems
                placeholder={placeholder}
                searchValue={searchValue}
                limitTags={1}
                expanded={expanded}
              />
            ) : (
              <InputBase
                placeholder={placeholder}
                inputRef={inputElement}
                endAdornment={<SearchOutlinedIcon />}
                value={searchValue}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  if (isPageWithItemSearchByTags(location.pathname))
    return <SearchTagsAutocompleteItems placeholder={placeholder} searchValue={searchValue} />;

  //old search form
  return (
    <div className="Search__form">
      <InputBase
        placeholder={placeholder}
        inputRef={inputElement}
        endAdornment={<SearchOutlinedIcon />}
        value={searchValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;
