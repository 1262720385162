import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Collapse } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { AssetTypeModel } from "../../../redux/models/data/AssetTypeModel";
import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";

import { getWizardDataQuery } from "../WizardContainer/getWizardData.query";
import { WIZARD_SCREENS_NAME } from "../WizardContainer/WizardConstants";

import FoundItem from "./FoundItem";
import EmptyState from "./EmptyState";
import InputAdornment from "./InputAdornment";
import Skeleton from "./Skeleton";

import {
  AssetTypeSkeleton,
  FoundItemsList,
  PopularSection,
  PopularSectionHeader,
  PopularSectionHeaderAction,
  PopularSectionList,
  PopularSectionTitle,
  WizardSearchScreenContainer,
  WizardSearchScreenInput,
  WizardSearchScreenItemTypeCard,
  WizardSearchScreenLogo,
  WizardSearchScreenLogoContainer,
  WizardSearchScreenLogoDescription,
} from "./WizardSearchScreenStyles";

interface WizardSearchScreenProps {
  handleChangeView: () => void;
  newItem: AssetForWizardModel;
  itemTypes: AssetTypeModel[];
  handleClick: (id: string) => void;
  itemsMap: Map<string, AssetTypeModel>;
  updateItem: (item: AssetForWizardModel) => void;
  setStep: any;
  typesMap: Map<string, []>;
  updateTypesMap: (typesMap: Map<string, []>) => void;
}

const WizardSearchScreen: React.FC<WizardSearchScreenProps> = ({
  handleChangeView,
  newItem,
  itemTypes,
  handleClick,
  itemsMap,
  updateItem,
  setStep,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const slicedItemTypes = itemTypes.slice(0, 6);

  const [assetsMap, setAssetsMap] = useState(new Map());

  const { data, loading, error } = useQuery(getWizardDataQuery);

  useEffect(() => {
    if (data?.WizardData) {
      const newMap = new Map();
      data["WizardData"].forEach((asset: any) => {
        if (!newMap.get(asset.AssetTypeId)) {
          newMap.set(asset.AssetTypeId, [asset]);
        } else {
          const oldList = newMap.get(asset.AssetTypeId);
          newMap.delete(asset.AssetTypeId);
          newMap.set(asset.AssetTypeId, [...oldList, asset]);
        }
      });

      setAssetsMap(newMap);
    }
  }, [data]);

  useEffect(() => {
    if (data?.WizardData) {
      const keysMap = new Map();
      const assetsWithAssetTypeNameMatch: any[] = [];

      const filteredData = data.WizardData.filter((item: any) => {
        if (item.Name.toLowerCase().includes(searchValue.toLowerCase())) {
          keysMap.set(item.Id, true);
          return true;
        }
        return false;
      });

      assetsMap.forEach((value, key) => {
        const assetType = itemsMap.get(key);
        if (assetType && assetType.Name.toLowerCase().includes(searchValue.toLowerCase())) {
          const assets = value.filter((el: any) => !keysMap.has(el.Id));
          assetsWithAssetTypeNameMatch.push(...assets);
        }
      });

      setFilteredItems([...filteredData, ...assetsWithAssetTypeNameMatch]);
    }
  }, [data, searchValue]);

  const changeItem = (name: string, item: any, activities?: any) => {
    const itemCopy: AssetForWizardModel = item
      ? new AssetForWizardModel(item)
      : new AssetForWizardModel(newItem);

    itemCopy.Name = name;
    itemCopy._AssetActivities = activities || [];
    const AssetType = itemsMap.get(item.AssetTypeId);
    updateItem(new AssetForWizardModel({ ...itemCopy, AssetType }));
  };

  const onClickHandler = (id: string) => {
    const foundAsset = data.WizardData.find((el: any) => el.Id === id);

    if (foundAsset) {
      changeItem(foundAsset.Name, foundAsset, foundAsset.Activities);
      setStep(WIZARD_SCREENS_NAME.item_edit);
    }
  };

  const resetInputValue = () => {
    setSearchValue("");
  };

  return (
    <WizardSearchScreenContainer>
      <Box flex={1}>
        <Collapse in={!searchValue.length} timeout="auto" mountOnEnter unmountOnExit>
          <WizardSearchScreenLogoContainer>
            <WizardSearchScreenLogo />
          </WizardSearchScreenLogoContainer>
        </Collapse>
      </Box>

      <WizardSearchScreenLogoDescription>
        Find the item you want to maintain by name or type:
      </WizardSearchScreenLogoDescription>
      <div>
        <WizardSearchScreenInput
          placeholder="What are you looking for?"
          endAdornment={
            <InputAdornment onClick={resetInputValue} withResetButton={searchValue.length > 0} />
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          fullWidth
        />
      </div>
      {searchValue.length > 0 && loading && <Skeleton />}
      {searchValue.length > 0 && !filteredItems.length && !loading && <EmptyState />}
      {searchValue.length ? (
        <FoundItemsList>
          {filteredItems.map((el: any) => {
            return (
              <FoundItem
                assetName={el.Name ?? ""}
                assetTypeName={itemsMap.get(el.AssetTypeId)?.Name ?? ""}
                iconName={itemsMap.get(el.AssetTypeId)?.IconName ?? ""}
                handleClick={() => onClickHandler(el.Id)}
              />
            );
          })}
        </FoundItemsList>
      ) : (
        <PopularSection>
          <PopularSectionHeader>
            <PopularSectionTitle>Most popular Item Types</PopularSectionTitle>
            <PopularSectionHeaderAction
              onClick={handleChangeView}
              endIcon={<ArrowForwardIosIcon />}
            >
              More
            </PopularSectionHeaderAction>
          </PopularSectionHeader>
          <PopularSectionList>
            {slicedItemTypes.length
              ? slicedItemTypes.map((itemType) => (
                  <WizardSearchScreenItemTypeCard
                    key={itemType.Id}
                    isSelected={newItem.AssetType?.Id === itemType.Id}
                    onClick={() => handleClick(itemType.Id)}
                    variant="row"
                    iconName={itemType.IconName}
                  >
                    {itemType.Name}
                  </WizardSearchScreenItemTypeCard>
                ))
              : Array(6)
                  .fill(null)
                  .map((_, index) => {
                    return <AssetTypeSkeleton key={index} variant="rect" />;
                  })}
          </PopularSectionList>
        </PopularSection>
      )}
    </WizardSearchScreenContainer>
  );
};

export default WizardSearchScreen;
