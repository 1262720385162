export enum AssignType {
  "UNASSIGNED" = "UNASSIGNED",
  "SINGLE_USER" = "SINGLE_USER",
  "ALL_USERS" = "ALL_USERS",
  "SELF" = "SELF",
}

export interface CalendarConfig {
  AbsoluteUri: string;
  AccountId: string;
  AssignType: AssignType;
  Id: string;
  UserId: string;
}
