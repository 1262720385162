import gql from "graphql-tag";

export const updateAccountQuery = gql`    
  mutation updateAccount(
      $Id: ID,
      $Name: String,
      $Description: String,
      $Images: [ImageInput],
      $Files: [Upload]) {
    AccountPut(
        Account: { Id: $Id, Name: $Name, Description: $Description, Images: $Images }
        Files: $Files
    ) {
      Id
      Name
      Description
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
    }
  }
`
