import React, { useEffect, useState } from "react";

import { useStaticDataQuery } from "../../../queries/useStaticDataQuery";
import { useGetAssetTypeDetails } from "../../../customHooks/useGetAssetTypesDescriptionPattern";

import { composeImageUrl, getAssetImage } from "../../../_utils/utils";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";

import { Collapse } from "@material-ui/core";

import {
  CardContainerStyled,
  CardStyled,
  MainContentStyled,
  ImgStyled,
  InfoWrapper,
  CardTitle,
  SubTitleContaineer,
  TypeIconStyled,
  SubTitle,
  DescriptionStyled,
  MoreInfoContainer,
  SideContentStyled,
} from "./ItemPrintableCard.style";

import TagsItemPrintableCard from "../TagsItemPrintableCard";
import MoreInfoButton from "../MoreInfoButton";
import CountInfoRow from "../CountInfoRow";
import TypeFieldRow from "../TypeFieldsRow";
import { AssetModel } from "../../../redux/models/data/AssetModel";

interface ItemPrintableCardProps {
  item: AssetModel;
  isOpenedMoreInfo: boolean;
  toggleExpand: () => void;
}
const ItemPrintableCard: React.FC<ItemPrintableCardProps> = ({
  item,
  isOpenedMoreInfo,
  toggleExpand,
}) => {
  // const [isActive, setActive] = useState(false);

  const staticData = useStaticDataQuery();
  const { getDetailsById } = useGetAssetTypeDetails({ onlyCache: true });
  const { itemTypeField } = getDetailsById(item?.AssetType?.Id);

  const MainImage = item?.Images?.length && item.Images[0];
  const description = item?.Description?.length ? item.Description : item?.DefaultDescription;

  const filesCount =
    (item.Documents?.length ?? 0) + (item.Videos?.length ?? 0) + (item.Images?.length ?? 0);
  const activitiesCount = item._activitiesLength ?? 0;
  const customFieldsCount = item?.Schema?.length ?? 0;

  const typeFieldValues = item.TypeFieldValues ?? {};
  const isTypeFieldsExist = !!Object.values(typeFieldValues).filter((value) => !!value).length;

  // useEffect(() => {
  //   setActive(() => isOpenedMoreInfo);
  // }, [isOpenedMoreInfo]);

  return (
    <CardStyled>
      <CardContainerStyled>
        <MainContentStyled>
          <ImgStyled
            variant="rounded"
            src={
              MainImage
                ? composeImageUrl(MainImage.Id, MainImage.ContainerName, MainImage.ContainerId)
                : getAssetImage(staticData, item?.AssetType?.Name ?? "")
            }
          />
          <InfoWrapper>
            <CardTitle>{item.Name}</CardTitle>
            <SubTitleContaineer>
              <TypeIconStyled className="Icon">{item?.AssetType?.IconName}</TypeIconStyled>
              <SubTitle>{item?.AssetType?.Name}</SubTitle>
            </SubTitleContaineer>
            <DescriptionStyled $withoutClip={isOpenedMoreInfo}>{description}</DescriptionStyled>
            {isTypeFieldsExist && (
              <>
                {/* <TagsItemPrintableCard /> */}
                <MoreInfoContainer>
                  <Collapse in={isOpenedMoreInfo} timeout="auto" unmountOnExit>
                    {Object.keys(typeFieldValues)
                      .filter((key) => key !== "__typename")
                      .map((key) => {
                        const typeField = itemTypeField.find((typeField) => typeField.Name === key);
                        const value =
                          typeFieldValues[key] && typeof typeFieldValues[key] === "object"
                            ? typeFieldValues[key]?.value
                            : typeFieldValues[key];

                        return (
                          value && (
                            <TypeFieldRow
                              fieldType={typeField?.FieldType}
                              title={key}
                              value={value}
                              key={value}
                            />
                          )
                        );
                      })}
                  </Collapse>
                </MoreInfoContainer>
                <MoreInfoButton isActive={isOpenedMoreInfo} handlerClick={toggleExpand} />
              </>
            )}
          </InfoWrapper>
        </MainContentStyled>
        <SideContentStyled>
          <CountInfoRow icon={<CheckCircleIcon />} value={activitiesCount ?? 0} text="Activities" />
          <CountInfoRow icon={<DescriptionIcon />} value={filesCount} text="Files" />
          <CountInfoRow icon={<EditIcon />} value={customFieldsCount} text="Custom Fields" />
        </SideContentStyled>
      </CardContainerStyled>
    </CardStyled>
  );
};

export default ItemPrintableCard;
