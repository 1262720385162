import styled from "styled-components";
import { Container } from "@material-ui/core";
import { scrollStyles } from "../../components/_styled/scrollStyles";

export const StyledContainer = styled(Container)`
  max-width: 680px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CalendarFeedList = styled.section`
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 30px;

  ${scrollStyles}

  @media screen and (max-width: 599px) {
    margin-top: 30px;
  }
`;

export const CalendarFeedListLoading = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
