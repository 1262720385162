import React, { useEffect, useState } from "react";

import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import { ActivityModel } from "../../redux/models/data/ActivityModelFull";

import { getWordEnding } from "../../_utils/utils";

import { CardAdditionalInfo } from "../Cards/styled/card.styled";

interface AdditionalCardInfoProps {
  item: AssetRefsModel;
  assetActivities: ActivityModel[];
}

const AssetCardInfo: React.FC<AdditionalCardInfoProps> = ({ item, assetActivities }) => {
  const [declination, setDeclination] = useState("Activities");

  useEffect(() => {
    const decl = getWordEnding(assetActivities.length, ["Activity", "Activities", "Activities"]);
    setDeclination(decl);
  }, [assetActivities]);

  return (
    <CardAdditionalInfo $contentType="info">
      <div>{item.HasActivities ? assetActivities.length + " " + declination : "0 Activities"}</div>
    </CardAdditionalInfo>
  );
};

export default AssetCardInfo;
