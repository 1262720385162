import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { CURRENT_ACCOUNT, PERSONAL } from "../_constant/wordings";

export const GET_ACCOUNT_ID = gql`
  query getAccountId {
    Account {
      Id
      Name
      IsCustomerManagement
    }
  }
`;

export const CHECK_ACCOUNT_ID = gql`
  query checkAccountId($Id: ID) {
    Account(Id: $Id) {
      Id
      Name
      IsDeleted
      IsCustomerManagement
    }
  }
`;

interface CurrentAccountInterface {
  Id: string;
  IsCustomerManagement: boolean;
}

type CurrentAccountType = CurrentAccountInterface | null;

const useGetAccountId = (): {
  accountId: string | null;
  isPersonal: boolean | null;
  accountName: string | null;
  IsCustomerManagement: boolean;
} => {
  const currentAccountFromStorage = localStorage.getItem(CURRENT_ACCOUNT);
  let currentAccountId: string | null, isCurrentAccountBusiness: boolean | null;

  if (currentAccountFromStorage && currentAccountFromStorage.length > 36) {
    const currentAccount: CurrentAccountType =
      currentAccountFromStorage && JSON.parse(currentAccountFromStorage);
    currentAccountId = currentAccount?.Id || null;
    isCurrentAccountBusiness = currentAccount?.IsCustomerManagement || null;
  } else {
    currentAccountId = currentAccountFromStorage;
    isCurrentAccountBusiness = null;
  }

  const { data: accountCheck, loading: checkLoading, error: checkError } = useQuery(
    CHECK_ACCOUNT_ID,
    {
      variables: { Id: currentAccountId },
      skip: !currentAccountId,
    },
  );

  const { data } = useQuery(GET_ACCOUNT_ID);

  const nullData = {
    accountId: null,
    isPersonal: null,
    accountName: null,
    IsCustomerManagement: !!isCurrentAccountBusiness,
  };

  if (currentAccountFromStorage) {
    if (checkLoading) {
      return nullData;
    }
    const isAccountDeleted = accountCheck && accountCheck.Account && accountCheck.Account.IsDeleted;
    if (isAccountDeleted || checkError) {
      localStorage.removeItem(CURRENT_ACCOUNT);
    } else {
      return {
        accountId: accountCheck?.Account?.Id,
        isPersonal: accountCheck?.Account?.Name === PERSONAL,
        accountName: accountCheck?.Account?.Name,
        IsCustomerManagement: accountCheck?.Account?.IsCustomerManagement,
      };
    }
  }
  if (data && data.Account && data.Account.Id) {
    return {
      accountId: data.Account.Id,
      isPersonal: data.Account?.Name === PERSONAL,
      accountName: data.Account?.Name,
      IsCustomerManagement: !!data.Account?.IsCustomerManagement,
    };
  }

  return nullData;
};
export default useGetAccountId;
