import gql from "graphql-tag";

export const CHECK_VISITS_EXIST = gql`
  query checkVisitsExist($Id: ID) {
    Account(Id: $Id) {
      Id
      Visits(Take: 1) {
        Id
      }
    }
  }
`;
