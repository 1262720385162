import gql from "graphql-tag";

export const getAssetsTags = gql`
  query GetAssetsTags($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetsTags
    }
  }
`;
