import React from "react";
import { useMutation } from "react-apollo";
import {
  NotificationWrapper,
  NotificationContainer,
  NotificationAction,
  NotificationItem,
  StyledSelect,
} from "./NotificationCardStyle";
import MenuItem from "@material-ui/core/MenuItem";
import SelectItem from "../SelectItem/SelectItem";
import { RemoveButton } from "../InvitationCard/RemoveButton";
import Box from "@material-ui/core/Box";
import {
  deleteNotification,
  setTypeNotificationSettings,
  setTimeNotificationSettings,
  setChannelNotificationSettings,
} from "../../pages/Notifications/Notifications.query";
import moment from "moment";
import { updateCacheWithRemovedNotification } from "../../pages/Notifications/updateCacheWithRemovedNotification";
import { DaysOfWeek, EACH_DAY } from "../../_constant/constants";
import DayAndTimeInputBtn from "../DayAndTimeInputBtn/DayAndTimeInputBtn";
import useGetAccountId from "../../customHooks/useGetAccountId";

type NotificationType = "ACTIVITIES_DUE" | "UPCOMING_ACTIVITIES" | "ASSIGNED_TO_USER";

export type NotificationModelType = {
  Id: string;
  DayOfWeek: DaysOfWeek | typeof EACH_DAY;
  IsEnabled: boolean;
  IsDeleted: boolean;
  Frequency: "DAILY" | "WEEKLY" | "INSTANTLY";
  NotificationChannel: {
    Destination: string;
    Source: "EMAIL" | "SMS" | "ASSISTANT";
  };
  Time: string;
  Type: NotificationType;
};

interface Props {
  accountId: string;
  userId: string;
  notification: NotificationModelType;
  typesLabels: { allowedFrequencies: string[]; value: string; key: string }[];
  frequencyLabels: { id: string; value: string; key: string }[];
  isPersonalAccountNotifications: boolean;
}

const getAllowedFrequencies = (
  typesLabels: { allowedFrequencies: string[]; value: string; key: string }[],
  frequencyLabels: { id: string; value: string; key: string }[],
  value: string,
) => {
  const foundType = typesLabels.find((type) => type.key === value);

  return frequencyLabels.filter((el) => {
    if (!foundType) return true;
    return foundType.allowedFrequencies.includes(el.id);
  });
};

const NotificationCard: React.FC<Props> = (props) => {
  const {
    notification,
    accountId,
    userId,
    frequencyLabels,
    typesLabels,
    isPersonalAccountNotifications,
  } = props;

  const [
    changeNotificationType,
    { data: notificationTypePatch, loading: changeTypeInProcess },
  ] = useMutation(setTypeNotificationSettings);

  const [
    changeNotificationTime,
    { data: notificationTimePatch, loading: changeTimeInProcess },
  ] = useMutation(setTimeNotificationSettings);

  const [
    changeNotificationChannel,
    { data: notificationChannelPatch, loading: changeChannelInProcess },
  ] = useMutation(setChannelNotificationSettings);

  const [removeNotification, { data, loading: removeInProcess }] = useMutation(deleteNotification);

  const onRemoveHandler = () => {
    removeNotification({
      variables: {
        Setting: {
          Id: notification.Id,
          IsDeleted: true,
        },
      },
      update(cache, { data: NotificationSettingsPatch }) {
        updateCacheWithRemovedNotification(cache, NotificationSettingsPatch, userId);
      },
    });
  };

  const onSelectHandler = (
    e: React.ChangeEvent<{ name: keyof NotificationModelType; value: never }>,
  ) => {
    e.preventDefault();

    const updatedNotification = { Id: notification.Id } as NotificationModelType;

    if (e.target.name === "Frequency") {
      switch (e.target.value) {
        case "DAILY":
          updatedNotification.DayOfWeek = EACH_DAY;
          break;
        case "INSTANTLY":
          updatedNotification.Time = null;
          break;
        default:
          updatedNotification.DayOfWeek = moment().format("dddd").toUpperCase() as DaysOfWeek;
          break;
      }
    }

    if (e.target.name === "Type" && notification.Frequency == "INSTANTLY") {
      const frequencies = getAllowedFrequencies(typesLabels, frequencyLabels, e.target.value);

      if (frequencies.length > 0) {
        updatedNotification.Frequency = frequencies[0].key;
      }
    }

    updatedNotification[e.target.name] = e.target.value;
    const variableObject = {
      variables: {
        Setting: updatedNotification,
      },
    };
    switch (e.target.name) {
      case "Type":
        changeNotificationType(variableObject);
        break;
      case "Frequency":
        changeNotificationTime(variableObject);
        break;
      case "NotificationChannel":
        changeNotificationChannel(variableObject);
        break;
    }
  };

  const changeTimeValue = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    const newNot = { Id: notification.Id } as NotificationModelType;

    newNot.Time = date.toISOString(true);

    if (notification.Frequency === "WEEKLY") {
      newNot.DayOfWeek = date.format("dddd").toUpperCase() as DaysOfWeek;
    } else {
      newNot.DayOfWeek = EACH_DAY;
    }
    changeNotificationTime({
      variables: {
        Setting: newNot,
      },
    });
  };

  let date = moment(notification.Time);

  date =
    notification.DayOfWeek === EACH_DAY || !notification.DayOfWeek
      ? date
      : date.day(notification.DayOfWeek.toLowerCase());

  const mutationLoading =
    changeTypeInProcess || changeTimeInProcess || changeChannelInProcess || removeInProcess;

  const frequenciesToRender = getAllowedFrequencies(
    typesLabels,
    frequencyLabels,
    notification.Type,
  );

  return (
    <NotificationWrapper elevation={2} component="article">
      <NotificationContainer component="section">
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ flex: "1 1 auto", flexWrap: "wrap", minWidth: 0 }}
        >
          <NotificationItem>
            <StyledSelect
              name="Type"
              label="Notification"
              withoutUnderline
              value={notification.Type}
              onChange={onSelectHandler}
              disabled={mutationLoading}
            >
              {typesLabels?.map((el) => {
                if (isPersonalAccountNotifications && el.key === "ASSIGNED_TO_USER") return null;
                return (
                  <MenuItem value={el.key} key={el.key}>
                    <SelectItem>{el.value}</SelectItem>
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </NotificationItem>

          <NotificationItem>
            <StyledSelect
              name="Frequency"
              label="Frequency"
              withoutUnderline
              value={notification.Frequency}
              onChange={onSelectHandler}
              disabled={mutationLoading}
            >
              {frequenciesToRender.map((el) => {
                return (
                  <MenuItem value={el.key} key={el.key}>
                    <SelectItem>{el.value}</SelectItem>
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </NotificationItem>

          <NotificationItem>
            {notification.Frequency === "INSTANTLY" ? (
              <StyledSelect name="Timing" label="Timing" value="N/A" onChange={changeTimeValue}>
                <MenuItem value="N/A" key="N/A">
                  <SelectItem>N/A</SelectItem>
                </MenuItem>
              </StyledSelect>
            ) : (
              <DayAndTimeInputBtn
                date={date}
                withDayOfWeek={!!notification.DayOfWeek && notification.DayOfWeek !== EACH_DAY}
                changeTimeValue={changeTimeValue}
                disabled={mutationLoading}
              />
            )}
          </NotificationItem>

          <NotificationItem>
            <StyledSelect
              name="Channel"
              label="Channel"
              withoutUnderline
              value={notification.NotificationChannel.Destination}
              onChange={onSelectHandler}
              disabled={mutationLoading}
            >
              <MenuItem value={notification.NotificationChannel.Destination} key="key1">
                <SelectItem>{notification.NotificationChannel.Destination}</SelectItem>
              </MenuItem>
            </StyledSelect>
          </NotificationItem>
        </Box>

        <NotificationAction>
          <RemoveButton
            onClick={onRemoveHandler}
            disabled={mutationLoading}
            loading={removeInProcess}
          />
        </NotificationAction>
      </NotificationContainer>
    </NotificationWrapper>
  );
};

export default NotificationCard;
