import React from "react";
import { useQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";

import { CustomerModel } from "../../redux/models/data/CustomersModel";

import { getCustomersQuery } from "../Customers/customersList.query";
import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";

import { composeImageUrl } from "../../_utils/utils";
import { getSelectedCustomerId, getSelectedMemberId } from "../../redux/reducers/filterReducer";
import { setCustomerId, setMemberId, updateVisitEmptyStatus } from "../../redux/actions/actions";

import FilterListIcon from "@material-ui/icons/FilterList";

import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import {
  StyledDrawerHeader,
  StyledDrawerHeaderTitle,
  StyledList,
  StyledListItem,
  StyledResetFilterButton,
  StyledSwipeableDrawer,
  StyledTab,
  StyledTabPanel,
} from "./FilterVisitsMobileLayoutStyle";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </StyledTabPanel>
  );
}

const SwipeableTemporaryDrawer: React.FC = () => {
  const { headerOptions, setHeaderOptions } = useHeaderOptions();
  const { filterOpen } = headerOptions;
  const [value, setValue] = React.useState(0);

  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const selectedMemberId = useSelector(getSelectedMemberId);

  const dispatch = useDispatch();

  const { accountId } = useGetAccountId();

  const { data: accountUsers } = useQuery(getAccountUsersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });

  const { data: customersData } = useQuery(getCustomersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setHeaderOptions({ filterOpen: open });
  };

  const handleResetFilter = () => {
    if (value === 0 && !!selectedCustomerId) dispatch(setCustomerId(""));
    if (value === 1 && !!selectedMemberId) dispatch(setMemberId(""));
    dispatch(updateVisitEmptyStatus({ reset: true }));
  };

  const customerList = (
    <StyledList>
      {customersData &&
        customersData?.Account?.Customers?.map((customer: CustomerModel) => {
          const customerImage = customer.Images?.length
            ? composeImageUrl(
                customer.Images[0].Id,
                customer.Images[0].ContainerName,
                customer.Images[0].ContainerId,
              )
            : "";
          const isCustomerSelected = selectedCustomerId === customer.Id;
          const payload = isCustomerSelected ? "" : customer.Id;
          return (
            <StyledListItem
              button
              key={customer.Id}
              onClick={() => dispatch(setCustomerId(payload))}
              $selected={isCustomerSelected}
            >
              <ListItemAvatar>
                <Avatar alt={customer.Name} src={customerImage} />
              </ListItemAvatar>
              <ListItemText primary={customer.Name} />
            </StyledListItem>
          );
        })}
    </StyledList>
  );

  const membersList = (
    <StyledList>
      {accountUsers &&
        accountUsers?.Account?.UserRefs?.map((member: any) => {
          const customerImage = member.MainImage
            ? composeImageUrl(
                member.MainImage.Id,
                member.MainImage.ContainerName,
                member.MainImage.ContainerId,
              )
            : "";
          const isMemberSelected = selectedMemberId === member.Id;
          const payload = isMemberSelected ? "" : member.Id;
          return (
            <StyledListItem
              button
              key={member.Id}
              onClick={() => dispatch(setMemberId(payload))}
              $selected={isMemberSelected}
            >
              <ListItemAvatar>
                <Avatar alt={member.Name} src={customerImage} />
              </ListItemAvatar>
              <ListItemText primary={`${member.FirstName} ${member.LastName}`} />
            </StyledListItem>
          );
        })}
    </StyledList>
  );

  const renderResetFilterButton = () => {
    if (!selectedCustomerId && !selectedMemberId) {
      return null;
    }
    return (
      <StyledResetFilterButton disableRipple aria-label="reset" onClick={handleResetFilter}>
        <FilterListIcon />
      </StyledResetFilterButton>
    );
  };

  return (
    <StyledSwipeableDrawer
      anchor="bottom"
      open={filterOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <>
        <StyledDrawerHeader>
          {renderResetFilterButton()}
          <StyledDrawerHeaderTitle>Filtering</StyledDrawerHeaderTitle>
          <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
            <StyledTab label="Customer" />
            <StyledTab label="Members" />
          </Tabs>
        </StyledDrawerHeader>
        <Divider />
        <TabPanel value={value} index={0}>
          {customerList}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {membersList}
        </TabPanel>
      </>
    </StyledSwipeableDrawer>
  );
};

export default SwipeableTemporaryDrawer;
