import React from "react";

import {
  InfoButton,
  PopoverContainer,
  PopoverTitle,
  PopoverText,
  StyledList,
  StyledListItem,
} from "./PrintableHeader.style";

import InfoIcon from "@material-ui/icons/Info";

import { Box, Button } from "@material-ui/core";

export const PrintableHeaderPopoverButton: React.FC = () => {
  return (
    <InfoButton variant="text" color="primary" startIcon={<InfoIcon />}>
      Info
    </InfoButton>
  );
};

interface PrintableHeaderPopoverContentProps {
  handleClose: VoidFunction;
}

export const PrintableHeaderPopoverContent: React.FC<PrintableHeaderPopoverContentProps> = ({
  handleClose,
}) => {
  return (
    <PopoverContainer>
      <PopoverTitle>How to choose a specific item or item type for printing?</PopoverTitle>
      <StyledList>
        <StyledListItem>
          <PopoverText>
            Filter items by item type in the sidebar — press Print By Item Type button.
          </PopoverText>
        </StyledListItem>
        <StyledListItem>
          <PopoverText>Use search to find a specific item — press Print Item button.</PopoverText>
        </StyledListItem>
      </StyledList>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" size="small" color="primary" onClick={handleClose}>
          Got it
        </Button>
      </Box>
    </PopoverContainer>
  );
};
