import React from "react";

import {
  FakeItemCardContainer,
  FakeItemCardImagePlaceholder,
  FakeItemCardAvatar,
  FakeItemCardBody,
  FakeItemCardTitle,
  FakeItemCardType,
} from "./FakeItemCardStyles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import { getAssetImage } from "../../_utils/utils";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

interface FakeItemCardProps {
  isLoading?: boolean;
  itemName?: string;
  typeName?: string;
  typeIcon?: string;
}

const FakeItemCard: React.FC<FakeItemCardProps> = (props) => {
  const { isLoading, itemName, typeIcon, typeName = "" } = props;
  const staticData = useStaticDataQuery();

  return (
    <FakeItemCardContainer>
      <FakeItemCardImagePlaceholder>
        <FakeItemCardAvatar src={isLoading ? "" : getAssetImage(staticData, typeName)}>
          {isLoading && <CircularProgress size={32} />}
        </FakeItemCardAvatar>
      </FakeItemCardImagePlaceholder>
      <FakeItemCardBody>
        <FakeItemCardTitle>
          {isLoading ? (
            <Skeleton variant="rect" width={100} height={12} animation="wave" />
          ) : (
            itemName
          )}
        </FakeItemCardTitle>
        <FakeItemCardType>
          {isLoading ? (
            <Skeleton variant="rect" width={70} height={12} animation="wave" />
          ) : (
            <>
              <i className="Icon">{typeIcon}</i> {typeName}
            </>
          )}
        </FakeItemCardType>
      </FakeItemCardBody>
    </FakeItemCardContainer>
  );
};

export default FakeItemCard;
