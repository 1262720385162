import React from "react";
import withScrolling from "react-dnd-scrolling";
import styled from "styled-components";

const Wrapper = styled.div`
  overflow-y: auto;
`;

const ScrollingComponent = withScrolling(Wrapper);

interface MobileScrollingContainerProps {
  isMobile: boolean;
}
const MobileScrollingContainer: React.FC<MobileScrollingContainerProps> = ({
  isMobile,
  children,
}) => {
  if (!isMobile) {
    return <>{children}</>;
  }
  return <ScrollingComponent>{children}</ScrollingComponent>;
};

export default MobileScrollingContainer;
