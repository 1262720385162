import * as React from "react";
import { useQuery } from "react-apollo";
import Input from "../../../components/Input/Input";
import { UserInfoModel } from "../../../redux/models/data/UserInfoModel";
import Button from "../../../components/Button";
import { ROUTES } from "../../../_constant/screens";
import DropZone from "../../../components/DropZone";
import { useNavigate } from "react-router-dom";
import Form from "../../../components/Form/Form";
import MenuItem from "@material-ui/core/MenuItem";
import SelectItem from "../../../components/SelectItem/SelectItem";
import Select from "../../../components/Select/Select";
import { ChangeEvent } from "react";
import { getTimezones } from "../timezones.query";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, useMediaQuery } from "@material-ui/core";
import InputRow from "../../../components/InputRow/InputRow";
import DropZoneContainer from "../../../components/DropZoneContainer/DropZoneContainer";
import { useTheme } from "@material-ui/core/styles";
import { displayErrorNotification } from "../../../redux/actions/_utils";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import EntityTabsProvider from "../../../components/EntityTabsContainer/EntityTabsProvider";
import EntityTabsContainer from "../../../components/EntityTabsContainer/EntityTabsContainer";
import { SubscriptionCard } from "../../../components/SubscriptionCard/SubscriptionCard";

interface EditProfileFormProps {
  profileData: UserInfoModel;
  errorFields: Map<string, string>;
  handleFieldChange: (e: ChangeEvent<any> | null, target?: any) => void;
  updateManyFields: any;
}

const EditProfileForm: React.FC<EditProfileFormProps> = (props) => {
  const {
    profileData: { FirstName, LastName, Email, Images, Files, TimezoneId },
  } = props;
  const { errorFields, updateManyFields, handleFieldChange } = props;
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const { data: timezonesData, loading: timezonesLoading } = useQuery(getTimezones, {
    onError: () => {
      navigate(ROUTES.ACCOUNTS);
      displayErrorNotification(NotificationTypes.PROFILE_ACCESS_ERROR);
    },
  });

  const changePassword = () => {
    navigate(ROUTES.CHANGE_PASSWORD);
  };

  const handleTimezoneSelectChange = (e: any) => {
    if (!timezonesData || timezonesLoading) return;
    props.handleFieldChange(null, { id: "TimezoneId", value: e.target.value });
  };

  // const defaultValue = TimezoneId ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const selectValue =
  //   timezonesLoading && !timezonesData
  //     ? Intl.DateTimeFormat().resolvedOptions().timeZone
  //     : TimezoneId;

  const renderTimezoneOptions = () => {
    if (timezonesLoading && !timezonesData) {
      return [
        TimezoneId && (
          <MenuItem key={TimezoneId} value={TimezoneId}>
            <SelectItem>{TimezoneId}</SelectItem>
          </MenuItem>
        ),
        <MenuItem key="loading">
          <CircularProgress size={24} style={{ margin: "0 auto" }} />
        </MenuItem>,
      ];
    } else if (!timezonesLoading && timezonesData && !timezonesData?.GetTimezones?.length) {
      return (
        <MenuItem>
          <SelectItem>No options</SelectItem>
        </MenuItem>
      );
    } else {
      return timezonesData?.GetTimezones?.map((el: any) => {
        return (
          <MenuItem key={el.Id} value={el.Id}>
            <SelectItem>{el.DisplayName}</SelectItem>
          </MenuItem>
        );
      });
    }
  };

  const profileTabs = [
    {
      name: "Subscription",
      disabled: false,
      content: <SubscriptionCard />,
    },
  ];

  return (
    <>
      <Form content>
        <section className={"Section "}>
          <InputRow alignItems="flex-end">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Input
                  name="FirstName"
                  label="First name"
                  onChange={props.handleFieldChange}
                  value={FirstName}
                  id={"FirstName"}
                  error={errorFields.has("FirstName")}
                  errorText={errorFields.get("FirstName")}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  type="text"
                  name="LastName"
                  label="Last name"
                  onChange={props.handleFieldChange}
                  value={LastName}
                  id={"LastName"}
                />
              </Grid>
            </Grid>
          </InputRow>

          <InputRow alignItems="flex-end">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Input
                  name="Email"
                  label="Email"
                  type="email"
                  value={Email}
                  disableClearButton
                  nonEditable
                  helperText="Cannot edit"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  name="TimezoneId"
                  id="TimezoneId"
                  type="text"
                  value={TimezoneId}
                  onChange={handleTimezoneSelectChange}
                  label="Time Zone"
                  fullWidth
                >
                  {renderTimezoneOptions()}
                </Select>
              </Grid>
            </Grid>
          </InputRow>
        </section>
        <EntityTabsProvider>
          <EntityTabsContainer
            renderSelect={isMobile}
            content={profileTabs}
            entityName="profile"
            startFrom="0"
            tabIndexAfterCreate="0"
            removeScrollButtons={isMobile}
            fillAvailableWidth={isMobile}
          />
        </EntityTabsProvider>

        {/*<div className="Section_row">*/}
        {/*  <Button*/}
        {/*    label={"Change password"}*/}
        {/*    icon={"https"}*/}
        {/*    type={"link"}*/}
        {/*    onClick={changePassword}*/}
        {/*  />*/}
        {/*</div>*/}
      </Form>

      <Form images>
        <DropZoneContainer
          data={props.profileData}
          handleChange={handleFieldChange}
          updateManyFields={updateManyFields}
          isMobile={isMobile}
          isSingleFile
          imagesOnly
          entityName="Profile"
        />
      </Form>
    </>
  );
};

export default EditProfileForm;
