import React, { ChangeEvent } from "react";
import { useMutation } from "react-apollo";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import { PROJECT_PATCH } from "../../../_constant/wordings";
import { assignUserToProject } from "./assignUserToProject.query";
import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { AssignUserInsideCard } from "../../../components/AssigmentContainer/AssignUserInsideCard";
import AssignUserSelect from "../../../components/AssignUserSelect/AssignUserSelect";

interface IAssigmentContainer {
  projectId: string;
  assignedUserId: string;
  name: string;
}

const AssignmentProjectContainer: React.FC<IAssigmentContainer> = ({
  projectId,
  assignedUserId,
  name,
}) => {
  const { accountId } = useGetAccountId();
  const [assignProjectMutation, { data, loading, error }] = useMutation(assignUserToProject);

  const handleAssignVisit = (e: ChangeEvent<HTMLInputElement> | null, target?: any) => {
    assignProjectMutation({
      variables: {
        AccountId: accountId,
        Id: projectId,
        AssignedUserId: target.value,
        Name: name,
      },
    })
      .then(({ data }: any) => {
        if (data[PROJECT_PATCH] && data[PROJECT_PATCH].AssignedUserId === target.value) {
          displaySuccessNotification(NotificationTypes.PROJECT_ASSIGN_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_ASSIGN_UPDATED_ERROR);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.PROJECT_ASSIGN_UPDATED_ERROR);
      });
  };

  return (
    <AssignUserInsideCard>
      <AssignUserSelect
        assignedUserId={assignedUserId}
        handleFieldChange={handleAssignVisit}
        isLoading={loading}
        withEmptyId
      />
    </AssignUserInsideCard>
  );
};

export default AssignmentProjectContainer;
