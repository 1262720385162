import gql from "graphql-tag";

export const getAssetQuery = gql`
  query getAssetInfo($Id: ID, $AssetId: ID) {
    Account(Id: $Id) {
      Id
      Assets(Id: $AssetId) {
        AssetType {
          Id
          Name @client
          IconName @client
        }
        Id
        Name
        Description
        DefaultDescription
        Images {
          Id
          Name
          ContainerId
          ContainerName
          AbsoluteUri
          FileExtension
        }
        Documents {
          Id
          Name
          ContainerId
          ContainerName
          AbsoluteUri
          FileExtension
        }
        Videos {
          Id
          Name
          ContainerId
          ContainerName
          FileExtension
          AbsoluteUri
        }
        CustomFields
        TypeFieldValues
        Schema {
          Id
          Name
          Order
          FieldType
        }
        Tags
        CustomerId
        ParentId
      }
    }
  }
`;

export const updateAssetQuery = gql`
  mutation updateExistingAsset(
    $AccountId: ID
    $AssetType: AssetTypeInput
    $Id: ID
    $Name: String!
    $Description: String!
    $Images: [ImageInput]
    $Documents: [FileInput]
    $Videos: [FileInput]
    $Files: [Upload]
    $Schema: [SchemaElementInput]
    $CustomFields: Json
    $CustomerId: ID
    $TypeFieldValues: Json
    $Tags: [String]
    $ParentId: ID
  ) {
    AssetPut(
      Asset: {
        AccountId: $AccountId
        AssetType: $AssetType
        Id: $Id
        Name: $Name
        Description: $Description
        Images: $Images
        Documents: $Documents
        Videos: $Videos
        Schema: $Schema
        CustomFields: $CustomFields
        CustomerId: $CustomerId
        TypeFieldValues: $TypeFieldValues
        Tags: $Tags
        ParentId: $ParentId
      }
      Files: $Files
    ) {
      Id
      AssetType {
        Id
        Name
        IconName
      }
      Name
      Description
      DefaultDescription
      Images {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Schema {
        Id
        Name
        Order
        FieldType
      }
      CustomFields
      TypeFieldValues
      CustomerId
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      Tags
      ParentId
    }
  }
`;

export const createNewAssetQuery = gql`
  mutation createNewAsset(
    $AccountId: ID
    $AssetType: AssetTypeInput
    $Name: String!
    $Description: String!
    $Files: [Upload]
    $Schema: [SchemaElementInput]
    $CustomFields: Json
    $CustomerId: ID
    $TypeFieldValues: Json
    $Tags: [String]
    $ParentId: ID
  ) {
    AssetPost(
      Asset: {
        AccountId: $AccountId
        Name: $Name
        Description: $Description
        AssetType: $AssetType
        Schema: $Schema
        CustomFields: $CustomFields
        CustomerId: $CustomerId
        TypeFieldValues: $TypeFieldValues
        Tags: $Tags
        ParentId: $ParentId
      }
      Files: $Files
    ) {
      AssetType {
        Id
        Name
        IconName
        SortOrder
      }
      Id
      Name
      Description
      DefaultDescription
      Tags
      ParentId
      ComponentIds
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      AssetActivities {
        Id
      }
      Schema {
        Id
        Name
        Order
        FieldType
      }
      CustomFields
      TypeFieldValues
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
    }
  }
`;

export const createListAssetsQuery = gql`
  mutation AssetPostMany($Assets: [AssetInput!]) {
    AssetPostMany(Assets: $Assets) {
      AssetType {
        Id
        Name
        IconName
        IsSystem
      }
      Id
      Name
      Description
    }
  }
`;
