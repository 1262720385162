import React from "react";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

export const CustomFieldsLoadingStateContainer = styled.div`
  padding: 1rem;
`;

export const CustomFieldsLoadingStateRow = styled.div`
  display: flex;
  margin-bottom: calc(1rem * 1.5);
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CustomFieldsLoadingStateIcon = styled((props) => (
  <Skeleton animation="wave" variant="text" {...props} />
))`
  width: 25px;
  height: 35px;
  margin-right: 20px;
`;
export const CustomFieldsLoadingStateInput = styled((props) => (
  <Skeleton animation="wave" variant="text" {...props} />
))`
  width: 100%;
  height: 46px;
`;
