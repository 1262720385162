import React from "react";

import { LayoutType } from "../../_constant/constants";

import {
  NoActivitiesHeader,
  NoActivitiesImage,
  NoActivitiesImageContainer,
  NoActivitiesContainer,
  NoActivitiesPaperStyle,
  NoActivitiesTitle,
  NoActivitiesDescription,
} from "./NoActivities.style";

interface NoActivitiesProps {
  layout: LayoutType;
  description: string;
  url: string;
  retinaUrl: string;
}

const NoActivities: React.FC<NoActivitiesProps> = (props) => {
  const { layout, description, url, retinaUrl } = props;

  return (
    <NoActivitiesPaperStyle $layout={layout}>
      <NoActivitiesContainer $layout={layout}>
        <NoActivitiesHeader>
          <NoActivitiesTitle>No activities?</NoActivitiesTitle>
          <NoActivitiesDescription>{description}</NoActivitiesDescription>
        </NoActivitiesHeader>
        <NoActivitiesImageContainer $layout={layout}>
          <NoActivitiesImage $url={url} $retinaUrl={retinaUrl} />
        </NoActivitiesImageContainer>
      </NoActivitiesContainer>
    </NoActivitiesPaperStyle>
  );
};

export default NoActivities;
