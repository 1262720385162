import gql from "graphql-tag";

export const uploadItemsFromCsvQuery = gql`
  mutation send($AccountId: ID!, $File: Upload) {
    AssetsCSVUpload(AccountId: $AccountId, CSVFile: $File) {
      AssetRefs {
        AssetTypeRef {
          Id
          Name @client
          IconName @client
        }
        Id
        Name
        Description
        Tags
        DefaultDescription
        MainImage {
          Id
          Name
          FileExtension
          Description
          ContainerId
          ContainerName
          TotalBytes
        }
        HasActivities
      }
      LimitWarn
    }
  }
`;
