import React from "react";
import { ExecutionResult } from "graphql";

import { useMutation } from "react-apollo";
import { useSelector } from "react-redux";

import useGetBusinessStatuses from "../../customHooks/useGetBusinessStatuses";

import { VisitModel } from "../../redux/models/data/VisitModel";
import { getActivityFilters } from "../../redux/reducers/appReducer";
import { createVisitMutation } from "./createVisit.query";
import { updateCacheWithNewVisit } from "./updateCacheWithNewVIsit";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { convertStatusToFilterVariable } from "../../redux/actions/_utils";

const useCreateVisitMutation = (): [
  (data: VisitModel) => Promise<ExecutionResult<any>>,
  { loading: boolean },
] => {
  const [createVisitMut, { loading }] = useMutation(createVisitMutation);
  const { accountId } = useGetAccountId();
  const statuses = useGetBusinessStatuses();

  const filterMap = useSelector((state) => getActivityFilters(state));

  const createVisit = (dataForCreate: VisitModel) => {
    if (!statuses || !accountId) return;

    const Files = dataForCreate.Files;

    const dataForPost: any = { ...dataForCreate, AccountId: accountId };
    delete dataForPost.Files;
    delete dataForPost.VisitStatusId;
    delete dataForPost.VisitStatusName;
    delete dataForPost.CustomerName;

    const variables = { Visit: dataForPost, Files };

    return createVisitMut({
      variables: variables,
      update(cache, { data: { VisitPost } }) {
        const currentStatus = statuses.find((status) => status.Id === VisitPost.VisitStatusId);
        const filterString = convertStatusToFilterVariable(
          currentStatus,
          filterMap,
          "VisitStatusId",
        );

        updateCacheWithNewVisit(cache, VisitPost, accountId, filterString);
      },
    });
  };

  return [createVisit, { loading }];
};

export default useCreateVisitMutation;
