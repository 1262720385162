import React from "react";
import { Link, useLocation } from "react-router-dom";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { NAV_LIST } from "../../_constant/navigation";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { List, ListItemText } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { Popover } from "../Popover/Popover";

import { ListItemStyle } from "../PopupNavigation/PopupNavigationStyle";
import { StyledHeaderMobileTitle } from "../../containers/_common/header/Header.style";
import { useGetUserSubscriptionTier } from "../../customHooks/useGetUserSubscriptionTier";

interface HeaderNavMobileProps {
  pageTitle: string;
}

const HeaderNavMobile: React.FC<HeaderNavMobileProps> = ({ pageTitle }) => {
  const { IsCustomerManagement } = useGetAccountId();
  const userSubscriptionTier = useGetUserSubscriptionTier();
  const location = useLocation();

  const getNavList = () => {
    if (IsCustomerManagement) {
      return NAV_LIST.business;
    }
    if (
      (userSubscriptionTier.isProfessional || userSubscriptionTier.isPremium) &&
      !userSubscriptionTier.loading
    ) {
      return NAV_LIST.default;
    } else {
      return NAV_LIST.default.filter((value) => value.label !== "Projects");
    }
  };

  const getPageName = () => {
    return getNavList()
      .filter(({ path }) => location.pathname.includes(path))
      .map(({ label }) => label);
  };

  if (IsCustomerManagement) {
    return <StyledHeaderMobileTitle>{pageTitle}</StyledHeaderMobileTitle>;
  }

  return (
    <Popover
      target={
        <Button endIcon={<KeyboardArrowDownIcon />} color="inherit">
          {getPageName().length ? getPageName()[0] : pageTitle}
        </Button>
      }
      content={() => (
        <List component="nav" disablePadding>
          {getNavList().map(({ label, path }) => {
            return (
              <ListItemStyle
                key={label}
                button
                component={Link}
                to={path}
                disableGutters
                $padding="0.5rem 1rem"
              >
                <ListItemText primary={label} />
              </ListItemStyle>
            );
          })}
        </List>
      )}
    />
  );
};

export default HeaderNavMobile;
