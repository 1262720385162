import gql from "graphql-tag";

export const GET_PROJECTS_LIST = gql`
  query getProjectsList($AccountId: ID!, $Filter: String!) {
    Projects(AccountId: $AccountId, Filter: $Filter) {
      isLoading @client
      AccountId
      AssignedUserId
      Description
      DueDateTime
      CompletedDateTime
      Id
      Name
      ProjectStatusId
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation changeStatus(
    $AccountId: ID
    $Id: ID
    $ProjectStatusId: ID
    $CompletedDateTime: DateTimeOffset
  ) {
    ProjectPatch(
      Project: {
        AccountId: $AccountId
        Id: $Id
        ProjectStatusId: $ProjectStatusId
        CompletedDateTime: $CompletedDateTime
      }
    ) {
      Id
      ProjectStatusId
      CompletedDateTime
    }
  }
`;
