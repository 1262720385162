import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";

import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button/Button";

import AddIcon from "@material-ui/icons/Add";

import { ROUTES } from "../../_constant/screens";

import ActionsBar from "../../components/ActionsBar/ActionsBar";
import Body from "../../components/Body/Body";
import AllVisitsWrapper from "../../components/Visits/AllVisitsWrapper";
import SwipeableTemporaryDrawer from "../../components/FilterVisitsMobileLayout/FilterVisitsMobileLayout";
import FilterVisitDesktop from "../../components/FilterVisitDesktop/FilterVisitDesktop";

const StyledWrapper = styled.div`
  @media screen and (min-width: 600px) {
    padding-left: 212px;
  }
`;

const Visits = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const { setHeaderOptions } = useHeaderOptions();

  useEffect(() => {
    if (isDesktop) {
      setHeaderOptions({ withoutMenuButton: true });
    }
  }, [isDesktop]);

  const onAddButtonHandler = () => {
    navigate(ROUTES.CREATE_VISIT);
  };

  useHeaderOptions({
    pageTitle: "All Visits",
    withAddButton: true,
    addButtonCallback: onAddButtonHandler,
    headerType: "BUSINESS",
  });

  const addButton = (
    <Button
      component={Link}
      to={ROUTES.CREATE_VISIT}
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
    >
      Visit
    </Button>
  );
  return (
    <Body withMobileNavigation disableFilterPadding>
      <StyledWrapper>
        <Container maxWidth="md">
          <Hidden only="xs">
            <ActionsBar title="All Visits" withSearch>
              {addButton}
            </ActionsBar>
          </Hidden>
          <AllVisitsWrapper />
          <FilterVisitDesktop />
          <SwipeableTemporaryDrawer />
        </Container>
      </StyledWrapper>
    </Body>
  );
};

export default Visits;
