import { ACCOUNT, CUSTOMER, ITEM_ACTIVITIES } from "../../../../../_constant/wordings";
import { GET_ACTIVITIES_LIST } from "../../../../activities/all/activities-list.query";
import {
  createQueryOptions,
  createRelatedActivitiesQueryObject,
  getActivitiesFromRelatedActivities,
} from "../RelatedActivitiesCacheUpdate";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { GET_CUSTOMER_ACTIVITIES } from "../../../../../components/ActivitiesTab/getCustomerActivities.query";
import { getStartedVisitActivities } from "../../../../../pages/EditVisit/getStartedVisitActivities.query";
import { getActivitiesRelatedToVendorQuery } from "../../../../../components/VendorForm/ActivitiesTab/getActivitiesRelatedToVendor.query";

export const updateRelatedActivitiesCacheWithNewActivity = (cache, activity) => {
  const options = createQueryOptions(activity.AccountId, activity.AssetId, activity.StatusId);
  const newActivity = { ...activity };
  delete newActivity["AccountId"];

  try {
    const dataFromCacheRelated = cache.readQuery(options);
    let dataFromCacheRelatedList = getActivitiesFromRelatedActivities(dataFromCacheRelated);
    dataFromCacheRelatedList = dataFromCacheRelatedList.concat([newActivity]);

    cache.writeQuery({
      ...options,
      data: createRelatedActivitiesQueryObject(dataFromCacheRelated, dataFromCacheRelatedList),
    });
  } catch (e) {}
};

export const updateAllActivitiesCacheWithNewActivity = (cache, activity, filterString) => {
  try {
    const dataFromCacheGlobal = cache.readQuery({
      query: GET_ACTIVITIES_LIST,
      variables: { Id: activity.AccountId, Filter: filterString },
    });

    const newActivity = { ...activity };
    delete newActivity["AccountId"];

    dataFromCacheGlobal[ACCOUNT]["AssetActivities"] = dataFromCacheGlobal[ACCOUNT][
      "AssetActivities"
    ].concat([newActivity]);

    cache.writeQuery({
      query: GET_ACTIVITIES_LIST,
      variables: { Id: activity.AccountId, Filter: filterString },
      data: dataFromCacheGlobal,
    });
  } catch (e) {}
};

export const updateCustomerActivitiesCacheWithNewActivity = (
  cache: DataProxy,
  activity: any,
  CustomerId: string,
) => {
  try {
    const options = {
      query: GET_CUSTOMER_ACTIVITIES,
      variables: {
        AccountId: activity.AccountId,
        CustomerId,
      },
    };

    const activityForCache = { ...activity };
    delete activityForCache.AccountId;

    const activities: any = cache.readQuery(options);

    if (!activities?.[CUSTOMER]?.[ITEM_ACTIVITIES]) {
      return;
    }

    activities[CUSTOMER][ITEM_ACTIVITIES] = activities[CUSTOMER][ITEM_ACTIVITIES].concat([
      activity,
    ]);
    cache.writeQuery({ ...options, data: { ...activities } });
  } catch (error) {
    console.error(error);
  }
};

export const updateStartedVisitActivitiesWithNewActivity = (
  cache: DataProxy,
  activity: any,
  accountId: string,
  visitId: string,
) => {
  try {
    const options = {
      query: getStartedVisitActivities,
      variables: {
        AccountId: accountId,
        VisitId: visitId,
      },
    };

    const activities: any = cache.readQuery(options);

    const newActivity = { ...activity };

    delete newActivity.AccountId;
    delete newActivity.AssetId;
    delete newActivity.Images;
    delete newActivity.RecurringRepeatType;
    delete newActivity.isLoading;

    activities["Visit"]["StartedVisitActivities"] = activities["Visit"][
      "StartedVisitActivities"
    ].concat([newActivity]);

    cache.writeQuery({ ...options, data: { ...activities } });
  } catch (error) {
    console.error(error);
  }
};

export const updateVendorsActivityCacheWithNewActivity = (
  cache: DataProxy,
  activity: any,
  ActivityIds: string[],
) => {
  try {
    const options = {
      query: getActivitiesRelatedToVendorQuery,
      variables: {
        AccountId: activity.AccountId,
        ActivityIds,
      },
    };

    const activityForCache = { ...activity };
    delete activityForCache.AccountId;

    const activities: any = cache.readQuery(options);

    activities.AssetActivities = activities.AssetActivities.concat([activity]);

    cache.writeQuery({ ...options, data: { ...activities } });
  } catch (error) {
    console.error(error);
  }
};
