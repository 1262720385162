import { useEffect, useState } from "react";
import useGetAccountId from "./useGetAccountId";
import useGetAcitvitiesStatuses from "./api/useGetAcitvitiesStatuses";
import { useQuery } from "react-apollo";
import { GET_ACTIVITIES_FOR_ITEM_REMOVE } from "../containers/assets/_components/deleteAssetPopup/DeleteAsset.queries";
import { ACCOUNT, ITEM_ACTIVITIES } from "../_constant/wordings";
import { formatDate } from "../_utils/date";
import { ActivityModelRelated } from "../containers/assets/_components/relaitedActivities/ActivityModelRelated";
import { sortArrOfAllRelatedActivities } from "../_utils/utils";

interface IUseGetAllRelatedActivities {
  assetId: string;
}

const useGetAllRelatedActivities = (props: IUseGetAllRelatedActivities) => {
  const { assetId } = props;

  const [statusFilter, setStatusFilter] = useState("");
  const [statusNameMap, setStatusName] = useState(new Map());
  const [relatedActivities, setRelatedActivities] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const { accountId } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();

  useEffect(() => {
    if (statuses.length > 0) {
      setStatusFilter(
        statuses
          .filter((status) => statuses.includes(status.Name.toLowerCase()))
          .reduce(
            (result, status) =>
              `${result}${!result ? `StatusId=="${status.Id}"` : ` OR StatusId=="${status.Id}"`}`,
            "",
          ),
      );
      const statusesArr = statuses.map((status) => [status.Id, status.Name]);
      setStatusName(new Map(statusesArr));
    }
  }, [statuses]);

  const statusFilterString = statusFilter ? ` AND (${statusFilter})` : "";

  const { data: activitiesRes, loading: activitiesLoading, error: activitiesError } = useQuery(
    GET_ACTIVITIES_FOR_ITEM_REMOVE,
    {
      variables: {
        Id: accountId,
        Filter: `AssetId=="${assetId}"${statusFilterString}`,
      },
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (statusNameMap.size > 0 && !activitiesLoading && !activitiesError) {
      const newActivities = activitiesRes[ACCOUNT][ITEM_ACTIVITIES].map((item) => {
        item.DueDateTime = formatDate(item.DueDateTime);
        item.CompletedDateTime = formatDate(item.CompletedDateTime);
        return new ActivityModelRelated(item);
      });

      setRelatedActivities(sortArrOfAllRelatedActivities(newActivities, statusNameMap));
      setIsDataAvailable(true);
    }
  }, [props.assetId, activitiesRes, statusFilter]);

  return { isDataAvailable, relatedActivities, statusNameMap, accountId, statuses };
};

export default useGetAllRelatedActivities;
