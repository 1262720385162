import * as React from "react";
import { useState } from "react";
import DropTargetRelatedActivities from "../DropTargetRelatedActivities";
import StatusAccordionContent from "./StatusAccordionContent";
import { useQuery } from "react-apollo";
import useGetAccountId from "../../../../../customHooks/useGetAccountId";
import { GET_CUSTOMER_ACTIVITIES } from "../../../../../components/ActivitiesTab/getCustomerActivities.query";
import { CUSTOMER, ITEM_ACTIVITIES } from "../../../../../_constant/wordings";
import DropTargetCustomerActivities from "../DropTargetCustomerRA";

interface StatusAccordionCustomerProps {
  StatusName: string;
  StatusId: string;
  expanded: boolean;
  customerId: string;
  AssetId?: string;
}

const StatusAccordionCustomer: React.FC<StatusAccordionCustomerProps> = ({
  StatusName,
  StatusId,
  expanded,
  customerId,
  AssetId,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const { accountId } = useGetAccountId();

  const { data, loading, error: activityError } = useQuery(GET_CUSTOMER_ACTIVITIES, {
    variables: {
      AccountId: accountId,
      CustomerId: customerId,
    },
    skip: !accountId || !customerId,
    fetchPolicy: "cache-first",
  });

  const handleOpen = async () => {
    setIsExpanded(true);
  };
  const handleClose = () => {
    setIsExpanded(false);
  };
  const handleOpenMemoized = React.useCallback(handleOpen, []);
  const handleCloseMemoized = React.useCallback(handleClose, []);

  const activities = data?.[CUSTOMER]?.[ITEM_ACTIVITIES]?.length
    ? data[CUSTOMER][ITEM_ACTIVITIES]
    : [];
  let activitiesFiltered: any[] = activities.filter((item: any) => item.StatusId === StatusId);

  activitiesFiltered = AssetId
    ? activitiesFiltered.filter((item) => item?.Asset?.Id === AssetId)
    : activitiesFiltered;

  const activitiesArrLength = activitiesFiltered ? activitiesFiltered.length : 0;
  const labelCounter = isExpanded && activitiesArrLength > 0 && (
    <span className="Label">({activitiesArrLength})</span>
  );

  return (
    <DropTargetCustomerActivities
      label={
        <>
          {StatusName.toUpperCase()} {labelCounter}
        </>
      }
      onOpen={handleOpenMemoized}
      onClose={handleCloseMemoized}
      isLoading={isExpanded ? loading : false}
      expanded={isExpanded}
      StatusId={StatusId}
      customerId={customerId}
    >
      <StatusAccordionContent
        activities={activitiesFiltered}
        StatusName={StatusName}
        customerId={customerId}
      />
    </DropTargetCustomerActivities>
  );
};

export default StatusAccordionCustomer;
