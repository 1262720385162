import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useMediaQuery, useTheme } from "@material-ui/core";

import useGetUserId from "../../customHooks/useGetUserId";

import { Users } from "../../redux/models/data/UsersModel";
import { ALL_ACTIVITIES, ME_PREFIX, UNASSIGNED } from "../../_constant/constants";
import { userRefFragment } from "../AssignUserSelect/getUserRefFragment.query";

import { composeImageUrl } from "../../_utils/utils";
import { isDefaultSelectOption } from "../../_utils/filtering";

import SelectIconSvgDefault from "../../../images/brands/default/activity_select_icon.svg";
import SelectIconSvgBrand from "../../../images/brands/autix/activity_select_icon.svg"

import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";

import SelectItem from "../SelectItem/SelectItem";
import { TypeSelectValue } from "../Select";

import { SelectIcon, FilterSelect } from "./ActivitiesSelectFilterStyle";

interface ActivitiesSelectFilterProps {
  filterValue: string;
  onChange: (value: string) => void;
  usersLoaded: boolean;
  users: Users[];
  justForCurrentUser?: boolean;
  withoutCrop?: boolean;
}

const ActivitiesSelectFilter: React.FC<ActivitiesSelectFilterProps> = ({
  filterValue,
  onChange,
  usersLoaded,
  users,
  justForCurrentUser = false,
  withoutCrop = false,
}) => {
  const client = useApolloClient();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const userId = useGetUserId();

  const filteredUsers = justForCurrentUser
    ? users.filter((userObj) => userObj.Id === userId)
    : users;

  const defaultSelectOptions: TypeSelectValue[] = [
    {
      label: isDesktop || withoutCrop ? "All Activities" : "All",
      value: ALL_ACTIVITIES,
    },
    {
      label: "Unassigned",
      value: UNASSIGNED,
    },
  ];

  const userRef = client.readFragment({
    id: filterValue || "",
    fragment: userRefFragment,
  });

  const getSelectIconUrl = () => {
    const brand = process.env.REACT_APP_BRAND;
    switch (brand) {
      case "autix": return SelectIconSvgBrand;
      default: return SelectIconSvgDefault;
    }
  };

  const selectIcon = isDefaultSelectOption(filterValue)
    ? getSelectIconUrl()
    : userRef?.MainImage
    ? composeImageUrl(
        userRef?.MainImage?.Id,
        userRef?.MainImage?.ContainerName,
        userRef?.MainImage?.ContainerId,
      )
    : undefined;

  const defaultSelectElements = defaultSelectOptions.map((el, index) => {
    return (
      <MenuItem value={el.value} key={index}>
        <SelectItem>{el.label}</SelectItem>
      </MenuItem>
    );
  });

  return (
    <FilterSelect
      disableMediaGutters={true}
      alignMedia="center"
      media={
        isDefaultSelectOption(filterValue) ? (
          <Avatar src={selectIcon} />
        ) : (
          <SelectIcon src={selectIcon}>
            {`${userRef?.FirstName} ${userRef?.LastName}`.charAt(0)}
          </SelectIcon>
        )
      }
      value={filterValue}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
      onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
      disabled={false}
      withoutUnderline={true}
    >
      {!usersLoaded || !userId
        ? defaultSelectElements
        : [
            ...defaultSelectElements,
            <MenuItem value={`${ME_PREFIX}${userId}`} key={userId}>
              <SelectItem>Self & Unassigned</SelectItem>
            </MenuItem>,
            filteredUsers.map((user) => {
              return (
                <MenuItem value={user.Id} key={user.Id}>
                  <SelectItem
                    media={
                      <Avatar
                        alt={`${user.FirstName} ${user.LastName}`}
                        src={
                          user.MainImage
                            ? composeImageUrl(
                                user.MainImage.Id,
                                user.MainImage.ContainerName,
                                user.MainImage.ContainerId,
                              )
                            : undefined
                        }
                        style={{ width: 16, height: 16 }}
                      >
                        {`${user.FirstName} ${user.LastName}`.charAt(0)}
                      </Avatar>
                    }
                  >
                    {user.FirstName} {user.LastName}
                  </SelectItem>
                </MenuItem>
              );
            }),
          ]}
    </FilterSelect>
  );
};

export default ActivitiesSelectFilter;
