import { ALL_ACTIVITIES } from "../../../_constant/constants";

export class FilterModel {
  selectedAssetType: string;
  selectedAssetIds: Set<string>;
  relatedIds: string[];
  searchString: string;
  filterValue: string;
  selectedCustomerId: string;
  selectedMemberId: string;
  filterTagValue: string[];

  constructor(
    selectedAssetType?: string,
    selectedAssetIds?: Set<string>,
    relatedIds?: string[],
    searchString?: string,
    filterValue?: string,
    selectedCustomerId?: string,
    selectedMemberId?: string,
    filterTagValue?: string[],
  ) {
    this.selectedAssetType = selectedAssetType || "";
    this.relatedIds = relatedIds || [];
    this.selectedAssetIds = selectedAssetIds || new Set();
    this.searchString = searchString || "";
    this.filterValue = filterValue || ALL_ACTIVITIES;
    this.selectedCustomerId = selectedCustomerId || "";
    this.selectedMemberId = selectedMemberId || "";
    this.filterTagValue = filterTagValue || [];
  }

  // Setters
  withSelectedAssets({ assetType, assets, relatedIds }) {
    const newAssets: Set<string> = assets ? new Set(assets) : new Set();
    const newRelatedIds = relatedIds ? [...relatedIds] : [];
    return new FilterModel(
      assetType,
      newAssets,
      newRelatedIds,
      this.searchString,
      this.filterValue,
      this.selectedCustomerId,
      this.selectedMemberId,
      this.filterTagValue,
    );
  }

  withUpdatedSearchQuery(searchString) {
    return new FilterModel(
      this.selectedAssetType,
      this.selectedAssetIds,
      this.relatedIds,
      searchString,
      this.filterValue,
      this.selectedCustomerId,
      this.selectedMemberId,
      this.filterTagValue,
    );
  }

  withUpdateFilterValue(filterValue) {
    return new FilterModel(
      this.selectedAssetType,
      this.selectedAssetIds,
      this.relatedIds,
      this.searchString,
      filterValue,
      this.selectedCustomerId,
      this.selectedMemberId,
      this.filterTagValue,
    );
  }

  withUpdateFilterTagsValue(filterTagsValue) {
    return new FilterModel(
      this.selectedAssetType,
      this.selectedAssetIds,
      this.relatedIds,
      this.searchString,
      this.filterValue,
      this.selectedCustomerId,
      this.selectedMemberId,
      filterTagsValue,
    );
  }

  withSelectedCustomer(customerId: string) {
    return new FilterModel(
      this.selectedAssetType,
      this.selectedAssetIds,
      this.relatedIds,
      this.searchString,
      this.filterValue,
      customerId,
      this.selectedMemberId,
      this.filterTagValue,
    );
  }

  withSelectedMember(memberId: string) {
    return new FilterModel(
      this.selectedAssetType,
      this.selectedAssetIds,
      this.relatedIds,
      this.searchString,
      this.filterValue,
      this.selectedCustomerId,
      memberId,
      this.filterTagValue,
    );
  }

  // Getters
  get searchStringValue() {
    return this.searchString;
  }

  getSelectedAssetType() {
    return this.selectedAssetType;
  }

  getSelectedAssetTypeItemsIds() {
    return this.relatedIds;
  }

  getSelectedAssetIds(): Set<string> {
    return this.selectedAssetIds;
  }

  getFilterValue(): string {
    return this.filterValue;
  }

  getCustomerId(): string {
    return this.selectedCustomerId;
  }

  getMemberId(): string {
    return this.selectedMemberId;
  }

  getAssetsTags(): string[] {
    return this.filterTagValue;
  }
}
