import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { StyledDrawer } from "../FilterVisitDesktop/FilterVisitDesktop.style";
import MembersFilterList from "../FilterVisitDesktop/MembersFilterList";

const FilterProjectsDesktop: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  if (!isDesktop) {
    return null;
  }

  return (
    <StyledDrawer anchor="left" variant="permanent">
      <MembersFilterList />
    </StyledDrawer>
  );
};

export default FilterProjectsDesktop;
