import React from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core";

type Props = {
  onClick: any;
  disabled: boolean;
  loading: boolean;
};

export const RemoveButton = ({ onClick, disabled, loading }: Props) => {
  const theme = useTheme();

  return (
    <IconButton onClick={onClick} disabled={disabled} edge="end">
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <RemoveCircleOutlineIcon
          style={{ color: disabled ? `${theme.palette.greyDesign.onSurface3}` : `${theme.palette.greyDesign.surface2}`, verticalAlign: "middle" }}
        />
      )}
    </IconButton>
  );
};
