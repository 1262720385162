import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

export const CardLoadingBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  opacity: 0.5;
  pointer-events: none;
`;

export const CalendarCardLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardLoadingState: React.FC = () => {
  return (
    <>
      <CardLoadingBg />
      <CalendarCardLoading>
        <CircularProgress color="primary" size={30} />
      </CalendarCardLoading>
    </>
  );
};

export default CardLoadingState;
