import styled, { css } from "styled-components";

const withBorderStyles = css`
  border-bottom: var(--border-style);
`;

const withDefaultBackgroundStyles = css`
  background-color: var(--color-bg-main);
`;

interface StyledSectionProps {
  withBorder?: boolean;
  withDefaultBackground?: boolean;
}

export const StyledSection = styled.section<StyledSectionProps>`
  display: flex;
  flex-direction: column;
  padding: var(--space-size--m);
  ${({ withBorder }) => withBorder && withBorderStyles};
  ${({ withDefaultBackground }) => withDefaultBackground && withDefaultBackgroundStyles};
`;
