import React from "react";
import { NavLink } from "react-router-dom";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { useIsItemsExistsForList } from "../../customHooks/useIsItemsExistsForList";

import { ROUTES } from "../../_constant/screens";
import { NAV_LIST } from "../../_constant/navigation";

import Button from "@material-ui/core/Button";

import "./Navigation.scss";
import { useGetUserSubscriptionTier } from "../../customHooks/useGetUserSubscriptionTier";

interface NavigationProps {
  mobileView?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ mobileView }) => {
  const { IsCustomerManagement } = useGetAccountId();
  const { exists, isDataAvailable } = useIsItemsExistsForList();

  const disableActivitiesButton = isDataAvailable && !exists;
  const userSubscriptionTier = useGetUserSubscriptionTier();

  const getNavList = () => {
    if (IsCustomerManagement) {
      return NAV_LIST.business;
    }
    if (
      (userSubscriptionTier.isProfessional || userSubscriptionTier.isPremium) &&
      !userSubscriptionTier.loading
    ) {
      return NAV_LIST.default;
    } else {
      return NAV_LIST.default.filter((value) => value.label !== "Projects");
    }
  };

  return (
    <div className={`Navigation${mobileView ? " Navigation--mobileView" : ""}`}>
      {getNavList().map(({ label, path }) => (
        <Button
          key={label}
          component={NavLink}
          to={path}
          activeClassName="isActive"
          disableRipple
          disableElevation
          disabled={path === ROUTES.ACTIVITIES && disableActivitiesButton}
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

export default Navigation;
