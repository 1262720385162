import React, { useState } from "react";
import "./MoveAsset.scss";

import { useQuery } from "react-apollo";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import { getAccountRefsList } from "../../pages/Accounts/getAccountList.query";

import { Image } from "../../redux/models/data/ImageModel";
import { AccountModel } from "../../redux/models/data/AccountModel";

import { ACCOUNT_REFS, PERSONAL, USER } from "../../_constant/wordings";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";

import { composeImageUrl } from "../../_utils/utils";
import Select from "../Select/Select";

interface IImageOption {
  Name: string;
  Images: Image[] | null;
}

interface MoveAssetProps {
  isMoveLoading: boolean;
  openConfirm: (accountMoveTo: AccountModel) => void;
}

const MoveAsset = ({ openConfirm, isMoveLoading }: MoveAssetProps) => {
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");

  const { accountId } = useGetAccountId();
  const staticData = useStaticDataQuery();
  const personalAccountImg = staticData ? staticData.Account.Account_default_icon : "";

  const { data: accountList, loading: accountsLoading, error: accountsError } = useQuery(
    getAccountRefsList,
    {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
      skip: !accountId,
    },
  );

  const getImageUrl = (props: IImageOption) => {
    const { Name, Images } = props;
    return Name === PERSONAL
      ? personalAccountImg
      : Images && Images[0]
      ? composeImageUrl(Images[0].Id, Images[0].ContainerName, Images[0].ContainerId)
      : "";
  };

  const getImageAndNameById = (id: string) => {
    const defaultValue = { Name: "", Images: null };
    if (!accountList || accountsLoading || accountsError) {
      return defaultValue;
    }
    const res = accountList[USER][ACCOUNT_REFS].find((item: AccountModel) => item.Id === id);

    if (!res) {
      return defaultValue;
    }
    return {
      Name: res.Name,
      Images: res.Images,
    };
  };

  const getAvatarVariant = (name: string): "rounded" | "circle" => {
    return name === PERSONAL ? "rounded" : "circle";
  };

  const renderAccountImage = () => {
    const { Name, ...rest } =
      !accountsLoading &&
      !accountsError &&
      accountList &&
      accountList[USER][ACCOUNT_REFS].find(({ Id }: AccountModel) => Id === accountId);

    const imageOption = dropdownValue
      ? getImageAndNameById(dropdownValue)
      : { Images: rest?.Images, Name };

    return (
      <Avatar
        className="MoveAsset__currentAccount"
        alt={`${imageOption.Name} - current account`}
        src={getImageUrl(imageOption)}
        variant={getAvatarVariant(imageOption.Name)}
      >
        {Name && Name.charAt(0)}
      </Avatar>
    );
  };
  const handleSubmit = () => {
    if (!accountList || accountsLoading || accountsError) {
      return;
    }
    const accountMoveTo = accountList[USER][ACCOUNT_REFS].find(
      (account: AccountModel) => account.Id === dropdownValue,
    );
    openConfirm(accountMoveTo);
  };

  return (
    <div className="MoveAsset">
      <Button
        className="MoveAsset__button"
        startIcon={<PresentToAllOutlinedIcon />}
        endIcon={expanded ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        onClick={() => setExpanded(!expanded)}
      >
        Move to another account
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit mountOnEnter>
        <div className="MoveAsset__container">
          <div className="MoveAsset__content">
            <p className="MoveAsset__text">
              You can move the item to another account. All related activities will move with the
              item.
            </p>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>{renderAccountImage()}</Grid>
              <Grid item xs>
                <FormControl fullWidth className="MoveAsset__select">
                  {/* <InputLabel>Select account</InputLabel> */}
                  <Select
                    label="Select account"
                    open={dropdownOpen}
                    onClose={() => setDropdownOpen(false)}
                    onOpen={() => setDropdownOpen(true)}
                    value={dropdownValue}
                    onChange={({ target: { value } }) =>
                      setDropdownValue(typeof value === "string" ? value : "")
                    }
                  >
                    {accountList &&
                      accountList[USER][ACCOUNT_REFS].filter(
                        ({ Id }: AccountModel) => Id !== accountId,
                      ).map(({ Id, Name, Images }: AccountModel) => (
                        <MenuItem value={Id} key={Id}>
                          <ListItemIcon className="MoveAsset__listItemIcon">
                            <Avatar
                              alt={Name}
                              src={getImageUrl({ Images, Name })}
                              variant={getAvatarVariant(Name)}
                            >
                              {Name.charAt(0)}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText className="MoveAsset__listItemText" primary={Name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  disabled={!dropdownValue || isMoveLoading}
                  onClick={handleSubmit}
                >
                  {isMoveLoading ? (
                    <CircularProgress color="primary" size={22} />
                  ) : (
                    <SendOutlinedIcon />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default MoveAsset;
