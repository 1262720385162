import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Box, Grid, useTheme } from "@material-ui/core";
import RepeatIcon from "@material-ui/icons/Repeat";

import useGetAcitvitiesStatuses from "../../customHooks/api/useGetAcitvitiesStatuses";

import { LayoutType } from "../../_constant/constants";
import { debounce, isRecurring } from "../../_utils/utils";
import { formatDateWithoutTZ } from "../../_utils/date";

import { Image } from "../../redux/models/data/ImageModel";

import { CardContainer } from "../Cards/styled/card.styled";
import { LoaderCircle } from "../Loader";

import { ActivityItemDate } from "../../containers/activities/_components/item/ActivityItemDate";
import CardInfo from "./CardInfo";
import StatusRadio from "./StatusRadio";
import { ROUTES } from "../../_constant/screens";
import useChangeVisitActivityStatus from "../../customHooks/useChangeVisitActivityStatus";
import { ActivityModelShort } from "../../redux/models/data/ActivityModelShort";

interface ActiveActivityCardProps {
  activity: any; // ActivityModelShort | ActivityModelRelated
  dateType: string;
  layout: LayoutType;
  disableNavigationByClick?: boolean;
  style?: {};
  isAccountPersonal: boolean;
  isDraggable?: boolean;
  isAssignmentsVisible: boolean;
  customerId?: string | null;
}

const ActiveActivityCard: React.FC<ActiveActivityCardProps> = ({
  activity,
  dateType,
  style,
  isDraggable,
}) => {
  const [activityData, setActivityData] = useState(
    new ActivityModelShort({ ...activity, AssetId: activity.Asset.Id }),
  );

  const { Name, Images = [], Schedule, RelatedDueDateTime, isLoading, Asset } = activityData;

  const recurring = isRecurring({ schedule: Schedule, relatedDueDate: RelatedDueDateTime });

  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const statuses = useGetAcitvitiesStatuses();

  const { updateActivityStatus, loading, error } = useChangeVisitActivityStatus();

  const imagesForCard = [] as Image[];
  if (Images && Images.length) {
    Images.forEach((image: Image, index: number) => {
      if (index <= 2) {
        imagesForCard.push(image);
      }
    });
  }

  const currentStatus = statuses.find((status: any) => status.Id === activityData.StatusId);

  const debouncedFn = debounce(updateActivityStatus, 1000);

  const debouncedFnMemoized = useCallback(debouncedFn, []);

  const handleStatusChange = () => {
    if (loading) return;
    const currentStatusIndex = statuses.findIndex(
      (status: any) => status.Id === activityData.StatusId,
    );
    if (currentStatusIndex < 0) return;

    let nextStatusId = "";

    if (currentStatusIndex + 1 < statuses.length) {
      nextStatusId = statuses[currentStatusIndex + 1].Id;
    } else {
      nextStatusId = statuses[0].Id;
    }

    setActivityData((prevState: any) => {
      return new ActivityModelShort({
        ...prevState,
        StatusId: nextStatusId,
      });
    });

    debouncedFnMemoized({ activity, currentStatusId: activity.StatusId, nextStatusId, isDesktop });
  };

  const handleClick = () => {
    if (loading) return;
    navigate(`${ROUTES.SINGLE_ACTIVITY}/${activity.Id}`);
  };

  return (
    <CardContainer
      padding={1}
      style={isDraggable ? style : { borderBottom: "1px solid #eeeeee" }}
      borderRadius={isDraggable ? "8px" : "0"}
      onClick={handleClick}
      withLabel={currentStatus.Name ?? ""}
    >
      {loading && (
        <div className={`ActivityItem_loader`}>
          <LoaderCircle size={"xs"} />
        </div>
      )}
      <Grid spacing={1} container alignItems="center" wrap="nowrap">
        <Grid item xs zeroMinWidth>
          <Box paddingLeft="4px">
            <CardInfo title={Name} subtitle={Asset.Name} prefix="for" />
            <Box display="flex" alignItems="center" marginLeft="-1px" paddingTop="4px">
              <ActivityItemDate
                activityDate={formatDateWithoutTZ(activity[dateType])}
                dateType={dateType}
              />
              <RepeatIcon
                style={{
                  color: recurring ? theme.palette.primary.main : theme.palette.text.primary,
                  fontSize: "1rem",
                  marginLeft: 8,
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <StatusRadio
            status={currentStatus ? currentStatus.Name : ""}
            onClick={handleStatusChange}
          />
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default ActiveActivityCard;
