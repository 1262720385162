import * as React from "react";
import {DateInput} from "../../../../../../components/DateInput";
import {Calendar} from "../../../../../../components/Calendar";
import {FullActivityDueDate} from "../../../../../../redux/models/data/ActivityModelFull";
import {useWindowSize} from "../../../../../../_utils/useWindowSize.hook";
import {ActivitiesFieldName, SCREEN_XS_MAX_SIZE} from "../../../../../../_constant/constants";
import {useContext} from "react";
import {DateContext} from "../DueDateComponent";

interface DueDateFormProps {
  onChange: (value: FullActivityDueDate) => void;
  handleDatePick: (value) => void;
}

export default function DueDateForm(props: DueDateFormProps) {
  const {dueDate} = useContext(DateContext);
  const [windowSize] = useWindowSize();

  return (
    <div className="DueDateForm">
      <div className={"Form_item"}>
        <i className="Icon Icon-event"/>
        <DateInput
          label="Due Date"
          name={ActivitiesFieldName.DUE_DATE_TIME}
          value={dueDate}
          onBlur={({target}) => props.handleDatePick(target.value)}
          className={windowSize > SCREEN_XS_MAX_SIZE ? "is-cropped" : null}
          onClear={() => props.onChange({dueDate: null})}
        />
      </div>
      <Calendar handleChange={props.handleDatePick} initValue={dueDate} numberOfMonths={2} />
    </div>
  );
}
