import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import { ReactNode } from "react";
import InputField from "../../InputField/InputField";

type wizardActivitiesStyles = {
  theme: Theme;
  children: ReactNode;
  disabled?: boolean;
};

export const RecurringSelectorContainer = styled.div(
  ({ theme, disabled }: wizardActivitiesStyles) => {
    return `
      color: red;
    `;
  },
);

export const WizardRecurringSelectorText = styled(Typography)(
  ({ theme, disabled }: wizardActivitiesStyles) => `
  font-size: 0.75rem;
  color: ${disabled ? theme.palette.text.disabled : theme.palette.text.secondary};
`,
);

export const WizardRecurringCount = styled(Typography)(
  ({ theme, disabled }: wizardActivitiesStyles) => `
  font-size: 1rem;
  color: ${disabled ? theme.palette.text.disabled : theme.palette.primary.main};
`,
);

export const WizardActivityDropdown = styled(InputField)(
  ({ theme, disabled }) => `
  .MuiInputBase-input {
    width: 100%;
    color: ${disabled ? theme.palette.text.disabled : theme.palette.primary.main};
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiSelect-icon {
    color: ${disabled ? theme.palette.text.disabled : theme.palette.primary.main};
  }
`,
);
