import { useEffect, useState } from "react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { ActivityPaperWrapper, AssetPaperWrapper } from "../components/Combobox/ComboboxStyles";

interface useGetComboboxConfigProps {
  highlightLastOptionInSelect?: boolean;
  autoSelectFirstMatchOption?: boolean;
  isResetField?: boolean;
  cancelFieldReset?: () => void;
  id: string;
  creatable: boolean;
  handleChange: () => void;
  handleOpenWizard?: () => void;
}

const useGetComboboxConfig = ({
  highlightLastOptionInSelect,
  autoSelectFirstMatchOption,
  isResetField,
  cancelFieldReset,
  id,
  creatable,
  handleChange,
  handleOpenWizard,
}: useGetComboboxConfigProps) => {
  const [activityValue, setActivityValue] = useState({ value: "", label: "" });
  const [isHighlightFirstOption, setIsHighlightFirstOption] = useState(false);
  const [isHighlightSelectedOption, setIsHighlightSelectedOption] = useState(true);

  useEffect(() => {
    if (isResetField) {
      setActivityValue({ value: "", label: "" });
    }
  }, [isResetField]);

  const filter = createFilterOptions();

  const handleOptionsFiltering = (options, params) => {
    if (autoSelectFirstMatchOption) {
      if (params.inputValue === "") {
        setIsHighlightFirstOption(false);
        setIsHighlightSelectedOption(true);
      }

      if (params.inputValue) {
        setIsHighlightFirstOption(true);
        cancelFieldReset && cancelFieldReset();
      }

      if (params.inputValue.length < activityValue?.label.length && isHighlightFirstOption) {
        setIsHighlightSelectedOption(false);
      }
    }

    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (params.inputValue !== "" && creatable) {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });

      if (autoSelectFirstMatchOption) {
        filtered.length === 1
          ? handleChange(null, { id, value: params.inputValue })
          : handleChange(null, {
              id,
              value: { value: filtered[0].value, label: filtered[0].label },
            });
      }
    }

    return filtered;
  };

  const getNewValueBasedOnAction = (e, newValue: any) => {
    cancelFieldReset && cancelFieldReset();
    if (typeof newValue === "string") {
      autoSelectFirstMatchOption && setActivityValue({ value: "", label: newValue });
      handleChange(null, { id, value: newValue });
    } else if (newValue && newValue?.inputValue) {
      autoSelectFirstMatchOption && setActivityValue({ value: "", label: newValue.inputValue });
      handleChange(null, { id, value: newValue.inputValue });
    } else if (newValue?.value === "add") {
      handleOpenWizard();
    } else {
      autoSelectFirstMatchOption && setActivityValue(newValue);
      handleChange(null, { id, value: newValue });
    }
  };

  const handleOptionLabelGetting = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }

    if (!option) {
      return "";
    }

    // Add "xxx" option created dynamically
    if (option?.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.label;
  };

  const handleOptionsRendering = (option) => {
    if (option?.label) {
      return option.label;
    }
    return "";
  };

  const getOptionSelected = (option, value) => {
    if (option?.value && value?.value && isHighlightSelectedOption) {
      return option?.value === value?.value;
    }
    return false;
  };

  const getPaperComponent = () => {
    if (highlightLastOptionInSelect) {
      return AssetPaperWrapper;
    }
    if (autoSelectFirstMatchOption && isHighlightFirstOption) {
      return ActivityPaperWrapper;
    }
  };

  return {
    activityValue,
    handleOptionLabelGetting,
    handleOptionsRendering,
    getOptionSelected,
    getNewValueBasedOnAction,
    handleOptionsFiltering,
    getPaperComponent,
  };
};

export default useGetComboboxConfig;
