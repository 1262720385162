import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { VisitModel } from "../../redux/models/data/VisitModel";
import { getVisitsByStatusQuery } from "../../components/VisitsByStatuses/visitsByStatus.query";
import { ACCOUNT, VISITS } from "../../_constant/wordings";
import { customerNameFragment } from "../../graphql/resolvers";

const updateCacheAfterVisitUpdate = (
  cache: DataProxy,
  oldVisit: VisitModel,
  newVisit: VisitModel,
  accountId: string,
  oldFilterString: string,
  newFilterString: string,
) => {
  try {
    if (oldFilterString !== newFilterString) {
      const oldVisitsQuery: any = cache.readQuery({
        query: getVisitsByStatusQuery,
        variables: { Id: accountId, Filter: oldFilterString },
      });

      const newVisitsQuery: any = cache.readQuery({
        query: getVisitsByStatusQuery,
        variables: { Id: accountId, Filter: newFilterString },
      });

      const newVisitsWithOldStatus = [...oldVisitsQuery[ACCOUNT][VISITS]].filter(
        (visit: VisitModel) => visit.Id !== oldVisit.Id,
      );

      const newCustomer = cache.readFragment({
        fragment: customerNameFragment,
        id: newVisit.CustomerId!,
      });
      newVisit["CustomerName"] = newCustomer.Name;
      const resultVisit = { ...oldVisit, ...newVisit };
      const newVisitsWithNewStatus = newVisitsQuery[ACCOUNT][VISITS].concat([resultVisit]);

      cache.writeQuery({
        query: getVisitsByStatusQuery,
        variables: { Id: accountId, Filter: oldFilterString },
        data: { Account: { Visits: newVisitsWithOldStatus, Id: accountId, __typename: ACCOUNT } },
      });

      cache.writeQuery({
        query: getVisitsByStatusQuery,
        variables: { Id: accountId, Filter: newFilterString },
        data: { Account: { Visits: newVisitsWithNewStatus, Id: accountId, __typename: ACCOUNT } },
      });
    } else {
      const visitsQuery: any = cache.readQuery({
        query: getVisitsByStatusQuery,
        variables: { Id: accountId, Filter: oldFilterString },
      });

      const newCustomer = cache.readFragment({
        fragment: customerNameFragment,
        id: newVisit.CustomerId!,
      });
      newVisit["CustomerName"] = newCustomer.Name;
      const resultVisit = { ...oldVisit, ...newVisit };

      const newVisits = visitsQuery[ACCOUNT][VISITS].map((visit: VisitModel) =>
        visit.Id === oldVisit.Id ? resultVisit : visit,
      );

      cache.writeQuery({
        query: getVisitsByStatusQuery,
        variables: { Id: accountId, Filter: oldFilterString },
        data: { Account: { Visits: newVisits, Id: accountId, __typename: ACCOUNT } },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export default updateCacheAfterVisitUpdate;
