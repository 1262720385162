import React, { useEffect } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { useChange } from "../../customHooks/useChange";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { displayWarningNotification } from "../../redux/actions/_utils";

import DropZoneContainer from "../DropZoneContainer/DropZoneContainer";
import { ProjectFormContextProvider } from "./ProjectFormContext";

import EntityTabsProvider from "../EntityTabsContainer/EntityTabsProvider";

import ProjectFormPageLayout from "./ProjectFormPageLayout";
import { ProjectModel } from "../../redux/models/data/ProjectModel";

interface ProjectFormContainerProps {
  handleSubmit: (data: ProjectModel, saveType: SaveType) => void;
  isSaving: boolean;
  isFetching?: boolean;
  isNew: boolean;
  data: ProjectModel;
  setHeaderOptions: (props: any) => void;
}

const ProjectFormContainer: React.FC<ProjectFormContainerProps> = ({
  handleSubmit: submitFunction,
  isSaving,
  isFetching,
  isNew,
  data: propsData,
  setHeaderOptions,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const limits = useGetLimits();

  const isLoading = isSaving || !!isFetching;

  const formInitObject = {
    initData: new ProjectModel(),
    classModel: ProjectModel,
    requiredFields: ["Name"],
    fieldsForValidation: new Map([["Name", null]]),
  };

  const formApi = useChange<ProjectModel>(formInitObject);
  const data = formApi.data;

  useEffect(() => {
    if (propsData && !isLoading) {
      formApi.updateInitFormData(propsData);
    }
  }, [propsData]);

  const lockSaveButton = formApi.isDataChanged && formApi.isDataValid;

  const handleSubmit = (e?: React.SyntheticEvent<Element, Event> | null, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: SaveType = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: (data) => formApi.updateInitFormData(data),
    };

    submitFunction(formApi.data, saveType);
  };

  const dropZone = React.useMemo(
    () => (
      <DropZoneContainer
        data={data}
        handleChange={formApi.handleChange}
        updateManyFields={formApi.updateManyFields}
        entityName="project"
        isMobile={!isDesktop}
        imagesOnly={false}
        limitDocuments={limits?.project.documents.Value}
        limitImages={limits?.project.images.Value}
        limitVideos={limits?.project.videos.Value}
      />
    ),
    [formApi.data.Files, formApi.data.Images, formApi.data.Documents, formApi.data.Videos],
  );

  return (
    <ProjectFormContextProvider
      isNew={isNew}
      isLoading={isLoading}
      isFetching={!!isFetching}
      isSaving={isSaving}
      handleSubmit={handleSubmit}
      formApi={formApi}
      lockSaveButton={lockSaveButton}
      dropZone={dropZone}
      setHeaderOptions={setHeaderOptions}
    >
      <EntityTabsProvider>
        <ProjectFormPageLayout />
      </EntityTabsProvider>
    </ProjectFormContextProvider>
  );
};

export default ProjectFormContainer;
