import React, { useState } from "react";
import Dropzone from "react-dropzone";

import {
  DropZoneStyled,
  DropZoneInner,
  DropZoneEmptyStateContainer,
  DropZoneEmptyStateIcon,
  DropZoneEmptyStateText,
  FormatTitleText,
  FormatText,
  FormatContainer,
  FullDropZoneContainer,
  FileNameText,
  CancelButton,
  ErrorContainer,
  ErrorNotification,
  UploadDropzoneWrapper,
  UploadDropzoneContainer,
} from "./UploadDropzone.style";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ReactComponent as CancelIcon } from "../../../images/delete.svg";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import { SyntheticEvent } from "hoist-non-react-statics/node_modules/@types/react";
import { displayWarningNotification } from "../../redux/actions/_utils";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { FileType, UploadErrorType } from "./UploadCsvDialog";

const CSVFileValidationOptions = {
  size: 524288000,
  type: [
    "text/csv",
    "application/csv",
    "text/comma-separated-values",
    ".csv",
    "application/vnd.ms-excel",
  ],
};

interface UploadDropzoneProps {
  selectFile: (file: FileType) => void;
  file: FileType;
  uploadError: UploadErrorType;
  setUploadError: (uploadError: UploadErrorType) => void;
}

const UploadDropzone: React.FC<UploadDropzoneProps> = ({
  selectFile,
  file,
  uploadError,
  setUploadError,
}) => {
  const staticData = useStaticDataQuery();
  const emptyStateIcon = staticData?.Icons?.Mobile?.pic_Mobile_Empty_State ?? "";

  const [isError, setIsError] = useState(false);

  const addFile = (acceptedFiles: File[]) => {
    setUploadError(null);
    const acceptedFile = acceptedFiles[0];
    if (acceptedFiles.length > 1) {
      displayWarningNotification(NotificationTypes.MORE_THEN_ONE_FILE);
    }
    if (
      CSVFileValidationOptions.type.includes(acceptedFile?.type) &&
      acceptedFile?.size < CSVFileValidationOptions.size
    ) {
      selectFile(acceptedFile);
      setIsError(false);
      return;
    }
    setIsError(true);
  };

  const removeFile = (e: SyntheticEvent) => {
    e.stopPropagation();
    selectFile(null);
  };

  return (
    <Dropzone
      onDrop={addFile}
      accept={CSVFileValidationOptions.type}
      maxSize={CSVFileValidationOptions.size}
    >
      {({ getRootProps, getInputProps }) => (
        <UploadDropzoneWrapper>
          <UploadDropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <DropZoneInner>
              <DropZoneStyled>
                {!file ? (
                  <DropZoneEmptyStateContainer>
                    <DropZoneEmptyStateIcon src={emptyStateIcon} />

                    {!isError ? (
                      <>
                        <DropZoneEmptyStateText>
                          Upload File to Create Multiple Items
                        </DropZoneEmptyStateText>
                        <FormatContainer>
                          <FormatTitleText>Valid format:</FormatTitleText>
                          <FormatText>CSV. Max 500 Mb.</FormatText>
                        </FormatContainer>
                      </>
                    ) : (
                      <ErrorContainer>
                        <ErrorNotification>
                          <InfoOutlinedIcon /> Invalid format
                        </ErrorNotification>
                        <FormatContainer>
                          <FormatTitleText>Please check your file for compliance:</FormatTitleText>
                          <FormatText>CSV. Max 500 Mb.</FormatText>
                        </FormatContainer>
                      </ErrorContainer>
                    )}
                  </DropZoneEmptyStateContainer>
                ) : (
                  <DropZoneEmptyStateContainer>
                    <FullDropZoneContainer>
                      <CancelButton type="button" onClick={removeFile}>
                        <CancelIcon />
                      </CancelButton>
                    </FullDropZoneContainer>
                    <FileNameText>{file.name}</FileNameText>
                    {uploadError && (
                      <ErrorContainer>
                        <ErrorNotification>
                          <InfoOutlinedIcon /> Upload Error
                        </ErrorNotification>
                        <FormatTitleText>Please check your file for compliance:</FormatTitleText>
                        <FormatText>{uploadError}</FormatText>
                      </ErrorContainer>
                    )}
                  </DropZoneEmptyStateContainer>
                )}
              </DropZoneStyled>
            </DropZoneInner>
          </UploadDropzoneContainer>
        </UploadDropzoneWrapper>
      )}
    </Dropzone>
  );
};

export default UploadDropzone;
