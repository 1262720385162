import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../_constant/screens";
import useAccountSwitch from "../customHooks/useAccountSwitch";

const SwitchAccountByUrl = () => {
  const { pathname, search } = useLocation();
  const { changeAccountIdWithoutCache } = useAccountSwitch();

  useEffect(() => {
    if (search.includes("accountId=")) {
      const accountId = search.split("accountId=")[1];
      if (Object.values(ROUTES).includes(pathname)) changeAccountIdWithoutCache({ Id: accountId });
    }
  }, []);

  return null;
};

export default SwitchAccountByUrl;
