import React from "react";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";

import { HEADER_TYPES } from "../../_constant/constants";

import BusinessHeader from "./BusinessHeader";
import DefaultHeader from "./DefaultHeader";

const MobileHeaderContainer: React.FC = () => {
  const { headerOptions } = useHeaderOptions();
  const { headerType } = headerOptions;
  const { IsCustomerManagement } = useGetAccountId();

  return IsCustomerManagement && headerType !== HEADER_TYPES.DEFAULT ? (
    <BusinessHeader isBusinessPage />
  ) : (
    <DefaultHeader />
  );
};

export default MobileHeaderContainer;
