import * as React from "react";
import DueDateComponent from "../../containers/activities/_components/date/due_date/DueDateComponent";
import CompletedDateComponent from "../../containers/activities/_components/date/completed/CompletedDateComponent";

interface ActivitiesDateSettingsProps {
  newRelatedActivity: any;
  updateRelatedActivity: any;
}
const ActivitiesDateSettings: React.FC<ActivitiesDateSettingsProps> = ({
  newRelatedActivity,
  updateRelatedActivity,
}) => {
  return (
    <div className="Section_row Section_row-equalColumns">
      <DueDateComponent
        value={newRelatedActivity.DueDateTime}
        additionalProps={newRelatedActivity.Schedule}
        relatedDueDateTime={newRelatedActivity.RelatedDueDateTime}
        onChange={updateRelatedActivity}
      />
      <CompletedDateComponent
        value={newRelatedActivity.CompletedDateTime}
        onChange={updateRelatedActivity}
      />
    </div>
  );
};

export default ActivitiesDateSettings;
