import { useMutation } from "react-apollo";
import { createActivitiesQuery } from "../containers/activities/existing/activity.query";
import {
  updateAllActivitiesCache,
  updateAssetActivitiesAfterAddNewActivity,
  updateAssetRefQuery,
} from "../containers/activities/new/updateCacheWithNewActivity";
import useGetAccountId from "./useGetAccountId";
import { ActivityModel } from "../redux/models/data/ActivityModelFull";
import { ExecutionResult } from "graphql";
import useGetAcitvitiesStatuses from "./api/useGetAcitvitiesStatuses";
import { getActivityFilters } from "../redux/reducers/appReducer";
import { convertStatusToFilterVariable } from "../redux/actions/_utils";
import { useSelector } from "react-redux";
import { updateActivitiesAmountCache } from "../components/ItemsTab/updateActivitiesCache";
import { updateCustomerActivitiesCacheWithNewActivity } from "../containers/assets/_components/relaitedActivities/new/updateActivitiesCacheWithNewActivity";
import { RepeatType } from "../_constant/constants";
import { ActivitiesCustomMap } from "../redux/models/data/ActivityForWizardModel";

const useCreateAssetActivities = (): [
  (
    dataForCreate: ActivitiesCustomMap,
    AssetId: string,
    customerId?: string | null,
  ) => Promise<ExecutionResult<any>> | undefined,
  { isActivityCreating: boolean },
] => {
  const [createActivitiesMutation, { data, loading: isActivityCreating, error }] = useMutation(
    createActivitiesQuery,
  );
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();

  const activityFiltersMap = useSelector((state) => getActivityFilters(state));

  const createActivities = (
    dataForCreate: ActivitiesCustomMap,
    AssetId: string,
    customerId?: string | null,
  ) => {
    if (!statuses) {
      return;
    }

    const activities = Array.from(dataForCreate.values())
      .filter((activity: any) => activity._checked)
      .map(({ Schedule, ...rest }) => {
        const Activity = new ActivityModel({
          ...rest,
          AssetId,
          Id: null,
          Schedule: {
            ...Schedule,
            RecurringType: !!Schedule?.RecurrenceCount ? Schedule.RecurringType : RepeatType.NONE,
          },
        });
        const { Files, isLoading, Affiliates, ...normalizedActivity } = Activity;
        normalizedActivity.AccountId = accountId;
        return normalizedActivity;
      });

    return createActivitiesMutation({
      variables: { activities },
      update(cache, { data: { ActivitiesPost } }) {
        ActivitiesPost.forEach((activity) => {
          const currentStatus = statuses.find((element) => element.Id === activity.StatusId);
          const filterString = convertStatusToFilterVariable(currentStatus, activityFiltersMap);
          if (IsCustomerManagement && customerId) {
            updateActivitiesAmountCache(cache, activity);
            updateCustomerActivitiesCacheWithNewActivity(cache, activity, customerId);
            return;
          }
          updateAllActivitiesCache(cache, activity, filterString);
          updateAssetRefQuery(cache, activity, accountId);
          updateAssetActivitiesAfterAddNewActivity(cache, activity, accountId);
        });
      },
    });
  };

  return [createActivities, { isActivityCreating }];
};

export default useCreateAssetActivities;
