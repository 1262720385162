import styled from "styled-components";
import { Box, Grid, IconButton } from "@material-ui/core";

export const ControlActivityButton = styled(IconButton)`
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

export const StyledContainer = styled(Box)`
  padding: 16px;
`;

export const ActivityContainer = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
  background-color: transparent;
`;

export const ActivityItemContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
  border-radius: 8px;
`;

export const RemoveLabel = styled.span`
  margin-right: 8px;
`;

export const StyledGrid = styled(Grid)`
  height: 100%;
`;

export const RepeatItemContainer = styled(Grid)`
  max-width: 22px;
`;
