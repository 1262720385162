import * as React from "react";
import { connect } from "react-redux";

import AuthService from "./authService";
import { getAppUrl, getAuthUrl, getClientId } from "../redux/reducers/appReducer";

const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  isExpired: () => ({}),
});

class AuthProvider extends React.Component<any, any> {
  authService: any;

  constructor(props: any) {
    super(props);
    this.authService = new AuthService({
      authUrl: props.authUrl,
      appUrl: props.appUrl,
      clientId: props.clientId,
    });
  }

  render() {
    return (
      <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  authUrl: getAuthUrl(state),
  appUrl: getAppUrl(state),
  clientId: getClientId(state),
});

export default connect(mapStateToProps)(AuthProvider);

export const AuthConsumer = AuthContext.Consumer;
