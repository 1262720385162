import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-apollo";

import { CircularProgress } from "@material-ui/core";

import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

import DeleteButton from "../DeleteButton/DeleteButton";

import { UserRef } from "../../redux/models/data/UserRefModel";

import getUserRoleInAccount from "./checkUserRoleInAccount.query";
import { getUserInfoQuery } from "../../containers/profile/profile.query";

import { ACCOUNT, ID, USER_REFS, USER } from "../../_constant/wordings";
import { UserRole } from "../../_constant/constants";

interface IAccountManageTab {
  id: string;
  isDataChanged: boolean;
  isLoading: boolean;
  isDeletePopupVisible: boolean;
  handleOpen: () => void;
  handleLeavePopupOpen: () => void;
}

const AccountManageTab: React.FC<IAccountManageTab> = ({
  handleOpen,
  handleLeavePopupOpen,
  id,
  isDataChanged,
  isLoading,
  isDeletePopupVisible,
}) => {
  const [currentUser, setCurrentUser] = useState<null | UserRef>(null);

  const { data: userRefs, loading: userRefsLoading, error: userRefsError } = useQuery(
    getUserRoleInAccount,
    {
      variables: {
        Id: id,
      },
      skip: !id,
      fetchPolicy: "network-only",
    },
  );

  const { data: userData } = useQuery(getUserInfoQuery, {
    fetchPolicy: "cache-only",
  });

  useEffect(() => {
    if (!userRefsLoading && !userRefsError && userRefs) {
      if (userData) {
        const userId = userData[USER][ID];
        const currentUserFromRef = userRefs[ACCOUNT][USER_REFS].find(
          (user: UserRef) => user.Id === userId,
        );
        setCurrentUser(currentUserFromRef);
      }
    }
  }, [userRefs]);

  if (userRefsLoading || !currentUser) {
    return (
      <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
        <CircularProgress size={22} />
      </div>
    );
  }

  return (
    <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
      {currentUser && currentUser.Role === UserRole.owner ? (
        <DeleteButton
          onClick={handleOpen}
          disabled={!id || isDataChanged}
          loading={isLoading || isDeletePopupVisible}
        >
          Delete Account
        </DeleteButton>
      ) : (
        <DeleteButton
          onClick={handleLeavePopupOpen}
          disabled={!id || isDataChanged}
          loading={isLoading || isDeletePopupVisible}
          icon={<ExitToAppOutlinedIcon />}
        >
          Leave Account
        </DeleteButton>
      )}
    </div>
  );
};

export default AccountManageTab;
