import React from "react";
import { useLazyQuery } from "react-apollo";

import { useConnectionState } from "../../customHooks/useConnectionState";

import Portal from "@material-ui/core/Portal/Portal";
import Grow from "@material-ui/core/Grow";
import Button from "@material-ui/core/Button";

import { Wrapper, Image, MessageTitle, MessageText, Container } from "./NoInternetScreen.style";

import { GET_ACCOUNT_ID } from "../../customHooks/useGetAccountId";

interface NoInternetScreenProps {
  isConnectionLost: boolean;
}

const NoInternetScreen: React.FC<NoInternetScreenProps> = ({ isConnectionLost }) => {
  const { setConnectionState } = useConnectionState();

  const [getAccount] = useLazyQuery(GET_ACCOUNT_ID);

  const reloadHandler = () => {
    setConnectionState(false);
    getAccount();
  };

  return (
    <Portal>
      <Grow in={isConnectionLost}>
        <Container>
          <Wrapper>
            <Image />
            <MessageTitle>No internet</MessageTitle>
            <MessageText>Check your internet connection</MessageText>
            <Button onClick={reloadHandler} color="primary">
              Reload
            </Button>
          </Wrapper>
        </Container>
      </Grow>
    </Portal>
  );
};

export default NoInternetScreen;
