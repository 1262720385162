import { getActivitiesRelatedToProjectQuery } from "../ActivitiesTabProject/getActivitiesRelatedToProject.query";

import { DataProxy } from "apollo-cache/lib/types/DataProxy";

export const addActivityToProjectTabCache = (
  cache: DataProxy,
  activity: any,
  projectId: string,
) => {
  const oldActivities = cache.readQuery({
    query: getActivitiesRelatedToProjectQuery,
    variables: { ProjectId: projectId },
  });

  const { AccountId, FileUploadResults, VendorRefs, isLoading, ...newActivity } = activity;

  const newActivities = {
    Project: {
      Id: projectId,
      __typename: "ProjectType",
      AssetActivities: [
        ...oldActivities.Project.AssetActivities,
        {
          ...newActivity,
        },
      ],
    },
  };

  cache.writeQuery({
    query: getActivitiesRelatedToProjectQuery,
    variables: { ProjectId: projectId },
    data: newActivities,
  });
};
