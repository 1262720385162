import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-apollo";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { Button, Container, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

import {
  displayErrorNotification,
  displaySuccessNotification,
  convertStatusToFilterVariable,
} from "../../../redux/actions/_utils";
import { getActivityFilters } from "../../../redux/reducers/appReducer";

import ActionsBar from "../../../components/ActionsBar/ActionsBar";
import Body from "../../../components/Body/Body";
import { LoaderCircle } from "../../../components/Loader";
import ActivitiesList from "../../../components/ActivitiesList/ActivitiesList";
import ProjectsByStatus from "./content/ProjectsByStatus";
import { AllProjectsCacheUpdate, turnOffLoadingInProjectCache } from "./AllProjectsCacheUpdate";

import { isCompletedStatus } from "../../../_utils/utils";
import FilterProjectsDesktop from "../../../components/FilterProjectsDesktop/FilterProjectsDesktop";

import { LayoutType } from "../../../_constant/constants";
import { NotificationTypes } from "../../../_constant/NotificationTypes";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import useGetProjectsStatuses from "../../../customHooks/api/useGetProjectsStatuses";

import { ROUTES } from "../../../_constant/screens";
import { CHANGE_STATUS } from "../all/projects-list.query";
import { PROJECT_PATCH } from "../../../_constant/wordings";
import { projectsEmptyStatusList } from "../../../redux/reducers/appReducer";
import { useGetUserSubscriptionTier } from "../../../customHooks/useGetUserSubscriptionTier";

interface AllProjectsPageProps {
  titlePrefix: string;
}

interface StyledWrapperProps {
  showPadding: boolean | null;
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  @media screen and (min-width: 600px) {
    padding-left: ${({ showPadding }) => (showPadding ? "212px" : 0)};
    height: 100%;
  }
`;

const AllProjects: React.FC<AllProjectsPageProps> = ({ titlePrefix = "All" }) => {
  const { accountId, isPersonal } = useGetAccountId();
  const statuses = useGetProjectsStatuses();
  const navigate = useNavigate();

  const [updateStatus, { client }] = useMutation(CHANGE_STATUS);

  const emptyStatuses = useSelector(projectsEmptyStatusList);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  const userSubscriptionTier = useGetUserSubscriptionTier();

  useEffect(() => {
    if (
      !userSubscriptionTier.isProfessional &&
      !userSubscriptionTier.isPremium &&
      !userSubscriptionTier.loading
    ) {
      navigate(ROUTES.ACTIVITIES);
    }
  }, [userSubscriptionTier]);

  useEffect(() => {
    if (statuses.length > 0) {
      setShowEmptyMessage(emptyStatuses.size === statuses.length);
    }
  }, [emptyStatuses]);

  const projectFiltersMap = useSelector((state) => getActivityFilters(state));

  const updateProjectStatus = (project: any, nextStatusId: string) => {
    const foundNextStatus = statuses.find((element) => element.Id === nextStatusId);
    const foundPrevStatus = statuses.find((element) => element.Id === project.ProjectStatusId);

    const filterStringTo = convertStatusToFilterVariable(
      foundNextStatus,
      projectFiltersMap,
      "ProjectStatusId",
    );

    const filterStringFrom = convertStatusToFilterVariable(
      foundPrevStatus,
      projectFiltersMap,
      "ProjectStatusId",
    );

    const CompletedDateTime = isCompletedStatus(statuses, nextStatusId)
      ? moment().toISOString()
      : null;

    updateStatus({
      variables: {
        AccountId: accountId,
        Id: project.Id,
        ProjectStatusId: nextStatusId,
        CompletedDateTime,
      },
      optimisticResponse: {
        ProjectPatch: {
          Id: project.Id,
          ProjectStatusId: nextStatusId,
          CompletedDateTime,
          __typename: "ProjectType",
        },
      },
      update: (cache, { data: { ProjectPatch } }) => {
        AllProjectsCacheUpdate(
          cache,
          project,
          ProjectPatch,
          filterStringFrom,
          filterStringTo,
          accountId,
        );
      },
    })
      .then(({ data }) => {
        if (data[PROJECT_PATCH] && data[PROJECT_PATCH].ProjectStatusId === nextStatusId) {
          turnOffLoadingInProjectCache(client, project, filterStringTo, accountId);
          displaySuccessNotification(NotificationTypes.PROJECT_UPDATED);
        } else {
          throw "null instead of data";
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.PROJECT_UPDATED_ERROR);
      });
  };

  return (
    <>
      <StyledWrapper showPadding={!!accountId && !isPersonal}>
        <Body disableDesktopScroll>
          <Container maxWidth="md">
            <ActionsBar title={`${titlePrefix} Projects`} withSearch>
              <Grid item container spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                  <Button
                    component={Link}
                    to={ROUTES.CREATE_PROJECT}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    disabled={
                      !userSubscriptionTier.isProfessional && !userSubscriptionTier.isPremium
                    }
                  >
                    Project
                  </Button>
                </Grid>
              </Grid>
            </ActionsBar>
          </Container>

          {statuses.length > 0 && (
            <ActivitiesList listView>
              {statuses.map((status) => (
                <ProjectsByStatus
                  key={status.Id}
                  status={status}
                  statuses={statuses}
                  isAccountPersonal={!!isPersonal}
                  layout={LayoutType.LIST}
                  updateProjectStatus={updateProjectStatus}
                  showEmptyMessage={showEmptyMessage}
                />
              ))}
            </ActivitiesList>
          )}

          {statuses.length === 0 && (
            <div className={"LoaderWrapper"}>
              <LoaderCircle size={"xl"} />
            </div>
          )}

          {!!accountId && !isPersonal && <FilterProjectsDesktop />}
        </Body>
      </StyledWrapper>
    </>
  );
};

export default AllProjects;
