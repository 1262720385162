import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../_constant/screens";
import { useQuery } from "@apollo/react-hooks";
import { getAccountRefsList } from "../Accounts/getAccountList.query";
import { AccountModel } from "../../redux/models/data/AccountModel";
import { ACCOUNT_REFS, USER } from "../../_constant/wordings";
import useGetUserId from "../../customHooks/useGetUserId";
import CircularProgress from "@material-ui/core/CircularProgress";

const Business: React.FC = () => {
  const navigate = useNavigate();
  const currentUserId = useGetUserId();

  const { data: accountRefsData, loading: accountRefsLoading } = useQuery(getAccountRefsList, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (currentUserId && accountRefsData) {
      const foundBusinessAccount: AccountModel = accountRefsData[USER][ACCOUNT_REFS].find(
        (account: AccountModel) => {
          return account.IsCustomerManagement && account.OwnerId === currentUserId;
        },
      );

      if (foundBusinessAccount) {
        navigate(ROUTES.EDIT_BUSINESS, { replace: true });
      } else {
        navigate(ROUTES.NEW_BUSINESS, { replace: true });
      }
    }
  }, [accountRefsData, currentUserId]);

  return (
    <div
      style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress size={50} />
    </div>
  );
};

export default Business;
