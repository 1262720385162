import gql from "graphql-tag";
import { vendorDetailedFragment } from "../NewVendor/createVendor.query";

export const getVendorById = gql`
  query getVendorById($AccountId: ID!, $VendorId: ID!) {
    Vendor(AccountId: $AccountId, Id: $VendorId) {
      ${vendorDetailedFragment}
    }
  }
`;
