import React, { memo } from "react";
import Combobox from "../../../../../components/Combobox/Combobox";
import useGetItemsNames from "../../../../../customHooks/useGetItemsNames";
import useGetCustomerItemsNames from "../../../../../customHooks/useGetCustomerItemsNames";
import useGetCustomerIdFromUrl from "../../../../../customHooks/useGetCustomerId";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

interface ItemSelectProps {
  AssetId: string;
  errorMessage?: string;
  onChangeWithValidation;
  getNewValueBasedOnAction;
  customerId?: string;
  disabled?: boolean;
  icon?: any;
  handleOpenWizard: () => void;
  justCreatedItemName?: string;
  highlightLastOptionInSelect?: boolean;
}

const ParentItemSelect: React.FC<ItemSelectProps> = (props) => {
  const customerId = useGetCustomerIdFromUrl();

  const { assetsData, loading } = useGetItemsNames(customerId);

  const customerAssetsData = useGetCustomerItemsNames(props.customerId || customerId);

  const handleItemSelect = (e, newValue: { id: string; value: any }) => {
    const { id, value } = newValue;
    if (value && value.value) {
      props.onChangeWithValidation(null, { id, value: value.value });
    } else if (!newValue) {
      props.onChangeWithValidation(null, { id, value: { Id: null, Name: null } });
    }
  };

  let data = props.customerId || customerId ? customerAssetsData : assetsData;
  data = data.map((item) => ({
    value: item.Id,
    label: item.Name,
  }));

  const optionsForSelect = props.highlightLastOptionInSelect
    ? data.concat([{ label: "Create new item", value: "add" }])
    : data;

  const value = data.find((item) => item.value === props.AssetId);

  return (
    <Combobox
      id="AssetId"
      label="Item"
      value={value}
      options={optionsForSelect}
      handleChange={handleItemSelect}
      hasError={!!props.errorMessage}
      creatable={false}
      icon={props.icon || <CreateOutlinedIcon />}
      disabled={props.disabled}
      required
      loading={loading}
      handleOpenWizard={props.handleOpenWizard}
      defaultValue={props.justCreatedItemName}
      highlightLastOptionInSelect={props.highlightLastOptionInSelect}
    />
  );
};

export default memo(ParentItemSelect, (prevProps, props) => {
  const isAssetIdSame = prevProps.AssetId === props.AssetId;
  const isErrorMsgSame = prevProps.errorMessage === props.errorMessage;
  const isLoadingSame = prevProps.disabled === props.disabled;
  const isOnChangeWithValidationSame =
    prevProps.onChangeWithValidation === props.onChangeWithValidation;

  if (isAssetIdSame && isErrorMsgSame && isLoadingSame && isOnChangeWithValidationSame) {
    return true;
  }
  return false;
});
