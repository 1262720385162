import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { ACCOUNT, VISITS } from "../../_constant/wordings";
import { getVisitsByStatusQuery } from "../VisitsByStatuses/visitsByStatus.query";
import { VisitModel } from "../../redux/models/data/VisitModel";

export const updateCacheAfterDeleteVisit = (
  cache: DataProxy,
  visitDelete: any,
  accountId: string,
  filterString: string,
) => {
  try {
    const visitListQuery: any = cache.readQuery({
      query: getVisitsByStatusQuery,
      variables: { Id: accountId, Filter: filterString },
    });

    const newVisitList = visitListQuery[ACCOUNT][VISITS].filter(
      (visit: VisitModel) => visit.Id !== visitDelete.Id,
    );

    cache.writeQuery({
      query: getVisitsByStatusQuery,
      variables: { Id: accountId, Filter: filterString },
      data: { Account: { Visits: newVisitList, Id: accountId, __typename: ACCOUNT } },
    });
  } catch (error) {}
};
