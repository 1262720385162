import gql from "graphql-tag";

export const getWizardDataQuery = gql`
  query getWizardData {
    WizardData {
      AssetTypeId
      Name
      Id
      TypeFieldValues
      Activities {
        Name
        Schedule {
          RecurrenceCount
          RecurringRepeatType
          RecurringType
          Date
        }
      }
    }
  }
`;
