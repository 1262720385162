import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { GET_PROJECTS_LIST } from "../../containers/projects/all/projects-list.query";

import { ProjectModel } from "../../redux/models/data/ProjectModel";

export const updateCacheWithEditProject = (
  cache: DataProxy,
  project: ProjectModel,
  filterStringForNewProject: string,
  filterStringForOldProject: string,
) => {
  try {
    if (filterStringForNewProject === filterStringForOldProject) {
      return;
    }
    const { Activities, Videos, Documents, FileUploadResults, ...normalizedProject } = {
      ...project,
    };

    const projectsListForNewStatus: any = cache.readQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: project.AccountId,
        Filter: filterStringForNewProject,
      },
    });

    const projectsListForOldStatus: any = cache.readQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: project.AccountId,
        Filter: filterStringForOldProject,
      },
    });

    const newProjectListForNewStatus = {
      Projects: [...projectsListForNewStatus.Projects, normalizedProject],
    };

    const newProjectListForOldStatus = {
      Projects: projectsListForOldStatus.Projects.filter(
        (project: ProjectModel) => project.Id !== normalizedProject.Id,
      ),
    };

    cache.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: project.AccountId,
        Filter: filterStringForNewProject,
      },
      data: newProjectListForNewStatus,
    });

    cache.writeQuery({
      query: GET_PROJECTS_LIST,
      variables: {
        AccountId: project.AccountId,
        Filter: filterStringForOldProject,
      },
      data: newProjectListForOldStatus,
    });
  } catch (e) {
    // console.log(e);
  }
};
