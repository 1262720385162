import gql from "graphql-tag";

export const getFinishedVisitActivities = gql`
  query getFinishedVisitActivities($VisitId: ID, $AccountId: ID) {
    Visit(Id: $VisitId, AccountId: $AccountId) {
      Id
      FinishedVisitActivities {
        Id
        Name
        StatusId
        Description
        DueDateTime
        CompletedDateTime
        RelatedDueDateTime
        Schedule {
          RecurringType
        }
        Asset {
          Id
          Name
        }
      }
    }
  }
`;
