import gql from "graphql-tag";

export const GET_CUSTOMER_ACTIVITIES = gql`
  query getCustomerActivities($AccountId: ID, $CustomerId: ID) {
    Customer(AccountId: $AccountId, Id: $CustomerId) {
      Id
      AssetActivities {
        Id
        Name
        Description
        StatusId
        DueDateTime
        CompletedDateTime
        Images {
          Id
          ContainerId
          ContainerName
        }
        Schedule {
          RecurringType
        }
        RelatedDueDateTime
        Asset {
          Id
          Name
        }
        isLoading @client
      }
    }
  }
`;

// export const getVisitActivities = gql`
//   query getVisitActivities($AccountId: ID, $Id: ID) {
//     Visit(Id: $Id, AccountId: $AccountId) {
//       Id
//       VisitStartActivities {
//         Id
//         StatusId
//         Description
//         DueDateTime
//         CompletedDateTime
//       }
//     }
//   }
// `;
