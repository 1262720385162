import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import EditAccount from "../pages/EditAccount/EditAccount";
import NewAccount from "../pages/NewAccount/NewAccount";

const AccountRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="create/new" element={<NewAccount />} />
      <Route path=":id" element={<EditAccount />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default AccountRoutes;
