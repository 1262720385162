import React, { useEffect } from "react";

import _ from "lodash";

import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";

import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";
import {
  ActivitiesCustomMap,
  ActivityForWizardModel,
} from "../../../redux/models/data/ActivityForWizardModel";

import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import WizardActivityCard from "../WizardActivityCard/WizardActivityCard";
import WizardActivityRow from "../WizardActivityRow/WizardActivityRow";

import { RepeatAfter, RepeatType } from "../../../_constant/constants";
import { formatDate } from "../../../_utils/date";

import {
  ActivityScreenContainer,
  ScrollableContainer,
  TypeAndItemContainer,
} from "./WizardActivityScreenStyle";
import { WizardActivitiesHint } from "../WizardContainer/WizardContainerStyles";

interface WizardActivityScreenProps {
  newItem: AssetForWizardModel;
  newActivities: ActivitiesCustomMap;
  updateActivities: (newActivities: ActivitiesCustomMap) => void;
}

const WizardActivityScreen: React.FC<WizardActivityScreenProps> = ({
  newItem,
  newActivities,
  updateActivities,
}) => {
  const statuses = useGetAcitvitiesStatuses();

  useEffect(() => {
    if (statuses) {
      const normalizedActivities: [string, ActivityForWizardModel][] = newItem._AssetActivities.map(
        (
          { Name, Schedule: { RecurrenceCount, RecurringRepeatType, RecurringType, Date } },
          index,
        ) => {
          return [
            Name as string,
            new ActivityForWizardModel({
              Name,
              DueDateTime: formatDate(Date),
              Schedule: { RecurrenceCount, RecurringRepeatType, RecurringType },
              Id: index.toString(),
              _order: index,
              StatusId: statuses[0].Id,
            }),
          ];
        },
      );

      const newActivitiesMap: ActivitiesCustomMap = new Map(normalizedActivities);

      updateActivities(newActivitiesMap);
    }
  }, [newItem, statuses]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name, checked } = target;

    const newActivitiesCopy = new Map(newActivities);
    const activityToChange = newActivitiesCopy.get(name);

    newActivitiesCopy.delete(name);

    newActivitiesCopy.set(name, {
      ...activityToChange,
      _checked: checked,
    });

    const arrFromNewActivities = Array.from(newActivitiesCopy.entries()).sort((a, b) => {
      return a[1]._order - b[1]._order;
    });

    updateActivities(new Map(arrFromNewActivities));
  };

  const handleNameChange = (name, nextName) => {
    const newActivitiesCopy = new Map(newActivities);
    const activityToChange = newActivitiesCopy.get(name);

    newActivitiesCopy.delete(name);

    newActivitiesCopy.set(nextName, {
      ...activityToChange,
      Name: nextName,
      _editModeEnabled: false,
    });

    const arrFromNewActivities = Array.from(newActivitiesCopy.entries()).sort((a, b) => {
      return a[1]._order - b[1]._order;
    });

    updateActivities(new Map(arrFromNewActivities));
  };

  const handleEditActivity = (name: string, property: string, value: any) => {
    const newActivitiesCopy = new Map(newActivities);
    const activityToChange = newActivitiesCopy.get(name);

    newActivitiesCopy.delete(name);

    newActivitiesCopy.set(name, {
      ...activityToChange,
      [property]: value,
    });

    const arrFromNewActivities = Array.from(newActivitiesCopy.entries()).sort((a, b) => {
      return a[1]._order - b[1]._order;
    });

    updateActivities(new Map(arrFromNewActivities));
  };

  const addCustomActivity = (name = "Custom Activity") => {
    const newActivitiesCopy = new Map(newActivities);
    newActivitiesCopy.set(
      name,
      new ActivityForWizardModel({
        Name: name,
        StatusId: statuses[0].Id,
        Schedule: {
          RecurringType: RepeatType.MONTH,
          RecurrenceCount: 0,
          RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
        },
        _editModeEnabled: true,
        _order: newActivities.size,
      }),
    );

    updateActivities(newActivitiesCopy);
  };

  return (
    <ActivityScreenContainer>
      <TypeAndItemContainer aria-label="breadcrumb">
        <Typography variant="caption">{newItem.AssetType.Name}</Typography>
        <Typography variant="caption" color="textPrimary">
          {newItem.Name}
        </Typography>
      </TypeAndItemContainer>
      <WizardActivitiesHint variant="caption" color="textPrimary">
        Uncheck any activities that don’t apply to your item:
      </WizardActivitiesHint>
      <ScrollableContainer>
        {Array.from(newActivities.values()).map((activity) => {
          return (
            <WizardActivityCard
              key={activity.Name}
              activity={activity}
              handleSelect={handleSelect}
              handleChange={handleEditActivity}
              handleNameChange={handleNameChange}
            />
          );
        })}
        <WizardActivityRow noBorder>
          <Button
            color="primary"
            onClick={() => addCustomActivity()}
            startIcon={<AddOutlinedIcon />}
          >
            Add Custom Activity
          </Button>
        </WizardActivityRow>
      </ScrollableContainer>
    </ActivityScreenContainer>
  );
};

export default WizardActivityScreen;
