import * as React from "react";
import {useEffect, useState} from "react";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import moment from "moment";
import {DATE_FORMAT, formatDate, getDateWithUpdatedTime} from "../_utils/date";
import {DIGIT} from "../_utils/regex";
import {INVALID_DATE} from "../_constant/constants";

interface DateInputPropTypes {
  label?: string;
  name: string;
  value?: string;
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  onClear?: () => void;
  onBlur?: (e?: any) => void;
  placeholder?: string;
  minDate?: string;
  maxDate?: string;
  errorMessage?: string;
  showCalendar?: () => void;
}

export const INPUT_DATE_MASK = [DIGIT, DIGIT, DIGIT, DIGIT, "/", DIGIT, DIGIT, "/", DIGIT, DIGIT];
const PLACEHOLDER = DATE_FORMAT.toLowerCase();
const DEFAULT_MIN_DATE = "1900/01/01";
const DEFAULT_MAX_DATE = "2100/01/01";

export const DateInput: React.FC<DateInputPropTypes> = (props) => {
  const [value, setValue] = useState(null);
  const {label = "", name, className = "", disabled, hasError, placeholder, minDate, maxDate, errorMessage} = props;
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe(DATE_FORMAT.toLocaleLowerCase());

  useEffect(() => { // component receive new props
    setValue(formatDate(props.value));
  }, [props.value]);

  const onChange = ({target}) => {
    setValue(target.value);
  };

  const handleBlur = ({target}) => {
    const inputValue = moment(target.value, DATE_FORMAT, true);
    const isValidValue = inputValue.isValid();
    let nextValue = inputValue.format(DATE_FORMAT);

    if (isValidValue && inputValue.isBefore(minDate || DEFAULT_MIN_DATE, "day")) {
      nextValue = minDate || DEFAULT_MIN_DATE;
    } else if (isValidValue && inputValue.isAfter(maxDate || DEFAULT_MAX_DATE, "day")) {
      nextValue = maxDate || DEFAULT_MAX_DATE;
    } else if (!isValidValue) {
      nextValue = null;
    }
    setValue(nextValue);

    if (props.onBlur) {
      props.onBlur({target: {value: getDateWithUpdatedTime(nextValue), name}});
    }
  };

  const handleMouseDown = (e) => {
    if (props.showCalendar) {
      e.preventDefault();
      props.showCalendar();
    }
  };

  return (
    <div className={`Input Input-withIcon ${className}`}>
      <MaskedInput
        keepCharPositions
        mask={INPUT_DATE_MASK}
        pipe={autoCorrectedDatePipe}
        className={`Input_field${hasError ? " has-error" : ""}${value ? " has-value" : ""}`}
        id={name}
        defaultValue={value || ""}
        onChange={onChange}
        placeholder={placeholder || PLACEHOLDER}
        onBlur={handleBlur}
        autoComplete={"off"}
        disabled={disabled}
        render={
          (ref, {defaultValue, ...renderProps}) =>
            <input ref={ref} value={defaultValue || ""}{...renderProps}/>
        }
        onMouseDown={handleMouseDown}
      />
      {value && !disabled && <div className={"ClearIcon"} onClick={() => props.onClear()}>
          <i className="Icon Icon-clear"/>
      </div>}
      <span className="Bar"/>
      <label className="Input_label" htmlFor={name}>{label}</label>
      {hasError && <div className="Input_errorLabel">{ errorMessage || INVALID_DATE}</div>}
    </div>
  );
};
