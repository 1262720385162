import React from "react";
import styled from "styled-components";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import InputRow from "../../InputRow/InputRow";
import InputField from "../../InputField/InputField";

const TabContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

interface CommonInfoTabProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  value: string | null;
}

const CommonInfoTab: React.FC<CommonInfoTabProps> = ({ handleChange, value }) => {
  return (
    <TabContainer>
      <InputRow alignItems="flex-end">
        <InputField
          type="text"
          handleChange={handleChange}
          value={value}
          label="Notes"
          id="Notes"
          fullWidth
          name="Notes"
          icon={<DescriptionOutlinedIcon />}
          multiline
          maxLength="1000"
        />
      </InputRow>
    </TabContainer>
  );
};

export default CommonInfoTab;
