import gql from "graphql-tag";

export const createProjectMutation = gql`
  mutation createProject($Project: ProjectInputType!, $Files: [Upload]) {
    ProjectPost(Project: $Project, Files: $Files) {
      AccountId
      Activities {
        Id
        Order
      }
      AssignedUserId
      Description
      Name
      ProjectStatusId
      DueDateTime
      CompletedDateTime
      Id
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        AbsoluteUri
        FileExtension
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
    }
  }
`;
