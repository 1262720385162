import * as React from "react";
import {DateInput} from "../../../../../../../components/DateInput";
import {Calendar} from "../../../../../../../components/Calendar";
import {useWindowSize} from "../../../../../../../_utils/useWindowSize.hook";
import {SCREEN_XS_MAX_SIZE} from "../../../../../../../_constant/constants";

interface InputFieldWithCalendarProps {
  value: string;
  onChange: (any) => void;
  handleBlur: (value) => void;
  hasFieldError: boolean;
  label: string;
  name: string;
  className: string;
  numberOfMonth: 1 | 2;
  minDate?: string;
}

const InputFieldWithCalendar: React.FC<InputFieldWithCalendarProps> = (props: InputFieldWithCalendarProps) => {
  const [windowSize] = useWindowSize();
  const isCropped = windowSize > SCREEN_XS_MAX_SIZE ? "is-cropped" : null;
  const nameOfClass = `${isCropped ? isCropped : ""} ${props.className ? props.className : ""}`;

  return (
    <>
      <div className={"Form_item"}>
        <i className="Icon Icon-event"/>
        <DateInput
          label={props.label}
          name={props.name}
          value={props.value}
          onBlur={props.handleBlur}
          className={nameOfClass}
          onClear={() => props.onChange(null)}
          errorMessage={"Required field"}
          hasError={props.hasFieldError}
          minDate={props.minDate ? props.minDate : null}
        />
      </div>
      <Calendar
        handleChange={(date) => props.onChange(date)}
        initValue={props.value}
        numberOfMonths={props.numberOfMonth}
        minDate={props.minDate ? props.minDate : null}
      />
    </>
  );
};

export default InputFieldWithCalendar;
