import styled from "styled-components";
import { Box, Card, CardContent, CardHeader, List, ListItem } from "@material-ui/core";
import { textClip } from "../_styled/textClip";

export const CardWrapper = styled(Box)`
  padding: var(--space-size--m);
`;

export const StyledCard = styled(Card)`
  position: relative;
  box-shadow: none;
  border: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  border-radius: 8px;
`;

export const StyledCardHeader = styled(CardHeader)`
  background-color: #f5fbff;
  .MuiCardHeader-content {
    display: flex;
    align-items: center;
    .MuiCardHeader-subheader {
      padding: 4px 10px;
      background-color: #d6efff;
      color: ${({theme}) => theme.palette.primary.main};
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 4px;
      margin-left: 16px;
    }
  }
  h3 {
    font-weight: 500;
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 16px;
`;

export const StyledList = styled(List)`
  padding: 0;
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;

  & > :first-child {
    min-width: 0;
    .MuiTypography-root {
      ${textClip};
    }
  }
  & > :last-child {
    text-align: center;
    width: 6rem;
    flex-shrink: 0;
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }

  .MuiTypography-root {
    font-size: 14px;
  }

  &:first-child {
    text-transform: uppercase;
    .MuiTypography-root {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .MuiBox-root {
    .MuiTypography-root {
      font-weight: 400;
    }
  }
`;

export const StyledCardFooter = styled.footer`
  padding: 14px 16px;
  border-top: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  .MuiTypography-root {
    font-size: 14px;
  }
  .MuiButton-root {
    padding: 8px 16px;
    margin: 0;
    box-shadow: none;
    ${({ theme }) => theme.breakpoints.down("xs")} {
      width: 100%;
      margin-top: 16px;
    }
  }
  .MuiGrid-root:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FeatureImg = styled.img``;
export const FeatureImgWrapper = styled.div`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e0f3ff;
`;
