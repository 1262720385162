import React, { useEffect, useState } from "react";

import { ActivityForWizardModel } from "../../../redux/models/data/ActivityForWizardModel";

import WizardActivityRecurringSelector from "../WizardActivityRecurringSelector/WizardActivityRecurringSelector";

import {
  EditButton,
  FormControlLabelStyle,
  LabelContainer,
  StyledCheckbox,
  StyledInput,
} from "./WizardActivityStyles";

import WizardActivityRow from "../WizardActivityRow/WizardActivityRow";
import { Label } from "@material-ui/icons";
import Input from "../../Input/Input";
import { Button, IconButton, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import FloatingInput from "../../FloatingInput/FloatingInput";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

interface IWizardActivityCard {
  activity: ActivityForWizardModel;
  handleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (id: string, property: string, value: any) => void;
  handleNameChange: (name: string, property: string, value: any) => void;
}

const WizardActivityCard: React.FC<IWizardActivityCard> = ({
  activity,
  handleSelect,
  handleChange,
  handleNameChange,
  handleCheckboxChange,
}) => {
  const { _checked, Name, Id, _editModeEnabled } = activity;

  const [inputValue, setInputValue] = useState(Name ?? "");

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const autoSelect = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.target.select();
  };

  return (
    <>
      <WizardActivityRow
        actionZoneComponent={
          <WizardActivityRecurringSelector handleChange={handleChange} activity={activity} />
        }
      >
        <FormControlLabelStyle>
          <StyledCheckbox
            name={Name}
            checked={_checked}
            onChange={(e) => {
              handleSelect(e);
            }}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
          {_editModeEnabled && isDesktop ? (
            <StyledInput
              value={inputValue}
              id={Id}
              onChange={({ target }) => setInputValue(target.value)}
              autoFocus
              onFocus={autoSelect}
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  e.target.blur();
                }
              }}
              disableUnderline
              onBlur={() => handleNameChange(Name, inputValue)}
              placeholder="Custom Activity"
              maxLength={50}
            />
          ) : (
            <LabelContainer>
              <Typography>{Name}</Typography>
              <EditButton
                onClick={(e) => {
                  e.preventDefault();
                  handleChange(Name as string, "_editModeEnabled", true);
                }}
                edge="end"
                size="small"
              >
                <CreateOutlinedIcon />
              </EditButton>
            </LabelContainer>
          )}
        </FormControlLabelStyle>
      </WizardActivityRow>
      <FloatingInput
        name={Name}
        isOpen={!!_editModeEnabled && !isDesktop}
        value={inputValue}
        onFocus={autoSelect}
        label="New Activity"
        placeholder="Custom Activity"
        onChange={handleNameChange}
        onClose={() => handleChange(Name as string, "_editModeEnabled", false)}
        maxLength={50}
      />
    </>
  );
};

export default WizardActivityCard;
