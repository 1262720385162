import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import Box from "@material-ui/core/Box";
import { CircularProgress, useTheme } from "@material-ui/core";
import { GET_PROJECT_ASSETS } from "./getProjectAssets.query";

import { AssetModel } from "../../redux/models/data/AssetModel";
import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import AssetCardRow from "../Cards/AssetCardRow";
import AssetCardInfo from "../AssetCardAdditionalInfo/AssetCardInfo";
import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";

interface ItemsTabProjectProps {
  projectId: string;
  showWarningMsg: boolean;
  renderWarningCard: () => void;
}

const ItemsTabProject: React.FC<ItemsTabProjectProps> = (props) => {
  const { projectId, showWarningMsg, renderWarningCard } = props;
  const [loading, setLoading] = useState<boolean>(true);

  const theme = useTheme();

  const [getAssets, { loading: assetLoading, error: assetError, data: assetData }] = useLazyQuery(
    GET_PROJECT_ASSETS,
    {
      variables: { ProjectId: projectId },
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    getAssets();
  }, [showWarningMsg]);

  const assetList = assetData?.Project?.Assets;

  useEffect(() => {
    !assetLoading && assetData && setLoading(false);
  }, [assetLoading, assetData]);

  if (loading) {
    return (
      <div style={{ height: "100%", flexGrow: 1, padding: "1rem", textAlign: "center" }}>
        <CircularProgress size={22} />
      </div>
    );
  }

  return (
    <>
      <Box>
        {assetList?.length ? (
          <Box padding="1rem" paddingTop="">
            {showWarningMsg && renderWarningCard()}
            <Box
              overflow="hidden"
              borderRadius="8px"
              border={`1px solid ${theme.palette.greyDesign.onSurface3}`}
            >
              {assetList.map((item: AssetModel) => {
                const convertedAsset: AssetRefsModel = new AssetRefsModel({
                  ...item,
                  AssetTypeRef: item.AssetType,
                  MainImage: item.Images && item.Images.length ? item.Images[0] : null,
                  HasActivities: item.AssetActivities.length > 0,
                });

                return (
                  <AssetCardRow
                    item={convertedAsset}
                    key={item.Id}
                    additionalInfo={
                      <AssetCardInfo item={convertedAsset} assetActivities={item.AssetActivities} />
                    }
                    hideMoreAction
                  />
                );
              })}
            </Box>
          </Box>
        ) : (
          <>
            <Box padding="1rem" paddingTop="">
              {showWarningMsg && renderWarningCard()}
              <EmptyCustomFieldState emptyFieldsMessage="There are no items" />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ItemsTabProject;
