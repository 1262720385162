import gql from "graphql-tag";

export const GET_ASSET_REFS = gql`
  query getAssetRefs($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetRefs {
        AssetTypeRef {
          Id
          Name @client
          IconName @client
        }
        Id
        Name
        Description
        DefaultDescription
        Tags
        MainImage {
          Id
          Name
          FileExtension
          Description
          ContainerId
          ContainerName
          TotalBytes
        }
        HasActivities
      }
    }
  }
`;

export const getDetailedAssetInfo = gql`
  query getDetailedAssetInfo($Id: ID) {
    Account(Id: $Id) {
      Id
      Assets {
        Id
        Name
        Description
        DefaultDescription
        AssetType {
          Id
          Name
          IconName
        }
        CustomFields
        Schema {
          Id
          FieldType
          Name
          Order
        }
        TypeFieldValues
        AssetActivities {
          Id
        }
        Documents {
          Id
        }
        Videos {
          Id
        }
        Images {
          Id
          Name
          ContainerId
          ContainerName
          AbsoluteUri
          FileExtension
        }
        Tags
      }
    }
  }
`;
