import React from "react";
import styled from "styled-components";

import { Checkbox, IconButton } from "@material-ui/core";
import Input from "../../Input/Input";

export const StyledCheckbox = styled(Checkbox)`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

export const FormControlLabelStyle = styled.label`
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;

  .MuiTypography-root {
    cursor: pointer;
  }
`;

export const EditButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: ${({ theme }) => theme.spacing(1)}px;

  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
  }
`;

export const StyledInput = styled(Input)`
  .MuiInputBase-root {
    font-size: 1rem;
    min-height: 0;
  }
`;
