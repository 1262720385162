import React, { useEffect, useState } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { conformToMask } from "react-text-mask";

import { useChange } from "../../customHooks/useChange";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { Image } from "../../redux/models/data/ImageModel";
import { VendorModel } from "../../redux/models/data/VendorModel";

import { PHONE_INPUT_MASK } from "../../_utils/regex";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { displayWarningNotification } from "../../redux/actions/_utils";
import { validateEmailField, validateWebsiteField } from "../../_utils/utils";

import VendorFormPageLayout from "./VendorFormPageLayout";
import { VendorFormContextProvider } from "./VendorFormContext";
import DropZoneContainer from "../DropZoneContainer/DropZoneContainer";

interface VendorFormContainerProps {
  handleSubmit: (data: VendorModel, saveType: SaveType) => void;
  isSaving: boolean;
  isFetching?: boolean;
  isNew: boolean;
  data: VendorModel;
  setHeaderOptions: (props: any) => void;
}

const VendorFormContainer: React.FC<VendorFormContainerProps> = ({
  handleSubmit: submitFunction,
  isSaving,
  isFetching,
  isNew,
  data: propsData,
  setHeaderOptions,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const limits = useGetLimits();

  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [websiteError, setwebsiteError] = useState("");

  const isLoading = isSaving || !!isFetching;

  const checkEmailField = (fieldName: string, value: string, errorMap: Map<string, string>) => {
    if (value.length > 0) {
      return validateEmailField(fieldName, value, errorMap);
    } else {
      if (errorMap.has(fieldName)) {
        errorMap.delete(fieldName);
      }
      return [errorMap, false];
    }
  };

  const checkPhoneField = (fieldName: string, value: string, errorMap: Map<string, string>) => {
    const newMap = new Map(errorMap);
    const onlyDigitFromPhone =
      value && value.length ? Number(value.replace(/\D+/g, "")).toString() : "";

    if (onlyDigitFromPhone.length > 0 && onlyDigitFromPhone.length !== 10) {
      newMap.set(fieldName, "Phone number must contain 10 digits");
      return [newMap, true];
    } else {
      newMap.delete(fieldName);
      return [newMap, newMap.size === 0];
    }
  };

  const checkWebsiteField = (fieldName: string, value: string, errorMap: Map<string, string>) => {
    if (value.length > 0) {
      return validateWebsiteField(fieldName, value, errorMap);
    } else {
      if (errorMap.has(fieldName)) {
        errorMap.delete(fieldName);
      }
      return [errorMap, false];
    }
  };

  const formInitObject = {
    initData: propsData ? propsData : new VendorModel(),
    classModel: VendorModel,
    requiredFields: ["Name"],
    fieldsForValidation: new Map([
      ["Name", null],
      ["Email", checkEmailField],
      ["Phone", checkPhoneField],
      ["Website", checkWebsiteField],
    ]),
  };
  const formApi = useChange<VendorModel>(formInitObject);
  const data = formApi.data;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;

    if (!formApi.data[id].length) {
      formApi.handleChange(null, { id, value: "" });
      return;
    }

    formApi.validateFields();
    switch (id) {
      case "Email":
        setEmailError(formApi.errorFields.get(id) || "");
        break;
      case "Phone":
        setPhoneError(formApi.errorFields.get(id) || "");
        break;
      case "Website":
        setwebsiteError(formApi.errorFields.get(id) || "");
        break;
    }
  };

  const handleChangeWithValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    switch (id) {
      case "Email":
        setEmailError("");
        break;
      case "Phone":
        setPhoneError("");
        break;
      case "Website":
        setwebsiteError("");
        break;
    }
    formApi.handleChange(e);
  };

  const lockSaveButton = formApi.isDataChanged && formApi.isDataValid;

  useEffect(() => {
    if (propsData && !isLoading) {
      const results =
        propsData.Phone && propsData.Phone.length
          ? conformToMask(propsData.Phone, PHONE_INPUT_MASK).conformedValue
          : "";
      formApi.updateInitFormData(new VendorModel({ ...propsData, Phone: results }));
    }
  }, [propsData]);

  const handleSubmit = (e?: React.SyntheticEvent<Element, Event> | null, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: SaveType = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: () => formApi.updateInitFormData(formApi.data),
    };

    submitFunction(formApi.data, saveType);
  };

  const dropZone = React.useMemo(
    () => (
      <DropZoneContainer
        data={data}
        handleChange={formApi.handleChange}
        updateManyFields={formApi.updateManyFields}
        entityName="vendor"
        isMobile={!isDesktop}
        imagesOnly={false}
        limitDocuments={limits?.asset.documents.Value}
        limitImages={limits?.asset.images.Value}
        limitVideos={limits?.asset.videos.Value}
      />
    ),
    [formApi.data.Files, formApi.data.Images, formApi.data.Documents, formApi.data.Videos],
  );

  return (
    <VendorFormContextProvider
      isNew={isNew}
      isLoading={isLoading}
      isFetching={!!isFetching}
      isSaving={isSaving}
      data={propsData}
      setHeaderOptions={setHeaderOptions}
      handleSubmit={handleSubmit}
      formApi={formApi}
      formData={data}
      lockSaveButton={lockSaveButton}
      handleBlur={handleBlur}
      handleChangeWithValidation={handleChangeWithValidation}
      phoneError={phoneError}
      emailError={emailError}
      websiteError={websiteError}
      dropZone={dropZone}
    >
      <VendorFormPageLayout />
    </VendorFormContextProvider>
  );
};

export default VendorFormContainer;
