import * as React from "react";
import { ReactElement } from "react";
import { Grid } from "@material-ui/core";

interface InputRowProps {
  alignItems?: "center" | "flex-end" | "flex-start" | "stretch";
  actionZoneComponent?: () => ReactElement;
}

const InputRow: React.FC<InputRowProps> = ({
  actionZoneComponent,
  children,
  alignItems = "center",
}) => (
  <div className="Section_row  Section_row__doubleMargin">
    <Grid container spacing={2} alignItems={alignItems}>
      <Grid item xs>
        {children}
      </Grid>
      {actionZoneComponent && <Grid item>{actionZoneComponent()}</Grid>}
    </Grid>
  </div>
);

export default InputRow;
