import * as React from "react";
import { useState } from "react";
import moment from "moment";
import { ActivityScheduleModel } from "../../../../../../../redux/models/data/ActivityScheduleModel";
import { DateInput } from "../../../../../../../components/DateInput";
import Input from "../../../../../../../components/Input";
import { FullActivityDueDate } from "../../../../../../../redux/models/data/ActivityModelFull";
import { getValidDateValue } from "./validation";
import { DIGITS_ONLY } from "../../../../../../../_utils/regex";
import { EndAfter } from "../../../../../../../_constant/constants";
import { useContext } from "react";
import { DateContext } from "../../DueDateComponent";
import NestedCalendarDialog from "../NestedCalenderDialog";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import RadioButton from "../../../../../../../components/RadioButton";

interface RecurringEndFormProps {
  errorFields: Set<string>;
  onChange: (value: FullActivityDueDate) => void;
}

const validateField = (name, value) => {
  if (name === "AppearanceCount") {
    return DIGITS_ONLY.test(value) ? parseInt(value, 10) : "";
  }
  return getValidDateValue(value);
};

export default function RecurringEndForm(props: RecurringEndFormProps) {
  const { schedule, endRecurring } = useContext(DateContext);
  const [radioValue, setRadioValue] = useState(endRecurring);
  const [endDateCalendarVisible, setEndDateCalendarVisible] = useState(false);

  const handleRadioSwitch = (nextValue) => {
    setRadioValue(nextValue.value);
    props.onChange({ schedule, endRecurring: nextValue.value });
  };

  const handleChange = (relatedRadio, name?, value?) => {
    setRadioValue(relatedRadio.value);

    const nextSchedule = new ActivityScheduleModel(schedule);
    if (!!name) {
      nextSchedule[name] = validateField(name, value);
    }
    if (relatedRadio.value !== EndAfter.END_DATE) {
      nextSchedule.EndDate = null;
    }
    if (relatedRadio.value !== EndAfter.REPEAT_COUNT) {
      nextSchedule.AppearanceCount = null;
    }
    props.onChange({ schedule: nextSchedule, endRecurring: relatedRadio.value });
  };

  return (
    <>
      <RadioGroup
        aria-label="Repeat after"
        name="repeatAfter"
        value={radioValue}
        onChange={(e) => handleRadioSwitch({ value: e.target.value })}
        className="RadioGroup"
      >
        <FormControlLabel
          value={EndAfter.NO_LIMIT}
          control={<RadioButton onChange={handleChange} />}
          label="No End Date"
        />
        <FormControlLabel
          value={EndAfter.END_DATE}
          control={<RadioButton />}
          label={
            <>
              End date
              <DateInput
                name="EndDate"
                className={"EndDate"}
                value={schedule.EndDate}
                onBlur={({ target }) =>
                  handleChange({ value: EndAfter.END_DATE }, "EndDate", target.value)
                }
                onClear={() => handleChange({ value: EndAfter.END_DATE }, "EndDate", null)}
                errorMessage={"Required field"}
                hasError={props.errorFields.has("EndDate")}
                minDate={moment().format("YYYY/MM/DD")}
                showCalendar={() => setEndDateCalendarVisible(true)}
              />
            </>
          }
        />
        <FormControlLabel
          value={EndAfter.REPEAT_COUNT}
          control={<RadioButton />}
          label={
            <>
              Repeat
              <Input
                className={"RepeatCount"}
                name={"AppearanceCount"}
                value={
                  !!schedule.AppearanceCount || typeof schedule.AppearanceCount === "number"
                    ? schedule.AppearanceCount
                    : ""
                }
                onChange={({ target }) =>
                  handleChange({ value: EndAfter.REPEAT_COUNT }, target.id, target.value)
                }
                errorMessage={"Required field"}
                hasError={props.errorFields.has("AppearanceCount")}
              />
              times
            </>
          }
        />
      </RadioGroup>
      {endDateCalendarVisible && (
        <NestedCalendarDialog
          value={schedule.EndDate}
          onChange={(date) => handleChange({ value: EndAfter.END_DATE }, "EndDate", date)}
          handleBlur={({ target }) =>
            handleChange({ value: EndAfter.END_DATE }, "EndDate", target.value)
          }
          hasDueDateError={props.errorFields.has("EndDate")}
          label="End Date"
          name="EndDate"
          handleClose={() => setEndDateCalendarVisible(false)}
          dialogTitle={"Recurring End Date"}
          minDate={moment().add(1, "days").format("YYYY/MM/DD")}
        />
      )}
    </>
  );
}
