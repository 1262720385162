import React from "react";
import "./InvitationCard.scss";
import { Box, Paper, Typography } from "@material-ui/core";
import { RemoveButton } from "./RemoveButton";
import { getInvitationCardDate } from "../../_utils/date";
import { useMutation } from "react-apollo";
import { cancelInvitationQuery } from "./cancelInvitation.query";
import { InvitationStateOptions } from "../../_constant/constants";

type InvitationStatus = "ACTIVE" | "INVITED" | "DECLINED" | "CANCELLED";

interface Props {
  email: string;
  date: string;
  status: InvitationStatus;
  invitationId: string;
  isCancelled: boolean;
}

const STATUS_TEXT_COLORS = {
  [InvitationStateOptions.active]: "var(--color-in-progress)",
  [InvitationStateOptions.ignored]: "var(--color-darker)",
  [InvitationStateOptions.cancelled]: "var(--color-error)",
  [InvitationStateOptions.declined]: "var(--color-error)",
  [InvitationStateOptions.accepted]: "var(--color-success)",
};

const STATUS_WORDINGS = {
  [InvitationStateOptions.active]: "Invited",
  [InvitationStateOptions.ignored]: "Ignored",
  [InvitationStateOptions.cancelled]: "Canceled",
  [InvitationStateOptions.declined]: "Declined",
  [InvitationStateOptions.accepted]: "Accepted",
};

export const InvitationCard = ({ status, email, date, invitationId, isCancelled }: Props) => {
  const [removeInvitation, { loading: removeLoading }] = useMutation(cancelInvitationQuery);

  const handleRemoveInvitation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (isCancelled) return;
    removeInvitation({ variables: { InvitationId: invitationId } }).then(() => {});
  };

  return (
    <Box className="invitation-card" display="flex" alignItems="center">
      <Paper className="invitation-card__container" elevation={2}>
        <Box className="invitation-card__wrapper" display="flex" alignItems="center">
          <Typography component="span" className="invitation-card__email">
            {email}
          </Typography>

          <Box display={{ xs: "none", md: "flex" }} alignItems="center">
            <Typography component="span" className="invitation-card__date">
              {getInvitationCardDate(date)}
            </Typography>
          </Box>

          <Box style={{ marginLeft: "auto" }}>
            <Typography
              className="invitation-card__status"
              style={{ color: STATUS_TEXT_COLORS[status] }}
            >
              {STATUS_WORDINGS[status]}
            </Typography>
          </Box>

          <Box className="invitation-card__action">
            <RemoveButton
              onClick={handleRemoveInvitation}
              disabled={isCancelled}
              loading={removeLoading}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
