import * as React from "react";
import {useEffect, useState} from "react";
import {DateInput} from "../../../../../components/DateInput";
import {Calendar} from "../../../../../components/Calendar";
import moment from "moment";
import {useWindowSize} from "../../../../../_utils/useWindowSize.hook";
import {ActivitiesFieldName, SCREEN_XS_MAX_SIZE} from "../../../../../_constant/constants";

interface CompletedDateFormProps {
  value: string;
  onChange: (value) => void;
  setSubmitDisabled: (flag: boolean) => void;
}

export default function CompletedDateForm(props: CompletedDateFormProps) {
  const [value, setValue] = useState(props.value);
  const [windowSize] = useWindowSize();

  useEffect(() => { //component receive new props
    setValue(props.value);
  }, []);

  const handleBlur = ({target}) => {
    // validation
    setValue(target.value);
    props.onChange(target.value);
  };

  const handleDatePick = (date) => {
    setValue(date);
    props.onChange(date);
  };

  const handleClear = () => {
    setValue(null);
    props.setSubmitDisabled(true);
  };

  return (
    <>
      <div className={"Form_item"}>
        <i className="Icon Icon-event_available"/>
        <DateInput
          label="Completed Date"
          name={ActivitiesFieldName.COMPLETED_DATE_TIME}
          value={value}
          onBlur={handleBlur}
          className={windowSize > SCREEN_XS_MAX_SIZE ? "is-cropped" : null}
          onClear={handleClear}
          maxDate={moment().format("YYYY/MM/DD")}
        />
      </div>
      <Calendar
        handleChange={(date) => handleDatePick(date)}
        initValue={value}
        numberOfMonths={2}
        blockDatesAfterToday
        showCurrentMonthRight={windowSize > SCREEN_XS_MAX_SIZE}
      />
    </>
  );
}
