import React, { ReactElement } from "react";

import {
  WizardItemCardContainer,
  WizardItemCardIcon,
  WizardItemCardText,
  WizardItemCardActionIcon,
  TextInCardLoading,
  ActionInCardLoading,
} from "./WizardCardStyles";

import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

export interface WizardItemCardProps {
  variant: "card" | "row";
  isSelected?: boolean;
  iconName?: string;
  button?: ReactElement;
  isLoading?: boolean;
  withSelectIndicator?: boolean;
}

const WizardCard = (props: any) => {
  const {
    variant,
    isSelected,
    width,
    iconName,
    children,
    button,
    isLoading,
    withSelectIndicator = false,
  } = props;

  const renderIcon = () => {
    if (iconName) {
      if (isLoading) {
        return <ActionInCardLoading animation="wave" variant="circle" withIcon />;
      } else {
        return (
          <WizardItemCardIcon>
            <i className="Icon">{iconName}</i>
          </WizardItemCardIcon>
        );
      }
    }
  };

  const renderAction = () => {
    if (isLoading) {
      return <ActionInCardLoading animation="wave" variant="circle" withIcon={false} />;
    }

    if (button) {
      return button;
    }

    if (isSelected) {
      return <RadioButtonCheckedIcon />;
    } else {
      return <RadioButtonUncheckedIcon />;
    }
  };

  return (
    <WizardItemCardContainer {...props}>
      {renderIcon()}
      <WizardItemCardText component={typeof children === "string" ? "p" : "div"}>
        {isLoading ? (
          <TextInCardLoading animation="wave" variant="text" row={variant === "row"} />
        ) : (
          children
        )}
      </WizardItemCardText>
      {variant === "row" && withSelectIndicator && (
        <WizardItemCardActionIcon>{renderAction()}</WizardItemCardActionIcon>
      )}
    </WizardItemCardContainer>
  );
};

export default WizardCard;
