import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useTheme } from "@material-ui/core/styles";

import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";

import { getWizardDataQuery } from "../WizardContainer/getWizardData.query";
import { updateItemFunctionType } from "./types";

import WizardInputInCard from "../WizardInputInCard/WizardInputInCard";

import { WizardItemCard } from "./WizardItemStyles";
import {
  WizardItemTypeContainer,
  WizardItemTypeHeader,
  WizardItemTypeList,
} from "../WizardItemTypeScreen/WizardItemTypeStyles";
import { AssetType } from "../../../redux/models/data/AssetTypeModel";

interface WizardItemScreenProps {
  newItem: AssetForWizardModel;
  updateItem: (item: AssetForWizardModel) => void;
  typesMap: Map<string, AssetForWizardModel[]>;
  updateTypesMap: (typesMap: Map<string, []>) => void;
}

const WizardItemScreen: React.FC<WizardItemScreenProps> = ({
  newItem,
  updateItem,
  updateTypesMap,
  typesMap,
}) => {
  const { data, loading, error } = useQuery(getWizardDataQuery);

  const theme = useTheme();

  useEffect(() => {
    if (!loading && !error && data) {
      const newtypesMap = new Map<string, any>();
      data["WizardData"].forEach((item: any) => {
        const { Activities, AssetTypeId, ...newItem } = item;
        const typeId = item.AssetTypeId;
        const generatedAsset = new AssetForWizardModel({
          ...newItem,
          _AssetActivities: item.Activities,
          Id: "",
        } as AssetForWizardModel);

        if (!newtypesMap.get(typeId)) {
          newtypesMap.set(typeId, [generatedAsset]);
        } else {
          const oldList = newtypesMap.get(typeId);
          newtypesMap.delete(typeId);
          newtypesMap.set(typeId, [...oldList, generatedAsset]);
        }
      });

      updateTypesMap(newtypesMap);
    }
  }, [data]);

  const changeItem: updateItemFunctionType = ({ newEntity, currentItem }) => {
    const itemType: AssetType = currentItem ? currentItem.AssetType : newItem.AssetType;

    // case when user type existing item name, need to add activities for this item
    if (!newEntity._isNameCustom && !newEntity._AssetActivities?.length) {
      const item = typesMap
        .get(newItem.AssetType.Id)
        ?.find((item: AssetForWizardModel) => item.Name === newEntity.Name);
      newEntity._AssetActivities = item ? item._AssetActivities : [];
    }

    const itemCopy = new AssetForWizardModel({ ...newEntity, AssetType: itemType });
    updateItem(itemCopy);
  };

  const typeId: string = newItem && newItem.AssetType ? newItem.AssetType?.Id : "";
  const itemsList = typesMap.has(typeId) ? typesMap.get(typeId)! : ([] as AssetForWizardModel[]);

  useEffect(() => {
    if (itemsList.length && !newItem.Name) {
      changeItem({ newEntity: itemsList[0], currentItem: newItem });
    }
  }, [itemsList]);

  const handleClick = (newEntity: AssetForWizardModel) => {
    if (newItem.Name !== newEntity.Name) {
      newEntity._isNameCustom = false;
      changeItem({ newEntity: newEntity, currentItem: newItem });
    }
  };

  const loadingList = Array.from(Array(4).keys()).map((item) => (
    <WizardItemCard key={item} variant="row" isLoading withSelectIndicator />
  ));
  // const loading_mock = true;
  return (
    <WizardItemTypeContainer>
      <WizardItemTypeHeader>
        Select the category that best fits the item you want to maintain:
      </WizardItemTypeHeader>
      <WizardItemTypeList>
        {loading ? loadingList : null}
        {itemsList.map((asset: AssetForWizardModel) => (
          <WizardItemCard
            key={asset.Name}
            id={asset.Id}
            isSelected={asset.Name === newItem.Name}
            onClick={() => handleClick(asset)}
            variant="row"
            withSelectIndicator
          >
            {asset.Name}
          </WizardItemCard>
        ))}
        {!loading && (
          <WizardInputInCard updateItem={changeItem} newItem={newItem} itemsList={itemsList} />
        )}
      </WizardItemTypeList>
    </WizardItemTypeContainer>
  );
};

export default WizardItemScreen;
