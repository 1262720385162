import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Section from "../Section/Section";

interface Props {
  count: number;
}

export const InvitationListSkeleton: React.FC<Props> = ({ count }) => {
  return (
    <Section>
      {new Array(count).fill(null).map((_, index) => (
        <Skeleton
          key={index}
          variant="rect"
          width="100%"
          height={46}
          style={{ marginBottom: 8, borderRadius: 6 }}
        />
      ))}
    </Section>
  );
};
