import gql from "graphql-tag";

export const createCalendarFeedConfig = gql`
  mutation createCalendarConfig($AccountId: ID, $UserId: ID, $AssignType: AssignType) {
    CalendarConfigurationPost(
      CalendarConfiguration: { AccountId: $AccountId, UserId: $UserId, AssignType: $AssignType }
    ) {
      Id
      UserId
      AccountId
      AssignType
      AbsoluteUri
    }
  }
`;

export const updateCalendarFeedConfig = gql`
  mutation updateCalendarConfig($AccountId: ID, $UserId: ID, $AssignType: AssignType, $Id: ID) {
    CalendarConfigurationPut(
      CalendarConfiguration: {
        AccountId: $AccountId
        UserId: $UserId
        AssignType: $AssignType
        Id: $Id
      }
    ) {
      Id
      UserId
      AccountId
      AssignType
      AbsoluteUri
    }
  }
`;
