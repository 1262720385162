import React, { useEffect, useState } from "react";
import WizardSearchScreen from "../WizardSearchScreen/WizardSearchScreen";
import WizardItemTypeScreen from "../WizardItemTypeScreen/WizardItemTypeScreen";
import { ItemTypeScreen } from "../WizardContainer/WizardContainer";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import { useQuery } from "react-apollo";
import { GET_DETAILED_ITEM_TYPE_LIST } from "../../../containers/_common/filter/asset-type.query";
import { ACCOUNT, ITEM_TYPES } from "../../../_constant/wordings";
import { AssetTypeModel } from "../../../redux/models/data/AssetTypeModel";
import { AssetForWizardModel } from "../../../redux/models/data/AssetForWizardModel";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { WizardItemTypeCard } from "../WizardItemTypeScreen/WizardItemTypeStyles";

interface Props {
  itemTypeScreen: ItemTypeScreen;
  setItemTypeScreen: (type: ItemTypeScreen) => void;
  newItem: AssetForWizardModel;
  updateItem: (asset: AssetForWizardModel) => void;
  switchDisable: (value: boolean) => void;
}

const WizardItemTypeContainer: React.FC<Props> = ({
  itemTypeScreen,
  setItemTypeScreen,
  newItem,
  updateItem,
  switchDisable,
  ...rest
}) => {
  const [filteredItemTypes, setFilteredItemTypes] = useState<AssetTypeModel[]>([]);
  const [filteredMap, setFilteredMap] = useState(new Map());

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const { accountId } = useGetAccountId();
  const { data: itemTypesResponse, loading: itemTypesLoading, error: itemTypeError } = useQuery(
    GET_DETAILED_ITEM_TYPE_LIST,
    {
      variables: {
        Id: accountId,
      },
      skip: !accountId,
    },
  );

  const setSearchScreen = () => {
    setItemTypeScreen("search");
  };
  const setSelectScreen = () => {
    setItemTypeScreen("select");
  };

  useEffect(() => {
    if (!itemTypesLoading && !itemTypeError && itemTypesResponse) {
      const brand = process.env.REACT_APP_BRAND;

      let filteredData = itemTypesResponse[ACCOUNT][ITEM_TYPES].filter(
        (assetType: AssetTypeModel) =>
          assetType.IsSystem &&
          (brand
            ? assetType.Brands.toLowerCase() === brand.toLowerCase()
            : assetType.Brands === "Upkeepr"),
      );
      filteredData = filteredData ? filteredData.sort((a, b) => a.SortOrder - b.SortOrder) : [];
      setFilteredItemTypes(filteredData); // filteredData should be a map with property id and value whole type object
      const preparedData = filteredData.map((itemType: AssetTypeModel) => [
        itemType.Id,
        new AssetTypeModel(itemType),
      ]);
      const finallyTypesMap: Map<string, AssetTypeModel> = new Map(preparedData);
      setFilteredMap(finallyTypesMap);
      const typeId = filteredData.length ? filteredData[0].Id : "";
      const AssetType = finallyTypesMap.get(typeId) || newItem.AssetType;

      switchDisable(false);

      if (!newItem.AssetType.Name) {
        updateItem(new AssetForWizardModel({ ...newItem, AssetType }));
      }
    }
  }, [itemTypesResponse]);

  const handleClick = (id: string) => {
    if (newItem.AssetType?.Id !== id) {
      const AssetType = filteredMap.get(id);
      updateItem(new AssetForWizardModel({ ...newItem, AssetType }));
    }
  };

  const loadingList = Array.from(Array(isDesktop ? 10 : 4).keys()).map((item) => (
    <WizardItemTypeCard
      key={item}
      variant={isDesktop ? "card" : "row"}
      isLoading
      iconName={isDesktop ? "icon" : undefined}
    />
  ));

  if (itemTypeScreen === "search") {
    return (
      <WizardSearchScreen
        {...rest}
        itemTypes={filteredItemTypes}
        handleChangeView={setSelectScreen}
        handleClick={handleClick}
        newItem={newItem}
        itemsMap={filteredMap}
        updateItem={updateItem}
      />
    );
  }

  if (itemTypeScreen == "select") {
    return (
      <WizardItemTypeScreen
        {...rest}
        itemTypes={filteredItemTypes}
        handleChangeView={setSearchScreen}
        handleClick={handleClick}
        newItem={newItem}
      />
    );
  }

  return null;
};

export default WizardItemTypeContainer;
