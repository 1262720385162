import React from "react";
import styled from "styled-components";

import EventIcon from "@material-ui/icons/Event";

import { Grid } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";

const StyledIconButton = styled(IconButton)`
  margin-left: -3px;

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const StyledGridInput = styled(Grid)`
  &.MuiGrid-root {
    padding-left: 9px;
  }
`;

interface DatePickerInputProps {
  value: string;
  handleChange: (value: any) => void;
  name: string;
  format?: string;
}

const DatePickerInput: React.FC<DatePickerInputProps> = (props) => {
  const { value, handleChange, name, format = "YYYY/MM/DD" } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Grid spacing={1} container alignItems="flex-end" className="InputField">
      <Grid item>
        <StyledIconButton onClick={() => setOpen(!open)} color="primary" size="small">
          <EventIcon fontSize="small" />
        </StyledIconButton>
      </Grid>
      <StyledGridInput item xs>
        <DatePicker
          label={name}
          value={value || null}
          onChange={(value) => handleChange(value)}
          format={format}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          clearable={true}
          fullWidth
        />
      </StyledGridInput>
    </Grid>
  );
};

export default DatePickerInput;
