export const ID = "Id";
export const ACCOUNT = "Account";
export const USER = "User";
export const ITEMS = "Assets";
export const ITEM = "Asset";
export const ITEM_ID = "AssetId";
export const ITEM_ACTIVITIES = "AssetActivities";
export const STARTED_VISIT_ACTIVITIES = "StartedVisitActivities";
export const FINISHED_VISIT_ACTIVITIES = "FinishedVisitActivities";
export const ASSET_TYPE_ICON = "AssetTypeIcon";
export const ITEM_PUT = "AssetPut";
export const ITEM_POST = "AssetPost";
export const ACTIVITY_PATCH = "AssetActivityPatch";
export const ACTIVITY_POST = "AssetActivityPost";
export const ACTIVITY_PUT = "AssetActivityPut";
export const ITEM_TYPES = "AssetTypes";
export const ASSET_TYPE = "AssetType";
export const ASSET_TYPE_REF = "AssetTypeRef";
export const ASSET_REFS = "AssetRefs";
export const PARENT_ID = "ParentId";
export const COMPONENT_IDS = "ComponentIds";

export const USER_PUT = "UserPut";
export const USER_REFS = "UserRefs";
export const REMOVE_USER = "RemoveUser";
export const IS_PREVIEW = "IsPreview";

export const NAME = "Name";
export const DESCRIPTION = "Description";
export const ACITVITY_NAME = "Activity name";
export const ITEM_NAME = "Item name";
export const ACCOUNT_NAME = "Account name";

export const PERSONAL = "Personal";

export const ACCOUNT_POST = "AccountPost";
export const ACCOUNT_PUT = "AccountPut";
export const ACCOUNTS = "Accounts";
export const ACCOUNT_REFS = "AccountRefs";

export const ACCEPT_INVITE = "AcceptInvite";
export const DECLINE_INVITE = "DeclineInvite";
export const ACTIVATE_INVITE = "ActivateInvite";

export const CURRENT_ACCOUNT = "currentAccount";

export const INCORRECT_VALUE_MESSAGE = "Incorrect character used";
export const getIncorrectCharactersNumberMessage = ({ min, max }: { min: number; max: number }) =>
  `Field must be longer than ${min} and less than ${max} characters`;
export const REQUIRED_VALUE_MESSAGE = "Required field";

export const CUSTOMER = "Customer";
export const CUSTOMER_POST = "CustomerPost";
export const CUSTOMER_PUT = "CustomerPut";
export const CUSTOMERS = "Customers";

export const VISITS = "Visits";
export const VISIT = "Visit";
export const VISIT_POST = "VisitPost";
export const VISIT_PUT = "VisitPut";
export const VISIT_PATCH = "VisitPatch";

export const VENDORS = "Vendors";
export const VENDOR = "Vendor";
export const VENDOR_POST = "VendorPost";
export const VENDOR_PUT = "VendorPut";
export const VENDOR_PATCH = "VendorPatch";

export const PROJECTS = "Projects";
export const PROJECT = "Project";
export const PROJECT_POST = "ProjectPost";
export const PROJECT_PUT = "ProjectPut";
export const PROJECT_PATCH = "ProjectPatch";

// static data img naming
export const ASSETS_EMPTY = "assets";
export const ACTIVITIES_EMPTY = "activities";

// new one
export const MY_INVITES = "InvitedByMe";

export const FILE_UPLOAD_RESULTS = "FileUploadResults";

export const VIN = "VIN/WMI";

export const SYSTEM_DATA = "SystemData";

export const ACTIVITY_STATUSES = "AssetActivityStatuses";

export const PROJECTS_STATUSES = "ProjectStatuses";
