import { GET_RELATED_ACTIVITIES_BY_STATUS } from "./activities-list.query";
import { ACCOUNT, ITEM_ACTIVITIES, ITEMS, CUSTOMER } from "../../../../_constant/wordings";
import { ActivityModel } from "../../../../redux/models/data/ActivityModelFull";
import gql from "graphql-tag";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import ApolloClient from "apollo-client";

interface IQueryFromCache {
  Account: {
    Id: string;
    __typename: "Account";
    Assets: [
      {
        Id: string;
        __typename: "Asset";
        AssetActivities: any[];
      },
    ];
  };
}

export const createRelatedActivitiesQueryObject = (
  queryFromCache: IQueryFromCache,
  newActivitiesList: any,
) => {
  return {
    Account: {
      Id: queryFromCache.Account.Id,
      __typename: queryFromCache.Account.__typename, // "Account"
      Assets: [
        {
          Id: queryFromCache.Account.Assets[0].Id,
          __typename: queryFromCache.Account.Assets[0].__typename, // "Asset"
          AssetActivities: newActivitiesList,
        },
      ],
    },
  };
};

export const createQueryOptions = (accountId: string, assetId: string, filter: string) => ({
  query: GET_RELATED_ACTIVITIES_BY_STATUS,
  variables: {
    AccountId: accountId,
    AssetId: assetId,
    Filter: `StatusId=="${filter}"`,
  },
});

export const getActivitiesFromRelatedActivities = (queryObject: IQueryFromCache) => {
  return queryObject[ACCOUNT][ITEMS][0][ITEM_ACTIVITIES];
};

export const getCustomerActivities = (queryObject: any) => {
  return queryObject?.[CUSTOMER]?.[ITEM_ACTIVITIES] ?? [];
};

export const RelatedActivitiesCacheUpdate = (
  cache,
  activity,
  responseActivity,
  accountId,
  nextStatus,
  CompletedDateTime,
  withLoader = true,
) => {
  try {
    const optionsFromStatus = createQueryOptions(accountId, activity.AssetId, activity.StatusId);

    const activitiesFromStatus = cache.readQuery(optionsFromStatus);
    let activitiesFromStatusList = getActivitiesFromRelatedActivities(activitiesFromStatus);
    activitiesFromStatusList = activitiesFromStatusList.filter((element) => {
      return element.Id !== responseActivity.Id;
    });

    cache.writeQuery({
      ...optionsFromStatus,
      data: createRelatedActivitiesQueryObject(activitiesFromStatus, activitiesFromStatusList),
    });

    const optionsToStatus = createQueryOptions(
      accountId,
      activity.AssetId,
      responseActivity.StatusId,
    );
    const activitiesToStatus = cache.readQuery(optionsToStatus);

    const updatedActivitiesToStatus = new ActivityModel({
      ...activity,
      ...responseActivity,
      isLoading: withLoader,
    });
    updatedActivitiesToStatus["__typename"] = "AssetActivities";
    updatedActivitiesToStatus.Schedule["__typename"] = "ScheduleType";

    let activitiesToStatusList = getActivitiesFromRelatedActivities(activitiesToStatus);
    activitiesToStatusList = activitiesToStatusList.concat([updatedActivitiesToStatus]);

    cache.writeQuery({
      ...optionsToStatus,
      data: createRelatedActivitiesQueryObject(activitiesToStatus, activitiesToStatusList),
    });
  } catch (e) {}
};

export const updateRelatedActivitiesOfOldItem = (cache, activity, responseActivity) => {
  try {
    const options = createQueryOptions(
      responseActivity.AccountId,
      activity.AssetId,
      activity.StatusId,
    );
    const activitiesFromOldItem = cache.readQuery(options);

    let activitiesFromOldItemList = getActivitiesFromRelatedActivities(activitiesFromOldItem);
    activitiesFromOldItemList = activitiesFromOldItemList.filter(
      (element) => element.Id !== responseActivity.Id,
    );

    cache.writeQuery({
      ...options,
      data: createRelatedActivitiesQueryObject(activitiesFromOldItem, activitiesFromOldItemList),
    });
  } catch (e) {}
};

export const updateRelatedActivitiesOfNewItem = (cache, activity, responseActivity) => {
  try {
    const optionsOldItem = createQueryOptions(
      responseActivity.AccountId,
      responseActivity.AssetId,
      responseActivity.StatusId,
    );
    const activitiesFromNewItem = cache.readQuery(optionsOldItem);

    const newObj = { ...responseActivity };
    delete newObj["AccountId"];

    let activitiesFromNewItemList = getActivitiesFromRelatedActivities(activitiesFromNewItem);
    activitiesFromNewItemList = activitiesFromNewItemList.concat([newObj]);

    cache.writeQuery({
      ...optionsOldItem,
      data: createRelatedActivitiesQueryObject(activitiesFromNewItem, activitiesFromNewItemList),
    });
  } catch (e) {}
};

export const turnOffLoadingInRelativeActivityCache = (
  client,
  accountId,
  activity,
  AssetActivityPatch,
) => {
  const options = createQueryOptions(accountId, activity.AssetId, AssetActivityPatch.StatusId);
  const activityMovedToContainer = client.readQuery(options);

  let activitiesMovedToContainerList = getActivitiesFromRelatedActivities(activityMovedToContainer);
  activitiesMovedToContainerList = activitiesMovedToContainerList.map((element) =>
    element.Id === AssetActivityPatch.Id
      ? { ...element, isLoading: false, __typename: "AssetActivity" }
      : element,
  );

  client.writeQuery({
    ...options,
    data: createRelatedActivitiesQueryObject(
      activityMovedToContainer,
      activitiesMovedToContainerList,
    ),
  });
};

export const activityFragment = gql`
  fragment AssetActivitiesLoading on AssetActivities {
    Id
    isLoading
    Asset {
      Id
      Name
    }
  }
`;

export const addLoadingStateToCustomerActivity = (cache: DataProxy, AssetActivityPatch: any) => {
  try {
    const activity = cache.readFragment({
      fragment: activityFragment,
      id: AssetActivityPatch.Id,
    });
    cache.writeFragment({
      fragment: activityFragment,
      id: AssetActivityPatch.Id,
      data: { ...activity, isLoading: true },
    });
  } catch (error) {
    console.error(error);
  }
};

export const turnOffLoadingInCustomerActivitiesCache = (
  client: ApolloClient<object> | undefined,
  AssetActivityPatch,
) => {
  if (!client) {
    return;
  }

  const fragmentOptions = {
    fragment: activityFragment,
    id: AssetActivityPatch.Id,
  };
  try {
    const activity = client.readFragment(fragmentOptions);

    client.writeFragment({
      ...fragmentOptions,
      data: { ...activity, isLoading: false },
    });
  } catch (error) {
    console.error(error);
  }
};

const assetNameFromAssetFragment = gql`
  fragment assetName on Asset {
    Id
    Name
    __typename
  }
`;

export const changeParentItemForCustomerActivity = (cache: DataProxy, AssetActivityPut) => {
  const fragmentOptions = {
    fragment: activityFragment,
    id: AssetActivityPut.Id,
  };
  try {
    const activity = cache.readFragment(fragmentOptions);

    let relatedItem;
    try {
      relatedItem = cache.readFragment({
        fragment: assetNameFromAssetFragment,
        id: AssetActivityPut.AssetId,
      });
    } catch (error) {
      console.error(error);
    }

    cache.writeFragment({
      ...fragmentOptions,
      data: { ...activity, Asset: relatedItem },
    });
  } catch (error) {
    console.error(error);
  }
};
