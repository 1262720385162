import gql from "graphql-tag";

const getUserRoleInAccount = gql`
  query getAccountQuery($Id: ID) {
    Account(Id: $Id) {
      Id
      UserRefs {
        Id
        Role
      }
    }
  }
`;

export default getUserRoleInAccount;
