import moment from "moment";
import { ActivityModel, FullActivityDueDate } from "../redux/models/data/ActivityModelFull";
import { DATE_FORMAT, DATE_FORMAT_INVITATION } from "./date";
import {
  EMAIL_REGEX,
  FIRST_LETTER,
  FOR_INPUT_TYPE_TEXT,
  FOR_PROFILE_NAME,
  WEBSITE_REGEX,
} from "./regex";
import {
  ActivitiesFieldName,
  AffiliatesUrls,
  AffiliatesUrlsType,
  EndAfter,
  LayoutType,
  RepeatType,
  statusesInDeletePopupArr,
  INPUT_LIMITS,
  AVAILABLE_IMAGE_TYPES,
  AVAILABLE_DOCUMENT_TYPES,
  AVAILABLE_VIDEO_TYPES,
  UNASSIGNED,
  ME_PREFIX,
  ALL_ACTIVITIES,
} from "../_constant/constants";
import { SORTING_DETAILS } from "../_constant/ActivityDateConstant";
import { ActivityScheduleModel } from "../redux/models/data/ActivityScheduleModel";
import {
  CURRENT_ACCOUNT,
  getIncorrectCharactersNumberMessage,
  ID,
  INCORRECT_VALUE_MESSAGE,
  REQUIRED_VALUE_MESSAGE,
} from "../_constant/wordings";
import { ActivityModelRelated } from "../containers/assets/_components/relaitedActivities/ActivityModelRelated";
import { store } from "../App";
import { ActivityAffiliateModel } from "../redux/models/data/ActivityAffiliateModel";
import { Image as ImageModel } from "../redux/models/data/ImageModel";
import imagesConfig from "../../configs/images-config.json";
import documentsConfig from "../../configs/documents-config.json";
import videosConfig from "../../configs/videos-config.json";
import * as statusesConfig from "../../configs/statuses-config.json";
import {
  isDateValid,
  isNumberCheck,
} from "../containers/activities/_components/date/due_date/forms/recurring/validation";
import { ChangeEvent } from "react";
import { AssignType } from "../pages/CalendarFeeds/CalendarConfig.types";
import { ActivityRelatedToProjectModel } from "../components/ActivitiesTabProject/ActivityRelatedToProjectModel";

export function notEmpty(val: { [key: string]: any }) {
  if (val === undefined || val === null) {
    return false;
  }
  if (val.hasOwnProperty(ID)) {
    return !!(val.Id || val.Name);
  }
  return val.trim().length > 0;
}

const _validateImage = (image: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    if (image.size < imagesConfig.maxImageSize) {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = function (e) {
        // not an arrow function (!)
        const { minWidth, minHeight, maxWidth, maxHeight } = imagesConfig;
        const isValid =
          img.width <= maxWidth &&
          img.height <= maxHeight &&
          img.width >= minWidth &&
          img.height >= minHeight;
        return isValid ? resolve(image) : reject(`Image ${image.name} has the wrong resolution`);
      };
    } else {
      return reject(`Image ${image.name} size ${image.size} exceeded the limit of ${imagesConfig.maxSize}`);
    }
  });
};

const _validateDocument = (doc: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    return doc.size <= documentsConfig.maxSize
      ? resolve(doc)
      : reject(`File ${doc.name} size ${doc.size} exceeded the limit of ${documentsConfig.maxSize}`);
  });
};

const _validateVideo = (video: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    return video.size <= videosConfig.maxSize
      ? resolve(video)
      : reject(`Video ${video.name} size ${video.size} exceeded the limit of ${videosConfig.maxSize}`);
  });
};

export function validateUploadedFile(file: File): Promise<any> {
  let isTypeOfFileAvailable = false;

  if (AVAILABLE_IMAGE_TYPES.includes(file.type)) {
    isTypeOfFileAvailable = true;
    return _validateImage(file);
  }

  if (AVAILABLE_DOCUMENT_TYPES.includes(file.type)) {
    isTypeOfFileAvailable = true;
    return _validateDocument(file);
  }

  if (AVAILABLE_VIDEO_TYPES.includes(file.type)) {
    isTypeOfFileAvailable = true;
    return _validateVideo(file);
  }

  if (!isTypeOfFileAvailable) {
    return Promise.reject(`File ${file.name} was rejected this type not supported`);
  }

  return Promise.resolve();
}

export function alphabetNameSorting(a, b) {
  const nameA = (a.Name || "").toLowerCase();
  const nameB = (b.Name || "").toLowerCase();
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
}

export const statusClassName = (name: string) => name.toLowerCase().split(" ").join("-");

export const formatField = (field: any) =>
  Number(moment(field, [DATE_FORMAT, moment.ISO_8601], true).format("YYYYMMDD")); // convert date to number

export function dateSorting(arr: any[], fieldToSortBy: string, order: string) {
  return arr.sort((a, b) => {
    if (!a[fieldToSortBy]) {
      return 1;
    } else if (!b[fieldToSortBy]) {
      return -1;
    }

    const sortResult = formatField(a[fieldToSortBy]) - formatField(b[fieldToSortBy]);

    return order === "asc" ? sortResult : -sortResult;
  });
}

export function updateUrlWithParameters(assetType?: string, assets?: Set<string>) {
  const pathname = window.location.pathname;
  let assetParams = "";
  assets && assets.forEach((Id) => (assetParams += `&item=${Id}`));
  window.history.replaceState(
    null,
    null,
    `${pathname}${assetType ? `?type=${assetType}${assetParams}` : ""}`,
  );
}

export function parseUrlParameters() {
  const url = new URL(window.location.href);
  const result = { selectedAssetType: "", selectedAssetIds: new Set() };

  if (notEmpty(url.searchParams.get("type"))) {
    result.selectedAssetType = url.searchParams.get("type");

    result.selectedAssetIds = url.searchParams.get("item")
      ? new Set(url.searchParams.getAll("item"))
      : result.selectedAssetIds;
  }
  return result;
}

export function getOptionWithValue(value, str?) {
  const label = `${value.toLowerCase().replace(FIRST_LETTER, (c) => c.toUpperCase())}${str || ""}`;
  return { label, value };
}

export const isRecurring = (value: FullActivityDueDate) => {
  if (!value?.schedule && !value?.relatedDueDate) {
    return false;
  }
  return (
    value?.schedule?.RecurringType !== RepeatType.NONE ||
    (value.relatedDueDate && value.relatedDueDate.length > 0)
  );
};

export const sortArrOfAllRelatedActivities = (
  activities: ActivityModelRelated[],
  statusName: Map<string, string>,
): ActivityModel[] => {
  const activitiesByStatus = new Map(
    statusesInDeletePopupArr.map((name) => [name, { status: "", value: [] }]),
  );
  let activitiesSorted = [];

  activities.forEach((item) => {
    const status = statusName.get(item.StatusId);
    if (!status) {
      return [] as ActivityModel[];
    }
    const statusNormalize = status.trim().toLowerCase();

    if (activitiesByStatus.has(statusNormalize)) {
      const value = [...activitiesByStatus.get(statusNormalize).value, item];
      activitiesByStatus.set(statusNormalize, { status, value });
    }
  });

  activitiesByStatus.forEach(({ status, value }) => {
    if (value.length > 0) {
      const statusObj = SORTING_DETAILS.get(status);
      const sorted = dateSorting(
        value.sort(alphabetNameSorting),
        statusObj["field"],
        statusObj["order"],
      );
      activitiesSorted = [...activitiesSorted, ...sorted];
    }
  });
  return activitiesSorted;
};

export function getEndAfterValue(recurrence: ActivityScheduleModel) {
  return recurrence.EndDate
    ? EndAfter.END_DATE
    : isNumberCheck(recurrence.AppearanceCount)
    ? EndAfter.REPEAT_COUNT
    : EndAfter.NO_LIMIT;
}

const checkArraysForDeepEqual = (a: any[], b: any[]) => {
  const aToString = JSON.stringify(a);
  const bToString = JSON.stringify(b);

  return aToString === bToString;
};

function checkDatesArrayForEqual(a: any[], b: any[]) {
  if (a.length !== b.length) return false;

  a = a.sort();
  b = b.sort();
  let result = true;

  for (let i = 0; i < a.length; i++) {
    if (!result) {
      return false;
    }
    if (moment(a[i]).isValid() && moment(b[i]).isValid()) {
      // remove time if arguments are dates
      result = removeTimeFromDate(a[i]) === removeTimeFromDate(b[i]);
    } else if (a[i] !== b[i]) {
      result = false;
    }
  }
  return result;
}

function arraysEqual(a: any[], b: any[]): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;

  const isDatesArray = a.every((item) => isDateValid(item));
  if (isDatesArray) return checkDatesArrayForEqual(a, b);

  return checkArraysForDeepEqual(a, b);
}

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

function isEqual(obj1, obj2): boolean {
  if (obj1 instanceof Array && obj2 instanceof Array) {
    return arraysEqual(obj1, obj2);
  }

  if (obj1 instanceof Object && obj2 instanceof Object) {
    return shallowEqual(obj1, obj2);
  }

  return obj1 === obj2;
}

export function updateChangedFields(fieldName, value, initialValue, prevValue, changedFields) {
  if (isEqual(prevValue, value)) {
    return changedFields;
  }

  if (isEqual(initialValue, prevValue)) {
    changedFields.add(fieldName);
  }

  if (isEqual(value, initialValue)) {
    changedFields.delete(fieldName);
  }

  return changedFields;
}

export function validateNameFields(
  fieldName,
  value,
  errorMap: Map<string, string>,
): [Map<string, string>, boolean] {
  value = value && value.hasOwnProperty("Name") ? value.Name : value;
  if (!notEmpty(value)) {
    errorMap.set(fieldName, REQUIRED_VALUE_MESSAGE);
    return [errorMap, false];
  }

  if (value.length < INPUT_LIMITS.Name.min || value.length > INPUT_LIMITS.Name.max) {
    errorMap.set(fieldName, getIncorrectCharactersNumberMessage(INPUT_LIMITS.Name));
    return [errorMap, false];
  }

  if (!FOR_INPUT_TYPE_TEXT.test(value)) {
    errorMap.set(fieldName, INCORRECT_VALUE_MESSAGE);
    return [errorMap, false];
  }

  errorMap.delete(fieldName);
  return [errorMap, errorMap.size === 0];
}

export const validateActivityNameField = (fieldName, value, errorMap: Map<string, string>) => {
  if (!notEmpty(value)) {
    errorMap.set(fieldName, REQUIRED_VALUE_MESSAGE);
    return [errorMap, false];
  }

  if (
    value.length < INPUT_LIMITS.NameActivity.min ||
    value.length > INPUT_LIMITS.NameActivity.max
  ) {
    errorMap.set(fieldName, getIncorrectCharactersNumberMessage(INPUT_LIMITS.NameActivity));
    return [errorMap, false];
  }

  if (!/^[^^±£§¡€¢¶•ªº©«]{1,50}$/.test(value)) {
    errorMap.set(fieldName, INCORRECT_VALUE_MESSAGE);
    return [errorMap, false];
  }

  errorMap.delete(fieldName);
  return [errorMap, errorMap.size === 0];
};

export function validateLastName(
  fieldName,
  value,
  errorMap: Map<string, string>,
): [Map<string, string>, boolean] {
  if (!FOR_PROFILE_NAME.test(value)) {
    errorMap.set(fieldName, INCORRECT_VALUE_MESSAGE);
    return [errorMap, false];
  } else {
    errorMap.delete(fieldName);
    return [errorMap, errorMap.size === 0];
  }
}

export function validateAssetField(
  fieldName,
  value,
  errorMap: Map<string, string>,
): [Map<string, string>, boolean] {
  if (notEmpty(value)) {
    errorMap.delete(fieldName);
    return [errorMap, errorMap.size === 0];
  } else {
    errorMap.set(fieldName, REQUIRED_VALUE_MESSAGE);
    return [errorMap, false];
  }
}

export function getTitlePrefix(
  isSearching = false,
  isFiltering = false,
  isSearchingByTags = false,
): string {
  if (isSearching || isSearchingByTags) {
    return "Found";
  }
  if (isFiltering) {
    return "Selected";
  }
  return "All";
}

export function isCompletedStatus(statuses, nextStatusId) {
  const { FILL, FINISH } = statusesConfig["statusNameMap"];
  const findStatusName = statuses.find((item) => item.Id === nextStatusId).Name.toLowerCase();
  return findStatusName === FILL.toLowerCase() || findStatusName === FINISH.toLowerCase();
}

export function composeImageUrl(
  imgId: string,
  imgContainerName: string,
  imgContainerId: string,
): string {
  const state = store.getState();
  const blobStorage = state.appInfo.clientInfo.blobStorage;

  return `${blobStorage}/${imgContainerName}/${imgContainerId}/${imgId}`;
}

export const checkOnImgChange = (fromServer, local, initImgs, changedFields) => {
  initImgs.length !== fromServer.length
    ? changedFields.add("Images")
    : changedFields.delete("Images");
  local.length > 0 ? changedFields.add("Files") : changedFields.delete("Files");
  return new Set(changedFields);
};

export function sortActivities(statusName, activitiesArr) {
  const { field, order } = SORTING_DETAILS.get(statusName);
  return dateSorting(activitiesArr.sort(alphabetNameSorting), field, order);
}

export function removeTimeFromDate(value) {
  const res = moment(value);

  return res.isValid() ? moment(res).format(DATE_FORMAT) : value;
}

export function isItDateField(fieldName: string): boolean {
  return (
    fieldName === ActivitiesFieldName.DUE_DATE_TIME ||
    fieldName === ActivitiesFieldName.COMPLETED_DATE_TIME ||
    fieldName === ActivitiesFieldName.START_DATE
  );
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getAffiliatesList(
  affiliatesArray: ActivityAffiliateModel[],
): ActivityAffiliateModel[] {
  const result: Array<ActivityAffiliateModel> = [];

  affiliatesArray.forEach((affiliate: ActivityAffiliateModel) => {
    if (affiliate[AffiliatesUrls.PRODUCT_URL]) {
      result.push(
        new ActivityAffiliateModel({
          Url: affiliate[AffiliatesUrls.PRODUCT_URL],
          Text: affiliate.Type === "YOU_TUBE" ? "How To" : "Find Products",
          Icon: affiliate.Type === "YOU_TUBE" ? "play_circle_outline" : "shopping_basket",
          Type: affiliate.Type,
        }),
      );
    }
    if (affiliate[AffiliatesUrls.SERVICE_URL]) {
      result.push(
        new ActivityAffiliateModel({
          Url: affiliate[AffiliatesUrls.SERVICE_URL],
          Text: "Find Service",
          Icon: "room_service",
          Type: affiliate.Type,
        }),
      );
    }
  });
  return result;
}

export const prepareImagesForGallery = (images: ImageModel[]) => {
  if (images.length) {
    return images.map((image) => {
      return image.Id
        ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
        : URL.createObjectURL(image);
    });
  } else {
    return [];
  }
};

export const clearLocalStorageExceptCurrentAccount = () => {
  const currentAccount = localStorage.getItem(CURRENT_ACCOUNT);
  localStorage.clear();
  if (currentAccount) {
    localStorage.setItem(CURRENT_ACCOUNT, currentAccount);
  }
};

export const transformName = (name: string): string => {
  const innerVal = name.trim().toLowerCase();
  if (innerVal.includes("/")) {
    return innerVal.replace("/", "_");
  }
  if (innerVal.includes(" ")) {
    // return innerVal.replaceAll(" ", "_");
    return innerVal.replace(/ /g, "_");
  }
  return innerVal;
};

export const getAssetImage = (staticData: any, type: string) => {
  const defaultImageName = `pic_${transformName(type)}`;

  return !staticData
    ? ""
    : staticData.Assets[defaultImageName]
    ? staticData.Assets[defaultImageName]
    : staticData.Assets.pic_default;
};

export const changeDateFormat = (date: string) => {
  return moment(date).format(DATE_FORMAT_INVITATION);
};

export const checkIfBoardView = (layout: LayoutType) => {
  return layout === LayoutType.BOARD;
};

export function validateEmail(email: string) {
  return EMAIL_REGEX.test(String(email).toLowerCase());
}
export function validateWebsiteUrl(url: string) {
  return WEBSITE_REGEX.test(String(url).toLowerCase());
}

export function validateEmailField(
  fieldName: string,
  value: string,
  errorMap: Map<string, string>,
): [Map<string, string>, boolean] {
  if (!validateEmail(value)) {
    errorMap.set(fieldName, "Wrong email format");
    return [errorMap, false];
  } else {
    errorMap.delete(fieldName);
    return [errorMap, errorMap.size === 0];
  }
}

export function validateWebsiteField(
  fieldName: string,
  value: string,
  errorMap: Map<string, string>,
): [Map<string, string>, boolean] {
  if (!validateWebsiteUrl(value)) {
    errorMap.set(fieldName, "Wrong website format");
    return [errorMap, false];
  } else {
    errorMap.delete(fieldName);
    return [errorMap, errorMap.size === 0];
  }
}

export const getWordEnding = (number: number, words: string[]) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ];
};

export const addCustomerIdToUrl = (url: string, customerId: string) => {
  return `${url}?customerId=${customerId}`;
};

export const mergeActivities = (fromCustomer: any[], fromSnapshot: any[], doneId: string = "") => {
  const res: any = [];

  const snapMap = fromSnapshot.reduce((acc: { [key: string]: string }, el: any) => {
    acc[el.Id] = el.Id;
    return acc;
  }, {});

  fromCustomer.forEach((el) => {
    if (el.StatusId !== doneId || snapMap.hasOwnProperty(el.Id)) {
      res.push(el);
    }
  });

  return res;
};

export const debounce = (func: Function, delay: number) => {
  let timeout: any;

  return function executedFunction(...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
  };
};

export const getVisitBooleanStatus = (visitStatusName: string) => {
  const { IN_PROGRESS, FINISH } = statusesConfig.statusNameMap;
  return {
    isVisitStarted: visitStatusName === IN_PROGRESS,
    isVisitFinished: visitStatusName === FINISH,
  };
};

interface mapFiles {
  images: File[];
  documents: File[];
  videos: File[];
}

export const convertUploadedFilesToMap = (acc: mapFiles, file: File) => {
  if (AVAILABLE_IMAGE_TYPES.includes(file.type)) {
    acc.images = acc?.images?.length ? [...acc.images, file] : [file];
    return acc;
  }
  if (AVAILABLE_DOCUMENT_TYPES.includes(file.type)) {
    acc.documents = acc?.documents?.length ? [...acc.documents, file] : [file];
    return acc;
  }
  if (AVAILABLE_VIDEO_TYPES.includes(file.type)) {
    acc.videos = acc?.videos?.length ? [...acc.videos, file] : [file];
    return acc;
  }
  return acc;
};

export const immediatelyFileDownload = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";

  return function (data: string, name: string) {
    // const blob = new Blob([data], { type: "octet/stream" }),
    //   url = window.URL.createObjectURL(blob);
    const mock_url =
      "https://filedevupkeeprcom.blob.core.windows.net/accounts/Annual%20dental%20cleaning%20for%20Dog.ics";
    a.href = mock_url;
    a.download = "Annual dental cleaning for Dog";
    a.click();
    // window.URL.revokeObjectURL(url);
  };
})();

export const mapAssignFromServer = (serverValue: string, userId: string) => {
  switch (serverValue) {
    case AssignType.UNASSIGNED:
      return UNASSIGNED;
    case AssignType.SELF:
      return `${ME_PREFIX}${userId}`;
    case AssignType.ALL_USERS:
      return ALL_ACTIVITIES;
    case AssignType.SINGLE_USER:
      return userId;
    default:
      return ALL_ACTIVITIES;
  }
};

export const mapAssignToServer = (value: string, userId: string) => {
  if (value === UNASSIGNED) {
    return AssignType.UNASSIGNED;
  }
  if (value.includes(ME_PREFIX)) {
    return AssignType.SELF;
  }
  if (value === ALL_ACTIVITIES) {
    return AssignType.ALL_USERS;
  }
  if (value === userId) {
    return AssignType.SINGLE_USER;
  }
};

export function swappingActivities(
  dragIndex: number,
  hoverIndex: number,
  activities: ActivityRelatedToProjectModel[],
) {
  const activitiesCopy = [...activities];
  [activitiesCopy[dragIndex], activitiesCopy[hoverIndex]] = [
    activitiesCopy[hoverIndex],
    activitiesCopy[dragIndex],
  ];
  return activitiesCopy;
}
