const useIsMobileDevice = (): Boolean => {
  const isUserDevicePatchedString = localStorage.getItem("isUserDevicePatched");

  const isUserDevicePatched = isUserDevicePatchedString
    ? JSON.parse(isUserDevicePatchedString)
    : null;

  if (isUserDevicePatched) {
    return !!isUserDevicePatched?.token;
  }

  return !!isUserDevicePatched;
};

export default useIsMobileDevice;
