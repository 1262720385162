import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { conformToMask } from "react-text-mask";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import { VendorModel } from "../../redux/models/data/VendorModel";

import { ROUTES } from "../../_constant/screens";
import { PHONE_CARD_MASK } from "../../_utils/regex";

import { textClip } from "../_styled/textClip";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { Typography } from "@material-ui/core";

import Modal from "../../containers/Modal/Modal";
import BoardCardLayout from "../../containers/assets/_components/BoardCardLayout";
import VendorDeleteDialog from "../VendorForm/VendorDeleteDialog/VendorDeleteDialog";

const ContactsItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.greyDesign.surface2};
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
  }
`;

const ContactsText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.greyDesign.surface2};

  ${textClip}
`;

interface VendorBoardCardProps {
  data: VendorModel;
}

const VendorBoardCard: React.FC<VendorBoardCardProps> = (props) => {
  const { data } = props;
  const { Name, Address, Images, Email, Schema, Id, Phone, Website } = data;

  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const openDeleteDialog = useCallback(() => setIsDeletePopupVisible(true), []);
  const closeDeleteDialog = useCallback(() => setIsDeletePopupVisible(false), []);

  const staticData = useStaticDataQuery();
  const navigate = useNavigate();

  const footerIcon = "Icon-add";
  const footerText = "Create activity";

  const handleFooterClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`${ROUTES.CREATE_ACTIVITY}?vendor=${Id}`);
  };

  const handleVendorClick = () => {
    navigate(`${ROUTES.SINGLE_VENDOR}/${Id}`);
  };

  const vendorImage = staticData ? staticData?.Icons?.Desktop?.pic_vendor : "";

  const image = Images?.length ? data.Images[0] : null;

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: openDeleteDialog,
    },
  ];

  return (
    <>
      <BoardCardLayout
        name={Name}
        image={image}
        imageIcon={vendorImage}
        alt={Name}
        staticData={staticData}
        footerIcon={footerIcon}
        footerText={footerText}
        handleFooterClick={handleFooterClick}
        handleSelectCard={handleVendorClick}
        moreActionOptions={options}
      >
        {Email && (
          <ContactsItem>
            <MailOutlineOutlinedIcon />
            <ContactsText title={Email}>{Email}</ContactsText>
          </ContactsItem>
        )}
        {Address && (
          <ContactsItem>
            <LocationOnOutlinedIcon />
            <ContactsText title={Address}>{Address}</ContactsText>
          </ContactsItem>
        )}
        {Phone && (
          <ContactsItem>
            <PhoneOutlinedIcon />
            <ContactsText title={conformToMask(Phone, PHONE_CARD_MASK).conformedValue}>
              {conformToMask(Phone, PHONE_CARD_MASK).conformedValue}
            </ContactsText>
          </ContactsItem>
        )}
        {Website && (
          <ContactsItem>
            <LanguageOutlinedIcon />
            <ContactsText title={Website}>{Website}</ContactsText>
          </ContactsItem>
        )}
      </BoardCardLayout>
      <Modal isShowing={isDeletePopupVisible}>
        <VendorDeleteDialog id={Id} handleCancel={closeDeleteDialog} withoutRedirect />
      </Modal>
    </>
  );
};

export default VendorBoardCard;
