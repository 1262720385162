import React from "react";
import { useLayoutSwitcherContext } from "./useLayoutSwitcherContext";
import ActivitiesLayoutSwitcher from "../ActivitiesLayoutSwitcher/ActivitiesLayoutSwitcher";

interface LayoutSwitcherContainerProps {}

const LayoutSwitcherActivitiesContainer: React.FC<LayoutSwitcherContainerProps> = (props) => {
  const {
    state: { activities },
    switchLayout,
  } = useLayoutSwitcherContext();

  return <ActivitiesLayoutSwitcher layout={activities} switchLayout={switchLayout.activities} />;
};

export default LayoutSwitcherActivitiesContainer;
