import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const StyledEmptyStateContainer = styled.div`
  text-align: center;
`;

const StyledTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({theme}) => theme.palette.text.primary};
  letter-spacing: 0.15px;
`;

const StyledSubtitle = styled(Typography)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.greyDesign.surface2};
  letter-spacing: 0.25px;
`;

const ImageContainer = styled.div`
  width: 122px;
  height: 128px;
  margin: 0 auto;
  background-image: var(--empty-state-wizard-search);
  margin-bottom: 16px;
`;

const EmptyState: React.FC = () => {
  return (
    <StyledEmptyStateContainer>
      <ImageContainer/>
      <StyledTitle>Nothing found</StyledTitle>
      <StyledSubtitle>There is nothing with that name</StyledSubtitle>
    </StyledEmptyStateContainer>
  );
};

export default EmptyState;
