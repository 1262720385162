import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { getNotificationsQuery } from "./Notifications.query";

export const updateCacheWithRemovedNotification = (
  cache: DataProxy,
  updatedNotifications: any,
  userId: string,
) => {
  try {
    const notificationListQuery: any = cache.readQuery({
      query: getNotificationsQuery,
      variables: {
        UserId: userId,
      },
    });
    notificationListQuery.AccountNotificationSettingModel.filter((notification: any) => {
      return notification.Id !== updatedNotifications.NotificationSettingsPatch[0].Id;
    });

    const filtered = notificationListQuery.AccountNotificationSettingModel.filter(
      (notification: any) => {
        return notification.Id !== updatedNotifications.NotificationSettingsPatch[0].Id;
      },
    );

    cache.writeQuery({
      query: getNotificationsQuery,
      variables: {
        UserId: userId,
      },
      data: {
        AccountNotificationSettingModel: filtered,
      },
    });
  } catch (e) {
    console.error(e);
  }
};
