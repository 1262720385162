import React from "react";

import useGetAccountId from "../../../customHooks/useGetAccountId";

import { Button } from "@material-ui/core";

interface WizardLoadingFooterProps {}

const WizardLoadingFooter: React.FC<WizardLoadingFooterProps> = ({ navigate }) => {
  const { IsCustomerManagement } = useGetAccountId();

  const layoutForBusiness = () => (
    <Button color="primary" disabled>
      Done
    </Button>
  );

  const defaultLayout = () => (
    <div>
      <Button onClick={() => navigate("backward")} disabled>
        Activities List
      </Button>
      <Button onClick={() => navigate()} disabled>
        Item List
      </Button>
    </div>
  );

  return IsCustomerManagement ? layoutForBusiness() : defaultLayout();
};

export default WizardLoadingFooter;
