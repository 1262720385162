import React from "react";
import { ActivityModelRelated } from "../../containers/assets/_components/relaitedActivities/ActivityModelRelated";
import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import AssetCardRow from "../Cards/AssetCardRow";
import AssetCardInfo from "../AssetCardAdditionalInfo/AssetCardInfo";
import { AssetModel } from "../../redux/models/data/AssetModel";

interface RelatedItemsListProps {
  relatedItems: ActivityModelRelated[];
  disableNavigationByClick: boolean;
  hideContextMenu?: boolean;
}

const RelatedItemsList: React.FC<RelatedItemsListProps> = (props) => {
  const { relatedItems, disableNavigationByClick, hideContextMenu } = props;

  return (
    <>
      {relatedItems.map((item: AssetModel) => {
        const convertedAssetItem = new AssetRefsModel({
          AssetTypeRef: item.AssetType,
          Id: item.Id,
          Name: item.Name,
          Description: item.Description,
          MainImage: item.Images?.length ? item.Images[0] : null,
          HasActivities: item.AssetActivities.length > 0,
          CustomerId: item.CustomerId,
        });
        return (
          <AssetCardRow
            item={convertedAssetItem}
            key={item.Id}
            disableNavigationByClick={disableNavigationByClick}
            additionalInfo={
              <AssetCardInfo item={convertedAssetItem} assetActivities={item.AssetActivities} />
            }
            hideMoreAction={hideContextMenu}
          />
        );
      })}
    </>
  );
};

export default RelatedItemsList;
