import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const userSubscriptionLimitsQuery = gql`
  query getCurrentUserLimits {
    User {
      Id
      SubscriptionSettings {
        AccountLimits {
          MaxGroupAccounts {
            IsLimited
            Value
          }
          MaxItemsPerAccount {
            IsLimited
            Value
          }
          MaxPersonalAccounts {
            IsLimited
            Value
          }
          MaxVendorsPerAccount {
            IsLimited
            Value
          }
        }
        AssetLimits {
          MaxDocuments {
            IsLimited
            Value
          }
          MaxImages {
            IsLimited
            Value
          }
          MaxVideos {
            IsLimited
            Value
          }
        }
        ActivityLimits {
          MaxDocuments {
            IsLimited
            Value
          }
          MaxImages {
            IsLimited
            Value
          }
          MaxVideos {
            IsLimited
            Value
          }
        }
        CustomerLimits {
          MaxDocuments {
            IsLimited
            Value
          }
          MaxImages {
            IsLimited
            Value
          }
          MaxVideos {
            IsLimited
            Value
          }
        }
        VisitLimits {
          MaxDocuments {
            IsLimited
            Value
          }
          MaxImages {
            IsLimited
            Value
          }
          MaxVideos {
            IsLimited
            Value
          }
        }
        ProjectLimits {
          MaxDocuments {
            IsLimited
            Value
          }
          MaxImages {
            IsLimited
            Value
          }
          MaxVideos {
            IsLimited
            Value
          }
        }
        UserLimits {
          EmailNotifications
          PushNotifications
          SuggestPurchaseLinks
          CalendarIntegration {
            IsLimited
            Value
          }
          MaxTotalUploadsSizeMb {
            IsLimited
            Value
          }
        }
      }
    }
  }
`;

interface LimitObject {
  IsLimited?: boolean;
  Value: number;
}

interface FilesLimits {
  documents: LimitObject;
  videos: LimitObject;
  images: LimitObject;
}

interface useGetLimitsReturn {
  account: {
    maxPersonalAccounts: LimitObject;
    maxGroupAccounts: LimitObject;
    maxItemsPerAccount: LimitObject;
    maxVendorsPerAccount: LimitObject;
  };
  asset: FilesLimits;
  activity: FilesLimits;
  customer: FilesLimits;
  visit: FilesLimits;
  project: FilesLimits;
  user: {
    emailNotifications: boolean;
    pushNotifications: boolean;
    suggestPurchaseLinks: boolean;
    calendarIntegration: LimitObject;
    maxTotalUploadsSizeMb: LimitObject;
  };
}

export const useGetLimits = (): useGetLimitsReturn | null => {
  const { data, loading, error } = useQuery(userSubscriptionLimitsQuery);

  if (data && !loading && !error) {
    if (!data?.User?.SubscriptionSettings) return null;
    const {
      AccountLimits,
      AssetLimits,
      ActivityLimits,
      CustomerLimits,
      VisitLimits,
      UserLimits,
      ProjectLimits,
    } = data.User.SubscriptionSettings;
    return {
      account: {
        maxPersonalAccounts: AccountLimits.MaxPersonalAccounts,
        maxGroupAccounts: AccountLimits.MaxGroupAccounts,
        maxItemsPerAccount: AccountLimits.MaxItemsPerAccount,
        maxVendorsPerAccount: AccountLimits.MaxVendorsPerAccount,
      },
      asset: {
        documents: AssetLimits.MaxDocuments,
        videos: AssetLimits.MaxVideos,
        images: AssetLimits.MaxImages,
      },
      activity: {
        documents: ActivityLimits.MaxDocuments,
        videos: ActivityLimits.MaxVideos,
        images: ActivityLimits.MaxImages,
      },
      customer: {
        documents: CustomerLimits.MaxDocuments,
        videos: CustomerLimits.MaxVideos,
        images: CustomerLimits.MaxImages,
      },
      visit: {
        documents: VisitLimits.MaxDocuments,
        videos: VisitLimits.MaxVideos,
        images: VisitLimits.MaxImages,
      },
      project: {
        documents: ProjectLimits.MaxDocuments,
        videos: ProjectLimits.MaxVideos,
        images: ProjectLimits.MaxImages,
      },
      user: {
        emailNotifications: UserLimits.EmailNotifications,
        pushNotifications: UserLimits.PushNotifications,
        suggestPurchaseLinks: UserLimits.SuggestPurchaseLinks,
        calendarIntegration: UserLimits.CalendarIntegration,
        maxTotalUploadsSizeMb: UserLimits.MaxTotalUploadsSizeMb,
      },
    };
  }

  return null;
};
