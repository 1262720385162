import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import Paper from "@material-ui/core/Paper";

export const StyledCombobox = styled(({ hasError, ...restProps }) => (
  <Autocomplete {...restProps} />
))`
  .MuiInput-underline:after {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.palette.error.main : theme.palette.primary.main};
  }
  .MuiInput-underline:before {
    border-color: ${({ hasError, theme }) => (hasError ? theme.palette.error.main : "#949494")};
  }
  .MuiFormLabel-root {
    color: ${({ hasError, theme }) =>
      hasError ? theme.palette.error.main : theme.palette.text.secondary};
  }
  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root.MuiInputLabel-shrink {
    color: ${({ hasError, theme }) =>
      hasError ? theme.palette.error.main : theme.palette.primary.main};
  }
  .MuiAutocomplete-clearIndicator {
    width: 12px;
    height: 12px;
  }
  .MuiIconButton-root {
    padding: 9px;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 9px);
  }
  .MuiAutocomplete-popupIndicator {
    padding: 3px;
    margin-left: 5px;
  }

  button .MuiSvgIcon-root {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const Wrapper = styled(StyledCombobox)`
  .MuiAutocomplete-popper {
    border: 2px solid red;
  }
`;

export const AssetPaperWrapper = styled(Paper)`
  .MuiAutocomplete-option:last-child {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ActivityPaperWrapper = styled(Paper)`
  .MuiAutocomplete-option:first-child {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ErrorHelperText = styled(({ text, ...restProps }) => (
  <span {...restProps}>{text}</span>
))`
  color: ${({ theme }) => theme.palette.error.main};
`;
