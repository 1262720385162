import * as config from "../../../../../configs/statuses-config.json";
import * as React from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../../../_utils/date";
import { ActivitiesFieldName, COLOR_CODE_STATE } from "../../../../_constant/constants";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import EventIcon from "@material-ui/icons/Event";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import { StyledActivityItemDate } from "./ActivityItemDateStyles";
import { useEffect, useState } from "react";

export type DATE_STATUS = "completed" | "overdue" | "close" | "far" | "cancelled" | string;

const iconsCodeMap = {
  [COLOR_CODE_STATE.completed]: <CheckBoxOutlinedIcon />,
  [COLOR_CODE_STATE.overdue]: <TimerOffIcon />,
  [COLOR_CODE_STATE.close]: <TimerOutlinedIcon />,
  [COLOR_CODE_STATE.far]: <EventIcon />,
  [COLOR_CODE_STATE.cancelled]: <EventBusyIcon />,
};

interface ActivityItemDateProps {
  activityDate: string | null;
  dateType: string;
  iconOnly?: boolean;
  isCancelled?: boolean;
}

export const ActivityItemDate: React.FC<ActivityItemDateProps> = ({
  activityDate,
  dateType,
  iconOnly = false,
  isCancelled,
}) => {
  const [currentStatus, setCurrentStatus] = useState<DATE_STATUS>(COLOR_CODE_STATE.far);

  useEffect(() => {
    const DAYS_TO_MARK_AS_COMING_SOON = config["daysToMarkAsComingSoon"];
    const today = moment();
    const dueDate = moment(activityDate, [DATE_FORMAT, moment.ISO_8601], true);

    if (!activityDate) {
      setCurrentStatus("");
    }

    if (dateType === ActivitiesFieldName.DUE_DATE_TIME) {
      if (dueDate.isBefore(today, "day")) {
        setCurrentStatus(COLOR_CODE_STATE.overdue);
      }
      if (
        dueDate.isBetween(
          today,
          moment(today).add(DAYS_TO_MARK_AS_COMING_SOON, "days"),
          "day",
          "[)",
        )
      ) {
        setCurrentStatus(COLOR_CODE_STATE.close);
      }
    }
    if (dateType === ActivitiesFieldName.COMPLETED_DATE_TIME && isCancelled) {
      setCurrentStatus(COLOR_CODE_STATE.cancelled);
      return;
    }
    if (dateType === ActivitiesFieldName.COMPLETED_DATE_TIME) {
      setCurrentStatus(COLOR_CODE_STATE.completed);
    }
  }, [activityDate]);

  const renderIcon = () => {
    return iconsCodeMap[currentStatus] || iconsCodeMap[COLOR_CODE_STATE.far];
  };

  const renderText = () => {
    if (iconOnly) return null;
    return activityDate ? activityDate : "No Due Date";
  };

  return (
    <StyledActivityItemDate status={currentStatus} iconOnly={iconOnly}>
      {renderIcon()}
      {renderText()}
    </StyledActivityItemDate>
  );
};
