import React from "react";
import { useNavigate } from "react-router-dom";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import { AccountModel } from "../../redux/models/data/AccountModel";

import { ROUTES } from "../../_constant/screens";

import { composeImageUrl } from "../../_utils/utils";

import Button from "@material-ui/core/Button";

// import { AvatarGroup } from "@material-ui/lab";

import AddIcon from "@material-ui/icons/Add";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import {
  AccountListItemStyled,
  AccountListLetterInAvatar,
  AccountListItemAvatar,
  AccountListItemAvatarPlaceholder,
  RadioButtonStyle,
} from "./AccountListItemStyle";
import { TramRounded } from "@material-ui/icons";

import AccountListItemName from "./AccountListItemName";

interface IAccountListItemProps {
  isMobile: boolean;
  isPersonal: boolean;
  isBusiness: boolean;
  account: AccountModel;
  currentAccountId: string | null;
  isLoading?: boolean;
  closeAlert: () => void;
  handleAccountSwitch?: (id: string) => void;
}

const AccountListItem = (props: IAccountListItemProps) => {
  const image = props.account.Images && props.account.Images[0] ? props.account.Images[0] : null;
  const isSelected = props.account.Id === props.currentAccountId;
  const navigate = useNavigate();
  const staticData = useStaticDataQuery();

  const onAddHandle = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(ROUTES.NEW_ACCOUNT);
    props.closeAlert();
  };

  const renderMobileSelected = () => (
    <RadioButtonStyle>
      {!isSelected || props.isLoading ? (
        <RadioButtonUncheckedIcon fontSize="small" color="primary" />
      ) : (
        <RadioButtonCheckedIcon fontSize="small" color="primary" />
      )}
    </RadioButtonStyle>
  );

  if (props.isPersonal) {
    return (
      <AccountListItemStyled
        button
        selected={isSelected}
        onClick={() => props.handleAccountSwitch && props.handleAccountSwitch(props.account.Id)}
        isMobile={props.isMobile}
      >
        {props.isLoading ? (
          <AccountListItemAvatarPlaceholder animation="wave" variant="rect" rounded={4} />
        ) : (
          <AccountListItemAvatar
            variant="rounded"
            alt={props.account.Name}
            src={staticData ? staticData.Account.Account_default_icon : ""}
          />
        )}
        <AccountListItemName
          isLoading={props.isLoading}
          isSelected={isSelected}
          accountName="Personal"
          isMobile={props.isMobile}
          isBusiness={props.isBusiness}
        />
        {props.isMobile ? (
          renderMobileSelected()
        ) : (
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={onAddHandle}
            style={{ marginLeft: "auto" }}
          >
            Account
          </Button>
        )}
      </AccountListItemStyled>
    );
  }

  return (
    <AccountListItemStyled
      button
      selected={isSelected}
      onClick={() => props.handleAccountSwitch && props.handleAccountSwitch(props.account.Id)}
      isMobile={props.isMobile}
    >
      {props.isLoading ? (
        <AccountListItemAvatarPlaceholder animation="wave" variant="circle" />
      ) : (
        <AccountListItemAvatar
          variant="circular"
          alt={props.account.Name}
          src={
            image ? composeImageUrl(image?.Id, image?.ContainerName, image?.ContainerId) : undefined
          }
        >
          {image ? null : (
            <AccountListLetterInAvatar align="center">
              {props.account.Name.charAt(0)}
            </AccountListLetterInAvatar>
          )}
        </AccountListItemAvatar>
      )}

      <AccountListItemName
        isLoading={props.isLoading}
        isSelected={isSelected}
        accountName={props.account.Name}
        isMobile={props.isMobile}
        isBusiness={props.isBusiness}
      />
      {props.isMobile && renderMobileSelected()}
    </AccountListItemStyled>
  );
};

export default AccountListItem;
