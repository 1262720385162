import { AssetType } from "./AssetTypeModel";
import { DEFAULT_ICON } from "../../../_constant/Icons";
import { Image } from "./ImageModel";

export class AssetRefsModel {
  AssetTypeRef: AssetType;
  Id: string;
  Name: string;
  Description: string;
  MainImage: Image | null;
  HasActivities: boolean;
  CustomerId: string | null;
  DefaultDescription: string | null;
  Tags: string[] | null;

  constructor({
    AssetTypeRef = { Id: "", Name: "", IconName: "" },
    Id = "",
    Name = "",
    Description = "",
    MainImage = new Image(),
    HasActivities = false,
    CustomerId = null,
    DefaultDescription = "",
    Tags = [],
  } = {}) {
    this.AssetTypeRef = {
      Id: AssetTypeRef.Id,
      Name: AssetTypeRef.Name,
      IconName: AssetTypeRef.IconName || DEFAULT_ICON,
    };
    this.Id = Id;
    this.Name = Name;
    this.Description = Description || DefaultDescription || "";
    this.DefaultDescription = DefaultDescription || "";
    this.MainImage = MainImage;
    this.HasActivities = HasActivities || false;
    this.CustomerId = CustomerId ?? null;
    this.Tags = Tags || [];
  }
}
