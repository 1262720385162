import { isCompletedStatus, isRecurring } from "../_utils/utils";
import moment from "moment";
import {
  createQueryOptions,
  RelatedActivitiesCacheUpdate,
} from "../containers/assets/_components/relaitedActivities/RelatedActivitiesCacheUpdate";
import {
  CHANGE_STATUS,
  GET_ACTIVITIES_LIST,
} from "../containers/activities/all/activities-list.query";
import {
  AllActivitiesCacheUpdate,
  turnOffLoadingInActivityCache,
} from "../containers/activities/all/AllActivitiesCacheUpdate";
import { ACTIVITY_PATCH } from "../_constant/wordings";
import {
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../redux/actions/_utils";
import { NotificationTypes } from "../_constant/NotificationTypes";
import { useMutation } from "react-apollo";
import useGetAcitvitiesStatuses from "./api/useGetAcitvitiesStatuses";
import { FILTERING_DETAILS } from "../_constant/ActivityDateConstant";
import { getActivityFilters } from "../redux/reducers/appReducer";
import { useSelector } from "react-redux";
import useGetAccountId from "./useGetAccountId";
import { ActivityModelShort } from "../redux/models/data/ActivityModelShort";

interface updateActivityStatusProps {
  activity: ActivityModelShort;
  currentStatusId: string;
  nextStatusId: string;
  isDesktop: boolean;
}

const useChangeVisitActivityStatus = () => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];

  const props = useSelector((state) => {
    return {
      getFilterString: (status) => {
        const filterObj = {
          id: status.Id,
          name: status.Name,
          offset: getActivityFilters(state).get(status.Id) || offset(status),
        };
        return getFilterString(filterObj);
      },
    };
  });
  const { accountId } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();
  const [updateStatus, { client, loading, data, error }] = useMutation(CHANGE_STATUS);

  const updateActivityStatus = ({
    activity,
    currentStatusId,
    nextStatusId,
    isDesktop,
  }: updateActivityStatusProps) => {
    if (!accountId || currentStatusId === nextStatusId) return;
    const filterStringFrom = props.getFilterString(
      statuses.find((element) => element.Id === activity.StatusId),
    );
    const filterStringTo = props.getFilterString(
      statuses.find((element) => element.Id === nextStatusId),
    );
    const CompletedDateTime = isCompletedStatus(statuses, nextStatusId)
      ? moment().toISOString()
      : null;

    const recurring = isRecurring({
      schedule: activity.Schedule,
      relatedDueDate: activity.RelatedDueDateTime,
    });
    const relatedActivityRefetchOptions = createQueryOptions(
      accountId,
      activity.AssetId,
      statuses[0].Id,
    );

    const refetchQueries: any =
      nextStatusId === statuses[statuses.length - 1].Id && recurring
        ? [
            {
              query: GET_ACTIVITIES_LIST,
              variables: {
                Id: accountId,
                Filter: props.getFilterString(statuses[0]),
              },
            },
          ]
        : [];
    try {
      if (client?.cache.readQuery(relatedActivityRefetchOptions)) {
        refetchQueries.push(relatedActivityRefetchOptions);
      }
    } catch (e) {}

    updateStatus({
      variables: {
        AccountId: accountId,
        AssetId: activity.AssetId,
        Id: activity.Id,
        StatusId: nextStatusId,
        CompletedDateTime,
      },
      update: (cache, { data: { AssetActivityPatch } }) => {
        AllActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPatch,
          filterStringFrom,
          filterStringTo,
          accountId,
        );
        RelatedActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPatch,
          accountId,
          nextStatusId,
          CompletedDateTime,
        );
      },
    })
      .then(({ data }) => {
        if (data[ACTIVITY_PATCH] && data[ACTIVITY_PATCH].StatusId === nextStatusId) {
          turnOffLoadingInActivityCache(client, activity, filterStringTo, accountId);
          if (isDesktop) displaySuccessNotification(NotificationTypes.ACTIVITY_STATUS_UPDATED);
        } else {
          throw "null instead of data";
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.ACTIVITY_STATUS_UPDATED_ERROR);
      });
  };

  return { updateActivityStatus, loading, data, error };
};

export default useChangeVisitActivityStatus;
