import * as React from "react";
import Select from "../../../../../../../components/Select";
import {ActivityScheduleModel} from "../../../../../../../redux/models/data/ActivityScheduleModel";
import {getWeekdays, getWeekOfMonth, incrementDateDays} from "../../../../../../../_utils/date";
import {FullActivityDueDate} from "../../../../../../../redux/models/data/ActivityModelFull";
import {optionsDaysOfWeek, optionsNumberOfWeekInMonth, RepeatAfter} from "../../../../../../../_constant/constants";
import moment from "moment";

interface DayOfWeekSelectProps {
  dueDate: string;
  initialDayOfWeek: {label: string, value: string};
  initialWeekInMonth: {label: string, value: string};
  additionalProps: ActivityScheduleModel;
  onChange: (value: FullActivityDueDate) => void;
}

const DayOfWeekSelect: React.FC<DayOfWeekSelectProps> = (props: DayOfWeekSelectProps) => {
  const {additionalProps, dueDate, initialDayOfWeek, initialWeekInMonth} = props;

  const getSecondProps = (name, dueDate) => {
    if (name === "WeekInMonth") {
      return ["DayOfWeek", initialDayOfWeek.value];
    }
    return ["WeekInMonth", optionsNumberOfWeekInMonth[getWeekOfMonth(dueDate)].value];
  };

  const handleChange = (name, nextValue, date) => {

    const schedule = new ActivityScheduleModel(additionalProps);
    const [secondPropName, secondPropVal] = getSecondProps(name, date); // second value set automatically if user select first
    schedule[name] = nextValue;
    schedule[secondPropName] = secondPropVal;
    schedule.RecurringRepeatType = RepeatAfter.DUE_DATE;
    props.onChange({dueDate: date.toISOString(), schedule});
  };

  return (
    <span className="doubled">
      <Select
        name={"WeekInMonth"}
        value={initialWeekInMonth}
        options={optionsNumberOfWeekInMonth}
        onChange={(newValue, details) => {
          const indexOfWeekDay = optionsNumberOfWeekInMonth.indexOf(newValue);
          const weekdays = getWeekdays(dueDate);
          const date = weekdays.length === indexOfWeekDay ? weekdays[indexOfWeekDay - 1] : weekdays[indexOfWeekDay];
          handleChange(details.name, newValue.value, date);
        }}
      />
      <Select
        name={"DayOfWeek"}
        value={initialDayOfWeek}
        options={optionsDaysOfWeek}
        onChange={(newValue, details) => {
          const daysDiff = optionsDaysOfWeek.indexOf(newValue) - optionsDaysOfWeek.indexOf(initialDayOfWeek);
          const newDate = moment(dueDate).isValid() ? dueDate : moment();
          const date = incrementDateDays(newDate, daysDiff);
          handleChange(details.name, newValue.value, date);
        }}
      />
    </span>
  );
};

export default DayOfWeekSelect;