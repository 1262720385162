import React from "react";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Tab } from "@material-ui/core";

export const FormControlLabelStyle = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;
    padding-left: 12px;
  }

  .MuiFormControlLabel-label {
    color: ${({ checked, theme }) =>
      checked ? theme.palette.primary.main : theme.palette.text.secondary};
  }

  @media screen and (max-width: 600px) {
    .MuiFormControlLabel-label {
      display: none;
    }
  }
`;

export const ControlContainer = styled.div`
  padding-left: 4px;
  display: flex;
`;

export const SeparatorLine = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${({theme}) => theme.palette.greyDesign.onSurface3};
  align-self: center;
`;

export const TabStyle = styled(Tab)(
  ({ hidden, theme }) => `
  display: ${hidden ? "none" : "block"}
`,
);

export const EntityTabsContainerStyle = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.MuiBox-root {
    border-bottom: var(--border-style);
  }

  .MuiTabs-root {
    padding: 0 1rem 0;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .MuiTab-root {
    min-width: fit-content;
  }
  .MuiTab-wrapper {
    text-transform: none;
    font-size: 0.75rem;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .MuiTabPanel-root {
    padding: 0;
    background-color: ${({ theme }) => theme.palette.background.default};
    flex-grow: 1;

    @media screen and (max-width: $screen-xs-min) {
      background-color: #edf3ff;
    }
  }
`;
