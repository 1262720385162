import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-apollo";

import { CURRENT_ACCOUNT } from "../_constant/wordings";

import { GlobalLoader } from "../components/Loader";
import gql from "graphql-tag";
import useGetUserId from "../customHooks/useGetUserId";

import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";
import { isDeviceRegisterInfoEnabled } from "../redux/reducers/appReducer";
import { shallowEqual } from "react-redux";

const deleteUserDevice = gql`
  mutation deleteUserDevice($UserId: ID, $PushTokens: [String]) {
    UserDeviceDelete(UserId: $UserId, PushTokens: $PushTokens) {
      PushToken
      MobilePlatform
    }
  }
`;

export const LogoutRoute = () => {
  const { logout } = useAuth0();
  const userId = useGetUserId();
  const isInfoEnabled = useSelector(isDeviceRegisterInfoEnabled, shallowEqual);

  const [removeUserDevice] = useMutation(deleteUserDevice);
  React.useEffect(() => {
    localStorage.removeItem(CURRENT_ACCOUNT);
    localStorage.removeItem("isUserDevicePatched");
    if (userId && window.pushConfig && window.pushConfig.token) {
      removeUserDevice({
        variables: {
          UserId: userId,
          PushTokens: [window.pushConfig.token],
        },
      })
        .then(({ errors }) => {
          if (errors) {
            if (isInfoEnabled) toastr.error("Something went wrong");
          } else {
            if (isInfoEnabled) toastr.success("Token has been sent");
          }
        })
        .then(() => {
          logout({ returnTo: window.location.origin });
        });
    } else {
      logout({ returnTo: window.location.origin });
    }
  }, []);
  return <GlobalLoader />;
};
