import gql from "graphql-tag";

export const GET_DETAILED_ITEM_TYPE_LIST = gql`
  query getAssetTypeList($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetTypes {
        Id
        Name
        Brands
        IconName
        IsSystem
        SortOrder
      }
    }
  }
`;
