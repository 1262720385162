import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const StyledHeaderMobileTitle = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 500;
`;

export const HeaderContainer = styled.div`
  @media print {
    display: none;
  }
`;
