import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { useConnectionState } from "../../customHooks/useConnectionState";

import { isEditPage, isPageWithoutSideMenu } from "../../redux/actions/updateData";
import { LeaveDialogViaRedirect } from "../../containers/_common/Dialog";

import Hidden from "@material-ui/core/Hidden";

import FilterButton from "../FIlterButton/FilterButton";
import SettingsButton from "../SettingsButton/SettingsButton";
import HeaderOptionsProvider from "../HeaderOptionsProvider/HeaderOptionsProvider";
import NoInternetScreen from "../NoInternetScreen/NoInternetScreen";
import Header from "../../containers/_common/header/Header";
import SideMenu from "../../containers/_common/filter/SideMenu";
import SettingsDrawer from "../SettingsDrawer/SettingsDrawer";
import MobileMenu from "../MobileMenu";

import "./MainWrapper.scss";

const MainWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const { isConnectionLost } = useConnectionState();

  const [filterOpen, setFilterOpen] = useState(false);
  const [settingsBarOpen, setSettingsBarOpen] = useState(false);

  const renderMenuButton = (pathname: string) => {
    if (isEditPage(pathname)) {
      return null;
    }

    if (pathname.includes("/settings")) {
      return <SettingsButton expanded={settingsBarOpen} setExpanded={setSettingsBarOpen} />;
    }

    return <FilterButton filterOpen={filterOpen} setFilterOpen={setFilterOpen} />;
  };

  const renderSidebar = (pathname: string): React.ReactNode | null => {
    if (pathname.includes("settings")) {
      return <SettingsDrawer expanded={settingsBarOpen} setExpanded={setSettingsBarOpen} />;
    }
    if (isEditPage(pathname)) {
      return null;
    }
    if (isPageWithoutSideMenu(pathname)) {
      return null;
    }
    return (
      <>
        <Hidden xsDown implementation="css">
          <SideMenu subMenuExpanded={filterOpen} />
        </Hidden>
        <Hidden smUp>
          <MobileMenu />
        </Hidden>
      </>
    );
  };
  return (
    <div className="MainWrapper">
      <HeaderOptionsProvider>
        <LeaveDialogViaRedirect />
        <Header withNavigation buttonSection={() => renderMenuButton(location.pathname)} />
        {renderSidebar(location.pathname)}
        <NoInternetScreen isConnectionLost={isConnectionLost} />
        {!isConnectionLost && children}
      </HeaderOptionsProvider>
    </div>
  );
};

export default MainWrapper;
