import React from "react";
import { Hidden, Box, ListItemIcon, ListItemText } from "@material-ui/core";
import { FileCopyOutlined, MoreVert } from "@material-ui/icons";

import { VendorDetailsProps } from "../VendorList.types";

import {
  CopyButton,
  StyledIconButton,
  StyledMenu,
  StyledMenuItem,
  StyledPopover,
} from "../VendorsList.style";
import { conformToMask } from "react-text-mask";
import { PHONE_CARD_MASK } from "../../../_utils/regex";

export const VendorDetails: React.FC<VendorDetailsProps> = ({
  menuItems,
  anchorEl,
  dataTitle,
  vendorTitle,
  copyToClipboard,
  disableButton,
  handleClick,
  handleClose,
}) => {
  return (
    <>
      <Hidden xsDown>
        <Box display="flex">
          {menuItems.map(({ id, icon, text }) => (
            <StyledIconButton
              disabled={disableButton(id)}
              data-title={
                id === `${vendorTitle}-2`
                  ? conformToMask(text, PHONE_CARD_MASK).conformedValue
                  : text
              }
              color="primary"
              onClick={handleClick}
              key={id}
              id={id}
            >
              {icon}
            </StyledIconButton>
          ))}
          <StyledPopover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box display="flex" alignItems="center">
              <span>{dataTitle}</span>
              <StyledIconButton color="primary" onClick={copyToClipboard}>
                <FileCopyOutlined />
              </StyledIconButton>
            </Box>
          </StyledPopover>
        </Box>
      </Hidden>
      <Hidden smUp>
        <StyledIconButton color="primary" onClick={handleClick}>
          <MoreVert />
        </StyledIconButton>
        <StyledMenu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
          {menuItems.map(({ id, text, icon }) => (
            <StyledMenuItem disabled={disableButton(id)} disableRipple key={id}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={
                  id === `${vendorTitle}-2` && text !== "Phone"
                    ? conformToMask(text, PHONE_CARD_MASK).conformedValue
                    : text
                }
              />
              <CopyButton onClick={(e) => copyToClipboard(e, id)}>
                <FileCopyOutlined />
              </CopyButton>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Hidden>
    </>
  );
};
