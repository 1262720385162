import styled from "styled-components";
import List from "@material-ui/core/List/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItem } from "@material-ui/core";

export const ListStyled = styled(List)`
  width: 180px;
`;

export const ListItemStyled = styled(ListItem)`
  padding: 0.5rem 1rem;
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
`;

export const ListItemTextStyled = styled(ListItemText)`
  .MuiListItemText-root {
    font-size: 1rem;
  }
`;
