import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { VendorModel } from "../../redux/models/data/VendorModel";

import { VENDORS } from "../../_constant/wordings";
import { getVendorsQuery } from "../../components/AllVendorsWrapper/getVendors.query";

export const updateCacheWithNewVendor = (
  cache: DataProxy,
  vendor: VendorModel,
  accountId: string,
) => {
  try {
    const vendorListQuery: any = cache.readQuery({
      query: getVendorsQuery,
      variables: { AccountId: accountId },
    });

    const newVendor: any = { ...vendor };
    delete newVendor["AccountId"];

    const newVendorList = vendorListQuery[VENDORS].concat([newVendor]);

    cache.writeQuery({
      query: getVendorsQuery,
      data: { Vendors: newVendorList },
      variables: { AccountId: accountId },
    });
  } catch (e) {
    console.error(e);
  }
};
