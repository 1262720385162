import React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";

const InputAdornmentContainer = styled.div`
  display: flex;
  align-items: center;

  .MuiDivider-root {
    margin: 0 16px;
  }
`;

interface InputAdornmentProps {
  onClick: () => void;
  withResetButton: boolean;
}

const InputAdornment: React.FC<InputAdornmentProps> = ({ onClick, withResetButton }) => {
  return withResetButton ? (
    <InputAdornmentContainer>
      <IconButton size="small" onClick={onClick}>
        <CloseIcon style={{ color: "var(--color-gray)" }} />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <SearchOutlinedIcon />
    </InputAdornmentContainer>
  ) : (
    <SearchOutlinedIcon />
  );
};

export default InputAdornment;
