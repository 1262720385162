import React from "react";
import styled, { css } from "styled-components";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`;

const CardContainerLink = styled.a`
  ${containerStyle}

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const CardContainer = styled.div`
  ${containerStyle}
`;

interface DropZoneCardContainerProps {
  dataOrder?: number;
  url?: string | null;
  onClick: () => void;
}

const DropZoneCardContainer: React.FC<DropZoneCardContainerProps> = ({
  children,
  url,
  ...props
}) => {
  if (url) {
    return (
      <CardContainerLink href={url} target="_blank" {...props}>
        {children}
      </CardContainerLink>
    );
  }
  return <CardContainer {...props}>{children}</CardContainer>;
};

export default DropZoneCardContainer;
