import styled, { css } from "styled-components";
import { TabPanel } from "@material-ui/lab";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Dialog from "@material-ui/core/Dialog";

export const TabContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 325px;
  min-width: 310px;
  min-height: 305px;
  overflow-x: hidden;
`;

export const TabPannelStyled = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0;
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
`;

export const FooterBtn = styled(Button)`
  /* flex-grow: 1; */

  .MuiButton-label {
    text-transform: uppercase;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:first-child {
    .MuiButton-label {
      text-transform: capitalize;
    }
  }
`;

export const TabContainer = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%);

  .MuiTabs-indicator {
    height: 3px;
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const iconCommonStyle = css`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
export const DateRangeIconStyle = styled(DateRangeIcon)`
  ${iconCommonStyle}
`;
export const ScheduleIconStyle = styled(ScheduleIcon)`
  ${iconCommonStyle}
`;

export const DialogStyle = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
  }
`;
