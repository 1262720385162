import * as React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import EditProfilePage from "../../containers/profile/EditProfilePage";
import Accounts from "../Accounts/Accounts";
import Notifications from "../Notifications/Notifications";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import About from "../About/About";
import BusinessRoutes from "../../routing/BusinessRoutes";
import AccountRoutes from "../../routing/AccountRoutes";
import { ROUTES } from "../../_constant/screens";
import CalendarFeeds from "../CalendarFeeds/CalendarFeeds";

const Settings = () => {
  useHeaderOptions({ withSettingsNav: true, withBackButton: true });

  return (
    <Routes>
      <Route path="/" element={<Navigate to={ROUTES.EDIT_PROFILE} />} />
      <Route path="accounts" element={<Accounts />} />
      <Route path="profile" element={<EditProfilePage />} />
      <Route path="notifications" element={<Notifications />} />
      <Route path="business/*" element={<BusinessRoutes />} />
      <Route path="account/*" element={<AccountRoutes />} />
      <Route path="about" element={<About />} />
      <Route path="calendar-feeds" element={<CalendarFeeds />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default Settings;
