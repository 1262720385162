import React, { ChangeEvent, useMemo } from "react";

import Grid from "@material-ui/core/Grid/Grid";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import Input from "../../components/Input/Input";
import InputRow from "../../components/InputRow/InputRow";

import useGetAccountId from "../../customHooks/useGetAccountId";

import CompletedDateComponent from "../../containers/activities/_components/date/completed/CompletedDateComponent";
import DueDateWithoutReccuring from "../../containers/activities/_components/date/due_date/DueDateWithoutReccuring";
import DateRowLayout from "../DateRowLayout/DateRowLayout";

import AssignUserSelect from "../AssignUserSelect/AssignUserSelect";
import StatusSelect from "./StatusSelect";

import { ProjectModel } from "../../redux/models/data/ProjectModel";

interface ProjectFormFieldsLayoutProps {
  data: ProjectModel;
  handleChange: (e: ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  errorFields: Map<string, string>;
}

const ProjectFormFieldsLayout: React.FC<ProjectFormFieldsLayoutProps> = (props) => {
  const { data, handleChange } = props;

  const { isPersonal } = useGetAccountId();

  const nameInput = useMemo(
    () => (
      <Input
        type="text"
        onChange={handleChange}
        value={data.Name}
        label={"Name"}
        id={"Name"}
        fullWidth
        icon={<CreateOutlinedIcon />}
        required
        helperText="* Required field"
        errorText={props.errorFields.get("Name")}
        error={!!props.errorFields.get("Name")}
      />
    ),
    [data.Name],
  );

  const notesInput = useMemo(
    () => (
      <Input
        onChange={handleChange}
        value={data.Description}
        label={"Notes"}
        id={"Description"}
        fullWidth
        icon={<DescriptionOutlinedIcon />}
        multiline
        maxLength={1000}
      />
    ),
    [data.Description],
  );

  return (
    <div className="Section">
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {nameInput}
          </Grid>
          {!isPersonal && (
            <Grid item xs={12} sm={6}>
              <AssignUserSelect
                assignedUserId={data.AssignedUserId}
                handleFieldChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            {notesInput}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid item xs={12} sm={6}>
          <StatusSelect
            statusId={data.ProjectStatusId}
            onChange={handleChange}
            getNewValueBasedOnAction={null}
            assignedUserId={data.AssignedUserId}
          />
        </Grid>
      </InputRow>
      <InputRow alignItems="center">
        <DateRowLayout>
          <DueDateWithoutReccuring value={data.DueDateTime} onChange={handleChange} />
          <CompletedDateComponent
            value={data.CompletedDateTime}
            onChange={handleChange}
            statusFieldName="ProjectStatusId"
            statusId={data.ProjectStatusId}
          />
        </DateRowLayout>
      </InputRow>
    </div>
  );
};

export default ProjectFormFieldsLayout;
