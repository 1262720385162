import * as React from "react";

import "./form-header.scss";
import SaveButton from "../SaveButton/SaveButton";
import { Typography } from "@material-ui/core";

interface IFormHeaderProps {
  isNew: boolean;
  title: string;
  titlePrefix?: string;
  isLoading: boolean;
  isDataChanged: boolean;
  isSaveAvailable: boolean;
  isBusinessAccount: boolean;
  cancelRouteForEmptyHistory: string;
  onCancel?: () => void;
  revertData: () => void;
  handleSubmit: (e: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
}

const FormHeader: React.FC<IFormHeaderProps> = (props) => {
  const {
    isNew,
    title,
    handleSubmit,
    isLoading,
    revertData,
    isSaveAvailable,
    isBusinessAccount,
    titlePrefix,
    cancelRouteForEmptyHistory,
    isDataChanged,
  } = props;

  const getFormTitle = () => {
    if (isBusinessAccount) {
      return "Customer Management";
    }

    if (titlePrefix) {
      return `${titlePrefix} ${title}`;
    }

    return `${isNew ? "Create New" : "Edit"} ${title}`;
  };

  return (
    <div className="FormHeader">
      <div className="FormHeader__title">{getFormTitle()}</div>
      <div className={"FormHeader__buttons"}>
        <SaveButton
          saveAndClose={handleSubmit}
          isLoading={isLoading}
          revertData={revertData}
          isSaveAvailable={isSaveAvailable}
          isNew={isNew}
          cancelRouteForEmptyHistory={cancelRouteForEmptyHistory}
          isDataChanged={isDataChanged}
        />
      </div>
    </div>
  );
};

export default FormHeader;
