import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import Combobox from "../../../../components/Combobox/Combobox";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import { ACCOUNT, ITEMS, PARENT_ID } from "../../../../_constant/wordings";
import { ReactComponent as ClipPartIcon } from "../../../../../images/ic_clip.svg";
import { GET_ASSETS_FOR_SELECT } from "./parent-asset.query";
import { alphabetNameSorting } from "../../../../_utils/utils";

interface ParentAssetSelectProps {
  ParentId: string | null;
  handleFieldChange: (target: any, {}) => void;
  currentAssetId: string;
  isLoading: boolean;
  isNew: boolean;
}

const ParentAssetSelect: React.FC<ParentAssetSelectProps> = (props) => {
  const { ParentId, handleFieldChange, currentAssetId, isLoading, isNew } = props;

  const [optionsForSelect, setOptionsForSelect] = useState<[] | { value: string; label: string }[]>(
    [],
  );

  const { accountId } = useGetAccountId();
  const { data: itemsData, loading: itemsLoading, error: itemsError } = useQuery(
    GET_ASSETS_FOR_SELECT,
    {
      variables: {
        Id: accountId,
      },
      skip: !accountId,
      fetchPolicy: "cache-first",
    },
  );

  useEffect(() => {
    if (!itemsLoading && itemsData && !itemsError) {
      let assetsDataForSelect = [];

      if (isNew) {
        assetsDataForSelect = itemsData[ACCOUNT][ITEMS].map(
          (item: { Id: string; Name: string }) => ({
            value: item.Id,
            label: item.Name,
          }),
        );
      } else if (currentAssetId) {
        assetsDataForSelect = itemsData[ACCOUNT][ITEMS].filter(
          ({ Id }: { Id: string }) => Id !== currentAssetId,
        )
          .filter(({ ParentId }: { ParentId: string }) => ParentId !== currentAssetId)
          .sort(alphabetNameSorting)
          .map((item: { Id: string; Name: string }) => ({ value: item.Id, label: item.Name }));
      }

      setOptionsForSelect([{ value: "none", label: "None" }, ...assetsDataForSelect]);
    }
  }, [itemsData, itemsLoading, itemsError, currentAssetId]);

  const getNewValueBySelect = (e, newValue: { id: string; value: any }) => {
    const { id, value } = newValue;

    if (newValue && value) {
      if (value.value === "none") {
        handleFieldChange(null, { value: null, id });
        return;
      }
      handleFieldChange(null, { value: value.value, id });
    }
  };

  const value = {
    value: ParentId ? ParentId : optionsForSelect[0]?.value,
    label: ParentId
      ? optionsForSelect.find((item) => item.value === ParentId)?.label
      : optionsForSelect[0]?.label,
  };

  return (
    <Combobox
      label="Part of"
      options={!isLoading ? optionsForSelect : []}
      handleChange={getNewValueBySelect}
      value={value}
      id={PARENT_ID}
      required={false}
      hasError={false}
      creatable={false}
      icon={<ClipPartIcon style={{ width: "16px", height: "16px" }} />}
      disableClearable
      loading={itemsLoading || isLoading}
    />
  );
};

export default ParentAssetSelect;
