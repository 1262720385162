import React from "react";

import { useMutation } from "react-apollo";

import useGetUserId from "../../customHooks/useGetUserId";

import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { Users } from "../../redux/models/data/UsersModel";

import { RemoveButton } from "../InvitationCard/RemoveButton";

import { removeUserFromAccountQuery } from "./removeUserFromAccount.query";
import { updateMembersCache } from "./updateMemberCache";

import { UserRole } from "../../_constant/constants";
import { ID, REMOVE_USER } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { composeImageUrl } from "../../_utils/utils";

import "./AccountUserItem.scss";

interface AccountUsersItemProps {
  accountUser: Users;
  accountId?: string;
  isLoading?: boolean;
  isOwner?: boolean;
}

const AccountUsersItem: React.FC<AccountUsersItemProps> = ({
  accountUser,
  isLoading,
  isOwner,
  accountId = "",
}) => {
  const { Id, FirstName, LastName, Email, MainImage, Role } = accountUser;
  const userId = useGetUserId();

  const userName =
    !FirstName && !LastName
      ? Email.substring(0, Email.indexOf("@"))
      : `${FirstName} ${LastName ?? ""}`.trim();

  const isThisUserHasAdminPermission =
    Role === UserRole.owner || Role === UserRole.admin ? "Owner" : "Member";

  const [removeUserMutation, { loading: removeLoading }] = useMutation(removeUserFromAccountQuery);

  const removeUserHandler = () => {
    const variables = { UserId: Id, AccountId: accountId };
    removeUserMutation({
      variables,
      update(cache) {
        updateMembersCache(cache, accountId, Id);
      },
    })
      .then(({ data }: any) => {
        if (data[REMOVE_USER][ID]) {
          displaySuccessNotification(NotificationTypes.USER_REMOVED);
        } else {
          displayErrorNotification(NotificationTypes.USER_REMOVE_ERROR);
        }
      })
      .catch((err) => {
        displayErrorNotification(NotificationTypes.USER_REMOVE_ERROR);
        console.error(err);
      });
  };

  return (
    <>
      <ListItem className="AccountUserItem">
        <ListItemAvatar className="AccountUserItem__ListItemAvatar">
          {isLoading ? (
            <Skeleton animation="wave" variant="circle" width={24} height={24} />
          ) : (
            <Avatar
              alt={userName}
              src={
                MainImage
                  ? composeImageUrl(MainImage.Id, MainImage.ContainerName, MainImage.ContainerId)
                  : ""
              }
              className="AccountUserItem__Avatar"
            >
              {FirstName ? FirstName.charAt(0) : userName.charAt(0)}
            </Avatar>
          )}
        </ListItemAvatar>
        {isLoading ? (
          <Skeleton animation="wave" height={12} width="100%" />
        ) : (
          <>
            <ListItemText primary={userName} className="AccountUserItem__text" />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              className="AccountUserItem__action"
            >
              <Box display={{ xs: "none", md: "flex" }}>
                <Typography variant="caption" className="AccountUserItem__secondaryText">
                  {/*Invited By*/}
                  {isThisUserHasAdminPermission}
                </Typography>
              </Box>
              {isOwner && (
                <RemoveButton
                  onClick={removeUserHandler}
                  loading={removeLoading}
                  disabled={Id === userId}
                />
              )}
            </Box>
          </>
        )}
      </ListItem>
      <Divider />
    </>
  );
};

export default AccountUsersItem;
