import { ActivityScheduleModel } from "./ActivityScheduleModel";
import { formatDateWithoutTZ, getDateWithUpdatedTime } from "../../../_utils/date";
import { EndAfter } from "../../../_constant/constants";
import { Image } from "./ImageModel";
import { ActivityAffiliateModel } from "./ActivityAffiliateModel";
import { getAffiliatesList } from "../../../_utils/utils";
import { FieldsSchemaTypes } from "./AssetModel";
import { VendorRefInputType } from "./VendorModel";

export interface FullActivityDueDate {
  dueDate?: string | null;
  relatedDueDate?: string[];
  schedule?: ActivityScheduleModel;
  endRecurring?: EndAfter;
}

export class ActivityModel {
  AssetId?: string;
  Id?: string;
  Name?: string;
  Description?: string;
  Images?: Image[];
  Documents?: any[];
  Videos?: any[];
  Files?: any;
  StatusId?: string;
  RelatedDueDateTime?: string[];
  Schedule?: ActivityScheduleModel;
  CompletedDateTime?: string | null;
  DueDateTime?: string | null;
  isLoading?: boolean;
  Affiliates?: ActivityAffiliateModel[];
  CustomFields?: {};
  Schema?: FieldsSchemaTypes[];
  AssignedUserId?: string | null;
  VendorRefs?: VendorRefInputType[];
  Asset: any;

  constructor({
    AssetId = "",
    Id = "",
    Name = "",
    Description = "",
    Images = [] as Image[],
    Documents = [] as any,
    Videos = [] as any,
    Files = [] as any,
    StatusId = "",
    RelatedDueDateTime = [] as string[],
    Schedule = new ActivityScheduleModel(),
    DueDateTime = "" as string | null,
    CompletedDateTime = "" as string | null,
    isLoading = false,
    Affiliates = [] as ActivityAffiliateModel[],
    CustomFields = {},
    Schema = [] as FieldsSchemaTypes[],
    AssignedUserId = null as string | null,
    VendorRefs = [] as VendorRefInputType[],
  } = {}) {
    this.Id = Id;
    this.Name = Name;
    this.AssetId = AssetId || "";
    this.Description = Description || "";
    this.Images = Images || [];
    this.Documents = Documents || [];
    this.Videos = Videos || [];
    this.Files = Files || [];
    this.StatusId = StatusId || "";
    this.Schedule = Schedule ? new ActivityScheduleModel(Schedule) : new ActivityScheduleModel();
    this.RelatedDueDateTime = RelatedDueDateTime || [];
    this.DueDateTime = DueDateTime ? formatDateWithoutTZ(DueDateTime) : null;
    this.CompletedDateTime = CompletedDateTime ? getDateWithUpdatedTime(CompletedDateTime) : null;
    this.isLoading = isLoading || false;
    this.Affiliates = Affiliates.length === 0 ? [] : getAffiliatesList(Affiliates);
    this.CustomFields = CustomFields || {};
    this.Schema = Schema || [];
    this.AssignedUserId = AssignedUserId || null;
    this.VendorRefs = VendorRefs || [];
  }
}
