import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_USER_SUBSCRIPTION_TIER = gql`
  query getUserSubscriptionTier {
    User {
      Id
      SubscriptionSettings {
        SubscriptionTier
      }
    }
  }
`;

const FREE = "FREE";
const PREMIUM = "PREMIUM";
const PROFESSIONAL = "PROFESSIONAL";

export const subscriptionValues = {
  free: FREE as typeof FREE,
  premium: PREMIUM as typeof PREMIUM,
  professional: PROFESSIONAL as typeof PROFESSIONAL,
};

type subscriptionTypes = typeof subscriptionValues[keyof typeof subscriptionValues];

type useGetUserSubscriptionTierReturn = {
  value: subscriptionTypes | null;
  isFree: boolean;
  isPremium: boolean;
  isProfessional: boolean;
  loading: boolean;
};

export const useGetUserSubscriptionTier = (): useGetUserSubscriptionTierReturn => {
  const { data, loading, error } = useQuery(GET_USER_SUBSCRIPTION_TIER, {
    fetchPolicy: "cache-first",
  });

  const subscriptionTier = data?.User?.SubscriptionSettings?.SubscriptionTier;

  if (data && !loading && !error) {
    return {
      value: subscriptionTier,
      isFree: subscriptionTier === subscriptionValues.free,
      isPremium: subscriptionTier === subscriptionValues.premium,
      isProfessional: subscriptionTier === subscriptionValues.professional,
      loading,
    };
  }

  return { value: null, isFree: false, isPremium: false, isProfessional: false, loading };
};
