import gql from "graphql-tag";

export const getNotificationsQuery = gql`
  query getNotifications($UserId: ID, $AccountId: ID) {
    AccountNotificationSettingModel(UserId: $UserId, AccountId: $AccountId) {
      Id
      UserId
      AccountId
      DayOfWeek
      Time
      IsDeleted
      Type
      Frequency
      NotificationChannel {
        Destination
        Source
      }
    }
  }
`;

export const postNotificationSettings = gql`
  mutation PostNotificationSettings($Setting: NotificationSettingInput!) {
    NotificationSettingsPost(NotificationSetting: [$Setting]) {
      Id
      UserId
      AccountId
      DayOfWeek
      IsEnabled
      IsDeleted
      Time
      Type
      Frequency
      NotificationChannel {
        Destination
        Source
      }
    }
  }
`;

export const setTypeNotificationSettings = gql`
  mutation NotificationSettingsPatch($Setting: NotificationSettingInput!) {
    NotificationSettingsPatch(NotificationSetting: [$Setting]) {
      Id
      Type
      Frequency
    }
  }
`;

export const setTimeNotificationSettings = gql`
  mutation NotificationSettingsPatch($Setting: NotificationSettingInput!) {
    NotificationSettingsPatch(NotificationSetting: [$Setting]) {
      Id
      Time
      DayOfWeek
      Frequency
    }
  }
`;

export const setChannelNotificationSettings = gql`
  mutation NotificationSettingsPatch($Setting: InputAccountNotificationSetting!) {
    NotificationSettingsPatch(NotificationSettings: [$Setting]) {
      Id
      NotificationChannel {
        Destination
        Source
      }
    }
  }
`;

export const deleteNotification = gql`
  mutation DeleteNotification($Setting: NotificationSettingInput!) {
    NotificationSettingsPatch(NotificationSetting: [$Setting]) {
      Id
      IsDeleted
    }
  }
`;
