import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useGetLimits } from "../../customHooks/useGetLimits";
import { useChange } from "../../customHooks/useChange";

import { CustomerModel } from "../../redux/models/data/CustomersModel";
import { Image } from "../../redux/models/data/ImageModel";

import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";

import { CustomerFormContextProvider } from "../CustomerForm/CustomerFormContext";

import { validateEmailField } from "../../_utils/utils";
import { displayWarningNotification } from "../../redux/actions/_utils";
import { conformToMask } from "react-text-mask";

import { PHONE_INPUT_MASK } from "../../_utils/regex";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import DropZoneContainer from "../DropZoneContainer/DropZoneContainer";
import CustomerFormDesktop from "../CustomerForm/CustomerFormDesktop";
import CustomerFormMobile from "../CustomerForm/CustomerFormMobile";

interface CustomerFormContainerProps {
  handleSubmit: (data: CustomerModel, saveType: SaveType) => void;
  isLoading: boolean;
  isNew: boolean;
  data: CustomerModel;
  setHeaderOptions: (props: any) => void;
}

const CustomerFormContainer: React.FC<CustomerFormContainerProps> = ({
  handleSubmit: submitFunction,
  isLoading,
  isNew,
  data: propsData,
  setHeaderOptions,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const limits = useGetLimits();

  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const checkEmailField = (fieldName: string, value: string, errorMap: Map<string, string>) => {
    if (value.length > 0) {
      return validateEmailField(fieldName, value, errorMap);
    } else {
      if (errorMap.has(fieldName)) {
        errorMap.delete(fieldName);
      }
      return [errorMap, false];
    }
  };

  const checkPhoneField = (fieldName: string, value: string, errorMap: Map<string, string>) => {
    const newMap = new Map(errorMap);
    const onlyDigitFromPhone =
      value && value.length ? Number(value.replace(/\D+/g, "")).toString() : "";

    if (onlyDigitFromPhone.length > 0 && onlyDigitFromPhone.length !== 10) {
      newMap.set(fieldName, "Phone number must contain 10 digits");
      return [newMap, true];
    } else {
      newMap.delete(fieldName);
      return [newMap, newMap.size === 0];
    }
  };

  const formInitObject = {
    initData: propsData ? propsData : new CustomerModel(),
    classModel: CustomerModel,
    requiredFields: ["Name"],
    fieldsForValidation: new Map([
      ["Name", null],
      ["Email", checkEmailField],
      ["Phone", checkPhoneField],
    ]),
  };
  const formApi = useChange<CustomerModel>(formInitObject);
  const data = formApi.data;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;

    if (formApi.data?.[id]?.length > 0) {
      formApi.validateFields();
      id === "Email"
        ? setEmailError(formApi.errorFields.get(id) || "")
        : setPhoneError(formApi.errorFields.get(id) || "");
    } else {
      formApi.handleChange(null, { id, value: "" });
    }
  };

  const handleChangeWithValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    id === "Email" ? setEmailError("") : setPhoneError("");
    formApi.handleChange(e);
  };

  const lockSaveButton = formApi.isDataChanged && formApi.isDataValid;

  useEffect(() => {
    if (propsData && !isLoading) {
      const results =
        propsData.Phone && propsData.Phone.length
          ? conformToMask(propsData.Phone, PHONE_INPUT_MASK).conformedValue
          : "";
      formApi.updateInitFormData(new CustomerModel({ ...propsData, Phone: results }));
    }
  }, [propsData]);

  const handleSubmit = (e?: React.SyntheticEvent<Element, Event> | null, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: SaveType = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: () => formApi.updateInitFormData(formApi.data),
    };

    submitFunction(formApi.data, saveType);
  };

  const dropZone = React.useMemo(
    () => (
      <DropZoneContainer
        data={data}
        handleChange={formApi.handleChange}
        updateManyFields={formApi.updateManyFields}
        entityName="customer"
        isMobile={!isDesktop}
        imagesOnly={false}
        limitDocuments={limits?.customer.documents.Value}
        limitImages={limits?.customer.images.Value}
        limitVideos={limits?.customer.videos.Value}
      />
    ),
    [formApi.data.Files, formApi.data.Images, formApi.data.Documents, formApi.data.Videos],
  );

  return (
    <CustomerFormContextProvider
      isNew={isNew}
      isLoading={isLoading}
      data={propsData}
      setHeaderOptions={setHeaderOptions}
      handleSubmit={handleSubmit}
      formApi={formApi}
      formData={data}
      lockSaveButton={lockSaveButton}
      handleBlur={handleBlur}
      handleChangeWithValidation={handleChangeWithValidation}
      phoneError={phoneError}
      emailError={emailError}
      dropZone={dropZone}
    >
      {isDesktop ? <CustomerFormDesktop /> : <CustomerFormMobile />}
    </CustomerFormContextProvider>
  );
};

export default CustomerFormContainer;
