import React, { useCallback, useEffect, useState } from "react";

import { useLayoutSwitcherContext } from "../LayoutSwitcher/useLayoutSwitcherContext";
import { useGetAssetTypeDetails } from "../../customHooks/useGetAssetTypesDescriptionPattern";

import { ItemType } from "../PrintableCard/ItemPrintableCard/types";

import { NoAssetsComponent } from "../NoResultComponent/NoResultComponent";
import PrintableHeader from "../PrintableHeader/PrintableHeader";
import ItemPrintableContainer from "../PrintableCard/ItemPrintableContainer";

import { AssetListWrapper, AssetsListContainer, AssetsListStyled } from "./AssetsListStyles";
import { AssetModel } from "../../redux/models/data/AssetModel";

export interface PrintableState {
  groupByType: boolean;
  showMoreInfo: boolean;
}

interface AssetListPrintableViewProps {
  assets: AssetModel[];
}

interface AssetsMapInterface {
  [key: string]: AssetModel[];
}

const AssetListPrintableView: React.FC<AssetListPrintableViewProps> = ({ assets }) => {
  const [printableState, setPrintableState] = useState<PrintableState>({
    groupByType: false,
    showMoreInfo: false,
  });

  const [groupByType, setGroupByType] = useState([] as AssetModel[]);

  const {
    state: { items: layout },
  } = useLayoutSwitcherContext();
  useGetAssetTypeDetails();

  useEffect(() => {
    const typeMap = assets.reduce((acc, item) => {
      if (acc[item.AssetType.Id]) {
        acc[item.AssetType.Id] = [...acc[item.AssetType.Id], item];
      } else {
        acc[item.AssetType.Id] = [item];
      }
      return acc;
    }, {} as AssetsMapInterface);

    setGroupByType(Object.values(typeMap).flat());
  }, [assets]);

  const handlePrintableState = useCallback(
    (props: Partial<PrintableState>) => setPrintableState((prev) => ({ ...prev, ...props })),
    [],
  );

  const data = printableState.groupByType ? groupByType : assets;

  return (
    <>
      <PrintableHeader handleState={handlePrintableState} state={printableState} />
      <AssetsListStyled>
        <AssetListWrapper maxWidth="md">
          <AssetsListContainer $layout={layout}>
            {data.map((item: AssetModel) => (
              <ItemPrintableContainer
                key={item.Id}
                item={item}
                isOpenedMoreInfo={printableState.showMoreInfo}
              />
            ))}
            {data.length === 0 && <NoAssetsComponent />}
          </AssetsListContainer>
        </AssetListWrapper>
      </AssetsListStyled>
    </>
  );
};

export default AssetListPrintableView;
