import React, { useState } from "react";
import { CustomerModel } from "../../redux/models/data/CustomersModel";
import { dataApiInterface } from "../../customHooks/useChange";
import { Image } from "../../redux/models/data/ImageModel";

interface CustomerFormContextProviderProps {
  handleSubmit: (e?: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
  isLoading: boolean;
  isNew: boolean;
  data: CustomerModel;
  setHeaderOptions: (props: any) => void;
  lockSaveButton: boolean;
  formData: CustomerModel;
  formApi: dataApiInterface;
  handleChangeWithValidation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  phoneError: string;
  emailError: string;
  dropZone: any;
}

interface CustomerFormProps extends CustomerFormContextProviderProps {
  isCustomize: boolean;
  handleCustomize: () => void;
  handleOpenWizard: () => void;
  handleCloseWizard: () => void;
  setIsRevertPopupVisible: (value: boolean) => void;
  setIsDeletePopupVisible: (value: boolean) => void;
  isRevertPopupVisible: boolean;
  isDeletePopupVisible: boolean;
  isWizardOpen: boolean;
}

export const CustomerFormContext = React.createContext<CustomerFormProps>({} as CustomerFormProps);

export const CustomerFormContextProvider: React.FC<CustomerFormContextProviderProps> = (props) => {
  const { children, ...rest } = props;
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCustomize, setIsCustomize] = useState(false);

  // const handleOpenWizard = () => {
  //   setIsWizardOpen(true);
  // };

  // const handleCloseWizard = () => {
  //   setIsWizardOpen(false);
  // };

  const handleCustomize = () => {
    setIsCustomize((prev) => !prev);
  };

  const handleOpenWizard = React.useCallback(() => setIsWizardOpen(true), [isWizardOpen]);
  const handleCloseWizard = React.useCallback(() => setIsWizardOpen(false), [isWizardOpen]);

  return (
    <CustomerFormContext.Provider
      value={{
        ...rest,
        isCustomize,
        handleCustomize,
        isRevertPopupVisible,
        isWizardOpen,
        handleOpenWizard,
        handleCloseWizard,
        isDeletePopupVisible,
        setIsRevertPopupVisible,
        setIsDeletePopupVisible,
      }}
    >
      {children}
    </CustomerFormContext.Provider>
  );
};
