import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { StyledSkeletonList } from "./Table.styles";

interface TableCardSkeletonProps {
  count: number;
}
const TableCardSkeleton: React.FC<TableCardSkeletonProps> = ({ count }) => {
  const xsUp = useMediaQuery("(min-width:600px)", { noSsr: true });
  const theme = useTheme();

  return (
    <StyledSkeletonList>
      <Grid container>
        {new Array(count).fill(null).map((_, index) => {
          return (
            <Grid
              key={index}
              container
              item
              xs={12}
              style={{
                borderBottom: index + 1 < count ? `1px solid ${theme.palette.greyDesign.onSurface3}` : "none",
                height: 52,
              }}
            >
              <Grid container spacing={2} alignItems="center" style={{ margin: 0, width: "100%" }}>
                <Grid item xs={xsUp ? 4 : 12}>
                  <Box display="flex" alignItems="center">
                    <Skeleton
                      variant="rect"
                      width={32}
                      height={32}
                      style={{ marginRight: 8, borderRadius: 4, minWidth: 32 }}
                    />
                    <Skeleton variant="rect" width="50%" style={{ borderRadius: 4 }} />
                  </Box>
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={4}>
                    <Skeleton variant="rect" width="80%" style={{ borderRadius: 4 }} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton variant="rect" width="80%" style={{ borderRadius: 4 }} />
                  </Grid>
                  <Grid item xs={1} />
                </Hidden>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </StyledSkeletonList>
  );
};

export default TableCardSkeleton;
