import * as React from "react";
import Logo from "./Logo";
import NavFilter from "./NavFIlter/NavFilter";
import { useHeaderOptions } from "./HeaderOptionsProvider/useHeaderOptions";
import { HEADER_TYPES } from "../_constant/constants";

const MobileMenu: React.FC = () => {
  const {
    headerOptions: { filterOpen, headerType },
    setHeaderOptions,
  } = useHeaderOptions();

  if (headerType !== HEADER_TYPES.DEFAULT) return null;

  return (
    <>
      <aside className={`MobileMenu ${filterOpen ? "MobileMenu-isOpened" : ""}`}>
        <header className="MobileMenu_header">
          <Logo className="MobileMenu_logo" />
        </header>
        <NavFilter />
      </aside>
      <div
        className={`Overlay-withMobileHeader ${filterOpen ? "is-active" : ""}`}
        onClick={() => setHeaderOptions({ filterOpen: false })}
      />
    </>
  );
};

export default MobileMenu;
