import { css } from "styled-components";

export const scrollStyles = css`
  &::-webkit-scrollbar {
    position: absolute;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-m);
    background-color: var(--color-brand);
  }
`;
