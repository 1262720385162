import styled from "styled-components";
import AssignUserSelect from "../AssignUserSelect/AssignUserSelect";

export const AssignUserInsideCard = styled.div`
  // margin-bottom: 6px;

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputLabel-root {
    display: none;
  }

  .MuiInput-formControl {
    margin-top: 0;
  }

  .InputField__icon_withPadding.MuiGrid-root {
    padding-top: 6px;
  }
`;
