import * as Actions from "./actionTypes";

//________________________
export const updateClientDetails = (props) => ({
  type: Actions.UPDATE_CLIENT_INFO,
  props,
});

export const updateActivitiesLayout = (props) => ({
  type: Actions.UPDATE_ACTIVITIES_LAYOUT,
  props,
});

export const updateGlobalLoaderState = (props) => ({
  type: Actions.GLOBAL_INFO_UPDATED,
  props,
});

export const updatePageForRedirection = (props = window.location.pathname) => ({
  type: Actions.UPDATE_REDIRECTION_PAGE,
  props,
});

export const showOnLeavePopup = (props: boolean) => ({
  type: Actions.SHOW_LEAVING_POPUP,
  props,
});

export const setEditFormIsDirty = (props: boolean) => ({
  type: Actions.UPDATE_IS_DIRTY_PROPERTY,
  props,
});

export const updateActivityFilter = (props?) => ({
  type: Actions.UPDATE_ACTIVITY_FILTER,
  props,
});

export const updateActivityEmptyStatus = (status: string, add: boolean) => ({
  type: Actions.UPDATE_ACTIVITIES_EMPTY_STATUS,
  props: { status, add },
});

export const updateVisitEmptyStatus = ({
  status,
  add,
  reset,
}: {
  status?: string;
  add?: boolean;
  reset?: boolean;
}) => ({
  type: Actions.UPDATE_VISITS_EMPTY_STATUS,
  props: { status, add, reset },
});

export const updateProjectsEmptyStatus = ({
  status,
  add,
  reset,
}: {
  status?: string;
  add?: boolean;
  reset?: boolean;
}) => ({
  type: Actions.UPDATE_PROJECTS_EMPTY_STATUS,
  props: { status, add, reset },
});

// Assets actions
export const setSelectedAssets = (assetType?: string, assets?: Set<string>, relatedIds?) => ({
  type: Actions.SELECT_ASSETS_TO_DISPLAY,
  props: { assetType, assets, relatedIds },
});

// Search action
export const setSearchString = (props: string) => ({
  type: Actions.UPDATE_SEARCH_ACTIVITIES_QUERY,
  props,
});

export const setSearchTagsArray = (props: string[]) => ({
  type: Actions.UPDATE_SEARCH_ASSET_TAGS_QUERY,
  props,
});

// Filter action
export const setActivitiesFilterValue = (filterValue: string) => ({
  type: Actions.SET_ACTIVITIES_FILTER_VALUE,
  props: filterValue,
});

export const setCustomerId = (customerId: string) => ({
  type: Actions.SET_CUSTOMER_ID,
  props: customerId,
});

export const setMemberId = (memberId: string) => ({
  type: Actions.SET_MEMBER_ID,
  props: memberId,
});

export const setConnectionState = (flag: boolean) => ({
  type: Actions.UPDATE_CONNECTION_STATE,
  props: flag,
});
