import { useContext } from "react";
import { DropZoneContext, DropZoneContextTypes } from "./DropZoneContainer";

export const useDropZoneContext = () => {
  const context = useContext(DropZoneContext);

  if (context === undefined) {
    throw new Error("useDropZoneContext must be used within a DropZoneContainer");
  }

  return { ...context } as DropZoneContextTypes;
};
