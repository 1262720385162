import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMutation, useQuery } from "react-apollo";
import ActivityForm from "../_components/ActivityForm";
import { ActivityModel } from "../../../redux/models/data/ActivityModelFull";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import {
  ACCOUNT,
  ACTIVITY_PUT,
  FILE_UPLOAD_RESULTS,
  ID,
  ITEM_ACTIVITIES,
} from "../../../_constant/wordings";
import { getEssentialActivityFieldsQuery, updateActivityQuery } from "./activity.query";
import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../../../redux/actions/_utils";
import { ROUTES } from "../../../_constant/screens";
import { toastr } from "react-redux-toastr";
import { NotificationTypes, SUCCESS_NOTIFICATIONS } from "../../../_constant/NotificationTypes";
import { setEditFormIsDirty, setSelectedAssets } from "../../../redux/actions/actions";
import {
  RelatedActivitiesCacheUpdate,
  updateRelatedActivitiesOfOldItem,
  updateRelatedActivitiesOfNewItem,
  changeParentItemForCustomerActivity,
} from "../../assets/_components/relaitedActivities/RelatedActivitiesCacheUpdate";
import { AllActivitiesCacheUpdate } from "../all/AllActivitiesCacheUpdate";
import { FILTERING_DETAILS } from "../../../_constant/ActivityDateConstant";
import { getActivityFilters } from "../../../redux/reducers/appReducer";
import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";
import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../assets/existing/EditAsset";
import { useNavigate, useParams } from "react-router-dom";
import useGetCustomerIdFromUrl from "../../../customHooks/useGetCustomerId";
import { useIsItemsExistsForForm } from "../../../customHooks/useIsItemsExistsForForm";
import useSuccessfulRedirect from "../../../customHooks/useSuccessfulRedirect";
import { formatDateForSubmit, formatDateInCustomFieldsForSubmit } from "../../../_utils/date";

interface EditActivityPageProps {
  setFilter: (assetType?: string, items?: Set<string>, relatedItems?) => void;
  filterString: (status) => string;
  setFormDirtyFlag: (flag: boolean) => void;
}

const EditActivityForm: React.FC<EditActivityPageProps> = (props) => {
  const [activity, setActivity] = useState(new ActivityModel());

  const params = useParams();

  const customerId = useGetCustomerIdFromUrl();
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();
  const { setHeaderOptions } = useHeaderOptions({
    pageTitle: "Edit Activity",
    withBackButton: true,
  });
  const navigate = useNavigate();
  const { successfulRedirect } = useSuccessfulRedirect();

  const { exists, isDataAvailable } = useIsItemsExistsForForm();

  useEffect(() => {
    if (isDataAvailable && !exists) {
      navigate(ROUTES.ASSETS, { replace: true });
    }
  }, [exists, isDataAvailable]);

  const {
    data: essentialData,
    loading: isLoadingActivityData,
    error: activityDataError,
  } = useQuery(getEssentialActivityFieldsQuery, {
    variables: {
      Id: accountId,
      ActivityId: params?.id,
    },
    fetchPolicy: "network-only",
    skip: !accountId,
  });

  if (activityDataError) {
    navigate(ROUTES.ACTIVITIES);
    displayErrorNotification(NotificationTypes.ACTIVITY_ACCESS_ERROR);
  }

  const [updateActivity, { loading: isSaving }] = useMutation(updateActivityQuery);

  useEffect(() => {
    if (essentialData && !isLoadingActivityData && !activityDataError) {
      const activity = essentialData[ACCOUNT][ITEM_ACTIVITIES][0];
      setActivity((prevState) => new ActivityModel({ ...prevState, ...activity }));
    }
  }, [essentialData]);

  const handleSubmit = (saveData: ActivityModel, saveType: SaveType) => {
    let statusObjectFrom: string;

    // for case when back-end send null instead of id in StatusId field
    if (!activity.StatusId) {
      statusObjectFrom = props.filterString(statuses[0]);
    } else {
      statusObjectFrom = props.filterString(
        statuses.find((element) => element.Id === activity.StatusId),
      );
    }
    const statusObjectTo = props.filterString(
      statuses.find((element) => element.Id === saveData.StatusId),
    );

    const imagesWithAppropriateTypename = saveData.Images?.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = saveData.Documents?.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = saveData.Videos?.map((video) => {
      delete video.__typename;
      return video;
    });

    const schemaWithAppropriateTypename = saveData.Schema?.map((element: any) => {
      delete element.__typename;
      return element;
    });

    const vendorRefsWithAppropriateTypename = saveData.VendorRefs?.map((vendor: any) => {
      delete vendor.__typename;
      return vendor;
    });

    const formatDueDateTime = formatDateForSubmit(saveData?.DueDateTime as string);
    const dataForPost: any = { ...saveData, DueDateTime: formatDueDateTime };
    formatDateInCustomFieldsForSubmit(saveData?.CustomFields as {});

    const variables = {
      ...dataForPost,
      AccountId: accountId,
      Images: imagesWithAppropriateTypename,
      Documents: documentsWithAppropriateTypename,
      Videos: videosWithAppropriateTypename,
      Schema: schemaWithAppropriateTypename,
      VendorRefs: vendorRefsWithAppropriateTypename,
    };

    updateActivity({
      variables: variables,
      update: (cache, { data: { AssetActivityPut } }) => {
        if (activity.AssetId !== AssetActivityPut.AssetId) {
          props.setFilter();

          if (IsCustomerManagement) {
            changeParentItemForCustomerActivity(cache, AssetActivityPut);
            return;
          }
          updateRelatedActivitiesOfOldItem(cache, activity, AssetActivityPut);
          updateRelatedActivitiesOfNewItem(cache, activity, AssetActivityPut);
        } else {
          RelatedActivitiesCacheUpdate(
            cache,
            activity,
            AssetActivityPut,
            accountId,
            saveData.StatusId,
            saveData.CompletedDateTime,
            false,
          );
        }
        AllActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPut,
          statusObjectFrom,
          statusObjectTo,
          accountId,
          false,
        );
      },
    })
      .then(({ data }) => {
        const uploadResult = data[ACTIVITY_PUT]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[ACTIVITY_PUT][ID] && saveType.type === SAVE) {
          saveType.callback();
          displaySuccessNotification(NotificationTypes.ACTIVITY_UPDATED);
        } else if (data[ACTIVITY_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          props.setFormDirtyFlag(false);
          saveType.callback(new ActivityModel(data[ACTIVITY_PUT]));
          if (window.history.length < 3) {
            navigate(ROUTES.ACTIVITIES);
          } else {
            navigate(-1);
            toastr.success(SUCCESS_NOTIFICATIONS.get(NotificationTypes.ACTIVITY_UPDATED), "");
          }
        } else {
          displayErrorNotification(NotificationTypes.ACTIVITY_UPDATE_ERROR);
        }
      })
      .catch(() => displayErrorNotification(NotificationTypes.ACTIVITY_UPDATE_ERROR));
  };

  return (
    <ActivityForm
      {...props}
      activityId={params?.id}
      isActivitySaving={isLoadingActivityData || isSaving}
      activityData={activity}
      handleSubmit={handleSubmit}
      isActivityLoading={isLoadingActivityData || !essentialData}
      isNew={false}
      setHeaderOptions={setHeaderOptions}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];
  return {
    filterString: (status) => {
      const filterObj = {
        id: status.Id,
        name: status.Name,
        offset: getActivityFilters(state).get(status.Id) || offset(status),
      };
      return getFilterString(filterObj);
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, items?: Set<string>, relatedItems?) =>
    dispatch(setSelectedAssets(assetType, items, relatedItems)),
  setFormDirtyFlag: (value: boolean) => dispatch(setEditFormIsDirty(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActivityForm);
