import gql from "graphql-tag";

export const createVisitMutation = gql`
  mutation createNewVisit($Visit: VisitInput!, $Files: [Upload]) {
    VisitPost(Visit: $Visit, Files: $Files) {
      Id
      CustomerId
      VisitStatusId
      CustomerName @client
      VisitStatusName @client
      AssignedUserId
      Purpose
      Notes
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
    }
  }
`;
