import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import type { ActivityRelatedToProjectModel } from "../ActivitiesTabProject/ActivityRelatedToProjectModel";
import { swappingActivities } from "../../_utils/utils";

import type { XYCoord } from "dnd-core";

interface ChangeOrderDragWrapperProps {
  activity: ActivityRelatedToProjectModel;
  activities: ActivityRelatedToProjectModel[];
  isLoading: boolean;
  updateFormActivities: (newActivities: ActivityRelatedToProjectModel[]) => void;
  updateCacheActivities: (newActivities: ActivityRelatedToProjectModel[]) => void;
  renderItem: (activity: ActivityRelatedToProjectModel) => JSX.Element;
  index: number;
}

const ChangeOrderDragWrapper: React.FC<ChangeOrderDragWrapperProps> = (props) => {
  const {
    activity,
    activities,
    isLoading,
    renderItem,
    updateFormActivities,
    updateCacheActivities,
    index,
  } = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    canDrag: !isLoading,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      type: "project-activity",
      activity,
      width,
      height,
      index,
    },
    end: () => {
      updateFormActivities(activities);
    },
  });

  const [{ canDrop, isOver, item }, drop] = useDrop({
    accept: "project-activity",
    drop: (item: { type: string; activity: ActivityRelatedToProjectModel }) => {},
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      };
    },
    hover: (
      item: { type: string; activity: ActivityRelatedToProjectModel; index: number },
      monitor,
    ) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      const newOrderActivities = swappingActivities(dragIndex, hoverIndex, activities);

      updateCacheActivities(newOrderActivities);
      item.index = hoverIndex;
    },
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  useEffect(() => {
    setWidth(ref.current ? ref.current.clientWidth : 0);
    setHeight(ref.current ? ref.current.clientHeight : 0);
  }, [ref]);

  drag(drop(ref));

  const className = `ActivityItem_wrapper ${
    isDragging ? "ActivityItem__whileDragPlaceholder--invisible" : ""
  }`;

  return (
    <div key={activity.Id} ref={ref} className={className}>
      {renderItem(activity)}
    </div>
  );
};

export default ChangeOrderDragWrapper;
