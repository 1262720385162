import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "react-apollo";
import { useMatch, useParams, useNavigate } from "react-router-dom";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";

import { CustomerModel } from "../../redux/models/data/CustomersModel";

import { getCustomerByIdQuery } from "./getCustomerById.query";
import { updateCustomerMutation } from "./updateCustomer.query";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { ACCOUNT, CUSTOMERS, ID, CUSTOMER_PUT } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import CustomerFormContainer from "../../components/CustomerFormContainer/CustomerFormContainer";

interface IMatchParams {
  id: string;
}

const EditCustomer: React.FC = () => {
  const dispatch = useDispatch();

  const [customerData, setCustomerData] = useState(new CustomerModel());
  const { accountId } = useGetAccountId();
  const { successfulRedirect } = useSuccessfulRedirect();
  // useRoutes([{path: ':tab/*'}])
  const match = useMatch(`${ROUTES.SINGLE_CUSTOMER}/:id`);
  const params = useParams();
  const navigate = useNavigate();

  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
    headerType: "BUSINESS",
  });

  const { data, loading, error } = useQuery(getCustomerByIdQuery, {
    variables: {
      AccountId: accountId,
      CustomerId: params?.id,
    },
    skip: !accountId || !match,
    fetchPolicy: "network-only",
    onError: () => {
      navigate(ROUTES.CUSTOMERS);
      displayErrorNotification(NotificationTypes.CUSTOMER_ACCESS_ERROR);
    },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setCustomerData(new CustomerModel(data[ACCOUNT][CUSTOMERS][0]));
    }
  }, [data]);

  const [updateCustomer, { loading: isSaving }] = useMutation(updateCustomerMutation);

  const handleCreate = (data: CustomerModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }
    const Phone = data.Phone.length ? Number(data.Phone.replace(/\D+/g, "")).toString() : "";
    const Files = data.Files;

    const imagesWithAppropriateTypename = data.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = data.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = data.Videos.map((video) => {
      delete video.__typename;
      return video;
    });

    const dataForPost: any = { ...data, Phone, AccountId: accountId };
    delete dataForPost.Files;

    const variables = {
      Customer: {
        ...dataForPost,
        Images: imagesWithAppropriateTypename,
        Documents: documentsWithAppropriateTypename,
        Videos: videosWithAppropriateTypename,
      },
      Files,
    };

    updateCustomer({
      variables: variables,
    })
      .then(({ data }: any) => {
        if (data[CUSTOMER_PUT][ID] && saveType.type === SAVE) {
          saveType.callback(new CustomerModel(data[CUSTOMER_PUT]));
          displaySuccessNotification(NotificationTypes.CUSTOMER_UPDATED);
        } else if (data[CUSTOMER_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new CustomerModel(data[CUSTOMER_PUT]));
          successfulRedirect(ROUTES.CUSTOMERS, NotificationTypes.CUSTOMER_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.CUSTOMER_UPDATED_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.CUSTOMER_UPDATED_ERROR);
      });
  };

  const isLoading = loading || isSaving;

  return (
    <CustomerFormContainer
      data={customerData}
      handleSubmit={handleCreate}
      isLoading={isLoading}
      isNew={false}
      setHeaderOptions={setHeaderOptions}
    />
  );
};

export default EditCustomer;
