import React from "react";
import { statusClassName } from "../../_utils/utils";
import { SORTING_DETAILS } from "../../_constant/ActivityDateConstant";
import ActivityItem from "../../containers/activities/_components/ActivityItem";
import { LayoutType } from "../../_constant/constants";
import { ActivityModelRelated } from "../../containers/assets/_components/relaitedActivities/ActivityModelRelated";
import useGetAccountId from "../../customHooks/useGetAccountId";

interface RelatedActivitiesList {
  activities: ActivityModelRelated[];
  statusName: Map<string, string>;
  disableNavigationByClick?: boolean;
}

const RelatedActivitiesList: React.FC<RelatedActivitiesList> = (props) => {
  const { activities, disableNavigationByClick, statusName: statusNameProp } = props;
  const { isPersonal } = useGetAccountId();
  return (
    <>
      {activities.map((item) => {
        const statusName = statusNameProp.get(item.StatusId) ?? "";
        return (
          <div className={"ActivityItem_wrapper"} key={item.Id}>
            <ActivityItem
              activity={item}
              layout={LayoutType.LIST}
              dateType={SORTING_DETAILS.get(statusName)?.field ?? ""}
              status={statusClassName(statusName)}
              disableNavigationByClick={!!disableNavigationByClick}
              isAccountPersonal={!!isPersonal}
              isAssignmentsVisibile={!isPersonal}
            />
          </div>
        );
      })}
    </>
  );
};

export default RelatedActivitiesList;
