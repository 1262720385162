import React from "react";

import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core";

interface WizardItemTypeFooterProps {
  nextDisabled: boolean;
  handleClose: () => void;
  navigate: (backward?: boolean) => void;
}

const WizardItemTypeFooter: React.FC<WizardItemTypeFooterProps> = (props) => {
  const theme = useTheme();
  const { navigate, handleClose, nextDisabled } = props;

  return (
    <div>
      <Button onClick={() => handleClose()} style={{ color: theme.palette.text.secondary }}>
        Cancel
      </Button>
      <Button color="primary" onClick={() => navigate()} disabled={nextDisabled}>
        Next
      </Button>
    </div>
  );
};

export default WizardItemTypeFooter;
