import React from "react";

import { mapAssignFromServer } from "../../../_utils/utils";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import {
  AvatarContainer,
  CardContainer,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CopyLinkButton,
  CardMediaStyled,
  CardImageContainer,
  SelectStyled,
  TextInsideImage,
  CardLabelList,
  CardLabel,
} from "./CalendarFeedCardMobile.style";

import ActivitiesSelectFilter from "../../ActivitiesSelectFilter/ActivitiesSelectFilter";
import CardLoadingState from "../CardLoadingState";
import { Popover } from "../../Popover/Popover";
import { CalendarPopoverButton, CalendarPopoverContent } from "../CalendarPopoverContent";

import { CalendarFeedCardProps } from "../CalendarFeedContainer";

const CalendarFeedCardMobile: React.FC<CalendarFeedCardProps> = ({
  name,
  imageUrl,
  isPersonal,
  isBusiness,
  personalImg,
  config,
  isImgExist,
  selectHandler,
  usersLoading,
  accountUsers,
  createConfigHandler,
  createLoading,
  updateLoading,
  assignValue,
  userId,
}) => {
  const renderLabels = () => {
    if (isBusiness) {
      return <CardLabel key={0}>Customer management</CardLabel>;
    }

    return null;
  };

  return (
    <CardContainer>
      <CardContent>
        <AvatarContainer $withouBackground={!!imageUrl}>
          <CardImageContainer $withoutBorder={isPersonal}>
            {isImgExist ? (
              <CardMediaStyled image={imageUrl ?? personalImg} title="Image here" />
            ) : (
              <CardMediaStyled>
                <TextInsideImage>{name[0].toLocaleUpperCase()}</TextInsideImage>
              </CardMediaStyled>
            )}
          </CardImageContainer>
        </AvatarContainer>
        <CardLabelList>{renderLabels()}</CardLabelList>
        <CardHeader>
          <CardTitle>{name}</CardTitle>
        </CardHeader>
        {isPersonal ? (
          <Popover
            target={<CalendarPopoverButton />}
            content={(handler) => <CalendarPopoverContent handleClose={handler} />}
          />
        ) : (
          <SelectStyled>
            <ActivitiesSelectFilter
              filterValue={
                config?.AssignType ? mapAssignFromServer(config.AssignType, userId) : assignValue
              }
              onChange={selectHandler}
              usersLoaded={!usersLoading}
              users={accountUsers?.Account?.UserRefs || []}
              withoutCrop
              justForCurrentUser
            />
          </SelectStyled>
        )}
      </CardContent>
      <CardFooter>
        <CopyLinkButton
          type="button"
          startIcon={<FileCopyOutlinedIcon />}
          onClick={createConfigHandler}
          disabled={createLoading}
        >
          Copy Integration Link
        </CopyLinkButton>
      </CardFooter>
      {(updateLoading || createLoading) && <CardLoadingState />}
    </CardContainer>
  );
};

export default CalendarFeedCardMobile;
