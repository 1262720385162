import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import { ApolloProvider } from "react-apollo";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { ApolloClient } from "apollo-client";
import { from } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { resolvers, typeDefs } from "../graphql/resolvers";
import { useConnectionState } from "../customHooks/useConnectionState";

const ApolloWrapper: React.FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { setConnectionState } = useConnectionState();

  const authLink = setContext(async (_, { headers, ...rest }) => {
    const token = await getAccessTokenSilently();
    return {
      ...rest,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    console.log("error afterware, graphQLErrors: ", graphQLErrors);
    console.log("error afterware, networkError: ", networkError);
    console.log("error afterware, response: ", response);
    console.log("error afterware, operation: ", operation);

    if (networkError && !navigator.onLine) {
      setConnectionState(true);
    }
  });

  const httpLink = createUploadLink({ uri: window.localStorage.serverUrl });

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink as any]),
    cache: new InMemoryCache({
      dataIdFromObject: (object: any) => object.Id || null,
    }),
    typeDefs,
    resolvers,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
