import React, { createContext, useEffect, useReducer } from "react";
import { useMediaQuery } from "@material-ui/core";
import { LayoutType } from "../../_constant/constants";

export interface LayoutSwitcherStateInterface {
  items: LayoutType;
  activities: LayoutType;
  vendors: LayoutType;
}

const initianlState = {
  items: LayoutType.BOARD,
  activities: LayoutType.BOARD,
  vendors: LayoutType.BOARD,
};

const resetAllPagesToOneView = "resetAllPagesToOneView";

interface actionInterface {
  type: keyof LayoutSwitcherStateInterface | typeof resetAllPagesToOneView;
  payload: LayoutType;
}

export const LayoutSwitcherStateTypes = {
  items: "items" as keyof LayoutSwitcherStateInterface,
  activities: "activities" as keyof LayoutSwitcherStateInterface,
  vendors: "vendors" as keyof LayoutSwitcherStateInterface,
};

function LayoutSwitcherReducer(state: LayoutSwitcherStateInterface, action: actionInterface) {
  switch (action.type) {
    case LayoutSwitcherStateTypes.items:
      return {
        ...state,
        [LayoutSwitcherStateTypes.items]: action.payload,
      };
    case LayoutSwitcherStateTypes.activities:
      return {
        ...state,
        [LayoutSwitcherStateTypes.activities]: action.payload,
      };
    case LayoutSwitcherStateTypes.vendors:
      return {
        ...state,
        [LayoutSwitcherStateTypes.vendors]: action.payload,
      };
    case resetAllPagesToOneView:
      return Object.values(LayoutSwitcherStateTypes).reduce((acc, item) => {
        acc[item] = action.payload;
        return acc;
      }, {} as LayoutSwitcherStateInterface);
    default:
      return state;
  }
}

export const LayoutSwitcherStateContext = createContext<LayoutSwitcherStateInterface | undefined>(
  undefined,
);
export const LayoutSwitcherDispatchContext = createContext<
  React.Dispatch<actionInterface> | undefined
>(undefined);

const LayoutSwitcherProvider: React.FC = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:599px)", { noSsr: true });

  const [states, dispatch] = useReducer(LayoutSwitcherReducer, initianlState);

  useEffect(() => {
    if (isMobile) {
      dispatch({ type: resetAllPagesToOneView, payload: LayoutType.LIST });
    }
  }, []);

  return (
    <LayoutSwitcherDispatchContext.Provider value={dispatch}>
      <LayoutSwitcherStateContext.Provider value={states}>
        {children}
      </LayoutSwitcherStateContext.Provider>
    </LayoutSwitcherDispatchContext.Provider>
  );
};

export default LayoutSwitcherProvider;
