import gql from "graphql-tag";

export const getInvitesQuery = (...rest: string[]) => {
  let filter = "";
  if (rest.length < 1) {
    throw new Error("getInvitesQuery function should receive at least one argument");
  }
  if (rest.length >= 1) {
    filter = rest.map((item) => `State=='${item}'`).join(" OR ");
  }
  return gql`
    query getInvites {
      User {
        Id
        MyInvitations(Filter: "${filter}") {
          Id
          Account {
            Id
            Name
            Description
          }
          CreateDateTime
          Id
          Message
          Sender {
            Id
            FirstName
            LastName
            Images {
              Id
              ContainerId
              ContainerName
              Description
              FileExtension
              Name
            }
          }
          State
          UpdateDateTime
      }
    }
    }`;
};
