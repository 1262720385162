import styled from "styled-components";
import Select from "@material-ui/core/Select";

export const StyledSelect = styled(Select)`
  width: 100%;
  font-size: 0.875rem;

  .MuiSelect-root {
    padding: 13px 24px 13px 28px;
  }

  .MuiSelect-icon {
    top: calc(50% - 13px);
  }

  &.MuiInput-underline:before {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    border-bottom-width: 2px;
  }

  &.MuiInput-underline.Mui-disabled:before {
    border-bottom-color: ${({ theme }) => theme.palette.greyDesign.onSurface3};
    border-bottom-style: solid;
  }
`;
