// Related to Activities
import gql from "graphql-tag";

export const createRelatedActivityQuery = gql`
  mutation createRelatedActivity(
    $AccountId: ID
    $AssetId: ID
    $Name: String!
    $StatusId: ID
    $DueDateTime: DateTimeOffset
    $CompletedDateTime: DateTimeOffset
    $Schedule: ScheduleInputType
    $RelatedDueDateTime: [DateTimeOffset!]
    $VendorRefs: [VendorRefInputType]
  ) {
    AssetActivityPost(
      AssetActivity: {
        AccountId: $AccountId
        AssetId: $AssetId
        Name: $Name
        StatusId: $StatusId
        DueDateTime: $DueDateTime
        CompletedDateTime: $CompletedDateTime
        RelatedDueDateTime: $RelatedDueDateTime
        Schedule: $Schedule
        VendorRefs: $VendorRefs
      }
    ) {
      AccountId
      Id
      Name
      AssetId
      Description
      StatusId
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      VendorRefs {
        Id
      }
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      AssignedUserId
    }
  }
`;
