import styled from "styled-components";
import Button from "@material-ui/core/Button/Button";

export const PickerToolbarContainer = styled.header`
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const PickerToolbarDay = styled.div``;

interface SelectedProps {
  $isSelected?: boolean;
}
export const PickerToolbarTime = styled.div<SelectedProps>`
  margin-left: auto;
  display: flex;
  font-size: 3rem;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
`;

export const PickerToolbarTimeBtn = styled(Button)<SelectedProps>`
  min-width: 1rem;
  line-height: 1;
  padding: 0;

  .MuiButton-label {
    font-size: 3rem;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.5)};
  }
`;

export const PickerToolbarMeridiem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 6px;

  .MuiButton-label {
    font-size: 1rem;
  }
`;
