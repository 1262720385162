import React from "react";

import { PrintableState } from "../AssetsList/AssetListPrintableVIew";

import PrintIcon from "@material-ui/icons/Print";

import { Checkbox, useMediaQuery } from "@material-ui/core";

import {
  FormControlLabelStyled,
  PrintableViewContainer,
  SeparatorLine,
  PrintButton,
} from "./PrintableHeader.style";

import { Popover } from "../Popover/Popover";
import {
  PrintableHeaderPopoverButton,
  PrintableHeaderPopoverContent,
} from "./PrintableHeaderPopover";
interface PrintableHeaderProps {
  handleState: (props: Partial<PrintableState>) => void;
  state: PrintableState;
}

const PrintableHeader: React.FC<PrintableHeaderProps> = ({ state, handleState }) => {
  const isDesktop = useMediaQuery("(min-width: 700px)", { noSsr: true });

  const handlePrint = () => {
    window.print();
  };

  const handleGroupByType = () => {
    handleState({ groupByType: !state.groupByType });
  };

  const handleShowMoreInfo = () => {
    handleState({ showMoreInfo: !state.showMoreInfo });
  };

  return (
    <PrintableViewContainer>
      <PrintButton variant="text" color="primary" startIcon={<PrintIcon />} onClick={handlePrint}>
        {isDesktop ? "Print Items" : "Print"}
      </PrintButton>
      {isDesktop && <SeparatorLine />}
      <FormControlLabelStyled
        control={
          <Checkbox
            checked={state.groupByType}
            onChange={handleGroupByType}
            name="group"
            color="primary"
          />
        }
        label={isDesktop ? "Group by Item Type" : "Group"}
      />
      {isDesktop && <SeparatorLine />}
      <FormControlLabelStyled
        control={
          <Checkbox
            checked={state.showMoreInfo}
            onChange={handleShowMoreInfo}
            name="group"
            color="primary"
          />
        }
        label={isDesktop ? "Show More Info" : "More Info"}
      />
      {isDesktop && (
        <Popover
          target={<PrintableHeaderPopoverButton />}
          content={(handler) => <PrintableHeaderPopoverContent handleClose={handler} />}
        />
      )}
    </PrintableViewContainer>
  );
};

export default PrintableHeader;
