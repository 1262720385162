import gql from "graphql-tag";

const deleteAccountQuery = gql`
  mutation deleteAccount($Id: ID) {
    AccountDelete(Id: $Id) {
      Id
    }
  }
`

export default deleteAccountQuery;