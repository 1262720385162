import gql from "graphql-tag";

export const getCustomersQuery = gql`
  query getCustomers($Id: ID) {
    Account(Id: $Id) {
      Id
      Customers {
        Address
        Name
        Email
        Id
        Phone
        Images {
          Id
          ContainerId
          ContainerName
          IsPrimary
          Name
        }
      }
    }
  }
`;
