import React, { useEffect, useState } from "react";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import TextField from "@material-ui/core/TextField";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { useQuery } from "react-apollo";
import { getCustomersQuery } from "../Customers/customersList.query";
import { CustomerModel } from "../../redux/models/data/CustomersModel";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { composeImageUrl } from "../../_utils/utils";
import { StyledCombobox } from "../Combobox/ComboboxStyles";
import { createStyles, makeStyles } from "@material-ui/core";

interface CustomerSelectProps {
  customerId: string | null;
  handleChange: any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    listbox: {
      maxHeight: "190px",
      "&::-webkit-scrollbar": {
        width: "0.3em",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "6px",
      },
    },
    option: {
      color: theme.palette.text.secondary,
      paddingBottom: "8px",
      paddingTop: "8px",
      '&[aria-selected="true"]': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighten,
      },
    },
    paper: {
      "&.MuiPaper-elevation1": {
        boxShadow:
          "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      },
    },
  }),
);

const CustomerSelect: React.FC<CustomerSelectProps> = (props) => {
  const { customerId, handleChange } = props;

  const classes = useStyles();

  const [value, setValue] = useState<CustomerModel | null>(null);
  const [inputValue, setInputValue] = useState("");

  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(getCustomersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });

  const options = data?.Account?.Customers || [];
  const selectedCustomer: CustomerModel = options.find(
    (customer: CustomerModel) => customer.Id === customerId,
  );

  useEffect(() => {
    if (accountId && !loading) {
      setValue(selectedCustomer || null);
    }
  }, [customerId, accountId, loading]);

  const renderInput = (params: object) => (
    <TextField {...params} label="Customer" variant="standard" />
  );

  const renderOptions = (customer: CustomerModel) => (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        {customer.Images && customer.Images.length ? (
          <Avatar
            variant="circular"
            src={composeImageUrl(
              customer.Images[0].Id,
              customer.Images[0].ContainerName,
              customer.Images[0].ContainerId,
            )}
            style={{ height: 24, width: 24 }}
          />
        ) : (
          <Avatar variant="circular" style={{ height: 24, width: 24 }}>
            <AccountBoxIcon />
          </Avatar>
        )}
      </Grid>
      <Grid item>{customer.Name}</Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item>
        {selectedCustomer && selectedCustomer.Images && selectedCustomer.Images.length ? (
          <Avatar
            variant="circular"
            style={{
              width: 16,
              height: 16,
              fontSize: 12,
              display: "inline-block",
            }}
            src={composeImageUrl(
              selectedCustomer.Images[0].Id,
              selectedCustomer.Images[0].ContainerName,
              selectedCustomer.Images[0].ContainerId,
            )}
          >
            {selectedCustomer.Name[0]}
          </Avatar>
        ) : (
          <FaceOutlinedIcon style={{ width: 16, height: 16 }} />
        )}
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <StyledCombobox
          classes={{
            listbox: classes.listbox,
            option: classes.option,
            paper: classes.paper,
          }}
          value={value}
          onChange={(_: React.SyntheticEvent, value: CustomerModel) => {
            handleChange(null, { id: "CustomerId", value: value ? value.Id : null });
          }}
          inputValue={inputValue}
          onInputChange={(_: React.SyntheticEvent, newInputValue: string) => {
            setInputValue(newInputValue);
          }}
          options={options}
          getOptionLabel={(option: CustomerModel) => option.Name}
          renderInput={renderInput}
          renderOption={renderOptions}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomerSelect, (prevProps, props) => {
  if (prevProps.customerId === props.customerId) {
    return true;
  }
  return false;
});
