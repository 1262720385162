export class ClientInfoModel {
  serverUrl: string;
  authUrl: string;
  appUrl: string;
  clientId: string;
  changePasswordUrl: string;
  blobStorage: string;
  enableBusinessAccounts: boolean;
  enableRegisterDeviceInfo: boolean;
  enableDebugMessages: boolean;
  scope: string;
  apiName: string;
  changeSubscriptionUrl: string;

  constructor(urlData = {}) {
    this.serverUrl = urlData?.["UpkeeprApi.Uri"] || "";
    this.authUrl = urlData?.["Authentication.Domain"] || "";
    this.appUrl =
      process.env.NODE_ENV === "DEV"
        ? "http://localhost:8080"
        : urlData?.["UpkeeprWebApp.Uri"] || "";
    window.localStorage.setItem("appUrl", this.appUrl);
    this.enableBusinessAccounts = urlData?.["FeatureSwitch.CustomerManagement"] || false;
    this.enableRegisterDeviceInfo = urlData?.["FeatureSwitch.RegisterDeviceInfo"] || false;
    this.enableDebugMessages = urlData?.["FeatureSwitch.ShowDebugMessages"] || false;
    window.localStorage.setItem("enableDebugMessages", this.enableDebugMessages);
    window.localStorage.setItem("enableBusinessAccounts", this.enableBusinessAccounts);
    this.clientId = urlData?.["Authentication.ClientId"] || "";
    this.changePasswordUrl = this.authUrl + urlData?.["Authentication.ChangePasswordRoute"] || "";
    this.blobStorage = urlData?.["FileStorage.Uri"] || "";
    this.scope = urlData?.["Authentication.Scope"] ?? "";
    this.apiName = urlData?.["UpkeeprApi.Name"] ?? "";
    this.changeSubscriptionUrl = urlData?.["UserSubscription.Uri"] ?? "";
  }

  withUpdatedValues(nextState) {
    return new ClientInfoModel(nextState);
  }
}
