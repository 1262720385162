import * as React from "react";
import {AuthConsumer} from "../authorization/AuthProvider";
import {GlobalLoader} from "../components/Loader";

export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <GlobalLoader/>;
        }}
    </AuthConsumer>
);
