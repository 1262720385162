import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { TypeText } from "@material-ui/core/styles/createPalette";

interface textType extends TypeText {
  additionalText: string;
}

let lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#26B6F6",
      light: "#E3EFFF",
      dark: "#1298eD",
      medium: "#CBDDF6",
      contrastText: "#FFFFFF",
      lighten: "#f6fcff",
    },
    secondary: {
      main: "#89CC45",
      light: "#E9FAE1",
      dark: "#618833",
      contrastText: "#FFFFFF",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F6F5Fb",
    },
    text: {
      primary: "#1F1F1F",
      secondary: "#666666",
      disabled: "#DDDDDD",
      additionalText: "#6E798C",
    } as textType,
    success: {
      main: "#89CC45",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FCB61E",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#F2133C",
      contrastText: "#FFFFFF",
    },
    action: {
      selected: "#F6FCFF",
    },
    greyDesign: {
      surface2: "#7E7D82",
      separator: "#F6F5FB",
      onSurface3: "#E4E1E1",
    },
    status: {
      toDo: "#26B6F6",
      inProgress: "#9874fe",
      done: "#8ec549",
      cancelled: "#F2133C"
    }
  },
  props: {
    MuiMenu: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
          "-webkit-overflow-scrolling": "touch",
        },
        body: {
          height: "100%",
        },
        button: {
          background: "none",
        },
        a: {
          textDecoration: "none",
        },

        "input, select, textarea": {
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        padding: "4px 16px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.875rem",
        padding: "12px",
        paddingTop: "4px",
        paddingBottom: "4px",
        height: "2.5rem",
        color: "#666666",

        "&$selected": {
          color: "#26B6F9",
        },
      },
    },

    MuiInputBase: {
      root: {
        fontSize: "0.875rem",
      },
    },

    MuiInput: {
      underline: {
        "&$error": {
          "&:after": {
            borderBottomWidth: "1px",
          },
        },
        "&:hover": {
          "&:not(.Mui-disabled)": {
            "&:before": {
              borderBottomWidth: "1px",
            },
          },
        },
      },
    },

    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 6px) scale(0.815)",
        color: "#26B6F9",
      },
    },

    MuiFormHelperText: {
      root: {
        position: "absolute",
        left: 0,
        top: "100%",
        lineHeight: "1.26",
        fontSize: "0.625rem",
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: "285px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "30px",
      },
    },
  },
});

lightTheme = responsiveFontSizes(lightTheme);
export default lightTheme;
