import gql from "graphql-tag";

export const assignUserMutation = gql`
  mutation PatchVisit($AccountId: ID, $Id: ID, $AssignedUserId: ID, $Purpose: String) {
    VisitPatch(
      Visit: { AccountId: $AccountId, Id: $Id, AssignedUserId: $AssignedUserId, Purpose: $Purpose }
    ) {
      Id
      AssignedUserId
      AccountId
      Purpose
    }
  }
`;
