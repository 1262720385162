import styled from "styled-components";

interface NoProjectsImageProps {
  $url: string;
}
export const NoProjectsImage = styled.div<NoProjectsImageProps>`
  background-color: ${({ theme }) => `${theme.palette.background.default}`};
  width: 100%;
  height: 100%;
  background-image: ${({ $url }) => `url(${$url})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
