import React from "react";

import { StepperLineContainer } from "./StepperLineStyles";

interface StepperLineProps {
  currentStep: number;
}

const StepperLine: React.FC<StepperLineProps> = ({ currentStep }) => {
  const generateProgress = (currentStep: number): number => {
    switch (currentStep) {
      case 2:
        return 33;
      case 3:
        return 66;
      case 4:
        return 100;
      default:
        return 0;
    }
  };

  return <StepperLineContainer variant="determinate" value={generateProgress(currentStep)} />;
};

export default StepperLine;
