import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";

import { VendorModel } from "../../redux/models/data/VendorModel";

import TableCard from "../../components/TableComponent/TableCard";
import TableHeader from "../../components/TableComponent/TableHeader";
import TableCardSkeleton from "../../components/TableComponent/TableCardSkeleton";

import { TableContentStyled } from "../../components/TableComponent/Table.styles";
import { ROUTES } from "../../_constant/screens";

import { scrollStyles } from "../_styled/scrollStyles";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import Modal from "../../containers/Modal/Modal";
import { NoVendorsComponent } from "../NoResultComponent/NoResultComponent";
import VendorDeleteDialog from "../VendorForm/VendorDeleteDialog/VendorDeleteDialog";

const ListViewContainer = styled.div`
  max-width: 960px;
  min-width: 288px;
  padding: 0 1rem 1.5rem 1rem;
  margin: 0 auto;
  overflow-y: auto;

  @media (min-width: 600px) {
    width: auto;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  ${scrollStyles}
`;

export interface RemoveDialogState {
  isOpen: boolean;
  id: null | string;
  name: string;
  isLoading: boolean;
}

interface VendorsTableContentProps {
  vendors: VendorModel[];
}

const VendorsTableContent: React.FC<VendorsTableContentProps> = ({ vendors }) => {
  const [deleteState, setDeleteState] = useState<RemoveDialogState>({
    isOpen: false,
    id: null,
    name: "",
    isLoading: false,
  });

  const openDeleteDialog = useCallback((id: string, name: string) => {
    setDeleteState((prev) => ({ ...prev, isOpen: true, isLoading: true, id, name }));
  }, []);
  const closeDeleteDialog = useCallback(
    () => setDeleteState((prev) => ({ ...prev, isOpen: false, isLoading: false })),
    [],
  );
  return (
    <TableContentStyled container item>
      {vendors.map((vendor: VendorModel) => {
        const options = [
          // {
          //   text: "Duplicate",
          //   icon: <FileCopyOutlinedIcon fontSize="small" />,
          //   callback: () => {},
          // },
          {
            text: "Delete",
            icon: <DeleteOutlinedIcon fontSize="small" />,
            callback: () => {
              if (normalizeVendor.Id && normalizeVendor.Name) {
                openDeleteDialog(normalizeVendor.Id, normalizeVendor.Name);
              }
            },
          },
        ];

        const normalizeVendor = new VendorModel(vendor);

        const isDialogOpen = deleteState.id === normalizeVendor.Id && deleteState.isOpen;
        return (
          <>
            <TableCard
              key={normalizeVendor?.Id}
              data={normalizeVendor}
              navigatePath={ROUTES.SINGLE_VENDOR}
              moreActionOptions={options}
            />
            {isDialogOpen && deleteState.isOpen && (
              <Modal isShowing={deleteState.isOpen}>
                <VendorDeleteDialog
                  id={normalizeVendor?.Id}
                  handleCancel={closeDeleteDialog}
                  withoutRedirect
                />
              </Modal>
            )}
          </>
        );
      })}
    </TableContentStyled>
  );
};

interface AllVendorsListViewProps {
  loading: boolean;
  vendors: VendorModel[];
}

const AllVendorsListView: React.FC<AllVendorsListViewProps> = ({ loading, vendors }) => {
  const xsUp = useMediaQuery("(min-width:600px)", { noSsr: true });

  if (!loading && !vendors.length) {
    return <NoVendorsComponent />;
  }

  return (
    <ListViewContainer>
      {xsUp && <TableHeader entityName="Vendor" />}
      {loading && <TableCardSkeleton count={2} />}
      {!!vendors.length && <VendorsTableContent vendors={vendors} />}
    </ListViewContainer>
  );
};

export default AllVendorsListView;
