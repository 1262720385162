import Icon, { DEFAULT_ICON } from "../../../_constant/Icons";

export interface ItemTypeField {
  Id: string;
  Name: string;
  FieldType: FieldType;
  AllowedValues: string[];
  Order: number;
  IsCustomValueAllowed: null | boolean;
}

export interface AssetType {
  Id: string;
  Name: string;
  IconName: string;
  ItemTypeFields?: ItemTypeField[];
  DescriptionPattern?: string[];
  __typename?: string;
}

export type FieldType = "STRING" | "NUMBER" | "DATE_TIME" | "ENUMERATION" | "BOOLEAN" | "ADDRESS";

export class AssetTypeModel {
  Id: string;
  Name: string;
  Brands: string;
  IconName: string;
  IsSystem: boolean;
  ItemTypeFields?: ItemTypeField[];
  DescriptionPattern?: string[];

  constructor({
    Id = "",
    Name = "",
    Brands = "",
    IconName = "",
    IsSystem = false,
    ItemTypeFields = [] as ItemTypeField[],
    DescriptionPattern = [] as string[],
  } = {}) {
    this.Id = Id || "";
    this.Name = Name || "";
    this.Brands = Brands;
    this.IsSystem = IsSystem || false;
    this.IconName = IconName ?? DEFAULT_ICON;
    this.ItemTypeFields = ItemTypeFields;
    this.DescriptionPattern = DescriptionPattern ?? [];
  }
}
