import { useState, useCallback } from "react";
import { DialogState, DialogStateHandlerType } from "../../containers/activities/_components/types";

const defaultDialogState = {
  isOpen: false,
  url: "",
};

const useDialogSuccessLinkState = () => {
  const [dialogState, setDialogState] = useState<DialogState>(defaultDialogState);

  const dialogStateHandler: DialogStateHandlerType = ({ isOpen, url }) => {
    setDialogState((preState) => ({
      ...preState,
      isOpen: isOpen ?? preState.isOpen,
      url: url ?? preState.url,
    }));
  };

  const onClose = useCallback((): void => {
    dialogStateHandler(defaultDialogState);
  }, []);

  const onOpen = useCallback((url: string): void => {
    dialogStateHandler({ isOpen: true, url });
  }, []);

  return {
    isOpen: dialogState.isOpen,
    url: dialogState.url,
    onClose,
    onOpen,
  };
};

export default useDialogSuccessLinkState;
