import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { useLayoutSwitcherContext } from "../../../components/LayoutSwitcher/useLayoutSwitcherContext";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";

import { ROUTES } from "../../../_constant/screens";
import { NotificationTypes } from "../../../_constant/NotificationTypes";

import { AssetModel } from "../../../redux/models/data/AssetModel";
import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import { LayoutType } from "../../../_constant/constants";

import { ACCOUNT, ASSET_REFS, ITEMS } from "../../../_constant/wordings";
import { WIZARD_SCREENS_NAME } from "../../../components/Wizard/WizardContainer/WizardConstants";

import { getDetailedAssetInfo, GET_ASSET_REFS } from "./assets.query";
import { getWizardDataQuery } from "../../../components/Wizard/WizardContainer/getWizardData.query";

import { alphabetNameSorting, getTitlePrefix } from "../../../_utils/utils";
import {
  getSearchStringValue,
  getSelectedAssetsTags,
  getSelectedAssetType,
} from "../../../redux/reducers/filterReducer";
import { filterAssets } from "../../../_utils/filtering";
import { displayErrorNotification } from "../../../redux/actions/_utils";

import AllAssets from "./AllAssets";
import WizardContainer from "../../../components/Wizard/WizardContainer/WizardContainer";

interface AssetsPageWrapperProps {
  filterItems: (items: any[]) => any[];
  searchFor: (searchStr: string) => void;
  titlePrefix: string;
}

const AllAssetsWrapper: React.FC<AssetsPageWrapperProps> = (props) => {
  useHeaderOptions({ pageTitle: "Items" });
  const [isStartedDialogOpened, setIsStartedDialogOpened] = useState(false);
  const [startStep, setStartStep] = useState(WIZARD_SCREENS_NAME.welcome);

  const navigate = useNavigate();
  const { accountId } = useGetAccountId();
  const {
    state: { items: layout },
  } = useLayoutSwitcherContext();

  useQuery(getWizardDataQuery, { fetchPolicy: "cache-first" });

  const { data, loading, error } = useQuery(GET_ASSET_REFS, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-first",
  });

  const [
    getDetailedAssetList,
    { data: detailedData, loading: detailedLoading, error: detailedError },
  ] = useLazyQuery(getDetailedAssetInfo, { fetchPolicy: "network-only" });

  if (error) {
    navigate(ROUTES.ERROR);
  }
  const handleOpenWizard = () => {
    setIsStartedDialogOpened(true);
  };

  const handleOpenWizardWithButton = () => {
    setStartStep(WIZARD_SCREENS_NAME.type_select);
    handleOpenWizard();
  };

  const handleCloseWizard = () => {
    setIsStartedDialogOpened(false);
  };

  useEffect(() => {
    if (!loading && !error && data) {
      const newAssets = data[ACCOUNT][ASSET_REFS] ? data[ACCOUNT][ASSET_REFS] : [];
      if (newAssets.length === 0) {
        setStartStep(WIZARD_SCREENS_NAME.welcome);
        handleOpenWizard();
      }
    }
    if (error) {
      displayErrorNotification(NotificationTypes.ASSET_GET_ERROR);
    }
  }, [data, error]);

  useEffect(() => {
    if (layout === LayoutType.PRINTABLE && accountId) {
      getDetailedAssetList({ variables: { Id: accountId } });
    }
  }, [layout]);

  const res =
    data && data[ACCOUNT] && data[ACCOUNT][ASSET_REFS]
      ? data[ACCOUNT][ASSET_REFS].sort(alphabetNameSorting).map(
          (item: any) => new AssetRefsModel(item),
        )
      : ([] as AssetRefsModel[]);
  const detailedAssets =
    detailedData && detailedData[ACCOUNT] && detailedData[ACCOUNT][ITEMS]
      ? detailedData[ACCOUNT][ITEMS].sort(alphabetNameSorting).map(
          (item: any) => new AssetModel(item),
        )
      : ([] as AssetModel[]);

  return (
    <>
      <AllAssets
        assetsToShow={props.filterItems(res)}
        detailedAssets={props.filterItems(detailedAssets)}
        detailedLoading={detailedLoading}
        loading={loading}
        titlePrefix={props.titlePrefix}
        isDataAvailable={!!data}
        isDetailedDataAvailable={!!detailedData}
        handleOpenWizard={handleOpenWizardWithButton}
      />
      {isStartedDialogOpened && (
        <WizardContainer
          isOpen={isStartedDialogOpened}
          handleClose={handleCloseWizard}
          startStep={startStep}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  filterItems: (items: AssetRefsModel[]) => filterAssets(state, items),
  titlePrefix: getTitlePrefix(
    !!getSearchStringValue(state),
    !!getSelectedAssetType(state),
    !!getSelectedAssetsTags(state).length,
  ),
});

export default connect(mapStateToProps)(AllAssetsWrapper);
