import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import EditCustomFieldLayout from "./EditCustomFieldLayout";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { displayErrorNotification } from "../../redux/actions/_utils";

const newFieldInit = {
  newFieldName: "",
  type: "STRING",
};

interface AddCustomField {
  CustomFields: {};
  Schema: any[];
  onChange: (e: any, target: any) => void;
}

const AddCustomField: React.FC<AddCustomField> = ({ Schema, CustomFields, onChange }) => {
  const [newField, setNewField] = useState(newFieldInit);
  const [canAddField, setCanAddField] = useState(false);

  useEffect(() => {
    if (newField.newFieldName.length > 1) {
      setCanAddField(true);
    } else {
      setCanAddField(false);
    }
  }, [newField]);

  const handleAddCustomField = () => {
    const newSchema = [...Schema];

    if (
      !newSchema.some(
        (schemaItem) => schemaItem.Name.toLowerCase() === newField.newFieldName.toLowerCase(),
      )
    ) {
      newSchema.push({
        Id: null,
        Name: newField.newFieldName,
        Order: Schema.length + 1,
        FieldType: newField.type,
      });

      onChange(null, { id: "Schema", value: newSchema });
      onChange(null, {
        id: "CustomFields",
        value: { ...CustomFields, [newField.newFieldName]: null },
      });

      setNewField(newFieldInit);

      return newSchema;
    }

    displayErrorNotification(NotificationTypes.CUSTOM_FIELD_ALREADY_EXIST);
    return null;
  };

  const renderAddButton = () => (
    <IconButton
      style={{ marginBottom: "-8px", marginRight: "-8px", padding: "8px" }} // align to right side
      onClick={handleAddCustomField}
      color="primary"
      disabled={!canAddField}
    >
      <AddCircleOutlineIcon fontSize="small" />
    </IconButton>
  );

  const handleNameChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setNewField((prevValue) => ({
      ...prevValue,
      newFieldName: target.value.replace(/\s\s+/g, " "),
    }));

  const handleTypeChange = ({ target }: React.ChangeEvent< {  value: any; name?: string; }>) => {
    setNewField((prevValue) => ({ ...prevValue, type: target.value }));
  };

  return (
    <section className="Section Section_withBorder Section_defaultBackground">
      <EditCustomFieldLayout
        Name={newField.newFieldName}
        FieldType={newField.type}
        nameIcon={<CreateOutlinedIcon />}
        renderActionButton={renderAddButton}
        handleNameChange={handleNameChange}
        handleTypeChange={handleTypeChange}
      />
    </section>
  );
};

export default AddCustomField;
