import React from "react";

import { useQuery } from "react-apollo";
import { getAssetsTags } from "./getAssetsTagsQuery";
import TagsAutocomplete from "./TagsAutocomplete";
import useGetAccountId from "../../customHooks/useGetAccountId";

const TagsAutocompleteItems = (props) => {
  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(getAssetsTags, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "network-only",
  });
  const allTags = data?.Account?.AssetsTags ?? [];

  return <TagsAutocomplete allTags={allTags} allTagsLoaded={loading} {...props} />;
};

export default TagsAutocompleteItems;
