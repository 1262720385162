import React from "react";

import { AvatarStyled, ListItemTextStyled, StyledListItem } from "./FilterVisitDesktop.style";

interface FilterListItemProps {
  text: string;
  id: string;
  imgUrl: string | null;
  isActive?: boolean;
  handleClick: (payload: string) => void;
}

const FilterListItem: React.FC<FilterListItemProps> = ({
  text,
  isActive,
  imgUrl,
  id,
  handleClick,
}) => {
  const handleListItemClick = () => {
    const payload = isActive ? "" : id;
    handleClick(payload);
  };

  return (
    <StyledListItem button key={text} isActive={isActive} onClick={handleListItemClick}>
      <AvatarStyled src={imgUrl ?? ""} />
      <ListItemTextStyled primary={text} />
    </StyledListItem>
  );
};

export default FilterListItem;
