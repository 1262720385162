import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { useNavigate } from "react-router-dom";

import { ACTIVITY_STATUSES, SYSTEM_DATA } from "../../_constant/wordings";
import { ROUTES } from "../../_constant/screens";

const GET_STATUSES_LIST = gql`
  query getActivityStatuses {
    SystemData {
      AssetActivityStatuses {
        Id
        Name
        SortOrder
      }
    }
  }
`;

// TODO: add types

const useGetAcitvitiesStatuses = (): any[] => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_STATUSES_LIST, {
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return [];
  }
  if (error) {
    navigate(ROUTES.ERROR);
    return [];
  }

  if (data && data[SYSTEM_DATA]) {
    return data[SYSTEM_DATA][ACTIVITY_STATUSES].sort(
      (current, next) => current.SortOrder - next.SortOrder,
    );
  }

  return [];
};

export default useGetAcitvitiesStatuses;
