import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedAssetIds, getSelectedAssetType } from "../../redux/reducers/filterReducer";
import { useCallback } from "react";
import { setSelectedAssets } from "../../redux/actions/actions";

interface FilterButtonProps {
  filterOpen: boolean;
  setFilterOpen: (expanded: boolean) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ filterOpen, setFilterOpen }) => {
  const dispatch = useDispatch();

  const selectedAssetType = useSelector((state) => getSelectedAssetType(state));
  const selectedAssetIds = useSelector((state) => getSelectedAssetIds(state));

  const setFilter = useCallback(
    (assetType?: string, assets?: Set<string>, relatedItems?) =>
      dispatch(setSelectedAssets(assetType, assets, relatedItems)),
    [dispatch],
  );

  const renderIcon = () => {
    let iconName = "filter_list";

    if (filterOpen) {
      iconName = "menu_open";
    }

    if (filterOpen && !!selectedAssetType) {
      iconName = "filter_list";
    }

    return (
      <div
        className={`SideMenu_action ${!!selectedAssetType ? "SideMenu_action-warning" : ""}`}
        onClick={handleSubmenuState}
      >
        <i className={`Icon Icon-${iconName}`} />
      </div>
    );
  };

  const handleSubmenuState = () => {
    if (filterOpen) {
      if (!!selectedAssetType) {
        setFilter();
      }
      setFilterOpen(false);
    } else {
      if (!!selectedAssetType) {
        setFilter();

        return;
      }
      setFilterOpen(true);
    }
  };

  return renderIcon();
};

export default FilterButton;
