import React from "react";

import { Box, IconButton } from "@material-ui/core";

import { RemoveCircleOutlineOutlined } from "@material-ui/icons";

import { removeActivityFromProjectTabCache } from "../../../../components/ActivitiesTabProject/removeActivityFromProjectTabCache";

import { useApolloClient } from "react-apollo";

import styled from "styled-components";

export const ControlActivityButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  margin-left: 20px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

export const StyledContainer = styled(Box)`
  padding: 16px;
`;

export const ActivityContainer = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
  background-color: transparent;
`;

export const ActivityItemContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.greyDesign.onSurface3};
  border-radius: 8px;
`;

export interface ActivityItemWrapperProps {
  isCustomize: boolean;
  activityId: string | null;
  removeActivity: (activityId: string) => void;
  projectId: string | null | undefined;
}

export const ActivityItemWrapper: React.FC<ActivityItemWrapperProps> = ({
  removeActivity,
  isCustomize,
  children,
  activityId,
  projectId,
}) => {
  const client = useApolloClient();

  const handleClick = () => {
    activityId && removeActivity(activityId);
    removeActivityFromProjectTabCache(client, activityId, projectId);
  };
  return isCustomize ? (
    <ActivityContainer>
      <StyledContainer>
        <Box display="flex" alignItems="center">
          <ActivityItemContainer>{children}</ActivityItemContainer>
          <ControlActivityButton color="primary" onClick={handleClick}>
            <RemoveCircleOutlineOutlined />
          </ControlActivityButton>
        </Box>
      </StyledContainer>
    </ActivityContainer>
  ) : (
    <>{children}</>
  );
};
