import styled from "styled-components";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  Menu,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { textClip } from "../_styled/textClip";

interface StyledListProps {
  divided?: boolean;
  rounded?: boolean;
}

export const StyledContainer = styled(Box)`
  padding: 1rem;
`;

export const StyledList = styled(List)<StyledListProps>`
  background-color: ${({ divided, theme }) => (divided ? "var(--color-bg-secondary)" : `${theme.palette.background.paper}`)};
  border: ${({ divided, theme }) => (divided ? "none" : `1px solid ${theme.palette.greyDesign.onSurface3}`)};
  border-radius: ${({ rounded }) => (rounded ? "8px" : 0)};
  padding: 0;
  overflow: hidden;

  .MuiListItem-root {
    border: ${({ divided, theme }) => (divided ? `1px solid ${theme.palette.greyDesign.onSurface3}` : "none")};
    border-radius: ${({ divided }) => (divided ? "8px" : "none")};
  }
  .MuiBox-root:last-child {
    border-bottom: none;
  }
  a {
    color: ${({theme}) => theme.palette.text.primary};
  }
`;

export const StyledListItem = styled(ListItem)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 8px;
  min-width: 0;
  width: 100%;
  cursor: pointer;

  a {
    flex-grow: 1;
    min-width: 0;
  }

  &:not(:last-child) {
    border-bottom: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  }

  .MuiTypography-body1 {
    ${textClip}
    font-size: 14px;
  }
` as React.ComponentType<ListItemProps>; // To avoid ts error in VendorList.tsx

export const StyledAvatarWrapper = styled(ListItemAvatar)`
  min-width: auto;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.medium};

  .MuiAvatar-root {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;

    ${({ theme }) => theme.breakpoints.down("xs")} {
      width: 24px;
      height: 24px;
    }
  }

  &:disabled {
    .MuiSvgIcon-root {
      fill: ${({theme}) => theme.palette.primary.light};
    }
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 1px 10px rgba(0, 0, 0, 0.2);
    max-width: 250px;
    .MuiTypography-body1 {
      ${textClip}
      font-size: 14px;
      color: ${({ theme }) => theme.palette.primary.main};
    }
    .MuiSvgIcon-root {
      width: 18px;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  cursor: default;
  &:hover {
    background-color: transparent;
  }
`;

export const CopyButton = styled(IconButton)`
  margin-left: 47px;
  width: 42px;
  height: 42px;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background-color: ${({theme}) => theme.palette.primary.light};
    box-shadow: none;
    padding: 8px 8px 8px 12px;
    overflow: visible;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      display: inline-block;
      background-color: ${({theme}) => theme.palette.primary.light};
      position: absolute;
      top: -4px;
      right: 18px;
      transform: rotate(45deg);
    }
  }
  .MuiIconButton-root {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

export const AddNewVendorContainer = styled(Box)<{ transparentBg?: boolean }>`
  border-bottom: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  background-color: ${({ transparentBg }) => (transparentBg ? "transparent" : "var(--color-white)")};
`;
