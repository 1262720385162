import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useApolloClient, useLazyQuery, useMutation } from "@apollo/react-hooks";

import { getActivityFilters } from "../../../../redux/reducers/appReducer";

import { CircularProgress, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { EditOutlined } from "@material-ui/icons";

import { ShortActivityModel } from "../../../assets/_components/relaitedActivities/new/ShortActivityModel";

import { createRelatedActivityQuery } from "../../../assets/_components/relaitedActivities/new/new-activity.query";
import { assetNameFragment, assetTypeRefFragmentType } from "../../../../graphql/resolvers";

import {
  updateAllActivitiesCacheWithNewActivity,
  updateRelatedActivitiesCacheWithNewActivity,
  updateVendorsActivityCacheWithNewActivity,
} from "../../../assets/_components/relaitedActivities/new/updateActivitiesCacheWithNewActivity";

import Combobox from "../../../../components/Combobox/Combobox";
import InputRow from "../../../../components/InputRow/InputRow";
import { addActivityToProjectTabCache } from "../../../../components/ActivitiesTabProject/addActivityToProjectTabCache";
import { ProjectFormContext } from "../../../../components/ProjectForm/ProjectFormContext";

import ParentItemSelect from "../../../activities/_components/form/fields/ParentItemSelect";

import useGetAcitvitiesStatuses from "../../../../customHooks/api/useGetAcitvitiesStatuses";
import useGetAccountId from "../../../../customHooks/useGetAccountId";

import { GET_ACTIVITIES_RELATED_TO_ASSET } from "./getActivitiesRelatedToAsset.query";

import { NotificationTypes } from "../../../../_constant/NotificationTypes";
import { ASSET_REFS, NAME } from "../../../../_constant/wordings";
import { FILTERING_DETAILS } from "../../../../_constant/ActivityDateConstant";

import {
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../../../../redux/actions/_utils";
import { WIZARD_SCREENS_NAME } from "../../../../components/Wizard/WizardContainer/WizardConstants";
import WizardContainer from "../../../../components/Wizard/WizardContainer/WizardContainer";

interface ActivityForComboboxOptions {
  Id: string;
  Name: string;
}
interface IOption {
  value: string;
  label: string;
}

function AddNewActivitySection({
  newRelatedActivity,
  updateRelatedActivity,
  handleActivityCreate,
  filterString,
  errorInField,
  setError,
  customerId,
  visitId,
  ActivityIds,
  projectId,
  setActivityToProject,
}) {
  const [options, setOptions] = useState<IOption[]>([]);
  const [clearActivityNameField, setClearActivityNameField] = useState(false);

  const { formApi, isWizardOpen, handleOpenWizard, handleCloseWizard, isCustomize } = useContext(
    ProjectFormContext,
  );
  const client = useApolloClient();
  const statuses = useGetAcitvitiesStatuses();
  const { accountId } = useGetAccountId();
  const doneStatus = statuses.find((status: { Name: string }) => status.Name === "Done");

  const [createNewActivity, { loading: isLoading }] = useMutation(createRelatedActivityQuery);
  const [getActivitiesFromAsset, { data, loading }] = useLazyQuery(GET_ACTIVITIES_RELATED_TO_ASSET);
  const activitiesFromAsset = data?.Account.Assets[0].AssetActivities;

  useEffect(() => {
    if (!accountId || !doneStatus) {
      return;
    }
    if (newRelatedActivity.AssetId) {
      getActivitiesFromAsset({
        variables: {
          Id: accountId,
          AssetId: newRelatedActivity.AssetId,
          Filter: `StatusId!="${doneStatus.Id}"`,
        },
      });
    }
  }, [newRelatedActivity.AssetId, accountId, doneStatus]);

  useEffect(() => {
    if (activitiesFromAsset) {
      const allOptions: IOption[] = activitiesFromAsset?.map(
        (activity: ActivityForComboboxOptions) => ({
          value: activity.Id,
          label: activity.Name,
        }),
      );

      const filteredOptions = allOptions.filter((activity) => {
        return !formApi.data.ActivityIds.some((id) => id === activity.value);
      });
      if (newRelatedActivity.AssetId) {
        setOptions(filteredOptions);
      }
    }
    if (!activitiesFromAsset) {
      setOptions([]);
    }
  }, [activitiesFromAsset, formApi.data.ActivityIds, newRelatedActivity.AssetId]);

  const handleCreateActivity = (e) => {
    const variables: ShortActivityModel = { ...newRelatedActivity };

    if (!accountId) return;
    if (!variables.StatusId && statuses.length) {
      variables.StatusId = statuses[0].Id;
    }
    if (!variables.AccountId) {
      variables.AccountId = accountId;
    }

    createNewActivity({
      variables: variables,
      update(cache, { data: { AssetActivityPost } }) {
        const assetRef: assetTypeRefFragmentType = cache.readFragment({
          fragment: assetNameFragment,
          id: AssetActivityPost.AssetId,
        });
        AssetActivityPost.Asset = {
          Name: assetRef?.Name,
          Id: AssetActivityPost.AssetId,
          __typename: "Asset",
        };

        AssetActivityPost["isLoading"] = false;
        AssetActivityPost["Images"] = [];
        addActivityToProjectTabCache(cache, AssetActivityPost, projectId);

        if (AssetActivityPost?.VendorRefs?.length) {
          updateVendorsActivityCacheWithNewActivity(cache, AssetActivityPost, ActivityIds);
        }

        const filteringString = filterString(
          statuses.find((element) => element.Id === AssetActivityPost.StatusId),
        );

        updateRelatedActivitiesCacheWithNewActivity(cache, AssetActivityPost);
        updateAllActivitiesCacheWithNewActivity(cache, AssetActivityPost, filteringString);
      },
    })
      .then(({ data }) => {
        setActivityToProject(data?.AssetActivityPost?.Id);
        handleActivityCreate();
        displaySuccessNotification(NotificationTypes.ACTIVITY_CREATED);
        setClearActivityNameField(true);
      })
      .catch(() => {
        handleActivityCreate();
        displayErrorNotification(NotificationTypes.ACTIVITY_CREATE_ERROR);
      });
  };

  const handleSubmit = (e) => {
    e.stopPropagation();

    const newError = new Map(errorInField);

    if (newRelatedActivity.Name && newRelatedActivity.Name.length > 0) {
      newError.delete("Name");
    } else {
      newError.set("Name", "Name must contain at least 1 character");
    }

    if (newRelatedActivity.AssetId) {
      newError.delete("AssetId");
    } else {
      newError.set("AssetId", "Choose activity asset");
    }

    setError(newError);

    if (newError.size > 0) return;

    if (newRelatedActivity.Id) {
      setActivityToProject(newRelatedActivity.Id);
      addActivityToProjectTabCache(client, newRelatedActivity, projectId);
      handleActivityCreate();
      setOptions([]);
      setClearActivityNameField(true);
      return;
    }
    handleCreateActivity(e);
  };

  const handleChangeActivity = useCallback(
    (e: React.SyntheticEvent, valueObj: { id: string; value: string }) => {
      let target = valueObj;
      if (e) {
        target = e.target;
      }
      if (!target) return;

      if (target.id === "AssetId" && newRelatedActivity.Id) {
        setOptions([]);
        updateRelatedActivity(new ShortActivityModel({ AssetId: target.value }));
        return;
      }
      updateRelatedActivity(null, { id: target.id, value: target.value });
    },
    [newRelatedActivity, setOptions, updateRelatedActivity],
  );

  const renderAddButton = () => (
    <IconButton
      style={{ marginBottom: "-8px", marginRight: "-8px", padding: "8px" }} // align to right side
      onClick={handleSubmit}
      color="primary"
      disabled={errorInField.has("Name") || errorInField.has("AssetId")}
    >
      {isLoading ? <CircularProgress size={22} /> : <AddCircleOutlineIcon />}
    </IconButton>
  );

  const onChangeActivityField = (e, target) => {
    const { id, value } = target;

    if (typeof value === "string") {
      const activity = { Name: value, AssetId: newRelatedActivity.AssetId };
      updateRelatedActivity(new ShortActivityModel(activity));
    } else if (value) {
      const activity = activitiesFromAsset?.find((activity) => activity.Id === value?.value);
      updateRelatedActivity(activity);
    } else if (!value) {
      const newActivity = { Name: "", AssetId: newRelatedActivity.AssetId };
      updateRelatedActivity(new ShortActivityModel(newActivity));
    }
  };

  const getNewValueBasedOnAction = (e, newValue) => {
    if (!newValue) {
      return { Id: null, Name: null };
    }

    return { Id: newValue.value, Name: newValue.label };
  };

  const cancelFieldResetHandler = () => setClearActivityNameField(false);

  const activityFieldValue = { value: newRelatedActivity.Id, label: newRelatedActivity.Name };

  return (
    <section className="Section Section_withBorder Section_defaultBackground">
      <WizardContainer
        isOpen={isWizardOpen}
        handleClose={handleCloseWizard}
        startStep={WIZARD_SCREENS_NAME.type_select}
        customerId={customerId}
        isInsideAddActivitySection={isCustomize}
        updateRelatedActivity={updateRelatedActivity}
      />
      <InputRow alignItems="flex-end" actionZoneComponent={renderAddButton}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ParentItemSelect
              AssetId={newRelatedActivity.AssetId || ""}
              errorMessage={errorInField.get("AssetId")}
              onChangeWithValidation={handleChangeActivity}
              getNewValueBasedOnAction={getNewValueBasedOnAction}
              customerId={customerId}
              disabled={isLoading}
              icon={<DescriptionOutlinedIcon />}
              handleOpenWizard={handleOpenWizard}
              justCreatedItemName={newRelatedActivity.Asset.Name}
              highlightLastOptionInSelect
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Combobox
              hasError={errorInField.get("Name")}
              label="Activity Name"
              options={options}
              handleChange={onChangeActivityField}
              value={activityFieldValue}
              creatable={true}
              required
              disabled={isLoading}
              id={"Name"}
              loading={loading}
              icon={<EditOutlined />}
              autoSelectFirstMatchOption
              isResetField={clearActivityNameField}
              cancelFieldReset={cancelFieldResetHandler}
            />
          </Grid>
        </Grid>
      </InputRow>
    </section>
  );
}

const mapStateToProps = (state) => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];
  return {
    filterString: (status) => {
      const filterObj = {
        id: status.Id,
        name: status.Name,
        offset: getActivityFilters(state).get(status.Id) || offset(status),
      };
      return getFilterString(filterObj);
    },
  };
};

export default connect(mapStateToProps)(
  React.memo(
    AddNewActivitySection,
    (prevProps, nextProps) => prevProps.newRelatedActivity === nextProps.newRelatedActivity,
  ),
);
