import { Image } from "./ImageModel";
import { AccountModel } from "./AccountModel";

export class UserInfoModel {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Images: Image[];
  Files: any;
  Accounts: AccountModel[];
  TimezoneId: string;
  IsPreview: boolean;

  constructor({
    Id = "",
    FirstName = "",
    LastName = "",
    Email = "",
    Images = [],
    Files = [],
    Accounts = [],
    TimezoneId = "",
    IsPreview = false,
  } = {}) {
    this.Id = Id;
    this.FirstName = FirstName || "";
    this.LastName = LastName || "";
    this.Email = Email;
    this.Images = Images || [];
    this.Files = Files || [];
    this.Accounts = Accounts || [];
    this.TimezoneId = TimezoneId || "";
    this.IsPreview = IsPreview || false;
  }
}
