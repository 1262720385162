import { useLocation } from "react-router-dom";

const useGetQueryParam = (): ((param: string) => string) => {
  const { search } = useLocation();

  const getQueryParam = (param: string = "") => {
    let queryParam = "";

    if (param.length) {
      queryParam = search.slice(search.indexOf(`${param}=`) + param.length + 1);
    }

    return queryParam;
  };

  return getQueryParam;
};

export default useGetQueryParam;
