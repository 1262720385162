import React from "react";

import { styled as materialStyled } from "@material-ui/core/styles";
import styled from "styled-components";

import Avatar from "@material-ui/core/Avatar";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

export const WizardItemCardContainer = styled.div(
  ({ isSelected, variant, theme }) => `
  display: flex;
  flex-direction: ${variant === "card" ? "column" : "row"};
  flex-shrink: 0;
  align-items: center;
  padding: ${variant === "card" ? "20px 10px 10px 10px" : "10px 12px"};
  text-align: center;
  color: ${isSelected ? theme.palette.primary.main : theme.palette.text.secondary};
  border: 1px solid ${isSelected ? theme.palette.primary.main : theme.palette.primary.light};;
  border-radius: ${theme.shape.borderRadius}px;
  cursor: pointer;

  ${WizardItemCardIcon} {
    width: ${variant === "card" ? "48px" : "24px"};
    height: ${variant === "card" ? "48px" : "24px"};
    font-size: ${variant === "card" ? "1.5rem" : "0.875rem"};
  }

  ${WizardItemCardText} {
    margin: ${variant === "card" ? `${theme.spacing(1)}px 0 0 0` : `0 0 0 ${theme.spacing(1)}px`};
    text-align: ${variant === "row" ? "left" : "center"};
  }
`,
);

export const WizardItemCardIcon = styled((props) => <Avatar {...props} />)(
  ({ theme }) => `
  background-color: ${theme.palette.primary.light};
  color: ${theme.palette.primary.main};
`,
);

export const WizardItemCardText = styled(Typography)`
  font-size: 0.875rem;
`;

export const WizardItemCardActionIcon = styled.div(
  ({ theme }) => `
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${theme.palette.primary.main};

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  `,
);

export const TextInCardLoading = styled(({ row, ...rest }) => <Skeleton {...rest} />)<{
  row?: boolean;
}>(
  ({ row = true }) => `
  width: ${row ? 180 : 84}px;
  height: 25px;
  `,
);

export const ActionInCardLoading = styled(({ withIcon, ...rest }) => <Skeleton {...rest} />)<{
  withIcon: boolean;
}>(
  ({ withIcon }) => `
  width: ${withIcon ? 44 : 20}px;
  height: ${withIcon ? 44 : 20}px;
`,
);
