import React, { useState } from "react";
import { useQuery } from "react-apollo";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { LayoutType } from "../../_constant/constants";

import CloseIcon from "@material-ui/icons/Close";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBox from "@material-ui/core/Checkbox";

import { FormControlLabelStyled, StyledCloseButton } from "./FinishProjectDialogStyle";

import ActivityItem from "../../containers/activities/_components/ActivityItem";
import { getActivitiesRelatedToProjectQuery } from "../ActivitiesTabProject/getActivitiesRelatedToProject.query";
import { useTheme } from "@material-ui/core";

interface FinishProjectDialogProps {
  visible: boolean;
  handleClose: () => void;
  projectId?: string | null;
  handleFinish: (shouldFinishActivities: boolean) => void;
  isUpdating: boolean;
}

const FinishProjectDialog: React.FC<FinishProjectDialogProps> = ({
  visible,
  handleClose,
  projectId,
  handleFinish,
  isUpdating,
}) => {
  const theme = useTheme();
  const [shouldFinishActivities, setShouldFinishActivities] = useState(false);

  const { accountId } = useGetAccountId();

  const { data, loading, error } = useQuery(getActivitiesRelatedToProjectQuery, {
    variables: {
      ProjectId: projectId,
    },
    skip: !projectId,
  });

  const finishedActivities = data?.Project?.AssetActivities ?? [];

  const handleSubmit = async () => {
    if (loading || !accountId) return;
    handleFinish(shouldFinishActivities);
  };

  const handleShouldFinishActivitiesCheckbox = () => {
    setShouldFinishActivities((preState) => !preState);
  };

  return (
    <Dialog onClose={handleClose} open={visible} maxWidth="sm" fullWidth>
      <DialogTitle id="customized-dialog-title" style={{ padding: "16px" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" style={{ fontSize: "1rem" }}>
            Finish Project
          </Typography>
          <StyledCloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </StyledCloseButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers style={{ padding: "16px" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
          <Typography variant="body2" style={{ fontSize: "12px", marginBottom: "8px" }}>
            Activities <span className="Label">({finishedActivities.length})</span>
          </Typography>
          <FormControlLabelStyled
            control={<CheckBox color="primary" disabled={loading || isUpdating} />}
            label="Mark All Activities Done"
            checked={shouldFinishActivities}
            onChange={handleShouldFinishActivitiesCheckbox}
          />
        </Box>
        <Box overflow="hidden" borderRadius="8px" border={`1px solid ${theme.palette.greyDesign.onSurface3}`} marginBottom="15px">
          {finishedActivities.map((activity: any) => {
            return (
              <ActivityItem
                key={activity.Id}
                activity={activity}
                dateType={activity.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
                layout={LayoutType.LIST}
                isAccountPersonal={false}
                isAssignmentsVisibile={true}
                finishedView
                isDraggable={false}
                isAssignmentWithoutControl={true}
              />
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          // justifyContent="space-between"
          style={{ width: "100%" }}
        >
          {/* <FormControlLabelStyled
            control={<CheckBox color="primary" disabled={loading || isUpdating} />}
            label="Notify all assigned members"
          /> */}
          <Box>
            <Button onClick={handleClose} color="primary" disabled={loading || isUpdating}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              startIcon={<CheckCircleRoundedIcon />}
              disabled={loading || isUpdating}
            >
              Finish
              {(loading || isUpdating) && (
                <CircularProgress size={20} style={{ position: "absolute" }} />
              )}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FinishProjectDialog;
