import React from "react";
import Button from "@material-ui/core/Button/Button";
import { convertValueToMeridiem, getMeridiem } from "../../../_utils/date";
import moment from "moment";
import {
  PickerToolbarContainer,
  PickerToolbarDay,
  PickerToolbarMeridiem,
  PickerToolbarTime,
  PickerToolbarTimeBtn,
} from "./DayAndTimePickerToolbar.style";

export type openViewType = "dayOfWeek" | "hours" | "minutes";
interface DayAndTimePickerToolbarProps {
  date: moment.Moment;
  openView: openViewType;
  handleDateChange: (date: moment.Moment) => void;
  changeOpenView: (viewType: openViewType) => void;
}

const DayAndTimePickerToolbar: React.FC<DayAndTimePickerToolbarProps> = ({
  openView,
  changeOpenView,
  date,
  handleDateChange,
}) => {
  const meridiemMode = getMeridiem(date);

  const getHandler = (type: openViewType) => {
    return () => changeOpenView(type);
  };

  const handleMeridiemChange = (newMeridiem: "am" | "pm") => {
    const newHours = convertValueToMeridiem(date.hours(), newMeridiem);

    if (typeof newHours === "number") {
      const newDate = moment(date).hours(newHours);
      handleDateChange(newDate);
    }
  };

  return (
    <PickerToolbarContainer>
      <PickerToolbarDay>
        <PickerToolbarTimeBtn
          onClick={getHandler("dayOfWeek")}
          $isSelected={openView === "dayOfWeek"}
        >
          {date.format("ddd")}
        </PickerToolbarTimeBtn>
      </PickerToolbarDay>
      <PickerToolbarTime>
        <PickerToolbarTimeBtn onClick={getHandler("hours")} $isSelected={openView === "hours"}>
          {date.format("hh")}
        </PickerToolbarTimeBtn>
        :
        <PickerToolbarTimeBtn onClick={getHandler("minutes")} $isSelected={openView === "minutes"}>
          {date.format("mm")}
        </PickerToolbarTimeBtn>
        <PickerToolbarMeridiem>
          <PickerToolbarTimeBtn
            onClick={() => handleMeridiemChange("am")}
            $isSelected={meridiemMode === "am"}
          >
            AM
          </PickerToolbarTimeBtn>
          <PickerToolbarTimeBtn
            onClick={() => handleMeridiemChange("pm")}
            $isSelected={meridiemMode === "pm"}
          >
            PM
          </PickerToolbarTimeBtn>
        </PickerToolbarMeridiem>
      </PickerToolbarTime>
    </PickerToolbarContainer>
  );
};

export default DayAndTimePickerToolbar;
