import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { isConnectionLost as checkConnectionState } from "../redux/reducers/appReducer";
import { setConnectionState } from "../redux/actions/actions";

interface useConnectionStateReturnValue {
  isConnectionLost: boolean;
  setConnectionState: (connectionLostFlag: boolean) => void;
}

export const useConnectionState = (): useConnectionStateReturnValue => {
  const isConnectionLost: boolean = useSelector(checkConnectionState, shallowEqual);
  const dispatch = useDispatch();

  const changeConnectionLostState = (connectionLostFlag: boolean) => {
    dispatch(setConnectionState(connectionLostFlag));
  };

  return {
    isConnectionLost,
    setConnectionState: changeConnectionLostState,
  };
};
