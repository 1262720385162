import * as React from "react";
import { connect } from "react-redux";

import {ROUTES} from "../_constant/screens";
import {getAppUrl, getChangePassUrl} from "../redux/reducers/appReducer";

interface ChangePasswordPageProps {
  appUrl: string;
  changePasswordUrl: string;
}

const ChangePasswordPage: React.FC<ChangePasswordPageProps> = ({appUrl, changePasswordUrl}) => {
  window.location.href = `${changePasswordUrl}?returnUrl=${appUrl}${ROUTES.EDIT_PROFILE}`;
  return null;
};

const mapStateToProps = (state) => ({
  changePasswordUrl: getChangePassUrl(state),
  appUrl: getAppUrl(state)
});

export default connect(mapStateToProps)(ChangePasswordPage);