import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import useGetAccountId from "../../customHooks/useGetAccountId";

import List from "@material-ui/core/List/List";
import Box from "@material-ui/core/Box/Box";

import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";
import { ROUTES } from "../../_constant/screens";

import { composeImageUrl } from "../../_utils/utils";
import { setMemberId } from "../../redux/actions/actions";
import { getSelectedMemberId } from "../../redux/reducers/filterReducer";

import FilterListItem from "./FilterListItem";

import { SectionHeaderText, StyledButton } from "./FilterVisitDesktop.style";

const MembersFilterList: React.FC = () => {
  const { accountId } = useGetAccountId();

  const selectedMemberId = useSelector(getSelectedMemberId, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: accountUsers } = useQuery(getAccountUsersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });
  const usersList = accountUsers?.Account?.UserRefs ?? [];

  const handlerListItemClick = useCallback((payload) => dispatch(setMemberId(payload)), []);

  const handleAddMemberClick = () => {
    navigate(
      location.pathname === ROUTES.PROJECTS
        ? `${ROUTES.SINGLE_ACCOUNT}/${accountId}`
        : ROUTES.EDIT_BUSINESS,
    );
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem"
        paddingBottom="0"
      >
        <SectionHeaderText>Members: {usersList.length}</SectionHeaderText>
        <StyledButton variant="text" color="primary" onClick={handleAddMemberClick}>
          Add new
        </StyledButton>
      </Box>
      <List>
        {usersList.map((member: any) => {
          const customerImage = member.MainImage
            ? composeImageUrl(
                member.MainImage.Id,
                member.MainImage.ContainerName,
                member.MainImage.ContainerId,
              )
            : "";
          const isMemberSelected = selectedMemberId === member.Id;
          const memberName = `${member?.FirstName ?? ""} ${member?.LastName ?? ""}`.trim();
          return (
            <FilterListItem
              key={member.Id}
              id={member.Id}
              text={memberName}
              isActive={isMemberSelected}
              imgUrl={customerImage}
              handleClick={handlerListItemClick}
            />
          );
        })}
      </List>
    </>
  );
};

export default MembersFilterList;
