import gql from "graphql-tag";

export const userRefFragment = gql`
  fragment UserRef on UserRef {
    FirstName
    LastName
    MainImage {
      Id
      ContainerId
      ContainerName
    }
  }
`;
