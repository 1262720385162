import gql from "graphql-tag";

export const GET_ACTIVITIES_LIST = gql`
  query getActivitiesList($Id: ID, $Filter: String!) {
    Account(Id: $Id) {
      Id
      AssetActivities(Filter: $Filter) {
        AssetId
        Asset @client {
          Name
          Id
        }
        isLoading @client
        Id
        Name
        Description
        DueDateTime
        CompletedDateTime
        StatusId
        Schedule {
          RecurringType
        }
        Images {
          Id
          IsPrimary
          Name
          AbsoluteUri
          FileExtension
          ContainerId
          ContainerName
          TotalBytes
        }
        Documents {
          Id
          Name
          ContainerId
          ContainerName
          AbsoluteUri
          FileExtension
        }
        Videos {
          Id
          Name
          ContainerId
          ContainerName
          FileExtension
          AbsoluteUri
        }
        RelatedDueDateTime
        AssignedUserId
      }
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation changeStatus(
    $AccountId: ID
    $AssetId: ID
    $Id: ID
    $StatusId: ID
    $CompletedDateTime: DateTimeOffset
    $AssignedUserId: ID
  ) {
    AssetActivityPatch(
      AssetActivity: {
        AccountId: $AccountId
        AssetId: $AssetId
        Id: $Id
        StatusId: $StatusId
        CompletedDateTime: $CompletedDateTime
        AssignedUserId: $AssignedUserId
      }
    ) {
      Id
      StatusId
      CompletedDateTime
      AssignedUserId
    }
  }
`;
