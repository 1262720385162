import * as React from "react";
import { useState, useEffect, CSSProperties } from "react";
import { useQuery } from "react-apollo";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import { GET_EDIT_FLAGS } from "./asset-type.query";
import { ACCOUNT } from "../../../../_constant/wordings";

interface AssetTypeIconProps {
  Id: string;
  iconName: string;
  openDialog;
  direction?: "row" | "column";
  iconStyles?: CSSProperties;
}

const AssetTypeIcon: React.FC<AssetTypeIconProps> = ({
  Id,
  iconName,
  openDialog,
  direction,
  iconStyles,
}) => {
  const [editMap, setEditMap] = useState(new Map());

  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(GET_EDIT_FLAGS, {
    variables: {
      Id: accountId,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (!loading && !error) {
      const nextIcon = data[ACCOUNT]["AssetTypes"].map((item) => [item.Id, item.IsSystem]);
      setEditMap(new Map(nextIcon));
    }
  }, [data]);

  const containerStyles =
    direction &&
    ({
      display: "flex",
      flexDirection: direction,
      alignItems: "center",
      fontSize: "16px",
      gap: "6px",
    } as CSSProperties);

  return editMap.get(Id) ? (
    <i className="Icon Icon-system" style={iconStyles}>
      {iconName}
    </i>
  ) : (
    <div className="Icon_edit" onClick={openDialog} style={containerStyles}>
      <i className="Icon Icon-editable" style={{ fontSize: "16px" }}>
        {iconName}
      </i>
      <span className={"Label"}>Edit</span>
    </div>
  );
};

export default AssetTypeIcon;
