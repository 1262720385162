import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";

export const FormControlLabelStyled = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    padding: 0 8px;
    margin: 0;
  }

  .MuiTypography-root {
    font-size: 12px;
    user-select: none;
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-right: 10px;
  }

  .MuiSvgIcon-root {
    height: 0.875rem;
    width: 0.875rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
