import gql from "graphql-tag";

export const GET_ASSETS_FOR_SELECT = gql`
  query getAssetsForSelect($Id: ID) {
    Account(Id: $Id) {
      Id
      Assets {
        Id
        Name
        ParentId
      }
    }
  }
`;