import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { VENDORS } from "../../../_constant/wordings";
import { getVendorsQuery } from "../../AllVendorsWrapper/getVendors.query";
import { VendorModel } from "../../../redux/models/data/VendorModel";

export const updateCacheAfterDeleteVendor = (
  cache: DataProxy,
  vendorDelete: Pick<VendorModel, "Id">,
  accountId: string,
) => {
  try {
    const vendorListQuery: any = cache.readQuery({
      query: getVendorsQuery,
      variables: { AccountId: accountId },
    });

    const newVendorList = vendorListQuery[VENDORS].filter(
      (vendor: VendorModel) => vendor.Id !== vendorDelete.Id,
    );

    cache.writeQuery({
      query: getVendorsQuery,
      variables: { AccountId: accountId },
      data: { Vendors: newVendorList },
    });
  } catch (error) {
    console.error(error);
  }
};
