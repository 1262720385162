import gql from "graphql-tag";

export const getCustomerByIdQuery = gql`
  query getCustomer($CustomerId: ID, $AccountId: ID) {
    Account(Id: $AccountId) {
      Id
      Customers(Id: $CustomerId) {
        Address
        Name
        Email
        Id
        Phone
        Images {
          Id
          ContainerId
          ContainerName
          Description
          FileExtension
          Name
          IsPrimary
        }
        Documents {
          Id
          Name
          ContainerId
          ContainerName
          AbsoluteUri
          FileExtension
        }
        Videos {
          Id
          Name
          ContainerId
          ContainerName
          FileExtension
          AbsoluteUri
        }
      }
    }
  }
`;
