import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";
import { useLeaveDialogContext } from "../../containers/_common/useLeaveDialogContext";

import { Badge, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import FilterListIcon from "@material-ui/icons/FilterList";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import { ROUTES } from "../../_constant/screens";

import {
  getSelectedAssetType,
  getSelectedCustomerId,
  getSelectedMemberId,
} from "../../redux/reducers/filterReducer";
import { isEditPage } from "../../redux/actions/updateData";

import SettingsNav from "../SettingsNav/SettingsNav";
import HeaderNavMobile from "../HeaderNavMobile/HeaderNavMobile";
import FormMenu from "../../containers/_common/header/FormMenu";
import Search from "../Search";
import HeaderAccount from "../../containers/_common/header/HeaderAccount";
import ElevationScroll from "../ElevationScroll/ElevationScroll";

import {
  StyledMobileAppBar,
  StyledMobileHeader,
  StyledMobileHeaderActions,
  StyledMobileHeaderButton,
  StyledMobileHeaderContent,
} from "./BusinessHeaderStyle";

interface LocationState {
  firstLoad?: boolean;
}

interface CustomerHeaderProps {
  isBusinessPage: boolean;
}

const BusinessHeader: React.FC<CustomerHeaderProps> = ({ isBusinessPage }) => {
  const navigation = useNavigate();
  const location = useLocation<LocationState>();
  const filterValue = useSelector((state) => getSelectedAssetType(state));
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const selectedMemberId = useSelector(getSelectedMemberId);
  const { headerOptions, setHeaderOptions } = useHeaderOptions();
  const {
    filterOpen,
    withBackButton,
    withSettingsNav,
    pageTitle,
    saveOptions,
    isFormDirty,
    withAddButton,
    addButtonCallback,
  } = headerOptions;

  const { showDialogBeforeChangeUrl } = useLeaveDialogContext();

  const handleGoBack = () => {
    if (location.state?.firstLoad) {
      navigation(ROUTES.VISITS);
    } else {
      isFormDirty ? showDialogBeforeChangeUrl(() => navigation(-1)) : navigation(-1);
    }
  };
  const defaultMobileIcon = filterOpen ? <MenuOpenIcon /> : <FilterListIcon />;

  return (
    <ElevationScroll>
      <StyledMobileAppBar
        $isBusinessStyles={isBusinessPage}
        $isEditPage={isEditPage(location.pathname)}
      >
        <StyledMobileHeader $isBusinessStyles={isBusinessPage}>
          <StyledMobileHeaderButton>
            <IconButton
              onClick={
                withBackButton ? handleGoBack : () => setHeaderOptions({ filterOpen: !filterOpen })
              }
            >
              <Badge
                variant="dot"
                invisible={!filterValue && !selectedMemberId && !selectedCustomerId}
              >
                {!withBackButton ? (
                  defaultMobileIcon
                ) : isFormDirty ? (
                  <CloseOutlinedIcon />
                ) : (
                  <ArrowBackIcon />
                )}
              </Badge>
            </IconButton>
          </StyledMobileHeaderButton>
          <StyledMobileHeaderContent>
            {withSettingsNav ? <SettingsNav /> : <HeaderNavMobile pageTitle={pageTitle} />}
          </StyledMobileHeaderContent>
          <StyledMobileHeaderActions>
            {isEditPage(location.pathname) ? (
              <>
                <IconButton
                  disabled={saveOptions.disabled || saveOptions.isLoading}
                  onClick={saveOptions.saveFunction}
                >
                  {saveOptions.isLoading ? (
                    <CircularProgress size={22} color="inherit" />
                  ) : (
                    <SaveOutlinedIcon color={saveOptions.disabled ? "disabled" : "inherit"} />
                  )}
                </IconButton>
                <FormMenu saveOptions={saveOptions} isFormDirty={isFormDirty} />
              </>
            ) : (
              <>
                <Search placeholder={"Search"} mobile />
                {withAddButton && (
                  <IconButton aria-label="add" onClick={addButtonCallback}>
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                )}
                <HeaderAccount isMobileVersion />
              </>
            )}
          </StyledMobileHeaderActions>
        </StyledMobileHeader>
      </StyledMobileAppBar>
    </ElevationScroll>
  );
};

export default BusinessHeader;
