import * as React from "react";
import { Button } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

interface IShowDetailsButton {
  extraText?: boolean;
  isOpen: boolean;
  handleDetailClick: (e: React.SyntheticEvent) => void;
  isHistory: boolean;
}

const ShowDetailsButton = (props: IShowDetailsButton) => {
  const { extraText, isOpen, handleDetailClick, isHistory } = props;

  const color = isHistory || isOpen ? "default" : "primary";

  if (!extraText) {
    return (
      <IconButton color={color} onClick={handleDetailClick}>
        {isOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    );
  }

  return (
    <Button
      className="invitation-item__detailsBtn"
      variant="text"
      color={color}
      startIcon={isOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
      onClick={handleDetailClick}
      style={extraText ? { marginRight: "20px" } : undefined}
    >
      {`${isOpen ? "Hide" : "See"} Details`}
    </Button>
  );
};

export default ShowDetailsButton;
