import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { useChange } from "../../customHooks/useChange";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { AccountModel } from "../../redux/models/data/AccountModel";

import { ROUTES } from "../../_constant/screens";
import { ACCOUNT_NAME, NAME } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";

import { setEditFormIsDirty } from "../../redux/actions/actions";
import { displayWarningNotification } from "../../redux/actions/_utils";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import Form from "../Form/Form";
import Body from "../Body/Body";
import Input from "../Input/Input";
import InputRow from "../../components/InputRow/InputRow";
import FormHeader from "../../components/FormHeader/FormHeader";
import DropZoneContainer from "../DropZoneContainer/DropZoneContainer";

import Modal from "../../containers/Modal/Modal";
import { RevertDialogComponent } from "../../containers/_common/Dialog";
import LeaveAccountContainer from "../../containers/accounts/leave/LeaveAccountContainer";
import { DeleteAccountDialog } from "../../containers/accounts/delete/DeleteAccountDialog";

import AccountUsersTab from "../AccountUsersTab/AccountUsersTab";
import AccountManageTab from "../AccountManageTab/AccountManageTab";
import EntityTabsProvider from "../EntityTabsContainer/EntityTabsProvider";
import { InvitationTabContent } from "../InvitationTabContent/InvitationTabContent";
import EntityTabsContainer from "../../components/EntityTabsContainer/EntityTabsContainer";

interface IAccountFormProps {
  handleSubmit: (data: AccountModel, saveType: SaveType) => void;
  isLoading: boolean;
  isNew: boolean;
  data: AccountModel;
  setHeaderOptions: (props: any) => void;
  isBusinessAccount?: boolean;
}

const AccountForm = (props: IAccountFormProps) => {
  const {
    handleSubmit: submitFunction,
    isLoading,
    isNew,
    data: propsData,
    setHeaderOptions,
    isBusinessAccount = false,
  } = props;

  const formInitObject = {
    initData: propsData ? propsData : new AccountModel(),
    classModel: AccountModel,
    requiredFields: ["Name"],
    fieldsForValidation: new Map([["Name", null]]),
  };
  const formApi = useChange<AccountModel>(formInitObject);
  const data = formApi.data;

  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isLeavePopupVisible, setIsLeavePopupVisible] = useState(false);
  const lockSaveButton = formApi.isDataChanged && formApi.isDataValid;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const dispatch = useDispatch();
  const limits = useGetLimits();

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    if (propsData && !isLoading) {
      formApi.updateInitFormData(new AccountModel({ ...propsData }));
    }
  }, [propsData]);

  const handleSubmit = (e?: React.SyntheticEvent<Element, Event> | null, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: SaveType = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: () => formApi.updateInitFormData(formApi.data),
    };

    submitFunction(formApi.data, saveType);
  };

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value ?? "";
    formApi.handleChange(null, { value, id: NAME });
  };

  useEffect(() => {
    setHeaderOptions({
      withSettingsNav: true,
      pageTitle: isNew ? "Create Account" : "Account Details",
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        revertFunction: openRevertDialog,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading]);

  const handleLeavePopupOpen = () => {
    setIsLeavePopupVisible(true);
  };

  const handleLeavePopupClose = () => {
    setIsLeavePopupVisible(false);
  };

  const accountTabs = [
    {
      name: "Members",
      disabled: isNew,
      content: <AccountUsersTab accountId={formApi.data.Id} maxMembers={data.MaxMembers} />,
    },
    {
      name: "Invite",
      content: (
        <InvitationTabContent accountId={formApi.data.Id} maxMembers={data.MaxMembers?.Value} />
      ),
      disabled: isNew,
    },
    {
      name: "Manage",
      disabled: isNew,
      content: (
        <AccountManageTab
          id={formApi.data.Id}
          isDataChanged={formApi.isDataChanged}
          isLoading={isLoading}
          isDeletePopupVisible={isDeletePopupVisible}
          handleOpen={() => setIsDeletePopupVisible(true)}
          handleLeavePopupOpen={handleLeavePopupOpen}
        />
      ),
    },
  ];

  return (
    <Body disableDesktopScroll>
      <Modal isShowing={isRevertPopupVisible}>
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      </Modal>
      <Modal isShowing={isDeletePopupVisible}>
        <DeleteAccountDialog
          isOpen={isDeletePopupVisible}
          handleClose={() => setIsDeletePopupVisible(false)}
          accountId={data.Id}
          accountName={data.Name}
        />
      </Modal>
      <Modal isShowing={isLeavePopupVisible}>
        <LeaveAccountContainer
          handleLeavePopupClose={handleLeavePopupClose}
          accountId={formApi.data.Id}
        />
      </Modal>
      <Form>
        <Form header>
          <FormHeader
            isNew={isNew}
            isBusinessAccount={isBusinessAccount}
            title={"Account"}
            isLoading={isLoading}
            isSaveAvailable={lockSaveButton}
            handleSubmit={handleSubmit}
            cancelRouteForEmptyHistory={ROUTES.ACCOUNTS}
            revertData={openRevertDialog}
            isDataChanged={formApi.isDataChanged}
          />
        </Form>

        <Form body>
          <Form content>
            <div className="Section">
              <InputRow alignItems="flex-end">
                <Input
                  type="text"
                  onChange={handleNameChange}
                  value={data.Name}
                  label={isBusinessAccount ? "Business Account name" : "Account name"}
                  id={ACCOUNT_NAME}
                  fullWidth
                  icon={<CreateOutlinedIcon />}
                  required
                  helperText="* Required field"
                  error={formApi.errorFields.has("Name")}
                  errorText={formApi.errorFields.get("Name")}
                />
              </InputRow>
              <InputRow alignItems="flex-end">
                <Input
                  type="text"
                  label="Description"
                  icon={<CreateOutlinedIcon />}
                  id={"Description"}
                  value={data.Description}
                  onChange={formApi.handleChange}
                />
              </InputRow>
            </div>
            {
              <EntityTabsProvider>
                <EntityTabsContainer
                  content={accountTabs}
                  entityName="account"
                  isNew={isNew}
                  startFrom="0"
                  tabIndexAfterCreate="1"
                  removeScrollButtons={isMobile}
                  fillAvailableWidth={isMobile}
                />
              </EntityTabsProvider>
            }
          </Form>
          <Form images>
            <DropZoneContainer
              data={formApi.data}
              handleChange={formApi.handleChange}
              updateManyFields={formApi.updateManyFields}
              entityName="account"
              isMobile={isMobile}
              isSingleFile
              imagesOnly
              limitDocuments={limits?.asset.documents.Value}
              limitImages={limits?.asset.images.Value}
              limitVideos={limits?.asset.videos.Value}
            />
          </Form>
        </Form>
      </Form>
    </Body>
  );
};

export default AccountForm;
