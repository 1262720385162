import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import styled from "styled-components";
import withScrolling from "react-dnd-scrolling";
import { useNavigate, Link } from "react-router-dom";
import { useMutation, useQuery } from "react-apollo";

import useGetUserId from "../../../customHooks/useGetUserId";
import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";
import { useLayoutSwitcherContext } from "../../../components/LayoutSwitcher/useLayoutSwitcherContext";
import { useIsItemsExistsForList } from "../../../customHooks/useIsItemsExistsForList";

import { getAccountUsersQuery } from "../../../components/AccountUsersTab/AccountUsersTab";
import { CHANGE_STATUS, GET_ACTIVITIES_LIST } from "./activities-list.query";

import { ROUTES } from "../../../_constant/screens";
import { ACTIVITY_PATCH } from "../../../_constant/wordings";
import { EMPTY_ID, LayoutType } from "../../../_constant/constants";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { FILTERING_DETAILS } from "../../../_constant/ActivityDateConstant";
import * as statusesConfig from "../../../../configs/statuses-config.json";

import { activityEmptyStatusList, getActivityFilters } from "../../../redux/reducers/appReducer";
import { getTitlePrefix, isCompletedStatus, isRecurring } from "../../../_utils/utils";
import { setActivitiesFilterValue } from "../../../redux/actions/actions";
import {
  getFilterValue,
  getSearchStringValue,
  getSelectedAssetType,
} from "../../../redux/reducers/filterReducer";
import {
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../../../redux/actions/_utils";
import {
  AllActivitiesCacheUpdate,
  turnOffLoadingInActivityCache,
} from "./AllActivitiesCacheUpdate";
import {
  RelatedActivitiesCacheUpdate,
  createQueryOptions,
} from "../../assets/_components/relaitedActivities/RelatedActivitiesCacheUpdate";

import AddIcon from "@material-ui/icons/Add";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Button, Container } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import Body from "../../../components/Body/Body";
import AllActivitiesByStatus from "./content/ActivitiesByStatus";
import { LoaderCircle } from "../../../components/Loader";
import ActionsBar from "../../../components/ActionsBar/ActionsBar";
import ActivitiesList from "../../../components/ActivitiesList/ActivitiesList";
import ActivitiesSelectFilter from "../../../components/ActivitiesSelectFilter/ActivitiesSelectFilter";
import LayoutSwitcherActivitiesContainer from "../../../components/LayoutSwitcher/LayoutSwitcherActivitiesContainer";

const { EMPTY } = statusesConfig["statusNameMap"];

interface ActivitiesPageProps {
  searchForActivities: (searchStr: string) => void;
  redirectToNewActivity: () => void;
  getFilterString: (status) => string;
  titlePrefix: string;
  activitiesEmptyList: Set<string>;
  filterValue: string;
  setActivitiesFilterValue: (filterValue: string) => void;
}

const ScrollingComponent = withScrolling("div");
export const StyledAddButtonMobile = styled(({ ...props }) => <IconButton {...props} />)`
  background-color: ${({theme}) => theme.palette.primary.main};
  color: ${({theme}) => theme.palette.primary.contrastText};
  width: 32px;
  height: 32px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: ${({theme}) => theme.palette.primary.dark};
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &[type="button"] {
    padding: 0;
  }
`;

const AllActivities: React.FC<ActivitiesPageProps> = (props) => {
  const [statusOptions, setStatuses] = useState<Map<string, any>>(new Map());

  const { accountId, isPersonal } = useGetAccountId();
  const {
    state: { activities: layout },
  } = useLayoutSwitcherContext();

  const statuses = useGetAcitvitiesStatuses();
  const userId = useGetUserId();

  const navigate = useNavigate();

  useEffect(() => {
    if (statuses.length > 0) {
      const newActivitiesMap = statuses.map((item) => [item.Id, item]);
      setStatuses(new Map(newActivitiesMap));
    }
  }, [statuses]);

  const { exists, isDataAvailable } = useIsItemsExistsForList();

  useEffect(() => {
    if (isDataAvailable && !exists) {
      navigate(ROUTES.ASSETS, { replace: true });
    }
  }, [exists, isDataAvailable]);

  const {
    data: accountUsers,
    loading: usersLoading,
    error: usersError,
    client: accountsClient,
  } = useQuery(getAccountUsersQuery, {
    variables: { Id: accountId },
    skip: !accountId || !!isPersonal,
    fetchPolicy: "cache-first",
  });

  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  useHeaderOptions({ pageTitle: "Activities" });
  useEffect(() => {
    if (statuses.length > 0) {
      setShowEmptyMessage(props.activitiesEmptyList.size === statuses.length);
    }
  }, [props.activitiesEmptyList.size]);

  const [updateStatus, { client }] = useMutation(CHANGE_STATUS);

  const updateActivityStatus = (activity: any, nextStatusId: string) => {
    const filterStringFrom = props.getFilterString(
      statuses.find((element) => element.Id === activity.StatusId),
    );
    const filterStringTo = props.getFilterString(
      statuses.find((element) => element.Id === nextStatusId),
    );
    const CompletedDateTime = isCompletedStatus(statuses, nextStatusId)
      ? moment().toISOString()
      : null;

    const recurring = isRecurring({
      schedule: activity.Schedule,
      relatedDueDate: activity.RelatedDueDateTime,
    });
    const relatedActivityRefetchOptions = createQueryOptions(
      accountId,
      activity.AssetId,
      statuses[0].Id,
    );

    const refetchQueries: any =
      nextStatusId === statuses[statuses.length - 1].Id && recurring
        ? [
            {
              query: GET_ACTIVITIES_LIST,
              variables: {
                Id: accountId,
                Filter: props.getFilterString(statuses[0]),
              },
            },
          ]
        : [];
    try {
      if (client?.cache.readQuery(relatedActivityRefetchOptions)) {
        refetchQueries.push(relatedActivityRefetchOptions);
      }
    } catch (e) {}

    const selectedStatusName = statusOptions.get(nextStatusId)?.Name || "";

    const assignedUserId =
      !isPersonal &&
      !activity.AssignedUserId &&
      activity.AssignedUserId !== EMPTY_ID &&
      selectedStatusName &&
      selectedStatusName !== EMPTY
        ? userId
        : activity.AssignedUserId;

    updateStatus({
      variables: {
        AccountId: accountId,
        AssetId: activity.AssetId,
        Id: activity.Id,
        StatusId: nextStatusId,
        CompletedDateTime,
        AssignedUserId: assignedUserId,
      },
      optimisticResponse: {
        AssetActivityPatch: {
          Id: activity.Id,
          StatusId: nextStatusId,
          CompletedDateTime,
          __typename: "AssetActivity",
        },
      },
      update: (cache, { data: { AssetActivityPatch } }) => {
        AllActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPatch,
          filterStringFrom,
          filterStringTo,
          accountId,
        );
        RelatedActivitiesCacheUpdate(
          cache,
          activity,
          AssetActivityPatch,
          accountId,
          nextStatusId,
          CompletedDateTime,
        );
      },
      refetchQueries, //should keep refetch for To do column in case of repeating activity completed
    })
      .then(({ data }) => {
        if (data[ACTIVITY_PATCH] && data[ACTIVITY_PATCH].StatusId === nextStatusId) {
          turnOffLoadingInActivityCache(client, activity, filterStringTo, accountId);
          displaySuccessNotification(NotificationTypes.ACTIVITY_STATUS_UPDATED);
        } else {
          throw "null instead of data";
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.ACTIVITY_STATUS_UPDATED_ERROR);
      });
  };

  const actionBarTitle =
    isPersonal || !accountId ? (
      `${props.titlePrefix} Activities`
    ) : (
      <ActivitiesSelectFilter
        filterValue={props.filterValue}
        onChange={props.setActivitiesFilterValue}
        usersLoaded={!usersLoading}
        users={accountUsers?.Account.UserRefs || []}
        currentAccountId={accountId}
      />
    );

  return (
    <Body disableDesktopScroll disableMobileScroll>
      <Container maxWidth="md">
        <ActionsBar title={actionBarTitle} withSearch>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <LayoutSwitcherActivitiesContainer />
            </Grid>
            <Grid item>
              <Hidden smDown>
                <Button
                  component={Link}
                  to={ROUTES.CREATE_ACTIVITY}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Activity
                </Button>
              </Hidden>

              <Hidden mdUp>
                <StyledAddButtonMobile component={Link} to={ROUTES.CREATE_ACTIVITY}>
                  <AddIcon />
                </StyledAddButtonMobile>
              </Hidden>
            </Grid>
          </Grid>
        </ActionsBar>
      </Container>
      {statuses.length === 0 && (
        <div className={"LoaderWrapper"}>
          <LoaderCircle size={"xl"} />
        </div>
      )}
      {statuses.length > 0 && (
        <ActivitiesList listView={layout === LayoutType.LIST}>
          {statuses.map((status) => (
            <AllActivitiesByStatus
              key={status.Id}
              status={status}
              statuses={statuses}
              updateActivityStatus={updateActivityStatus}
              filterString={props.getFilterString(status)}
              isAccountPersonal={isPersonal}
              showEmptyMessage={showEmptyMessage}
            />
          ))}
        </ActivitiesList>
      )}
    </Body>
  );
};

const mapStateToProps = (state) => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];
  return {
    getFilterString: (status) => {
      const filterObj = {
        id: status.Id,
        name: status.Name,
        offset: getActivityFilters(state).get(status.Id) || offset(status),
      };
      return getFilterString(filterObj);
    },
    titlePrefix: getTitlePrefix(!!getSearchStringValue(state), !!getSelectedAssetType(state)),
    activitiesEmptyList: activityEmptyStatusList(state),
    filterValue: getFilterValue(state),
  };
};

const mapDispatchToProps = {
  setActivitiesFilterValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllActivities);
