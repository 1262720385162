import React from "react";

import { useQuery } from "@apollo/react-hooks";

import Container from "@material-ui/core/Container";

import Body from "../../components/Body/Body";
import ActionsBar from "../../components/ActionsBar/ActionsBar";

import { getNotificationsQuery } from "./Notifications.query";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetUserId from "../../customHooks/useGetUserId";
import { getAccountRefsList } from "../Accounts/getAccountList.query";
import { ACCOUNT_REFS, USER } from "../../_constant/wordings";
import { AccountModel } from "../../redux/models/data/AccountModel";
import AccountNotificationSettings from "../../components/AccountNotificationSettings/AccountNotificationSettings";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { ROUTES } from "../../_constant/screens";
import { NavLink } from "react-router-dom";

const Notifications: React.FC = () => {
  const { accountId } = useGetAccountId();
  const userId = useGetUserId();
  const { data, loading: notificationsLoading, error } = useQuery(getNotificationsQuery, {
    variables: {
      UserId: userId,
    },
    skip: !userId,
  });

  const { data: accountList, loading: accountsLoading, error: accountsError } = useQuery(
    getAccountRefsList,
    {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
      skip: !accountId,
    },
  );

  const getNotificationsForAccount = (accountId: string) => {
    return data && accountId
      ? data.AccountNotificationSettingModel.filter(
          (notification: any) => notification.AccountId === accountId,
        )
      : [];
  };

  return (
    <Body>
      <Container maxWidth="md" className="Notifications">
        <ActionsBar
          title={
            <Typography component="h1" variant="body1" style={{ fontWeight: 500 }}>
              Notifications
            </Typography>
          }
        />

        <Box style={{ marginBottom: 15 }}>
          <Typography style={{ fontSize: "14px" }}>
            Notifications will be sent based on your
            <Link to={ROUTES.EDIT_PROFILE} component={NavLink} underline="none">
              {" "}
              time zone settings
            </Link>
          </Typography>
        </Box>

        {accountList
          ? accountList[USER][ACCOUNT_REFS].map((account: AccountModel) => {
              return (
                <AccountNotificationSettings
                  key={account.Id}
                  account={account}
                  notifications={getNotificationsForAccount(account.Id)}
                  loading={notificationsLoading}
                />
              );
            })
          : Array(5)
              .fill(null)
              .map((_, index) => {
                return (
                  <Skeleton
                    key={index}
                    variant="rect"
                    width="100%"
                    height={75}
                    style={{ marginBottom: 20, borderRadius: 4 }}
                  />
                );
              })}
      </Container>
    </Body>
  );
};

export default Notifications;
