// Related Activity with Asset
import gql from "graphql-tag";

export const relatedActivityWithAssetQuery = gql`
  mutation relatedActivityWithAsset($AccountId: ID, $Id: ID, $VendorRefs: [VendorRefInputType]) {
    AssetActivityPatch(AssetActivity: { AccountId: $AccountId, Id: $Id, VendorRefs: $VendorRefs }) {
      AccountId
      Id
      Name
      AssetId
      Description
      StatusId
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        IsPrimary
        Name
        FileExtension
        ContainerId
        ContainerName
        TotalBytes
        AbsoluteUri
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      VendorRefs {
        Id
      }
      FileUploadResults {
        ErrorMessages
        FileName
        Status
      }
      AssignedUserId
    }
  }
`;
