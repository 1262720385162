import { useMutation } from "react-apollo";
import { cancelInvitationQuery } from "../components/InvitationCard/cancelInvitation.query";

const useGetApolloClient = () => {
  const [_, { client }] = useMutation(cancelInvitationQuery); // just need to get apollo-client entity

  return client ?? null;
};

export default useGetApolloClient;
