import * as React from "react";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { dataApiInterface } from "../../customHooks/useChange";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid";

import { RevertDialogComponent } from "../../containers/_common/Dialog";
import EntityTabsContainer from "../../components/EntityTabsContainer/EntityTabsContainer";
import EntityTabsProvider from "../EntityTabsContainer/EntityTabsProvider";
import Modal from "../../containers/Modal/Modal";
import Form from "../Form/Form";
import Body from "../Body/Body";

import { VisitModel } from "../../redux/models/data/VisitModel";
import { setEditFormIsDirty } from "../../redux/actions/actions";
import { getVisitBooleanStatus } from "../../_utils/utils";

import { ROUTES } from "../../_constant/screens";

import DeleteButton from "../DeleteButton/DeleteButton";
import DeleteVisitDialog from "../DeleteVisitDialog/DeleteVisitDialog";
import ActivitiesTab from "../ActivitiesTab/ActivitiesTab";
import RelatedActivities from "../../containers/assets/_components/relaitedActivities/RelatedActivities";
import CustomizeActivityButton from "../CustomizeActivityButton/CustomizeActivityButton";
import { ITabContent } from "../CustomerForm/CustomerFormDesktop";
import ItemsTab from "../ItemsTab/ItemsTab";

import CustomerTab from "../CustomerTab/CustomerTab";
import ActionsBar from "../ActionsBar/ActionsBar";
import FormPageTitle from "../FormPageTitle/FormPageTitle";
import SaveButton from "../SaveButton/SaveButton";
import StartVisitButtonContainer from "../../containers/visits/_components/StartVisitButtonContainer";

import { ButtonsContainer } from "./VisitForm.style";
import VisitFormLayout from "./VisitFormLayout";
import { VisitFormContext } from "./VisitFormContext";
import FinishVisitDialog from "../FinishVisitDialog/FinishVisitDialog";

interface IVisitFormProps {
  handleSubmit: (e?: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
  isLoading: boolean;
  isNew: boolean;
  data: VisitModel;
  setHeaderOptions: (props: any) => void;
  formData: VisitModel;
  formApi: dataApiInterface;
  dropZone: any;
  lockSaveButton: boolean;
}

const VisitFormDesktop = (props: IVisitFormProps) => {
  const {
    handleSubmit,
    isLoading,
    isNew,
    data: propsData,
    setHeaderOptions,
    dropZone,
    lockSaveButton,
    formApi,
    formData,
  } = props;

  const dispatch = useDispatch();
  const { isVisitFinished } = getVisitBooleanStatus(formData.VisitStatusName);

  const {
    isWizardOpen,
    isCustomize,
    isDeletePopupVisible,
    isRevertPopupVisible,
    setIsRevertPopupVisible,
    setIsDeletePopupVisible,
    handleCancel,
    handleCloseWizard,
    handleCustomize,
    handleOpenWizard,
    handleRevert,
    isConfirmationDialogVisible,
    finishedActivities,
    handleFinishVisit,
    setIsConfirmationDialogVisible,
  } = useContext(VisitFormContext);

  const visitTabs: ITabContent[] = [
    {
      name: "Activities",
      disabled: isNew,
      hasCustomizeMode: true,
      content: (
        <RelatedActivities
          assetId={""}
          isCustomize={isCustomize}
          customerId={formData.CustomerId ?? ""}
          withAssetSelect
          visitId={formData.Id ?? ""}
          isWizardOpen={isWizardOpen}
          handleOpenWizard={handleOpenWizard}
          handleCloseWizard={handleCloseWizard}
        >
          {formData.CustomerId ? (
            <ActivitiesTab visit={formData} isVisitLoading={isLoading} />
          ) : null}
        </RelatedActivities>
      ),
      tabControl: isNew ? null : (
        <CustomizeActivityButton
          onClick={handleCustomize}
          disabled={false}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Activity"}
        </CustomizeActivityButton>
      ),
    },
    {
      name: "Items",
      disabled: isNew,
      content: (
        <ItemsTab
          isWizardOpen={isWizardOpen}
          handleCloseDialog={handleCloseWizard}
          customerId={formData.CustomerId ?? ""}
          isCustomerLoading={isLoading}
          visitId={formData.Id ?? ""}
        />
      ),
      tabControl: isNew ? null : (
        <Button
          onClick={handleOpenWizard}
          color="primary"
          startIcon={<AddOutlinedIcon color="primary" fontSize="small" />}
        >
          Item
        </Button>
      ),
      hasCustomizeMode: false,
    },
    {
      name: "Customer",
      hasCustomizeMode: false,
      disabled: isNew,
      content: <CustomerTab customerId={formData.CustomerId ?? null} />,
      tabControl: null,
    },
    {
      name: "Manage",
      hasCustomizeMode: false,
      content: (
        <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
          <DeleteButton
            onClick={() => setIsDeletePopupVisible(true)}
            disabled={false}
            loading={isLoading}
          >
            Delete Visit
          </DeleteButton>
        </div>
      ),
      disabled: isNew,
      tabControl: null,
    },
  ];

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: handleSubmit,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading]);

  // const handleLeavePopupOpen = () => {
  //   setIsLeavePopupVisible(true);
  // };

  // const handleLeavePopupClose = () => {
  //   setIsLeavePopupVisible(false);
  // };

  // const customerSelect = React.memo(
  //   () => ,
  //   [data.CustomerId],
  // );

  return (
    <Body disableDesktopScroll>
      <Modal isShowing={isRevertPopupVisible}>
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      </Modal>
      <Modal isShowing={isDeletePopupVisible}>
        <DeleteVisitDialog
          visitId={formData.Id}
          handleCancel={() => setIsDeletePopupVisible(false)}
          VisitStatusId={formData.VisitStatusId}
        />
      </Modal>
      <FinishVisitDialog
        visit={formData}
        visible={isConfirmationDialogVisible}
        onCancel={() => setIsConfirmationDialogVisible(false)}
        finishedActivities={finishedActivities}
      />
      <EntityTabsProvider>
        <Form>
          <Form header>
            <ActionsBar
              title={
                <FormPageTitle
                  isNew={isNew}
                  isCustomize={false}
                  entity="Visit"
                  name={formApi.data.Name}
                />
              }
              buttonContainer={ButtonsContainer}
            >
              <Grid item container spacing={2} alignItems="center">
                <Grid item>
                  <StartVisitButtonContainer
                    customerId={formData.CustomerId ?? ""}
                    visitStatusId={formData.VisitStatusId ?? ""}
                    loading={props.isLoading}
                    visit={propsData}
                    showButton={!isNew}
                    handleFinishVisit={handleFinishVisit}
                    formApiVisit={formApi.data}
                  />
                </Grid>
                <Grid item>
                  <SaveButton
                    saveAndClose={handleSubmit}
                    isLoading={props.isLoading}
                    revertData={() => setIsRevertPopupVisible(true)}
                    isSaveAvailable={lockSaveButton}
                    isNew={isNew}
                    cancelRouteForEmptyHistory={ROUTES.VISITS}
                    isDataChanged={formApi.isDataChanged}
                  />
                </Grid>
              </Grid>
            </ActionsBar>
          </Form>

          <Form body>
            <Form content>
              <Collapse in={!isCustomize} timeout="auto" unmountOnExit>
                <VisitFormLayout
                  errorFields={formApi.errorFields}
                  data={formApi.data}
                  handleChange={formApi.handleChange}
                  isVisitFinished={isVisitFinished}
                />
              </Collapse>
              {
                <EntityTabsContainer
                  content={
                    isCustomize
                      ? visitTabs.filter((el) => el.hasCustomizeMode === isCustomize)
                      : visitTabs
                  }
                  entityName="visit"
                  isNew={isNew}
                  startFrom="0"
                  changeCustomize={handleCustomize}
                  isDesktop
                  tabIndexAfterCreate="0"
                />
              }
            </Form>
            <Form images>{dropZone}</Form>
          </Form>
        </Form>
      </EntityTabsProvider>
    </Body>
  );
};

export default VisitFormDesktop;
