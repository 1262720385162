import React from "react";
import styled from "styled-components";

import { Typography } from "@material-ui/core";

import IconWrapper from "./IconWrapper";

const CountInfoRowValue = styled.span`
  font-weight: 500;
`;

const CountInfoRowText = styled(Typography)`
  font-size: 14px;
`;

interface CountInfoRowProps {
  icon: JSX.Element;
  value: number;
  text: string;
  innerPadding?: number;
  outerPadding?: number;
}

const CountInfoRow: React.FC<CountInfoRowProps> = ({
  icon,
  value,
  text,
  innerPadding,
  outerPadding,
}) => {
  return (
    <IconWrapper icon={icon} innerPadding={innerPadding} outerPadding={outerPadding}>
      <CountInfoRowText>
        <CountInfoRowValue>{value}</CountInfoRowValue> {text}
      </CountInfoRowText>
    </IconWrapper>
  );
};

export default CountInfoRow;
