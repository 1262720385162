import gql from "graphql-tag";

import { FileUploadResult, vendorDetailedFragment } from "../NewVendor/createVendor.query";

export const updateVendorMutation = gql`
  mutation updateVendorMutation($Vendor: VendorInput!, $Files: [Upload]) {
    VendorPut(Vendor: $Vendor, Files: $Files) {
      ${vendorDetailedFragment}
      ${FileUploadResult}
    }
  }
`;
