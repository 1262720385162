import React, { useMemo } from "react";
import MaskedInput from "react-text-mask";

import { VendorModel } from "../../redux/models/data/VendorModel";

import { PHONE_INPUT_MASK } from "../../_utils/regex";

import Grid from "@material-ui/core/Grid/Grid";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";

import Input from "../../components/Input/Input";
import InputRow from "../../components/InputRow/InputRow";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";

interface VendorFormFieldsLayoutProps {
  data: VendorModel;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, target?: any) => void;
  handleChangeWithValidation: any;
  handleBlur: any;
  phoneError: string;
  emailError: string;
  websiteError: string;
  errorFields: Map<string, string>;
}

const VendorFormFieldsLayout: React.FC<VendorFormFieldsLayoutProps> = (props) => {
  const {
    data,
    handleChange,
    emailError,
    phoneError,
    websiteError,
    handleBlur,
    handleChangeWithValidation,
    errorFields,
  } = props;

  const handleChangeWithIdReplace = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    handleChange(null, { id: "Name", value });
  };

  const nameInput = useMemo(
    () => (
      <Input
        type="text"
        onChange={handleChangeWithIdReplace}
        value={data.Name}
        label={"Vendor Name"}
        id={"VendorName"}
        fullWidth
        icon={<CreateOutlinedIcon />}
        required
        helperText="* Required field"
        errorText={errorFields.get("Name")}
        error={!!errorFields.get("Name")}
      />
    ),
    [data.Name],
  );

  const addressInput = useMemo(
    () => (
      <Input
        // innerRef={placesApiRef}
        type="text"
        // onChange={handleChange}
        // value={data.Address}
        label={"Address"}
        id={"Address"}
        fullWidth
        icon={<LocationOnOutlinedIcon />}
      />
    ),
    [data.Address],
  );

  const addressAutocomplete = useMemo(
    () => (
      <AddressAutocomplete
        address={data.Address}
        handleChange={handleChange}
        icon={<LocationOnOutlinedIcon />}
      />
    ),
    [data.Address],
  );

  const phoneWithMask = useMemo(
    () => (
      <MaskedInput
        keepCharPositions
        mask={PHONE_INPUT_MASK}
        id={"Phone"}
        defaultValue={data.Phone || ""}
        onChange={handleChangeWithValidation}
        label={"Phone"}
        onBlur={handleBlur}
        autoComplete={"off"}
        error={!!phoneError}
        errorText={phoneError}
        fullWidth
        icon={<PhoneOutlinedIcon />}
        type="tel"
        render={(ref, { defaultValue, ...renderProps }) => {
          return <Input value={defaultValue} {...renderProps} inputRef={ref} />;
        }}
      />
    ),
    [data.Phone, phoneError],
  );

  const emailInput = useMemo(
    () => (
      <Input
        type="email"
        onChange={handleChangeWithValidation}
        onBlur={handleBlur}
        value={data.Email}
        error={!!emailError}
        errorText={emailError}
        label={"Email"}
        id={"Email"}
        fullWidth
        icon={<MailOutlineOutlinedIcon />}
      />
    ),
    [data.Email, emailError],
  );

  const websitelInput = useMemo(
    () => (
      <Input
        type="url"
        onChange={handleChangeWithValidation}
        onBlur={handleBlur}
        value={data.Website}
        error={!!websiteError}
        errorText={websiteError}
        label={"Website"}
        id={"Website"}
        fullWidth
        icon={<LanguageOutlinedIcon />}
      />
    ),
    [data.Website, websiteError],
  );

  return (
    <div className="Section">
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {nameInput}
          </Grid>
          <Grid item xs={12} sm={6}>
            {emailInput}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {addressAutocomplete}
          </Grid>
        </Grid>
      </InputRow>
      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {phoneWithMask}
          </Grid>
          <Grid item xs={12} sm={6}>
            {websitelInput}
          </Grid>
        </Grid>
      </InputRow>
    </div>
  );
};

export default VendorFormFieldsLayout;
