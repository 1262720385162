import { Label } from "@material-ui/icons";
export const CREATE_NEW_PATH = "/create/new";

const ACTIVITIES = { label: "Activities", path: "/activities" };
const SINGLE_ACTIVITY = { label: "Activity", path: "/activity" };
const CREATE_ACTIVITY = {
  label: "Create New Activity",
  path: `${SINGLE_ACTIVITY.path}${CREATE_NEW_PATH}`,
};

const ASSETS = { label: "Items", path: "/items" };
const SINGLE_ASSET = { label: "Item", path: "/item" };
const CREATE_ASSET = { label: "Create New Item", path: `${SINGLE_ASSET.path}${CREATE_NEW_PATH}` };

const SETTINGS = { label: "Settings", path: "/settings" };

const EDIT_PROFILE = { label: "Edit profile", path: `${SETTINGS.path}/profile` };

const ACCOUNTS = { label: "Accounts", path: `${SETTINGS.path}/accounts` };
const SINGLE_ACCOUNT = { label: "Edit Account", path: `${SETTINGS.path}/account` };
const NEW_ACCOUNT = { label: "New Account", path: `${SINGLE_ACCOUNT.path}${CREATE_NEW_PATH}` };

const BUSINESS = { label: "Business", path: `${SETTINGS.path}/business` };
const NEW_BUSINESS = { label: "Business", path: `${BUSINESS.path}${CREATE_NEW_PATH}` };
const EDIT_BUSINESS = { label: "Business", path: `${BUSINESS.path}/edit` };

const CHANGE_PASSWORD = { path: "/change-password" };
const LOGOUT = { path: "/logout" };
const SILENT_RENEW = { path: "/silentrenew" };
const ERROR = { path: "/error" };
const VERIFY_EMAIL = { path: "/verify-email" };

const CHANGE_ACCOUNT = { path: "/change-account" };
const NOTIFICATIONS = { label: "Notification", path: `${SETTINGS.path}/notifications` };

const VISITS = { label: "Visits", path: "/visits" };
const SINGLE_VISIT = { label: "Visit", path: "/visit" };
const CREATE_VISIT = { label: "Create New Visit", path: `${SINGLE_VISIT.path}${CREATE_NEW_PATH}` };

const CUSTOMERS = { label: "Customers", path: "/customers" };
const SINGLE_CUSTOMER = { label: "Customer", path: "/customer" };
const CREATE_CUSTOMER = {
  label: "Create New Customer",
  path: `${SINGLE_CUSTOMER.path}${CREATE_NEW_PATH}`,
};

const VENDORS = { label: "Vendors", path: "/vendors" };
const SINGLE_VENDOR = { label: "Vendor", path: "/vendor" };
const CREATE_VENDOR = {
  label: "Create New Vendor",
  path: `${SINGLE_VENDOR.path}${CREATE_NEW_PATH}`,
};

const ABOUT = { label: "About", path: `${SETTINGS.path}/about` };
const CALENDAR_FEEDS = { label: "Calendar Feeds", path: `${SETTINGS.path}/calendar-feeds` };

const PROJECTS = { label: "Projects", path: `/projects` };
const SINGLE_PROJECT = { label: "Project", path: "/project" };
const CREATE_PROJECT = {
  label: "Create New Project",
  path: `${SINGLE_PROJECT.path}${CREATE_NEW_PATH}`,
};

export const ROUTES = {
  ACTIVITIES: ACTIVITIES.path,
  SINGLE_ACTIVITY: SINGLE_ACTIVITY.path,
  CREATE_ACTIVITY: CREATE_ACTIVITY.path,
  ASSETS: ASSETS.path,
  SINGLE_ASSET: SINGLE_ASSET.path,
  CREATE_ASSET: CREATE_ASSET.path,
  SETTINGS: SETTINGS.path,
  EDIT_PROFILE: EDIT_PROFILE.path,
  ACCOUNTS: ACCOUNTS.path,
  SINGLE_ACCOUNT: SINGLE_ACCOUNT.path,
  NEW_ACCOUNT: NEW_ACCOUNT.path,
  BUSINESS: BUSINESS.path,
  NEW_BUSINESS: NEW_BUSINESS.path,
  EDIT_BUSINESS: EDIT_BUSINESS.path,
  CHANGE_PASSWORD: CHANGE_PASSWORD.path,
  ERROR: ERROR.path,
  CHANGE_ACCOUNT: CHANGE_ACCOUNT.path,
  LOGOUT: LOGOUT.path,
  SILENT_RENEW: SILENT_RENEW.path,
  VISITS: VISITS.path,
  SINGLE_VISIT: SINGLE_VISIT.path,
  CREATE_VISIT: CREATE_VISIT.path,
  CUSTOMERS: CUSTOMERS.path,
  SINGLE_CUSTOMER: SINGLE_CUSTOMER.path,
  CREATE_CUSTOMER: CREATE_CUSTOMER.path,
  NOTIFICATIONS: NOTIFICATIONS.path,
  ABOUT: ABOUT.path,
  VERIFY_EMAIL: VERIFY_EMAIL.path,
  VENDORS: VENDORS.path,
  SINGLE_VENDOR: SINGLE_VENDOR.path,
  CREATE_VENDOR: CREATE_VENDOR.path,
  CALENDAR_FEEDS: CALENDAR_FEEDS.path,
  PROJECTS: PROJECTS.path,
  SINGLE_PROJECT: SINGLE_PROJECT.path,
  CREATE_PROJECT: CREATE_PROJECT.path,
};

export const PAGE_NAMES = {
  ACTIVITIES: ACTIVITIES.label,
  SINGLE_ACTIVITY: SINGLE_ACTIVITY.label,
  CREATE_ACTIVITY: CREATE_ACTIVITY.label,
  ASSETS: ASSETS.label,
  SINGLE_ASSET: SINGLE_ASSET.label,
  CREATE_ASSET: CREATE_ASSET.label,
  SETTINGS: SETTINGS.label,
  EDIT_PROFILE: EDIT_PROFILE.label,
  ACCOUNTS: ACCOUNTS.label,
  SINGLE_ACCOUNT: SINGLE_ACCOUNT.label,
  NEW_ACCOUNT: NEW_ACCOUNT.label,
  VISITS: VISITS.label,
  SINGLE_VISIT: SINGLE_VISIT.label,
  CREATE_VISIT: CREATE_VISIT.label,
  CUSTOMERS: CUSTOMERS.label,
  SINGLE_CUSTOMER: SINGLE_CUSTOMER.label,
  CREATE_CUSTOMER: CREATE_CUSTOMER.label,
  NOTIFICATIONS: NOTIFICATIONS.label,
  ABOUT: ABOUT.label,
  PROJECTS: PROJECTS.label,
  SINGLE_PROJECT: SINGLE_PROJECT.label,
  CREATE_PROJECT: CREATE_PROJECT.label,
};
