import React from "react";
import Input from "../Input/Input";
import { ErrorHelperText, StyledCombobox } from "./ComboboxStyles";
import { createStyles, makeStyles, TextFieldProps } from "@material-ui/core";
import { InputProps } from "../Input/InputTypes";
import useGetComboboxConfig from "../../customHooks/useGetComboboxConfig";

interface ComboboxProps {
  label: string;
  options: { value: string; label: string; payload?: any }[];
  handleChange: any;
  value: any;
  hasError: boolean;
  helperText?: any;
  disabled?: boolean;
  creatable: boolean;
  required: boolean;
  id: string;
  handleOpenWizard?: () => void;
  highlightLastOptionInSelect?: boolean;
  disableClearable?: boolean;
  loading?: boolean;
  autoSelectFirstMatchOption?: boolean;
  isResetField?: boolean;
  cancelFieldReset?: () => void | undefined;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    listbox: {
      maxHeight: "196px",
      "&::-webkit-scrollbar": {
        width: "0.3em",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "6px",
      },
    },
    option: {
      '&[aria-selected="true"]': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.selected,
      },
    },
  }),
);

const Combobox: React.FC<ComboboxProps & TextFieldProps & InputProps> = ({
  value = { value: "", label: "", payload: null },
  label,
  options,
  handleChange,
  hasError,
  helperText,
  creatable,
  disabled,
  icon,
  errorText,
  required,
  id,
  handleOpenWizard,
  highlightLastOptionInSelect,
  disableClearable,
  autoSelectFirstMatchOption,
  isResetField,
  cancelFieldReset,
  ...restProps
}) => {
  const classes = useStyles();

  const validatedHelperText = hasError ? <ErrorHelperText text={errorText} /> : null;

  const {
    activityValue,
    handleOptionLabelGetting,
    handleOptionsRendering,
    getOptionSelected,
    getNewValueBasedOnAction,
    handleOptionsFiltering,
    getPaperComponent,
  } = useGetComboboxConfig({
    highlightLastOptionInSelect,
    autoSelectFirstMatchOption,
    isResetField,
    cancelFieldReset,
    id,
    creatable,
    handleChange,
    handleOpenWizard,
  });

  const createComboboxInput = (params) => (
    <Input
      {...params}
      label={label}
      helperText={!value.value ? validatedHelperText : ""}
      required={!!value.value ? false : required}
      icon={icon}
      error={hasError}
    />
  );

  const getDisableClearable = () => {
    if (autoSelectFirstMatchOption) return !activityValue?.value || disableClearable;
    return !value.value || disableClearable;
  };

  return (
    <StyledCombobox
      classes={{
        listbox: classes.listbox,
        option: classes.option,
      }}
      value={autoSelectFirstMatchOption ? activityValue : value}
      hasError={hasError}
      onChange={getNewValueBasedOnAction}
      filterOptions={handleOptionsFiltering}
      getOptionSelected={getOptionSelected}
      options={options}
      getOptionLabel={handleOptionLabelGetting}
      renderOption={handleOptionsRendering}
      noOptionsText={<span>No options</span>}
      renderInput={createComboboxInput}
      selectOnFocus
      disabled={disabled}
      blurOnSelect={true}
      {...restProps}
      disableClearable={getDisableClearable()}
      freeSolo={creatable}
      openOnFocus
      PaperComponent={getPaperComponent()}
    />
  );
};

export default React.memo(Combobox, (prevProps, nextProps) => {
  return (
    prevProps.autoSelectFirstMatchOption &&
    prevProps.options === nextProps.options &&
    prevProps.loading === nextProps.loading &&
    prevProps.isResetField === nextProps.isResetField
  );
});
