import * as React from "react";

import { NormalizedFile } from "../DropZoneContainer/DropZoneContainer";
import { SyntheticEvent } from "react";
import { FileType } from "../../_utils/dropzoneHelpers";

import { useDropZoneContext } from "../DropZoneContainer/useDropZoneContext";

import {
  AVAILABLE_DOCUMENT_TYPES,
  AVAILABLE_IMAGE_TYPES,
  AVAILABLE_VIDEO_TYPES,
} from "../../_constant/constants";

import Dropzone from "react-dropzone";

import DropZoneHintSection from "./DropZoneHintSection";

import DropZoneSvgCard from "../DropZoneSvgCard/DropZoneSvgCard";
import MainFilesSection from "./MainFilesSection";

import {
  DropZoneWrapper,
  DropZoneStyled,
  DropZoneGridContainer,
  DropZoneInner,
  DropZoneEmptyStateContainer,
  DropZoneEmptyStateIcon,
  DropZoneEmptyStateText,
} from "./DropZone.style";
import DropZonePlaceholder from "./DropZonePlaceholder";

type FileRemove = (e: SyntheticEvent<HTMLDivElement>, id: string) => void;

interface DropZoneProps {}

const DropZone: React.FC<DropZoneProps> = () => {
  const {
    imagesOnly,
    isMobile,
    reducedDocuments,
    reducedImages,
    reducedVideos,
    mainFiles,
    isSingleFile,
    localFiles = [],
    emptyStateIcon,
    handleServerFileDelete,
    handleLocalFilesDelete,
    handleFileClick,
    handleImageClick,
    handleFileFromServerClick,
    addFilesToDropZone,
    amountOfMainFiles,
    totalFilesAmount,
  } = useDropZoneContext();

  const amountOfRegularFiles = totalFilesAmount - amountOfMainFiles;

  const renderNonMainFiles = () => {
    const nonMainFiles = [...reducedImages, ...reducedDocuments, ...reducedVideos, ...localFiles];

    const difference = amountOfRegularFiles - nonMainFiles.length;

    const result = nonMainFiles.map((file: NormalizedFile) => {
      let openHandler = null;
      let removeHandler = null;

      if (file.isLocal) {
        openHandler = file.url
          ? (e: any) => handleFileClick(e, file)
          : (e: any) => handleImageClick(e);
        removeHandler = (e: any) => handleLocalFilesDelete(e, file.id);
      } else {
        openHandler = file.url
          ? (e: any) => handleFileFromServerClick(e, file)
          : (e: any) => handleImageClick(e);
        removeHandler = (e: any) => handleServerFileDelete(e, file.id, file.fileType as FileType);
      }

      return (
        <DropZoneSvgCard
          id={file.id}
          key={file.id}
          imageUrl={file.imgUrl}
          title={file.title}
          open={openHandler}
          remove={removeHandler}
          dataOrder={file?.imgOrder}
          url={file.url}
        />
      );
    });

    if (difference > 0) {
      return result.concat(
        new Array(difference)
          .fill(null)
          .map((_, index) => (
            <DropZonePlaceholder
              key={`img_placeholder_non_main_${index}`}
              id={`img_placeholder_non_main_${index}`}
            />
          )),
      );
    }

    return result;
  };

  const totalFilesLength = [
    ...mainFiles,
    ...reducedImages,
    ...reducedDocuments,
    ...reducedVideos,
    ...localFiles,
  ].length;

  const availableTypes = imagesOnly
    ? AVAILABLE_IMAGE_TYPES
    : [...AVAILABLE_IMAGE_TYPES, ...AVAILABLE_DOCUMENT_TYPES, ...AVAILABLE_VIDEO_TYPES];

  return (
    <DropZoneWrapper>
      <Dropzone onDrop={(files) => addFilesToDropZone(files)} accept={availableTypes}>
        {({ getRootProps, getInputProps }) => (
          <>
            {isMobile && <DropZoneHintSection getRootProps={getRootProps} />}
            <DropZoneInner>
              {!isMobile && <DropZoneHintSection getRootProps={getRootProps} />}
              <DropZoneStyled
                {...getRootProps()}
                $withoutOffset={!totalFilesLength || isSingleFile}
              >
                <input {...getInputProps()} />
                {totalFilesLength > 0 ? (
                  <>
                    <MainFilesSection />
                    {isSingleFile ? null : (
                      <DropZoneGridContainer>{renderNonMainFiles()}</DropZoneGridContainer>
                    )}
                  </>
                ) : (
                  <DropZoneEmptyStateContainer>
                    <DropZoneEmptyStateIcon src={emptyStateIcon} />
                    <DropZoneEmptyStateText>File zone</DropZoneEmptyStateText>
                  </DropZoneEmptyStateContainer>
                )}
              </DropZoneStyled>
            </DropZoneInner>
          </>
        )}
      </Dropzone>
    </DropZoneWrapper>
  );
};

export default DropZone;
