import styled from "styled-components";
import { StyledCombobox } from "../../../../components/Combobox/ComboboxStyles";

export const StyledAssetTypeSelect = styled.div`
  position: relative;

  .Icon_edit,
  .Icon-system {
    position: absolute;
    left: 0;
  }

  .Icon_edit {
    bottom: -17px;
  }

  .Icon-system {
    bottom: 3px;
  }

  ${StyledCombobox} {
    padding-left: 33px;
  }
`;
