import * as React from "react";

interface ConfirmAssetDeleteI {
  customerName: string;
}

const ConfirmCustomerDelete = (props: ConfirmAssetDeleteI) => {
  const { customerName } = props;

  return (
    <>
      Deleting the <span className="item-name">{customerName}</span> customer will also permanently
      delete these items and their related activities.
      <div className="DeleteAsset_note">
        After deleting this customer, you won't be able to undo this action.
      </div>
    </>
  );
};

export default ConfirmCustomerDelete;
