import React from "react";
import {
  CustomFieldsLoadingStateContainer,
  CustomFieldsLoadingStateIcon,
  CustomFieldsLoadingStateInput,
  CustomFieldsLoadingStateRow,
} from "./CustomFieldsLoadingStateStyle";

const CustomFieldsLoadingState = () => {
  return (
    <CustomFieldsLoadingStateContainer>
      <CustomFieldsLoadingStateRow>
        <CustomFieldsLoadingStateIcon />
        <CustomFieldsLoadingStateInput />
      </CustomFieldsLoadingStateRow>
      <CustomFieldsLoadingStateRow>
        <CustomFieldsLoadingStateIcon />
        <CustomFieldsLoadingStateInput />
      </CustomFieldsLoadingStateRow>
    </CustomFieldsLoadingStateContainer>
  );
};

export default CustomFieldsLoadingState;
