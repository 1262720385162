import * as React from "react";

import { Provider as ReduxProvider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import configureStore from "./redux/configureStore";
import { ThemeProvider as StyleThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Helmet } from "react-helmet";

import "../css/app.scss";

import AppRouter from "./routing/Router";
import ConfigWrapper from "./authorization/ConfigWrapper";

import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "./_utils/htmlToTouch";

import { ThemeProvider } from "@material-ui/styles";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import lightTheme from "material-theme-config";
import { StylesProvider } from "@material-ui/core/styles";
import LayoutSwitcherProvider from "./components/LayoutSwitcher/LayoutSwitcherProvider";
import InitNotificationFunctions from "./notifications/InitNotificationFunctions";
import ApolloWrapper from "./_api/ApolloWrapper";
import Auth0ProviderCustom from "./authorization_auth0/Auth0Provider";
import LeaveDialogProvider from "./containers/_common/LeaveDialogProvider";
import NoInternetSnackbar from "./components/NoInternetSnackbar/NoInternetSnackbar";
import { BRAND } from "./_constant/constants";

export const store = configureStore();
export type AppDispatch = typeof store.dispatch;

function App() {
  const brand = (process.env.REACT_APP_BRAND ?? "default") as "autix" | "default";  

  return (
    <>
      <Helmet>
        <title>{BRAND[brand]}</title>
        <link rel="manifest" href={`/brands/${brand}/manifest.json`} />
        <meta name="theme-color" content={lightTheme.palette.primary.main} />
        <link rel="icon" type="image/png" href={`/img/pwa/brands/${brand}/favicon.ico`} />
        <link
          rel="icon"
          type="image/png"
          href={`/img/pwa/brands/${brand}/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href={`/img/pwa/brands/${brand}/favicon-32x32.png`}
          sizes="32x32"
        />
      </Helmet>
      <ReduxProvider store={store}>
        <ConfigWrapper>
          <Auth0ProviderCustom>
            <ApolloWrapper>
              <InitNotificationFunctions />
              <DndProvider options={HTML5toTouch}>
                <StylesProvider injectFirst>
                  <ThemeProvider theme={lightTheme}>
                    <StyleThemeProvider theme={lightTheme}>
                      <CssBaseline />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <LayoutSwitcherProvider>
                          <LeaveDialogProvider>
                            <AppRouter />
                            <ReduxToastr
                              closeOnToastrClick
                              timeOut={6000}
                              newestOnTop
                              transitionIn="fadeIn"
                              transitionOut="fadeOut"
                              position="top-right"
                              getState={(state) => state.toastr} // This is the default
                            />
                            <NoInternetSnackbar />
                          </LeaveDialogProvider>
                        </LayoutSwitcherProvider>
                      </MuiPickersUtilsProvider>
                    </StyleThemeProvider>
                  </ThemeProvider>
                </StylesProvider>
              </DndProvider>
            </ApolloWrapper>
          </Auth0ProviderCustom>
        </ConfigWrapper>
      </ReduxProvider>
    </>
  );
}

export default App;
