import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import Input from "../Input/Input";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import { PHONE_INPUT_MASK } from "../../_utils/regex";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import MaskedInput from "react-text-mask";
import useGetAccountId from "../../customHooks/useGetAccountId";

const GET_CUSTOMER_DATA = gql`
  query getCustomerData($AccountId: ID, $CustomerId: ID) {
    Customer(Id: $CustomerId, AccountId: $AccountId) {
      Id
      Address
      Email
      Phone
    }
  }
`;

interface CustomerTabProps {
  customerId: string | null;
}

const CustomerTab: React.FC<CustomerTabProps> = ({ customerId }) => {
  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(GET_CUSTOMER_DATA, {
    variables: {
      AccountId: accountId,
      CustomerId: customerId,
    },
    skip: !accountId || !customerId,
  });

  const handleChange = (e) => {};
  const handleChangeMemoized = React.useCallback(handleChange, []);

  const phoneWithMask = React.useMemo(
    () => (
      <MaskedInput
        keepCharPositions
        mask={PHONE_INPUT_MASK}
        id={"Phone"}
        defaultValue={(data && data.Customer.Phone) ?? ""}
        onChange={handleChangeMemoized}
        label={"Phone"}
        onBlur={handleChangeMemoized}
        autoComplete={"off"}
        error={false}
        fullWidth
        icon={<PhoneOutlinedIcon />}
        type="tel"
        render={(ref, { defaultValue, ...renderProps }) => {
          return (
            <Input
              value={defaultValue}
              {...renderProps}
              inputRef={ref}
              disableClearButton
              disabled
            />
          );
        }}
      />
    ),
    [data],
  );

  return (
    <section className="Section">
      <div className="Section_row Section_row-withoutIcon">
        <Input
          type="text"
          onChange={handleChangeMemoized}
          value={(data && data.Customer.Address) ?? ""}
          label={"Addresses"}
          id={"Address"}
          fullWidth
          icon={<LocationOnOutlinedIcon />}
          disableClearButton
          disabled
        />
      </div>

      <div className="Section_row Section_row-withoutIcon">
        <Input
          type="text"
          onChange={handleChangeMemoized}
          value={(data && data.Customer.Email) ?? ""}
          label={"Email"}
          id={"Email"}
          fullWidth
          icon={<MailOutlineIcon />}
          disableClearButton
          disabled
        />
      </div>

      <div className="Section_row Section_row-withoutIcon">{phoneWithMask}</div>
    </section>
  );
};

export default CustomerTab;
