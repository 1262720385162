import { NormalizedFile } from "../../../components/DropZoneContainer/DropZoneContainer";
import _ from "lodash";

const FILE_ID_PREFIX = "file_id_";

export class NormalizedFileModel implements NormalizedFile {
  id: string;
  imgUrl: string;
  url: string | null;
  isLocal: boolean;
  fileType: string;
  title?: string;
  imgOrder?: number;

  constructor({
    id = _.uniqueId(FILE_ID_PREFIX),
    imgUrl = "",
    url = null,
    isLocal = true,
    fileType = "",
    title = "",
    imgOrder = 0,
  }: NormalizedFile) {
    this.id = id;
    this.imgUrl = imgUrl;
    this.url = url;
    this.isLocal = isLocal;
    this.fileType = fileType;
    this.title = title;
    this.imgOrder = imgOrder;
  }
}
