import { ActivityScheduleModel } from "../../../../../redux/models/data/ActivityScheduleModel";
import AssetShortModel from "../../../../../redux/models/data/AssetShortModel";
import { VendorRefInputType } from "../../../../../redux/models/data/VendorModel";
import { getDateWithUpdatedTime } from "../../../../../_utils/date";
import { Image } from "../../../../../redux/models/data/ImageModel";

export class ShortActivityModel {
  Id: string | null;
  Name: string;
  StatusId: string;
  DueDateTime: string | null;
  CompletedDateTime: string | null;
  RelatedDueDateTime: string[];
  Schedule: ActivityScheduleModel;
  isLoading: boolean;
  AccountId: string;
  AssetId: string;
  VendorRefs?: VendorRefInputType[];
  AssignedUserId: string;
  Asset: AssetShortModel;
  Images?: Image[];
  Description?: string;
  Documents?: any[];
  Videos?: any[];

  constructor({
    Id = null,
    AssetId = "",
    AccountId = "",
    Name = "",
    StatusId = "",
    RelatedDueDateTime = [],
    Schedule = new ActivityScheduleModel(),
    DueDateTime = "",
    CompletedDateTime = "",
    isLoading = false,
    VendorRefs = [] as VendorRefInputType[],
    AssignedUserId = "",
    Asset = new AssetShortModel(),
    Description = "",
    Images = [],
    Documents = [] as any,
    Videos = [] as any,
  } = {}) {
    this.Id = Id;
    this.AssetId = AssetId;
    this.AccountId = AccountId;
    this.Name = Name;
    this.StatusId = StatusId;
    this.Schedule = new ActivityScheduleModel(Schedule);
    this.RelatedDueDateTime = RelatedDueDateTime || [];
    this.DueDateTime = DueDateTime ? getDateWithUpdatedTime(DueDateTime) : null;
    this.CompletedDateTime = CompletedDateTime ? getDateWithUpdatedTime(CompletedDateTime) : null;
    this.isLoading = isLoading ?? false;
    this.VendorRefs = VendorRefs ?? [];
    this.AssignedUserId = AssignedUserId ?? "";
    this.Asset = Asset || new AssetShortModel();
    this.Images = Images || [];
    this.Description = Description || "";
    this.Documents = Documents || [];
    this.Videos = Videos || [];
  }
}
