import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import styled from "styled-components";

import useGetAccountId from "../../customHooks/useGetAccountId";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

import { VisitModel } from "../../redux/models/data/VisitModel";
import { Image } from "../../redux/models/data/ImageModel";

import { sendVisitReport } from "./sendVisitReport.query";
import { finishVisitDialogCustomerData } from "../../graphql/resolvers";
import { userRefFragment } from "../AssignUserSelect/getUserRefFragment.query";
import { getAccountUsersQuery } from "../AccountUsersTab/AccountUsersTab";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { composeImageUrl, validateEmail } from "../../_utils/utils";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { EMPTY_ID, LayoutType } from "../../_constant/constants";

import CloseIcon from "@material-ui/icons/Close";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import ActivityItem from "../../containers/activities/_components/ActivityItem";
import InputField from "../InputField/InputField";
import { useTheme } from "@material-ui/core";

interface FinishVisitDialogProps {
  visible: boolean;
  onCancel: () => void;
  finishedActivities: any[];
  visit: VisitModel;
}

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FinishVisitDialog: React.FC<FinishVisitDialogProps> = ({
  visible,
  onCancel,
  finishedActivities,
  visit,
}) => {
  const { accountId, isPersonal } = useGetAccountId();

  const [noteText, setNoteText] = useState(visit.Notes);
  const [customerEmailValue, setCustomerEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (visit.Notes) {
      setNoteText(visit.Notes);
    }
  }, [visit.Notes]);

  const handleNoteTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNoteText(e.target.value);
  };

  const handleValidateEmail = () => {
    if (!validateEmail(customerEmailValue)) {
      setEmailError("Wrong email format");
    } else {
      setEmailError("");
    }
  };

  const handleCustomerEmailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCustomerEmailValue(e.target.value);
    if (!validateEmail(e.target.value)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const staticData = useStaticDataQuery();

  const [visitReport, { data, loading }] = useMutation(sendVisitReport);

  const defaultUserIcon = staticData ? staticData.User.User_default_icon : "";

  const { data: accountUsers, loading: usersLoading, error: usersError, client } = useQuery(
    getAccountUsersQuery,
    {
      variables: { Id: accountId },
      skip: !accountId || !!isPersonal,
      fetchPolicy: "cache-first",
    },
  );

  const createImgUrl = (image: Image) => {
    if (image) {
      return composeImageUrl(image.Id, image.ContainerName, image.ContainerId);
    } else {
      return "";
    }
  };

  const getCurrentAssignedUserAvatar = (id: string | null) => {
    if (id && id !== EMPTY_ID && accountUsers) {
      const user = client.readFragment({ fragment: userRefFragment, id: id });
      return user && user.MainImage ? createImgUrl(user.MainImage) : "";
    } else {
      return defaultUserIcon;
    }
  };

  const getCurrentAssignedUserName = (id: string | null) => {
    if (id && accountUsers) {
      const user = client.readFragment({ fragment: userRefFragment, id: id });
      return user ? `${user.FirstName} ${user.LastName}`.trim() : "";
    } else {
      return "";
    }
  };

  const getCustomerData = () => {
    const result = {
      customerName: "",
      customerAvatar: defaultUserIcon,
      customerEmail: "",
    };
    try {
      const foundCustomer = client.readFragment({
        fragment: finishVisitDialogCustomerData,
        id: visit.CustomerId ?? "",
      });

      result.customerName = foundCustomer?.Name ?? "";
      result.customerEmail = foundCustomer?.Email ?? "";
      result.customerAvatar =
        foundCustomer && foundCustomer.Images?.length
          ? createImgUrl(foundCustomer.Images[0])
          : defaultUserIcon;
    } catch (error) {
      console.log(error);
    }
    return result;
  };

  const { customerName, customerEmail, customerAvatar } = getCustomerData();

  const handleSubmit = async () => {
    if (loading || !visit.Id || !accountId || (!customerEmail && !isEmailValid)) return;

    visitReport({
      variables: {
        Id: visit.Id,
        AccountId: accountId,
        Email: customerEmail || customerEmailValue,
        Notes: noteText,
      },
    })
      .then(() => {
        onCancel();
        displaySuccessNotification(NotificationTypes.VISIT_REPORT_SUCCESS);
      })
      .catch((error) => {
        console.log(error);
        displayErrorNotification(NotificationTypes.VISIT_REPORT_FAILURE);
      });
  };

  const isSendButtonDisabled = Boolean(
    !customerEmail && (customerEmailValue || !customerEmailValue) && !isEmailValid,
  );

  const renderMessage = () => {
    if (!visit.AssignedUserId) return "Finished";

    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center" marginRight="8px">
          <Avatar
            style={{ width: "16px", height: "16px", marginRight: "8px" }}
            src={getCurrentAssignedUserAvatar(visit.AssignedUserId)}
          />
          <Typography variant="body2" style={{ fontSize: "12px", color: `${theme.palette.text.primary}` }}>
            {getCurrentAssignedUserName(visit.AssignedUserId)}
          </Typography>
        </Box>

        <Typography variant="body2" style={{ fontSize: "12px", color: `${theme.palette.greyDesign.surface2}` }}>
          <span>finished</span> <span style={{ color: `${theme.palette.primary.main}` }}>{visit.Purpose}</span>{" "}
          <span>visit for</span>{" "}
        </Typography>

        <Box display="flex" alignItems="center" marginLeft="8px">
          <Avatar
            style={{ width: "16px", height: "16px", marginRight: "8px" }}
            src={customerAvatar}
          >
            {customerName.charAt(0)}
          </Avatar>
          <Typography variant="body2" style={{ fontSize: "12px", color: `${theme.palette.text.primary}` }}>
            {customerName}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Dialog onClose={onCancel} open={visible} maxWidth="sm" fullWidth>
      <DialogTitle id="customized-dialog-title" style={{ padding: "16px" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" style={{ fontSize: "1rem" }}>
            Notify customer
          </Typography>
          <StyledCloseButton aria-label="close" onClick={onCancel}>
            <CloseIcon />
          </StyledCloseButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers style={{ padding: "16px" }}>
        <Typography variant="body2" style={{ fontSize: "12px", marginBottom: "16px" }}>
          {renderMessage()}
        </Typography>
        <Typography variant="body2" style={{ fontSize: "12px", marginBottom: "8px" }}>
          Activities <span className="Label">({finishedActivities.length})</span>
        </Typography>
        <Box overflow="hidden" borderRadius="8px" border={`1px solid ${theme.palette.greyDesign.onSurface3}`} marginBottom="15px">
          {finishedActivities.map((activity: any) => {
            return (
              <ActivityItem
                key={activity.Id}
                activity={activity}
                dateType={activity.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
                layout={LayoutType.LIST}
                isAccountPersonal={isPersonal || false}
                isAssignmentsVisibile={false}
                customerId={visit.CustomerId}
                status={""}
                finishedView
              />
            );
          })}
        </Box>
        <Box>
          <InputField
            handleChange={handleNoteTextChange}
            value={noteText}
            id="Notes"
            label="Notes"
            fullWidth
            icon={<DescriptionOutlinedIcon />}
            multiline
            maxLength="1000"
          />
          {!customerEmail && (
            <InputField
              handleChange={handleCustomerEmailChange}
              value={customerEmailValue}
              id="Customer Email"
              label="Customer Email"
              fullWidth
              icon={<MailOutlineIcon />}
              multiline
              maxLength="320"
              errorMessage={emailError}
              error={emailError.length > 0}
              onBlur={handleValidateEmail}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={loading}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          startIcon={<MailOutlineIcon />}
          disabled={loading || isSendButtonDisabled}
        >
          Send
          {loading && <CircularProgress size={20} style={{ position: "absolute" }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishVisitDialog;
