import React, { useEffect, useState } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { useConnectionState } from "../../customHooks/useConnectionState";

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionDown = (props: TransitionProps) => <Slide direction="up" {...props} />;

const NoInternetSnackbar: React.FC = () => {
  const [isOffline, setOffline] = useState(false);

  const { setConnectionState } = useConnectionState();

  const offlineStateHandler = () => {
    setOffline(true);
  };
  const onlineStateHandler = () => {
    setOffline(false);
  };

  useEffect(() => {
    window.addEventListener("offline", offlineStateHandler);

    return () => {
      window.removeEventListener("offline", offlineStateHandler);
    };
  }, []);

  useEffect(() => {
    if (isOffline) {
      window.addEventListener("online", onlineStateHandler);
    }

    return () => {
      window.addEventListener("online", onlineStateHandler);
      setConnectionState(false);
    };
  }, [isOffline]);

  return (
    <Snackbar
      open={isOffline}
      TransitionComponent={TransitionDown}
      message="Your internet connection is lost"
      key={"no-internet"}
    />
  );
};

export default NoInternetSnackbar;
