const STRING = "STRING";
const NUMBER = "NUMBER";
const BOOLEAN = "BOOLEAN";
const DATE = "DATE_TIME";
const SELECT = "ENUMERATION";
const ADDRESS = "ADDRESS";

export const FIELD_TYPES_OBJECT = {
  STRING: STRING,
  NUMBER: NUMBER,
  BOOLEAN: BOOLEAN,
  DATE: DATE,
  SELECT: SELECT,
  ADDRESS: ADDRESS,
};

export type FieldTypes =
  | typeof STRING
  | typeof NUMBER
  | typeof BOOLEAN
  | typeof DATE
  | typeof SELECT
  | typeof ADDRESS;

export const itemCustomFieldTypesOptions = [
  { label: "Text", value: FIELD_TYPES_OBJECT.STRING },
  { label: "Number", value: FIELD_TYPES_OBJECT.NUMBER },
  { label: "Switch", value: FIELD_TYPES_OBJECT.BOOLEAN },
  { label: "Date", value: FIELD_TYPES_OBJECT.DATE },
  { label: "Address", value: FIELD_TYPES_OBJECT.ADDRESS },
];
