import React from "react";
import { Link } from "react-router-dom";

import {
  subscriptionValues,
  useGetUserSubscriptionTier,
} from "../../customHooks/useGetUserSubscriptionTier";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { ROUTES } from "../../_constant/screens";
import { getSettingsLinks, PopoverLinkItem } from "../SettingsDrawer/SettingsDrawer";

import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

import Button from "@material-ui/core/Button/Button";

import {
  PopoverStyle,
  ListContainer,
  ListItemStyle,
  ListItemTextStyle,
  ListItemIconStyle,
  KeyboardArrowDownIconStyle,
} from "./PopupNavigationStyle";

interface PopupNavigationProps {
  closeAlert: () => void;
}

const PopupNavigation: React.FC<PopupNavigationProps> = ({ closeAlert }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNav = () => {
    setAnchorEl(null);
  };

  const userSubscriptionTier = useGetUserSubscriptionTier();
  const limits = useGetLimits();

  const enableBusinessAccounts = userSubscriptionTier.value === subscriptionValues.professional;
  const enableCalendarFeed = limits?.user.calendarIntegration.Value;

  const links: PopoverLinkItem[] = getSettingsLinks({
    myBusiness: !enableBusinessAccounts,
    calendarFeeds: !enableCalendarFeed,
  });

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIconStyle isOpen={open} />}
      >
        Settings
      </Button>
      <PopoverStyle
        id={id}
        open={open}
        onClose={handleCloseNav}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ListContainer>
          {links
            .filter((el) => !el.disabled)
            .map(({ name, url }) => (
              <ListItemStyle
                key={name}
                button
                component={Link}
                to={url}
                onClick={closeAlert}
                disableGutters
              >
                <ListItemTextStyle primary={name} />
              </ListItemStyle>
            ))}
          <ListItemStyle
            button
            component={Link}
            to={ROUTES.LOGOUT}
            onClick={closeAlert}
            disableGutters
            primary
          >
            <ListItemIconStyle>
              <ExitToAppOutlinedIcon fontSize="small" color="primary" />
            </ListItemIconStyle>
            <ListItemTextStyle primary="Log out" />
          </ListItemStyle>
        </ListContainer>
      </PopoverStyle>
    </div>
  );
};

export default PopupNavigation;
