import * as React from "react";
import { useDrop } from "react-dnd";

import { ActivityModelShort } from "../../redux/models/data/ActivityModelShort";

import { statusClassName } from "../../_utils/utils";
import { LayoutType } from "../../_constant/constants";

interface ActivitiesDropWrapperProps {
  status: any;
  layout: LayoutType;
  loading: boolean;
  updateActivityStatus: (activity: any, nextStatusId: string) => void;
  expandStatus: (flag: boolean) => void;
}

const ActivitiesDropWrapper: React.FC<ActivitiesDropWrapperProps> = ({
  status,
  layout,
  loading,
  children,
  expandStatus,
  updateActivityStatus,
}) => {
  const [{ canDrop, isOver, item }, drop] = useDrop({
    accept: "activity",
    drop: (item: { type: string; activity: ActivityModelShort }) => {
      expandStatus(true);
      updateActivityStatus(item.activity, status.Id);
    },
    canDrop: (item) => {
      return item.activity.StatusId !== status.Id && !loading;
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      };
    },
  });

  const wrapperClassName = `ActivitiesBoard_${layout} ${statusClassName(status.Name)}${
    !item ? " centered" : ""
  }`;
  return (
    <div
      ref={drop}
      className={`ActivitiesList__container${
        canDrop && isOver ? " ActivitiesList__container--highlighted" : ""
      } ActivitiesList__container--${layout}`}
    >
      {children}
    </div>
  );
};

export default ActivitiesDropWrapper;
