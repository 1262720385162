import React from "react";
import styled from "styled-components";

interface LabelProps {
  type?: string;
  text: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 68px;
  height: 12px;
  background-color: #81cc35;
  transform: rotate(45deg) translate(20px, 0); ;
`;

const Text = styled.span`
  color: #fff;
  font-size: 8px;
  font-weight: 700;
`;

export const Label: React.FC<LabelProps> = ({ type, text }) => {
  return (
    <Container>
      <Text>{text}</Text>
    </Container>
  );
};
