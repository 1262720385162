import React, { useState } from "react";
import { ClockView } from "@material-ui/pickers";
import moment from "moment";
import { TabContext } from "@material-ui/lab";
import DayAndTimePickerToolbar, {
  openViewType,
} from "./DayAndTimePickerToolbar/DayAndTimePickerToolbar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import DayOfWeekPicker from "./DayOfWeekPiceker/DayOfWeekPicker";
import {
  DateRangeIconStyle,
  DialogFooter,
  DialogStyle,
  FooterBtn,
  ScheduleIconStyle,
  TabContainer,
  TabContentStyle,
  TabPannelStyled,
} from "./DayAndTimePicker.style";

type DayTimePickerView = "dayOfWeek" | "time";

interface DayAndTimePickerProps {
  open: boolean;
  selectedDate: moment.Moment;
  handleDateChange: (date: moment.Moment) => void;
  handleDialog: () => void;
}

const DayAndTimePicker: React.FC<DayAndTimePickerProps> = (props) => {
  const { open, selectedDate, handleDateChange, handleDialog } = props;

  const [innerDate, setInnerDate] = useState(selectedDate);
  const [type, setType] = useState<"hours" | "minutes">("hours");
  const [tabValue, setTabValue] = useState<DayTimePickerView>("dayOfWeek");

  const handleTabChange = (e: React.ChangeEvent<{}> | null, value: DayTimePickerView) => {
    if (value !== tabValue) {
      setTabValue(value);
    }
  };

  const changeOpenView = (incomingType: openViewType) => {
    if (incomingType === "dayOfWeek") {
      if (tabValue !== incomingType) {
        setTabValue(incomingType);
      }
      return;
    }
    if (incomingType !== type || tabValue !== "time") {
      handleTabChange(null, "time");
      setType(incomingType as "hours" | "minutes");
    }
  };

  const changeDayHandler = (value: string) => {
    const newDate = moment(innerDate).day(value);
    setInnerDate(newDate);
    setTabValue("time");
    if (type === "minutes") {
      setType("hours");
    }
  };

  const handleSaveBtn = () => {
    handleDateChange(innerDate);
    handleDialog();
  };

  return (
    <DialogStyle open={open} onClose={handleDialog}>
      <DayAndTimePickerToolbar
        date={innerDate}
        handleDateChange={setInnerDate}
        changeOpenView={changeOpenView}
        openView={tabValue === "dayOfWeek" ? "dayOfWeek" : type}
      />
      <TabContext value={tabValue}>
        <TabContainer square elevation={1}>
          <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange}>
            <Tab value="dayOfWeek" aria-label="pick day" icon={<DateRangeIconStyle />} />
            <Tab value="time" aria-label="pick time" icon={<ScheduleIconStyle />} />
          </Tabs>
        </TabContainer>
        <TabPannelStyled value={"time"}>
          <TabContentStyle>
            <ClockView
              date={innerDate}
              onHourChange={(date) => {
                setInnerDate(date as moment.Moment);
                setType("minutes");
              }}
              onMinutesChange={(date) => {
                setInnerDate(date as moment.Moment);
              }}
              onSecondsChange={() => {}}
              minutesStep={15}
              ampm
              type={type}
            />
          </TabContentStyle>
        </TabPannelStyled>

        <TabPannelStyled value={"dayOfWeek"}>
          <TabContentStyle>
            <DayOfWeekPicker date={innerDate} changeDayHandler={changeDayHandler} />
          </TabContentStyle>
        </TabPannelStyled>
      </TabContext>
      <DialogFooter>
        <FooterBtn color="primary" onClick={handleDialog}>
          Cancel
        </FooterBtn>
        <FooterBtn color="primary" onClick={handleSaveBtn}>
          Ok
        </FooterBtn>
      </DialogFooter>
    </DialogStyle>
  );
};

export default DayAndTimePicker;
