import * as React from "react";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import Combobox from "../../../../components/Combobox/Combobox";
import { useQuery } from "react-apollo";
import { useState, useEffect } from "react";
import { GET_ASSET_TYPE_LIST } from "./asset-type.query";
import { alphabetNameSorting } from "../../../../_utils/utils";
import { AssetType, AssetTypeModel } from "../../../../redux/models/data/AssetTypeModel";
import { ACCOUNT, ASSET_TYPE } from "../../../../_constant/wordings";
import { useGetAssetTypeDetails } from "../../../../customHooks/useGetAssetTypesDescriptionPattern";
import { GET_DETAILED_ITEM_TYPE_LIST } from "../../../_common/filter/asset-type.query";

interface AssetTypeIconProps {
  AssetType: AssetType;
  hasError: boolean;
  errorMessage?: string;
  creatable?: boolean;
  setValueOnFirstLoad?: boolean;
  updateManyFields: (newData: Partial<any>) => void;
}

const AssetTypeSelect: React.FC<AssetTypeIconProps> = (props) => {
  const {
    AssetType,
    errorMessage,
    hasError,
    updateManyFields,
    creatable = true,
    setValueOnFirstLoad = false,
  } = props;

  const [assetTypes, setAssetTypesData] = useState(new Map());
  const [assetTypesOptions, setAssetTypesOptions] = useState([]);

  const { accountId } = useGetAccountId();
  const { getDetailsById } = useGetAssetTypeDetails();

  const { data, loading, error } = useQuery(GET_DETAILED_ITEM_TYPE_LIST, {
    variables: {
      Id: accountId,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (!loading && !error) {
      const brand = process.env.REACT_APP_BRAND;

      const newAssetTypesOptions = data[ACCOUNT]["AssetTypes"]
        .filter((assetType: AssetTypeModel) =>
          brand
            ? assetType.Brands?.toLowerCase() === brand.toLowerCase() || !assetType.IsSystem
            : assetType.Brands === "Upkeepr" || !assetType.IsSystem,
        )
        .sort(alphabetNameSorting)
        .map((item) => ({ value: item.Id, label: item.Name }));
      setAssetTypesOptions(newAssetTypesOptions);
      const newAssetTypes = data[ACCOUNT]["AssetTypes"].map((item) => [item.Id, item]);
      setAssetTypesData(new Map(newAssetTypes));

      if (setValueOnFirstLoad) {
        updateManyFields({
          AssetType: data[ACCOUNT]["AssetTypes"][0],
        });
      }
    }
  }, [data]);

  const getNewValueBasedOnAction = (e, newValue: { id?: string; value: any }) => {
    const { id, value } = newValue;

    if (typeof value === "string") {
      updateManyFields({
        AssetType: { Id: null, Name: value },
        DefaultDescription: "",
        TypeFieldValues: {},
      });
    } else if (newValue && value) {
      const assetType = assetTypes.get(value?.value);
      const { itemTypeField } = getDetailsById(value?.value as string);

      updateManyFields({
        AssetType: assetType ? { ...assetType, ItemTypeFields: itemTypeField } : null,
        DefaultDescription: "",
        TypeFieldValues: {},
      });
    } else if (!value || !newValue) {
      updateManyFields({
        AssetType: { Id: null, Name: "" },
        DefaultDescription: "",
        TypeFieldValues: {},
      });
    }
  };

  const value = { value: AssetType.Id, label: AssetType.Name };

  return (
    <Combobox
      errorText={errorMessage}
      hasError={hasError}
      label="Item type"
      options={assetTypesOptions}
      handleChange={getNewValueBasedOnAction}
      value={value}
      creatable={creatable}
      required
      id={ASSET_TYPE}
      loading={loading}
    />
  );
};

export default AssetTypeSelect;
