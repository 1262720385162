import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { VendorModel } from "../../redux/models/data/VendorModel";

import { createVendorMutation } from "./createVendor.query";
import { getVendorsQuery } from "../../components/AllVendorsWrapper/getVendors.query";

import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";
import { FILE_UPLOAD_RESULTS, ID, VENDORS, VENDOR_POST } from "../../_constant/wordings";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";

import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../redux/actions/actions";
import { updateCacheWithNewVendor } from "./updateCacheWithNewVendor";

import VendorFormContainer from "../../components/VendorForm/VendorFormContainer";
import { ExceededLimitDialog } from "../../containers/_common/Dialog";

const NewVendor: React.FC = () => {
  const [data] = useState(new VendorModel());
  const [isLimitExceeded, setLimitExceeded] = useState(false);

  const { accountId } = useGetAccountId();
  const { successfulRedirect } = useSuccessfulRedirect();
  const limits = useGetLimits();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setHeaderOptions } = useHeaderOptions({
    withBackButton: true,
    headerType: "DEFAULT",
  });

  const { data: vendorsData, loading: isVendorLoading, error } = useQuery(getVendorsQuery, {
    variables: { AccountId: accountId },
    skip: !accountId,
  });

  useEffect(() => {
    if (isVendorLoading || !vendorsData || !limits) return;

    if (vendorsData?.[VENDORS]?.length >= limits.account.maxVendorsPerAccount.Value) {
      setLimitExceeded(true);
    } else {
      setLimitExceeded(false);
    }
  }, [limits, vendorsData, isVendorLoading]);

  const [createVendor, { loading: isCreating }] = useMutation(createVendorMutation);

  const handleCreate = (data: VendorModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }
    const Phone = data.Phone.length ? Number(data.Phone.replace(/\D+/g, "")).toString() : "";
    const { Files } = data;

    const imagesWithAppropriateTypename = data.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = data.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = data.Videos.map((video) => {
      delete video.__typename;
      return video;
    });

    const dataForPost: any = { ...data, Phone, AccountId: accountId };
    delete dataForPost.Files;

    const variables = {
      Vendor: {
        ...dataForPost,
        Images: imagesWithAppropriateTypename,
        Documents: documentsWithAppropriateTypename,
        Videos: videosWithAppropriateTypename,
      },
      Files,
    };
    createVendor({
      variables: variables,
      update(cache, { data: { VendorPost } }) {
        updateCacheWithNewVendor(cache, VendorPost, accountId);
      },
    })
      .then(({ data }: any) => {
        const uploadResult = data[VENDOR_POST]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[VENDOR_POST][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VendorModel(data[VENDOR_POST]));
          displaySuccessNotification(NotificationTypes.VENDOR_CREATED);
          const currentCustomerPath = `${ROUTES.SINGLE_VENDOR}/${data[VENDOR_POST][ID]}`;
          navigate(currentCustomerPath, {
            replace: true,
            state: { justCreated: true },
          });
        } else if (data[VENDOR_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new VendorModel(data[VENDOR_POST]));
          successfulRedirect(ROUTES.VENDORS, NotificationTypes.VENDOR_CREATED);
        } else {
          displayErrorNotification(NotificationTypes.VENDOR_CREATE_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error(message);
        } else {
          displayErrorNotification(NotificationTypes.VENDOR_CREATE_ERROR);
        }
      });
  };

  return (
    <>
      <VendorFormContainer
        data={data}
        setHeaderOptions={setHeaderOptions}
        handleSubmit={handleCreate}
        isNew
        isSaving={isCreating}
        isFetching={isVendorLoading || !vendorsData}
      />
      {isLimitExceeded && (
        <ExceededLimitDialog
          message="Creating a new Vendor would exceed your limit."
          submit={() => navigate(-1)}
          cancel={() => navigate(ROUTES.EDIT_PROFILE)}
        />
      )}
    </>
  );
};

export default NewVendor;
