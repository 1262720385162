import { formatDateWithoutTZ } from "../../../_utils/date";
import { ActivityModel } from "./ActivityModelFull";
import { FieldsSchemaTypes } from "./AssetModel";

export interface ProjectDueDate {}

export interface ProjectActivity {
  Id: string;
  Order: number;
  __typename?: string;
}

export class ProjectModel {
  AccountId?: string | null;
  Id?: null | string;
  Name?: string;
  ActivityIds: [];
  AssignedUserId?: string | null;
  Description?: string;
  DueDateTime?: string | null;
  CompletedDateTime?: string | null;
  ProjectStatusId?: string | null;
  Activities: ProjectActivity[];
  Files: File[];
  Images: any[];
  Videos: any[];
  Documents: any[];
  FileUploadResults: any;
  CustomFields: any;
  Schema: FieldsSchemaTypes[];

  constructor(
    {
      Id = null,
      Name = "",
      ActivityIds = [],
      AssignedUserId = null,
      Description = "",
      DueDateTime = null,
      CompletedDateTime = null,
      ProjectStatusId = null as null | string,
      Activities = [] as ProjectActivity[],
      Files = [] as File[],
      Images = [],
      Videos = [],
      Documents = [],
      CustomFields = {},
      Schema = [] as FieldsSchemaTypes[],
    } = {} as ProjectModel,
  ) {
    this.Id = Id;
    this.Name = Name ?? "";
    this.ActivityIds = ActivityIds ?? [];
    this.AssignedUserId = AssignedUserId;
    this.Description = Description ?? "";
    this.ProjectStatusId = ProjectStatusId ?? null;
    this.DueDateTime = DueDateTime ? formatDateWithoutTZ(DueDateTime) : null;
    this.CompletedDateTime = CompletedDateTime ?? null;
    this.Activities = Activities
      ? Activities.map((activity) => {
          delete activity.__typename;
          return activity;
        })
      : [];
    this.Files = Files ?? [];
    this.Images = Images ?? [];
    this.Videos = Videos ?? [];
    this.Documents = Documents ?? [];
    this.CustomFields = CustomFields ?? {};
    this.Schema = Schema ?? [];
  }
}
