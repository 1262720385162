import { GraphQLObjectType } from "graphql";
import gql from "graphql-tag";
import { PossibleStates } from "../../redux/models/data/InvitationModel";

const generateFilterByState = (statuses: PossibleStates[]): string => {
  if (!statuses || statuses.length < 1) {
    return "";
  }

  return `AND (${statuses.map((state) => `State=='${state}'`).join(" OR ")})`;
};

export const getInvitedByMe = (accountId: string, statuses?: PossibleStates[]) => {
  return gql`
    query getInvitedByMe {
      User {
        Id
        InvitedByMe(Filter: "AccountId=='${accountId}' ${generateFilterByState(
    statuses as PossibleStates[],
  )}") {
          Id
          Account {
            Id
            Name
            Description
          }
          CreateDateTime
          Id
          Message
          Receiver {
            Id
            Email
            FirstName
            LastName
          }
          State
          UpdateDateTime
        }
      }
    }
  `;
};
