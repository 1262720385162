import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Input from "../../Input/Input";
import { WizardItemTypeHeader } from "../WizardItemTypeScreen/WizardItemTypeStyles";
import WizardUploadDropZone from "./WizardUploadDropZone";

const WizardEditItemContainerStyles = styled.div`
  padding: 16px 25px;
  width: 100%;
  height: 100%;

  & .WizardItemTypeStyles__WizardItemTypeHeader-fWgBQb {
    padding: 8px 0;
  }

  & .MuiInputBase-root {
    padding: 8px 16px;
    width: 275px;
    border-radius: 4px;
    border: ${({ theme }) => ` 1px solid ${theme.palette.primary.main}`};
    background-color: transparent;
  }

  & .InputStyles__InputContainer-bkfVOX {
    margin-bottom: 10px;
  }

  & .UploadDropzonestyle__UploadDropzoneWrapper-bvLgvc {
    height: 65%;
  }
`;

export type FileType = File | null;
export type UploadErrorType = Text | null;

const WizardEditItemScreen = (props) => {
  const { newItem, updateItem } = props;
  const [value, setValue] = useState(newItem.Name);
  const [initFile, setInitFile] = useState(newItem.Files[0]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setValue(target.value);
    updateItem({ ...newItem, Name: target.value });
  };

  const [file, setFile] = useState<FileType>(initFile);

  const [uploadError, setUploadError] = useState<UploadErrorType>(null);
  const selectFileHandler = useCallback((newFile: File | null) => {
    setFile(() => newFile);
  }, []);

  return (
    <>
      <WizardEditItemContainerStyles>
        <WizardItemTypeHeader>You can change the name of your item:</WizardItemTypeHeader>
        <Input
          type="text"
          placeholder="Name"
          disableUnderline
          onChange={handleChange}
          value={value}
          id="Name"
        />

        <WizardUploadDropZone
          selectFile={selectFileHandler}
          file={file}
          uploadError={uploadError}
          setUploadError={setUploadError}
          updateItem={updateItem}
          newItem={newItem}
        />
      </WizardEditItemContainerStyles>
    </>
  );
};

export default WizardEditItemScreen;
