import React from "react";
import { useQuery } from "react-apollo";
import { InvitationCard } from "../InvitationCard/InvitationCard";
import Section from "../Section/Section";
import { InvitationStateOptions } from "../../_constant/constants";
import { getInvitedByMe } from "./InvitationList.query";
import { InvitationListSkeleton } from "./InvitationListSkeleton";
import { formatField } from "../../_utils/utils";
import { InvitationModel } from "../../redux/models/data/InvitationModel";
import { toastr } from "react-redux-toastr";

const statusSortOrder = {
  [InvitationStateOptions.active]: 1,
  [InvitationStateOptions.accepted]: 2,
  [InvitationStateOptions.declined]: 3,
  [InvitationStateOptions.cancelled]: 4,
  [InvitationStateOptions.ignored]: 5,
};

const sortInvites = (invites: InvitationModel[]) => {
  return invites.sort((a: InvitationModel, b: InvitationModel) => {
    if (a.State === b.State) {
      return formatField(b.CreateDateTime) - formatField(a.CreateDateTime);
    }

    return statusSortOrder[a.State] - statusSortOrder[b.State];
  });
};

interface InvitationListL {
  accountId: string;
}

export const InvitationList: React.FC<InvitationListL> = ({ accountId }) => {
  const { data, loading, error } = useQuery(
    getInvitedByMe(accountId, [
      InvitationStateOptions.active,
      InvitationStateOptions.declined,
      InvitationStateOptions.ignored,
    ]),
    {
      skip: !accountId,
      fetchPolicy: "network-only",
    },
  );

  if (loading || !accountId) return <InvitationListSkeleton count={2} />;

  if (error) {
    toastr.error(error.message);
    return null;
  }

  if (data && data.User && (!data.User.InvitedByMe || data.User.InvitedByMe.length === 0)) {
    return null;
  }

  return (
    <Section>
      {data &&
        data.User &&
        sortInvites(data.User.InvitedByMe).map((invite: any) => {
          return (
            <InvitationCard
              key={invite.Id}
              email={invite.Receiver.Email}
              date={invite.CreateDateTime}
              status={invite.State}
              invitationId={invite.Id}
              isCancelled={invite.State !== InvitationStateOptions.active}
            />
          );
        })}
    </Section>
  );
};
