import moment from "moment";

export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_FORMAT_INVITATION = "YYYY/MM/DD · HH:mm";

export function getJsDate(value: string) {
  return value ? new Date(value) : new Date();
}

export const formatDate = (date: string) => {
  const formattedDate = moment(date, [DATE_FORMAT, moment.ISO_8601], true);
  return formattedDate.isValid() ? formattedDate.format(DATE_FORMAT) : null;
};

export const formatDateWithoutTZ = (date: string) => {
  const formattedDate = moment(date, [DATE_FORMAT]);
  return formattedDate.isValid() ? formattedDate.format(DATE_FORMAT) : null;
};

export const formatDateForSubmit = (date: string) => {
  const formattedDate = moment(date, [DATE_FORMAT]);
  return formattedDate.isValid() ? formattedDate.format("YYYY-MM-DD[T00:00:00Z]") : null;
};

export const formatDateInCustomFieldsForSubmit = (customFields: {}) => {
  for (const key in customFields) {
    const formattedDate = moment(customFields[key], [DATE_FORMAT]);
    const isDateField = formattedDate.isValid() && moment.isMoment(customFields[key]);

    if (isDateField) {
      customFields[key] = formatDateForSubmit(customFields[key]);
    }
  }
};

export const setCurrentTime = (date: string): moment.Moment | null => {
  const now = moment();

  const formattedDate: moment.Moment = moment(date, [DATE_FORMAT, moment.ISO_8601], true);

  return formattedDate.isValid()
    ? formattedDate.set({ h: now.hours(), m: now.minutes(), s: now.seconds() })
    : null;
};

export const getDateWithUpdatedTime = (DateTime: string) => {
  const dateWithTimeStamp = setCurrentTime(DateTime);

  return dateWithTimeStamp && dateWithTimeStamp.isValid()
    ? dateWithTimeStamp.toISOString(true)
    : null;
};

export const incrementDateDays = (date: string, days: number) => {
  return moment(date, [DATE_FORMAT, moment.ISO_8601], true).add(days, "days");
};

export function getWeekdays(initialDate: string) {
  const date = getJsDate(initialDate),
    month = date.getMonth(),
    days = [];
  const dayIndex = date.getDay();

  date.setDate(1);

  // Get the first in the month
  while (date.getDay() !== dayIndex) {
    // day number Monday - 1
    date.setDate(date.getDate() + 1);
  }

  // Get all the other in the month
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 7);
  }

  return days;
}

export const getWeekOfMonth = (initialDate: string) => {
  const day = getJsDate(initialDate).getDate();
  return Math.ceil(day / 7) - 1;
};

/// generate date for invitationCard

export const getInvitationCardDate = (initialDate: string) => {
  return moment(initialDate).format(DATE_FORMAT_INVITATION);
};

export const generateAmPm = (): { [key: string]: string }[] => {
  const result: { [key: string]: string }[] = [];

  const dt = new Date(1970, 0, 1);
  const step = 15;
  while (dt.getDate() === 1) {
    const timeString = dt.toLocaleTimeString("en-US");
    const indexForSlice = timeString.lastIndexOf(":00");
    const finalTime = `${timeString.slice(0, indexForSlice)} ${timeString
      .slice(indexForSlice + 4, timeString.length)
      .toLocaleLowerCase()}`;

    result.push({
      key: finalTime,
      value: finalTime,
    });
    dt.setMinutes(dt.getMinutes() + step);
  }

  return result;
};

export const getWeekDays = (): { [key: string]: string }[] => {
  return [
    {
      key: "Monday",
      value: "Monday",
    },
    {
      key: "Tuesday",
      value: "Tuesday",
    },
    {
      key: "Wednesday",
      value: "Wednesday",
    },
    {
      key: "Thursday",
      value: "Thursday",
    },
    {
      key: "Friday",
      value: "Friday",
    },
    {
      key: "Saturday",
      value: "Saturday",
    },
    {
      key: "Sunday",
      value: "Sunday",
    },
  ];
};

export const DAYS_OF_WEEK = {
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
};

type Meridiem = "am" | "pm" | null;

export const convertValueToMeridiem = (value: number, meridiem: Meridiem) => {
  const currentMeridiem = value >= 12 ? "pm" : "am";

  if (currentMeridiem !== meridiem) {
    return meridiem === "am" ? value - 12 : value + 12;
  }
};

export const getMeridiem = (date: string | moment.Moment): Meridiem => {
  if (!date) {
    return null;
  }

  return moment(date).hours() >= 12 ? "pm" : "am";
};
