import React from "react";
import { Typography } from "@material-ui/core";
import { CardParent } from "../Cards/styled/card.styled";
import { CardPaddingContainer } from "./ActiveActivityCard.style";

interface CardInfoProps {
  title: string;
  subtitle: string;
  prefix: string;
}

const CardInfo: React.FC<CardInfoProps> = ({ title, subtitle, prefix }) => {
  return (
    <div>
      <Typography variant="body2" component="h3" noWrap>
        {title}
      </Typography>
      <CardPaddingContainer>
        <CardParent alignItems="start">
          <span>{prefix.toUpperCase()} </span>
          {subtitle}
        </CardParent>
      </CardPaddingContainer>
    </div>
  );
};

export default CardInfo;
