import moment from "moment";
import { FullActivityDueDate } from "../../../../../../../redux/models/data/ActivityModelFull";
import { notEmpty } from "../../../../../../../_utils/utils";
import { DATE_FORMAT } from "../../../../../../../_utils/date";
import {
  EndAfter,
  INVALID_DATE,
  RepeatAfter,
  RepeatType,
} from "../../../../../../../_constant/constants";

export const validateDay = (dayForCheck, dueDateValue) => {
  const lastDayOfMonth = notEmpty(dueDateValue)
    ? moment(dueDateValue, [DATE_FORMAT, moment.ISO_8601], true).daysInMonth()
    : moment().daysInMonth();

  if (+dayForCheck > +lastDayOfMonth) {
    return `${lastDayOfMonth}`;
  } else if (+dayForCheck === 0 || dayForCheck === "") {
    return "1";
  } else {
    return dayForCheck;
  }
};

export function getValidDateValue(date: string) {
  return date === INVALID_DATE ? null : date;
}

function isDueDateValid(value: FullActivityDueDate) {
  return value.schedule.RecurringRepeatType === RepeatAfter.COMPLETED_DATE || !!value.dueDate;
}

export function validateDueDate(value: FullActivityDueDate, errorSet: Set<string>) {
  const nextErrFields = new Set(errorSet);
  if (isDueDateValid(value)) {
    nextErrFields.delete("DueDate");
  } else {
    nextErrFields.add("DueDate");
  }
  return nextErrFields;
}

export function checkIfDueDateValid(value: FullActivityDueDate, errorSet: Set<string>) {
  if (!isDueDateValid(value)) {
    return errorSet;
  }
  const nextErrFields = new Set(errorSet);
  nextErrFields.delete("DueDate");
  return nextErrFields;
}

export function checkIfRecurringCountValid(value: FullActivityDueDate, errorSet: Set<string>) {
  if (!isRecurrenceCountValid(value)) {
    return errorSet;
  }
  const nextErrFields = new Set(errorSet);
  nextErrFields.delete("RecurrenceCount");
  return nextErrFields;
}

function isEndDateValid(value: FullActivityDueDate) {
  return value.endRecurring !== EndAfter.END_DATE || !!value?.schedule?.EndDate;
}

export function isNumberCheck(value: number | null | undefined) {
  return typeof value === "number" && isFinite(value);
}

function isRepeatCountValid(value: FullActivityDueDate) {
  return (
    value.endRecurring !== EndAfter.REPEAT_COUNT || isNumberCheck(value?.schedule?.AppearanceCount)
  );
}

function isRecurrenceCountValid(value: FullActivityDueDate) {
  const recurCount = value?.schedule?.RecurrenceCount;
  return !!(recurCount && parseInt(recurCount.toString()));
}

function validateRecurring(value: FullActivityDueDate, errorSet: Set<string>) {
  const nextErrFields = new Set(errorSet);
  if (isRecurrenceCountValid(value)) {
    nextErrFields.delete("RecurrenceCount");
  } else {
    nextErrFields.add("RecurrenceCount");
  }
  if (isEndDateValid(value)) {
    nextErrFields.delete("EndDate");
  } else {
    nextErrFields.add("EndDate");
  }
  if (isRepeatCountValid(value)) {
    nextErrFields.delete("AppearanceCount");
  } else {
    nextErrFields.add("AppearanceCount");
  }
  return nextErrFields;
}

export function checkIfRecurringValid(value: FullActivityDueDate, errorSet: Set<string>) {
  const nextErrFields = new Set(errorSet);
  if (isEndDateValid(value)) {
    nextErrFields.delete("EndDate");
  }
  if (isRepeatCountValid(value)) {
    nextErrFields.delete("AppearanceCount");
  }
  return nextErrFields;
}

export function getDueDateFieldsWithError(value: FullActivityDueDate): Set<string> {
  // global validation
  let errorFields = new Set<string>();
  if (value.schedule.RecurringType !== RepeatType.NONE) {
    errorFields = validateDueDate(value, errorFields);
    errorFields = validateRecurring(value, errorFields);
  }

  return errorFields;
}

export function isDateValid(value) {
  value = moment(value, [DATE_FORMAT, moment.ISO_8601], true);
  return value.isValid();
}
