import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";

export const StepperLineContainer = styled(LinearProgress)(
  ({ theme }) =>
    `
  background-color: ${theme.palette.background.paper};
  height: 2px;
`,
);
