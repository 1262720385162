import React, { useCallback, useState } from "react";

import { useLayoutSwitcherContext } from "../LayoutSwitcher/useLayoutSwitcherContext";

import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import { AssetModel } from "../../redux/models/data/AssetModel";

import { LayoutType } from "../../_constant/constants";

import AssetCardRow from "../Cards/AssetCardRow";
import AssetCardLink from "../AssetCardAdditionalInfo/AssetCardLink";
import AssetItem from "../../containers/assets/_components/AssetItem";
import { NoAssetsComponent } from "../NoResultComponent/NoResultComponent";

import "./AssetsList.scss";
import { AssetListWrapper, AssetsListContainer, AssetsListStyled } from "./AssetsListStyles";
export interface RemoveDialogState {
  isOpen: boolean;
  assetId: null | string;
  assetName: string;
  customerId?: null | string;
  isLoading: boolean;
}

interface AssetsListProps {
  assets: AssetRefsModel[];
}

const AssetsList: React.FC<AssetsListProps> = ({ assets }) => {
  const {
    state: { items: layout },
  } = useLayoutSwitcherContext();

  const renderAppropriateCard = (item: AssetRefsModel) => {
    switch (layout) {
      case LayoutType.BOARD:
        return <AssetItem data={item} key={item.Id} />;
      case LayoutType.LIST:
        return (
          <AssetCardRow
            item={item}
            key={item.Id}
            additionalInfo={<AssetCardLink item={item} />}
            hideRemoveOption
          />
        );
      default:
        return <AssetItem data={item} key={item.Id} />;
    }
  };

  return (
    <>
      <AssetsListStyled>
        <AssetListWrapper maxWidth={layout === LayoutType.BOARD ? "xl" : "sm"}>
          <AssetsListContainer $layout={layout}>
            {assets.map((item: AssetRefsModel) => {
              return renderAppropriateCard(item);
            })}
            {assets.length === 0 && <NoAssetsComponent />}
          </AssetsListContainer>
        </AssetListWrapper>
      </AssetsListStyled>
    </>
  );
};

export default AssetsList;
