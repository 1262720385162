import gql from "graphql-tag";

export const typeDefs = gql`
  extend type AssetActivity {
    isLoading: Boolean
    AssetName: String
  }

  extend type AssetTypeRef {
    IconName: String
    Name: String
  }

  extend type AssetType {
    IconName: String
    Name: String
  }

  extend type ProjectType {
    isLoading: Boolean
  }
`;

export const assetNameFragment = gql`
  fragment AssetName on AssetRef {
    Name
  }
`;

interface IAssetNameFragment {
  Name: string;
}

export type assetTypeRefFragmentType = IAssetNameFragment | null;

export const assetTypeRefFragment = gql`
  fragment AssetTypeInfo on AssetType {
    Name
    IconName
  }
`;

export const customerNameFragment = gql`
  fragment CustomerName on Customer {
    Name
  }
`;

export const customerImageDataFragment = gql`
  fragment CustomerImage on Customer {
    Images {
      Id
      ContainerId
      ContainerName
    }
  }
`;

export const finishVisitDialogCustomerData = gql`
  fragment finishVisitDialogCustomerData on Customer {
    Name
    Email
    Images {
      Id
      ContainerId
      ContainerName
    }
  }
`;

export const visitStatusFragment = gql`
  fragment VisitStatusName on VisitStatus {
    Name
  }
`;

const getNameFromEmail = (_root) => {
  if (!_root || !_root.Id) {
    return "";
  }

  if (_root.Email && !_root.FirstName) {
    const { Email } = _root;
    return Email.substring(0, Email.indexOf("@"));
  }
};

export const resolvers = {
  AssetActivity: {
    isLoading() {
      return false;
    },
    Asset(_root, variables, { cache }) {
      if (_root) {
        if (_root.Asset) {
          return _root.Asset;
        }

        const Id = _root.AssetId;
        let NameFromRef = "";
        try {
          NameFromRef = cache.readFragment({ fragment: assetNameFragment, id: Id }).Name;
        } catch (error) {}
        return {
          Id,
          Name: NameFromRef,
          __typename: "AssetRef",
        };
      }
    },
  },
  AssetTypeRef: {
    Name(_root, variables, { cache }) {
      if (_root && cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id })) {
        const result = cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id });
        return result ? result?.Name : "";
      }
      return "";
    },
    IconName(_root, variables, { cache }) {
      if (_root && cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id })) {
        return cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id }).IconName;
      }
      return "";
    },
  },
  AssetType: {
    Name(_root, variables, { cache }) {
      if (_root && cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id })) {
        const result = cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id });
        return result ? result?.Name : "";
      }
      return "";
    },
    IconName(_root, variables, { cache }) {
      if (_root && cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id })) {
        const result = cache.readFragment({ fragment: assetTypeRefFragment, id: _root.Id });
        return result ? result?.IconName : "";
      }
      return "";
    },
  },
  Visit: {
    CustomerName(_root, variables, { cache }) {
      if (_root && cache.readFragment({ fragment: customerNameFragment, id: _root.CustomerId })) {
        const result = cache.readFragment({ fragment: customerNameFragment, id: _root.CustomerId });
        return result ? result.Name : "";
      }
      return "";
    },
    VisitStatusName(_root, variables, { cache }) {
      if (_root && cache.readFragment({ fragment: visitStatusFragment, id: _root.VisitStatusId })) {
        const foundStatus = cache.readFragment({
          fragment: visitStatusFragment,
          id: _root.VisitStatusId,
        });
        return foundStatus.Name;
      }
      return "";
    },
  },
  User: {
    FirstName: (_root) => getNameFromEmail(_root),
  },
  UserRefs: {
    FirstName: (_root) => getNameFromEmail(_root),
  },
  ProjectType: {
    isLoading() {
      return false;
    },
  },
};
