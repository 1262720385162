import React from "react";
import styled from "styled-components";
import List from "@material-ui/core/List/List";
import { ListItem, Popover } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export const PopoverStyle = styled(Popover)`
  & .MuiPaper-rounded {
    border-radius: 6px;
  }
`;

export const ListContainer = styled(List)`
  width: 180px;
  padding: 4px 0;
`;

interface ListItemStyleProps {
  primary?: boolean;
  $padding?: string;
}
export const ListItemStyle = styled(({ primary, ...rest }) => (
  <ListItem {...rest} />
))<ListItemStyleProps>`
  padding: ${({ $padding }) => $padding ?? "16px"};
  color: ${({ primary, theme }) =>
    primary ? theme.palette.primary.main : theme.palette.text.primary};
`;

export const ListItemTextStyle = styled(ListItemText)`
  margin: 0;
`;

export const ListItemIconStyle = styled(ListItemIcon)`
  min-width: 0;
  padding-right: 12px;
`;

interface KeyboardArrowDownIconStyleProps {
  isOpen: boolean;
}
export const KeyboardArrowDownIconStyle = styled(({ isOpen, ...rest }) => (
  <KeyboardArrowDownIcon {...rest} />
))<KeyboardArrowDownIconStyleProps>`
  transition: transform 0.3s;

  ${({ isOpen }) => (isOpen ? "transform: rotate(180deg);" : "")}
`;
