import * as React from "react";
import Input from "../../../../../../../components/Input";
import {NOT_DIGITS} from "../../../../../../../_utils/regex";

interface RecurrenceCountInputProps {
  value: number;
  onChange: (name, value) => void;
  hasError: boolean;
}

export default function RecurrenceCountInput(props: RecurrenceCountInputProps) {

  const handleChange = ({target}) => {
    props.onChange("RecurrenceCount", target.value.replace(NOT_DIGITS, ""))
  };

  const handleBlur = ({target: {value}}) => {
    const valueToNumber = parseInt(value);
    if (Number.isNaN(valueToNumber) || valueToNumber === 0) {
      props.onChange("RecurrenceCount", 1)
    }
  };

  return (
    <>
      <label className={"Label"} htmlFor={"day-recurring"}>Every</label>
      <Input className={"ReccuringFrequence"} name={"day-recurring"}
             value={(props.value && props.value > 0) ? props.value : ""} onChange={handleChange}
             placeholder={"1"} onBlur={handleBlur} hasError={props.hasError}
      />
    </>
  );
}
