import { Container, Hidden, useMediaQuery } from "@material-ui/core";
import * as React from "react";

import "./Form.scss";

interface FormProps {
  header?: boolean;
  body?: boolean;
  content?: boolean;
  images?: boolean;
  addBottomSpace?: boolean;
}

const Form: React.FC<FormProps> = ({ children, header, body, content, images, addBottomSpace }) => {
  const isDesktopView = useMediaQuery("(min-width:600px)", { noSsr: true });

  if (header)
    return (
      <div className="Form__header">
        <Hidden xsDown>{children}</Hidden>
      </div>
    );
  if (body)
    return (
      <div className={`Form__body ${addBottomSpace ? "Form__bottomPadding" : ""}`}>{children}</div>
    );
  if (content) return <div className="Form__content">{children}</div>;
  if (images) return <div className="Form__images">{children}</div>;

  return (
    <Container maxWidth="md" className="Form" disableGutters={!isDesktopView}>
      <>{children}</>
    </Container>
  );
};

export default Form;
