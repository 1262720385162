import * as React from "react";
import { ReactNode, useContext, useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { dataApiInterface } from "../../customHooks/useChange";

import { Image } from "../../redux/models/data/ImageModel";
import { CustomerModel } from "../../redux/models/data/CustomersModel";

import { ROUTES } from "../../_constant/screens";

import DeleteButton from "../DeleteButton/DeleteButton";
import DeleteCustomerDialog from "../DeleteCustomerDialog/DeleteCustomerDialog";

import Button from "@material-ui/core/Button/Button";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Collapse from "@material-ui/core/Collapse";

import { setEditFormIsDirty } from "../../redux/actions/actions";
import { CustomerFormContext } from "./CustomerFormContext";

import Form from "../Form/Form";
import Body from "../Body/Body";
import ItemsTab from "../ItemsTab/ItemsTab";
import VisitTab from "../VisitTab/VisitTab";
import Modal from "../../containers/Modal/Modal";
import VisitTabContent from "../VisitTab/VisitTabContent";
import FormHeader from "../../components/FormHeader/FormHeader";
import VisitStatusContent from "../VisitTab/VisitStatusContent";
import CustomerFormLayout from "./CustomerFormLayout";
import { RevertDialogComponent } from "../../containers/_common/Dialog";
import EntityTabsProvider from "../EntityTabsContainer/EntityTabsProvider";
import CustomizeActivityButton from "../CustomizeActivityButton/CustomizeActivityButton";
import EntityTabsContainer from "../../components/EntityTabsContainer/EntityTabsContainer";

import RelatedActivities from "../../containers/assets/_components/relaitedActivities/RelatedActivities";
import StatusAccordionCustomer from "../../containers/assets/_components/relaitedActivities/byStatuses/StatusAccordionCustomer";
import StatusesAccordionBuilder from "../../containers/assets/_components/relaitedActivities/byStatuses/StatusesAccordionBuilder";
export interface ITabContent {
  name: string;
  disabled: boolean;
  hasCustomizeMode: boolean;
  content: ReactNode;
  tabControl?: ReactNode | null;
}

export interface CustomerFormProps {
  handleSubmit: (e?: React.SyntheticEvent<Element, Event> | null, justSave: boolean) => void;
  isLoading: boolean;
  isNew: boolean;
  data: CustomerModel;
  setHeaderOptions: (props: any) => void;
  lockSaveButton: boolean;
  formData: CustomerModel;
  formApi: dataApiInterface;
  handleChangeWithValidation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  phoneError: string;
  emailError: string;
  updateFiles: (fromServer: Image[] | null, local: File[] | null) => void;
  dropZone: any;
}

const CustomerFormDesktop = () => {
  const {
    isDeletePopupVisible,
    isWizardOpen,
    isCustomize,
    isRevertPopupVisible,
    handleCloseWizard,
    handleOpenWizard,
    handleCustomize,
    setIsDeletePopupVisible,
    setIsRevertPopupVisible,
    handleSubmit,
    isLoading,
    isNew,
    data: propsData,
    setHeaderOptions,
    lockSaveButton,
    formApi,
    formData,
    phoneError,
    emailError,
    handleBlur,
    handleChangeWithValidation,
    dropZone,
  } = useContext(CustomerFormContext);

  const dispatch = useDispatch();

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);

  const [isItemsFetching, setIsItemsFetching] = useState(false);

  const handleDeleteDialogOpen = () => {
    setIsDeletePopupVisible(true);
    setIsItemsFetching(true);
  };

  const customerTabs: ITabContent[] = [
    {
      name: "Visit",
      disabled: isNew,
      content: (
        <VisitTab customerId={formData.Id ?? ""} isCustomize={isCustomize}>
          <VisitTabContent
            renderComponent={(props) => {
              return <VisitStatusContent customerId={formData?.Id || ""} {...props} />;
            }}
          />
        </VisitTab>
      ),
      tabControl: isNew ? null : (
        <CustomizeActivityButton
          disabled={false}
          onClick={handleCustomize}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Visit"}
        </CustomizeActivityButton>
      ),
      hasCustomizeMode: true,
    },
    {
      name: "Activities",
      disabled: isNew,
      content: (
        <RelatedActivities
          assetId={""}
          isCustomize={isCustomize}
          customerId={formData?.Id ?? ""}
          withAssetSelect
          isWizardOpen={isWizardOpen}
          handleOpenWizard={handleOpenWizard}
          handleCloseWizard={handleCloseWizard}
        >
          {formData?.Id ? (
            <StatusesAccordionBuilder
              withCreateButton
              customerId={formData?.Id || ""}
              renderComponent={(props) => (
                <StatusAccordionCustomer customerId={formData?.Id || ""} {...props} />
              )}
            />
          ) : null}
        </RelatedActivities>
      ),
      tabControl: isNew ? null : (
        <CustomizeActivityButton
          disabled={false}
          onClick={handleCustomize}
          color="primary"
          withStartIcon={!isCustomize}
        >
          {isCustomize ? "Cancel" : "Activity"}
        </CustomizeActivityButton>
      ),
      hasCustomizeMode: true,
    },
    {
      name: "Items",
      disabled: isNew,
      content: (
        <ItemsTab
          isWizardOpen={isWizardOpen}
          handleCloseDialog={handleCloseWizard}
          customerId={formData?.Id ?? ""}
          isCustomerLoading={isLoading}
        />
      ),
      tabControl: isNew ? null : (
        <Button
          onClick={handleOpenWizard}
          color="primary"
          startIcon={<AddOutlinedIcon color="primary" fontSize="small" />}
        >
          Item
        </Button>
      ),
      hasCustomizeMode: false,
    },
    {
      name: "Manage",
      disabled: isNew,
      content: (
        <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
          <DeleteButton
            onClick={handleDeleteDialogOpen}
            disabled={false}
            loading={isLoading || isItemsFetching}
          >
            Delete Customer
          </DeleteButton>
        </div>
      ),
      tabControl: null,
      hasCustomizeMode: false,
    },
  ];

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      pageTitle: isNew ? "Add New Customer" : "Edit Customer",
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        delete: () => setIsDeletePopupVisible(true),
        revertFunction: openRevertDialog,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading]);

  return (
    <Body disableDesktopScroll>
      <Modal isShowing={isRevertPopupVisible}>
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      </Modal>
      <Modal isShowing={isDeletePopupVisible}>
        <DeleteCustomerDialog
          customerId={formData.Id}
          customerName={formData.Name}
          handleCancel={() => setIsDeletePopupVisible(false)}
          handleFinishPopupLoading={() => setIsItemsFetching(false)}
        />
      </Modal>
      <Form>
        <Form header>
          <FormHeader
            isNew={isNew}
            isBusinessAccount={false}
            title={"Customer"}
            titlePrefix={isNew ? "Add New" : undefined}
            isLoading={isLoading}
            isSaveAvailable={lockSaveButton}
            handleSubmit={handleSubmit}
            cancelRouteForEmptyHistory={ROUTES.CUSTOMERS}
            revertData={openRevertDialog}
            isDataChanged={formApi.isDataChanged}
          />
        </Form>

        <Form body>
          <Form content>
            <Collapse in={!isCustomize} timeout="auto" unmountOnExit>
              <CustomerFormLayout
                errorFields={formApi.errorFields}
                data={formApi.data}
                handleChange={formApi.handleChange}
                handleChangeWithValidation={handleChangeWithValidation}
                handleBlur={handleBlur}
                phoneError={phoneError}
                emailError={emailError}
              />
            </Collapse>
            {
              <EntityTabsProvider>
                <EntityTabsContainer
                  content={
                    isCustomize
                      ? customerTabs.filter((el) => el.hasCustomizeMode === isCustomize)
                      : customerTabs
                  }
                  entityName="customer"
                  isNew={isNew}
                  startFrom="0"
                  isCustomize={isCustomize}
                  changeCustomize={handleCustomize}
                  tabIndexAfterCreate="2"
                />
              </EntityTabsProvider>
            }
          </Form>
          <Form images>{dropZone}</Form>
        </Form>
      </Form>
    </Body>
  );
};

export default CustomerFormDesktop;
