import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { VisitModel } from "../../redux/models/data/VisitModel";

import { ROUTES } from "../../_constant/screens";

import { isRecurring } from "../../_utils/utils";
import { formatDateWithoutTZ } from "../../_utils/date";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

import Modal from "../../containers/Modal/Modal";
import AssignmentVisitContainer from "./AssignmentVisitContainer";
import DeleteVisitDialog from "../DeleteVisitDialog/DeleteVisitDialog";
import { ActivityItemDate } from "../../containers/activities/_components/item/ActivityItemDate";

import { CardContainer, CardParent } from "../Cards/styled/card.styled";
import MoreAction from "../MoreAction/MoreAction";

interface VisitCardProps {
  visit: VisitModel | any;
  dateType: string;
  status: string;
  disableNavigationByClick?: boolean;
  style?: {};
  isAccountPersonal: boolean;
  isDraggable?: boolean;
  isAssignmentsVisible: boolean;
}
const VisitCard: React.FC<VisitCardProps> = (props) => {
  const {
    visit,
    status,
    dateType,
    isAccountPersonal,
    disableNavigationByClick,
    isAssignmentsVisible,
    isDraggable,
    style,
  } = props;

  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const openDeleteDialog = useCallback(() => setIsDeletePopupVisible(true), []);
  const closeDeleteDialog = useCallback(() => setIsDeletePopupVisible(false), []);

  const statusStyleName = status.toLowerCase().split(" ").join("-");

  const navigate = useNavigate();

  const from960 = useMediaQuery("(min-width:960px)", { noSsr: true });

  const from600 = useMediaQuery("(min-width: 600px)", { noSsr: true });

  const iconClassName = isRecurring({
    schedule: visit.Schedule,
    relatedDueDate: visit.RelatedDueDateTime,
  })
    ? "ActivityItem_recurring"
    : "";

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: openDeleteDialog,
    },
  ];

  const renderAssignment = () =>
    !isDraggable && !isAccountPersonal ? (
      <AssignmentVisitContainer
        assignedUserId={visit.AssignedUserId}
        visitId={visit.Id}
        purpose={visit.Purpose}
      />
    ) : null;

  const handleOnClick = () => {
    navigate(`${ROUTES.SINGLE_VISIT}/${visit.Id}`);
  };

  return (
    <>
      <Modal isShowing={isDeletePopupVisible}>
        <DeleteVisitDialog
          visitId={visit.Id}
          handleCancel={closeDeleteDialog}
          VisitStatusId={visit.VisitStatusId}
        />
      </Modal>
      <CardContainer
        padding={1}
        borderRadius={isDraggable ? "8px" : "0"}
        onClick={handleOnClick}
        withLabel={statusStyleName}
      >
        <Grid spacing={1} container alignItems="center" wrap="nowrap">
          <Grid item xs zeroMinWidth>
            <Box paddingLeft="4px" paddingRight="10px">
              <Typography variant="body2" component="h3" noWrap>
                {visit.Purpose}
              </Typography>
              {visit?.Purpose && (
                <CardParent>
                  <span>WITH </span>
                  <Typography variant="body2" component="h5" noWrap style={{ fontSize: 10 }}>
                    {visit.CustomerName}
                  </Typography>
                </CardParent>
              )}
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={3}>
              {isAssignmentsVisible && renderAssignment()}
            </Grid>
          </Hidden>
          <Grid item xs={from960 ? 4 : false}>
            <Grid item container alignItems="center" justify="flex-end" spacing={1} wrap="nowrap">
              <Grid item style={{ minWidth: from600 ? 110 : 0 }}>
                <ActivityItemDate
                  activityDate={formatDateWithoutTZ(visit[dateType])}
                  dateType={dateType}
                  iconOnly={!from600}
                />
              </Grid>
              <Grid item>
                <i className={`Icon ${iconClassName}`}>repeat</i>
              </Grid>
              <Grid item>
                <MoreAction options={options} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContainer>
    </>
  );
};

export default VisitCard;
