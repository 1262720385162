import React, { ChangeEvent, useEffect } from "react";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { useMediaQuery } from "@material-ui/core";
import { ROUTES } from "../../_constant/screens";
import { NAV_LIST } from "../../_constant/navigation";

import { useGetUserSubscriptionTier } from "../../customHooks/useGetUserSubscriptionTier";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 100,
  },
});

const MobileNavigation: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const userSubscriptionTier = useGetUserSubscriptionTier();

  const { IsCustomerManagement } = useGetAccountId();

  const [value, setValue] = React.useState(0);

  const getNavList = () => {
    if (IsCustomerManagement) {
      return NAV_LIST.business;
    }
    if (
      (userSubscriptionTier.isProfessional || userSubscriptionTier.isPremium) &&
      !userSubscriptionTier.loading
    ) {
      return NAV_LIST.default;
    } else {
      return NAV_LIST.default.filter((value) => value.label !== "Projects");
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const visitsMatch = useMatch(ROUTES.VISITS);
  const customersMatch = useMatch(ROUTES.CUSTOMERS);

  useEffect(() => {
    const foundTabIndex = getNavList().findIndex((tab) => location.pathname.startsWith(tab.path));
    if (foundTabIndex >= 0) {
      setValue(foundTabIndex);
    }
  }, [location]);

  const handleClick = (e: ChangeEvent<any>, newValue: number) => {
    if (!getNavList()[newValue]) return;
    navigate(getNavList()[newValue].path);
  };

  if (isMobile && IsCustomerManagement && (visitsMatch || customersMatch)) {
    return (
      <BottomNavigation value={value} onChange={handleClick} showLabels className={classes.root}>
        {getNavList().map((tab) => {
          return <BottomNavigationAction key={tab.label} label={tab.label} icon={tab.icon} />;
        })}
      </BottomNavigation>
    );
  }

  return null;
};

export default MobileNavigation;
