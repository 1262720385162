import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import useGetAccountId from "./useGetAccountId";
import { ROUTES } from "../_constant/screens";
import { useNavigate } from "react-router-dom";

const GET_BUSINESS_STATUSES_LIST = gql`
  query getBusinessStatusesList {
    SystemData {
      VisitStatuses {
        Id
        Name
        Label
        SortOrder
      }
    }
  }
`;

const useGetBusinessStatuses = (skip?: boolean) => {
  const { accountId } = useGetAccountId();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_BUSINESS_STATUSES_LIST, {
    variables: { Id: accountId },
    fetchPolicy: "cache-first",
    skip: !accountId || skip,
  });

  if (loading) {
    return [];
  }
  if (error) {
    navigate(ROUTES.ERROR);
    return [];
  }

  if (data && data.SystemData) {
    return data.SystemData.VisitStatuses.sort(
      (current, next) => current.SortOrder - next.SortOrder,
    );
  }

  return [];
};

export default useGetBusinessStatuses;
