import React, { ChangeEvent } from "react";
import AssignUserSelect from "../AssignUserSelect/AssignUserSelect";
import { useMutation } from "react-apollo";
import { assignUserQuery } from "./assignActivityToUser.query";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ACTIVITY_PATCH } from "../../_constant/wordings";
import { AssignUserInsideCard } from "./AssignUserInsideCard";

interface IAssigmentContainer {
  activityId: string;
  assignedUserId: string;
  withoutControl?: boolean;
}

const AssignmentActivityContainer: React.FC<IAssigmentContainer> = (props) => {
  const { activityId, assignedUserId, withoutControl } = props;

  const { accountId } = useGetAccountId();
  // (e: ChangeEvent<HTMLInputElement> | null, target?: any)
  const [assignActivityMutation, { data, loading, error }] = useMutation(assignUserQuery);

  const handleAssignActivity = (e: ChangeEvent<HTMLInputElement> | null, target?: any) => {
    assignActivityMutation({
      variables: {
        AccountId: accountId,
        Id: activityId,
        AssignedUserId: target.value,
      },
    })
      .then(({ data }: any) => {
        if (data[ACTIVITY_PATCH] && data[ACTIVITY_PATCH].AssignedUserId === target.value) {
          displaySuccessNotification(NotificationTypes.ACTIVITY_ASSIGN_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.ACTIVITY_ASSIGN_UPDATED_ERROR);
        }
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.ACTIVITY_ASSIGN_UPDATED_ERROR);
      });
  };

  return (
    <AssignUserInsideCard>
      <AssignUserSelect
        assignedUserId={assignedUserId}
        handleFieldChange={handleAssignActivity}
        isLoading={loading}
        withEmptyId
        withoutControl={withoutControl}
      />
    </AssignUserInsideCard>
  );
};

export default AssignmentActivityContainer;
