import * as React from "react";
import { useEffect, useState } from "react";
import { DateDialog } from "../../../../_common/Dialog";
import Button from "../../../../../components/Button";
import DateValue from "../../../../../components/DateValue/DateValue";
import CompletedDateForm from "./CompletedDateForm";
import { ActivitiesFieldName, INVALID_DATE } from "../../../../../_constant/constants";
import { isDateValid } from "../due_date/forms/recurring/validation";
import config from "../../../../../../configs/statuses-config.json";
import { formatDate } from "../../../../../_utils/date";
import useGetAcitvitiesStatuses from "../../../../../customHooks/api/useGetAcitvitiesStatuses";
import { DueDateButton } from "../due_date/DueDateComponent.style";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import useGetBusinessStatuses from "../../../../../customHooks/useGetBusinessStatuses";
import useGetProjectsStatuses from "../../../../../customHooks/api/useGetProjectsStatuses";

interface CompletedDateComponentProps {
  value: string;
  onChange: (event, customEvent) => void;
  statusFieldName?: "VisitStatusId" | "ProjectStatusId" | "StatusId";
  disabled?: boolean;
  disabledInfoText?: string;
  statusId?: string;
}

const { FILL, FINISH, CANCELLED } = config["statusNameMap"];

export default function CompletedDateComponent(props: CompletedDateComponentProps) {
  const [isOpened, setOpen] = useState(false);
  const [value, setValue] = useState(props.value);
  const [isSubmitDisabled, setSubmitDisabled] = useState(!isDateValid(value));
  const [statusIdCompleted, setStatusId] = useState("");
  const [statusIdCancelled, setStatusIdCancelled] = useState("");

  const statusFieldName = props.statusFieldName ?? "StatusId";
  const disabled = !!props.disabled;
  const statuses = useGetAcitvitiesStatuses();
  const statusesBusiness = useGetBusinessStatuses();
  const statusesProject = useGetProjectsStatuses();

  const isCancelled = statusIdCancelled === props.statusId;

  useEffect(() => {
    if (statusFieldName === "VisitStatusId" && statusesBusiness.length > 0) {
      const statusId = statusesBusiness.find((status) => status.Name === FINISH).Id;
      setStatusId(statusId);
    }
    if (statusFieldName === "ProjectStatusId" && statusesProject.length > 0) {
      const statusId = statusesProject.find((status) => status.Name === FINISH).Id;
      setStatusId(statusId);
    }
    if (statusFieldName === "ProjectStatusId" && statusesProject.length > 0) {
      const statusId = statusesProject.find((status) => status.Name === CANCELLED).Id;
      setStatusIdCancelled(statusId);
    }
    if (statusFieldName === "StatusId" && statuses.length > 0) {
      const statusId = statuses.find((status) => status.Name === FILL).Id;
      setStatusId(statusId);
    }
  }, [statuses, statusesProject, statusesBusiness]);

  useEffect(() => {
    // component receive new props
    setValue(props.value);
    setSubmitDisabled(!isDateValid(props.value));
  }, [props.value]);

  const handleSubmit = () => {
    if (value !== INVALID_DATE) {
      if (!isCancelled) {
        props.onChange(null, {
          id: statusFieldName,
          value: statusIdCompleted,
        });
      }

      props.onChange(null, { id: ActivitiesFieldName.COMPLETED_DATE_TIME, value: value });
    }

    setOpen(false);
  };

  const handleChange = (value) => {
    setValue(value);
    setSubmitDisabled(!isDateValid(value));
  };

  const handleClose = () => {
    setOpen(false);
    setValue(null);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };

  return (
    <>
      {props.value ? (
        <DateValue
          label="Completed Date"
          value={formatDate(props.value)}
          className="notRequired"
          onClick={() => setOpen(true)}
          isCancelled={isCancelled}
          isCompleted
        />
      ) : (
        <div title={disabled && props.disabledInfoText ? props.disabledInfoText : ""}>
          <DueDateButton
            startIcon={<EventAvailableOutlinedIcon fontSize="small" />}
            onClick={handleOpen}
            variant="text"
            color="primary"
            disabled={disabled}
          >
            Completed Date
          </DueDateButton>
        </div>
      )}
      {isOpened && (
        <DateDialog
          header={"Completed Date"}
          cancel={handleClose}
          submit={handleSubmit}
          submitDisabled={isSubmitDisabled}
        >
          <CompletedDateForm
            value={value}
            onChange={handleChange}
            setSubmitDisabled={setSubmitDisabled}
          />
        </DateDialog>
      )}
    </>
  );
}
