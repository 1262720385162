import React from "react";
import styled from "styled-components";

import { Backdrop, BackdropProps, CircularProgress } from "@material-ui/core";

interface BackdropStyledProps {
  $borderRadius?: number;
}
const BackdropStyled = styled(Backdrop)<BackdropStyledProps>`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  cursor: default;
  border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}px` : "none")};
`;

interface CardBackdrop extends BackdropProps {
  borderRadius?: number;
}

const CardBackdrop: React.FC<CardBackdrop> = ({ open, borderRadius }) => {
  return (
    <BackdropStyled open={open} $borderRadius={borderRadius}>
      <CircularProgress color="primary" size={20} />
    </BackdropStyled>
  );
};

export default CardBackdrop;
