import styled, { css } from "styled-components";

import { Box, Button, CardMedia, CircularProgress, Paper, Typography } from "@material-ui/core";

export const Card = styled(Paper)`
  position: relative;
  border-radius: 16px;
  max-width: 780px;

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px 16px 16px;
`;

export const CardMainContent = styled.main`
  max-width: calc(100% - 300px);
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LinkSection = styled.section`
  margin-left: auto;
`;

export const TextInsideImage = styled(Typography)`
  font-size: 2rem;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.background.default};
`;

export const CardTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 12px;
`;
const SelectTextStyle = css`
  font-size: 14px;
  font-weight: 400;
`;

export const Text = styled(Typography)`
  ${SelectTextStyle}
  margin-left: 8px;
`;
export const SelectStyled = styled.div`
  .MuiListItemText-root .MuiTypography-root {
    ${SelectTextStyle}
  }
`;

interface CardImageContainerProps {
  $withoutBorder: boolean;
}
export const CardImageContainer = styled(Box)<CardImageContainerProps>`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: ${({ theme, $withoutBorder }) =>
    $withoutBorder ? "none" : `1px solid ${theme.palette.greyDesign.onSurface3}`};
`;

export const CardMediaStyled = styled(CardMedia)`
  width: 104px;
  height: 88px;
  background-color: ${({ theme }) => theme.palette.primary.medium};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardLabelList = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const CardLabel = styled(Typography)`
  font-size: 8px;
  line-height: 16px;
  color: #fff;
  background-color: #81cc35;
  text-transform: uppercase;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const ButtonStyled = styled(Button)`
  position: relative;
`;

export const ButtonLoader = styled(CircularProgress)`
  position: absolute;
`;
