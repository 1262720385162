import styled from "styled-components";

import { InputIconProps } from "./InputTypes";

import TextField from "@material-ui/core/TextField";

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
`;

export const InputIcon = styled.div<InputIconProps>`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.text.disabled : theme.palette.text.secondary};

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
`;

export const StyledInput = styled(TextField)<{
  $disableUnderline?: boolean;
  $nonEditable?: boolean;
}>`
  .MuiInput-underline {
    &::before,
    &::after {
      display: ${({ $disableUnderline }) => {
        return $disableUnderline ? "none" : "block";
      }};
    }
  }

  .MuiSvgIcon-root {
    width: 0.75rem;
    height: 0.75rem;
  }

  pointer-events: ${({ $nonEditable }) => ($nonEditable ? "none" : "auto")};
`;
