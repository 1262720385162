import React, { useCallback, useContext, useEffect } from "react";
import { useApolloClient, useLazyQuery } from "react-apollo";
import { FetchPolicy } from "apollo-client";

import { getActivitiesRelatedToProjectQuery } from "./getActivitiesRelatedToProject.query";

import useGetAccountId from "../../customHooks/useGetAccountId";

import ProjectTabActivityItem from "../../components/ProjectTabActivityItem/ProjectTabActivityItem";

import { LayoutType } from "../../_constant/constants";

import { Box, CircularProgress, useTheme } from "@material-ui/core";

import EmptyCustomFieldState from "../EmptyCustomFieldsState/EmptyCustomFieldState";
import ChangeOrderDragWrapper from "../ChangeOrderDragWrapper/ChangeOrderDragWrapper";
import { ProjectFormContext } from "../ProjectForm/ProjectFormContext";
import { updateActivitiesCache } from "./updateActivitiesCache";
import { ActivityRelatedToProjectModel } from "./ActivityRelatedToProjectModel";
import ProgressBarForActivities from "../ProgressBarForActivities/ProgressBarForActivities";

import useGetAcitvitiesStatuses from "../../customHooks/api/useGetAcitvitiesStatuses";
import Accordion from "../Accordion";
import styled from "styled-components";
import { ActivityItemWrapper } from "../../containers/projects/_components/relaitedActivities/ActivityItemWrapper";
import ActiveActivityCard from "../ActiveActivityCard/ActiveActivityCard";

interface ActivitiesTabProps {
  projectId: string | null | undefined;
  isLoadingForm: boolean;
  activitiesTabFetchPolicy: FetchPolicy;
  setCachePolicyForActivityTab: (policy: FetchPolicy) => void;
  isCustomize: boolean;
  removeActivityFromProject: (activityId: string) => void;
  removeActivityFromProjectWithDelete: (activityId: string) => void;
  isProjectStarted: boolean;
  isProjectFinished: boolean;
}

const AccordionStyled = styled(Accordion)`
  border-bottom: none;
`;

const ActivitiesTabProject: React.FC<ActivitiesTabProps> = ({
  projectId,
  isLoadingForm,
  activitiesTabFetchPolicy,
  setCachePolicyForActivityTab,
  isCustomize,
  removeActivityFromProject,
  removeActivityFromProjectWithDelete,
  isProjectStarted,
  isProjectFinished,
}) => {
  const theme = useTheme();
  const { isPersonal, accountId } = useGetAccountId();
  const client = useApolloClient();
  const { formApi } = useContext(ProjectFormContext);
  const statuses = useGetAcitvitiesStatuses();

  const [getActivities, { data, loading, error }] = useLazyQuery(
    getActivitiesRelatedToProjectQuery,
    { fetchPolicy: activitiesTabFetchPolicy },
  );

  const updateFormActivities = useCallback(
    (newActivities: ActivityRelatedToProjectModel[]) => {
      const newActivitiesIds = newActivities?.map((activity) => {
        return activity.Id;
      });

      formApi.handleChange(null, {
        id: "ActivityIds",
        value: newActivitiesIds,
      });
    },
    [formApi.handleChange, client],
  );

  const updateCacheActivities = useCallback(
    (newActivities: ActivityRelatedToProjectModel[]) => {
      updateActivitiesCache(client, newActivities, formApi.data.Id);
    },
    [formApi.data.Id],
  );

  useEffect(() => {
    if (data?.Project?.AssetActivities?.length && !loading)
      setCachePolicyForActivityTab("cache-first");
  }, [setCachePolicyForActivityTab, data, loading]);

  useEffect(() => {
    if (projectId) {
      getActivities({
        variables: {
          ProjectId: projectId,
        },
      });
    }
  }, [projectId]);

  const allActivitiesList: ActivityRelatedToProjectModel[] = data?.Project?.AssetActivities;

  const renderItem = useCallback(
    (activity: ActivityRelatedToProjectModel) => {
      return (
        <ProjectTabActivityItem
          key={activity?.Id}
          activity={activity}
          dateType={activity?.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
          layout={LayoutType.LIST}
          isAccountPersonal={isPersonal || false}
          isAssignmentsVisibile={false}
          customerId={null}
          withMoreAction
          withDragAndDropIcon={isCustomize || isProjectStarted || !isProjectFinished}
          projectId={projectId}
          removeActivity={removeActivityFromProject}
          isCustomize={isCustomize}
          removeActivityFromProjectWithDelete={removeActivityFromProjectWithDelete}
        />
      );
    },
    [
      projectId,
      isCustomize,
      isPersonal,
      removeActivityFromProject,
      removeActivityFromProjectWithDelete,
    ],
  );

  const renderSeparatedActivitiesList = (activities: ActivityRelatedToProjectModel[]) => {
    const doneStatus = statuses.find((status) => status.Name === "Done");
    const separatedLists = [
      {
        name: "Not Done",
        list: activities.filter((activity) => activity.StatusId !== doneStatus.Id),
      },
      {
        name: "Done",
        list: activities.filter((activity) => activity.StatusId === doneStatus.Id),
      },
    ];

    return separatedLists.map(({ name, list }) => {
      return (
        <Box
          overflow="hidden"
          borderRadius="8px"
          border={`1px solid ${theme.palette.greyDesign.onSurface3}`}
          marginTop={doneStatus.Name === name && "1rem"}
        >
          <AccordionStyled label={`${name} (${list.length})`} expanded={doneStatus.Name !== name}>
            {list.map((activity: ActivityRelatedToProjectModel) => {
              return (
                <ActivityItemWrapper
                  key={activity?.Id}
                  activityId={activity?.Id}
                  removeActivity={removeActivityFromProject}
                  isCustomize={false}
                  projectId={projectId}
                >
                  <ActiveActivityCard
                    key={activity?.Id}
                    activity={activity}
                    dateType={activity?.CompletedDateTime ? "CompletedDateTime" : "DueDateTime"}
                    layout={LayoutType.LIST}
                    isAccountPersonal={isPersonal || false}
                    isAssignmentsVisible={false}
                  />
                </ActivityItemWrapper>
              );
            })}
          </AccordionStyled>
        </Box>
      );
    });
  };

  const renderActivities = (activities: ActivityRelatedToProjectModel[]) => {
    return activities.map((activity: ActivityRelatedToProjectModel, index: number) => {
      if (isProjectFinished && !isCustomize) return renderItem(activity);
      return (
        <ChangeOrderDragWrapper
          key={activity.Id}
          activity={activity}
          activities={activities}
          isLoading={loading}
          updateCacheActivities={updateCacheActivities}
          updateFormActivities={updateFormActivities}
          renderItem={renderItem}
          index={index}
        />
      );
    });
  };

  if (loading || isLoadingForm) {
    return (
      <div style={{ height: "100%", flexGrow: 1, padding: "1rem", textAlign: "center" }}>
        <CircularProgress size={22} />
      </div>
    );
  }

  return (
    <Box>
      {allActivitiesList?.length ? (
        <Box padding="1rem" paddingTop="">
          {((isProjectStarted && !isCustomize) || isProjectFinished) && (
            <Box paddingBottom="1rem">
              <ProgressBarForActivities activities={allActivitiesList} />
            </Box>
          )}

          {isProjectStarted && !isCustomize ? (
            renderSeparatedActivitiesList(allActivitiesList)
          ) : (
            <Box overflow="hidden" borderRadius="8px" border={`1px solid ${theme.palette.greyDesign.onSurface3}`}>
              {renderActivities(allActivitiesList)}
            </Box>
          )}
        </Box>
      ) : (
        <Box padding="1rem" paddingTop="">
          <EmptyCustomFieldState
            emptyFieldsImageName={"project_activities_tab"}
            emptyFieldsMessage={`Switch on Planning to add \n activities to this project.`}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActivitiesTabProject;
