import { ProjectModel } from "../../redux/models/data/ProjectModel";
import { toastr } from "react-redux-toastr";
import { useMutation } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { PureQueryOptions } from "apollo-client";

import { getActivityFilters } from "../../redux/reducers/appReducer";
import {
  checkUploadResult,
  convertStatusToFilterVariable,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import { updateCacheWithEditProject } from "../../pages/EditProject/updateCacheWithEditProject";

import useGetProjectsStatuses from "../../customHooks/api/useGetProjectsStatuses";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useGetAcitvitiesStatuses from "../../customHooks/api/useGetAcitvitiesStatuses";

import { useEntityTabsContext } from "../EntityTabsContainer/useEntityTabsContext";

import { finishProjectMutation } from "../../pages/EditProject/finishProject.query";
import { updateProjectMutation } from "../../pages/EditProject/updateProject.query";
import { getActivitiesRelatedToProjectQuery } from "../ActivitiesTabProject/getActivitiesRelatedToProject.query";
import { GET_ACTIVITIES_LIST } from "../../containers/activities/all/activities-list.query";

import { DataProxy } from "apollo-cache";

import { FILE_UPLOAD_RESULTS, ID, PROJECT_PUT } from "../../_constant/wordings";
import * as statusesConfig from "../../../configs/statuses-config.json";
import { NotificationTypes } from "../../_constant/NotificationTypes";

const { IN_PROGRESS, FINISH } = statusesConfig["statusNameMap"];

interface ApiInterface {
  changeStatus: () => void;
  finishProject: (ShouldFinishActivities?: boolean) => void;
  isUpdating: boolean;
  isFinishing: boolean;
}

interface fnParams {
  project: ProjectModel;
  initFormData: ProjectModel;
  isProjectStarted: boolean;
  isFormDirty: boolean;
  handleOpenFinishProjectDialog: () => void;
  isFinishProjectDialogVisible: boolean;
  handleCloseFinishProjectDialog: () => void;
  updateInitFormData: (projectForm: ProjectModel) => void;
  handleCustomize: (isCustomize: boolean) => void;
}

type HandleQueryProjectStatusProps = (params: fnParams) => ApiInterface;

export const useHandleQueryProjectStatus: HandleQueryProjectStatusProps = ({
  project,
  isFinishProjectDialogVisible,
  handleOpenFinishProjectDialog,
  isProjectStarted,
  updateInitFormData,
  handleCustomize,
  initFormData,
  handleCloseFinishProjectDialog,
  isFormDirty,
}) => {
  const dispatch = useDispatch();

  const statuses = useGetProjectsStatuses();
  const activitiesStatuses = useGetAcitvitiesStatuses();

  const { accountId } = useGetAccountId();
  const { changeTab } = useEntityTabsContext();

  const filterMap = useSelector((state) => getActivityFilters(state));

  const [updateProject, { loading: isUpdating }] = useMutation(updateProjectMutation);
  const [finishProjectQuery, { loading: isFinishing, client }] = useMutation(finishProjectMutation);

  const inProgressStatusId = statuses?.find(
    (status: { Name: string }) => status.Name === IN_PROGRESS,
  )?.Id;

  const getFilterStrings = (newStatusId: string, oldStatusId: string) => {
    const newStatus = statuses.find((element: { Id: any }) => element.Id === newStatusId);

    const oldStatus = statuses.find(
      (element: { Id: string | null | undefined }) => element.Id === oldStatusId,
    );

    const filterStringForNewProject = convertStatusToFilterVariable(
      newStatus,
      filterMap,
      "ProjectStatusId",
    );

    const filterStringForOldProject = convertStatusToFilterVariable(
      oldStatus,
      filterMap,
      "ProjectStatusId",
    );
    return { filterStringForNewProject, filterStringForOldProject };
  };

  const changeStatus = () => {
    if (!accountId) {
      return;
    }
    const { Files } = project;

    const imagesWithAppropriateTypename = project.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = project.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = project.Videos.map((video) => {
      delete video.__typename;
      return video;
    });

    const schemaWithAppropriateTypename = project.Schema.map((schema) => {
      delete schema.__typename;
      return schema;
    });

    const ProjectStatusId = !isProjectStarted ? inProgressStatusId : project.ProjectStatusId;

    const dataForPut: any = { ...project, ProjectStatusId, AccountId: accountId };
    delete dataForPut.Files;

    const variables = {
      Project: {
        ...dataForPut,
        Images: imagesWithAppropriateTypename,
        Documents: documentsWithAppropriateTypename,
        Videos: videosWithAppropriateTypename,
        Schema: schemaWithAppropriateTypename,
      },
      Files,
    };

    if (isProjectStarted && !isFormDirty) {
      handleOpenFinishProjectDialog();
      return;
    }

    updateProject({
      variables: variables,
      update(cache: DataProxy, { data: { ProjectPut } }) {
        if (!initFormData?.ProjectStatusId) {
          return;
        }
        const filterStrings = getFilterStrings(ProjectStatusId, initFormData?.ProjectStatusId);

        updateCacheWithEditProject(
          cache,
          ProjectPut,
          filterStrings.filterStringForNewProject,
          filterStrings.filterStringForOldProject,
        );
      },
    })
      .then(({ data }) => {
        if (project.ProjectStatusId === inProgressStatusId && !isFinishProjectDialogVisible) {
          handleOpenFinishProjectDialog();
        }

        const uploadResult = data[PROJECT_PUT]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        const projectForm = new ProjectModel(data[PROJECT_PUT]);
        updateInitFormData(projectForm);

        changeTab("0");
        handleCustomize(false);

        if (data[PROJECT_PUT][ID]) {
          dispatch(setEditFormIsDirty(false));
          displaySuccessNotification(NotificationTypes.PROJECT_STARTED);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_STARTED_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error("", message);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_UPDATED_ERROR);
        }
      });
  };

  const finishProject = (ShouldFinishActivities?: boolean) => {
    if (inProgressStatusId !== project.ProjectStatusId) return;

    let refetchQueries: PureQueryOptions[] = [];
    if (project.ActivityIds.length && activitiesStatuses.length) {
      refetchQueries = activitiesStatuses.map((element) => ({
        query: GET_ACTIVITIES_LIST,
        variables: { Id: accountId, Filter: convertStatusToFilterVariable(element, filterMap) },
      }));
      try {
        client?.cache.readQuery(refetchQueries[0]);
      } catch (e) {
        refetchQueries = [];
      }
      refetchQueries = [
        ...refetchQueries,
        {
          query: getActivitiesRelatedToProjectQuery,
          variables: {
            ProjectId: project.Id,
          },
        },
      ];
    }

    finishProjectQuery({
      variables: { ProjectId: project.Id, ShouldFinishActivities },
      refetchQueries: ShouldFinishActivities ? refetchQueries : undefined,
      update(cache: DataProxy, { data: { ProjectFinish } }) {
        if (!initFormData?.ProjectStatusId) {
          return;
        }

        const ProjectStatusId = statuses.find(
          (element: { Name: string }) => element.Name === FINISH,
        ).Id;

        const filterStrings = getFilterStrings(ProjectStatusId, initFormData.ProjectStatusId);

        updateCacheWithEditProject(
          cache,
          ProjectFinish,
          filterStrings.filterStringForNewProject,
          filterStrings.filterStringForOldProject,
        );
      },
    })
      .then(({ data: { ProjectFinish } }) => {
        const project = new ProjectModel(ProjectFinish);
        updateInitFormData(project);
        handleCustomize(false);
        changeTab("0");

        if (ProjectFinish[ID]) {
          dispatch(setEditFormIsDirty(false));
          displaySuccessNotification(NotificationTypes.PROJECT_FINISHED);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_FINISHED_ERROR);
        }

        handleCloseFinishProjectDialog();
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error("", message);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_FINISHED_ERROR);
        }
      });
  };

  return { changeStatus, finishProject, isUpdating, isFinishing };
};
