import gql from "graphql-tag";

export const startVisitMutation = gql`
  mutation startVisit($Visit: VisitInput!, $Files: [Upload]) {
    VisitStart(Visit: $Visit, Files: $Files) {
      Id
      CustomerId
      VisitStatusId
      CustomerName @client
      VisitStatusName @client
      AssignedUserId
      Purpose
      Notes
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      StartedVisitActivities {
        Id
        Name
        StatusId
        Description
        DueDateTime
        CompletedDateTime
        RelatedDueDateTime
        Schedule {
          RecurringType
        }
        Asset {
          Id
          Name
        }
      }
    }
  }
`;

export const finishVisitMutation = gql`
  mutation finishVisit($Visit: VisitInput!, $Files: [Upload]) {
    VisitFinish(Visit: $Visit, Files: $Files) {
      Id
      CustomerId
      VisitStatusId
      CustomerName @client
      VisitStatusName @client
      AssignedUserId
      Purpose
      Notes
      DueDateTime
      CompletedDateTime
      RelatedDueDateTime
      Schedule {
        AppearanceCount
        DayOfWeek
        EndDate
        RecurrenceCount
        RecurringRepeatType
        RecurringType
        RelatedDayOfWeeks
        StartDate
        WeekInMonth
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
      Documents {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      Videos {
        Id
        Name
        ContainerId
        ContainerName
        FileExtension
        AbsoluteUri
      }
      FinishedVisitActivities {
        Id
        Name
        StatusId
        Description
        DueDateTime
        CompletedDateTime
        RelatedDueDateTime
        Schedule {
          RecurringType
        }
        Asset {
          Id
          Name
        }
      }
    }
  }
`;
