import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";

import { ItemTypeField } from "../redux/models/data/AssetTypeModel";

const ASSET_TYPE_FIELDS = `
  Id
  Name
  DescriptionPattern
  ItemTypeFields {
    Name
    FieldType
    AllowedValues
    Order
    IsCustomValueAllowed
  }
`;

const getAssetTypeInfo = gql`
  query {
    SystemData {
      AssetTypes {
        ${ASSET_TYPE_FIELDS}
      }
    }
  }
`;

const getAssetTypeDetailsFragment = gql`
  fragment AssetTypeDetails on AssetType {
    ${ASSET_TYPE_FIELDS}
  }
`;

const useGetAssetTypesDescriptionPattern = (onlyCache?: boolean) => {
  const response = useQuery(getAssetTypeInfo, {
    fetchPolicy: onlyCache ? "cache-only" : "cache-first",
  });

  return response;
};

interface getDetailsByIdReturn {
  pattern: string[];
  itemTypeField: ItemTypeField[];
}
interface getTypeInfoRerurnValue {
  getDetailsById: (id: string) => getDetailsByIdReturn;
}

export const useGetAssetTypeDetails = (props = { onlyCache: false }): getTypeInfoRerurnValue => {
  const onlyCache = props?.onlyCache;
  const { client } = useGetAssetTypesDescriptionPattern(onlyCache);

  const getDetailsById = (id: string): getDetailsByIdReturn => {
    let assetDetails = null;
    try {
      assetDetails = client.readFragment({ fragment: getAssetTypeDetailsFragment, id });
    } catch (error) {
      return {
        pattern: [],
        itemTypeField: [],
      };
    }

    const pattern = assetDetails?.DescriptionPattern
      ? assetDetails.DescriptionPattern
      : ([] as string[]);
    const itemTypeField = assetDetails?.ItemTypeFields
      ? assetDetails.ItemTypeFields
      : ([] as ItemTypeField[]);

    return {
      pattern,
      itemTypeField,
    };
  };

  return { getDetailsById };
};

export default useGetAssetTypesDescriptionPattern;
