import React from "react";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Button, { ButtonProps } from "@material-ui/core/Button/Button";

export interface CustomizeButtonProps {
  onClick: any;
  color: ButtonProps["color"];
  disabled: boolean;
  withStartIcon: boolean;
}
const CustomizeActivityButton: React.FC<CustomizeButtonProps> = ({
  onClick,
  color = "primary",
  disabled = false,
  withStartIcon,
  children,
}) => {
  return (
    <Button
      onClick={onClick}
      color={color}
      startIcon={
        withStartIcon && (
          <AddOutlinedIcon color={disabled ? "disabled" : "primary"} fontSize="small" />
        )
      }
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default CustomizeActivityButton;
