import styled from "styled-components";
import { LayoutType } from "../../_constant/constants";
import { checkIfBoardView } from "../../_utils/utils";
import { scrollStyles } from "../_styled/scrollStyles";
import Container from "@material-ui/core/Container";

export const AssetsListStyled = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  min-height: 300px;

  @media screen and (max-width: 600px) {
    height: 90%;
    margin-top: auto;
  }

  ${scrollStyles};
`;

interface AssetsListContainerProps {
  $layout: LayoutType;
}

export const AssetsListContainer = styled.div<AssetsListContainerProps>`
  display: flex;
  flex-direction: ${({ $layout }) => (checkIfBoardView($layout) ? "row" : "column")};
  flex-wrap: wrap;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: ${({ theme, $layout }) =>
    $layout !== LayoutType.LIST ? theme.shadows[0] : theme.shadows[3]};

  max-width: ${({ $layout }) => ($layout !== LayoutType.PRINTABLE ? "auto" : "790px")};
  padding: ${({ $layout }) => ($layout !== LayoutType.PRINTABLE ? "0" : "0 4px 6px")};
  margin: ${({ $layout }) => ($layout !== LayoutType.PRINTABLE ? "0 0 1rem 0" : "0 auto 1rem")};
  @media screen and (max-width: 549px) {
    justify-content: center;
  }

  @media print {
    display: block;
  }
`;

export const AssetListWrapper = styled(Container)`
  @media screen and (max-width: 549px) {
    padding: 0 10px;
  }

  @media print {
    padding: 0;

    @page {
      margin: 0 auto;
      padding: 0;
      size: auto;
    }
  }
`;
