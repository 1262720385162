import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";

const InputField = ({
  value,
  handleChange,
  label,
  id,
  type = "text",
  fullWidth = false,
  icon,
  maxLength = "30",
  errorMessage = null,
  multiline = false,
  autoComplete = "off",
  alignIcon = "flex-end",
  onClickForSelect = () => {},
  isLoading = false,
  withoutClearBtn = false,
  placeholder = null,
  ...otherProps
}: any) => {
  const clearAdornment =
    value && !otherProps.select && !withoutClearBtn
      ? {
          endAdornment: (
            <IconButton
              className="InputField__clear-icon"
              size="small"
              onClick={() => {
                handleChange({ target: { id: id, value: "" } });
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }
      : {};

  return (
    <Grid
      className={`InputField ${multiline ? "InputField_multiline" : ""}`}
      alignItems={alignIcon}
      container
      spacing={2}
    >
      {icon && (
        <Grid
          className={`InputField__icon ${
            alignIcon === "center" ? "InputField__icon_withPadding" : ""
          }`}
          item
        >
          {icon}
        </Grid>
      )}
      <Grid item xs>
        <TextField
          id={id}
          label={label}
          value={value || ""}
          onChange={handleChange}
          fullWidth={fullWidth}
          multiline={multiline}
          InputProps={clearAdornment}
          inputProps={
            type === "number"
              ? {
                  inputMode: "decimal",
                  pattern: "[0-9]+([\\.,][0-9]+)?",
                  type: "text",
                }
              : { maxLength, type }
          }
          InputLabelProps={
            placeholder
              ? {
                  shrink: true,
                }
              : null
          }
          autoComplete={autoComplete}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,

              PaperProps: {
                className: "InputField",
              },
              MenuListProps: {
                disablePadding: true,
              },
            },
            onClick: onClickForSelect,
            disabled: isLoading || otherProps?.disabled,
            endAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : null,
          }}
          helperText={errorMessage}
          placeholder={placeholder}
          {...otherProps}
        />
      </Grid>
    </Grid>
  );
};

export default InputField;
