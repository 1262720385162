import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@material-ui/icons";

import { VendorControlsLayoutProps } from "../VendorList.types";

import { VendorSelectContainer } from "../../VendorSelectContainer/VendorSelectContainer";

import { AddNewVendorContainer, StyledContainer } from "../VendorsList.style";

import { ControlVendorButton } from "../../VendorSelectContainer/VendorSelectContainer.style";

export const VendorControlsLayout: React.FC<VendorControlsLayoutProps> = ({
  vendorRefs,
  vendorId,
  allVendorsOptions = [],
  setActivity,
  inputType = "",
  buttonType,
  children,
  transparentBg,
}) => {
  const [vendorToAttach, setVendorToAttach] = useState({ value: "", label: "" });

  const attachVendor = () => {
    setActivity(null, { id: "VendorRefs", value: [...vendorRefs, { Id: vendorToAttach.value }] });
    setVendorToAttach({ value: "", label: "" });
  };
  const removeVendor = () => {
    const filteredVendorRefs = vendorRefs.filter(({ Id }) => Id !== vendorId);

    setActivity(null, { id: "VendorRefs", value: filteredVendorRefs });
  };

  const renderInputField = () => {
    switch (inputType) {
      case "combobox":
        return (
          <VendorSelectContainer
            setVendorToAttach={setVendorToAttach}
            vendorToAttach={vendorToAttach}
            allVendorsOptions={allVendorsOptions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <AddNewVendorContainer transparentBg={transparentBg}>
      <StyledContainer>
        <Box display="flex" alignItems="center">
          {children ? children : renderInputField()}
          <ControlVendorButton
            color="primary"
            disabled={buttonType === "add" ? !Boolean(vendorToAttach.value.length) : false}
            onClick={buttonType === "add" ? attachVendor : removeVendor}
          >
            {buttonType === "add" ? <AddCircleOutlineOutlined /> : <RemoveCircleOutlineOutlined />}
          </ControlVendorButton>
        </Box>
      </StyledContainer>
    </AddNewVendorContainer>
  );
};
