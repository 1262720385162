import React from "react";
import { useLocation } from "react-router-dom";

import { ROUTES } from "../../_constant/screens";

import { Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import Search from "../Search";

import { StyledSearch, StyledTitle, StyledContainer } from "./ActionsBarStyle";

import "./ActionsBar.scss";

interface ActionsBarProps {
  title?: React.ReactNode;
  withSearch?: boolean;
  additionalInfo?: React.ReactNode;
  buttonContainer?: React.FC;
}

const ActionsBar: React.FC<ActionsBarProps> = (props) => {
  const { children, title, withSearch, additionalInfo, buttonContainer } = props;
  // TODO: use additional info, ButtonContainer props
  const theme = useTheme();
  const location = useLocation();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const isItemPageLocation = location.pathname === ROUTES.ASSETS;

  const renderTitle = () => {
    if (isItemPageLocation && !isDesktop) {
      return null;
    }
    return (
      <Grid item>
        <StyledTitle>{title}</StyledTitle>
      </Grid>
    );
  };

  return (
    <StyledContainer>
      <Grid container spacing={2} alignItems="center">
        {renderTitle()}
        <Grid item xs>
          {withSearch ? (
            <Hidden xsDown>
              <StyledSearch>
                <Search />
              </StyledSearch>
            </Hidden>
          ) : null}
        </Grid>
        {children && (
          <Grid item xs={!isDesktop && isItemPageLocation && 12}>
            {children}
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default ActionsBar;
