import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import EssentialActivity from "./form/EssentialActivity";
import { ActivityModel } from "../../../redux/models/data/ActivityModelFull";
import DeleteActivityPopup from "./form/DeleteActivityPopup";
import { NAME } from "../../../_constant/wordings";
import { VendorsList } from "../../../components/VendorsList/VendorsList";
import { displayWarningNotification } from "../../../redux/actions/_utils";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { useChange } from "../../../customHooks/useChange";
import EntityTabsContainer from "../../../components/EntityTabsContainer/EntityTabsContainer";
import AffiliateCard from "../../../components/AffiliatesCard";
import ItemCustomFieldsContainer from "../../../components/ItemCustomFields/ItemCustomFieldsContainer";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../assets/existing/EditAsset";
import SaveButton from "../../../components/SaveButton/SaveButton";
import { LeaveDialogViaRedirect, RevertDialogComponent } from "../../_common/Dialog";
import MobileDialogSuccessLink from "../../../components/MobileDialogSuccessLink/MobileDialogSuccessLink";
import { validateActivityNameField, validateAssetField } from "../../../_utils/utils";
import Body from "../../../components/Body/Body";
import Form from "../../../components/Form/Form";
import ActionsBar from "../../../components/ActionsBar/ActionsBar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../_constant/screens";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import { Image } from "../../../redux/models/data/ImageModel";
import EntityTabsProvider from "../../../components/EntityTabsContainer/EntityTabsProvider";
import Collapse from "@material-ui/core/Collapse";
import FormPageTitle from "../../../components/FormPageTitle/FormPageTitle";
import CustomerInfo from "../../../components/CustomerInfo/CustomerInfo";
import useGetCustomerIdFromUrl from "../../../customHooks/useGetCustomerId";
import { ITabContent } from "../../../components/CustomerForm/CustomerFormDesktop";
import CustomizeToggleButton from "../../../components/CustomizeToggleButton/CustomizeToggleButton";
import { useDispatch, useSelector } from "react-redux";
import { setEditFormIsDirty } from "../../../redux/actions/actions";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DropZoneContainer from "../../../components/DropZoneContainer/DropZoneContainer";
import { useGetLimits } from "../../../customHooks/useGetLimits";
import { ActionBarButtonContainer, GetCalendarButtonStyled } from "./form/ActivityForm.style";
import useGetCalendarDate from "../../../customHooks/useGetCalendarDate";
import useDialogSuccessLinkState from "../../../components/MobileDialogSuccessLink/useDialogSuccessLinkState";

interface ActivityFormProps {
  activityId?: string;
  activityData: ActivityModel;
  isActivitySaving: boolean;
  isActivityLoading?: boolean;
  isNew: boolean;
  // showOnLeavePopup: boolean;
  handleSubmit: (data: ActivityModel, saveType: SaveType) => void;
  // setDirty: (isDirty: boolean) => void;
  setHeaderOptions: (props: any) => void;
}

const ActivityForm: React.FC<ActivityFormProps> = (props) => {
  const formInitObject = {
    initData: new ActivityModel(),
    classModel: ActivityModel,
    requiredFields: ["AssetId", NAME],
    fieldsForValidation: new Map([
      ["AssetId", validateAssetField],
      [NAME, validateActivityNameField],
    ]),
  }; // if null received instead of function - useChange used default one "validateNameField"
  const formApi = useChange<ActivityModel>(formInitObject);
  const customerId = useGetCustomerIdFromUrl();
  const limits = useGetLimits();
  const { getDateFile } = useGetCalendarDate();
  const { isOpen, onClose, onOpen, url } = useDialogSuccessLinkState();

  // formApi.handleChange(null, { id: "Refs", value: [] });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isCustomize, setIsCustomize] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const { activityData, setHeaderOptions } = props;

  const openRevertDialog = useCallback(() => setIsRevertPopupVisible(true), []);

  useEffect(() => {
    if (activityData && !isActivityLoading) {
      formApi.updateInitFormData(new ActivityModel(activityData));
    }
  }, [activityData]);
  // useEffect(() => {
  //   props.setDirty(formApi.changedFields.size > 0);
  // }, [formApi.changedFields]);

  const { isActivitySaving, isActivityLoading } = props;
  const lockSaveButton = formApi.isDataChanged && formApi.isDataValid;
  const isNew = !props.activityId;

  const handleSubmit = (e?, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: SaveType = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: () => formApi.updateInitFormData(formApi.data),
    };

    props.handleSubmit(formApi.data, saveType);
  };

  const revertData = () => {
    formApi.revertData();
    setIsRevertPopupVisible(false);
  };

  const handleCustomize = () => {
    setIsCustomize((prev) => !prev);
  };

  const isLoading = isActivityLoading || isActivitySaving;

  useEffect(() => {
    dispatch(setEditFormIsDirty(formApi.isDataChanged));
  }, [formApi.isDataChanged]);

  useEffect(() => {
    setHeaderOptions({
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
        revertFunction: openRevertDialog,
        getDate: () =>
          getDateFile({
            activityId: formApi.data.Id,
            assetId: formApi.data.AssetId,
            onOpen,
          }),
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, isLoading, lockSaveButton]);

  const handleCancel = () => {
    navigate(-1);
  };

  const activitiesTabs: ITabContent[] = [
    {
      name: "Suggestions",
      disabled: isNew,
      content: (
        <div className="suggestions__container">
          {activityData?.Affiliates?.map((item) => {
            return <AffiliateCard affiliate={item} key={item.Url} />;
          })}
        </div>
      ),
      hasCustomizeMode: false,
      tabControl: null,
    },
    {
      name: "Activity Info",
      disabled: isNew,
      content: (
        <ItemCustomFieldsContainer
          CustomFields={formApi.data.CustomFields}
          Schema={formApi.data.Schema}
          onChange={formApi.handleChange}
          isCustomize={isCustomize}
          entityName="Activity"
          isLoading={isLoading}
        />
      ),
      hasCustomizeMode: true,
      tabControl: (
        <CustomizeToggleButton disabled={isNew} checked={isCustomize} onClick={handleCustomize} />
      ),
    },
    {
      name: "Vendors",
      disabled: isNew,
      content: (
        <VendorsList
          vendorRefs={formApi.data.VendorRefs}
          setActivity={formApi.handleChange}
          isCustomize={isCustomize}
        />
      ),
      hasCustomizeMode: true,
      tabControl: (
        <CustomizeToggleButton disabled={isNew} checked={isCustomize} onClick={handleCustomize} />
      ),
    },
    {
      name: "Manage",
      disabled: isNew,
      content: (
        <div style={{ height: "100%", flexGrow: 1, padding: "1rem" }}>
          <DeleteButton
            loading={isLoading || isDeletePopupVisible}
            disabled={!activityData.Id || formApi.isDataChanged}
            onClick={() => setIsDeletePopupVisible(true)}
          >
            Delete Activity
          </DeleteButton>
        </div>
      ),
      hasCustomizeMode: false,
      tabControl: null,
    },
  ];

  return (
    <>
      <DeleteActivityPopup
        activity={activityData}
        isDeletePopupVisible={isDeletePopupVisible}
        handleCancel={() => setIsDeletePopupVisible(false)}
        customerId={customerId}
      />
      {isRevertPopupVisible && (
        <RevertDialogComponent submit={revertData} cancel={() => setIsRevertPopupVisible(false)} />
      )}

      <Body disableDesktopScroll disableFilterPadding>
        <Form>
          <Form header>
            <ActionsBar
              title={
                <FormPageTitle
                  isNew={isNew}
                  isCustomize={isCustomize}
                  entity="Activity"
                  name={formApi.data.Name}
                />
              }
              additionalInfo={<CustomerInfo customerId={customerId} />}
            >
              <ActionBarButtonContainer>
                {!isNew && (
                  <GetCalendarButtonStyled
                    activityId={formApi.data.Id}
                    assetId={formApi.data.AssetId}
                    disabled={isActivitySaving || isActivityLoading}
                  />
                )}
                <SaveButton
                  saveAndClose={handleSubmit}
                  isLoading={isLoading}
                  revertData={openRevertDialog}
                  isSaveAvailable={lockSaveButton}
                  isNew={isNew}
                  cancelRouteForEmptyHistory={ROUTES.ACTIVITIES}
                  isDataChanged={formApi.isDataChanged}
                />
              </ActionBarButtonContainer>
            </ActionsBar>
          </Form>
          <Form body>
            <Form content>
              <Collapse in={!isCustomize} timeout="auto" unmountOnExit>
                <EssentialActivity
                  activityData={formApi.data}
                  handleFieldChange={formApi.handleChange}
                  errorFields={formApi.errorFields}
                />
              </Collapse>
              <EntityTabsProvider>
                <EntityTabsContainer
                  startFrom={isNew ? "1" : "0"}
                  content={
                    isCustomize
                      ? activitiesTabs.filter((el) => el.hasCustomizeMode === isCustomize)
                      : activitiesTabs
                  }
                  isCustomize={isCustomize}
                  changeCustomize={handleCustomize}
                  isNew={isNew}
                  entityName={"activity"}
                  tabIndexAfterCreate="0"
                  renderSelect={isMobile}
                />
              </EntityTabsProvider>
            </Form>
            <Form images>
              <DropZoneContainer
                data={formApi.data}
                handleChange={formApi.handleChange}
                updateManyFields={formApi.updateManyFields}
                entityName="activity"
                isMobile={isMobile}
                imagesOnly={false}
                amountOfMainFiles={3}
                limitDocuments={limits?.activity.documents.Value}
                limitImages={limits?.activity.images.Value}
                limitVideos={limits?.activity.videos.Value}
              />
            </Form>
          </Form>
        </Form>
      </Body>
      <MobileDialogSuccessLink
        url={url}
        isOpen={isOpen}
        onClose={onClose}
        title="Add Activity to Calendar"
        contentText="Download and open the file to add this activity to your calendar app."
      />
    </>
  );
};

export default ActivityForm;
