import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { getVisitsByStatusQuery } from "../VisitsByStatuses/visitsByStatus.query";
import { useSelector } from "react-redux";
import { getActivityFilters } from "../../redux/reducers/appReducer";
import { convertStatusToFilterVariable } from "../../redux/actions/_utils";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { ACCOUNT, VISITS } from "../../_constant/wordings";
import { VisitModel } from "../../redux/models/data/VisitModel";
import VisitCard from "../VisitCard/VisitCard";
import { SORTING_DETAILS } from "../../_constant/ActivityDateConstant";
import Accordion from "../Accordion";
import { sortActivities, statusClassName } from "../../_utils/utils";

interface VisitStatusContentProps {
  customerId: string;
  status: any;
  expanded: boolean;
}

const VisitStatusContent: React.FC<VisitStatusContentProps> = ({
  customerId,
  status,
  expanded,
}) => {
  const [visits, setVisits] = useState<any[]>([]);
  const { accountId } = useGetAccountId();

  const filterMap = useSelector(
    (state) => getActivityFilters(state),
    (map1, map2) => {
      if (!map1.has(status?.Id)) {
        return true;
      }
      if (map1.get(status?.Id) === map2.get(status?.Id)) {
        return true;
      }
      return false;
    },
  );
  const requestFilterString = convertStatusToFilterVariable(status, filterMap, "VisitStatusId");
  const { data, loading, error } = useQuery(getVisitsByStatusQuery, {
    variables: {
      Id: accountId,
      Filter: requestFilterString,
    },
    skip: !accountId || !requestFilterString || !customerId,
  });

  useEffect(() => {
    if (data) {
      const filteredVisits = data[ACCOUNT][VISITS].filter((visit: VisitModel) => {
        return visit.CustomerId === customerId;
      });

      const sortedVisits = sortActivities(status.Name, filteredVisits);

      setVisits(sortedVisits);
    }
  }, [data, customerId]);

  const labelCounter = expanded && visits.length > 0 && (
    <span className="Label">({visits.length})</span>
  );

  return (
    <Accordion
      label={
        <>
          {status.Name.toUpperCase()} {labelCounter}
        </>
      }
      className="RelatedActivities__Accordion"
      expanded={expanded}
      isLoading={loading}
    >
      <div className={`RelatedActivities_list ${statusClassName(status.Name)}`}>
        {visits.map((visit: VisitModel) => {
          return (
            <VisitCard
              key={visit.Id}
              visit={visit}
              dateType={SORTING_DETAILS.get(status.Name)["field"]}
              status={status.Name}
              isAccountPersonal={false}
              isAssignmentsVisible={false}
            />
          );
        })}
      </div>
    </Accordion>
  );
};

export default VisitStatusContent;
