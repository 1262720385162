import styled from "styled-components";

import Button from "@material-ui/core/Button";

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    align-items: center;
  }
`;

export const StyledInnerButtonContainer = styled.div`
  margin-top: 1.5rem;

  @media screen and (max-width: 425px) {
    margin-top: 0;
  }
`;

export const StyledResendButton = styled(Button)`
  width: 15rem;

  @media screen and (max-width: 425px) {
    order: 3;
    margin-top: 10px;
  }
`;

export const SecondaryButtons = styled(Button)`
  width: 7rem;

  &.MuiButton-root + &.MuiButton-root {
    margin-left: 1rem;
  }
`;

export const ImageContainer = styled.div`
  flex: 1 0 55%;

  background-size: contain;
  background-repeat: no-repeat;
  z-index: -100;
  background-position: bottom left;
  background-image: var(--verify-email);

  @media screen and (max-width: 1280px) {
    order: 2;
    overflow-y: hidden;
    flex-grow: 1;
  }
`;
