import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { getNotificationsQuery } from "./Notifications.query";

export const updateCacheWithNewNotifications = (
  cache: DataProxy,
  newNotification: any,
  userId: string,
) => {
  try {
    const notificationListQuery: any = cache.readQuery({
      query: getNotificationsQuery,
      variables: {
        UserId: userId,
      },
    });
    notificationListQuery.AccountNotificationSettingModel.push(
      newNotification.NotificationSettingsPost[0],
    );
    cache.writeQuery({
      query: getNotificationsQuery,
      data: notificationListQuery,
      variables: {
        UserId: userId,
      },
    });
  } catch (e) {
    console.error(e);
  }
};
