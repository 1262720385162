import * as React from "react";
import commonConfig from "../../configs/common-config.json";

interface InputProps {
  name?: string;
  label?: string;
  value?: string | number;
  className?: string;
  type?: string;
  errorMessage?: string;
  isRequired?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  hasError?: boolean;
  placeholder?: string;
  maxLength?: number;
  onChange?: (e?: any) => void;
  onClick?: (e?: any) => void;
  onClear?: (e?: any) => void;
  onBlur?: (e?: any) => void;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    name = "",
    value,
    type = "text",
    hasError = false,
    disabled = false,
    readonly = false,
    maxLength = commonConfig.maxInputLength,
  } = props;

  const onInputClear = () => {
    props.onChange({ target: { id: name, value: "" } });
  };

  const isRequired = props.isRequired && !hasError && !value;
  const displayCleanIcon = !!value && !disabled && !readonly;
  return (
    <div className={`Input ${props.className || ""}`}>
      <input
        className={`Input_field${hasError ? " has-error" : ""}${
          value ? " has-value" : ""
        }${readonly ? " Input_readonly" : ""}`}
        type={type}
        id={name}
        value={value}
        required
        onChange={props.onChange}
        onClick={props.onClick}
        autoComplete={"off"}
        disabled={disabled}
        readOnly={readonly}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        maxLength={maxLength}
      />
      {displayCleanIcon && (
        <div className={"ClearIcon"} onClick={props.onClear || onInputClear}>
          <i className="Icon Icon-clear" />
        </div>
      )}
      <span className="Bar" />
      <label className="Input_label" htmlFor={name}>
        {props.label}
      </label>
      {hasError && (
        <div className="Input_errorLabel">
          {props.errorMessage || "Required field"}
        </div>
      )}
      {isRequired && (
        <div className="Input_requiredLabel">* Required field</div>
      )}
    </div>
  );
};

export default Input;
