import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import { useLeaveDialogContext } from "../useLeaveDialogContext";
import { useGetUserSubscriptionTier } from "../../../customHooks/useGetUserSubscriptionTier";

import { AppBar, Grid, Hidden, IconButton, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { ROUTES } from "../../../_constant/screens";

import MobileHeaderContainer from "../../../components/MobileHeaderContainer/MobileHeaderContainer";
import HeaderAccount from "./HeaderAccount";
import Navigation from "../../../components/Navigation/Navigation";
import Logo from "../../../components/Logo";

import { HeaderContainer } from "./Header.style";

interface LocationState {
  firstLoad?: boolean;
}

interface HeaderProps {
  withNavigation: boolean;
}

const Header: React.FC<HeaderProps> = ({ withNavigation }) => {
  const navigate = useNavigate();
  const location = useLocation<LocationState>();

  const theme = useTheme();

  const { IsCustomerManagement } = useGetAccountId();
  const { headerOptions } = useHeaderOptions();
  const { isFormDirty, withoutMenuButton } = headerOptions;
  useGetUserSubscriptionTier();

  const { showDialogBeforeChangeUrl } = useLeaveDialogContext();

  const handleGoBack = () => {
    if (location.state?.firstLoad) {
      IsCustomerManagement ? navigate(ROUTES.VISITS) : navigate(ROUTES.ACTIVITIES);
    } else {
      isFormDirty ? showDialogBeforeChangeUrl(() => navigate(-1)) : navigate(-1);
    }
  };

  return (
    <HeaderContainer>
      <Hidden xsDown>
        <AppBar elevation={0} className="Header__appBar">
          <div className="Header">
            <Grid container wrap="nowrap">
              <Grid item xs={4} zeroMinWidth>
                <Box display="flex">
                  {!withoutMenuButton && (
                    <Box flexShrink={0}>
                      <div className="Header__buttonSection">
                        <IconButton onClick={handleGoBack} className="">
                          {isFormDirty ? <CloseOutlinedIcon /> : <ArrowBackOutlinedIcon />}
                        </IconButton>
                      </div>
                    </Box>
                  )}
                  <Box flexShrink={0}>
                    <Logo className="Header__logo" />
                  </Box>
                  {IsCustomerManagement && (
                    <Typography
                      noWrap
                      style={{
                        fontSize: "10px",
                        margin: "8px 0",
                        lineHeight: "16px",
                        alignSelf: "flex-end",
                        letterSpacing: "0.8 px",
                        textTransform: "uppercase",
                        color: `${theme.palette.text.secondary}`,
                      }}
                    >
                      Customer Management
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item container xs={4} justify="center">
                {withNavigation && <Navigation />}
              </Grid>
              <Grid item container xs={4} justify="flex-end">
                <Grid item zeroMinWidth>
                  {withNavigation && (
                    <div className="Header_account">
                      <HeaderAccount />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </AppBar>
      </Hidden>
      <Hidden smUp>
        <MobileHeaderContainer />
      </Hidden>
    </HeaderContainer>
  );
};

export default Header;
