import { getActivitiesRelatedToProjectQuery } from "../ActivitiesTabProject/getActivitiesRelatedToProject.query";

import { DataProxy } from "apollo-cache/lib/types/DataProxy";

export const removeActivityFromProjectTabCache = (
  cache: DataProxy,
  activityId: string | null | undefined,
  projectId: string | null | undefined,
) => {
  const oldActivities: any = cache.readQuery({
    query: getActivitiesRelatedToProjectQuery,
    variables: { ProjectId: projectId },
  });

  const newActivities = {
    Project: {
      Id: projectId,
      __typename: "ProjectType",
      AssetActivities: oldActivities.Project.AssetActivities.filter(
        (activity: { Id: string }) => activity.Id !== activityId,
      ),
    },
  };

  cache.writeQuery({
    query: getActivitiesRelatedToProjectQuery,
    variables: { ProjectId: projectId },
    data: newActivities,
  });
};
