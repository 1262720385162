import * as React from "react";
import { IconButton } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import EditCustomFieldLayout from "./EditCustomFieldLayout";
import { ChangedFields } from "../CustomFieldsList/CustomFieldsListCustomize";

interface EditCustomField {
  Name: string;
  FieldType: string;
  oldName: string;
  Id: string | null;
  setField: ({ id, oldName, name }: ChangedFields) => void;
  setTypeField: ({ name, type }: any) => void;
  apllyChange: () => void;
  handleRemove: () => void;
}

const EditCustomField: React.FC<EditCustomField> = ({
  Name,
  FieldType,
  oldName,
  setField,
  Id,
  apllyChange,
  setTypeField,
  handleRemove,
}) => {
  const renderAddButton = () => (
    <IconButton
      style={{ marginBottom: "-8px", marginRight: "-8px", padding: "8px" }} // align to right side
      onClick={handleRemove}
      edge="end"
    >
      <RemoveCircleOutlineIcon fontSize="small" />
    </IconButton>
  );

  const handleNameChange = ({ target }) => {
    setField({
      id: Id,
      oldName,
      name: target.value.replace(/\s\s+/g, " "),
    });
  };

  const handleTypeChange = ({ target }) => {
    setTypeField({ name: Name, type: target.value });
  };

  return (
    <EditCustomFieldLayout
      Name={Name}
      FieldType={FieldType}
      nameIcon={<DragIndicatorOutlinedIcon />}
      renderActionButton={renderAddButton}
      handleNameChange={handleNameChange}
      handleTypeChange={handleTypeChange}
      handleNameBlur={apllyChange}
    />
  );
};

export default EditCustomField;
