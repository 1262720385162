import { ActivityModel } from "./ActivityModelFull";

export type ActivitiesCustomMap = Map<string, ActivityForWizardModel>;

export class ActivityForWizardModel extends ActivityModel {
  _checked?: boolean;
  _editModeEnabled?: boolean;
  _order?: number | null;

  constructor({
    _checked = true,
    _editModeEnabled = false,
    _order = null,
    ...rest
  }: ActivityForWizardModel & ActivityModel = {}) {
    super(rest as ActivityModel);

    this._checked = _checked;
    this._editModeEnabled = _editModeEnabled;
    this._order = _order;
  }
}
