import React from "react";
import styled from "styled-components";
import { DESCRIPTION, VIN } from "../../../_constant/wordings";
import VimSmartField from "../../CommonInfoTabContainer/SmartField/VimSmartField";
import CustomField from "../../CustomField/CustomField";
import InputField from "../../InputField/InputField";
import { FIELD_TYPES_OBJECT } from "../../ItemCustomFields/ItemCustomFieldsTypeOptions";
import { scrollStyles } from "../../_styled/scrollStyles";
import { WizardItemTypeHeader } from "../WizardItemTypeScreen/WizardItemTypeStyles";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const WizardCommonInfoContainerStyles = styled.div`
  padding: 16px 25px;
  width: 100%;
  height: 100%;

  & .WizardItemTypeStyles__WizardItemTypeHeader-fWgBQb {
    padding: 8px 0;
  }

  & .Section_row {
    margin-bottom: 10px;
  }
  `;

  const ListWrapper = styled.div`
    width: 100%;
    height: 94%;
    padding: 0 15px;
    overflow-y: auto;

    ${scrollStyles};
`;

const WizardCommonInfoScreen = (props) => {
  const { newItem, updateItem, ItemTypeFields, pattern } = props;
  const handleFieldValueChange = ({ target }) => {
    const name = target.id;
    let value = target.value;

    if (name === DESCRIPTION) {
      updateItem({ ...newItem, Description: value });
      return;
    }

    const currentFieldset = ItemTypeFields.find((element) => element.Name === name);
    if (!currentFieldset) return;
    if (currentFieldset.FieldType === FIELD_TYPES_OBJECT.NUMBER) {
      value = value ? Number(value) : null;
    }
    if (currentFieldset.FieldType === FIELD_TYPES_OBJECT.DATE) {
      value = value ? value : null;
    }
    if (currentFieldset.FieldType === FIELD_TYPES_OBJECT.SELECT) {
      value = value.hasOwnProperty("value") ? value.value : value;
    }

    updateItem({
      ...newItem,
      TypeFieldValues: { ...newItem.TypeFieldValues, [name]: value }});
  };

  const updateManyFields = (newData: Partial<any>) => {
    updateItem({...newItem, TypeFieldValues: newData.TypeFieldValues});
  };

  return (
      <WizardCommonInfoContainerStyles>
        <WizardItemTypeHeader>
          The more details you provide on your item, the more helpful Upkeepr can be.
        </WizardItemTypeHeader>
        <ListWrapper>
        {ItemTypeFields.length ?
          (ItemTypeFields.sort((prev, next) => prev.Order - next.Order).map(
              ({ Name, FieldType, IsCustomValueAllowed, AllowedValues }) => {
                const value = newItem.TypeFieldValues.hasOwnProperty(Name)
                  ? newItem.TypeFieldValues[Name]
                  : null;
                const options = AllowedValues?.map((item) => ({ value: item, label: item }));

                if (Name === VIN) {
                  return (
                    <VimSmartField
                      key={Name}
                      type={FieldType}
                      name={Name}
                      value={value}
                      options={options}
                      isSelectWritable={!!IsCustomValueAllowed}
                      multipleUpdate={updateManyFields}
                      AssetFieldValues={newItem?.TypeFieldValues ?? {}}
                      onChange={handleFieldValueChange}
                      defaultDescriptionPatern={pattern}
                    />
                  );
                }

                return (
                  <CustomField
                    key={Name}
                    type={FieldType}
                    name={Name}
                    value={value}
                    onChange={handleFieldValueChange}
                    options={options}
                    isSelectWritable={!!IsCustomValueAllowed}
                  />
                );
              },
            )
          ) : (
            <InputField
              handleChange={handleFieldValueChange}
              value={newItem[DESCRIPTION]}
              id={DESCRIPTION}
              label={DESCRIPTION}
              fullWidth
              icon={<DescriptionOutlinedIcon />}
              multiline
              maxLength="1000"
          />
          )}
        </ListWrapper>
      </WizardCommonInfoContainerStyles>
  );
};

export default WizardCommonInfoScreen;
