import React from "react";
import { FormTitlePrefix, FormTitleStyle } from "./FormPageTitle.style";

interface FormPageTitleProps {
  entity: string;
  name: string;
  isCustomize: boolean;
  isNew: boolean;
}

const FormPageTitle: React.FC<FormPageTitleProps> = ({ entity, name, isCustomize, isNew }) => {
  const defaultTitle = isNew ? `Create New ${entity}` : `Edit ${entity}`;

  if (isCustomize) {
    return (
      <div>
        <FormTitlePrefix>Customization</FormTitlePrefix> <FormTitleStyle>{name}</FormTitleStyle>
      </div>
    );
  } else {
    return <FormTitleStyle>{defaultTitle}</FormTitleStyle>;
  }
};

export default FormPageTitle;
