import React, { useCallback, useEffect, useRef, useState } from "react";

// import { ItemType } from "./ItemPrintableCardMobile/types";

import { useMediaQuery } from "@material-ui/core";

import ItemPrintableCard from "./ItemPrintableCard/ItemPrintableCard";
import ItemPrintableCardMobile from "./ItemPrintableCardMobile/ItemPrintableCardMobile";
import { AssetModel } from "../../redux/models/data/AssetModel";

interface ItemPrintableContainerProps {
  item: AssetModel;
  isOpenedMoreInfo: boolean;
}
const ItemPrintableContainer: React.FC<ItemPrintableContainerProps> = ({
  item,
  isOpenedMoreInfo,
}) => {
  const isDesktop = useMediaQuery("(min-width: 860px)", { noSsr: true });
  const desktopListRef = useRef<HTMLDivElement>(null);
  const mobileListRef = useRef<HTMLDivElement>(null);

  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(() => isOpenedMoreInfo);
  }, [isOpenedMoreInfo]);

  const toggleExpand = useCallback(() => {
    setActive((prev) => !prev);
  }, []);

  const handleStartPrintEvent = () => {
    if (!isDesktop) {
      if (desktopListRef?.current?.style?.display && mobileListRef?.current?.style?.display) {
        desktopListRef.current.style.display = "block";
        mobileListRef.current.style.display = "none";
      }
    }
  };
  useEffect(() => {
    window.addEventListener("beforeprint", handleStartPrintEvent);
    return () => {
      window.removeEventListener("beforeprint", handleStartPrintEvent);
    };
  }, []);

  const handleEndPrintEvent = () => {
    if (!isDesktop) {
      if (desktopListRef?.current?.style?.display && mobileListRef?.current?.style?.display) {
        desktopListRef.current.style.display = "none";
        mobileListRef.current.style.display = "block";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("afterprint", handleEndPrintEvent);
    return () => {
      window.removeEventListener("afterprint", handleEndPrintEvent);
    };
  }, []);

  return (
    <>
      <div
        ref={desktopListRef}
        style={{ display: isDesktop ? "block" : "none", marginBottom: "1rem" }}
      >
        <ItemPrintableCard
          key={item.Id}
          item={item}
          isOpenedMoreInfo={isActive}
          toggleExpand={toggleExpand}
        />
      </div>
      <div
        ref={mobileListRef}
        style={{ display: !isDesktop ? "block" : "none", width: "100%", marginBottom: "1rem" }}
      >
        <ItemPrintableCardMobile
          key={`${item.Id}-mobile`}
          item={item}
          isOpenedMoreInfo={isActive}
          toggleExpand={toggleExpand}
        />
      </div>
    </>
  );
};

export default ItemPrintableContainer;
