import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { shallowEqual, useSelector } from "react-redux";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { VendorModel } from "../../redux/models/data/VendorModel";
import { getVendorsQuery } from "./getVendors.query";
import { getSearchStringValue } from "../../redux/reducers/filterReducer";

import Grid from "@material-ui/core/Grid";

import AllVendorsBoardView from "./AllVendorsBoardView";
import AllVendorsListView from "./AllVendorsListView";
import { useLayoutSwitcherContext } from "../../components/LayoutSwitcher/useLayoutSwitcherContext";
import { LayoutType } from "../../_constant/constants";
import { alphabetNameSorting } from "../../_utils/utils";

interface AllVendorsWrapperProps {}

const AllVendorsWrapper: React.FC<AllVendorsWrapperProps> = () => {
  const { accountId } = useGetAccountId();
  const { state } = useLayoutSwitcherContext();
  const searchString: string = useSelector(getSearchStringValue, shallowEqual);

  const { data, loading, error } = useQuery(getVendorsQuery, {
    variables: { AccountId: accountId },
    skip: !accountId,
  });

  const vendors =
    data?.Vendors?.filter((vendor: VendorModel) => vendor).sort(alphabetNameSorting) ??
    ([] as VendorModel[]);

  const filteredVendors =
    vendors &&
    vendors.filter((vendor: VendorModel) => {
      return (
        vendor.Name?.toLowerCase().includes(searchString.toLowerCase()) ||
        vendor.Address?.toLowerCase().includes(searchString.toLowerCase()) ||
        vendor.Email?.toLowerCase().includes(searchString.toLowerCase()) ||
        vendor.Phone?.toLowerCase().includes(searchString.toLowerCase()) ||
        vendor.Website?.toLowerCase().includes(searchString.toLowerCase())
      );
    });

  return (
    <>
      {state.vendors === LayoutType.BOARD ? (
        <AllVendorsBoardView vendors={filteredVendors} loading={loading} />
      ) : (
        <AllVendorsListView vendors={filteredVendors} loading={loading} />
      )}
    </>
  );
};

export default AllVendorsWrapper;
