import React from "react";
import styled, { css } from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";

const svgCardDefaultValues = {
  rectBigSize: 108,
  rectSmallSize: 40,
  rectBorderRadius: 8,
  offset: 10,
  removeIconSize: 21,
};

interface SizeProps {
  size: number;
}

interface SvgContainerProps {
  offset: number;
  height: number;
  width: number;
}

interface SizeAndPlaceholder extends SizeProps {
  withoutImg: boolean;
}

const BorderedRect = styled.rect<SizeAndPlaceholder>`
  fill: ${({ withoutImg, theme }) => (withoutImg ? "none" : `${theme.palette.primary.light}`)};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const RemoveIconBase = css`
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
`;

const RemoveBadge = styled(CancelIcon)<SizeProps>`
  ${RemoveIconBase}
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ theme }) => theme.palette.primary.main};
`;

const RemovePlaceholder = styled.div<SizeProps>`
  ${RemoveIconBase}
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: ${({ theme }) => theme.palette.primary.light};
`;

const SvgCardContainer = styled.div<SvgContainerProps>`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  padding: ${({ offset }) => `${offset}px ${offset}px 0`};
`;

export interface DropZoneSvgCardBaseProps {
  rectSize?: number;
  rectBorderRadius?: number;
  offset?: number;
  removeIconSize?: number;
  large?: boolean;
  imageUrl?: string;
  id: string;
  remove: () => void;
}

const DropZoneSvgCardBase: React.FC<DropZoneSvgCardBaseProps> = (props) => {
  const {
    rectBorderRadius = svgCardDefaultValues.rectBorderRadius,
    offset = svgCardDefaultValues.offset,
    removeIconSize = svgCardDefaultValues.removeIconSize,
    rectSize = props.large ? svgCardDefaultValues.rectBigSize : svgCardDefaultValues.rectSmallSize,
    imageUrl,
    id,
    remove,
  } = props;

  const svgId = `dropzoneFileCard-${id?.replace(/[\W_]+/g, "")}`;

  return (
    <SvgCardContainer height={rectSize + offset} width={rectSize + offset * 2} offset={offset}>
      <svg height={rectSize} width={rectSize} role="none">
        <mask id={svgId}>
          {/* Circle mask avatar */}
          <rect
            rx={rectBorderRadius}
            ry={rectBorderRadius}
            fill="white"
            width={rectSize}
            height={rectSize}
          ></rect>
          {/* A small circle is cut out in the top right corner of the avatar */}
          <circle cx={rectSize} fill="black" r={(removeIconSize + 2) / 2}></circle>
        </mask>
        {/* A group that contains a rectangle and an image*/}
        <g mask={`url(#${svgId})`}>
          <image
            width={rectSize}
            height={rectSize}
            preserveAspectRatio="xMidYMid slice"
            href={imageUrl}
          />
          {
            <BorderedRect
              rx={rectBorderRadius}
              ry={rectBorderRadius}
              size={rectSize}
              withoutImg={!!imageUrl}
            ></BorderedRect>
          }
        </g>
      </svg>
      {!imageUrl ? (
        <RemovePlaceholder size={removeIconSize - 2} />
      ) : (
        <RemoveBadge size={removeIconSize} onClick={remove} />
      )}
    </SvgCardContainer>
  );
};

export default DropZoneSvgCardBase;
