import * as React from "react";

import LooksOneOutlinedIcon from "@material-ui/icons/LooksOneOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import { FieldTypes, FIELD_TYPES_OBJECT } from "../ItemCustomFields/ItemCustomFieldsTypeOptions";
import { getCustomFieldIcon } from "../ItemCustomFields/CustomFIeldIconMapByType";

import InputField from "../InputField/InputField";
import InputRow from "../InputRow/InputRow";
import DatePickerInput from "../DatePickerInput/DatePickerInput";
import Combobox from "../Combobox/Combobox";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";
import { formatDateWithoutTZ } from "../../_utils/date";

interface CustomFieldProps {
  type: FieldTypes;
  name: string;
  value: string | boolean;
  options?: { value: string; label: string }[];
  isSelectWritable?: boolean;
  onChange: (e: any) => void;
  onRemove?: () => void;
}

const CustomField: React.FC<CustomFieldProps> = ({
  type = "STRING",
  value = "",
  name,
  options,
  onChange,
  onRemove,
  isSelectWritable,
}) => {
  const generateInputType = () => {
    if (type === FIELD_TYPES_OBJECT.STRING) {
      return (
        <InputRow alignItems="flex-end">
          <InputField
            type="text"
            handleChange={onChange}
            value={value}
            label={name}
            id={name}
            fullWidth
            name={name}
            icon={getCustomFieldIcon(type)}
            maxLength={60}
          />
        </InputRow>
      );
    }

    if (type === FIELD_TYPES_OBJECT.NUMBER) {
      return (
        <InputRow alignItems="flex-end">
          <InputField
            type="number"
            handleChange={onChange}
            value={value}
            label={name}
            id={name}
            fullWidth
            icon={getCustomFieldIcon(type)}
            maxLength={60}
          />
        </InputRow>
      );
    }

    if (type === FIELD_TYPES_OBJECT.ADDRESS && typeof value !== "boolean") {
      const hanlderChange = (e, target) => {
        onChange({ target });
      };
      return (
        <InputRow alignItems="flex-end">
          <AddressAutocomplete
            address={value}
            handleChange={hanlderChange}
            icon={getCustomFieldIcon(type)}
            id={name}
            label={name}
          />
        </InputRow>
      );
    }

    if (type === FIELD_TYPES_OBJECT.BOOLEAN) {
      const hanlderSwitch = () => onChange({ target: { id: name, value: !value } });
      return (
        <InputRow alignItems="flex-end">
          <FormControlLabel
            control={
              <Switch checked={!!value} onChange={hanlderSwitch} name="checkedB" color="primary" />
            }
            label={name}
          />
        </InputRow>
      );
    }

    if (type === FIELD_TYPES_OBJECT.DATE) {
      const formattedDate = formatDateWithoutTZ(value as string);
      const date = formattedDate ? formattedDate : (value as string);

      return (
        <InputRow alignItems="flex-end">
          <DatePickerInput
            value={date}
            handleChange={(value) => onChange({ target: { id: name, value: value } })}
            name={name}
          />
        </InputRow>
      );
    }

    const getNewValueBasedOnAction = (e, newValue: { id?: string; value: any }) => {
      const { id, value } = newValue;
      onChange({ target: { id, value } });
    };

    if (type === FIELD_TYPES_OBJECT.SELECT) {
      return (
        <InputRow alignItems="flex-end">
          <Combobox
            id={name}
            value={value ?? ""}
            handleChange={getNewValueBasedOnAction}
            name={name}
            label={name}
            creatable={!!isSelectWritable}
            options={options}
            required={false}
            hasError={false}
            icon={getCustomFieldIcon(type)}
          />
        </InputRow>
      );
    }

    return null;
  };

  return generateInputType();
};

export default CustomField;
