import * as React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import useGetAccountId from "./useGetAccountId";
import { CUSTOMER } from "../_constant/wordings";
import { alphabetNameSorting } from "../_utils/utils";

const GET_CUSTOMER_ITEMS = gql`
  query getCustomerItems($CustomerId: ID, $AccountId: ID) {
    Customer(Id: $CustomerId, AccountId: $AccountId) {
      Id
      Assets {
        Id
        Name
      }
    }
  }
`;

const useGetCustomerItemsNames = (customerId: string | null | undefined = null) => {
  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(GET_CUSTOMER_ITEMS, {
    variables: { AccountId: accountId, CustomerId: customerId },
    skip: !accountId || !customerId, // if customerId = null, skip fetch
  });

  if (loading || error) {
    return [];
  }

  const res = data && data[CUSTOMER] && data[CUSTOMER]["Assets"].sort(alphabetNameSorting);

  return res || [];
};

export default useGetCustomerItemsNames;
