import React from "react";

import { FIELD_TYPES_OBJECT } from "../ItemCustomFields/ItemCustomFieldsTypeOptions";

import CustomField from "../CustomField/CustomField";

interface CustomFieldsList {
  Schema: any[];
  CustomFields: any;
  onChange: (e, target: any) => void;
}

const CustomFieldsList: React.FC<CustomFieldsList> = (props) => {
  const { Schema, CustomFields, onChange } = props;

  const handleRemove = (fieldName) => {
    const newSchema = Schema.filter((element) => element.Name !== fieldName)
      .sort((a, b) => a.Order - b.Order)
      .map((element, index) => ({ ...element, Order: index + 1 }));

    const newCustomFields = { ...CustomFields };
    delete newCustomFields[fieldName];
    onChange(null, { id: "Schema", value: newSchema });
    onChange(null, { id: "CustomFields", value: newCustomFields });
  };

  const handleCustomFieldValueChange = ({ target }) => {
    let value = target.value;

    const currentSchema = Schema.find((element) => element.Name === target.id);
    if (currentSchema?.FieldType === FIELD_TYPES_OBJECT.NUMBER) {
      value = value ? Number(value) : null;
    }
    if (currentSchema?.FieldType === FIELD_TYPES_OBJECT.DATE) {
      value = value ? value : null;
    }
    onChange(null, { id: "CustomFields", value: { ...CustomFields, [target.id]: value } });
  };

  return (
    <>
      {Schema.sort((a, b) => a.Order - b.Order).map(({ Id, Name, FieldType }) => (
        <CustomField
          key={Id ? Id : Name}
          type={FieldType}
          name={Name}
          value={CustomFields[Name]}
          onChange={handleCustomFieldValueChange}
          onRemove={() => handleRemove(Name)}
        />
      ))}
    </>
  );
};

export default CustomFieldsList;
