import { useMutation } from "@apollo/react-hooks";

import { uploadItemsFromCsvQuery } from "./uploadCsvFile.query";
import { GET_ASSET_REFS } from "../../containers/assets/all/assets.query";

import { displayErrorNotification } from "../../redux/actions/_utils";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { toastr } from "react-redux-toastr";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { FileType, UploadErrorType } from "./UploadCsvDialog";

type uploadCSVFileType = (
  file: FileType,
  setFile: { (file: FileType): void },
  setIsOpen: { (isOpen: Boolean): void },
  setUploadError: { (UploadError: UploadErrorType): void },
) => void;

type useGetItemsFromCsvFileType = () => [uploadCSVFileType, { uploadLoading: Boolean }];

export const useGetItemsFromCsvFile: useGetItemsFromCsvFileType = () => {
  const { accountId } = useGetAccountId();
  const [uploadMutation, { loading: uploadLoading }] = useMutation(uploadItemsFromCsvQuery, {
    errorPolicy: "all",
  });

  const uploadCSVFile: uploadCSVFileType = (file, setFile, setIsOpen, setUploadError) => {
    uploadMutation({
      variables: {
        AccountId: accountId,
        File: file,
      },
      update(cache: DataProxy, { data: { AssetsCSVUpload } }: any) {
        try {
          const AssetRefsQuery: any = cache.readQuery({
            query: GET_ASSET_REFS,
            variables: {
              Id: accountId,
            },
          });
          AssetRefsQuery.Account.AssetRefs = [
            ...AssetRefsQuery.Account.AssetRefs,
            ...AssetsCSVUpload.AssetRefs,
          ];
          cache.writeQuery({
            query: GET_ASSET_REFS,
            variables: {
              Id: accountId,
            },
            data: { ...AssetRefsQuery },
          });
        } catch {}
      },
    })
      .then(({ data }) => {
        if (!data?.AssetsCSVUpload?.AssetRefs && !data?.AssetsCSVUpload?.LimitWarn) {
          displayErrorNotification(NotificationTypes.UPLOAD_FILE_ERROR);
          return;
        }
        if (data?.AssetsCSVUpload?.AssetRefs !== null) {
          toastr.success(`${data?.AssetsCSVUpload?.AssetRefs?.length} Items created`, "");
        }
        if (data?.AssetsCSVUpload?.LimitWarn) {
          toastr.warning(`${data?.AssetsCSVUpload?.LimitWarn}`, "");
        }
        setFile(null);
        setIsOpen(false);
      })
      .catch((error) => {
        const message = error.message.includes("GraphQL")
          ? error.message.split("GraphQL.ExecutionError: ").slice(1).join(" ")
          : error.message;

        setUploadError(message);

        if (message && message.length < 100) {
          setUploadError(message);
        } else {
          displayErrorNotification(NotificationTypes.UPLOAD_FILE_ERROR);
        }
      });
  };

  return [uploadCSVFile, { uploadLoading }];
};
