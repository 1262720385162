import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setEditFormIsDirty } from "../../redux/actions/actions";
import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../redux/actions/_utils";

import { useNavigate } from "react-router";

import { toastr } from "react-redux-toastr";

import ProjectFormContainer from "../../components/ProjectForm/ProjectFormContainer";

import useGetProjectsStatuses from "../../customHooks/api/useGetProjectsStatuses";
import useGetAccountId from "../../customHooks/useGetAccountId";
import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";
import useCreateProjectMutation from "../../customHooks/useCreateProjectMutation";

import { ProjectModel } from "../../redux/models/data/ProjectModel";

import { NotificationTypes } from "../../_constant/NotificationTypes";
import { ROUTES } from "../../_constant/screens";
import { FILE_UPLOAD_RESULTS, ID, PROJECT_POST } from "../../_constant/wordings";
import { SAVE, SaveType, SAVE_AND_CLOSE } from "../../containers/assets/existing/EditAsset";

import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import { useGetUserSubscriptionTier } from "../../customHooks/useGetUserSubscriptionTier";
import { formatDateForSubmit } from "../../_utils/date";

const NewProject: React.FC = () => {
  const [project, setProject] = useState(new ProjectModel());

  const { accountId } = useGetAccountId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { successfulRedirect } = useSuccessfulRedirect();
  const statuses = useGetProjectsStatuses();
  const [createProject, { isProjectCreating }] = useCreateProjectMutation();
  const userSubscriptionTier = useGetUserSubscriptionTier();

  const { setHeaderOptions } = useHeaderOptions({
    pageTitle: "Create Project",
    withBackButton: true,
    headerType: "DEFAULT",
  });

  useEffect(() => {
    if (
      !userSubscriptionTier.isProfessional &&
      !userSubscriptionTier.isPremium &&
      !userSubscriptionTier.loading
    ) {
      navigate(ROUTES.ACTIVITIES);
    }
  }, [userSubscriptionTier]);

  useEffect(() => {
    const resultActivity = {} as ProjectModel;
    if (statuses.length > 0) {
      resultActivity.ProjectStatusId = statuses[0].Id;
      setProject((prevActivity) => new ProjectModel({ ...prevActivity, ...resultActivity }));
    }
  }, [statuses]);

  const handleCreate = (data: ProjectModel, saveType: SaveType) => {
    if (!accountId) {
      return;
    }
    const { Files } = data;

    const formatDueDateTime = formatDateForSubmit(data?.DueDateTime as string);
    const dataForPost: any = { ...data, AccountId: accountId, DueDateTime: formatDueDateTime };
    delete dataForPost.Files;

    const variables = {
      Project: {
        ...dataForPost,
      },
      Files,
    };

    createProject(variables)
      .then(({ data }: any) => {
        const uploadResult = data[PROJECT_POST]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[PROJECT_POST][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new ProjectModel(data[PROJECT_POST]));
          displaySuccessNotification(NotificationTypes.PROJECT_CREATED);
          const currentProjectPath = `${ROUTES.SINGLE_PROJECT}/${data[PROJECT_POST][ID]}`;
          navigate(currentProjectPath, {
            replace: true,
            state: { justCreated: true },
          });
        } else if (data[PROJECT_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new ProjectModel(data[PROJECT_POST]));
          successfulRedirect(ROUTES.PROJECTS, NotificationTypes.PROJECT_CREATED);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_CREATE_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message && message.length < 100) {
          toastr.error(message);
        } else {
          displayErrorNotification(NotificationTypes.PROJECT_CREATE_ERROR);
        }
      });
  };

  return (
    <>
      <ProjectFormContainer
        data={project}
        handleSubmit={handleCreate}
        isNew
        isSaving={isProjectCreating}
        setHeaderOptions={setHeaderOptions}

        // isFetching={isVendorLoading || !vendorsData}
      />
      {/* {isLimitExceeded && (
        <ExceededLimitDialog
          message="Creating a new Project would exceed your limit."
          submit={() => navigate(-1)}
          cancel={() => navigate(ROUTES.EDIT_PROFILE)}
        />
      )} */}
    </>
  );
};

export default NewProject;
