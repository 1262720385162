import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useMutation } from "react-apollo";
import { useSelector, shallowEqual } from "react-redux";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { getUserInfoQuery } from "../containers/profile/profile.query";

import { ID, USER } from "../_constant/wordings";

import { isDeviceRegisterInfoEnabled } from "../redux/reducers/appReducer";

declare global {
  interface Window {
    getPushNotification: any;
    getPushToken: any;
    pushConfig: any;
  }
}

const patchUserDevice = gql`
  mutation patchUserDevice($UserId: ID, $UserDevice: [UserDeviceInputType], $TimeZoneId: String) {
    UserDevicePatch(UserId: $UserId, UserDevice: $UserDevice, TimeZoneId: $TimeZoneId) {
      PushToken
      MobilePlatform
    }
  }
`;

const InitNotificationFunctions = () => {
  const [patch, { data }] = useMutation(patchUserDevice);
  const [pushConfig, setPushConfig] = useState<any>({
    token: "",
    platform: "",
  });
  const { data: userData } = useQuery(getUserInfoQuery, {
    fetchPolicy: "cache-only",
  });

  const isInfoEnabled = useSelector(isDeviceRegisterInfoEnabled, shallowEqual);

  useEffect(() => {
    if (window.pushConfig.token || window.pushConfig.platform) {
      setPushConfig({ ...window.pushConfig });
    }
  }, []);

  useEffect(() => {
    window.pushConfig = new Proxy({ ...pushConfig } as any, {
      set: (target, prop, value) => {
        target[prop] = value;
        setPushConfig((prevState: any) => {
          return {
            ...prevState,
            [prop]: value,
          };
        });
        return true;
      },
    });
  }, []);

  useEffect(() => {
    const isAlreadyPatched = localStorage.getItem("isUserDevicePatched");
    const pushConfigFromLocalStorage = isAlreadyPatched ? JSON.parse(isAlreadyPatched) : null;
    const token = pushConfigFromLocalStorage?.token ?? null;

    if (
      pushConfig.token &&
      pushConfig.platform &&
      userData &&
      userData[USER] &&
      userData[USER][ID] &&
      token !== pushConfig.token
    ) {
      const { token, platform } = pushConfig;
      let normalizedPlatform;

      if (platform === "Android") {
        normalizedPlatform = "ANDROID";
      } else {
        normalizedPlatform = "I_OS";
      }
      patch({
        variables: {
          UserId: userData[USER][ID],
          UserDevice: [
            {
              PushToken: token,
              MobilePlatform: normalizedPlatform,
            },
          ],
          TimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
        .then((res) => {
          if (isInfoEnabled) toastr.success(JSON.stringify(res));
          const pushConfigStringify = JSON.stringify(pushConfig);
          localStorage.setItem("isUserDevicePatched", pushConfigStringify);
        })
        .catch((err) => {
          if (isInfoEnabled) toastr.error(err.toString());
          console.log(err);
        });
    }
  }, [userData, pushConfig]);

  return null;
};

export default InitNotificationFunctions;
