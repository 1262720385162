import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { getInvitesQuery } from "./getInvitationsList.query";

import { ID, USER } from "../../_constant/wordings";
import { InvitationStateOptions } from "../../_constant/constants";

import { InvitationModel } from "../../redux/models/data/InvitationModel";

export const removeInvitationFromInvitationList = (cache: DataProxy, invitationId: string) => {
  const cacheInvitation: any = cache.readQuery({
    query: getInvitesQuery(InvitationStateOptions.active),
  });

  const newInvitationList = cacheInvitation[USER]["MyInvitations"].filter(
    (invitation: InvitationModel) => invitation.Id !== invitationId,
  );

  cache.writeQuery({
    query: getInvitesQuery(InvitationStateOptions.active),
    data: {
      User: { Id: cacheInvitation[USER][ID], MyInvitations: newInvitationList, __typename: "User" },
    },
  });
};

export const addInvitationToInvitationList = (cache: DataProxy, invitation: InvitationModel) => {
  try {
    const cacheInvitation: any = cache.readQuery({
      query: getInvitesQuery(InvitationStateOptions.active),
    });
    const newInvitationList = cacheInvitation[USER]["MyInvitations"].concat([invitation]);

    cache.writeQuery({
      query: getInvitesQuery(InvitationStateOptions.active),
      data: {
        User: {
          Id: cacheInvitation[USER][ID],
          MyInvitations: newInvitationList,
          __typename: "User",
        },
      },
    });
  } catch (e) {}
};

export const addInvitationToHistoryList = (cache: DataProxy, invitation: InvitationModel) => {
  try {
    const cacheInvitation: any = cache.readQuery({
      query: getInvitesQuery(InvitationStateOptions.declined, InvitationStateOptions.ignored),
    });
    const newInvitationList = cacheInvitation[USER]["MyInvitations"].concat([invitation]);

    cache.writeQuery({
      query: getInvitesQuery(InvitationStateOptions.declined, InvitationStateOptions.ignored),
      data: {
        User: {
          Id: cacheInvitation[USER][ID],
          MyInvitations: newInvitationList,
          __typename: "User",
        },
      },
    });
  } catch (e) {}
};

export const removeInvitationFromHistoryList = (cache: DataProxy, invitationId: string) => {
  try {
    const cacheInvitation: any = cache.readQuery({
      query: getInvitesQuery(InvitationStateOptions.declined, InvitationStateOptions.ignored),
    });

    const newInvitationList = cacheInvitation[USER]["MyInvitations"].filter(
      (invitation: InvitationModel) => invitation.Id !== invitationId,
    );

    cache.writeQuery({
      query: getInvitesQuery(InvitationStateOptions.declined, InvitationStateOptions.ignored),
      data: {
        User: {
          Id: cacheInvitation[USER][ID],
          MyInvitations: newInvitationList,
          __typename: "User",
        },
      },
    });
  } catch (e) {}
};
