import gql from "graphql-tag";

export const GET_ASSET_TYPE_LIST = gql`
  query getAssetTypeList($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetTypes {
        Id
        Name
        IconName
      }
    }
  }
`;

export const GET_EDIT_FLAGS = gql`
  query getAssetTypeList($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetTypes {
        Id
        Name
        IsSystem
        IconName
      }
    }
  }
`;
