import gql from "graphql-tag";

export const GET_PROJECT_VENDORS = gql`
  query getProjectVendors($ProjectId: ID) {
    Project(ProjectId: $ProjectId) {
      Id
      Vendors {
        Id
        Name
        Images {
          Id
          ContainerId
          ContainerName
        }
        Phone
        Email
        Address
        Website
      }
    }
  }
`;
