import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";

export const StyledListItem = styled.div`
  display: flex;

  .MuiAvatar-root {
    width: 24px;
    height: 24px;
    font-size: 14px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

export const StyledListItemAvatar = styled(ListItemAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`;
