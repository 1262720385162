import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";

import useSuccessfulRedirect from "../../customHooks/useSuccessfulRedirect";
import useGetUserId from "../../customHooks/useGetUserId";
import { useHeaderOptions } from "../../components/HeaderOptionsProvider/useHeaderOptions";
import { getAccountById } from "../EditAccount/getAccount.query";

import { AccountModel } from "../../redux/models/data/AccountModel";
import { Image } from "../../redux/models/data/ImageModel";

import { ACCOUNT, ACCOUNT_PUT, ACCOUNT_REFS, ID, USER } from "../../_constant/wordings";
import { ROUTES } from "../../_constant/screens";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { updateAccountsCacheAfterEditAccount } from "../EditAccount/updateAccountsCacheAfterEditAccount";
import { setEditFormIsDirty } from "../../redux/actions/actions";

import { updateBusinessAccountQuery } from "./updateBusinessAccount.query";
import { getAccountRefsList } from "../Accounts/getAccountList.query";

import AccountForm from "../../components/AccountForm/AccountForm";

const EditBusinessAccount: React.FC = (props) => {
  const [accountId, setAccountId] = useState<string | null>(null);
  const [accountModel, setAccountModel] = useState(new AccountModel());
  const { setHeaderOptions } = useHeaderOptions();

  const navigate = useNavigate();
  const currentUserId = useGetUserId();
  const dispatch = useDispatch();
  const { successfulRedirect } = useSuccessfulRedirect();

  const { data: accountRefsData, loading: accountRefsLoading } = useQuery(getAccountRefsList, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const { data: accountData, loading: accountLoading, error: accountError } = useQuery(
    getAccountById,
    {
      variables: { Id: accountId },
      skip: !accountId,
      fetchPolicy: "cache-first",
    },
  );

  useEffect(() => {
    if (currentUserId && accountRefsData) {
      const foundBusinessAccount: AccountModel = accountRefsData[USER][ACCOUNT_REFS].find(
        (account: AccountModel) => {
          return account.IsCustomerManagement && account.OwnerId === currentUserId;
        },
      );
      if (foundBusinessAccount) {
        setAccountId(foundBusinessAccount.Id);
      } else {
        navigate(ROUTES.NEW_BUSINESS);
      }
    }
  }, [accountRefsData, currentUserId]);

  useEffect(() => {
    if (!accountLoading && !accountError && accountData) {
      setAccountModel(new AccountModel(accountData[ACCOUNT]));
    }
  }, [accountData]);

  const [
    updateAccount,
    { data: updatedData, loading: isUpdating, error: updateError },
  ] = useMutation(updateBusinessAccountQuery);

  const onSubmitHandler = (data: AccountModel, saveType: SaveType) => {
    let Image: Image;
    let ImagesList: Image[] = [];
    if (data.Images.length) {
      Image = { ...data.Images[0] };
      delete Image.__typename;
      ImagesList = [Image];
    }
    updateAccount({
      variables: {
        Id: data.Id,
        Name: data.Name,
        Description: data.Description,
        IsCustomerManagement: data.IsCustomerManagement,
        Files: data.Files,
        Images: ImagesList,
      },
      update(cache, { data: { AccountPut } }) {
        updateAccountsCacheAfterEditAccount(cache, AccountPut);
      },
    })
      .then(({ data }: any) => {
        if (data[ACCOUNT_PUT][ID] && saveType.type === SAVE) {
          saveType.callback(data[ACCOUNT_PUT]);
          displaySuccessNotification(NotificationTypes.BUSINESS_ACCOUNT_UPDATED);
        } else if (data[ACCOUNT_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(data[ACCOUNT_PUT]);
          successfulRedirect(ROUTES.ACCOUNTS, NotificationTypes.BUSINESS_ACCOUNT_UPDATED);
        } else {
          displayErrorNotification(NotificationTypes.BUSINESS_ACCOUNT_UPDATED_ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        displayErrorNotification(NotificationTypes.BUSINESS_ACCOUNT_UPDATED_ERROR);
      });
  };

  return (
    <AccountForm
      handleSubmit={onSubmitHandler}
      isLoading={isUpdating}
      isNew={false}
      data={accountModel}
      setHeaderOptions={setHeaderOptions}
      isBusinessAccount={true}
    />
  );
};

export default EditBusinessAccount;
