import gql from "graphql-tag";

export const getActivitiesRelatedToProjectQuery = gql`
  query getActivitiesRelatedToProject($ProjectId: ID) {
    Project(ProjectId: $ProjectId) {
      Id
      AssetActivities {
        Id
        Name
        StatusId
        DueDateTime
        CompletedDateTime
        RelatedDueDateTime
        AssignedUserId
        AssetId
        Description
        Images {
          Id
        }
        Documents {
          Id
        }
        Videos {
          Id
        }
        Schedule {
          RecurringType
        }

        Asset {
          Id
          Name
        }
      }
    }
  }
`;
