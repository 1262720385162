import React, { ReactElement } from "react";

import { IconButton } from "@material-ui/core";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {
  ListItemTextStyled,
  MoreIconStyled,
  ListItemIconStyled,
  MenuItemStyled,
} from "./MoreAction.style";

export interface MoreActionOptions {
  text: string;
  icon: ReactElement;
  callback: (props?: any) => void;
}

interface MoreActionProps {
  options: MoreActionOptions[];
  isLoading?: boolean;
  popupPosition?: PopperPlacementType;
}
const MoreAction: React.FC<MoreActionProps> = ({ options, isLoading, popupPosition }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (e: any, cb: () => void) => {
    e.stopPropagation();
    cb();
    setOpen(false);
  };

  const handleToggle = (e: any) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <span ref={anchorRef}>
        <IconButton
          type="button"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <MoreIconStyled />
        </IconButton>
      </span>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={popupPosition ?? "bottom-end"}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" onClick={(e) => e.stopPropagation()}>
                  {options.map((option) => {
                    const clickHandler = (e: React.SyntheticEvent) =>
                      handleMenuItemClick(e, option.callback);
                    return (
                      <MenuItemStyled key={option.text} onClick={clickHandler} disabled={isLoading}>
                        <ListItemIconStyled>{option.icon}</ListItemIconStyled>
                        <ListItemTextStyled>{option.text}</ListItemTextStyled>
                      </MenuItemStyled>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default MoreAction;
