import React from "react";
import "./AssetManageTab.scss";

import MoveAsset from "../MoveAsset/MoveAsset";

import DeleteButton from "../DeleteButton/DeleteButton";
import { AccountModel } from "../../redux/models/data/AccountModel";

interface AssetManageTabProps {
  isRemoveLoading: boolean;
  isMoveLoading: boolean;
  disabled: boolean;
  onDelete: () => void;
  openConfirm: (accountMoveTo: AccountModel) => void;
  isCustomerManagement: boolean;
}

const AssetManageTab: React.FC<AssetManageTabProps> = ({
  isRemoveLoading,
  disabled,
  onDelete,
  isCustomerManagement,
  ...rest
}) => {
  return (
    <div className="AssetManageTab">
      {!isCustomerManagement && <MoveAsset {...rest} />}
      <DeleteButton disabled={disabled} loading={isRemoveLoading} onClick={onDelete}>
        Delete Item
      </DeleteButton>
    </div>
  );
};

export default AssetManageTab;
