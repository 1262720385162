import * as React from "react";
import { Button, Card, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";
import WifiAnimationLogo from "../components/WifiAnimationLogo/WifiAnimationLogo";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    marginTop: "20px",
  },
});

type ReloadVisibility = boolean;

const SaveConnection = () => {
  const classes = useStyles();
  const [isReloadVisible, setReloadVisible] = React.useState<ReloadVisibility>(false);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setReloadVisible(true);
    }, 3500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="SaveConnectionPage">
      <Card className="SaveConnectionPage__card">
        <CardContent>
          <CardMedia src="/" className="SaveConnectionPage__image" />
          <div className="SaveConnectionPage__info">
            <WifiAnimationLogo />
            <Typography color="textSecondary" gutterBottom>
              Securing your
              <br />
              connection
            </Typography>
            {isReloadVisible && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                classes={{ root: classes.root }}
                onClick={handleClick}
              >
                Reload App
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SaveConnection;
