import React from "react";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import Select from "../Select/Select";

export const SelectIcon = styled(Avatar)`
  border: 1px solid var(--color-brand);
`;

export const FilterSelect = styled(({ ...props }) => <Select {...props} />)`
  
  .MuiInputBase-input {
    padding-top: 0;  
    padding-bottom: 0;
  }

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
    @media screen and (min-width: 800px) {
      font-size: 16px;
      line-height: 24px;
  }
  }
`;
