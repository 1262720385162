import useGetAccountId from "./useGetAccountId";
import { useQuery } from "react-apollo";
import { ACCOUNT, ASSET_REFS } from "../_constant/wordings";
import { GET_ITEMS_LIST } from "./useGetItemsNames";

export const useIsItemsExistsForForm = () => {
  const { accountId } = useGetAccountId();

  const { data } = useQuery(GET_ITEMS_LIST, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-only",
  });

  let exists = false;

  if (data?.[ACCOUNT]?.[ASSET_REFS]?.length) {
    exists = true;
  }

  return {
    exists,
    isDataAvailable: !!data,
  };
};
