import React, { useState } from "react";
import { ROUTES } from "../../_constant/screens";
import { useNavigate } from "react-router-dom";
import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";

interface VisitFormProps {
  isCustomize: boolean;
  handleCustomize: () => void;
  isWizardOpen: boolean;
  handleCloseWizard: () => void;
  handleOpenWizard: () => void;
  setIsDeletePopupVisible: (value: boolean) => void;
  setIsRevertPopupVisible: (value: boolean) => void;
  isRevertPopupVisible: boolean;
  isDeletePopupVisible: boolean;
  handleCancel: () => void;
  handleRevert: () => void;
  isConfirmationDialogVisible: boolean;
  finishedActivities: any[];
  handleFinishVisit: (data: any[]) => void;
  setIsConfirmationDialogVisible: (value: boolean) => void;
}

export const VisitFormContext = React.createContext<VisitFormProps>({} as VisitFormProps);

export const VisitFormContextProvider: React.FC = ({ children }) => {
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCustomize, setIsCustomize] = useState(false);

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);
  const [finishedActivities, setFinishedActivities] = useState<any[]>([]);

  const handleFinishVisit = (data: any[]) => {
    setIsConfirmationDialogVisible(true);
    setFinishedActivities(data);
  };

  const { setHeaderOptions, headerOptions } = useHeaderOptions();
  const navigate = useNavigate();

  const handleOpenWizard = () => {
    setIsWizardOpen(true);
  };
  const handleCloseWizard = () => {
    setIsWizardOpen(false);
  };

  const handleOpenWizardMemoized = React.useCallback(handleOpenWizard, [isWizardOpen]);
  const handleCloseWizardMemoized = React.useCallback(handleCloseWizard, [isWizardOpen]);

  const handleCustomize = () => {
    setIsCustomize((prev) => !prev);
  };

  const handleCancel = () => navigate(ROUTES.VISITS);
  const handleCancelMemoized = React.useCallback(handleCancel, []);
  const handleRevert = () => setIsRevertPopupVisible(true);
  const handleRevertMemoized = React.useCallback(handleRevert, []);

  React.useEffect(() => {
    if (!headerOptions.saveOptions.delete) {
      setHeaderOptions({
        saveOptions: {
          ...headerOptions.saveOptions,
          delete: () => setIsDeletePopupVisible(true),
          revertFunction: handleRevertMemoized,
        },
      });
    }
  }, [headerOptions.saveOptions]);

  return (
    <VisitFormContext.Provider
      value={{
        isCustomize,
        isRevertPopupVisible,
        isDeletePopupVisible,
        isWizardOpen,
        setIsDeletePopupVisible,
        setIsRevertPopupVisible,
        isConfirmationDialogVisible,
        finishedActivities,
        handleFinishVisit,
        handleCustomize,
        handleCancel: handleCancelMemoized,
        handleRevert: handleRevertMemoized,
        handleOpenWizard: handleOpenWizardMemoized,
        handleCloseWizard: handleCloseWizardMemoized,
        setIsConfirmationDialogVisible,
      }}
    >
      {children}
    </VisitFormContext.Provider>
  );
};
