import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import gql from "graphql-tag";

import { getActivityFilters } from "../../../../../redux/reducers/appReducer";

import { CircularProgress, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import { createRelatedActivityQuery } from "./new-activity.query";
import { assetNameFragment, assetTypeRefFragmentType } from "../../../../../graphql/resolvers";

import {
  updateAllActivitiesCacheWithNewActivity,
  updateCustomerActivitiesCacheWithNewActivity,
  updateRelatedActivitiesCacheWithNewActivity,
  updateStartedVisitActivitiesWithNewActivity,
  updateVendorsActivityCacheWithNewActivity,
} from "./updateActivitiesCacheWithNewActivity";

import Input from "../../../../../components/Input/Input";
import InputRow from "../../../../../components/InputRow/InputRow";
import { addActivityToProjectTabCache } from "../../../../../components/ActivitiesTabProject/addActivityToProjectTabCache";
import ActivitiesDateSettings from "../../../../../components/ActivityDateSettings/ActivityDateSettings";
import { updateActivitiesAmountCache } from "../../../../../components/ItemsTab/updateActivitiesCache";

import ParentItemSelect from "../../../../activities/_components/form/fields/ParentItemSelect";

import useGetAcitvitiesStatuses from "../../../../../customHooks/api/useGetAcitvitiesStatuses";
import useGetAccountId from "../../../../../customHooks/useGetAccountId";
import useGetBusinessStatuses from "../../../../../customHooks/useGetBusinessStatuses";

import { NotificationTypes } from "../../../../../_constant/NotificationTypes";
import { ASSET_REFS, NAME } from "../../../../../_constant/wordings";
import { FILTERING_DETAILS } from "../../../../../_constant/ActivityDateConstant";

import { getVisitBooleanStatus } from "../../../../../_utils/utils";
import {
  displayErrorNotification,
  displaySuccessNotification,
  getFilterString,
} from "../../../../../redux/actions/_utils";
import WizardContainer from "../../../../../components/Wizard/WizardContainer/WizardContainer";
import { WIZARD_SCREENS_NAME } from "../../../../../components/Wizard/WizardContainer/WizardConstants";
import { GET_ACTIVITIES_RELATED_TO_ASSET } from "../../../../projects/_components/relaitedActivities/getActivitiesRelatedToAsset.query";
import Combobox from "../../../../../components/Combobox/Combobox";
import { EditOutlined } from "@material-ui/icons";
import { ShortActivityModel } from "./ShortActivityModel";
import { relatedActivityWithAssetQuery } from "./relatedActivityToAsset.query";

const getVisitStatusData = gql`
  query getVisitStatusData($VisitId: ID, $AccountId: ID) {
    Visit(Id: $VisitId, AccountId: $AccountId) {
      Id
      VisitStatusId
      VisitStatusName @client
    }
  }
`;

interface ActivityForComboboxOptions {
  Id: string;
  Name: string;
}
interface IOption {
  value: string;
  label: string;
}

function AddNewActivitySection({
  newRelatedActivity,
  updateRelatedActivity,
  handleActivityCreate,
  filterString,
  errorInField,
  setError,
  withAssetSelect,
  customerId,
  visitId,
  ActivityIds,
  isCustomize,
  formApi,
  isWizardOpen,
  handleOpenWizard,
  handleCloseWizard,
  activityIdsForFilteringSelectOptions,
  setActivityIdsForFilteringSelectOptions,
}) {
  const [options, setOptions] = useState<IOption[]>([]);

  const [createNewActivity, { loading: isLoading }] = useMutation(createRelatedActivityQuery);
  const [getActivitiesFromAsset, { data, loading }] = useLazyQuery(GET_ACTIVITIES_RELATED_TO_ASSET);
  const [relatedActivityWithAsset, { loading: isUpdate }] = useMutation(
    relatedActivityWithAssetQuery,
  );

  const statuses = useGetAcitvitiesStatuses();
  const vendorId = formApi?.data.Id;
  const { IsCustomerManagement, accountId } = useGetAccountId();
  useGetBusinessStatuses(!visitId);
  const { data: visitStatusData } = useQuery(getVisitStatusData, {
    variables: {
      VisitId: visitId,
      AccountId: accountId,
    },
    skip: !visitId || !accountId,
  });

  const { isVisitStarted } = getVisitBooleanStatus(visitStatusData?.Visit?.VisitStatusName ?? "");

  const doneStatus = statuses.find((status: { Name: string }) => status.Name === "Done");
  const activitiesFromAsset = data?.Account.Assets[0].AssetActivities;

  useEffect(() => {
    if (!accountId || !doneStatus || IsCustomerManagement) {
      return;
    }
    if (newRelatedActivity.AssetId) {
      getActivitiesFromAsset({
        variables: {
          Id: accountId,
          AssetId: newRelatedActivity.AssetId,
          Filter: `StatusId!="${doneStatus.Id}"`,
        },
      });
    }
  }, [newRelatedActivity.AssetId, accountId, doneStatus]);

  useEffect(() => {
    if (!IsCustomerManagement) {
      if (activitiesFromAsset && activityIdsForFilteringSelectOptions) {
        const allOptions: IOption[] = activitiesFromAsset?.map(
          (activity: ActivityForComboboxOptions) => ({
            value: activity.Id,
            label: activity.Name,
          }),
        );

        const filteredOptions = allOptions.filter((activity) => {
          return !activityIdsForFilteringSelectOptions.some((id) => id === activity.value);
        });
        if (newRelatedActivity.AssetId) {
          setOptions(filteredOptions);
        }
      }
      if (!activitiesFromAsset) {
        setOptions([]);
      }
    }
  }, [activitiesFromAsset, activityIdsForFilteringSelectOptions, newRelatedActivity.AssetId]);

  const handleCreateActivity = (e) => {
    const variables: ShortActivityModel = { ...newRelatedActivity };
    if (!accountId) return;
    e.stopPropagation();
    const newError = new Map(errorInField);
    if (newRelatedActivity.Name && newRelatedActivity.Name.length > 0) {
      newError.delete("Name");
    } else {
      newError.set("Name", "Name must contain at least 1 character");
    }

    if (newRelatedActivity.AssetId) {
      newError.delete("AssetId");
    } else {
      newError.set("AssetId", "Choose activity asset");
    }

    setError(newError);

    if (newError.size > 0) return;

    if (!variables.StatusId && statuses.length) {
      variables.StatusId = statuses[0].Id;
    }
    if (!variables.AccountId) {
      variables.AccountId = accountId;
    }

    createNewActivity({
      variables: variables,
      update(cache, { data: { AssetActivityPost } }) {
        const assetRef: assetTypeRefFragmentType = cache.readFragment({
          fragment: assetNameFragment,
          id: AssetActivityPost.AssetId,
        });
        AssetActivityPost.Asset = {
          Name: assetRef?.Name,
          Id: AssetActivityPost.AssetId,
          __typename: ASSET_REFS,
        };

        AssetActivityPost["isLoading"] = false;
        AssetActivityPost["Images"] = [];

        if (IsCustomerManagement) {
          updateCustomerActivitiesCacheWithNewActivity(cache, AssetActivityPost, customerId);
          updateActivitiesAmountCache(cache, AssetActivityPost);
          if (isVisitStarted) {
            updateStartedVisitActivitiesWithNewActivity(
              cache,
              AssetActivityPost,
              accountId,
              visitId,
            ); // call only if visit started
          }
          return; // business account activities cache are handled only by above functions
        }

        if (AssetActivityPost?.VendorRefs?.length) {
          updateVendorsActivityCacheWithNewActivity(cache, AssetActivityPost, ActivityIds);
        }

        const filteringString = filterString(
          statuses.find((element) => element.Id === AssetActivityPost.StatusId),
        );

        updateRelatedActivitiesCacheWithNewActivity(cache, AssetActivityPost);
        updateAllActivitiesCacheWithNewActivity(cache, AssetActivityPost, filteringString);
      },
    })
      .then(({ data }) => {
        handleActivityCreate();
        displaySuccessNotification(NotificationTypes.ACTIVITY_CREATED);
      })
      .catch(() => {
        handleActivityCreate();
        displayErrorNotification(NotificationTypes.ACTIVITY_CREATE_ERROR);
      });
  };

  const handleSubmit = (e) => {
    if (!newRelatedActivity.Id || IsCustomerManagement) {
      handleCreateActivity(e);
      return;
    }

    const variables = {
      AccountId: accountId,
      Id: newRelatedActivity.Id,
      VendorRefs: [...newRelatedActivity.VendorRefs],
    };
    relatedActivityWithAsset({
      variables: variables,
      update(cache, { data: { AssetActivityPatch } }) {
        if (AssetActivityPatch?.VendorRefs?.length) {
          updateVendorsActivityCacheWithNewActivity(cache, AssetActivityPatch, ActivityIds);
        }
      },
    })
      .then(({ data }) => {
        updateRelatedActivity(new ShortActivityModel({ AssetId: data.AssetActivityPatch.AssetId }));
        displaySuccessNotification(NotificationTypes.ACTIVITY_CREATED);
        setActivityIdsForFilteringSelectOptions((prev) => [...prev, data.AssetActivityPatch.Id]);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.ACTIVITY_CREATE_ERROR);
      });
  };

  const handleChangeActivity = (
    e: React.SyntheticEvent,
    valueObj: { id: string; value: string },
  ) => {
    let target = valueObj;
    if (e) {
      target = e.target;
    }

    if (!target) return;
    if (target.id === "AssetId" && newRelatedActivity.Id) {
      setOptions([]);
      updateRelatedActivity(new ShortActivityModel({ AssetId: target.value }));
      return;
    }
    updateRelatedActivity(null, { id: target.id, value: target.value });
  };

  const onChangeActivityField = (e, target) => {
    const { id, value } = target;

    if (typeof value === "string") {
      const activity = {
        Name: value,
        AssetId: newRelatedActivity.AssetId,
        VendorRefs: [{ Id: vendorId }],
      };
      updateRelatedActivity(new ShortActivityModel(activity));
    } else if (value) {
      const activity: any = activitiesFromAsset?.find((activity) => activity.Id === value?.value);
      let prevVendorRefs = [];
      if (activity?.VendorRefs) {
        activity.VendorRefs.map((ref) => delete ref.__typename);
        prevVendorRefs = activity.VendorRefs;
      }
      updateRelatedActivity({ ...activity, VendorRefs: [...prevVendorRefs, { Id: vendorId }] });
    } else if (!value) {
      const newActivity = { Name: "", AssetId: newRelatedActivity.AssetId };
      updateRelatedActivity(new ShortActivityModel(newActivity));
    }
  };

  const renderAddButton = () => (
    <IconButton
      style={{ marginBottom: "-8px", marginRight: "-8px", padding: "8px" }} // align to right side
      onClick={handleSubmit}
      color="primary"
      disabled={errorInField.has("Name") || errorInField.has("AssetId")}
    >
      {isLoading || isUpdate ? <CircularProgress size={22} /> : <AddCircleOutlineIcon />}
    </IconButton>
  );

  const getNewValueBasedOnAction = (e, newValue) => {
    if (!newValue) {
      return { Id: null, Name: null };
    }

    return { Id: newValue.value, Name: newValue.label };
  };

  const activityFieldValue = { value: newRelatedActivity.Id, label: newRelatedActivity.Name };

  if (withAssetSelect) {
    return (
      <section className="Section Section_withBorder Section_defaultBackground">
        <WizardContainer
          isOpen={isWizardOpen}
          handleClose={handleCloseWizard}
          startStep={WIZARD_SCREENS_NAME.type_select}
          customerId={customerId}
          isInsideAddActivitySection={isCustomize}
          updateRelatedActivity={updateRelatedActivity}
          updateCacheInSelectItem
        />
        <InputRow alignItems="flex-end" actionZoneComponent={renderAddButton}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ParentItemSelect
                AssetId={newRelatedActivity.AssetId || ""}
                errorMessage={errorInField.get("AssetId")}
                onChangeWithValidation={handleChangeActivity}
                getNewValueBasedOnAction={getNewValueBasedOnAction}
                customerId={customerId}
                disabled={isLoading || isUpdate}
                handleOpenWizard={handleOpenWizard}
                justCreatedItemName={newRelatedActivity.Asset.Name}
                highlightLastOptionInSelect
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {IsCustomerManagement ? (
                <Input
                  autoComplete="off"
                  label="Activity Name"
                  id="Name"
                  value={newRelatedActivity.Name}
                  icon={<CreateOutlinedIcon />}
                  isDisabled={isLoading}
                  error={errorInField.has("Name")}
                  errorText={errorInField.get("Name")}
                  onChange={handleChangeActivity}
                />
              ) : (
                <Combobox
                  hasError={errorInField.get("Name")}
                  label="Activity Name"
                  options={options}
                  handleChange={onChangeActivityField}
                  value={activityFieldValue}
                  creatable={true}
                  required
                  disabled={isLoading || isUpdate}
                  id={"Name"}
                  loading={loading}
                  icon={<EditOutlined />}
                />
              )}
            </Grid>
          </Grid>
        </InputRow>
      </section>
    );
  }

  return (
    <section className="Section Section_defaultBackground Section_withBorder">
      <InputRow alignItems="flex-end" actionZoneComponent={renderAddButton}>
        <Input
          autoComplete="off"
          label="Activity Name"
          id="Name"
          value={newRelatedActivity.Name}
          icon={<CreateOutlinedIcon />}
          isDisabled={isLoading}
          error={errorInField.has("Name")}
          errorText={errorInField.get("Name")}
          onChange={handleChangeActivity}
        />
      </InputRow>
      <ActivitiesDateSettings
        newRelatedActivity={newRelatedActivity}
        updateRelatedActivity={updateRelatedActivity}
      />
    </section>
  );
}

const mapStateToProps = (state) => {
  const offset = (status) =>
    FILTERING_DETAILS.get(status.Name) && FILTERING_DETAILS.get(status.Name)["selected"];
  return {
    filterString: (status) => {
      const filterObj = {
        id: status.Id,
        name: status.Name,
        offset: getActivityFilters(state).get(status.Id) || offset(status),
      };
      return getFilterString(filterObj);
    },
  };
};

export default connect(mapStateToProps)(
  React.memo(
    AddNewActivitySection,
    (prevProps, nextProps) => prevProps.newRelatedActivity === nextProps.newRelatedActivity,
  ),
);
