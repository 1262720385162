import { AssetType, FieldType, ItemTypeField } from "./AssetTypeModel";
import { DEFAULT_ICON } from "../../../_constant/Icons";
import { Image } from "./ImageModel";

const getActivitiesLength = (activities: any[] | null): number => {
  if (!activities) return 0;
  return activities.length;
};

export interface FieldsSchemaTypes {
  Id: string;
  Name: string;
  FieldType: string;
  Order: number;
  __typename?: string;
}

interface AssetFromResponse extends AssetModel {
  AssetActivities?: any[] | null;
}

export class AssetModel {
  AssetType: AssetType;
  Id: string;
  Name: string;
  Description: string;
  DefaultDescription?: string;
  Images: Image[];
  Documents: any[];
  Videos: any[];
  Files: any;
  hasRelatedActivities: boolean;
  CustomFields: any;
  Schema: FieldsSchemaTypes[];
  CustomerId: string | null;
  TypeFieldValues: { [key: string]: any };
  Tags: string[];
  ParentId: string | null;
  ComponentIds: string[];
  _activitiesLength?: number;

  constructor(
    {
      AssetType = {
        Id: "",
        Name: "",
        IconName: DEFAULT_ICON,
        ItemTypeFields: [] as ItemTypeField[],
      } as AssetType,
      Id = "",
      Name = "",
      Description = "",
      DefaultDescription = "",
      Images = [] as Image[],
      Documents = [] as any[],
      Videos = [] as any[],
      Files = [] as any,
      AssetActivities = [],
      CustomFields = {} as any,
      Schema = [] as FieldsSchemaTypes[],
      hasRelatedActivities = null,
      CustomerId = null,
      TypeFieldValues = {},
      Tags = [] as string[],
      ParentId = null,
      ComponentIds = [],
      _activitiesLength = null,
    } = {} as AssetFromResponse,
  ) {
    this.AssetType = {
      Id: AssetType.Id,
      Name: AssetType.Name,
      IconName: AssetType.IconName || DEFAULT_ICON,
      ItemTypeFields: (AssetType.ItemTypeFields as ItemTypeField[]) || [],
    } as AssetType;
    this.Id = Id ?? "";
    this.Name = Name ?? "";
    this.Description = Description ?? "";
    this.DefaultDescription = DefaultDescription ?? "";
    this.Images = Images ?? ([] as Image[]);
    this.Documents = Documents ?? ([] as any[]);
    this.Videos = Videos ?? ([] as any[]);
    this.Files = Files ?? ([] as any);
    this.CustomFields = CustomFields ?? ({} as any);
    this.Schema = Schema ?? ([] as FieldsSchemaTypes[]);
    this.hasRelatedActivities =
      hasRelatedActivities ?? (!!AssetActivities && AssetActivities.length > 0);
    this.CustomerId = CustomerId ?? null;
    this.TypeFieldValues = TypeFieldValues ?? {};
    this.Tags = Tags ?? [];
    this.ParentId = ParentId ?? null;
    this.ComponentIds = ComponentIds ?? [];
    this._activitiesLength = _activitiesLength ?? getActivitiesLength(AssetActivities);
  }
}
