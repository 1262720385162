import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { AccountModel } from "../../redux/models/data/AccountModel";

import { getAccountRefsList } from "../Accounts/getAccountList.query";

import { ACCOUNT_REFS, USER } from "../../_constant/wordings";

export const createAccountRef = (account: AccountModel) => {
  return {
    Id: account.Id,
    Name: account.Name,
    Description: account.Description,
    Images: account.Images,
    IsCustomerManagement: account.IsCustomerManagement,
    OwnerId: account.OwnerId,
    __typename: "AccountRefType",
  };
};

export const updateCacheWithNewAccount = (cache: DataProxy, account: AccountModel) => {
  try {
    const accountListQuery: any = cache.readQuery({ query: getAccountRefsList });
    const res = {
      User: {
        ...accountListQuery[USER],
        AccountRefs: [...accountListQuery[USER][ACCOUNT_REFS], createAccountRef(account)],
      },
    };

    cache.writeQuery({
      query: getAccountRefsList,
      data: res,
    });
  } catch (e) {
    console.error(e);
  }
};
