import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../../_constant/screens";

import {
  EmailOutlined,
  PhoneOutlined,
  LocationOnOutlined,
  LanguageOutlined,
} from "@material-ui/icons";
import { Avatar, Box, ListItemText } from "@material-ui/core";

import { VendorDetails } from "../VendorDetails/VendorDetails";

import { ListItemProps } from "../VendorList.types";

import { StyledAvatarWrapper, StyledListItem } from "../VendorsList.style";

export const ListItem: React.FC<ListItemProps> = ({
  title,
  img,
  email,
  phone,
  address,
  website,
  vendorId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dataTitle, setdataTitle] = useState<string | null>("");
  const menuItems = [
    {
      id: `${title}-1`,
      icon: <EmailOutlined color="primary" />,
      text: email.length ? email : "Email",
    },
    {
      id: `${title}-2`,
      icon: <PhoneOutlined color="primary" />,
      text: phone.length ? phone : "Phone",
    },
    {
      id: `${title}-3`,
      icon: <LocationOnOutlined color="primary" />,
      text: address.length ? address : "Address",
    },
    {
      id: `${title}-4`,
      icon: <LanguageOutlined color="primary" />,
      text: website.length ? website : "Website",
    },
  ];

  useEffect(() => {
    if (anchorEl && anchorEl.getAttribute("data-title")) {
      setdataTitle(anchorEl.getAttribute("data-title"));
    }
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e.preventDefault();
    setAnchorEl(null);
  };
  const copyToClipboard = (event: React.MouseEvent<HTMLButtonElement>, id?: string) => {
    event.preventDefault();

    switch (anchorEl?.id ? anchorEl?.id : id) {
      case `${title}-1`:
        window.navigator.clipboard.writeText(email);
        break;
      case `${title}-2`:
        window.navigator.clipboard.writeText(phone);
        break;
      case `${title}-3`:
        window.navigator.clipboard.writeText(address);
        break;
      case `${title}-4`:
        window.navigator.clipboard.writeText(website);
        break;
    }
    handleClose(event);
  };
  const disableButton = (id: string) => {
    switch (id) {
      case `${title}-1`:
        return !Boolean(email.length);
      case `${title}-2`:
        return !Boolean(phone.length);
      case `${title}-3`:
        return !Boolean(address.length);
      case `${title}-4`:
        return !Boolean(website.length);
      default:
        return false;
    }
  };

  return (
    <StyledListItem>
      <Link to={`${ROUTES.SINGLE_VENDOR}/${vendorId}`}>
        <Box flexGrow="1" display="flex" alignItems="center" minWidth="0">
          <StyledAvatarWrapper>
            <Avatar src={img} variant="rounded" />
          </StyledAvatarWrapper>
          <ListItemText primary={title} />
          <VendorDetails
            vendorTitle={title}
            menuItems={menuItems}
            anchorEl={anchorEl}
            dataTitle={dataTitle}
            copyToClipboard={copyToClipboard}
            disableButton={disableButton}
            handleClick={handleClick}
            handleClose={handleClose}
          />
        </Box>
      </Link>
    </StyledListItem>
  );
};
