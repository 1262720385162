import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-apollo";
import { getUserInfoQuery } from "../../profile/profile.query";
import { UserInfoModel } from "../../../redux/models/data/UserInfoModel";
import { ACCOUNT_REFS, USER } from "../../../_constant/wordings";
import { composeImageUrl } from "../../../_utils/utils";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { AccountModel } from "../../../redux/models/data/AccountModel";
import { getAccountRefsList } from "../../../pages/Accounts/getAccountList.query";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import Avatar from "@material-ui/core/Avatar/Avatar";
import { Hidden, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountPopup from "../../../components/AccountPopup/AccountPopup";
import { useStaticDataQuery } from "../../../queries/useStaticDataQuery";

interface HeaderAccountProps {
  isMobileVersion?: boolean;
}

const HeaderAccount: React.FC<HeaderAccountProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState<string>("");
  const [currentAccountName, setCurrentAccountName] = useState<string | null>("");

  const { accountId } = useGetAccountId();
  const staticData = useStaticDataQuery();

  useEffect(() => {
    if (accountId) {
      setCurrentAccountId(accountId);
    }
  }, [accountId]);

  const { data, loading, error } = useQuery(getUserInfoQuery, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !accountId,
  });

  const { data: accountList, loading: accountsLoading, error: accountsError } = useQuery(
    getAccountRefsList,
    {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
      skip: !accountId,
    },
  );
  useEffect(() => {
    if (!accountsLoading && !accountsError && accountList && accountId) {
      const account: AccountModel = accountList[USER][ACCOUNT_REFS].find(
        (element: AccountModel) => element.Id === accountId,
      );
      setCurrentAccountName(account ? account.Name : "");
    }
  }, [accountList, accountId]);

  if (error) {
    if (error.networkError) {
      console.log(error);
    }
  }

  const closeAlert = () => {
    setIsExpanded(false);
  };

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const profileData =
    !loading && !error && data ? new UserInfoModel(data[USER]) : new UserInfoModel();
  const image = profileData.Images && profileData.Images[0];
  const accounts = accountList && accountList[USER][ACCOUNT_REFS];
  const defaultUserIcon = staticData ? staticData.User.User_default_icon : "";

  return (
    <>
      <div className="Account">
        <Hidden xsDown>
          <div className="Account_link" onClick={handleClick}>
            {loading ? (
              <Skeleton animation="wave" variant="circle" className="Account_ico" />
            ) : (
              <Avatar
                className="Account_ico"
                alt="avatar"
                src={
                  image && image.Id
                    ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
                    : defaultUserIcon
                }
              />
            )}
            <Typography variant="body1" className="Account__currentAccountInfo" noWrap>
              {loading || accountsLoading ? (
                <Skeleton animation="wave" className="Account__currentAccountInfo_default" />
              ) : (
                currentAccountName
              )}
            </Typography>
            <IconButton size="small" style={{ marginLeft: "8px" }}>
              <ExpandMoreIcon
                className={`${
                  isExpanded
                    ? "Account__toggleIcon Account__toggleIcon_open"
                    : "Account__toggleIcon Account__toggleIcon_close"
                }`}
              />
            </IconButton>
          </div>
        </Hidden>
        <Hidden smUp>
          {loading ? (
            <Skeleton animation="wave" variant="circle" className="Account_ico" />
          ) : (
            <IconButton onClick={handleClick}>
              <Avatar
                alt="avatar"
                src={
                  image && image.Id
                    ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
                    : defaultUserIcon
                }
              />
            </IconButton>
          )}
        </Hidden>
        <AccountPopup
          isExpanded={isExpanded}
          isMobileVersion={props.isMobileVersion}
          userLoading={loading}
          accountsLoading={accountsLoading}
          User={profileData}
          accountList={accounts}
          currentAccountId={currentAccountId}
          handleClick={handleClick}
          closeAlert={closeAlert}
        />
      </div>
    </>
  );
};

export default HeaderAccount;
