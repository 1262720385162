import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
// @ts-ignore
import { TouchTransition, MouseTransition } from "dnd-multi-backend";

// @ts-ignore
const hasNative = document && (document.elementsFromPoint || document.msElementsFromPoint);

function getDropTargetElementsAtPoint(x: number, y: number, dropTargets: any) {
  return dropTargets.filter((t: any) => {
    const rect = t.getBoundingClientRect();

    return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top;
  });
}

export default {
  backends: [
    {
      backend: HTML5Backend,
      preview: true,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      options: {
        delayTouchStart: 200,
        enableMouseEvents: true,
        ignoreContextMenu: true,
        getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
      },
      preview: true,
      transition: TouchTransition,
    },
  ],
};
