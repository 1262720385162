import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useGetCustomerIdFromUrl from "../../../customHooks/useGetCustomerId";
import useCreateActivityMutation from "../../../customHooks/useCreateActivityMutation";
import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";
import useGetQueryParam from "../../../customHooks/useGetQueryParams";
import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";

import ActivityForm from "../_components/ActivityForm";

import { ActivityModel } from "../../../redux/models/data/ActivityModelFull";

import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";

import { ROUTES } from "../../../_constant/screens";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { ACTIVITY_POST, FILE_UPLOAD_RESULTS, ID } from "../../../_constant/wordings";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../assets/existing/EditAsset";

import { setEditFormIsDirty, setSelectedAssets } from "../../../redux/actions/actions";
import { useIsItemsExistsForForm } from "../../../customHooks/useIsItemsExistsForForm";
import { formatDateForSubmit } from "../../../_utils/date";

interface NewActivityFormProps {}

const NewActivityForm: React.FC<NewActivityFormProps> = (props) => {
  const statuses = useGetAcitvitiesStatuses();
  const navigate = useNavigate();
  const [activity, setActivity] = useState(new ActivityModel());
  const dispatch = useDispatch();
  const getQueryParam = useGetQueryParam();

  const { setHeaderOptions } = useHeaderOptions({
    pageTitle: "Create New Activity",
    withBackButton: true,
  });
  const [createActivity, { isActivityCreating }] = useCreateActivityMutation();
  const customerId = useGetCustomerIdFromUrl();

  const { exists, isDataAvailable } = useIsItemsExistsForForm();

  useEffect(() => {
    const vendor = getQueryParam("vendor");

    if (vendor.length) {
      setActivity((prevActivity) => {
        const prevVendorRefs = prevActivity?.VendorRefs ?? [];
        return new ActivityModel({
          ...prevActivity,
          VendorRefs: [...prevVendorRefs, { Id: vendor }],
        });
      });
    }
  }, []);

  useEffect(() => {
    if (isDataAvailable && !exists) {
      navigate(ROUTES.ASSETS, { replace: true });
    }
  }, [exists, isDataAvailable]);

  useEffect(() => {
    const item = getQueryParam("item");

    const resultActivity = {} as ActivityModel;

    if (statuses.length > 0) {
      if (item.length) {
        // if we have parameter in URL
        resultActivity.AssetId = item ?? "";
      }
      resultActivity.StatusId = statuses[0].Id;
      setActivity((prevActivity) => new ActivityModel({ ...prevActivity, ...resultActivity }));
    }
  }, [statuses]);

  const handleSubmit = (dataForCreate: ActivityModel, saveType: SaveType) => {
    const formatDueDateTime = formatDateForSubmit(dataForCreate?.DueDateTime as string);
    const dataForPost: any = { ...dataForCreate, DueDateTime: formatDueDateTime };
    createActivity(dataForPost)
      .then(({ data }) => {
        const uploadResult = data[ACTIVITY_POST]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[ACTIVITY_POST][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new ActivityModel(data[ACTIVITY_POST]));
          displaySuccessNotification(NotificationTypes.ACTIVITY_CREATED);
          const currentActivityPath = `${ROUTES.SINGLE_ACTIVITY}/${data[ACTIVITY_POST][ID]}`;
          navigate(currentActivityPath, {
            state: { justCreated: true },
          });
        } else if (data[ACTIVITY_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new ActivityModel(data[ACTIVITY_POST]));
          displaySuccessNotification(NotificationTypes.ACTIVITY_CREATED);
          dispatch(setSelectedAssets());
          customerId ? navigate(-1) : navigate(ROUTES.ACTIVITIES);
        } else {
          displayErrorNotification(NotificationTypes.ACCOUNT_CREATE_ERROR);
        }
      })
      .catch(() => displayErrorNotification(NotificationTypes.ACTIVITY_CREATE_ERROR));
  };

  return (
    <ActivityForm
      {...props}
      isActivitySaving={isActivityCreating}
      handleSubmit={handleSubmit}
      activityData={activity}
      isNew
      setHeaderOptions={setHeaderOptions}
    />
  );
};

export default NewActivityForm;
