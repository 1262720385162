import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

import Divider from "@material-ui/core/Divider/Divider";

import MembersFilterList from "./MembersFilterList";
import CustomersFilterList from "./CustomersFilterList";

import { StyledDrawer } from "./FilterVisitDesktop.style";

const FilterVisitDesktop: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  if (!isDesktop) {
    return null;
  }

  return (
    <StyledDrawer anchor="left" variant="permanent">
      <MembersFilterList />
      <Divider />
      <CustomersFilterList />
    </StyledDrawer>
  );
};

export default FilterVisitDesktop;
