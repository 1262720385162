import React, { useCallback, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "@material-ui/core";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { Image } from "../../redux/models/data/ImageModel";

import { getSearchStringValue } from "../../redux/reducers/filterReducer";
import { alphabetNameSorting } from "../../_utils/utils";
import { getCustomersQuery } from "./customersList.query";

import { ROUTES } from "../../_constant/screens";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import Grid from "@material-ui/core/Grid";

import Modal from "../../containers/Modal/Modal";
import TableCard from "../TableComponent/TableCard";
import TableHeader from "../TableComponent/TableHeader";
import TableCardSkeleton from "../TableComponent/TableCardSkeleton";
import DeleteCustomerDialog from "../DeleteCustomerDialog/DeleteCustomerDialog";

import { NoCustomersComponent } from "../NoResultComponent/NoResultComponent";

import { TableContentStyled } from "../TableComponent/Table.styles";

type Customer = {
  Id: string;
  Email: string;
  Name: string;
  Address: string;
  Phone: string;
  Images: Image[];
};

export interface RemoveDialogState {
  isOpen: boolean;
  id: null | string;
  name: string;
  isLoading: boolean;
}

interface CustomersTableContentProps {
  customers: Customer[];
}
const CustomersTableContent: React.FC<CustomersTableContentProps> = ({ customers }) => {
  const [deleteState, setDeleteState] = useState<RemoveDialogState>({
    isOpen: false,
    id: null,
    name: "",
    isLoading: false,
  });

  const openDeleteDialog = useCallback((id: string, name: string) => {
    setDeleteState((prev) => ({ ...prev, isOpen: true, isLoading: true, id, name }));
  }, []);
  const closeDeleteDialog = useCallback(
    () => setDeleteState((prev) => ({ ...prev, isOpen: false, isLoading: false })),
    [],
  );
  const stopLoading = useCallback(
    () => setDeleteState((prev) => ({ ...prev, isLoading: false })),
    [],
  );

  const searchString: string = useSelector(getSearchStringValue, shallowEqual);

  const filteredCustomers = customers.sort(alphabetNameSorting).filter((customer) => {
    return (
      customer.Name.toLowerCase().includes(searchString.toLowerCase()) ||
      customer.Address.toLowerCase().includes(searchString.toLowerCase()) ||
      customer.Email.toLowerCase().includes(searchString.toLowerCase()) ||
      customer.Phone.toLowerCase().includes(searchString.toLowerCase())
    );
  });

  return (
    <TableContentStyled container item>
      {filteredCustomers.map((customer: Customer) => {
        const options = [
          // {
          //   text: "Duplicate",
          //   icon: <FileCopyOutlinedIcon fontSize="small" />,
          //   callback: () => {},
          // },
          {
            text: "Delete",
            icon: <DeleteOutlinedIcon fontSize="small" />,
            callback: () => openDeleteDialog(customer.Id, customer.Name),
          },
        ];

        const removeLoading = deleteState.id === customer.Id && deleteState.isLoading;
        const isDialogOpen = deleteState.id === customer.Id && deleteState.isOpen;
        return (
          <>
            <TableCard
              key={customer.Id}
              data={customer}
              navigatePath={ROUTES.SINGLE_CUSTOMER}
              isLoading={removeLoading}
              moreActionOptions={options}
            />
            {isDialogOpen && (
              <Modal isShowing={isDialogOpen} key={`${customer.Id}modal`}>
                <DeleteCustomerDialog
                  customerId={customer.Id}
                  customerName={customer.Name}
                  handleCancel={closeDeleteDialog}
                  handleFinishPopupLoading={stopLoading}
                />
              </Modal>
            )}
          </>
        );
      })}
    </TableContentStyled>
  );
};

interface CustomersProps {}

const AllCustomersWrapper: React.FC<CustomersProps> = () => {
  const xsUp = useMediaQuery("(min-width:600px)", { noSsr: true });

  const { accountId } = useGetAccountId();

  const { data, loading, error } = useQuery(getCustomersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
  });

  if (!loading && !data?.Account.Customers.length) {
    return <NoCustomersComponent />;
  }

  return (
    <Grid container style={{ marginBottom: 30, paddingTop: "1rem" }}>
      {xsUp && <TableHeader entityName="Customer" />}
      {loading && <TableCardSkeleton count={2} />}
      {data && <CustomersTableContent customers={data.Account.Customers} />}
    </Grid>
  );
};

export default AllCustomersWrapper;
