import { ExecutionResult } from "graphql";
import { useMutation } from "react-apollo";
import { useSelector } from "react-redux";
import { createProjectMutation } from "../pages/NewProject/createProject.query";
import { updateCacheWithNewProject } from "../pages/NewProject/updateCacheWithNewProject";
import { convertStatusToFilterVariable } from "../redux/actions/_utils";
import { ProjectModel } from "../redux/models/data/ProjectModel";
import { getActivityFilters } from "../redux/reducers/appReducer";
import useGetProjectsStatuses from "./api/useGetProjectsStatuses";
import useGetAccountId from "./useGetAccountId";

const useCreateProjectMutation = (): [
  () => Promise<ExecutionResult<any>>,
  { isProjectCreating: boolean },
] => {
  const [createProjectQuery, { loading: isProjectCreating }] = useMutation(createProjectMutation);
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const statuses = useGetProjectsStatuses();

  const projectFiltersMap = useSelector((state) => getActivityFilters(state));

  const createProject = (variables) => {
    if (!statuses) {
      return;
    }
    const currentStatus = statuses.find(
      (element) => element.Id === variables.Project.ProjectStatusId,
    );

    const filterString = convertStatusToFilterVariable(
      currentStatus,
      projectFiltersMap,
      "ProjectStatusId",
    );

    return createProjectQuery({
      variables,
      update(cache, { data: { ProjectPost } }) {
        updateCacheWithNewProject(cache, ProjectPost, filterString);
      },
    });
  };

  return [createProject, { isProjectCreating }];
};

export default useCreateProjectMutation;
