import * as React from "react";
import { connect } from "react-redux";
import { useQuery } from "react-apollo";
import { ReactNode, useState, useEffect } from "react";
import { setSelectedAssets } from "../../../redux/actions/actions";
import { getSelectedAssetIds, getSelectedAssetType } from "../../../redux/reducers/filterReducer";
import { alphabetNameSorting, composeImageUrl } from "../../../_utils/utils";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import { GET_DETAILED_ITEM_TYPE_LIST } from "./asset-type.query";
import Submenu from "./Submenu";
import { ACCOUNT, ASSET_REFS } from "../../../_constant/wordings";
import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";
import FilterButton from "../../../components/FIlterButton/FilterButton";
import { GET_ASSET_REFS } from "../../assets/all/assets.query";

interface SideMenuProps {
  selectedAssetType: string;
  selectedAssetIds: Set<string>;
  setFilter: (assetType?: string, assets?, relatedItems?) => void;
  subMenuExpanded: boolean;
  children?: ReactNode;
}

export const getItem = (item) => ({
  Id: item.Id,
  Name: item.Name,
  MainImage: item.MainImage,
  ImageUrl:
    item.MainImage &&
    composeImageUrl(item.MainImage.Id, item.MainImage.ContainerName, item.MainImage.ContainerId),
});

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const [assetTypes, setAssetTypesData] = useState([]);
  const [items, setItems] = useState(new Map());
  const [expanded, setExpanded] = useState(false);
  const { IsCustomerManagement } = useGetAccountId();

  useEffect(() => {
    setExpanded(props.subMenuExpanded);
  }, [props.subMenuExpanded]);

  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(GET_DETAILED_ITEM_TYPE_LIST, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-first",
  });

  const { data: assetsData, loading: assetsLoading, error: assetsError } = useQuery(
    GET_ASSET_REFS,
    {
      variables: {
        Id: accountId,
      },
      skip: !accountId,
      fetchPolicy: "cache-first",
    },
  );

  useEffect(() => {
    if (!loading && !error && data) {
      const newAssetTypes = (data[ACCOUNT]["AssetTypes"] || []).sort(alphabetNameSorting);
      setAssetTypesData(newAssetTypes);
    }
  }, [data]);

  useEffect(() => {
    if (assetsData && assetsData?.[ACCOUNT]?.[ASSET_REFS]) {
      const itemsData = new Map();
      assetsData[ACCOUNT][ASSET_REFS] &&
        assetsData[ACCOUNT][ASSET_REFS].forEach((item: AssetRefsModel) => {
          itemsData.set(item.AssetTypeRef.Id, [
            getItem(item),
            ...(itemsData.get(item.AssetTypeRef.Id) || []),
          ]);
        });
      setItems(itemsData);
    }
  }, [assetsData]);

  const handleSubmenuItemSelected = (type: string, keys: Set<string>) => {
    if (keys.size !== 0) {
      props.setFilter(type, keys);
      return;
    }
    if (props.selectedAssetType === "") {
      props.setFilter();
    } else {
      props.setFilter(
        props.selectedAssetType,
        new Set(),
        (items.get(type) || []).map((item) => item.Id),
      );
    }
  };

  const handleAssetTypeSelected = ({ target }) => {
    const nextKey = target.getAttribute("data-item-type");
    if (props.selectedAssetType !== nextKey) {
      props.setFilter(
        nextKey,
        new Set(),
        items.get(nextKey).map((item) => item.Id),
      );
    }
    setExpanded(true);

    if (expanded && props.selectedAssetType === nextKey) {
      props.setFilter();
    }
  };

  const renderMenuItems = () => {
    const menuItems = [];
    assetTypes.forEach((assetType, key) => {
      if (items.get(assetType.Id)) {
        // hide if empty
        const className = `SideMenu_types${
          props.selectedAssetType === assetType.Id ? "-selected" : ""
        }`;
        menuItems.push(
          <div
            className={className}
            data-item-type={assetType.Id}
            key={key}
            onClick={handleAssetTypeSelected}
          >
            <i className="Icon">{assetType.IconName}</i>
          </div>,
        );
      }
    });
    return menuItems;
  };

  if (IsCustomerManagement) {
    return null;
  }

  return (
    <>
      <div className="SideMenu">
        <FilterButton filterOpen={expanded} setFilterOpen={setExpanded} />
        <div className={"SideMenu_nav"}>{renderMenuItems()}</div>
      </div>
      <Submenu
        className={expanded ? "is-active" : ""}
        assetTypes={
          !!props.selectedAssetType
            ? assetTypes.filter((type) => type.Id === props.selectedAssetType)
            : assetTypes
        }
        items={items}
        selectedKey={props.selectedAssetType}
        selectedAssets={props.selectedAssetIds}
        onClick={handleSubmenuItemSelected}
      />
      <div
        className={`Overlay-withHeader ${expanded ? "is-active" : ""}`}
        onClick={() => setExpanded(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedAssetType: getSelectedAssetType(state),
  selectedAssetIds: getSelectedAssetIds(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, assets?: Set<string>, relatedItems?) =>
    dispatch(setSelectedAssets(assetType, assets, relatedItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
