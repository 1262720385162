import styled from "styled-components";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import { scrollStyles } from "../_styled/scrollStyles";
import ListItem from "@material-ui/core/ListItem";

export const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    min-height: 50%;
    max-height: 65%;
  }
`;

export const StyledDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px;

  .MuiTabs-root {
    margin-left: auto;
  }
`;

export const StyledDrawerHeaderTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
`;

export const StyledResetFilterButton = styled(IconButton)`
  position: relative;
  padding-left: 0;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 10px;
    top: 12px;
    right: 10px;
    transform: rotate(45deg);
    background-color: #98cc45;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 10px;
    top: 12px;
    right: 10px;
    transform: rotate(-45deg);
    background-color: #98cc45;
  }
`;

export const StyledTab = styled(Tab)`
  font-weight: 400;
`;

export const StyledTabPanel = styled.div`
  overflow: scroll;
`;

export const StyledList = styled(List)`
  max-height: 100%;
  ${scrollStyles}
`;

interface ListItemProps {
  $selected: boolean;
}

export const StyledListItem = styled(ListItem)<ListItemProps>`
  background-color: ${({ theme, $selected }) =>
    $selected ? `${theme.palette.primary.light} !important` : "transparent"};

  .MuiListItemAvatar-root {
    width: 24px;
    height: 24px;
    min-width: auto;
    margin-right: 12px;
  }

  .MuiAvatar-root {
    width: 24px;
    height: 24px;
  }
`;
