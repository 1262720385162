import gql from "graphql-tag";

export const moveItemToAnotherAccountQuery = gql`
  mutation moveAssetToAnotherAccount(
    $AssetId: Guid
    $DestinationAccountId: Guid
    $SourceAccountId: Guid
  ) {
    AssetMove(
      AssetId: $AssetId
      DestinationAccountId: $DestinationAccountId
      SourceAccountId: $SourceAccountId
    ) {
      Id
      AssetType {
        Id
        Name
        IconName
        IsSystem
      }
      Name
      Description
      Images {
        Id
        Name
        ContainerId
        ContainerName
      }
      Schema {
        Id
        Name
        Order
        FieldType
      }
      CustomFields
    }
  }
`;
