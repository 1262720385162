import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React from "react";

import FakeItemCard from "../../FakeItemCard/FakeItemCard";
import {
  WizardLoadingContainer,
  WizardLoadingFakeCardContainer,
  WizardLoadingBody,
} from "./WizardLoadingStyles";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

interface WizardLoadingScreenProps {
  isLoading: boolean;
}

const WizardLoadingScreen: React.FC<WizardLoadingScreenProps> = ({ isLoading }) => {
  return (
    <WizardLoadingContainer>
      <WizardLoadingFakeCardContainer>
        <FakeItemCard isLoading />
      </WizardLoadingFakeCardContainer>
      <WizardLoadingBody>
        {true ? (
          "Loading..."
        ) : (
          <>
            <Typography variant="subtitle1">
              Your item has been created with related activities.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Let's keep going!
            </Typography>
            <Button startIcon={<AddOutlinedIcon />} variant="contained" color="primary">
              Item
            </Button>
          </>
        )}
      </WizardLoadingBody>
    </WizardLoadingContainer>
  );
};

export default WizardLoadingScreen;
