import React from "react";
import { useSelector } from "react-redux";
import { Auth0Provider, Auth0ProviderOptions, CacheLocation } from "@auth0/auth0-react";

import { getClientInfo } from "../redux/reducers/appReducer";

const Auth0ProviderCustom: React.FC = ({ children }) => {
  const onRedirectCallback = (appState) => {
    window.history.pushState(
      "",
      "",
      appState && appState.returnTo ? appState.returnTo : window.location.pathname,
    );
  };

  const state = useSelector((state) => getClientInfo(state));

  // Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
  // for a full list of the available properties on the provider

  const providerConfig = {
    domain: state.authUrl,
    clientId: state.clientId,
    audience: state.apiName,
    redirectUri: window.location.origin,
    onRedirectCallback,
    // cacheLocation: "localstorage" as CacheLocation,
    useRefreshTokens: true,
    scope: state.scope,
  } as Auth0ProviderOptions;

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default Auth0ProviderCustom;
