import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../../_constant/screens";
import { COLOR_CODE_STATE } from "../../../_constant/constants";

import { formatDateWithoutTZ } from "../../../_utils/date";
import { composeImageUrl } from "../../../_utils/utils";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Avatar, Box, CardMedia, Grid, Hidden, Typography } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import { CardContainer } from "../../../components/Cards/styled/card.styled";
import MoreAction from "../../../components/MoreAction/MoreAction";
import DeleteProjectDialog from "../../../components/ProjectForm/ProjectDeleteDialog/DeleteProjectDialog";

import { useStaticDataQuery } from "../../../queries/useStaticDataQuery";

import { ActivityItemDate } from "../../activities/_components/item/ActivityItemDate";
import { ProjectModel } from "../../../redux/models/data/ProjectModel";
import AssignmentProjectContainer from "./AssignmentProjectContainer";
import styled from "styled-components";
import { LoaderCircle } from "../../../components/Loader";

interface ProjectItemProps {
  project: ProjectModel;
  dateType: string;
  statusName: string;
  isAccountPersonal: boolean | null;
  style?: {};
  isLoading?: boolean;
  isAssignmentsVisibile?: boolean;
  isDraggable?: boolean;
}

const StyledImage = styled((props) => <Avatar {...props} />)`
  margin-left: 8px;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const ProjectItem: React.FC<ProjectItemProps> = (props) => {
  const {
    project: { isLoading, ...project },
    dateType,
    statusName,
    isAccountPersonal,
    style,
    isDraggable = false,
  } = props;

  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const navigate = useNavigate();

  const staticData = useStaticDataQuery();

  const smUp = useMediaQuery("(min-width: 600px)", { noSsr: true });
  const mdUp = useMediaQuery("(min-width: 960px)", { noSsr: true });

  const options = [
    // {
    //   text: "Duplicate",
    //   icon: <FileCopyOutlinedIcon fontSize="small" />,
    //   callback: () => {},
    // },
    {
      text: "Delete",
      icon: <DeleteOutlinedIcon fontSize="small" />,
      callback: () => setIsDeletePopupVisible(true),
    },
  ];

  const getProjectImageUrl = (images: any[]) => {
    const image = images ? images?.[0] : null;
    if (image) {
      return composeImageUrl(image.Id, image.ContainerName, image.ContainerId);
    }
    return staticData?.default?.["project-card"];
  };

  const goToProject = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let path = `${ROUTES.SINGLE_PROJECT}/${project.Id}`;
    navigate(path);
  };

  return (
    <>
      <CardContainer
        style={isDraggable ? style : { borderBottom: "1px solid #eeeeee" }}
        padding={1}
        borderRadius={isDraggable ? "8px" : "0"}
        onClick={goToProject}
        withLabel={statusName}
      >
        {isLoading && (
          <div className={`ActivityItem_loader`}>
            <LoaderCircle size={"xs"} />
          </div>
        )}
        <StyledImage variant="rounded" src={getProjectImageUrl(project.Images)} alt="avatar" />
        <Grid spacing={1} container alignItems="center" wrap="nowrap">
          <Grid item xs zeroMinWidth>
            <Box paddingLeft="4px">
              <Typography variant="body2" component="h3" noWrap>
                {project.Name}
              </Typography>
            </Box>
          </Grid>
          {!isAccountPersonal && !isDraggable && (
            <Hidden smDown>
              <Grid item xs={3}>
                <AssignmentProjectContainer
                  assignedUserId={project?.AssignedUserId}
                  projectId={project?.Id}
                  name={project?.Name}
                />
              </Grid>
            </Hidden>
          )}
          <Grid item xs={mdUp ? 4 : false}>
            <Grid item container alignItems="center" justify="flex-end" spacing={1} wrap="nowrap">
              <Grid item style={{ minWidth: smUp ? 110 : 0 }}>
                <ActivityItemDate
                  activityDate={formatDateWithoutTZ(project?.[dateType])}
                  dateType={dateType}
                  iconOnly={!smUp}
                  isCancelled={statusName === COLOR_CODE_STATE.cancelled}
                />
              </Grid>

              <Grid item>
                <MoreAction options={options} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContainer>
      {isDeletePopupVisible && (
        <DeleteProjectDialog
          id={project.Id ?? null}
          handleCancel={() => setIsDeletePopupVisible(false)}
          statusId={project.ProjectStatusId ?? null}
          withoutRedirect
        />
      )}
    </>
  );
};

export default ProjectItem;
