import { useQuery } from "@apollo/react-hooks";

import { getUserInfoQuery } from "../containers/profile/profile.query";

import { USER, IS_PREVIEW } from "../_constant/wordings";

const useGetIsPreview = () => {
  const { data, loading: loadingIsPreview } = useQuery(getUserInfoQuery, {
    fetchPolicy: "cache-first",
  });

  return { isPreview: data?.[USER]?.[IS_PREVIEW], loadingIsPreview };
};

export default useGetIsPreview;
