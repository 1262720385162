import React, { ReactElement, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import useGetUserId from "../../customHooks/useGetUserId";
import { useEntityTabsContext } from "../EntityTabsContainer/useEntityTabsContext";

import { Users } from "../../redux/models/data/UsersModel";

import { UserRole } from "../../_constant/constants";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { AllInclusive } from "@material-ui/icons";

import List from "@material-ui/core/List/List";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography/Typography";
import { Box, Button, ListItem, ListItemAvatar } from "@material-ui/core";

import AccountUsersItem from "../AccountUsersItem/AccountUsersItem";

import { StyledTabdHeader } from "./AccountUsersTab.style";
import "./AccountUsers.scss";

export const getAccountUsersQuery = gql`
  query getAccountUsers($Id: ID) {
    Account(Id: $Id) {
      Id
      UserRefs {
        Id
        FirstName
        LastName
        Email
        MainImage {
          Id
          ContainerId
          ContainerName
        }
        Role
      }
    }
  }
`;

interface AccountUsersProps {
  accountId: string;
  maxMembers?: { Value: number; IsLimited: boolean } | null;
}

const InviteMemberButton: React.FC = () => {
  const { changeTab } = useEntityTabsContext();
  return (
    <Box mx={2} my={3}>
      <Button
        onClick={() => changeTab("1")}
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        size="small"
      >
        Invite member
      </Button>
    </Box>
  );
};

const AccountUsersTab: React.FC<AccountUsersProps> = ({ accountId, maxMembers }): ReactElement => {
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const userId = useGetUserId();

  const { data, loading, error } = useQuery(getAccountUsersQuery, {
    variables: { Id: accountId },
    skip: !accountId,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && !error && data) {
      const currentUser = data?.Account?.UserRefs.find((user: Users) => user.Id === userId);
      setIsOwner(currentUser?.Role === UserRole.owner);
    }

    return () => {};
  }, [data]);

  if (loading || !accountId) {
    return (
      <>
        <Box m={3}>
          <StyledTabdHeader
            title={
              <Typography variant="subtitle1" component="h3">
                Maximum number of members
              </Typography>
            }
            subheader={null}
          />
          <List className="AccountUsers" disablePadding>
            <ListItem className="AccountUserItem">
              <ListItemAvatar className="AccountUserItem__ListItemAvatar">
                <Skeleton animation="wave" variant="circle" width={24} height={24} />
              </ListItemAvatar>
              <Skeleton animation="wave" height={12} width="100%" />
            </ListItem>
          </List>
        </Box>
        <InviteMemberButton />
      </>
    );
  }
  if (error) return <div>{error.message}</div>;

  if (data && data.Account && (!data.Account.UserRefs || data.Account.UserRefs.length === 0))
    return <InviteMemberButton />;

  return (
    <>
      <Box m={3}>
        <StyledTabdHeader
          title={
            <Typography variant="subtitle1" component="h3">
              Maximum number of members
            </Typography>
          }
          subheader={
            maxMembers?.IsLimited === false ? <AllInclusive color="primary" /> : maxMembers?.Value
          }
        />
        <List className="AccountUsers" disablePadding>
          {data &&
            data.Account &&
            data.Account.UserRefs.map((user: Users, index: number) => (
              <AccountUsersItem
                key={user.Id}
                accountUser={user}
                isOwner={isOwner}
                accountId={accountId}
              />
            ))}
        </List>
      </Box>
      <InviteMemberButton />
    </>
  );
};

export default AccountUsersTab;
