import * as React from "react";
import { HEADER_TYPES } from "../../_constant/constants";

export interface saveOptionsInterface {
  isLoading: boolean;
  disabled: boolean;
  saveFunction: any;
  revertFunction?: () => void;
  saveAndClose?: () => void;
  delete?: () => void;
  getDate?: () => void;
}

type HeaderType = "DEFAULT" | "BUSINESS";

export class HeaderOptionsModel {
  filterOpen?: boolean;
  pageTitle?: string;
  withBackButton?: boolean;
  withSettingsNav?: boolean;
  withAddButton?: boolean;
  addButtonCallback?: () => void;
  headerType?: HeaderType;
  saveOptions?: saveOptionsInterface;
  isFormDirty?: boolean;
  isPromptAvailable?: boolean;
  withoutMenuButton?: boolean;

  constructor({
    filterOpen = false,
    pageTitle = "",
    withBackButton = false,
    withSettingsNav = false,
    withAddButton = false,
    addButtonCallback = undefined,
    headerType = HEADER_TYPES.DEFAULT as HeaderType,
    saveOptions = { isLoading: false, disabled: false, saveFunction: null },
    isFormDirty = false,
    isPromptAvailable = true,
    withoutMenuButton = false,
  } = {}) {
    this.filterOpen = filterOpen;
    this.pageTitle = pageTitle;
    this.withBackButton = withBackButton;
    this.withSettingsNav = withSettingsNav;
    this.withAddButton = withAddButton;
    this.addButtonCallback = addButtonCallback;
    this.headerType = headerType;
    this.saveOptions = saveOptions;
    this.isFormDirty = isFormDirty;
    this.isPromptAvailable = isPromptAvailable;
    this.withoutMenuButton = withoutMenuButton;
  }
}
