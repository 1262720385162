import { useContext, useEffect } from "react";
import { HeaderOptionsContext } from "./HeaderOptionsProvider";
import { HeaderOptionsModel } from "./HeaderOptionsModel";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

export const useHeaderOptions = (dataToSet?: HeaderOptionsModel) => {
  const context = useContext(HeaderOptionsContext);
  const { pathname } = useLocation();
  const matches = useMediaQuery("(min-width:600px)", { noSsr: true });

  if (context === undefined) {
    throw new Error("useHeaderOptions can only be used inside HeaderOptionsProvider");
  }

  const setHeaderOptions = (data?: HeaderOptionsModel) => {
    if (data) {
      context.setHeaderOptions((prevState) => new HeaderOptionsModel({ ...prevState, ...data }));
    }
  };

  useEffect(() => {
    if (dataToSet) {
      setHeaderOptions(dataToSet);
    }

    return () => setHeaderOptions(new HeaderOptionsModel());
  }, [pathname, matches]);

  return { headerOptions: context.headerOptions as HeaderOptionsModel, setHeaderOptions };
};
