import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES } from "../../_constant/screens";

import { isDebugInfoEnabled } from "../../redux/reducers/appReducer";

import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useMediaQuery, useTheme } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemText from "@material-ui/core/ListItemText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  StyledButtonGroup,
  AddItemButtonMobile,
  StyledListItemIcon,
  StyledArrowDropDownIcon,
  StyledPopper,
  MenuItemText,
} from "./AddItemContextMenu.style";

import UploadCsvDialog from "../UploadCsvDialog/UploadCsvDialog";

interface SaveButtonProps {
  handleOpenWizard: VoidFunction;
}

const AddItemContextMenu: React.FC<SaveButtonProps> = ({ handleOpenWizard }) => {
  const [open, setOpen] = React.useState(false);
  const [isCSVUploaderOpen, setIsCSVUploaderOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const mdDown = useMediaQuery("(max-width:800px)", { noSsr: true });
  const navigate = useNavigate();

  const isDev = useSelector(isDebugInfoEnabled);

  const options = [
    {
      text: "Wizard",
      icon: <CreateNewFolderOutlinedIcon fontSize={"small"} />,
      callback: handleOpenWizard,
    },
    {
      text: "One Item",
      icon: <AddOutlinedIcon fontSize={"small"} />,
      callback: () => navigate(ROUTES.CREATE_ASSET),
    },
  ];

  if (isDev) {
    options.push({
      text: "Upload CSV",
      icon: (
        <i className="Icon" style={{ fontSize: "20px" }}>
          upload_file
        </i>
      ),
      callback: () => setIsCSVUploaderOpen(true),
    });
  }

  const handleMenuItemClick = (cb: () => void) => {
    cb();
    setOpen(false);
  };

  const handleClickMainBtn = () => {
    handleOpenWizard();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledButtonGroup variant="contained" color="primary" ref={anchorRef}>
        {smUp && mdDown ? (
          <AddItemButtonMobile
            color="primary"
            startIcon={<AddOutlinedIcon />}
            onClick={handleClickMainBtn}
          />
        ) : (
          <Button color="primary" startIcon={<AddOutlinedIcon />} onClick={handleClickMainBtn}>
            Item
          </Button>
        )}
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <StyledArrowDropDownIcon fontSize={"small"} isOpen={open} />
        </Button>
      </StyledButtonGroup>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={"top-end"}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option) => {
                    return (
                      <MenuItem
                        key={option.text}
                        onClick={() => handleMenuItemClick(option.callback)}
                      >
                        <StyledListItemIcon>{option.icon}</StyledListItemIcon>
                        <ListItemText>
                          <MenuItemText variant="body1" color="primary">
                            {option.text}
                          </MenuItemText>
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
      <UploadCsvDialog isOpen={isCSVUploaderOpen} setIsOpen={setIsCSVUploaderOpen} />
    </>
  );
};

export default AddItemContextMenu;
