import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useGetLimits } from "../../customHooks/useGetLimits";
import { useChange } from "../../customHooks/useChange";

import { VisitModel } from "../../redux/models/data/VisitModel";
import { HeaderOptionsModel } from "../HeaderOptionsProvider/HeaderOptionsModel";

import { SAVE, SAVE_AND_CLOSE, SaveType } from "../../containers/assets/existing/EditAsset";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import { displayWarningNotification } from "../../redux/actions/_utils";

import DropZoneContainer from "../DropZoneContainer/DropZoneContainer";
import VisitFormDesktop from "../VisitForm/VisitFormDesktop";
import VisitFormMobile from "../VisitForm/VisitFormMobile";
import { VisitFormContextProvider } from "../VisitForm/VisitFormContext";

interface VisitFormProps {
  handleSubmit: (data: VisitModel, saveType: SaveType) => void;
  isLoading: boolean;
  isNew: boolean;
  data: VisitModel;
  setHeaderOptions: (props: HeaderOptionsModel) => void;
}

const VisitFormContainer: React.FC<VisitFormProps> = ({
  handleSubmit: submitFunction,
  isLoading,
  isNew,
  data: propsData,
  setHeaderOptions,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const limits = useGetLimits();

  const formInitObject = {
    initData: propsData ? propsData : new VisitModel(),
    classModel: VisitModel,
    requiredFields: ["CustomerId", "Purpose"],
    fieldsForValidation: new Map([["Purpose", null]]),
  };

  const formApi = useChange<VisitModel>(formInitObject);
  const data = formApi.data;
  const lockSaveButton = React.useMemo(() => !!(formApi.isDataChanged && formApi.isDataValid), [
    formApi.isDataChanged,
    formApi.isDataValid,
  ]);

  useEffect(() => {
    if (propsData && !isLoading) {
      formApi.updateInitFormData(new VisitModel(propsData));
    }
  }, [propsData]);

  const handleSubmit = (e?: React.SyntheticEvent<Element, Event> | null, justSave = false) => {
    if (!navigator.onLine) {
      displayWarningNotification(NotificationTypes.LOST_CONNECTION);
      return;
    }
    const saveType: any = {
      type: justSave ? SAVE : SAVE_AND_CLOSE,
      callback: formApi.updateInitFormData,
    };

    submitFunction(formApi.data, saveType);
  };

  const dropZone = React.useMemo(
    () => (
      <DropZoneContainer
        data={data}
        handleChange={formApi.handleChange}
        updateManyFields={formApi.updateManyFields}
        entityName="visit"
        isMobile={!isDesktop}
        imagesOnly={false}
        amountOfMainFiles={0}
        limitDocuments={limits?.visit.documents.Value}
        limitImages={limits?.visit.images.Value}
        limitVideos={limits?.visit.videos.Value}
      />
    ),
    [data.Files, data.Images, data.Documents, data.Videos],
  );

  useEffect(() => {
    setHeaderOptions({
      saveOptions: {
        isLoading: isLoading,
        disabled: !lockSaveButton,
        saveFunction: () => handleSubmit(null, true),
        saveAndClose: handleSubmit,
      },
      isFormDirty: formApi.isDataChanged,
    });
  }, [formApi.data, formApi.isDataChanged, lockSaveButton, isLoading]);

  return (
    <VisitFormContextProvider>
      {isDesktop ? (
        <VisitFormDesktop
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          isNew={isNew}
          data={data}
          setHeaderOptions={setHeaderOptions}
          dropZone={dropZone}
          formData={data}
          formApi={formApi}
          lockSaveButton={lockSaveButton}
        />
      ) : (
        <VisitFormMobile
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          isNew={isNew}
          data={propsData}
          setHeaderOptions={setHeaderOptions}
          formApi={formApi}
          formData={data}
          lockSaveButton={lockSaveButton}
          dropZone={dropZone}
        />
      )}
    </VisitFormContextProvider>
  );
};

export default VisitFormContainer;
