import * as React from "react";

import "./ActivitiesList.scss";

import withScrolling from "react-dnd-scrolling";
import { Container } from "@material-ui/core";

interface ActivitiesListProps {
  listView: boolean;
}

const ScrollingComponent = withScrolling("div");

const ActivitiesList: React.FC<ActivitiesListProps> = ({ children, listView }) => {
  return (
    <ScrollingComponent className={`ActivitiesList${listView ? " ActivitiesList--listView" : ""}`}>
      <Container maxWidth="md">{children}</Container>
    </ScrollingComponent>
  );
};

export default ActivitiesList;
