import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { clearLocalStorageExceptCurrentAccount } from "../_utils/utils";

const StyledButtonContainer = styled.div`
  .MuiButton-root + .MuiButton-root {
    margin-left: 10px;
  }
`;

interface ErrorPageProps {
  title: string;
  message: string;
  buttonText: string;
  buttonClick?: () => void;
  bgImage: "wrong" | "error";
}

interface LocationState {
  error?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const locationState = useLocation<LocationState>();
  const navigate = useNavigate();

  const message = locationState.state?.error ? locationState.state.error : props.message;

  const onSupportButtonClick = (e) => {
    e.preventDefault();
    window.open("https://upkeepr.com/support");
  };

  const pushToMainWithPersist = () => {
    clearLocalStorageExceptCurrentAccount();
    navigate("/");
  };

  return (
    <>
      <div className="ServicePage">
        <section className="ServicePage_section">
          <div className={`ServicePage_background ServicePage_background_${props.bgImage}`} />
          <div className="ServicePage_textContainer">
            <div className="ServicePage_textBlock">
              <h2 className="ServicePage_title">{props.title}</h2>
              <p className="ServicePage_message">{message}</p>
              <StyledButtonContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.buttonClick ?? pushToMainWithPersist}
                >
                  {props.buttonText}
                </Button>
                <Button variant="contained" color="primary" onClick={onSupportButtonClick}>
                  Support
                </Button>
              </StyledButtonContainer>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ErrorPage;
