import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { CircularProgress } from "@material-ui/core";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";
import { NotificationTypes } from "../../_constant/NotificationTypes";

import { ROUTES } from "../../_constant/screens";

import {
  ImageContainer,
  SecondaryButtons,
  StyledButtonContainer,
  StyledInnerButtonContainer,
  StyledResendButton,
} from "./VerifyEmail.style";

const PAGE_TITLE = `Please verify your email\nbefore logging in.`;
const VERIFY_EMAIL_MSG =
  "Check our inbox for a message from Upkeepr\nApp with a link to verify your email.";
const VERIFY_EMAIL_MSG_2 = `If it's not in your inbox, check your Junk or Spam\nfolders and mark it "Not spam" to move it back to\nyour inbox and enable the verification link`;

interface ErrorPageProps {}

const VerifyEmail: React.FC<ErrorPageProps> = (props) => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      if (token) {
        setToken(token);
      }
    });
  }, [getAccessTokenSilently]);

  const handleSupportButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open("https://upkeepr.com/support");
  };

  const handleResendEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!token || isLoading || sendingEmail) return;

    setSendingEmail(true);

    fetch("https://api-dev.upkeepr.com/resend-verification-email", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.ok)
      .then((success) => {
        if (success) {
          displaySuccessNotification(NotificationTypes.SEND_VERIFICATION_EMAIL_SUCCESS);
        } else {
          displayErrorNotification(NotificationTypes.SEND_VERIFICATION_EMAIL_FAILURE);
        }
        setSendingEmail(false);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.SEND_VERIFICATION_EMAIL_FAILURE);
        setSendingEmail(false);
      });
  };

  const handleLogout = () => {
    navigate(ROUTES.LOGOUT);
  };

  return (
    <>
      <div className="ServicePage">
        <section className="ServicePage_section">
          <ImageContainer />
          <div className="ServicePage_textContainer">
            <div className="ServicePage_textBlock">
              <h2 className="ServicePage_title">{PAGE_TITLE}</h2>
              <div className="ServicePage_messageContainer">
                <p className="ServicePage_message">{VERIFY_EMAIL_MSG}</p>
                <p className="ServicePage_message">{VERIFY_EMAIL_MSG_2}</p>
              </div>
              <StyledButtonContainer>
                <StyledResendButton
                  variant="contained"
                  color="primary"
                  onClick={handleResendEmail}
                  disabled={isLoading || sendingEmail}
                >
                  Resend verification email
                  {(isLoading || sendingEmail) && (
                    <CircularProgress size={24} className="SaveButton__loader" />
                  )}
                </StyledResendButton>
                <StyledInnerButtonContainer>
                  <SecondaryButtons
                    variant="outlined"
                    color="primary"
                    onClick={handleSupportButtonClick}
                  >
                    Support
                  </SecondaryButtons>
                  <SecondaryButtons variant="outlined" color="primary" onClick={handleLogout}>
                    Logout
                  </SecondaryButtons>
                </StyledInnerButtonContainer>
              </StyledButtonContainer>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VerifyEmail;
