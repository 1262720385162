import * as React from "react";
import { ActivityModel } from "../../../../redux/models/data/ActivityModelFull";
import Textarea from "../../../../components/Textarea";
import Input from "../../../../components/Input/Input";
import CompletedDateComponent from "../date/completed/CompletedDateComponent";
import DueDateComponent from "../date/due_date/DueDateComponent";
import StatusSelect from "./fields/StatusSelect";
import ParentItemSelect from "./fields/ParentItemSelect";
import { ACITVITY_NAME, DESCRIPTION, NAME } from "../../../../_constant/wordings";
import AssignUserSelect from "../../../../components/AssignUserSelect/AssignUserSelect";
import { ChangeEvent } from "react";
import { Grid } from "@material-ui/core";
import useGetAccountId from "../../../../customHooks/useGetAccountId";
import DateRowLayout from "../../../../components/DateRowLayout/DateRowLayout";
import InputRow from "../../../../components/InputRow/InputRow";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { EditOutlined } from "@material-ui/icons";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import useTheme from "@material-ui/core/styles/useTheme";
import { StyledSection } from "../../../../components/_styled/StyledSection";
import InputField from "../../../../components/InputField/InputField";
import GetCalendarDateButton from "./GetCalendarDateButton";

interface EssentialActivityProps {
  activityData: ActivityModel;
  errorFields: Map<string, string>;
  handleFieldChange: (e: ChangeEvent<HTMLInputElement> | null, target?: any) => void;
}

const EssentialActivity: React.FC<EssentialActivityProps> = (props) => {
  const { activityData } = props;
  const { isPersonal: isAccountPersonal } = useGetAccountId();

  const theme = useTheme();

  function getNewValueBasedOnAction(e, newValue) {
    if (!newValue) {
      return { Id: null, Name: null };
    }

    return { Id: newValue.value, Name: newValue.label };
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value ?? "";
    props.handleFieldChange(null, { value, id: NAME });
  };

  return (
    <StyledSection>
      <InputRow alignItems="flex-end">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ParentItemSelect
              AssetId={activityData.AssetId || ""}
              errorMessage={props.errorFields.get("AssetId")}
              onChangeWithValidation={props.handleFieldChange}
              getNewValueBasedOnAction={getNewValueBasedOnAction}
              icon={<ListAltIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              autoComplete="off"
              type="text"
              name={ACITVITY_NAME}
              label={ACITVITY_NAME}
              onChange={handleNameChange}
              value={activityData.Name}
              id={ACITVITY_NAME}
              error={props.errorFields.has(NAME)}
              errorText={props.errorFields.get(NAME)}
              required
              icon={<EditOutlined />}
            />
          </Grid>
        </Grid>
      </InputRow>

      <InputRow alignItems="flex-end">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputField
              handleChange={props.handleFieldChange}
              value={activityData.Description}
              id={DESCRIPTION}
              label={DESCRIPTION}
              fullWidth
              icon={<DescriptionOutlinedIcon />}
              multiline
              maxLength="1000"
            />
          </Grid>
        </Grid>
      </InputRow>

      <InputRow alignItems="flex-end">
        <Grid container spacing={2}>
          {!isAccountPersonal && (
            <Grid item xs={12} sm={6}>
              <AssignUserSelect
                assignedUserId={activityData.AssignedUserId}
                handleFieldChange={props.handleFieldChange}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <StatusSelect
              statusId={activityData.StatusId}
              onChange={props.handleFieldChange}
              getNewValueBasedOnAction={getNewValueBasedOnAction}
              assignedUserId={activityData.AssignedUserId}
            />
          </Grid>
        </Grid>
      </InputRow>

      <InputRow alignItems="center">
        <DateRowLayout>
          <DueDateComponent
            onChange={props.handleFieldChange}
            value={activityData.DueDateTime}
            additionalProps={activityData.Schedule}
            relatedDueDateTime={activityData.RelatedDueDateTime}
          />
          <CompletedDateComponent
            value={activityData.CompletedDateTime}
            onChange={props.handleFieldChange}
          />
        </DateRowLayout>
      </InputRow>
    </StyledSection>
  );
};

export default EssentialActivity;
