import { Image } from "./ImageModel";
import { Users } from "./UsersModel";

export class AccountModel {
  Id: string;
  OwnerId: string;
  Name: string;
  Description: string;
  IsCustomerManagement: boolean;
  Images: Image[];
  Files: any;
  MaxMembers?: {
    Value: number;
    IsLimited: boolean;
  } | null;
  // Users: Users[];

  constructor({
    Id = "",
    OwnerId = "",
    Name = "",
    Description = "",
    IsCustomerManagement = false,
    Images = [] as Image[],
    Files = [],
    MaxMembers = null,
    // Users = [] as Users[],
  } = {}) {
    this.Id = Id;
    this.OwnerId = OwnerId;
    this.Name = Name || "";
    this.Description = Description || "";
    this.IsCustomerManagement = IsCustomerManagement || false;
    this.Images = Images || [];
    this.Files = Files || [];
    this.MaxMembers = MaxMembers ?? null;
    // this.Users = Users || [] as Users[];
  }
}
