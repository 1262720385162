import React from "react";
import { DropZoneGridContainer } from "./DropZone.style";
import DropZoneSvgCard from "../DropZoneSvgCard/DropZoneSvgCard";
import styled, { css } from "styled-components";
import { useDropZoneContext } from "../DropZoneContainer/useDropZoneContext";
import { FileType } from "../../_utils/dropzoneHelpers";
import DropZonePlaceholder from "./DropZonePlaceholder";

const verticalDivider = css`
  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    right: 0;
    background-color: ${({theme}) => theme.palette.primary.light};
  }
`;

const horizontalDivider = css`
  &:after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    bottom: 0;
    background-color: ${({theme}) => theme.palette.primary.light};
    left: 50%;
    transform: translateX(-50%);
  }
`;

interface StyledMainSectionProps {
  $isSingleFile: boolean;
}
const StyledMainSection = styled.div<StyledMainSectionProps>`
  // min-width: 116px;
  position: relative;
  display: flex;
  padding-right: 12px;

  ${({ $isSingleFile }) => ($isSingleFile ? "" : verticalDivider)}
  ${({ $isSingleFile }) =>
    $isSingleFile
      ? `
    justify-content: center;
    width: 100%;
    padding-right: 0;
  `
      : ""}

  @media screen and (min-width: 600px) {
    padding: 16px 0;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    ${({ $isSingleFile }) => ($isSingleFile ? "" : horizontalDivider)}
  }
`;

const MainFilesSection: React.FC = () => {
  const {
    isSingleFile,
    mainFiles,
    handleServerFileDelete,
    handleLocalFilesDelete,
    handleImageClick,
    handleFileFromServerClick,
    amountOfMainFiles,
  } = useDropZoneContext();

  const foundMainImage = mainFiles.find((file) => file.fileType === "image");
  const restFiles = mainFiles.filter((file) => file.id !== foundMainImage?.id);
  const noMainFileFlag = !mainFiles.length || !foundMainImage;

  if (amountOfMainFiles <= 0) return null;

  const mainImage = noMainFileFlag ? (
    <DropZoneSvgCard large title="" imageUrl="" id={"placeholder"} />
  ) : (
    <DropZoneSvgCard
      large
      bigTitle
      title={foundMainImage.title}
      imageUrl={foundMainImage.imgUrl}
      id={foundMainImage.id}
      open={handleImageClick}
      remove={
        foundMainImage?.isLocal
          ? (e) => handleLocalFilesDelete(e, foundMainImage.id)
          : (e) => handleServerFileDelete(e, foundMainImage.id, foundMainImage.fileType as FileType)
      }
      dataOrder={foundMainImage.imgOrder}
    />
  );

  const renderRestMainFiles = () => {
    if (amountOfMainFiles === 1) return null;

    const difference =
      mainFiles.length === 0
        ? amountOfMainFiles - (mainFiles.length || 1)
        : amountOfMainFiles - restFiles.length - 1;

    const restFilesArray = restFiles.map((el) => {
      return (
        <DropZoneSvgCard
          key={el.id}
          title={el.title}
          imageUrl={el.imgUrl}
          id={el.id}
          open={
            el.url ? (e: any) => handleFileFromServerClick(e, el) : (e: any) => handleImageClick(e)
          }
          remove={
            el.isLocal
              ? (e) => handleLocalFilesDelete(e, el.id)
              : (e) => handleServerFileDelete(e, el.id, el.fileType as FileType)
          }
          dataOrder={el.url ? "" : el?.imgOrder}
          url={el.url}
        />
      );
    });

    if (difference > 0) {
      return restFilesArray.concat(
        new Array(difference)
          .fill(null)
          .map((_, index) => (
            <DropZonePlaceholder
              key={`img_placeholder_main_${index}`}
              id={`img_placeholder_main_${index}`}
            />
          )),
      );
    }

    return restFilesArray;
  };

  return (
    <StyledMainSection $isSingleFile={isSingleFile}>
      {mainImage}
      {amountOfMainFiles > 1 && (
        <DropZoneGridContainer $withPaddingRight={false}>
          {renderRestMainFiles()}
        </DropZoneGridContainer>
      )}
    </StyledMainSection>
  );
};

export default MainFilesSection;
