import React from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import MUISkeleton from "@material-ui/lab/Skeleton";
import { InputBase, Typography } from "@material-ui/core";

import { ReactComponent as Logo } from "logoBrand/logo.svg";
import WizardCard from "../WizardCard/WizardCard";

import { scrollStyles } from "../../_styled/scrollStyles";

export const WizardSearchScreenContainer = styled.div`
  padding: 16px 24px 0 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const WizardSearchScreenLogoContainer = styled.div`
  padding: var(--padding-wizard-search-screen-logo-container);
  display: flex;
  justify-content: center;
`;

export const WizardSearchScreenLogo = styled(Logo)`
  max-height: 60px;
  height: 100%;
  max-width: 100%;
  width: 100%;
`;

export const WizardSearchScreenLogoDescription = styled(Typography)`
  font-size: 0.75rem;
  letter-spacing: 0.25px;
  margin-bottom: 12px;
`;

export const WizardSearchScreenInput = styled(InputBase)`
  border: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  border-radius: 4px;
  padding-right: 16px;
  margin-bottom: 30px;

  .MuiInputBase-input {
    padding: 9px 16px;
  }
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 1rem;
  }
`;

export const PopularSection = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

export const PopularSectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PopularSectionTitle = styled(Typography)`
  font-size: 0.625rem;
  letter-spacing: 0.25px;
  color: ${({theme}) => theme.palette.greyDesign.surface2};
`;

export const PopularSectionHeaderAction = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  font-size: 0.875rem;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .MuiSvgIcon-root {
    font-size: 10px;
  }
`;

export const WizardSearchScreenItemTypeCard = styled((props) => <WizardCard {...props} />)(
  ({ theme }) => `
    margin: 3px 0;
    width: 100%;

    ${theme.breakpoints.up("sm")} {
      margin: 3px;
      width: calc(100% / 3 - 6px);
    }
  `,
);

export const PopularSectionList = styled.div`
  height: fit-content;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 14px 0;

  ${scrollStyles};
`;

export const FoundItemsList = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;

  ${scrollStyles};
`;

export const AssetTypeSkeleton = styled(MUISkeleton)(
  ({ theme }) => `
  margin: 3px;
  width: 100%;
  height: 46px;
  border-radius: 4px;
  
  ${theme.breakpoints.up("sm")} {
      margin: 3px;
      width: calc(100% / 3 - 6px);
    }
`,
);
