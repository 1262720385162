import React from "react";
import { useMutation } from "react-apollo";
import { useSelector } from "react-redux";

import useGetAccountId from "./useGetAccountId";
import useGetBusinessStatuses from "./useGetBusinessStatuses";
import useSuccessfulRedirect from "./useSuccessfulRedirect";

import { convertStatusToFilterVariable, displayErrorNotification } from "../redux/actions/_utils";
import { getActivityFilters } from "../redux/reducers/appReducer";
import { updateCacheAfterDeleteCustomer } from "../components/DeleteCustomerDialog/updateCacheAfterDeleteCustomer";

import { deleteCustomerMutation } from "../components/DeleteCustomerDialog/deleteCustomer.query";
import { getVisitsByStatusQuery } from "../components/VisitsByStatuses/visitsByStatus.query";

import { ROUTES } from "../_constant/screens";
import { NotificationTypes } from "../_constant/NotificationTypes";

const useDeleteCustomer = (customerId: string | null) => {
  const { accountId } = useGetAccountId();
  const statuses = useGetBusinessStatuses();
  const { successfulRedirect } = useSuccessfulRedirect();

  const activityFiltersMap = useSelector((state) => getActivityFilters(state));

  const [removeCustomer, { loading: isRemoving }] = useMutation(deleteCustomerMutation);

  const handleDelete = () => {
    if (!accountId) {
      return;
    }
    const refetchQueries = statuses
      ? statuses.map((status) => {
          const filterString = convertStatusToFilterVariable(
            status,
            activityFiltersMap,
            "VisitStatusId",
          );
          return {
            query: getVisitsByStatusQuery,
            variables: { Id: accountId, Filter: filterString },
          };
        })
      : [];

    removeCustomer({
      variables: { AccountId: accountId, Id: customerId },
      update(cache, { data: { CustomerDelete } }) {
        updateCacheAfterDeleteCustomer(cache, CustomerDelete, accountId as string);
      },
      refetchQueries,
    })
      .then(() => {
        successfulRedirect(ROUTES.CUSTOMERS, NotificationTypes.CUSTOMER_DELETED);
      })
      .catch(() => displayErrorNotification(NotificationTypes.CUSTOMER_DELETE_ERROR));
  };

  return { removeCustomer: handleDelete, isRemoving };
};

export default useDeleteCustomer;
