import React, { createContext, useRef, useState } from "react";

type ContextTypes = {
  showPrompt: showPromptState;
  setShowPrompt: (newState: showPromptState) => void;
  lastLocation: any;
  setLastLocation: (newValue: any) => void;
  confirmedNavigation: boolean;
  setConfirmedNavigation: (value: boolean) => void;
  deferredCallback: any;
};

type showPromptState = boolean;

const showPromptContextDefaults = false;

export const LeaveDialogContext = createContext<ContextTypes | undefined>(undefined);

const LeaveDialogProvider: React.FC = ({ children }) => {
  const [showPrompt, setShowPrompt] = useState(showPromptContextDefaults);
  const [lastLocation, setLastLocation]: any = useState<null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const deferredCallback: any = useRef(null);

  return (
    <LeaveDialogContext.Provider
      value={{
        showPrompt,
        setShowPrompt,
        deferredCallback,
        lastLocation,
        setLastLocation,
        confirmedNavigation,
        setConfirmedNavigation,
      }}
    >
      {children}
    </LeaveDialogContext.Provider>
  );
};

export default LeaveDialogProvider;
