import * as React from "react";
import Modal from "../containers/Modal/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const SwitchAccountPage = () => {
  return (
    <Modal isShowing={true}>
      <Backdrop className={""} open={true} style={{ width: "100vw", zIndex: 100 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Modal>
  );
};

export default SwitchAccountPage;
