export class Image {
  Id: string;
  Name: string;
  Description: string;
  FileExtension: string;
  TotalBytes: number;
  ContainerName: string;
  ContainerId: string;
  // IsPrimary: boolean;
  AbsoluteUri?: string;
  __typename?: string;

  constructor({
    Id = "",
    Name = "",
    Description = "",
    FileExtension = "",
    TotalBytes = 0,
    ContainerName = "",
    ContainerId = "",
    AbsoluteUri = "",
  } = {}) {
    this.Id = Id;
    this.Name = Name;
    this.Description = Description || "";
    this.FileExtension = FileExtension || "";
    this.TotalBytes = TotalBytes;
    this.ContainerName = ContainerName;
    this.ContainerId = ContainerId;
    this.AbsoluteUri = AbsoluteUri;
  }
}
