import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import useGetAccountId from "../../../../customHooks/useGetAccountId";
import useGetAcitvitiesStatuses from "../../../../customHooks/api/useGetAcitvitiesStatuses";

import { AssetRefsModel } from "../../../../redux/models/data/AssetRefsModel";
import { ACCOUNT, ASSET_REFS, ASSET_TYPE_REF, ID } from "../../../../_constant/wordings";

import {
  removeAllRelatedAssetsAfterDeleteParentAsset,
  removeCacheAfterAssetDelete,
  removeIdFromComponentIdsInParentAsset,
  removeItemFromAssetReferencesQuery,
  removeItemFromPartOfSelect,
} from "./updateCacheFunction";

import { GET_ASSET_REFS } from "../../all/assets.query";
import { GET_ACTIVITIES_LIST } from "../../../activities/all/activities-list.query";

import { setSelectedAssets } from "../../../../redux/actions/actions";
import { getActivityFilters } from "../../../../redux/reducers/appReducer";
import { getSelectedAssetType } from "../../../../redux/reducers/filterReducer";
import { convertStatusToFilterVariable } from "../../../../redux/actions/_utils";
import { GET_CUSTOMER_ASSETS } from "../../../../components/ItemsTab/getCustomerAssets.query";

const deleteAssetQuery = gql`
  mutation deleteAsset($AccountId: ID, $AssetId: ID, $IncludeComponents: Boolean) {
    AssetDelete(AccountId: $AccountId, Id: $AssetId, IncludeComponents: $IncludeComponents) {
      Id
      ParentId
      AssetType {
        Id
        IsSystem
      }
    }
  }
`;

interface handleDeleteProps {
  assetId: string;
  customerId?: string;
  relatedActivities?: boolean;
  isDeleteAllParts?: boolean;
}

const useRemoveItem = (relatedParts: any[] | []) => {
  const { accountId, IsCustomerManagement } = useGetAccountId();
  const statuses = useGetAcitvitiesStatuses();
  const dispatch = useDispatch();

  const filterMap = useSelector(getActivityFilters);
  const selectedAssetType = useSelector(getSelectedAssetType, shallowEqual);

  const [removeAsset, { loading: isRemoving, client }] = useMutation(deleteAssetQuery);

  const handleDelete = ({
    relatedActivities,
    assetId,
    customerId,
    isDeleteAllParts,
  }: handleDeleteProps) => {
    let refetchQueries = [];
    if (!accountId || !assetId) {
      return;
    }

    if (relatedActivities) {
      refetchQueries = statuses.map((element) => ({
        query: GET_ACTIVITIES_LIST,
        variables: { Id: accountId, Filter: convertStatusToFilterVariable(element, filterMap) },
      }));
      try {
        client?.cache.readQuery({
          query: GET_ACTIVITIES_LIST,
          variables: {
            Id: accountId,
            Filter: convertStatusToFilterVariable(statuses[0], filterMap),
          },
        });
      } catch (e) {
        refetchQueries = [];
      }
    }

    return removeAsset({
      variables: { AccountId: accountId, AssetId: assetId, IncludeComponents: isDeleteAllParts },
      refetchQueries,
      update(cache, { data: { AssetDelete } }) {
        let assetReferencesQuery: any;

        if (IsCustomerManagement && customerId) {
          removeCacheAfterAssetDelete(cache, customerId, accountId, AssetDelete);
          removeItemFromPartOfSelect(cache, AssetDelete, accountId);
          removeIdFromComponentIdsInParentAsset(cache, AssetDelete, accountId);

          if (isDeleteAllParts) {
            try {
              assetReferencesQuery = cache.readQuery({
                query: GET_CUSTOMER_ASSETS,
                variables: { CustomerId: customerId, AccountId: accountId },
              });
            } catch (error) {}

            removeAllRelatedAssetsAfterDeleteParentAsset(
              cache,
              accountId,
              relatedParts,
              assetReferencesQuery,
              customerId,
            );
          }
          return;
        }

        try {
          assetReferencesQuery = cache.readQuery({
            query: GET_ASSET_REFS,
            variables: { Id: accountId },
          });
        } catch (error) {}

        removeItemFromAssetReferencesQuery(cache, AssetDelete, accountId, assetReferencesQuery);
        removeItemFromPartOfSelect(cache, AssetDelete, accountId);
        removeIdFromComponentIdsInParentAsset(cache, AssetDelete, accountId);

        if (isDeleteAllParts) {
          removeAllRelatedAssetsAfterDeleteParentAsset(
            cache,
            accountId,
            relatedParts,
            assetReferencesQuery,
          );
        }

        const isLastOfType = !assetReferencesQuery?.[ACCOUNT]?.[ASSET_REFS]?.some(
          (asset: AssetRefsModel) => asset[ASSET_TYPE_REF][ID] === selectedAssetType,
        );

        if (isLastOfType) {
          dispatch(setSelectedAssets());
        }
      },
    });
  };
  return { removeItem: handleDelete, isRemoving };
};

export default useRemoveItem;
