import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import { useSelector } from "react-redux";

import { ACCOUNT_REFS, USER } from "../../_constant/wordings";
import { AccountModel } from "../../redux/models/data/AccountModel";
import { getSearchStringValue } from "../../redux/reducers/filterReducer";
import { composeImageUrl } from "../../_utils/utils";
import { filterAccounts } from "../../_utils/filtering";

import Body from "../../components/Body/Body";
import AccountCard from "../../components/AccontCard/AccountCard";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import { getAccountRefsList } from "./getAccountList.query";
import { getInvitesQuery } from "./getInvitationsList.query";

import Accordion from "../../components/Accordion";
import InvitationItem from "../../components/InvitationItem/InvitationItem";
import ActionsBar from "../../components/ActionsBar/ActionsBar";

import { Container, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InvitationModel } from "../../redux/models/data/InvitationModel";
import { InvitationStateOptions } from "../../_constant/constants";

import "./Acconts.scss";
import InvitationHistoryItem from "../../components/InvitationItem/InvitationHistoryItem";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { ROUTES } from "../../_constant/screens";
import Hidden from "@material-ui/core/Hidden";
import useGetUserId from "../../customHooks/useGetUserId";

const Accounts: React.FC = () => {
  const [isAccountsExpanded, setAccountsExpanded] = useState(true);
  const [isInvitesOpened, setInvitesOpened] = useState(true);
  const [isHistoryOpened, setHistoryOpened] = useState(false);

  const searchValue = useSelector((state) => getSearchStringValue(state));

  const currentUserId = useGetUserId();

  const staticData = useStaticDataQuery();

  const { data, loading: isAccountsLoading } = useQuery(getAccountRefsList, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const { data: invites, loading: invitationsLoading } = useQuery(
    getInvitesQuery(InvitationStateOptions.active),
    {
      skip: !data,
    },
  );

  useEffect(() => {
    if (!isAccountsLoading && invites) {
      if (invites.User.MyInvitations.length > 0 && !isInvitesOpened) {
        setInvitesOpened(true);
      }
    }
  }, [invites]);

  const invitationWasDeclined = () => {
    if (!isHistoryOpened) {
      setHistoryOpened(true);
    }
  };

  const [
    getHistoryInvites,
    { data: historyInvites, loading: historyInvitationsLoading },
  ] = useLazyQuery(
    getInvitesQuery(InvitationStateOptions.declined, InvitationStateOptions.ignored),
  );

  useEffect(() => {
    if (!historyInvitationsLoading && historyInvites) {
      if (historyInvites.User.MyInvitations.length > 0 && !isHistoryOpened) {
        setHistoryOpened(true);
      }
    }
  }, [historyInvites]);

  const accountList =
    data && data[USER] ? filterAccounts(data[USER][ACCOUNT_REFS], searchValue) : [];

  const contentLoading = () => (
    <div
      style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress color="primary" size={30} />
    </div>
  );

  const addButton = (
    <Button
      component={Link}
      to={ROUTES.NEW_ACCOUNT}
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
    >
      Account
    </Button>
  );

  return (
    <Body>
      <Container maxWidth="md" className="Accounts">
        <Hidden only="xs">
          <ActionsBar title="My Accounts" withSearch>
            {addButton}
          </ActionsBar>
        </Hidden>

        <Hidden smUp>
          <ActionsBar title="">{addButton}</ActionsBar>
        </Hidden>

        <Accordion
          label={
            <>
              Invitation{" "}
              <span className="Label">
                {" "}
                {invites && invites.User.MyInvitations.length > 0
                  ? `(${invites.User.MyInvitations.length})`
                  : null}
              </span>
            </>
          }
          expanded={isInvitesOpened}
          onClose={() => setInvitesOpened(false)}
          withBackground={false}
        >
          {invitationsLoading || isAccountsLoading
            ? contentLoading()
            : invites &&
              invites.User.MyInvitations.map((invitation: InvitationModel) => (
                <InvitationItem
                  key={invitation.Id}
                  invitation={invitation}
                  declineCallback={invitationWasDeclined}
                />
              ))}
        </Accordion>
        <Accordion
          label={
            <>
              Accounts{" "}
              <span className="Label">
                {" "}
                {accountList.length > 0 ? `(${accountList.length})` : null}
              </span>
            </>
          }
          expanded={isAccountsExpanded}
          onClose={() => setAccountsExpanded(false)}
          withBackground={false}
          // isLoading={isAccountsLoading}
        >
          {isAccountsLoading ? (
            contentLoading()
          ) : (
            <div className="Accounts__wrapper">
              {accountList.length > 0 &&
                accountList.map((account: AccountModel) => {
                  const image = account.Images && account.Images[0];
                  const imageUrl = image
                    ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
                    : null;
                  return (
                    <div className="Accounts__item" key={account.Id}>
                      <AccountCard
                        name={account.Name}
                        description={account.Description}
                        image={imageUrl}
                        linkTo={account.Id}
                        personalImg={staticData ? staticData.Account.Personal_account_img : ""}
                        isBusiness={account.IsCustomerManagement}
                        isOwner={account.OwnerId === currentUserId}
                        // members={account.Users}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </Accordion>
        <div style={{ marginBottom: "30px" }}>
          <Accordion
            label={
              <>
                History{" "}
                <span className="Label">
                  {" "}
                  {historyInvites && historyInvites.User.MyInvitations.length > 0
                    ? `(${historyInvites.User.MyInvitations.length})`
                    : null}
                </span>
              </>
            }
            expanded={isHistoryOpened}
            withBackground={false}
            onOpen={getHistoryInvites}
            onClose={() => setHistoryOpened(false)}
          >
            {historyInvitationsLoading || isAccountsLoading
              ? contentLoading()
              : historyInvites &&
                historyInvites.User.MyInvitations.map((invitation: InvitationModel) => (
                  <InvitationHistoryItem key={invitation.Id} invitation={invitation} />
                ))}
          </Accordion>
        </div>
      </Container>
    </Body>
  );
};

export default Accounts;
