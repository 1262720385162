import gql from "graphql-tag";

export const getVendorsQuery = gql`
  query getVendors($AccountId: ID) {
    Vendors(AccountId: $AccountId) {
      Id
      Name
      Address
      Email
      Website
      Phone
      CustomFields
      Schema {
        FieldType
        Id
        Name
        Order
      }
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
    }
  }
`;
