import React from "react";
import InputRow from "../InputRow/InputRow";

import Grid from "@material-ui/core/Grid/Grid";

import Input from "../Input/Input";

import { itemCustomFieldTypesOptions } from "../ItemCustomFields/ItemCustomFieldsTypeOptions";
import { getCustomFieldIcon } from "../ItemCustomFields/CustomFIeldIconMapByType";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "../Select/Select";
import SelectItem from "../SelectItem/SelectItem";

interface EditCustomFieldLayout {
  Name: string;
  FieldType: string;
  nameIcon: React.ReactElement;
  handleNameChange: any;
  handleTypeChange: (target: React.ChangeEvent<{ value: any; name?: string }>) => void;
  renderActionButton: () => React.ReactElement;
  handleNameBlur?: () => void;
}

const EditCustomFieldLayout: React.FC<EditCustomFieldLayout> = (props) => {
  const {
    Name,
    FieldType,
    nameIcon,
    handleNameChange,
    handleTypeChange,
    renderActionButton,
    handleNameBlur,
  } = props;

  const selectOptions = itemCustomFieldTypesOptions.map((option) => (
    <MenuItem value={option.value} key={option.value}>
      <SelectItem>{option.label}</SelectItem>
    </MenuItem>
  ));

  return (
    <InputRow alignItems="flex-end" actionZoneComponent={renderActionButton}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input
            type="text"
            label="Field Name"
            onChange={handleNameChange}
            value={Name}
            id={`custom-field-name-${Name}`}
            fullWidth
            onBlur={handleNameBlur || undefined}
            icon={nameIcon || undefined}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            type="text"
            onChange={handleTypeChange}
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            value={FieldType || ""}
            label="Field Type"
            id={`custom-field-type-${Name}`}
            fullWidth
            media={getCustomFieldIcon(FieldType)}
          >
            {selectOptions}
          </Select>
        </Grid>
      </Grid>
    </InputRow>
  );
};

export default EditCustomFieldLayout;
