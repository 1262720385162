import {AssetModel} from "./AssetModel";

export class AssetForWizardModel extends AssetModel {
  _isNameCustom: boolean;
  _AssetActivities: any[];

  constructor ({_isNameCustom = false, _AssetActivities = [] as any[], ...rest} = {} as AssetForWizardModel & AssetModel) {
    super(rest as AssetModel);
    this._isNameCustom = _isNameCustom;
    this._AssetActivities = _AssetActivities || [] as any[];
  }
}