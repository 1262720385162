import styled from "styled-components";

interface WizardActivityRowStyled {
  noBorder?: boolean;
}

export const WizardActivityRowStyled = styled.div<WizardActivityRowStyled>(
  ({ theme, noBorder }) => `
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;

  &::after {
    content: "";
    position: absolute;
    height: ${noBorder ? "0px" : "1px"};
    left: 48px;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid ${theme.palette.grey[300]};
  }
`,
);

export const WizardActivityRowContent = styled.div`
  width: 100%;
`;
export const WizardActivityRowActionZone = styled.div(
  ({ theme }) => `
  display: flex;
  margin-left: ${theme.spacing(4)}px;
`,
);
