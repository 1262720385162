import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { toastr } from "react-redux-toastr";

import useIsMobileDevice from "../../customHooks/useIsMobileDevice";

import useGetAccountId from "../../customHooks/useGetAccountId";

import { DEFAULT_ICON } from "../../_constant/Icons";
import { NotificationTypes } from "../../_constant/NotificationTypes";
import { AssetTypeModel } from "../../redux/models/data/AssetTypeModel";

import { displayErrorNotification, displaySuccessNotification } from "../../redux/actions/_utils";

import { ButtonWithLoader, StyledCircularProgress } from "./UploadCsvDialog";
import AssetTypeSelect from "../../containers/assets/_components/form/AssetTypeSelect";
import { DialogStateHandlerType } from "../../containers/activities/_components/types";

export const GetTemplateZone = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SelectContainer = styled.div`
  min-width: 200px;
  padding-bottom: 20px;
  flex-grow: 1;
`;

export const AssetTypeWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 599px) {
    min-width: 100%;
  }
`;

export const TypeIcon = styled.i`
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
  margin-right: 10px;
  font-size: 1rem;
`;

const getCSVTemplateQuery = gql`
  mutation($id: ID!, $accountId: ID!) {
    AssetCSVTemplate(AssetTypeId: $id, AccountId: $accountId)
  }
`;

interface UploadTemplateZoneProps {
  changeType: (itemType: any) => void;
  itemType: AssetTypeModel;
  onOpen: (url: string) => void;
}

const UploadTemplateZone: React.FC<UploadTemplateZoneProps> = ({
  changeType,
  itemType,
  onOpen,
}) => {
  const { accountId } = useGetAccountId();

  const isMobileDevice = useIsMobileDevice();

  const [getCSVTemplate, { loading: getCSVTemplateLoading }] = useMutation(getCSVTemplateQuery);

  const handleDownloadCSVTemplate = () => {
    getCSVTemplate({
      variables: {
        id: itemType.Id,
        accountId,
      },
    })
      .then(({ data }) => {
        if (isMobileDevice) {
          onOpen(data?.AssetCSVTemplate);
          return;
        }
        data && window.open(data?.AssetCSVTemplate, "_self");
        displaySuccessNotification(NotificationTypes.TEMPLATE_DOWNLOADED);
      })
      .catch((error) => {
        const message = error.message.includes("GraphQL")
          ? error.message.split("GraphQL.ExecutionError: ").slice(1).join(" ")
          : error.message;

        if (message && message.length < 100) {
          toastr.error(message);
        } else {
          displayErrorNotification(NotificationTypes.DOWNLOAD_TEMPLATE_ERROR);
        }
      });
  };

  return (
    <GetTemplateZone>
      <AssetTypeWrapper>
        <TypeIcon className="Icon">{itemType.IconName || DEFAULT_ICON}</TypeIcon>
        <SelectContainer>
          <AssetTypeSelect
            AssetType={itemType}
            hasError={false}
            errorMessage=""
            updateManyFields={(type) => changeType(type?.AssetType)}
            creatable={false}
            setValueOnFirstLoad
          />
        </SelectContainer>
      </AssetTypeWrapper>
      <ButtonWithLoader
        variant="text"
        color="primary"
        startIcon={<i className="Icon">file_download</i>}
        disabled={!itemType.Id || getCSVTemplateLoading}
        onClick={handleDownloadCSVTemplate}
      >
        {getCSVTemplateLoading && <StyledCircularProgress size={24} />}
        Download Template
      </ButtonWithLoader>
    </GetTemplateZone>
  );
};

export default UploadTemplateZone;
