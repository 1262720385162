import React, { memo } from "react";
import { Line } from "rc-progress";
import { useTheme } from "@material-ui/core";
import {
  ProgressHeader,
  ProgressTitle,
  LegendList,
  LegendItem,
  ProgressLineWrap,
} from "./ProgressBarForActivitiesStyle";
import { ActivityRelatedToProjectModel } from "../ActivitiesTabProject/ActivityRelatedToProjectModel";
import useGetAcitvitiesStatuses from "../../customHooks/api/useGetAcitvitiesStatuses";

interface ProgressBarProps {
  activities: ActivityRelatedToProjectModel[];
}

const ProgressBarForActivities: React.FC<ProgressBarProps> = ({ activities }) => {
  const theme = useTheme();
  const statuses: { Id: string; Name: "string" }[] = useGetAcitvitiesStatuses();

  const getActivitiesPercentByStatus = (
    activities: ActivityRelatedToProjectModel[],
    activityStatus: string,
  ) => {
    const totalActivities = activities.length;
    const getStatus = statuses.find((status) => activityStatus === status.Name);
    const activityAmount = activities.filter(
      (activity: { StatusId: string }) => activity.StatusId === getStatus?.Id,
    ).length;

    return (activityAmount / totalActivities) * 100;
  };

  const percentOfInProgress = Math.round(getActivitiesPercentByStatus(activities, "In Progress"));
  const percentOfDone = Math.round(getActivitiesPercentByStatus(activities, "Done"));

  return (
    <>
      <ProgressHeader>
        <ProgressTitle>Project progress</ProgressTitle>

        <LegendList>
          {statuses.map((status) => (
            <LegendItem key={status.Id}>{status.Name}</LegendItem>
          ))}
        </LegendList>
      </ProgressHeader>

      <ProgressLineWrap>
        <Line
          percent={[
            percentOfDone,
            percentOfDone === 0 ? 0 : 0.5,
            percentOfInProgress,
            100 - percentOfDone - percentOfInProgress <= 0 || percentOfInProgress === 0 ? 0 : 0.5,
          ]}
          strokeWidth={1.2}
          trailWidth={1.2}
          strokeColor={[
            `${theme.palette.status.done}`,
            `var(--color-bg-secondary)`,
            `${theme.palette.status.inProgress}`,
            `var(--color-bg-secondary)`,
          ]}
          trailColor={theme.palette.status.toDo}
          strokeLinecap="butt"
          prefixCls="progress-bar"
        />
      </ProgressLineWrap>
    </>
  );
};

export default memo(
  ProgressBarForActivities,
  (prevProps, nextProps) => prevProps.activities === nextProps.activities,
);
