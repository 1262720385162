import gql from "graphql-tag";
import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { ACCOUNT, ASSET_REFS, CUSTOMER, ITEMS, PARENT_ID } from "../../../_constant/wordings";
import { AssetRefsModel } from "../../../redux/models/data/AssetRefsModel";

const GET_CUSTOMER_ITEMS_FOR_SELECT = gql`
  query getCustomerItems($CustomerId: ID, $AccountId: ID) {
    Customer(Id: $CustomerId, AccountId: $AccountId) {
      Id
      Assets {
        Id
        Name
      }
    }
  }
`;

const GET_ITEMS_LIST_FOR_SELECT = gql`
  query getItemsList($Id: ID) {
    Account(Id: $Id) {
      Id
      AssetRefs {
        Id
        Name
      }
    }
  }
`;

const GET_ASSETS_FOR_SELECT = gql`
  query getAssetsForSelect($Id: ID) {
    Account(Id: $Id) {
      Id
      Assets {
        Id
        Name
        ParentId
      }
    }
  }
`;

export const updateCustomerItemSelectCache = (
  cache: DataProxy,
  assetPost: any,
  accountId: string,
  customerId: string,
) => {
  try {
    const customerAssets: any = cache.readQuery({
      query: GET_CUSTOMER_ITEMS_FOR_SELECT,
      variables: {
        AccountId: accountId,
        CustomerId: customerId,
      },
    });

    customerAssets[CUSTOMER][ITEMS] = customerAssets[CUSTOMER][ITEMS].concat([
      {
        Id: assetPost.Id,
        Name: assetPost.Name,
        __typename: "Asset",
      },
    ]);

    cache.writeQuery({
      query: GET_CUSTOMER_ITEMS_FOR_SELECT,
      variables: {
        AccountId: accountId,
        CustomerId: customerId,
      },
      data: { ...customerAssets },
    });
  } catch (e) {
    console.error(e);
  }
};

interface SelectItem extends Pick<AssetRefsModel, "Id" | "Name"> {
  __typename: string;
}

export const updateItemSelectListCache = (cache: DataProxy, assetPost: any, accountId: string) => {
  try {
    const selectItemsList: any = cache.readQuery({
      query: GET_ITEMS_LIST_FOR_SELECT,
      variables: {
        Id: accountId,
      },
    });

    if (!selectItemsList[ACCOUNT][ASSET_REFS]) {
      selectItemsList[ACCOUNT][ASSET_REFS] = [];
    }

    if (
      !selectItemsList[ACCOUNT][ASSET_REFS].find((item: SelectItem) => item.Id === assetPost.Id)
    ) {
      selectItemsList[ACCOUNT][ASSET_REFS] = selectItemsList[ACCOUNT][ASSET_REFS].concat([
        {
          Id: assetPost.Id,
          Name: assetPost.Name,
          __typename: "AssetRef",
        },
      ]);
    }

    cache.writeQuery({
      query: GET_ITEMS_LIST_FOR_SELECT,
      variables: {
        Id: accountId,
      },
      data: { ...selectItemsList },
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateItemPartOfSelectCache = (cache: DataProxy, assetPost: any, accountId: string) => {
  try {
    const cacheData: any = cache.readQuery({
      query: GET_ASSETS_FOR_SELECT,
      variables: {
        Id: accountId,
      }
    });

    if (!cacheData[ACCOUNT][ITEMS].find((item) => item.Id === assetPost.Id)) {
      cacheData[ACCOUNT][ITEMS] = cacheData[ACCOUNT][ITEMS].concat([{
        Id: assetPost.Id,
        Name: assetPost.Name,
        ParentId: assetPost.ParentId,
        __typename: "Asset"
      }])
    }

    cache.writeQuery({
      query: GET_ASSETS_FOR_SELECT,
      variables: {
        Id: accountId,
      },
      data: {...cacheData},
    });
  } catch (error) {}
};

export const updatePartOfSelectCacheAfterRemovingPart = (cache: DataProxy, removedPart: any, accountId: string ) => {
  try {
      const cacheData: any = cache.readQuery({
        query: GET_ASSETS_FOR_SELECT,
        variables: {
          Id: accountId,
        },
      });

      const updatedAssets = cacheData[ACCOUNT][ITEMS].map((asset) => {
        if (asset.Id === removedPart.Id) {
          return { ...removedPart, [PARENT_ID]: null, __typename: "Asset" };
        } else return asset;
      });

      cacheData[ACCOUNT][ITEMS] = updatedAssets;

      cache.writeQuery({
          query: GET_ASSETS_FOR_SELECT,
          variables: {
              Id: accountId,
            },
            data: cacheData,
          });
      } catch (error) { }
};
