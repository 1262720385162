import * as React from "react";
import { useDrag } from "react-dnd";
import { useCallback, useEffect, useState } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";

interface ProjectItemDragWrapperProps {
  project: any;
  layout: string;
  statusName: string;
  browserWindowWidth: number;
  isAccountPersonal: boolean;
}

export const ProjectItemDragWrapper: React.FC<ProjectItemDragWrapperProps> = ({
  project,
  children,
  layout,
  statusName,
  browserWindowWidth,
  isAccountPersonal,
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [{ isDragging }, drag, preview] = useDrag({
    canDrag: !project.isLoading,
    item: {
      type: "project",
      project,
      layout,
      statusName,
      width,
      height,
      isAccountPersonal,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  const attach = useCallback(
    (domElement) => {
      drag(domElement);

      const node: any = findDOMNode(domElement);

      node ? setWidth(node.clientWidth) : setWidth(0);
      node ? setHeight(node.clientHeight) : setHeight(0);
    },
    [browserWindowWidth, layout],
  );

  return (
    <div
      ref={attach}
      className={`ActivityItem_wrapper ${isDragging ? "ActivityItem__whileDragPlaceholder" : ""}`}
    >
      {children}
    </div>
  );
};

export default ProjectItemDragWrapper;
