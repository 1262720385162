import React from "react";

import { InputProps } from "./InputTypes";
import { TextFieldProps } from "@material-ui/core";

import { StyledInput, InputContainer, InputIcon } from "./InputStyles";

import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Zoom from "@material-ui/core/Zoom/Zoom";

const Input: React.FC<TextFieldProps & InputProps> = ({
  isDisabled,
  icon,
  disableUnderline,
  nonEditable,
  disableClearButton,
  helperText: text,
  errorText,
  required,
  inputRef,
  ...restProps
}) => {
  const handleClear = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const emptyValue: any = { target: { id: restProps.id, value: "" } };

    restProps.onChange && restProps.onChange(emptyValue);

    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
  };

  const clearAdornment = {
    endAdornment: disableClearButton ? null : (
      <Zoom in={!!restProps.value} mountOnEnter unmountOnExit>
        <IconButton onClick={handleClear} edge="end" size="small">
          <ClearIcon />
        </IconButton>
      </Zoom>
    ),
  };

  const getHelperText = (): string | React.ReactNode => {
    const { value } = restProps;

    if (value && !!errorText) {
      return errorText;
    } else if (!value && required) {
      return "*Required";
    } else if (text) {
      return text;
    } else {
      return "";
    }
  };

  return (
    <InputContainer>
      {icon && <InputIcon disabled={isDisabled}>{icon}</InputIcon>}
      <StyledInput
        InputProps={clearAdornment}
        disabled={isDisabled}
        fullWidth
        inputProps={{
          maxLength: restProps.maxLength ?? 60,
        }}
        helperText={getHelperText()}
        $disableUnderline={disableUnderline}
        $nonEditable={nonEditable}
        required={required}
        error={!!errorText}
        inputRef={inputRef}
        {...restProps}
      />
    </InputContainer>
  );
};

export default Input;
