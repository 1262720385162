import gql from "graphql-tag";

export const updateBusinessAccountQuery = gql`
  mutation updateBusinessAccount(
    $Id: ID
    $Name: String
    $Description: String
    $Images: [ImageInput]
    $Files: [Upload]
    $IsCustomerManagement: Boolean
  ) {
    AccountPut(
      Account: {
        Id: $Id
        Name: $Name
        Description: $Description
        Images: $Images
        IsCustomerManagement: $IsCustomerManagement
      }
      Files: $Files
    ) {
      Id
      Name
      Description
      IsCustomerManagement
      Images {
        Id
        ContainerId
        ContainerName
        Description
        FileExtension
        Name
        IsPrimary
      }
    }
  }
`;
