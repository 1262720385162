import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { useStaticDataQuery } from "../../queries/useStaticDataQuery";
import useGetUserId from "../../customHooks/useGetUserId";

import { getAccountRefsList } from "../Accounts/getAccountList.query";
import { getCalendarConfigsQuery } from "./getCalendarConfigs.query";
import { composeImageUrl } from "../../_utils/utils";

import { ACCOUNT_REFS, PERSONAL, USER } from "../../_constant/wordings";
import { CalendarConfig } from "./CalendarConfig.types";

import { Box, CircularProgress, Typography, Hidden } from "@material-ui/core";

import Body from "../../components/Body/Body";
import { AboutPagePopoverButton, AboutPagePopoverContent } from "./InfoPopoverAboutPage";
import { Popover } from "../../components/Popover/Popover";
import CalendarFeedContainer from "../../components/CalendarFeedContainer/CalendarFeedContainer";

import { CalendarFeedList, StyledContainer, CalendarFeedListLoading } from "./CalendarFeeds.style";

interface MapConfigInterface {
  [field: string]: CalendarConfig;
}

const CalendarFeeds: React.FC = () => {
  const userId = useGetUserId();
  const staticData = useStaticDataQuery();

  const { data: accountsList, loading: isAccountsLoading } = useQuery(getAccountRefsList, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const { data: configsList, loading: configsLoading, error: configsError } = useQuery(
    getCalendarConfigsQuery,
    {
      variables: {
        userId,
      },
      skip: !userId,
    },
  );

  const accounts = accountsList?.[USER]?.[ACCOUNT_REFS] ?? [];
  const pesonalImg = staticData ? staticData.Account.Personal_account_img : "";

  const configsMap: MapConfigInterface = configsList?.CalendarConfigurations
    ? configsList?.CalendarConfigurations?.reduce(
        (acc: MapConfigInterface, config: CalendarConfig) => {
          acc[config.AccountId] = config;
          return acc;
        },
        {} as MapConfigInterface,
      )
    : {};

  return (
    <Body disableDesktopScroll>
      <StyledContainer>
        <Hidden xsDown>
          <Box display="flex" marginTop="30px" marginBottom="30px" justifyContent="space-between">
            <Typography component="h1" variant="body1" style={{ fontWeight: 500 }}>
              Calendar Feeds
            </Typography>
            <Popover
              target={<AboutPagePopoverButton />}
              content={(handler) => <AboutPagePopoverContent handleClose={handler} />}
            />
          </Box>
        </Hidden>
        {isAccountsLoading && (
          <CalendarFeedListLoading>
            <CircularProgress color="primary" size={30} />
          </CalendarFeedListLoading>
        )}
        {accounts && (
          <CalendarFeedList>
            {accounts.map((account: any) => {
              const image = account.Images && account.Images[0];
              const imageUrl = image
                ? composeImageUrl(image.Id, image.ContainerName, image.ContainerId)
                : null;

              return (
                <CalendarFeedContainer
                  imageUrl={imageUrl}
                  name={account.Name}
                  isBusiness={account.IsCustomerManagement}
                  id={account.Id}
                  isPersonal={account.Name === PERSONAL}
                  personalImg={pesonalImg}
                  config={configsMap[account.Id]}
                  key={account.Id}
                />
              );
            })}
          </CalendarFeedList>
        )}
      </StyledContainer>
    </Body>
  );
};

export default CalendarFeeds;
