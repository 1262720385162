import { useState } from "react";
import gql from "graphql-tag";
import { toastr } from "react-redux-toastr";
import { useApolloClient } from "@apollo/react-hooks";

import useGetAccountId from "./useGetAccountId";
import useIsMobileDevice from "./useIsMobileDevice";

import { immediatelyFileDownload } from "../_utils/utils";

import { DialogStateHandlerType } from "../containers/activities/_components/types";

const getCalendarEvent = gql`
  query getCalendarEvent($activityId: ID, $accountId: ID, $assetId: ID) {
    CalendarEvent(AssetActivityId: $activityId, AccountId: $accountId, AssetId: $assetId)
  }
`;

interface requestStateInterface {
  loading: boolean;
  uploaded: boolean;
}

interface getDateFileProps {
  activityId: string;
  assetId: string;
  onOpen: (url: string) => void;
}

interface useGetCalendarDateReturn {
  state: requestStateInterface;
  getDateFile: (props: getDateFileProps) => void;
}

const useGetCalendarDate = (): useGetCalendarDateReturn => {
  const [requestState, setRequestState] = useState<requestStateInterface>({
    loading: false,
    uploaded: false,
  });

  const { accountId } = useGetAccountId();
  const client = useApolloClient();
  const isMobileDevice = useIsMobileDevice();

  const getDateFile = ({ activityId, assetId, onOpen }: getDateFileProps) => {
    if (!activityId || !assetId || !accountId) {
      return;
    }
    if (client) {
      setRequestState(() => ({
        loading: true,
        uploaded: false,
        onOpen,
      }));
      client
        .query({ query: getCalendarEvent, variables: { activityId, accountId, assetId } })
        .then(({ data }) => {
          if (!data?.CalendarEvent) {
            return;
          }

          const url = data?.CalendarEvent ?? "";
          if (!isMobileDevice) {
            // immediatelyFileDownload(content, fileName);
            window.open(url, "_self");
            setRequestState(() => ({ loading: false, uploaded: true }));
          } else {
            onOpen(url);
            setRequestState(() => ({ loading: false, uploaded: true }));
          }
        })
        .catch((error) => {
          setRequestState((prev) => ({ ...prev, loading: false }));
          const message = error.message?.includes("GraphQL")
            ? error.message?.split("ExecutionError: ").slice(1).join(" ")
            : error.message?.message;
          if (message && message?.length < 100) {
            toastr.error(message, "");
          }
        });
    }
  };
  return { state: requestState, getDateFile };
};

export default useGetCalendarDate;
