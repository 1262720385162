import React from "react";

import ClearIcon from "@material-ui/icons/Clear";

import { IconButton } from "@material-ui/core";

import Input from "../Input/Input";

import {
  StyledAutocompleteContainer,
  StyledAutocomplete,
  StyledChip,
  StyledPaper,
  StyledButton,
  PaperContainer,
  NoOptionsHint,
  StyledLabelOutlinedIcon,
} from "./ItemTagsAutocomplete.styles";

interface TagsAutocompleteProps {
  handleFieldChange: (target: any) => void;
  checkedTags: string[];
  allTags: string[];
  allTagsLoaded: boolean;
}

const TagsAutocomplete: React.FC<TagsAutocompleteProps> = ({
  checkedTags,
  handleFieldChange,
  allTags,
  allTagsLoaded,
}) => {
  const [tagValue, setTagValue] = React.useState("");

  return (
    <StyledAutocompleteContainer item xs={12}>
      <StyledLabelOutlinedIcon />
      <StyledAutocomplete
        id="tags"
        multiple
        // limitTags={2}
        value={checkedTags}
        freeSolo={true}
        options={allTags}
        openOnFocus
        disableCloseOnSelect
        filterSelectedOptions
        onChange={(event, newValue) => {
          handleFieldChange({
            target: { id: "Tags", value: newValue },
          });
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <StyledChip
              deleteIcon={
                <IconButton size="small">
                  <ClearIcon />
                </IconButton>
              }
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        onInputChange={(e) => {
          if (!e?.currentTarget?.value?.length) {
            setTagValue("");
            return;
          }
          setTagValue(e?.currentTarget?.value.toLowerCase() ?? "");
        }}
        loading={allTagsLoaded}
        renderInput={(params) => <Input {...params} label="Tags" placeholder="More tags" />}
        renderOption={(option, state) => {
          return <StyledChip label={option} />;
        }}
        PaperComponent={({ children }) => (
          <PaperContainer>
            {tagValue && !checkedTags.includes(tagValue) && (
              <StyledButton
                onMouseDown={() => {
                  handleFieldChange({
                    target: { id: "Tags", value: [...checkedTags, tagValue] },
                  });
                }}
                fullWidth
              >
                Create "{tagValue}"
              </StyledButton>
            )}

            {checkedTags.length === allTags.length && !allTagsLoaded && (
              <NoOptionsHint>No options</NoOptionsHint>
            )}

            <StyledPaper>{children}</StyledPaper>
          </PaperContainer>
        )}
      />
    </StyledAutocompleteContainer>
  );
};

export default TagsAutocomplete;
