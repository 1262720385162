import React from "react";

import { mapAssignFromServer } from "../../../_utils/utils";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import {
  Card,
  CardContainer,
  CardImageContainer,
  CardMediaStyled,
  TextInsideImage,
  CardLabelList,
  CardMainContent,
  CardTitle,
  SelectStyled,
  LinkSection,
  CardLabel,
  ButtonStyled,
} from "./CalendarFeedCard.style";

import ActivitiesSelectFilter from "../../ActivitiesSelectFilter/ActivitiesSelectFilter";
import CardLoadingState from "../CardLoadingState";
import { Popover } from "../../Popover/Popover";
import { CalendarPopoverButton, CalendarPopoverContent } from "../CalendarPopoverContent";

import { CalendarFeedCardProps } from "../CalendarFeedContainer";

const CalendarFeedCard: React.FC<CalendarFeedCardProps> = ({
  name,
  imageUrl,
  isPersonal,
  isBusiness,
  personalImg,
  config,
  isImgExist,
  selectHandler,
  usersLoading,
  accountUsers,
  createConfigHandler,
  createLoading,
  updateLoading,
  assignValue,
  userId,
}) => {
  const renderLabels = () => {
    if (isBusiness) {
      return <CardLabel key={0}>Customer management</CardLabel>;
    }

    return null;
  };

  return (
    <Card>
      <CardContainer>
        <CardImageContainer $withoutBorder={isPersonal}>
          {isImgExist ? (
            <CardMediaStyled image={imageUrl ?? personalImg} title="Image here" />
          ) : (
            <CardMediaStyled>
              <TextInsideImage>{name[0].toLocaleUpperCase()}</TextInsideImage>
            </CardMediaStyled>
          )}
          <CardLabelList>{renderLabels()}</CardLabelList>
        </CardImageContainer>

        <CardMainContent>
          <CardTitle>{name}</CardTitle>
          {isPersonal ? (
            <Popover
              target={<CalendarPopoverButton />}
              content={(handler) => <CalendarPopoverContent handleClose={handler} />}
            />
          ) : (
            <SelectStyled>
              <ActivitiesSelectFilter
                filterValue={
                  config?.AssignType ? mapAssignFromServer(config.AssignType, userId) : assignValue
                }
                onChange={selectHandler}
                usersLoaded={!usersLoading}
                users={accountUsers?.Account?.UserRefs || []}
                withoutCrop
                justForCurrentUser
              />
            </SelectStyled>
          )}
        </CardMainContent>
        <LinkSection>
          <ButtonStyled
            variant="text"
            color="primary"
            startIcon={<FileCopyOutlinedIcon />}
            onClick={createConfigHandler}
            disabled={createLoading}
          >
            Copy Feed URL
          </ButtonStyled>
        </LinkSection>
      </CardContainer>
      {(updateLoading || createLoading) && <CardLoadingState />}
    </Card>
  );
};

export default CalendarFeedCard;
