import gql from "graphql-tag";

export const getAccountList = gql`
  query getAccountList {
    User {
      Id
      Accounts {
        Id
        Name
        Description
        Images {
          Id
          ContainerId
          ContainerName
          Description
          FileExtension
          Name
          IsPrimary
        }
        Users {
          FirstName
          LastName
          Id
          Images {
            Id
            ContainerId
            ContainerName
            Description
            FileExtension
            Name
            IsPrimary
          }
        }
      }
    }
  }
`;

export const getAccountRefsList = gql`
  query getAccountRefsList {
    User {
      Id
      AccountRefs {
        Id
        Name
        Description
        IsCustomerManagement
        OwnerId
        Images {
          Id
          ContainerId
          ContainerName
          Description
          FileExtension
          Name
          IsPrimary
        }
      }
    }
  }
`;
