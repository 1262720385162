import styled from "styled-components";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export const AddItemButtonMobile = styled(Button)`
  max-width: 40px;
  padding: 0 0 0 10px;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  .MuiButton-root {
    min-width: 40px;
  }
`;

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  transition: transform 0.25s linear;
  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen && "transform: rotate(180deg); transform-origin: center;"}
`;

export const StyledPopper = styled(Popper)`
  width: 136px;
  z-index: 1;
`;

export const MenuItemText = styled(Typography)`
  font-size: 12px;
`;
