export const DIGIT = /\d/;
export const DIGITS_ONLY = /^\d+$/;
export const TWO_DIGITS_ONLY = /^\d{0,2}$/;
export const NOT_DIGITS = /\D+/g;
export const FIRST_LETTER = /^\w/;
export const WORDS = /^[a-zA-Z'\-\s]*$/;
export const FOR_INPUT_TYPE_TEXT = /^[^^±£§¡€¢¶•ªº©«]{1,30}$/;
export const FOR_PROFILE_NAME = /^[^~`!@$%^*_=+\[\]{}|;",.<>?±£§¡€¢¶•ªº©«\\]{0,30}$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const WEBSITE_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const PHONE_INPUT_MASK = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const PHONE_CARD_MASK = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
