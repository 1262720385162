import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { getAccountRefsList } from "../Accounts/getAccountList.query";

import { ACCOUNT_REFS, USER } from "../../_constant/wordings";

import { AccountModel } from "../../redux/models/data/AccountModel";

import { createAccountRef } from "../NewAccount/updateCacheWithNewAccount";

export const updateAccountsCacheAfterEditAccount = (cache: DataProxy, accountPut: AccountModel) => {
  try {
    const accountRefsQuery: any = cache.readQuery({ query: getAccountRefsList });
    accountRefsQuery[USER][ACCOUNT_REFS] = accountRefsQuery[USER][ACCOUNT_REFS].map(
      (account: AccountModel) => {
        if (account.Id === accountPut.Id) {
          return createAccountRef(accountPut);
        }
        return account;
      },
    );
    cache.writeQuery({
      query: getAccountRefsList,
      data: {
        User: {
          ...accountRefsQuery[USER],
          AccountRefs: accountRefsQuery[USER][ACCOUNT_REFS],
        },
      },
    });
  } catch (e) {}
};
