import React, { ErrorInfo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { toastr } from "react-redux-toastr";
import { isDebugInfoEnabled } from "../../redux/reducers/appReducer";
import { ROUTES } from "../../_constant/screens";
import { RoutedProps, withRouter } from "../../routing/withRouter";

interface ErrorBoundaryProps extends RoutedProps {
  isDebugInfoEnabled: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
  message: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, message: "" };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, message: error.message });
    const { isDebugInfoEnabled } = this.props;
    if (isDebugInfoEnabled) {
      toastr.error(error.message);
      toastr.info(JSON.stringify(info));
    }
  }

  render() {
    const { hasError, message } = this.state;
    const { isDebugInfoEnabled, navigate, children } = this.props;

    if (hasError) {
      if (isDebugInfoEnabled) {
        return (
          <>
            <h1>Something went wrong.</h1>
            <div>{message}</div>
          </>
        );
      } else {
        navigate(ROUTES.ERROR);
        window.location.reload();
        return;
      }
    }

    return children;
  }
}

const mapStateToProps = (state) => ({
  isDebugInfoEnabled: isDebugInfoEnabled(state),
});

export default compose(connect(mapStateToProps), withRouter)(ErrorBoundary);
