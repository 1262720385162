import React, { useState, useCallback, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useGetAccountId from "../../../../customHooks/useGetAccountId";
import { useStaticDataQuery } from "../../../../queries/useStaticDataQuery";

import { ProjectModel } from "../../../../redux/models/data/ProjectModel";

import { LayoutType } from "../../../../_constant/constants";
// import { ROUTES } from "../../../../_constant/screens";
import { PROJECTS } from "../../../../_constant/wordings";
import { SORTING_DETAILS } from "../../../../_constant/ActivityDateConstant";

import { getFilterValue, getSearchStringValue } from "../../../../redux/reducers/filterReducer";

import { Collapse, useTheme } from "@material-ui/core";

import ActivityCap from "../../../../components/activity-cap";

import ProjectItem from "../../_components/ProjectItem";
import ActivitiesByStatusHeader from "../../../activities/all/content/ActivitiesByStatusHeader";
import { useLazyQuery } from "@apollo/react-hooks";
import { getActivityFilters } from "../../../../redux/reducers/appReducer";
import { convertStatusToFilterVariable } from "../../../../redux/actions/_utils";
import { GET_PROJECTS_LIST } from "../projects-list.query";
import { sortActivities, transformName } from "../../../../_utils/utils";
import { filterProjects } from "../../../../_utils/filtering";
import { getSelectedMemberId } from "../../../../redux/reducers/filterReducer";
import { updateProjectsEmptyStatus } from "../../../../redux/actions/actions";

import ProjectsDropWrapper from "../../../../components/ProjectsDropWrapper/ProjectsDropWrapper";
import ProjectItemDragWrapper from "../wrappers/ProjectItemDragWrapper";
import { useWindowSize } from "../../../../_utils/useWindowSize.hook";
// import { NoProjectsComponent } from "../../../../components/NoResultComponent/NoResultComponent";
import NoProjects from "../../../../components/NoResultComponent/NoProjects";

interface ProjectsByStatusProps {
  status: any;
  statuses: any;
  layout: LayoutType;
  isAccountPersonal: boolean;
  updateProjectStatus: (project: any, nextStatusId: string) => void;
  showEmptyMessage: boolean;
}

const ProjectsByStatus: React.FC<ProjectsByStatusProps> = (props) => {
  const { status, isAccountPersonal, layout, updateProjectStatus, showEmptyMessage } = props;
  const dispatch = useDispatch();

  const selectedMemberId = useSelector(getSelectedMemberId, shallowEqual);

  const [browserWindowWidth] = useWindowSize();
  const [isExpanded, setExpanded] = useState(true);

  const [fetchPolicy, setFetchPolicy] = useState<"cache-first" | "network-only">("cache-first");
  const [filteredProjects, setFilteredProjects] = useState<ProjectModel[]>([] as ProjectModel[]);

  const staticData = useStaticDataQuery();
  const emptyImgUrl = staticData ? staticData.Empty.projects : "";

  const searchString = useSelector(getSearchStringValue, shallowEqual);
  const filterMap = useSelector(
    (state) => getActivityFilters(state),
    (map1, map2) => {
      if (!map1.has(status?.Id)) {
        return true;
      }
      if (map1.get(status?.Id) === map2.get(status?.Id)) {
        return true;
      }
      return false;
    },
  );

  const { accountId, isPersonal } = useGetAccountId();

  const [getProjectsList, projectsData] = useLazyQuery(GET_PROJECTS_LIST, { fetchPolicy });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const requestFilterString = convertStatusToFilterVariable(status, filterMap, "ProjectStatusId");

  useEffect(() => {
    if (!accountId) return;
    if (projectsData.data && projectsData.data?.[PROJECTS]) return;

    getProjectsList({
      variables: {
        AccountId: accountId,
        Filter: requestFilterString,
      },
    });
  }, [accountId]);

  useEffect(() => {
    if (projectsData.data && projectsData.data?.[PROJECTS]) {
      setFetchPolicy("network-only");
    }
  }, [requestFilterString]);

  useEffect(() => {
    if (!accountId) return;
    if (fetchPolicy !== "network-only") return;
    getProjectsList({
      variables: {
        AccountId: accountId,
        Filter: requestFilterString,
      },
    });
  }, [fetchPolicy, accountId]);

  useEffect(() => {
    if (projectsData.data && !projectsData.loading && !projectsData.error) {
      const projectsList = projectsData.data?.[PROJECTS] || [];
      let filteredVisitsArr: ProjectModel[] = filterProjects(projectsList, searchString);

      if (selectedMemberId) {
        filteredVisitsArr = filteredVisitsArr.filter((visit) => {
          return visit.AssignedUserId === selectedMemberId;
        });
      }
      const actionObject = { status: status.Name, add: !filteredVisitsArr.length };
      dispatch(updateProjectsEmptyStatus(actionObject));

      setFilteredProjects(sortActivities(status?.Name, filteredVisitsArr));
      setFetchPolicy("cache-first");

      setFilteredProjects(sortActivities(status?.Name, filteredVisitsArr));
    }
  }, [projectsData.data, searchString, selectedMemberId]);

  const handleExpandStatus = useCallback((flag: boolean) => setExpanded(flag), []);
  const toggleExpandStatus = useCallback(() => setExpanded((prevValue) => !prevValue), []);

  const statusStyleName = status.Name.toLowerCase().split(" ").join("-");

  return (
    <ProjectsDropWrapper
      loading={projectsData.loading}
      expandStatus={handleExpandStatus}
      status={status}
      updateProjectStatus={updateProjectStatus}
    >
      <ActivitiesByStatusHeader
        status={status}
        layout={layout}
        onClick={toggleExpandStatus}
        isExpanded={isExpanded}
      />
      <div
        className={`ActivitiesList__statusColumnContent ${
          isExpanded ? "ActivitiesList__statusColumnContent--withPadding" : ""
        }`}
      >
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <div className={`ActivitiesList__wrapper ActivitiesList__wrapper--listView`}>
            {projectsData.loading && (
              <>
                <ActivityCap listView={layout === LayoutType.LIST} />
              </>
            )}

            {status.Name === "Not Started" && showEmptyMessage && (
              <NoProjects
                layout={layout}
                description={`There are no Projects. Clear the search field and filter or try to add a new Project.`}
                url={emptyImgUrl}
              />
            )}

            {!projectsData.loading &&
              filteredProjects.length > 0 &&
              filteredProjects.map((project) => (
                <ProjectItemDragWrapper
                  key={project.Id}
                  project={project}
                  layout={layout}
                  statusName={status.Name}
                  browserWindowWidth={browserWindowWidth}
                  isAccountPersonal={isAccountPersonal}
                >
                  <ProjectItem
                    key={project.Id}
                    project={project}
                    dateType={SORTING_DETAILS.get(status.Name)["field"]}
                    statusName={statusStyleName}
                    isAccountPersonal={isPersonal}
                  />
                </ProjectItemDragWrapper>
              ))}
          </div>
        </Collapse>
      </div>
    </ProjectsDropWrapper>
  );
};

export default ProjectsByStatus;
