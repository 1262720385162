import gql from "graphql-tag";

export const getVisitsByStatusQuery = gql`
  query getVisitsByStatus($Id: ID, $Filter: String) {
    Account(Id: $Id) {
      Id
      Visits(Filter: $Filter) {
        Id
        CustomerId
        VisitStatusId
        CustomerName @client
        VisitStatusName @client
        AssignedUserId
        Purpose
        Notes
        DueDateTime
        CompletedDateTime
        RelatedDueDateTime
        Schedule {
          RecurringType
        }
      }
    }
  }
`;
