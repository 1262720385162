import styled, { css } from "styled-components";

import { Box, Button, CardMedia, Paper, Typography } from "@material-ui/core";

export const CardContainer = styled(Paper)`
  position: relative;
  &.MuiPaper-root {
    width: 100%;
    max-width: 360px;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;

    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

interface AvatarContainerProp {
  $withouBackground?: boolean;
}
export const AvatarContainer = styled.div<AvatarContainerProp>`
  height: 172px;
  overflow: hidden;
  background-color: ${({ theme, $withouBackground }) =>
    $withouBackground ? "none" : theme.palette.primary.medium};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContent = styled.div`
  position: relative;
  padding: 12px 12px 0 12px;
`;

export const CardHeader = styled.header`
  margin-top: 0.625rem; // 10px
`;

export const CardTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
`;

export const CardFooter = styled.footer`
  margin-top: 0.875rem;
  min-height: 56px;
  background-color: ${({ theme }) => theme.palette.primary.light};
`;

export const CopyLinkButton = styled(Button)`
  padding-left: 1.125rem;
  width: 100%;
  min-height: 56px;
  justify-content: start;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CardMediaStyled = styled(CardMedia)`
  width: 100%;
  height: 172px;
  background-color: ${({ theme }) => theme.palette.primary.medium};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CardImageContainerProps {
  $withoutBorder: boolean;
}
export const CardImageContainer = styled(Box)<CardImageContainerProps>`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: ${({ theme, $withoutBorder }) =>
    $withoutBorder ? "none" : `1px solid ${theme.palette.greyDesign.onSurface3}`};
`;

const SelectTextStyle = css`
  font-size: 14px;
  font-weight: 400;
`;

export const SelectStyled = styled.div`
  width: fit-content;
  max-width: 100%;
  .MuiListItemText-root .MuiTypography-root {
    ${SelectTextStyle}
  }
`;

export const TextInsideImage = styled(Typography)`
  font-size: 2rem;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.background.default};
`;

export const CardLabelList = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const CardLabel = styled(Typography)`
  position: absolute;
  padding: 1px 4px;
  display: inline;
  width: auto;
  top: 18px;
  left: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  background-color: #81cc35;
  text-transform: uppercase;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
