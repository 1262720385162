import { DataProxy } from "apollo-cache/lib/types/DataProxy";
import { relatedItemForActivity } from "../../containers/activities/_components/form/updateFnAfterActivityRemove";

export const updateActivitiesAmountCache = (cache: DataProxy, ActivityPost: any) => {
  try {
    const options = {
      fragment: relatedItemForActivity,
      id: ActivityPost?.AssetId,
    };
    const relatedAsset: any = cache.readFragment(options);
    let activities = relatedAsset?.AssetActivities ?? [];

    const activitiesForCache = {
      Id: ActivityPost.Id,
      __typename: "AssetActivities",
    };

    activities = activities.concat([activitiesForCache]);

    cache.writeFragment({
      ...options,
      data: { ...relatedAsset, AssetActivities: activities },
    });
  } catch (error) {
    // console.error(error);
  }
};
