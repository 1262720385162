import * as React from "react";
import { useNavigate } from "react-router-dom";

import "./AccountCard.scss";

import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import EmojiPeopleOutlinedIcon from "@material-ui/icons/EmojiPeopleOutlined";

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { ACCOUNT, NAME, PERSONAL } from "../../_constant/wordings";
import { ROUTES } from "../../_constant/screens";
import { Users } from "../../redux/models/data/UsersModel";
import { CardLabel, CardLabelList, CardMediaContainer } from "./AccountCardStyle";

interface AccountCardProps {
  name: string;
  description: string;
  image: string | null;
  linkTo: string;
  // notificationsEnabled: boolean;
  personalImg: string;
  // members: Users[];
  isBusiness: boolean;
  isOwner: boolean;
}

const AccountCard: React.FC<AccountCardProps> = ({
  name,
  description,
  image,
  linkTo,
  // notificationsEnabled,
  personalImg,
  isBusiness,
  isOwner,
  // members,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.SyntheticEvent) => {
    if (name === PERSONAL) {
      return;
    }
    if (isBusiness && isOwner) {
      return navigate(ROUTES.EDIT_BUSINESS);
    }
    navigate(`${ROUTES.SINGLE_ACCOUNT}/${linkTo}`);
  };

  const renderImage = () => {
    if (name === PERSONAL) {
      return personalImg;
    }

    if (image) {
      return image;
    }

    return null;
  };

  const renderDescription = () => {
    if (name === PERSONAL) {
      return description || "My personal items and activities not shared with others";
    }
    return description;
  };

  const renderLabels = () => {
    const labels = [];
    if (isBusiness) {
      labels.push(<CardLabel key={0}>Customer management</CardLabel>);
    }

    return labels;
  };

  return (
    <Card className="AccountCard">
      <CardActionArea onClick={handleClick}>
        <CardMediaContainer>
          {renderImage() ? (
            <CardMedia
              component="img"
              image={renderImage() || ""}
              height={120}
              title="Image here"
            />
          ) : (
            <CardMedia>
              <Avatar>{name[0].toLocaleUpperCase()}</Avatar>
            </CardMedia>
          )}

          <CardLabelList>{renderLabels()}</CardLabelList>
        </CardMediaContainer>
        <CardContent>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="caption">{renderDescription()}</Typography>
          {/*<AvatarGroup max={6} className="AccountCard__users">*/}
          {/*  {members.map((user) => (*/}
          {/*    <Avatar key={user.Id}>{user.FirstName.charAt(0)}</Avatar>*/}
          {/*  ))}*/}
          {/*</AvatarGroup>*/}
        </CardContent>
      </CardActionArea>
      <CardActions>
        {name === PERSONAL ? <EmojiPeopleOutlinedIcon /> : <CreateOutlinedIcon />}
        <Box ml={2}>
          <Typography variant="body1">
            {name === PERSONAL ? "Default account" : "Edit account"}
          </Typography>
        </Box>
        {/*<Switch*/}
        {/*  size="small"*/}
        {/*  checked={true}*/}
        {/*  // onChange={handleChange}*/}
        {/*  color="primary"*/}
        {/*  name="checkedB"*/}
        {/*  inputProps={{ "aria-label": "primary checkbox" }}*/}
        {/*/>*/}
      </CardActions>
    </Card>
  );
};

export default AccountCard;
