import React from "react";

import WizardWelcomeScreen from "../WizardWelcomeScreen/WizardWelcomeScreen";
import WizardWelcomeScreenFooter from "../WizardWelcomeScreen/WizardWelcomeScreenFooter";
import WizardItemTypeScreen from "../WizardItemTypeScreen/WizardItemTypeScreen";
import WizardItemTypeFooter from "../WizardItemTypeScreen/WizardItemTypeFooter";
import WizardItemScreen from "../WizardItemScreen/WizardItemScreen";
import WizardItemFooter from "../WizardItemScreen/WizardItemFooter";
import WizardActivityScreen from "../WizardActivityScreen/WizardActivityScreen";
import WizardActivityFooter from "../WizardActivityScreen/WizardActivityFooter";
import WizardLoadingScreen from "../WizardLoadingScreen/WizardLoadingScreen";
import WizardLoadingFooter from "../WizardLoadingScreen/WizardLoadingFooter";
import WizardFinalScreen from "../WizardFinalScreen/WizardFinalScreen";
import WizardFinalScreenFooter from "../WizardFinalScreen/WizardFinalScreenFooter";

import { ActivityModel } from "../../../redux/models/data/ActivityModelFull";

import { RepeatAfter, RepeatType } from "../../../_constant/constants";
import WizardItemTypeContainer from "../WizardItemTypeContainer/WizardItemTypeContainer";
import WizardEditItemScreen from "../WizardEditItemScreen/WizardEditItemScreen";
import WizardCommonInfoScreen from "../WizardCommonInfoScreen/WizardCommonInfoScreen";

export const WIZARD_SCREENS_NAME = {
  welcome: 1,
  type_select: 2,
  item_select: 3,
  item_edit: 4,
  item_common_info: 5,
  activities_select: 6,
  loading: 7,
  success: 8,
  error: 9,
};

export const HEADER_OPTIONS = {
  none: "none",
  skip: "skip",
  close: "close",
};

export type HEADER_OPTIONS_TYPE =
  | typeof HEADER_OPTIONS.none
  | typeof HEADER_OPTIONS.skip
  | typeof HEADER_OPTIONS.close;

export const isWizardScreeForSelect = (step: number) => {
  const screensForEdit = [
    WIZARD_SCREENS_NAME.type_select,
    WIZARD_SCREENS_NAME.item_select,
    WIZARD_SCREENS_NAME.item_edit,
    WIZARD_SCREENS_NAME.item_common_info,
    WIZARD_SCREENS_NAME.activities_select,
  ];
  return screensForEdit.some((screen) => screen === step);
};

export const wizardScreens = new Map([
  [
    WIZARD_SCREENS_NAME.welcome,
    {
      title: "Welcome",
      headerAction: HEADER_OPTIONS.close,
      renderContent: (props?: any) => <WizardWelcomeScreen {...props} />,
      renderFooter: (props?: any) => <WizardWelcomeScreenFooter {...props} />,
      stepperTitle: null,
    },
  ],
  [
    WIZARD_SCREENS_NAME.type_select,
    {
      title: "Select an Item Type",
      headerAction: HEADER_OPTIONS.skip,
      renderContent: (props?: any) => <WizardItemTypeContainer {...props} />,
      renderFooter: (props?: any) => <WizardItemTypeFooter {...props} />,
      stepperTitle: "Item type",
    },
  ],
  [
    WIZARD_SCREENS_NAME.item_select,
    {
      title: "Select an Item",
      headerAction: HEADER_OPTIONS.skip,
      renderContent: (props?: any) => <WizardItemScreen {...props} />,
      renderFooter: (props?: any) => <WizardItemFooter {...props} />,
      stepperTitle: "Item",
    },
  ],
  [
    WIZARD_SCREENS_NAME.item_edit,
    {
      title: "Customize Your Item",
      headerAction: HEADER_OPTIONS.skip,
      renderContent: (props?: any) => <WizardEditItemScreen {...props} />,
      renderFooter: (props?: any) => <WizardItemFooter {...props} />,
      stepperTitle: "Customize",
    },
  ],
  [
    WIZARD_SCREENS_NAME.item_common_info,
    {
      title: "Enter Common Information",
      headerAction: HEADER_OPTIONS.skip,
      renderContent: (props?: any) => <WizardCommonInfoScreen {...props} />,
      renderFooter: (props?: any) => <WizardItemFooter {...props} />,
      stepperTitle: "Info",
    },
  ],
  [
    WIZARD_SCREENS_NAME.activities_select,
    {
      title: "Select Activities",
      headerAction: HEADER_OPTIONS.skip,
      renderContent: (props?: any) => <WizardActivityScreen {...props} />,
      renderFooter: (props?: any) => <WizardActivityFooter {...props} />,
      stepperTitle: "Activity",
    },
  ],
  [
    WIZARD_SCREENS_NAME.loading,
    {
      title: "Creating...",
      headerAction: HEADER_OPTIONS.none,
      renderContent: (props?: any) => <WizardLoadingScreen {...props} />,
      renderFooter: (props?: any) => <WizardLoadingFooter {...props} />,
      stepperTitle: null,
    },
  ],
  [
    WIZARD_SCREENS_NAME.success,
    {
      title: "Congratulations!",
      headerAction: HEADER_OPTIONS.close,
      renderContent: (props?: any) => <WizardFinalScreen {...props} />,
      renderFooter: (props?: any) => <WizardFinalScreenFooter {...props} />,
      stepperTitle: null,
    },
  ],
  [
    WIZARD_SCREENS_NAME.error,
    {
      title: "Something Happened!",
      headerAction: "cross",
      renderContent: (props?: any) => <WizardFinalScreen withError {...props} />,
      renderFooter: (props?: any) => <WizardFinalScreenFooter {...props} />,
      stepperTitle: null,
    },
  ],
]);

export const defaultItems: any = {
  "f7b9ed02-d5c9-4f34-8118-e093148b5e2f": [
    {
      Id: "1",
      Name: "Animal Item 1",
      AssetActivities: [
        new ActivityModel({
          Id: "20",
          Name: "Visit vet",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 12,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "21",
          Name: "Buy food",
          Schedule: {
            RecurringType: RepeatType.DAY,
            RecurrenceCount: 3,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "22",
          Name: "Buy toys",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "23",
          Name: "Buy cat house",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
      ],
    },
    {
      Id: "2",
      Name: "Animal Item 2",
      AssetActivities: [
        new ActivityModel({
          Name: "Animal Activities 1",
          Id: "Animal Activities 1",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 12,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "Animal Activities 2",
          Name: "Animal Activities 2",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
      ],
    },
    {
      Id: "17",
      Name: "Animal Item 3",
      AssetActivities: [
        new ActivityModel({
          Name: "Animal Activities 3",
          Id: "Animal Activities 3",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 12,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "Animal Activities 4",
          Name: "Animal Activities 4",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
      ],
    },
    {
      Id: "4",
      Name: "Appliance Item 2",
      AssetActivities: [
        new ActivityModel({
          Name: "Appliance Activities 3",
          Id: "Appliance Activities 3",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 12,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "Appliance Activities 4",
          Name: "Appliance Activities 4",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
      ],
    },
  ],
  "439612b4-fb28-4775-96c9-4fd7e9916a22": [
    {
      Id: "5",
      Name: "Electronic Item 1",
      AssetActivities: [
        new ActivityModel({
          Name: "Electronic Activities 1",
          Id: "Electronic Activities 1",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 12,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "Electronic Activities 2",
          Name: "Electronic Activities 2",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
      ],
    },
    {
      Id: "6",
      Name: "Electronic Item 2",
      AssetActivities: [
        new ActivityModel({
          Name: "Electronic Activities 3",
          Id: "Electronic Activities 3",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 12,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
        new ActivityModel({
          Id: "Electronic Activities 4",
          Name: "Electronic Activities 4",
          Schedule: {
            RecurringType: RepeatType.MONTH,
            RecurrenceCount: 6,
            RecurringRepeatType: RepeatAfter.COMPLETED_DATE,
          },
        }),
      ],
    },
  ],
  "e7c89b4c-ef47-48a7-8393-21cc74d5ddef": [
    {
      Id: "7",
      Name: "Furniture Item 1",
    },
    {
      Id: "8",
      Name: "Furniture Item 2",
    },
  ],
  "3d69a719-8b3f-45c2-b592-8be1f4aede63": [
    {
      Id: "9",
      Name: "Home Item 1",
    },
    {
      Id: "10",
      Name: "Home Item 2",
    },
  ],
  "544365a2-51cc-41b5-99e5-a380e236c848": [
    {
      Id: "11",
      Name: "Jewelry Item 1",
    },
    {
      Id: "12",
      Name: "Jewelry Item 2",
    },
  ],
  "602bf30a-8634-4efb-9ab5-85f1a74f850b": [
    {
      Id: "13",
      Name: "Valuable Item 1",
    },
    {
      Id: "14",
      Name: "Valuable Item 2",
    },
  ],
  "ae95caa0-b080-4f3f-83b9-7af817d48b83": [
    {
      Id: "15",
      Name: "Vehicle Item 1",
    },
    {
      Id: "16",
      Name: "Vehicle Item 2",
    },
  ],
};
