import React, { useEffect } from "react";
import useGetAccountId from "./useGetAccountId";
import { useQuery } from "react-apollo";
import { GET_CUSTOMER_ASSETS } from "../components/ItemsTab/getCustomerAssets.query";
import { CUSTOMER, ITEMS } from "../_constant/wordings";

const useGetRelatedCustomerItems = (
  customerId: string | null,
  handleFinishPopupLoading: () => void,
) => {
  const { accountId } = useGetAccountId();

  const { data: relatedItems, loading: isItemsLoading } = useQuery(GET_CUSTOMER_ASSETS, {
    variables: {
      AccountId: accountId,
      CustomerId: customerId,
    },
    skip: !customerId || !accountId,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (!isItemsLoading && relatedItems) {
      handleFinishPopupLoading();
    }
  }, [relatedItems, isItemsLoading]);

  return {
    relatedItems: relatedItems?.[CUSTOMER]?.[ITEMS] || [],
    isItemsLoading,
    isDataAvailable: !isItemsLoading && relatedItems,
  };
};

export default useGetRelatedCustomerItems;
