import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  useGetUserSubscriptionTier,
  subscriptionValues,
} from "../../customHooks/useGetUserSubscriptionTier";
import { useHeaderOptions } from "../HeaderOptionsProvider/useHeaderOptions";
import { useGetLimits } from "../../customHooks/useGetLimits";

import { Divider, List, ListItemIcon, ListItemText } from "@material-ui/core";

import { ROUTES } from "../../_constant/screens";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

import Button from "@material-ui/core/Button";

import { Popover } from "../Popover/Popover";
import { ListItemStyle } from "../PopupNavigation/PopupNavigationStyle";
import { getSettingsLinks, PopoverLinkItem } from "../SettingsDrawer/SettingsDrawer";

import "./SettingsNav.scss";

interface SettingsNavProps {}

const SettingsNav: React.FC<SettingsNavProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userSubscriptionTier = useGetUserSubscriptionTier();
  const { headerOptions } = useHeaderOptions();

  const limits = useGetLimits();
  const enableCalendarFeed = limits?.user.calendarIntegration.Value;
  const enableBusinessAccounts = userSubscriptionTier.value === subscriptionValues.professional;

  const links: PopoverLinkItem[] = getSettingsLinks({
    myBusiness: !enableBusinessAccounts,
    calendarFeeds: !enableCalendarFeed,
  });

  const getPageName = () => {
    return links.filter(({ url }) => location.pathname.includes(url)).map(({ name }) => name);
  };

  const handleLogout = () => {
    navigate(ROUTES.LOGOUT);
  };

  const targetTitle = getPageName().length ? getPageName()[0] : headerOptions.pageTitle;

  return (
    <Popover
      target={
        <Button endIcon={<KeyboardArrowDownIcon />} color="inherit">
          {targetTitle}
        </Button>
      }
      content={() => (
        <List component="nav" disablePadding>
          {links
            .filter((el) => !el.disabled)
            .map(({ name, url, icon }) => {
              return (
                <ListItemStyle
                  key={name}
                  button
                  disableGutters
                  component={Link}
                  to={url}
                  $padding="0.5rem 1rem"
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemStyle>
              );
            })}
          <Divider />
          <ListItemStyle
            $padding="0.5rem 1rem"
            button
            component={Link}
            to="/logout"
            onClick={handleLogout}
            disableGutters
          >
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemStyle>
        </List>
      )}
    />
  );
};

export default SettingsNav;
