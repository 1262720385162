import React from "react";

import styled from "styled-components";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { Box, Button, IconButton, Typography } from "@material-ui/core";

export const InfoIcon = styled(InfoOutlinedIcon)`
  font-size: 24px;
  margin-bottom: 2px;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
`;

export const CalendarPopoverButton: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <IconButton size="small" edge="start">
        <InfoIcon color="primary" />
      </IconButton>
      <Text>All Activities</Text>
    </Box>
  );
};

export const PopoverText = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.greyDesign.surface2};
`;

export const PopoverContainer = styled.section`
  max-width: 300px;
  padding: 1rem 1rem 0.5rem 1rem;
`;

interface CalendarPopoverContentProps {
  handleClose: VoidFunction;
}

export const CalendarPopoverContent: React.FC<CalendarPopoverContentProps> = ({ handleClose }) => {
  return (
    <PopoverContainer>
      <PopoverText>
        You can’t filter activities in your Personal account. You always see all activities because
        they’re all for you.
      </PopoverText>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" size="small" color="primary" onClick={handleClose}>
          Got it
        </Button>
      </Box>
    </PopoverContainer>
  );
};
