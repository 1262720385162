import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import useGetAccountId from "../../../customHooks/useGetAccountId";
import useGetProjectsStatuses from "../../../customHooks/api/useGetProjectsStatuses";

import { deleteProjectQuery } from "./deleteProject.query";
import { updateCacheAfterDeleteProject } from "./updateCacheAfterDeleteProject";

import {
  convertStatusToFilterVariable,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";
import { getActivityFilters } from "../../../redux/reducers/appReducer";
import { NotificationTypes } from "../../../_constant/NotificationTypes";

import { DeleteDialog } from "../../../containers/_common/Dialog";

interface ProjectDeleteDialogProps {
  id: string | null;
  statusId: string | null;
  handleCancel: () => void;
  withoutRedirect?: boolean;
}

const DeleteProjectDialog: React.FC<ProjectDeleteDialogProps> = (props) => {
  const { id, handleCancel, withoutRedirect, statusId } = props;

  const { accountId } = useGetAccountId();
  const navigate = useNavigate();

  const [removeProject, { loading: isRemoving }] = useMutation(deleteProjectQuery);
  const statuses = useGetProjectsStatuses();

  const foundStatus = statuses.find((status) => {
    return status.Id === statusId;
  });
  const filterMap = useSelector(
    (state) => getActivityFilters(state),
    (map1, map2) => {
      if (!map1.has(foundStatus?.Id)) {
        return true;
      }
      if (map1.get(foundStatus?.Id) === map2.get(foundStatus?.Id)) {
        return true;
      }
      return false;
    },
  );
  const filterString =
    foundStatus && convertStatusToFilterVariable(foundStatus, filterMap, "ProjectStatusId");

  const handleDelete = () => {
    if (!id || !accountId || !statusId) {
      return;
    }

    removeProject({
      variables: { ProjectId: id },
      update: (cache, { data: { ProjectDelete } }) => {
        updateCacheAfterDeleteProject(cache, ProjectDelete, accountId, filterString);
      },
    })
      .then(() => {
        if (!withoutRedirect) {
          navigate(-1);
        }
        displaySuccessNotification(NotificationTypes.PROJECT_DELETED);
      })
      .catch(() => {
        displayErrorNotification(NotificationTypes.PROJECT_DELETE_ERROR);
        handleCancel();
      });
  };

  return (
    <DeleteDialog isDataLoading={isRemoving} cancel={handleCancel} submit={handleDelete}>
      {"After deleting this Project, you won't be able to undo this action."}
    </DeleteDialog>
  );
};

export default DeleteProjectDialog;
