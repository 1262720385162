import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { useGetLimits } from "../../../customHooks/useGetLimits";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import useGetCustomerIdFromUrl from "../../../customHooks/useGetCustomerId";
import useSuccessfulRedirect from "../../../customHooks/useSuccessfulRedirect";
import useCreateAssetMutation from "../../../customHooks/useCreateAssetMutation";
import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";

import { AssetModel } from "../../../redux/models/data/AssetModel";

import { GET_ASSET_REFS } from "../all/assets.query";

import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";
import { setEditFormIsDirty } from "../../../redux/actions/actions";

import { ROUTES } from "../../../_constant/screens";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import {
  ACCOUNT,
  ASSET_REFS,
  FILE_UPLOAD_RESULTS,
  ID,
  ITEM_POST,
} from "../../../_constant/wordings";
import { SAVE, SAVE_AND_CLOSE, SaveType } from "../existing/EditAsset";

import AssetForm from "../_components/AssetForm";
import { ExceededLimitDialog } from "../../_common/Dialog";

interface EditAssetPageProps {}

const NewAssetPage: React.FC<EditAssetPageProps> = (props) => {
  const [isLimitExceeded, setLimitExceeded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountId } = useGetAccountId();
  const { successfulRedirect } = useSuccessfulRedirect();
  const customerId = useGetCustomerIdFromUrl();
  const limits = useGetLimits();

  const { setHeaderOptions } = useHeaderOptions({
    pageTitle: "Create New Item",
    withBackButton: true,
  });

  const { data: itemsData, loading: isItemsLoading, error } = useQuery(GET_ASSET_REFS, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (isItemsLoading || !itemsData || !limits) return;

    if (itemsData?.[ACCOUNT]?.[ASSET_REFS]?.length >= limits.account.maxItemsPerAccount.Value) {
      setLimitExceeded(true);
    } else {
      setLimitExceeded(false);
    }
  }, [limits, itemsData, isItemsLoading]);

  const [saveMutation, { isCreating }] = useCreateAssetMutation();

  const handleSave = (saveData: AssetModel, saveType: SaveType) => {
    if (customerId) {
      saveData.CustomerId = customerId;
    }
    saveMutation(saveData)
      .then(({ data }) => {
        const uploadResult = data[ITEM_POST]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        if (data[ITEM_POST][ID] && saveType.type === SAVE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new AssetModel(data[ITEM_POST]));

          displaySuccessNotification(NotificationTypes.ASSET_CREATED);
          const currentAssetPath = `${ROUTES.SINGLE_ASSET}/${data[ITEM_POST][ID]}`;

          navigate(currentAssetPath, {
            state: { justCreated: true },
          });
        } else if (data[ITEM_POST][ID] && saveType.type === SAVE_AND_CLOSE) {
          dispatch(setEditFormIsDirty(false));
          saveType.callback(new AssetModel(data[ITEM_POST]));
          if (customerId) {
            displaySuccessNotification(NotificationTypes.ASSET_CREATED);
            navigate(-1);
          } else {
            successfulRedirect(ROUTES.ASSETS, NotificationTypes.ASSET_CREATED);
          }
        } else {
          displayErrorNotification(NotificationTypes.ASSET_CREATE_ERROR);
        }
      })
      .catch((error) => {
        const message = error.networkError?.result?.errors[0]?.message.includes("GraphQL")
          ? error.networkError?.result?.errors[0]?.message.split(": ").slice(1).join(" ")
          : error.networkError?.result?.errors[0]?.message;

        if (message) {
          toastr.error(message && message.length < 100);
        } else {
          displayErrorNotification(NotificationTypes.ASSET_CREATE_ERROR);
        }
      });
  };

  return (
    <>
      <AssetForm
        {...props}
        handleSubmit={handleSave}
        isLoading={isCreating || isItemsLoading}
        setHeaderOptions={setHeaderOptions}
      />
      {isLimitExceeded && (
        <ExceededLimitDialog
          message="Creating a new Item would exceed your limit."
          submit={() => navigate(-1)}
          cancel={() => navigate(ROUTES.EDIT_PROFILE)}
        />
      )}
    </>
  );
};

export default NewAssetPage;
