import { DataProxy } from "apollo-cache/lib/types/DataProxy";

import { GET_ASSET_REFS } from "../../all/assets.query";

import {
  ACCOUNT,
  ASSET_REFS,
  ID,
  ASSET_TYPE,
  ITEM_TYPES,
  CUSTOMER,
  ITEMS,
  COMPONENT_IDS,
  PARENT_ID,
} from "../../../../_constant/wordings";

import { AssetRefsModel } from "../../../../redux/models/data/AssetRefsModel";
import { GET_CUSTOMER_ACTIVITIES } from "../../../../components/ActivitiesTab/getCustomerActivities.query";
import { GET_CUSTOMER_ASSETS } from "../../../../components/ItemsTab/getCustomerAssets.query";
import { GET_DETAILED_ITEM_TYPE_LIST } from "../../../_common/filter/asset-type.query";
import { GET_ASSETS_FOR_SELECT } from "../form/parent-asset.query";
import { getAssetQuery } from "../../existing/asset.query";
import { getExistAssets } from "../../../../components/PartsTabContainer/assets.query";

export function removeItemFromAssetReferencesQuery(
  cache: DataProxy,
  assetDelete,
  accountId,
  assetReferencesQuery,
) {
  try {
    const typeListQuery: any = cache.readQuery({
      query: GET_DETAILED_ITEM_TYPE_LIST,
      variables: { Id: accountId },
    });

    assetReferencesQuery[ACCOUNT][ASSET_REFS] = assetReferencesQuery[ACCOUNT][ASSET_REFS].filter(
      (item) => item[ID] !== assetDelete[ID],
    );

    // if this item was last for his type, and this type was not system
    // type should be removed from allTypes query
    let hasAssetTypeAnyItems = true;
    if (!assetDelete.AssetType.IsSystem) {
      hasAssetTypeAnyItems = assetReferencesQuery[ACCOUNT][ASSET_REFS].some(
        (asset: AssetRefsModel) => asset.AssetTypeRef.Id === assetDelete.AssetType.Id,
      );
    }

    if (!hasAssetTypeAnyItems) {
      typeListQuery[ACCOUNT][ITEM_TYPES] = typeListQuery[ACCOUNT][ITEM_TYPES].filter(
        (assetType) => assetType.Id !== assetDelete.AssetType.Id,
      );
    }

    cache.writeQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
      data: assetReferencesQuery,
    });
    cache.writeQuery({
      query: GET_DETAILED_ITEM_TYPE_LIST,
      variables: { Id: accountId },
      data: typeListQuery,
    });
  } catch (error) {}
}

export function removeCacheAfterAssetDelete(
  cache: DataProxy,
  customerId: string,
  accountId: string,
  deletedAsset: any,
) {
  try {
    const customerActivitiesQuery: any = cache.readQuery({
      query: GET_CUSTOMER_ACTIVITIES,
      variables: { CustomerId: customerId, AccountId: accountId },
    });

    const customerAssets: any = cache.readQuery({
      query: GET_CUSTOMER_ASSETS,
      variables: { CustomerId: customerId, AccountId: accountId },
    });

    customerActivitiesQuery[CUSTOMER]["AssetActivities"] = customerActivitiesQuery[CUSTOMER][
      "AssetActivities"
    ].filter((el: any) => {
      return el.Asset.Id !== deletedAsset.Id;
    });

    customerAssets[CUSTOMER][ITEMS] = customerAssets[CUSTOMER][ITEMS].filter(
      (el: any) => el.Id !== deletedAsset.Id,
    );

    cache.writeQuery({
      query: GET_CUSTOMER_ACTIVITIES,
      variables: { CustomerId: customerId, AccountId: accountId },
      data: customerActivitiesQuery,
    });

    cache.writeQuery({
      query: GET_CUSTOMER_ASSETS,
      variables: { CustomerId: customerId, AccountId: accountId },
      data: customerAssets,
    });
  } catch (error) {}
}

export function removeItemFromPartOfSelect(
  cache: DataProxy,
  assetDelete,
  accountId) {

  try {
    const cacheData: any = cache.readQuery({
      query: GET_ASSETS_FOR_SELECT,
      variables: { Id: accountId },
    });

    cacheData[ACCOUNT][ITEMS] = cacheData[ACCOUNT][ITEMS].filter((item) => item[ID] !== assetDelete[ID]);

    cache.writeQuery({
      query: GET_ASSETS_FOR_SELECT,
      variables: { Id: accountId },
      data: cacheData,
    });
  } catch (error) {}
  
}

export function removeIdFromComponentIdsInParentAsset(cache: DataProxy,
  currentAsset,
  accountId,
  parentId = currentAsset[PARENT_ID],
) {
  try {
    const existAssetsCacheData: any = cache.readQuery({
      query: getExistAssets,
      variables: { Id: accountId },
    });

    const parentAsset = existAssetsCacheData[ACCOUNT][ITEMS].find((asset) => asset.Id === parentId)
    existAssetsCacheData[ACCOUNT][ITEMS] = existAssetsCacheData[ACCOUNT][ITEMS].map((asset) => {
      if (asset.Id === parentAsset.Id) {
        parentAsset[COMPONENT_IDS] = parentAsset[COMPONENT_IDS].filter((childId) => childId !== currentAsset.Id);
        return parentAsset;
      } else {
        return asset;
      }
    });

    cache.writeQuery({
      query: getExistAssets,
      variables: {
        Id: accountId,
      },
      data: existAssetsCacheData,
    });
  } catch (error) {}
}

export function removeAllRelatedAssetsAfterDeleteParentAsset(
  cache: DataProxy,
  accountId: string,
  relatedParts: any[] | [],
  assetReferencesQuery: any,
  customerId = "",
  ) {
  try {
    if (!relatedParts.length) return;
    const existAssetsCacheData: any = cache.readQuery({
      query: getExistAssets,
      variables: { Id: accountId },
    });

    const assetsForSelectCache: any = cache.readQuery({
      query: GET_ASSETS_FOR_SELECT,
      variables: { Id: accountId },
    });

    const allAssetsWithoutDeletedAsset = existAssetsCacheData[ACCOUNT][ITEMS];

    const genIdsArrOfAllDeletedAssets = (childrenIds: string[] | []): string[] | [] => {
      if (childrenIds.length === 0) return [];
      return childrenIds.flatMap((childId) =>
        [childId].concat(genIdsArrOfAllDeletedAssets(allAssetsWithoutDeletedAsset.find((asset) => asset.Id === childId)[COMPONENT_IDS])),
      );
    };

    const relatedIds = relatedParts.map((part) => part.Id);
    const idsArrOfAllDeletedAssets = genIdsArrOfAllDeletedAssets(relatedIds);
    
    

    if (!!customerId) {
      assetReferencesQuery[CUSTOMER][ITEMS] = assetReferencesQuery[CUSTOMER][ITEMS].filter((asset) => {
      return !idsArrOfAllDeletedAssets.includes(asset.Id);
      });

      cache.writeQuery({
      query: GET_CUSTOMER_ASSETS,
      variables: { CustomerId: customerId, AccountId: accountId },
      data: assetReferencesQuery,
    });
    };

    if (!customerId) {
      assetReferencesQuery[ACCOUNT][ASSET_REFS] = assetReferencesQuery[ACCOUNT][ASSET_REFS].filter((asset) => {
      return !idsArrOfAllDeletedAssets.includes(asset.Id);
      });

      cache.writeQuery({
      query: GET_ASSET_REFS,
      variables: { Id: accountId },
      data: assetReferencesQuery,
    });
    }

    assetsForSelectCache[ACCOUNT][ITEMS] = assetsForSelectCache[ACCOUNT][ITEMS].filter((asset) => {
      return !idsArrOfAllDeletedAssets.includes(asset.Id);
    });

    cache.writeQuery({
      query: GET_ASSETS_FOR_SELECT,
      variables: { Id: accountId },
      data: assetsForSelectCache,
    });
  } catch (e) {}
}
