import * as React from "react";
import { connect } from "react-redux";
import { getSelectedAssetIds } from "../../redux/reducers/filterReducer";
import { useEffect } from "react";
import { alphabetNameSorting, getAssetImage } from "../../_utils/utils";
import { useStaticDataQuery } from "../../queries/useStaticDataQuery";

const NavFilterAssetTypes = ({
  AssetTypeId,
  Name,
  IconName,
  Assets,
  expandedAssetTypeId,
  setExpanded,
  setFilter,
  selectedAssetIds,
}) => {
  const [pickedAssets, setPickedAssets] = React.useState(new Set());
  const staticData = useStaticDataQuery();

  useEffect(() => {
    if (selectedAssetIds !== pickedAssets) {
      setPickedAssets(selectedAssetIds);
    }
  }, [selectedAssetIds, pickedAssets]);

  const handleAssetTypeSelected = () => {
    setExpanded((prevExpandedAssetType) =>
      prevExpandedAssetType !== AssetTypeId ? AssetTypeId : null,
    );

    if (expandedAssetTypeId === AssetTypeId) {
      setFilter();
    } else {
      setFilter(
        AssetTypeId,
        new Set(),
        Assets.map((item) => item.Id),
      );
    }
    setPickedAssets(new Set());
  };

  const handleAssetFilter = (assetId) => {
    if (pickedAssets.has(assetId)) {
      pickedAssets.delete(assetId);
      setPickedAssets(new Set(pickedAssets));
    } else if (pickedAssets.size) {
      pickedAssets.clear();
      setPickedAssets(new Set(pickedAssets.add(assetId)));
    } else {
      setPickedAssets(new Set(pickedAssets.add(assetId)));
    }
    setFilter(
      AssetTypeId,
      pickedAssets,
      Assets.map((item) => item.Id),
    );
  };

  return (
    <li
      className={`NavFilter_assetType ${
        expandedAssetTypeId === AssetTypeId ? "NavFilter_assetType-expanded" : ""
      }`}
    >
      <div className="NavFilter_assetTypeTitle" onClick={handleAssetTypeSelected}>
        <i className="Icon NavFilter_assetTypeIcon">{IconName}</i>
        <h3 className="NavFilter_assetTypeName">{Name}</h3>
      </div>
      {Assets && (
        <ul
          className={`NavFilter_itemsList ${
            expandedAssetTypeId === AssetTypeId ? "NavFilter_itemsList-isVisible" : ""
          }`}
        >
          {Assets.sort(alphabetNameSorting).map((asset) => (
            <li
              key={asset.Id}
              className={`NavFilter_item ${
                pickedAssets.has(asset.Id) ? "NavFilter_item-isActive" : ""
              }`}
              onClick={() => handleAssetFilter(asset.Id)}
            >
              {asset.ImageUrl ? (
                <img
                  src={asset.ImageUrl}
                  width="28"
                  height="28"
                  className="NavFilter_itemImg"
                  alt=""
                />
              ) : (
                <img
                  className="Image-default NavFilter_itemImg"
                  src={getAssetImage(staticData, Name)}
                  alt={asset.Name}
                />
              )}
              {asset.Name}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const mapStateToProps = (state) => ({
  selectedAssetIds: getSelectedAssetIds(state),
});

export default connect(mapStateToProps)(NavFilterAssetTypes);
