import styled from "styled-components";

import { IconButton } from "@material-ui/core";

import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

interface SearchFormProps {
  $expanded?: boolean;
}
export const SearchForm = styled.div<SearchFormProps>`
  width: 100%;
  position: relative;

  .MuiAutocomplete-inputRoot {
    flex-wrap: ${({ $expanded }) => ($expanded ? "wrap" : "nowrap")};
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 32px;
  }

  .MuiInputBase-root {
    width: 100%;
    padding: 0 var(--space-size--xs);
    background-color: var(--color-white);
    border-radius: var(--border-radius-xs);

    .MuiInputBase-input {
      color: var(--color-text);
    }

    .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;
      color: var(--color-darker);
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: var(--space-size--xs);
`;

export const StyledSearchOutlinedIcon = styled(SearchOutlinedIcon)`
  position: absolute;
  right: var(--space-size--xs);
`;
