import React from "react";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AddIcon from "@material-ui/icons/Add";
import { CardAdditionalInfo } from "../Cards/styled/card.styled";
import { ROUTES } from "../../_constant/screens";
import { setSelectedAssets } from "../../redux/actions/actions";
import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

interface AssetCardAdditionalInfoProps {
  item: AssetRefsModel;
  setFilter: any;
}

const AssetCardLink: React.FC<AssetCardAdditionalInfoProps> = ({ item, setFilter }) => {
  const { Id, Name, AssetTypeRef, MainImage, HasActivities } = item;

  const navigate = useNavigate();

  const handleSelectNewActivity = (e) => {
    e.stopPropagation();
    navigate(`${ROUTES.CREATE_ACTIVITY}?item=${Id}`);
  };

  const handleSelectActivitiesList = (e) => {
    e.stopPropagation();

    const itemsFilter: Set<string> = new Set();
    itemsFilter.add(Id);

    setFilter(AssetTypeRef.Id, itemsFilter);
    navigate(ROUTES.ACTIVITIES);
  };
  return (
    <CardAdditionalInfo
      $contentType="link"
      onClick={HasActivities ? handleSelectActivitiesList : handleSelectNewActivity}
    >
      {HasActivities ? <ListAltOutlinedIcon /> : <AddIcon />}
      <span>{HasActivities ? "Activities List" : "Create Activity"}</span>
    </CardAdditionalInfo>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, assets?: Set<string>, relatedItems?) =>
    dispatch(setSelectedAssets(assetType, assets, relatedItems)),
});

export default connect(null, mapDispatchToProps)(AssetCardLink);
