import React from "react";

import { IconButton, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import {
  StyledAutocompleteContainer,
  StyledAutocomplete,
  StyledChip,
  StyledPaper,
  PaperContainer,
} from "../TagsAutocomplete/ItemTagsAutocomplete.styles";

import { StyledIconButton, StyledSearchOutlinedIcon } from "./SearchTagAutocomplete.style";

interface ItemTagsAutocompleteProps {
  searchValue: string;
  checkedTags: string[];
  allTags: string[];
  allTagsLoaded: boolean;
  placeholder: string;
  clearInput: () => void;
  setTags: (newValue: string[]) => void;
  onInputChange: (event: any, value: string) => void;
  limitTags?: number;
}

const SearchTagsAutocomplete: React.FC<ItemTagsAutocompleteProps> = ({
  onInputChange,
  checkedTags,
  allTags,
  allTagsLoaded,
  clearInput,
  setTags,
  searchValue,
  placeholder,
  limitTags = 2,
}) => {
  const renderEndInputIcon = (searchValue: string, tags: string[]): React.ReactNode => {
    return (
      <>
        {!searchValue && !tags.length ? (
          <StyledSearchOutlinedIcon />
        ) : (
          <StyledIconButton onClick={clearInput}>
            <ClearIcon />
          </StyledIconButton>
        )}
      </>
    );
  };

  return (
    <StyledAutocompleteContainer item xs={12}>
      <StyledAutocomplete
        id="tags"
        multiple
        limitTags={limitTags}
        value={checkedTags}
        options={allTags}
        filterSelectedOptions
        clearOnBlur={false}
        openOnFocus={true}
        onChange={(event, newValue) => {
          setTags(newValue);
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <StyledChip
              deleteIcon={
                <IconButton size="small">
                  <ClearIcon />
                </IconButton>
              }
              label={option}
              {...getTagProps({ index })}
            />
          ));
        }}
        onInputChange={onInputChange}
        loading={allTagsLoaded}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: renderEndInputIcon(searchValue, checkedTags),
              }}
              // inputRef={desktopInputElement}
              variant="outlined"
              placeholder={checkedTags.length === 0 ? placeholder : ""}
            />
          );
        }}
        renderOption={(option, state) => {
          return <StyledChip label={option} />;
        }}
        PaperComponent={({ children }) => (
          <PaperContainer>
            <StyledPaper>{children}</StyledPaper>
          </PaperContainer>
        )}
      />
    </StyledAutocompleteContainer>
  );
};

export default SearchTagsAutocomplete;
