import * as React from "react";
import { connect } from "react-redux";
import NavFilterAssetTypes from "./NavFilterAssetTypes";
import { getSelectedAssetType } from "../../redux/reducers/filterReducer";
import { alphabetNameSorting } from "../../_utils/utils";
import { AssetTypeModel } from "../../redux/models/data/AssetTypeModel";
import useGetAccountId from "../../customHooks/useGetAccountId";
import { useQuery } from "react-apollo";
import { GET_DETAILED_ITEM_TYPE_LIST } from "../../containers/_common/filter/asset-type.query";
import { useEffect } from "react";
import { useState } from "react";
import { getItem } from "../../containers/_common/filter/SideMenu";
import { ACCOUNT, ASSET_REFS, ITEM_TYPES } from "../../_constant/wordings";
import { setSelectedAssets } from "../../redux/actions/actions";
import { GET_ASSET_REFS } from "../../containers/assets/all/assets.query";
import { AssetRefsModel } from "../../redux/models/data/AssetRefsModel";

const NavFilter = (props) => {
  const [assetTypes, setAssetTypesData] = useState([]);
  const [items, setItems] = useState(new Map());
  const [selectedType, setSelectedType] = React.useState(null);

  const { accountId } = useGetAccountId();
  const { data, loading, error } = useQuery(GET_DETAILED_ITEM_TYPE_LIST, {
    variables: {
      Id: accountId,
    },
    skip: !accountId,
    fetchPolicy: "cache-first",
  });

  const { data: assetsData, loading: assetsLoading, error: assetsError } = useQuery(
    GET_ASSET_REFS,
    {
      variables: {
        Id: accountId,
      },
      skip: !accountId,
      fetchPolicy: "cache-first",
    },
  );

  useEffect(() => {
    if (!loading && !error && data) {
      const newAssetTypes = (data[ACCOUNT][ITEM_TYPES] || []).sort(alphabetNameSorting);
      setAssetTypesData(newAssetTypes);
    }
  }, [data]);

  useEffect(() => {
    if (assetsData && assetsData?.[ACCOUNT]?.[ASSET_REFS]) {
      const itemsData = new Map();
      assetsData[ACCOUNT][ASSET_REFS] &&
        assetsData[ACCOUNT][ASSET_REFS].forEach((item: AssetRefsModel) => {
          itemsData.set(item.AssetTypeRef.Id, [
            getItem(item),
            ...(itemsData.get(item.AssetTypeRef.Id) || []),
          ]);
        });
      setItems(itemsData);
    }
  }, [assetsData]);

  useEffect(() => {
    if (props.selectedAssetType !== selectedType) {
      setSelectedType(props.selectedAssetType);
    }
  }, [props.selectedAssetType]);

  return (
    <section className="NavFilter MobileMenu_navFilter">
      <header className="NavFilter_header">
        <h3 className="NavFilter_title">Filter</h3>
        <button
          className="Button-link NavFilter_clearButton"
          onClick={() => props.setFilter()}
          disabled={!props.selectedAssetType}
        >
          <div
            className={`NavFilter_clearIconContainer ${selectedType && "SideMenu_action-warning"}`}
          >
            <i className="Icon Icon-filter_list NavFilter_filterIcon" />
          </div>
        </button>
      </header>
      <ul className="NavFilter_assetTypeList">
        {assetTypes
          .filter((assetType: AssetTypeModel) => items.has(assetType.Id))
          .map(({ Id, ...otherAssetTypeProps }) => (
            <NavFilterAssetTypes
              key={Id}
              expandedAssetTypeId={selectedType}
              setExpanded={setSelectedType}
              AssetTypeId={Id}
              Assets={items.get(Id)}
              setFilter={props.setFilter}
              {...otherAssetTypeProps}
            />
          ))}
      </ul>
    </section>
  );
};

const mapStateToProps = (state) => ({
  selectedAssetType: getSelectedAssetType(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, assets?: Set<string>, relatedItems?) => {
    dispatch(setSelectedAssets(assetType, assets, relatedItems));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavFilter);
