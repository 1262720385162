import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { LoaderCircle } from "./Loader";

import { Collapse } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface AccordionProps {
  label: any;
  helper?: any;
  children: any;
  isLoading?: boolean;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  expanded?: boolean;
  dndRef?: any;
  className?: string;
  withBackground?: boolean;
  withExpandIcon?: boolean;
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    isLoading,
    expanded,
    label,
    helper,
    dndRef,
    className,
    withBackground = true,
    withExpandIcon = true,
  } = props;
  const disabled = props.disabled || isLoading;
  const [isOpen, setOpenFlag] = useState(expanded);

  const handleOpen = () => {
    if (props.disabled) {
      return;
    }
    // close if opened
    if (isOpen) {
      setOpenFlag(false);
      if (props.onClose) {
        props.onClose();
      }
      return;
    }
    // custom: if props callback - call it
    if (props.onOpen) {
      props.onOpen();
    }

    setOpenFlag(true);
  };

  useEffect(() => {
    if (expanded) {
      if (props.onOpen) {
        props.onOpen();
      }
      setOpenFlag(true);
    }
  }, [expanded]);
  const nameOfClass = `Accordion ${isOpen ? "is-opened" : ""} ${
    withBackground ? "withBackground" : ""
  } ${disabled ? "disabled" : ""} ${className || ""}`;
  return (
    <div className={nameOfClass} ref={dndRef}>
      <div className="Accordion_header" onClick={disabled ? undefined : handleOpen}>
        <span>{label}</span>
        {withExpandIcon && <ExpandMoreIcon className="Icon Icon-expand_more" />}
        {isLoading && <LoaderCircle size={"s"} />}
        <span className="Accordion_helper" onClick={(e) => e.stopPropagation()}>
          {helper}
        </span>
      </div>
      <Collapse in={!isLoading && isOpen} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
};

export default Accordion;
