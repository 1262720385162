import styled from "styled-components";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import { LayoutType } from "../../_constant/constants";

interface withLoyautProps {
  $layout: LayoutType;
}
export const NoActivitiesPaperStyle = styled(Paper)<withLoyautProps>`
  &.MuiPaper-rounded {
    border-radius: 8px;
  }
  &.MuiPaper-root {
    width: 100%;

    margin-top: ${({ $layout }) => ($layout === LayoutType.BOARD ? "calc(40vh - 190px)" : "0")};

    @media (max-width: 600px) {
      margin-top: ${({ $layout }) => ($layout === LayoutType.BOARD ? "calc(50vh - 190px)" : "0")};
    }
  }
`;

export const NoActivitiesContainer = styled.div<withLoyautProps>`
  padding: ${({ $layout }) => ($layout === LayoutType.BOARD ? "1rem;" : ".5rem 1rem")};
  display: flex;
  flex-direction: ${({ $layout }) => ($layout === LayoutType.BOARD ? "column" : "row")};
  align-items: ${({ $layout }) => ($layout === LayoutType.BOARD ? "flex-start" : "center")};
  justify-content: space-between;
`;

export const NoActivitiesHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

export const NoActivitiesImageContainer = styled.section<withLoyautProps>`
  margin-top: ${({ $layout }) => ($layout === LayoutType.BOARD ? "1rem" : "0")};
  margin-left: ${({ $layout }) => ($layout === LayoutType.BOARD ? "0" : "1rem")};
  border: ${({theme}) => `1px solid ${theme.palette.greyDesign.onSurface3}` };
  border-radius: 4px;
  overflow: hidden;

  width: ${({ $layout }) => ($layout === LayoutType.BOARD ? "100%" : "108px")};
  height: ${({ $layout }) => ($layout === LayoutType.BOARD ? "112px" : "72px")};
`;

interface NoActivitiesImageProps {
  $url: string;
  $retinaUrl: string;
}
export const NoActivitiesImage = styled.div<NoActivitiesImageProps>`
  background-color: ${({ theme }) => `${theme.palette.background.default}`};
  width: 100%;
  height: 100%;
  background-image: ${({ $url }) => `url(${$url})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: ${({ $retinaUrl }) => `url(${$retinaUrl})`};
  }
`;

export const NoActivitiesTitle = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const NoActivitiesDescription = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
