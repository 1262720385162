import * as React from "react";
import { useMemo, useState } from "react";
import { HeaderOptionsModel } from "./HeaderOptionsModel";

export const HeaderOptionsContext = React.createContext(undefined);

const HeaderOptionsProvider: React.FC = ({ children }) => {
  const [headerOptions, setHeaderOptions] = useState(new HeaderOptionsModel());

  const data = useMemo(() => ({ headerOptions, setHeaderOptions }), [
    headerOptions,
    setHeaderOptions,
  ]);

  return <HeaderOptionsContext.Provider value={data}>{children}</HeaderOptionsContext.Provider>;
};

export default HeaderOptionsProvider;
