import { Image } from "./ImageModel";

export class CustomerModel {
  Id: string | null;
  Name: string;
  Address: string;
  Email: string;
  Phone: string;
  Images: Image[];
  Documents: any[];
  Videos: any[];
  Files: any;

  constructor({
    Id = null as null | string,
    Name = "",
    Address = "",
    Email = "",
    Phone = "",
    Images = [] as Image[],
    Documents = [] as any[],
    Videos = [] as any[],
    Files = [] as any,
  } = {}) {
    this.Id = Id;
    this.Name = Name;
    this.Address = Address || "";
    this.Email = Email || "";
    this.Phone = Phone || "";
    this.Images = Images || [];
    this.Documents = Documents || [];
    this.Videos = Videos || [];
    this.Files = Files || [];
  }
}
