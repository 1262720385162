import { useContext, useCallback } from "react";
import { LeaveDialogContext } from "./LeaveDialogProvider";

type showDialogBeforeChangeUrlType = (props?: () => void) => void;

export const useLeaveDialogContext = () => {
  const context = useContext(LeaveDialogContext);

  if (context === undefined) {
    throw new Error("useLeaveDialogContext must be used within a LeaveDialogProvider");
  }

  const {
    showPrompt,
    setShowPrompt,
    deferredCallback,
    lastLocation,
    setLastLocation,
    confirmedNavigation,
    setConfirmedNavigation,
  } = context;

  const togglePrompt = (flag: boolean) => {
    setShowPrompt(flag);
  };

  const changeLastLocation = useCallback((value: any) => {
    setLastLocation(value);
  }, []);

  const toggleConfirmedNavigation = useCallback((value: boolean) => {
    setConfirmedNavigation(value);
  }, []);

  const showDialogBeforeChangeUrl: showDialogBeforeChangeUrlType = (callback) => {
    if (callback) deferredCallback.current = callback;

    togglePrompt(true);
  };

  return {
    showPrompt,
    togglePrompt,
    deferredCallback,
    changeLastLocation,
    toggleConfirmedNavigation,
    lastLocation,
    confirmedNavigation,
    showDialogBeforeChangeUrl,
  };
};
