import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { connect } from "react-redux";
import AssetForm from "../_components/AssetForm";
import {
  ACCOUNT,
  FILE_UPLOAD_RESULTS,
  ID,
  ITEM_PUT,
  ITEMS,
  PARENT_ID,
} from "../../../_constant/wordings";
import {
  checkUploadResult,
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../redux/actions/_utils";
import { ROUTES } from "../../../_constant/screens";
import { NotificationTypes } from "../../../_constant/NotificationTypes";
import { AssetModel } from "../../../redux/models/data/AssetModel";
import useGetAccountId from "../../../customHooks/useGetAccountId";
import * as config from "../../../../configs/statuses-config.json";
import { updateAssetRefsQuery, updateAssetTypeListQuery } from "./updateCacheFunction";
import useGetAcitvitiesStatuses from "../../../customHooks/api/useGetAcitvitiesStatuses";
import { getAssetQuery, updateAssetQuery } from "./asset.query";
import { setEditFormIsDirty, setSelectedAssets } from "../../../redux/actions/actions";
import { useHeaderOptions } from "../../../components/HeaderOptionsProvider/useHeaderOptions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useSuccessfulRedirect from "../../../customHooks/useSuccessfulRedirect";
import { formatDateInCustomFieldsForSubmit } from "../../../_utils/date";
import { updateComponentIdsForParentAssetWithNewPart } from "../new/updateCacheWithNewItem";
import { removeIdFromComponentIdsInParentAsset } from "../_components/deleteAssetPopup/updateCacheFunction";

export const SAVE = "SAVE";
export const SAVE_AND_CLOSE = "SAVE_AND_CLOSE";

export interface SaveType {
  type: typeof SAVE | typeof SAVE_AND_CLOSE;
  callback: (data?: any) => void;
}

interface EditAssetPageProps {
  assetId: string;
  showOnLeavePopup: boolean;
  saveAssetImages: (AccountId: string, AssetId: string) => Promise<any>;
  clearAssetInfo: () => void;
  setFilter: (assetType?: string, items?: Set<string>, relatedItems?) => void;
  setFormDirtyFlag: (flag: boolean) => void;
}

const { EMPTY } = config["statusNameMap"];

const EditPage: React.FC<EditAssetPageProps> = (props) => {
  const { setFilter, setFormDirtyFlag, ...rest } = props;

  const navigate = useNavigate();
  const params = useParams();
  const locationState = useLocation().state as {
    goToEditPrevAsset: boolean;
    startPartTab: boolean;
  };
  const { successfulRedirect } = useSuccessfulRedirect();

  const [asset, setAsset] = useState(new AssetModel());

  const { accountId } = useGetAccountId();
  const { setHeaderOptions } = useHeaderOptions({
    pageTitle: "Edit Item",
    withBackButton: true,
  });

  const { data, loading: isLoadingAssetData, error } = useQuery(getAssetQuery, {
    variables: {
      Id: accountId,
      AssetId: params?.id,
    },
    fetchPolicy: "network-only",
    skip: !accountId,
    onError: () => {
      navigate(ROUTES.ASSETS);
      displayErrorNotification(NotificationTypes.ASSET_ACCESS_ERROR);
    },
  });

  const [updateAsset, { loading: isSaving }] = useMutation(updateAssetQuery);

  useEffect(() => {
    if (data && !isLoadingAssetData && !isSaving && !error) {
      const item = data[ACCOUNT][ITEMS][0];
      setAsset(new AssetModel(item));
    }
  }, [data]);

  const updateAssetInfo = (saveData: AssetModel, saveType: SaveType) => {
    if (!accountId) {
      console.error("Trying to do request without account id");
      return;
    }
    const imagesWithAppropriateTypename = saveData.Images.map((image) => {
      delete image.__typename;
      return image;
    });

    const documentsWithAppropriateTypename = saveData.Documents.map((doc) => {
      delete doc.__typename;
      return doc;
    });

    const videosWithAppropriateTypename = saveData.Videos.map((video) => {
      delete video.__typename;
      return video;
    });

    const schemaWithAppropriateTypename = saveData.Schema.map((element) => {
      delete element.__typename;
      return element;
    });

    delete saveData.AssetType.__typename;
    delete saveData.DefaultDescription;
    delete data._activitiesLength;
    formatDateInCustomFieldsForSubmit(saveData?.CustomFields);

    const variables = {
      ...saveData,
      AssetType: {
        Id: saveData.AssetType.Id,
        Name: saveData.AssetType.Name,
        IconName: saveData.AssetType.IconName,
      },
      AccountId: accountId,
      Images: imagesWithAppropriateTypename,
      Documents: documentsWithAppropriateTypename,
      Videos: videosWithAppropriateTypename,
      Files: saveData.Files,
      Schema: schemaWithAppropriateTypename,
    };

    const isTypeChanged = asset.AssetType.Id !== saveData.AssetType.Id;

    updateAsset({
      variables,
      update(cache, { data: { AssetPut } }) {
        updateAssetRefsQuery(cache, AssetPut, accountId);
        if (AssetPut[PARENT_ID] !== asset[PARENT_ID]) {
          removeIdFromComponentIdsInParentAsset(cache, AssetPut, accountId, asset[PARENT_ID]);
          AssetPut[PARENT_ID] !== null &&
            updateComponentIdsForParentAssetWithNewPart(cache, AssetPut, accountId);
        }

        if (isTypeChanged) {
          updateAssetTypeListQuery(cache, AssetPut, accountId, asset.AssetType.Id);
          setFilter();
        }
      },
    })
      .then(({ data }) => {
        const uploadResult = data[ITEM_PUT]?.[FILE_UPLOAD_RESULTS] ?? [];
        checkUploadResult(uploadResult);

        const customerIdResponse = data[ITEM_PUT]?.CustomerId;

        if (data[ITEM_PUT][ID] && saveType.type === SAVE) {
          saveType.callback(new AssetModel(data[ITEM_PUT]));
          displaySuccessNotification(NotificationTypes.ASSET_UPDATED);
        } else if (data[ITEM_PUT][ID] && saveType.type === SAVE_AND_CLOSE) {
          setFormDirtyFlag(false);
          saveType.callback(new AssetModel(data[ITEM_PUT]));
          if (customerIdResponse || locationState?.goToEditPrevAsset) {
            displaySuccessNotification(NotificationTypes.ASSET_UPDATED);
            // TODO: history can be empty
            navigate(-1);
          } else {
            successfulRedirect(ROUTES.ASSETS, NotificationTypes.ASSET_UPDATED);
          }
        } else {
          displayErrorNotification(NotificationTypes.ASSET_UPDATE_ERROR);
        }
      })
      .catch(() => displayErrorNotification(NotificationTypes.ASSET_UPDATE_ERROR));
  };

  const [statusFrom, setDefaultStatus] = useState("");
  const statusesData = useGetAcitvitiesStatuses();
  useEffect(() => {
    if (statusesData.length > 0) {
      const statusId = statusesData.find((status) => status.Name === EMPTY).Id;
      setDefaultStatus(statusId);
    }
  }, [statusesData]);

  const isLoading = isLoadingAssetData || isSaving || !accountId;

  return (
    <AssetForm
      {...rest}
      assetId={params.id || ""}
      handleSubmit={updateAssetInfo}
      assetData={asset}
      isLoading={isLoading}
      statusFrom={statusFrom}
      setHeaderOptions={setHeaderOptions}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  assetId: ownProps?.match?.params?.id,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (assetType?: string, items?: Set<string>, relatedItems?) =>
    dispatch(setSelectedAssets(assetType, items, relatedItems)),
  setFormDirtyFlag: (value: boolean) => dispatch(setEditFormIsDirty(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
